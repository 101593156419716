import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import {
  setPendingChangesList,
  setPendingChangesLoading,
  setPendingChangesLoaded,
} from 'ducks/pendingChangesSlice'
import { refreshUpcomingChangeDate } from './refreshUpcomingChangeDate'
import { batch } from 'react-redux'

const GET_PENDING_CHANGES = gql`
  query getPendingChanges {
    pendingChanges {
      id
      changeType
      recordId
      changeDetails
      publishDate
      createdBy
      createdAt
      createUser
      rule
    }
  }
`

export const refreshPendingChanges = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPendingChangesLoading(true))
      const pendingChangesData = await client.query({
        query: GET_PENDING_CHANGES,
        fetchPolicy: 'network-only',
      })

      const rawPendingChanges = pendingChangesData.data.pendingChanges

      batch(() => {
        dispatch(setPendingChangesList(rawPendingChanges))
        dispatch(setPendingChangesLoaded(true))
        dispatch(setPendingChangesLoading(false))    
        dispatch(refreshUpcomingChangeDate())
      })  
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(setPendingChangesLoaded(true))
      dispatch(setPendingChangesLoading(false))
    }
  }
}
