import { createSlice, createSelector } from '@reduxjs/toolkit'
import { format } from 'date-fns'

const initialState = {
  timestamp: null,
  isLoadingTimestamp: false,
}

const lastSavedSlice = createSlice({
  name: 'lastSaved',
  initialState,
  reducers: {
    setLastSaved: (state, action) => {
      state.timestamp = action.payload
    },
    setLoadingTimestamp: (state, action) => {
      state.isLoadingTimestamp = action.payload
    },
  },
})

export const { setLastSaved, setLoadingTimestamp } = lastSavedSlice.actions

const lastSavedSelector = state => state.lastSaved
export const selectLastSaved = createSelector(
  lastSavedSelector,
  ({ timestamp, isLoadingTimestamp }) => {
    if (!timestamp) return 'Not Saved'
    else if (isLoadingTimestamp) return 'Saving'
    const date = new Date(timestamp)
    const formattedDate = format(date, 'MM/dd/yyyy')
    const formattedTime = format(date, 'HH:mm:ss')
    return `Last Saved: ${formattedDate} ${formattedTime}`
  }
)

export default lastSavedSlice.reducer
