import EffectCollector from 'side-effects/effectCollector'
import { tableQuestions } from 'components/interview/DomesticAbuseVictimQuestions'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  tableQuestions.forEach(question => {
    if (question_id === question.problem && answer?.toUpperCase() !== 'YS') {
      question.issue && effectCollector.add(question.issue, null)
    }
  })

  if (question_id === 'rcvd_counslng' && answer?.toUpperCase() === 'NO') {
    effectCollector.add('counseling_table', null)
  }

  return effectCollector.effects
}

export default effects;