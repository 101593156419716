import LensOutlinedIcon from '@material-ui/icons/LensOutlined'
import LensIcon from '@material-ui/icons/Lens'
import React from 'react'
import { colors } from 'app/theme'

const NavIcon = React.memo(({ status }) => {
  const navStatusIcon =
    status === 'complete' ? (
      <LensIcon style={{ color: colors.turquoise }} fontSize='small' />
    ) : status === 'in progress' ? (
      <LensOutlinedIcon style={{ color: colors.turquoise }} fontSize='small' />
    ) : (
      <LensIcon style={{ color: colors.lightgrey }} fontSize='small' />
    )

  return <>{navStatusIcon}</>
})

export default NavIcon
