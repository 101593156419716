import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'general_health_challenge' &&
    (answer === 'NO' || !answer)
  ) {
    effectCollector.add('health_challenge_wtw_activities', null)
    effectCollector.add('general_health_explain', null)
  }
  if (
    question_id === 'health_challenge_wtw_activities' &&
    (answer === 'NO' || !answer)
  ) {
    effectCollector.add('general_health_explain', null)
  }
  return effectCollector.effects
}

export default effects;