import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { colors } from 'app/theme'
import { setNewUser } from 'ducks/adminSlice'
import AdminManageFilterMenu from 'components/admin/AdminManageFilterMenu'
import AdminManageSearchResults from 'components/admin/AdminManageSearchResults'
import {
  filterData,
  createHandler,
} from 'components/admin/AdminHelperFunctions'
import AdminQueries from './AdminQueries'

const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
  }
`

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`

const AdminManage = () => {
  const { orgLevelCode } = useSelector(state => state.user)
  const { organization } = useSelector(state => state.admin)
  const STATE_WIDE = '00'

  const dispatch = useDispatch()

  // ***************************************************************************
  //                                 QUERIES
  // ***************************************************************************

  // Query for regions. Fires off after an organziation is selected.
  const [queryRegions, { data: regionData, error: regionError }] = useLazyQuery(
    AdminQueries.GET_REGIONS
  )
  // Query for offices. Fires off after a region is selected.
  const [queryOffices, { data: officeData, error: officeError }] = useLazyQuery(
    AdminQueries.GET_OFFICES_IN_DIFFERENT_REGIONS
  )

  // Query for retrieving the search results.
  const [
    manageUserSearch,
    {
      loading: userSearchLoading,
      data: userSearchResults,
      error: userSearchError,
    },
  ] = useLazyQuery(AdminQueries.SEARCH_FOR_USERS, {
    fetchPolicy: 'no-cache',
  })

  // Query for the counties associated with that organization level.
  // Fires off immediately.
  const { data: orgLevelData, error: orgLevelError } = useQuery(
    AdminQueries.GET_COUNTIES,
    {
      variables: { id: orgLevelCode },
      fetchPolicy: 'network-only',
    }
  )

  // ***************************************************************************
  //                                 QUERY HANDLERS
  // ***************************************************************************
  const handleRegionsQuery = createHandler({ query: queryRegions })
  const handleOfficesQuery = createHandler({ query: queryOffices })
  const handleUserSearch = createHandler({
    query: manageUserSearch,
    payload: true,
  })

  // ***************************************************************************
  //                                 USEEFFECT
  // ***************************************************************************
  const queryRegionsOnLoad = useCallback(
    orgLevelID => {
      queryRegions({
        variables: { id: orgLevelID },
        fetchPolicy: 'network-only',
      })
    },
    [queryRegions]
  )

  // Make this query if the user is not a statewide admin user and load the
  // regions that are associated with the user's county.
  useEffect(() => {
    if (!organization && orgLevelCode !== STATE_WIDE) {
      dispatch(setNewUser({ field: 'organization', value: orgLevelCode }))
      queryRegionsOnLoad(orgLevelCode)
    }
  }, [orgLevelCode, dispatch, queryRegionsOnLoad, organization])

  // ***************************************************************************
  //                              FILTERED DATA
  // ***************************************************************************
  const orgLevelOptions = filterData(
    orgLevelData,
    orgLevelError,
    'getCountyByOrgLevelCode',
    'dropdown',
    dispatch
  )
  const regionOptions = filterData(
    regionData,
    regionError,
    'getRegionsByOrganizationLevelID',
    'dropdown',
    dispatch
  )
  const officeOptions = filterData(
    officeData,
    officeError,
    'getOfficesByRegionIDs',
    'dropdown',
    dispatch
  )

  return (
    <Grid container spacing={2}>
      <PageTitleContainer>
        <Typography variant='h3'>OCAT Administration</Typography>
      </PageTitleContainer>
      <Grid item xs={12}>
        <SectionTitle variant='h4'>Manage User</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Typography display='block' component='span'>
          Search for user by entering information in the following fields.
        </Typography>
        <AdminManageFilterMenu
          dropdownValues={{
            orgLevelOptions,
            regionOptions,
            officeOptions,
          }}
          lazyQueries={{
            manageUserSearch: handleUserSearch,
            queryRegions: handleRegionsQuery,
            queryOffices: handleOfficesQuery,
          }}
        />
        <AdminManageSearchResults
          loading={userSearchLoading}
          data={userSearchResults?.manageUsersQuery}
          error={userSearchError}
        />
      </Grid>
    </Grid>
  )
}

export default AdminManage
