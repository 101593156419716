import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import isEmpty from 'lodash/isEmpty'
import { openDialog } from 'ducks/dialogSlice'
import { setNotes } from 'ducks/noteSlice'
import { storeInterviewData } from 'ducks/interviewSlice'
import { setInterviewNavigation } from 'ducks/navigationSlice'
import {
  setRecommendations,
  setRecommendationsLoading,
} from 'ducks/recommendationsSlice'
import { storeInterviewProgress } from 'ducks/interviewProgressSlice'
import { initialValidation } from 'thunks/validation'
import { seedInitialActionPlans } from 'thunks/seedInitialActionPlans'
import { logError } from 'thunks/logError'
import { setRelatedCases } from 'ducks/clientSlice'
import { batch } from 'react-redux'

const GET_ASSOCIATED_CLIENT_INTERVIEWS = gql`
  query getAssociatedInterviews($interviewID: Int!) {
    associatedInterviews(interviewID: $interviewID) {
      id
      Client {
        id
        firstName
        lastName
        cin
      }
      Case {
        id
        caseNumber
      }
    }
  }
`

// Get all categories, domains, and subdomains for the interview navigation bar
const GET_INTERVIEW_NAV_DATA = gql`
  query categoriesAndDomains {
    categoriesAndDomains {
      payload
    }
  }
`

// Get a combination of questions and answers for this interview
const GET_INTERVIEW_DATA = gql`
  query($interviewID: Int!) {
    questionsWithAnswers(where: { interviewID: $interviewID }) {
      payload
      impacts {
        id
        changeType
        recordId
        changeDetails
        publishDate
        createdBy
        createdAt
        createUser
        rule
        question
      }
    }
  }
`

const GET_INTERVIEW_NOTES = gql`
  query interviewNotes($interviewId: Int) {
    interviewNotes(interviewId: $interviewId) {
      subdomain
      Notes
    }
  }
`

// Get all categories, domains, and subdomains for the interview navigation bar
const GET_INTERVIEW_RECOMMENDATION_DATA = gql`
  query recommendationsAndActionPlans($interviewId: Int) {
    recommendationsAndActionPlans(interviewId: $interviewId) {
      recommendations {
        id
        domainID
        categoryID
        typeID
        englishRecommendationText
        englishCategoryText
        englishTypeText
        actionPlans {
          id
          recommendationID
          accepted
          referralMade
          actionText
        }
        domain {
          id
          description
          code
        }
        category {
          id
          description
          code
        }
        type {
          id
          description
          code
        }
      }
    }
  }
`

export const getAssociatedClientInterviews = interviewID => {
  return async (dispatch, getState) => {
    try {
      const { data } = await client.query({
        query: GET_ASSOCIATED_CLIENT_INTERVIEWS,
        variables: { interviewID },
        fetchPolicy: 'network-only',
      })

      const filteredRelatedCases = data.associatedInterviews.map(interview => ({
        id: interview.id,
        cin: interview.Client.cin,
        name: `${interview.Client.firstName} ${interview.Client.lastName}`,
        caseNumber: interview.Case.caseNumber,
      }))
      dispatch(setRelatedCases(filteredRelatedCases))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
export const getCategoriesAndDomains = () => {
  return async (dispatch, getState) => {
    try {
      // Query the server for all categories, domains, and subdomains
      // to populate the interview progress bar
      const categoriesAndDomains = await client.query({
        query: GET_INTERVIEW_NAV_DATA,
      })
      const interviewNavigationData = categoriesAndDomains.data
      // No server error, but questions payload is empty for any reason
      if (
        interviewNavigationData &&
        isEmpty(interviewNavigationData?.categoriesAndDomains?.payload)
      ) {
        // This shouldn't have happened, send the request to logError mutation
        dispatch(
          logError({
            shortDescription: 'Error retrieving categories and domains',
          })
        )

        return
      } else {
        dispatch(
          setInterviewNavigation(
            categoriesAndDomains.data.categoriesAndDomains.payload
          )
        )
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

export const getRecommendationsAndActionPlans = interviewId => {
  return async (dispatch, getState) => {
    try {
      // Query the server for all recommendations and action plans associated to this interview
      // Set a flag to let the recommendations page to wait
      dispatch(setRecommendationsLoading(true))
      const recommendationData = await client.query({
        query: GET_INTERVIEW_RECOMMENDATION_DATA,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })

      const rawRecommendations =
        recommendationData.data.recommendationsAndActionPlans.recommendations
      const recommendations = {}

      rawRecommendations.forEach(recommendation => {
        recommendations[recommendation.id] = recommendation
      })
      batch(() => {
        dispatch(setRecommendations(recommendations))
        dispatch(seedInitialActionPlans())
        dispatch(setRecommendationsLoading(false))
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(setRecommendationsLoading(false))
    }
  }
}

export const getInterviewQuestionsAndAnswers = interviewID => {
  return async (dispatch, getState) => {
    try {
      // Query the server for all categories, domains, and subdomains
      // to populate the interview progress bar
      const questionsAndAnswers = await client.query({
        query: GET_INTERVIEW_DATA,
        variables: { interviewID },
        fetchPolicy: 'network-only',
      })
      const questionsAndAnswerData = questionsAndAnswers.data
      // No server error, but questions payload is empty for any reason
      if (
        questionsAndAnswerData &&
        isEmpty(questionsAndAnswerData?.questionsWithAnswers?.payload)
      ) {
        // This shouldn't have happened, send the request to logError mutation
        dispatch(
          logError({
            shortDescription: 'Error retrieving questions',
          })
        )
        return
      } else {
        dispatch(storeInterviewData(questionsAndAnswers.data))
        dispatch(
          initialValidation(
            questionsAndAnswers.data.questionsWithAnswers.payload
          )
        )
        
        if(questionsAndAnswers.data.questionsWithAnswers.impacts && questionsAndAnswers.data.questionsWithAnswers.impacts.length > 0) {
          dispatch(
            openDialog({
              type: 'interviewImpacts',
              title: 'Interview Impacted by Recent Updates',
              description: `This interview has been impacted by recent updates to questions or recommendation rules`,
              props: {
                impacts: questionsAndAnswers.data.questionsWithAnswers.impacts
              }
            })
          )
        }
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

// Get interview completion progress information
const GET_INTERVIEW_PROGRESS = gql`
  query($interviewId: Int!) {
    interviewProgress(where: { interview_id: $interviewId }) {
      id
      domain
      subdomain
      visited
    }
  }
`

export const getInterviewProgress = interviewId => {
  return async (dispatch, getState) => {
    try {
      // Query the server for interview progress to set nav state
      const interviewProgress = await client.query({
        query: GET_INTERVIEW_PROGRESS,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })

      dispatch(storeInterviewProgress(interviewProgress.data))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

export const getInterviewNotes = interviewId => {
  return async (dispatch, getState) => {
    try {
      const interviewNotes = await client.query({
        query: GET_INTERVIEW_NOTES,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })
      const notes = interviewNotes?.data?.interviewNotes
      dispatch(setNotes({ notes }))
      return true
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      return false
    }
  }
}
const GET_PREVIEW_APPRAISAL_PREVIEW = gql`
  query getPreviewAppraisalPreview($answers: [AnswerInput!]) {
    getPreviewAppraisalPreview(answers: $answers)
  }
`
const GET_APPRAISAL_PREVIEW = gql`
  query($interviewId: Int!) {
    getAppraisalPreview(interviewId: $interviewId)
  }
`

export const getPreviewInterviewAppraisalPreview = interviewId => {
  return async (dispatch, getState) => {
    try {
      const previewData = getState().preview
      const answers = Object.keys(previewData)
        .filter(key => previewData[key] && previewData[key].answer)
        .map(key => ({
          questionID: key,
          answerValue: { question_id: key, answer: previewData[key].answer },
        }))
      const htmlData = await client.query({
        query: GET_PREVIEW_APPRAISAL_PREVIEW,
        fetchPolicy: 'network-only',
        variables: { answers },
      })
      return htmlData.data
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

export const getInterviewAppraisalPreview = interviewId => {
  return async (dispatch, getState) => {
    try {
      const htmlData = await client.query({
        query: GET_APPRAISAL_PREVIEW,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })
      return htmlData.data
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

const GET_INTERVIEW_READY_TO_GENERATE_STATUS = gql`
  query($interviewId: Int) {
    interviewReadyToGenerate(interviewId: $interviewId)
  }
`

export const isInterviewReadyToGenerateStatus = interviewId => {
  return async (dispatch, getState) => {
    try {
      const result = await client.query({
        query: GET_INTERVIEW_READY_TO_GENERATE_STATUS,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })
      return result.data
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
