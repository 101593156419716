import rules from './rulesList'

const check = (rules, roles, action, data) => {
  if (!roles) return
  return roles
    .map(role => {
      const permissions = rules[role.code]
      if (!permissions) {
        return false
      }

      const staticPermissions = permissions.static

      if (staticPermissions && staticPermissions.includes(action)) {
        return true
      }
      const dynamicPermissions = permissions.dynamic

      if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action]
        if (!permissionCondition) {
          return false
        }
        return permissionCondition(data)
      }
      return false
    })
    .some(results => results === true)
}

const Can = props =>
  check(props.rules || rules, props.roles, props.perform, props.data)
    ? props.yes()
    : props.no()

Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

export default Can
