import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'
import ClientListQueries from '../clientList/ClientListQueries'
import ClientSearch from '../clientSearch/ClientSearch'
import { openDialog } from 'ducks/dialogSlice'
import {
  changeCurrentRow,
  changeLocalFilters,
  changePageIndex,
  changePageSize,
  changeSearchCriteria,
  changeSort,
  setClientSearchData,
  resetClientSearch,
} from 'ducks/clientSearchSlice'

// Shares data source with ClientList
const ClientSearchDataLoader = () => {
  const [canSearch, setCanSearch] = useState(false);

  const dispatch = useDispatch()

  const initialFilterState = {
    firstName: '',
    lastName: '',
    ssn: '',
    cin: '',
    interviewerId: '',
    birthDate: null,
    countyId: '',
    caseNumber: '',
  }

  // this state contains the server filter data as it is changed by the user. The user
  // has to click the search button to confirm teh criteria and have it dispatched to the redux store
  const [nonConfirmedSearchCriteria, setNonConfirmedSearchCriteria] = useState(
    initialFilterState
  )

  const {
    pageIndex,
    pageSize,
    inputFilters,
    serverFilters,
    totalRecordCount,
    records,
    orderBy,
    order,
    currentRow,
  } = useSelector(state => state.clientSearch)

  const [doClientSearch, { loading, data, error }] = useLazyQuery(
    ClientListQueries.CLIENT_SEARCH_INTERVIEW_PAGED_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )

  const doSearch = () => {
    // the user has clicked the search button, move the search filters to redux state
    dispatch(
      changeSearchCriteria({
        ...nonConfirmedSearchCriteria,
        interviewerId: nonConfirmedSearchCriteria.interviewerId
          ? parseInt(nonConfirmedSearchCriteria.interviewerId)
          : null,
        countyId: nonConfirmedSearchCriteria.countyId
          ? parseInt(nonConfirmedSearchCriteria.countyId)
          : null,
      })
    )
  }

  useEffect(() => {
    dispatch(resetClientSearch())
    setCanSearch(true);
  }, [dispatch])

  useEffect(() => {
    if (serverFilters && canSearch) {
      doClientSearch({
        variables: {
          ...serverFilters,
          pageIndex,
          pageSize,
          sortBy: orderBy,
          sortAscending: order === 'asc',
        },
      })
    } 
  }, [doClientSearch, serverFilters, pageIndex, pageSize, orderBy, order, canSearch])

  const handlePage = newPageIndex => {
    dispatch(changePageIndex(newPageIndex))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(changePageSize(rowsPerPage))
  }

  const handleFilterChange = (filter, type) => {
    setNonConfirmedSearchCriteria({
      ...nonConfirmedSearchCriteria,
      [filter]: type ?? '',
    })
  }

  const handleInputFilterChange = inputFilters => {
    dispatch(changeLocalFilters(inputFilters))
  }

  const handleSelectedRowIndexChange = rowIndex => {
    dispatch(changeCurrentRow(rowIndex))
  }

  const handleSortChange = newSort => {
    dispatch(changeSort(newSort))
  }

  const handleReset = () => {
    setNonConfirmedSearchCriteria(initialFilterState)
    dispatch(resetClientSearch())
  }

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
    data && dispatch(setClientSearchData(data?.interviewsByClientSearchPage))
  }, [error, dispatch, data])

  const sort = { order: order, orderBy: orderBy }

  return (
    <ClientSearch
      records={records}
      loading={loading}
      handleClientSearch={doSearch}
      handlePage={handlePage}
      handleRowsPerPage={handleRowsPerPage}
      page={pageIndex}
      rowsPerPage={pageSize}
      totalRecordCount={totalRecordCount}
      filters={nonConfirmedSearchCriteria}
      filtersChanged={handleFilterChange}
      inputFilters={inputFilters}
      handleInputFiltersChanged={handleInputFilterChange}
      selectedRowIndex={currentRow}
      handleSelectedRowIndexChange={handleSelectedRowIndexChange}
      sort={sort}
      handleSortChange={handleSortChange}
      onReset={handleReset}
    />
  )
}

export default ClientSearchDataLoader
