import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'

const CREAT_ASR_PDF = gql`
  mutation($interviewId: Int!, $language: SupportedPdfLanguage!) {
    generateAsrPdf(interviewId: $interviewId, language: $language) {
      reportLink
      spanishReportLink
    }
  }
`

export const createAsrPdf = (interviewId, language) => {
  return async (dispatch, getState) => {
    try {
      const asrResult = await client.mutate({
        mutation: CREAT_ASR_PDF,
        variables: {
          interviewId,
          language,
        },
      })
      return asrResult.data
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
