import { removeOffice } from 'ducks/entitiesSlice'
import { openDialog } from 'ducks/dialogSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'

const DELETE_OFFICE = gql`
  mutation($id: Int!) {
    deleteOffice(id: $id) {
      deletedOfficeId
    }
  }
`

export const deleteOffice = office => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: DELETE_OFFICE,
        variables: {
          id: office.officeID,
        },
      })

      dispatch(removeOffice(office))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
