import React from 'react'
import styled from 'styled-components'
import calsawsLogo from 'images/calsaws-logo.png'

const LogoImage = styled.img`
  height: 43px;
  width: 156px;
  margin-right: 20px;
`

const CalsawsLogo = () => <LogoImage data-testid="calsaws-logo" src={calsawsLogo} alt='The Calsaws logo' />

export default CalsawsLogo
