import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Info = styled.div`
  flex: 1 0 100%;
  display: block;
  padding-bottom: 16px;
`

const InfoTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`

export const InformationDialogContent = ({ information }) => {
  const infoElements = information.map(info => {
    return (
      <Info key={info.id}>
        <InfoTitle variant='body1'>{info.field}</InfoTitle>
        <Typography variant='body2' color='error'>
          {info.value}
        </Typography>
      </Info>
    )
  })
  return <div>{infoElements}</div>
}

export const InformationDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }

  return (
    <>
      <Button onClick={handleClose} variant='contained' color='primary'>
        OK
      </Button>
    </>
  )
}
