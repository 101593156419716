import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { setIdleSession, setTimedOut } from 'ducks/sessionSlice'
import { openDialog } from 'ducks/dialogSlice'
import { signOut } from 'thunks/signOut'
import { updateCookieExpiration } from 'utilTools/cookiesHelper'
import debounce from 'lodash/debounce'

let idleTimerID = null
let modalTimerID = null
let autoLogoutTimerID = null

const UPDATE_SESSION = gql`
  mutation {
    updateSession
  }
`
// debounce the server session extension call so we dont flood the server needlessly.
// one call every 5 minutes should be sufficient.
const debouncedExtendSession = debounce((dispatch) => {
  if (process.env.NODE_ENV !== 'development') {
  // Update cookie expiration time by 20 minutes
  updateCookieExpiration('ocatJwt', 1200)

  // this function is declared in the index file for application monitoring
  // eslint-disable-next-line no-undef
  ineum('reportEvent', 'sessionKeepAlive')
  // Update session expiration time
  client.mutate({ mutation: UPDATE_SESSION }).catch(e => {
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportError', e, {
      meta: {
        useCase: 'attempting to extend session',
      },
    })
    // If update session fails, show timed out page.
    const autoRedirect = false
    dispatch(signOut(autoRedirect))
    dispatch(setTimedOut(true))
  })
} else {
  // add a breakpoint here is you need to troubleshoot in dev or locally.
}
}, 1000 * 60 * 5, { trailing: false, leading: true, maxWait: 1000 * 60 * 5 })

const idleTimer = ({ dispatch, getState }) => next => action => {
  switch (action.type) {
    case 'session/setActiveSession':
      {
        // Clear previous timer
        if (idleTimerID) {
          clearInterval(idleTimerID)
          idleTimerID = null
        }
        if (modalTimerID) {
          clearInterval(modalTimerID)
          modalTimerID = null
        }
        if (autoLogoutTimerID) {
          clearInterval(autoLogoutTimerID)
          autoLogoutTimerID = null
        }

        const isTimedOut = getState().session.isTimedOut
        const userNotFound = getState().user.userNotFound
        const loggedIn = getState().user.loggedIn

        if (isTimedOut || userNotFound || !loggedIn) {
          dispatch({
            type: 'stopIdleTimer',
          })
          return
        }

        debouncedExtendSession(dispatch)

        // if the session is idle for a minute set the idle state
        idleTimerID = setInterval(async () => {
          dispatch(setIdleSession()) // Reset active session state
        }, 1000 * 60 * 1)

        // if the session is idle for 19 minutes display the session timeout modal
        modalTimerID = setInterval(async () => {
          // this function is declared in the index file for application monitoring
          // eslint-disable-next-line no-undef
          ineum('reportEvent', 'showSessionModal')

          dispatch(
            openDialog({
              type: 'timeoutWarning',
              title: `Your session is about to expire!`,
            })
          )
        }, 1000 * 60 * 19)

        // if idle for 21 minutes automatically log the user out,
        autoLogoutTimerID = setInterval(async () => {
          // this function is declared in the index file for application monitoring
          // eslint-disable-next-line no-undef
          ineum('reportEvent', 'sessionTimedOut')
          
          dispatch({
            type: 'stopIdleTimer',
          })

          // If update session fails, show timed out page.
          const autoRedirect = false
          dispatch(signOut(autoRedirect))
          dispatch(setTimedOut(true))
        }, 1000 * 60 * 21)
      }
      break
    case 'stopIdleTimer': {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportEvent', 'stopIdleTimer')
      if (idleTimerID) {
        clearInterval(idleTimerID)
        idleTimerID = null
      }
      if (modalTimerID) {
        clearInterval(modalTimerID)
        modalTimerID = null
      }
      if (autoLogoutTimerID) {
        clearInterval(autoLogoutTimerID)
        autoLogoutTimerID = null
      }
      break
    }
    default: {
      return next(action)
    }
  }
  return next(action)
}

export default idleTimer
