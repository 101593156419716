import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { navigateToNextPage } from 'ducks/navigationSlice'
import Can from 'app/Can'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  && {
    margin: 16px;
  }
`

const NextButton = ({ label, isPreview }) => {
  const dispatch = useDispatch()
  const roles = useSelector(state => state.user.roles)
  const history = useHistory()
  const interviewStatus = useSelector(
    state => state.client.currentInterviewStatus
  )
  const canSave =
    interviewStatus?.toLowerCase() !== 'complete' &&
    interviewStatus?.toLowerCase() !== 'cancelled'

  const handleClick = () => {
    dispatch(navigateToNextPage(history, isPreview, canSave))
  }

  const handleClickReadOnly = () => {
    dispatch(navigateToNextPage(history, isPreview, false))
  }

  return (
    <Can
      perform={isPreview ? 'BRE:view' : 'interview:edit'}
      roles={roles}
      no={() => (
        <Container>
          <StyledButton
            variant='contained'
            color='primary'
            onClick={handleClickReadOnly}
          >
            {label}
          </StyledButton>
        </Container>
      )}
      yes={() => (
        <Container>
          <StyledButton
            variant='contained'
            color='primary'
            onClick={handleClick}
          >
            {label}
          </StyledButton>
        </Container>
      )}
    />
  )
}

export default NextButton
