import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  let uppercaseAnswers = []
  if (
    answer &&
    question_id === 'status_of_relationship' &&
    Array.isArray(answer)
  ) {
    uppercaseAnswers = answer.map(ans => ans.toUpperCase())
    if (!uppercaseAnswers.includes('DA') && !uppercaseAnswers.includes('MA')) {
      effectCollector.add('rate_relationship', null)
    }
  }
  return effectCollector.effects
}

export default effects;