import React, { useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { colors } from 'app/theme'
import { openDialog } from 'ducks/dialogSlice'

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    display: block;
    margin: 20px 0;
  }
`

const GridContainer = styled(Grid)`
  margin-bottom: 8px;
`

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`
const DataContainer = styled(Grid)`
  && {
    margin-top: 10px;
    margin-left: 10px;
  }
`
const CustomTextField = styled(TextField)`
  && {
    margin-top: ${p => (p.supervisor ? '0' : '20px')};
    > input: {
      color: white;
    }
    width: 350px;
    margin-right: 20px;
  }
`

const CustomTableContainer = styled(Paper)`
  && {
    width: 400px;
    pading-right: 0;
  }
`

const GET_SECTION_MEMBERS = gql`
  query getSectionMembers($supervisorID: Int!) {
    getSectionMembers(supervisorID: $supervisorID) {
      firstName
      lastName
    }
  }
`

const ViewProfile = React.memo(() => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { error, data: sectionMembers } = useQuery(GET_SECTION_MEMBERS, {
    variables: { supervisorID: user.id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  let primaryRole = null
  let secondaryRole = null

  // If the user has more than one role, then secondary role is always "Administrator"
  if (user.roles?.length > 1) {
    primaryRole = user.roles?.find(role => role.code !== 'AD')
    secondaryRole = user.roles?.find(role => role.code === 'AD')
  } else {
    primaryRole = user.roles?.length === 1 && user.roles[0]
  }

  return (
    <Container>
      <PageTitleContainer>
        <Typography variant='h3'>User Profile</Typography>
      </PageTitleContainer>
      <SectionTitle variant='h4'>
        {user.firstName} {user.lastName}
      </SectionTitle>
      <DataContainer item xs={12}>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <CustomTextField
            variant='outlined'
            type='text'
            label='First Name'
            inputProps={{ 'aria-label': 'First Name' }}
            value={user.firstName}
            disabled={true}
            autoComplete='off'
          ></CustomTextField>
          <CustomTextField
            variant='outlined'
            type='text'
            label='Last Name'
            inputProps={{ 'aria-label': 'Last Name' }}
            value={user.lastName}
            disabled={true}
            autoComplete='off'
          ></CustomTextField>
          <CustomTextField
            variant='outlined'
            type='text'
            label='Email'
            inputProps={{ 'aria-label': 'Email' }}
            value={user.email}
            disabled={true}
            autoComplete='off'
          ></CustomTextField>
        </GridContainer>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <CustomTextField
            variant='outlined'
            type='text'
            label='Role'
            inputProps={{ 'aria-label': 'Role' }}
            value={primaryRole?.name}
            disabled={true}
            autoComplete='off'
          />

          <CustomTextField
            variant='outlined'
            type='text'
            label='Secondary Role'
            inputProps={{ 'aria-label': 'Secondary Role' }}
            value={secondaryRole?.name}
            disabled={true}
            autoComplete='off'
          />

          <CustomTextField
            variant='outlined'
            type='text'
            label='Organization'
            inputProps={{ 'aria-label': 'Organization' }}
            value={user.orgLevelName}
            disabled={true}
            autoComplete='off'
          />
        </GridContainer>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <CustomTextField
            variant='outlined'
            type='text'
            label='Region'
            inputProps={{ 'aria-label': 'Region' }}
            value={user.region}
            disabled={true}
            autoComplete='off'
          />
          <CustomTextField
            variant='outlined'
            type='text'
            label='Office'
            inputProps={{ 'aria-label': 'Office' }}
            value={user.office}
            disabled={true}
            autoComplete='off'
          />
          <CustomTextField
            variant='outlined'
            type='text'
            label='Database Extract'
            inputProps={{ 'aria-label': 'Database Extract' }}
            value={user.dataExtract ? 'Yes' : 'No'}
            disabled={true}
            autoComplete='off'
          />
        </GridContainer>
      </DataContainer>
      <SectionTitle variant='h6'>Supervisor</SectionTitle>
      <DataContainer item xs={12}>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <CustomTextField
            supervisor='true'
            variant='outlined'
            type='text'
            label='Supervisor'
            inputProps={{ 'aria-label': 'Supervisor' }}
            value={user.supervisor}
            disabled={true}
            autoComplete='off'
          />
        </GridContainer>
      </DataContainer>
      <SectionTitle variant='h6'>Section Members</SectionTitle>
      <DataContainer item xs={12}>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sectionMembers?.getSectionMembers ? (
                  sectionMembers?.getSectionMembers.map((member, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <CustomTextField
                            variant='outlined'
                            type='text'
                            value={`${member?.firstName} ${member?.lastName}`}
                            disabled={true}
                            autoComplete='off'
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </GridContainer>
      </DataContainer>
    </Container>
  )
})

export default ViewProfile
