import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import HelpAndSupport from 'components/help/HelpAndSupport'
import ListItem from '@material-ui/core/ListItem'
import { colors } from 'app/theme'
import { withStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'

import Can from 'app/Can'
import ChatbotTrigger from './ChatbotTrigger'

export const GET_RESOURCE_LINK = gql`
  query getResourceLink($resourceId: String!) {
    getResourceLink(resourceId: $resourceId)
  }
`



const StyledContainer = styled.div`
  width: 270px;
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      color: ${p => p.active && colors.turquoise};
    }
  }
`

const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
    font-weight: 400;
  }
`

const StyledListItem = withStyles({
  root: {
    paddingLeft: '36px',
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },

    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(ListItem)

const Help = () => {
  const roles = useSelector(state => state.user.roles)

  const [queryStaticResource, { data: resourceData, loading }] = useLazyQuery(
    GET_RESOURCE_LINK,
    {
      fetchPolicy: 'network-only',
    }
  )
  
  const getResourceLink = resourceId => {
    queryStaticResource({
      variables: { resourceId }
    })
  }

  useEffect(() => {
    if(resourceData && !loading) {
      setTimeout(() => {
        window.open(resourceData.getResourceLink)
      })
    }
  }, [resourceData, loading])

  return (
    <Grid container spacing={2}>
      <StyledContainer>
        <StyledListItem button>
          <StyledListItemText primary='Help and Support' active={1} />
        </StyledListItem>
        <StyledListItem button>
          <StyledLink onClick={() => getResourceLink('faq')} target='_blank' rel='noreferrer'>
            Frequently Asked Questions (FAQ)
          </StyledLink>
        </StyledListItem>
        <StyledListItem button>
          <StyledLink onClick={() => getResourceLink('userManual')} target='_blank' rel='noreferrer'>
            OCAT User Manual
          </StyledLink>
        </StyledListItem>
        <Can
          perform={'help:viewAdmin'}
          roles={roles}
          yes={() => {
            return (
              <StyledListItem button>
                <StyledLink onClick={() => getResourceLink('adminManual')}  target='_blank' rel='noreferrer'>
                  OCAT Administrator User Manual
                </StyledLink>
              </StyledListItem>
            )
          }}
        />

        <StyledListItem button>
          <StyledLink
            href={process.env.REACT_APP_LMS}
            target='_blank'
            rel='noreferrer'
          >
            OCAT Learning Management System
          </StyledLink>
        </StyledListItem>
      </StyledContainer>
      <Grid item xs={6}>
        <HelpAndSupport />
      </Grid>
      <ChatbotTrigger />
    </Grid>
  )
}

export default Help
