import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography, Grid } from '@material-ui/core'
import Text from 'components/general/Text'
import Select from 'components/general/Select'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'
import TableRowDescription from 'components/general/TableRowDescription'
import Dropdown from 'components/general/Dropdown'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteText from 'components/table/InfiniteText'
import Indicator from 'components/general/Indicator'
import { selectStyle } from 'components/interview/DemographicsGeneralInformation'

const currencyStyle = {
  marginTop: '6px',
}
const mediCalStyle = {
  marginBottom: '-31px',
  marginLeft: '269px',
}
const receivedCalworksPreviousSelector = state =>
  state.interview.received_calworks_previous.answer

const previewReceivedCalworksPreviousSelector = state =>
  state.preview.received_calworks_previous.answer

const paymentsDebtsTableIndicatorSelector = state =>
  state.interview.payments_debts_table.indicator

const previewPaymentsDebtsTableIndicatorSelector = state =>
  state.preview.payments_debts_table.indicator

const selectPaymentsDebtsTableIndicator = createSelector(
  paymentsDebtsTableIndicatorSelector,
  indicator => indicator
)

const selectPreviewPaymentsDebtsTableIndicator = createSelector(
  previewPaymentsDebtsTableIndicatorSelector,
  indicator => indicator
)

const selectReceivedCalworksPrevious = createSelector(
  receivedCalworksPreviousSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewReceivedCalworksPrevious = createSelector(
  previewReceivedCalworksPreviousSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const receivingSsiSelector = state => state.interview.receiving_ssi.answer
const previewReceivingSsiSelector = state => state.preview.receiving_ssi.answer

const selectReceivingSsi = createSelector(
  receivingSsiSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const selectPreviewReceivingSsi = createSelector(
  previewReceivingSsiSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const specifyOtherSelector = (state, question_id, index) =>
  state.interview[question_id].answer[index].payment_debt_type
const previewSpecifyOtherSelector = (state, question_id, index) =>
  state.preview[question_id].answer[index].payment_debt_type
const createSpecifyOtherSelector = () => {
  return createSelector(
    specifyOtherSelector,
    answer => answer?.toUpperCase() === 'OT'
  )
}
const createPreviewSpecifyOtherSelector = () => {
  return createSelector(
    previewSpecifyOtherSelector,
    answer => answer?.toUpperCase() === 'OT'
  )
}

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const tableHeaders = [
  { name: 'Benefit', colWidth: '50%' },
  { name: 'Amount', colWidth: '50%' },
]

const questionIdsI = [
  'calfresh_amount',
  'calworks_amount',
  'capi_amount',
  'childcare_amount',
  'childsupport_amount',
  'housing_amount',
]

const questionIdsII = [
  'state_disability_amount',
  'student_scholarship_amount',
  'supplemental_social_security_amount',
  'transportation_amount_per_month',
  'unemployment_amount',
  'veteran_amount',
  'non_monetary_gov_benefits',
]

const headers = ['Payment/Debt Type', 'Amount per Month']

const PaymentDebtRow = props => {
  const { index, question_id, isPreview } = props
  const specifyOtherSelector = isPreview ? createPreviewSpecifyOtherSelector :  createSpecifyOtherSelector()
  const specifyOther = useSelector(state =>
    isPreview ? previewSpecifyOtherSelector(state, question_id, index) : specifyOtherSelector(state, question_id, index)
  )

  return (
    <>
      <TableCell align='center'>
        <div>
          <InfiniteDropdown
            noTable
            sub_question_id={'payment_debt_type'}
            isPreview={isPreview}
            {...props}
          />
        </div>
        {specifyOther && (
          <InfiniteText
            noTable
            isDisabled={!specifyOther}
            sub_question_id={'specify_finances'}
            isPreview={isPreview}
            {...props}
            hideLabel={false}
          />
        )}
      </TableCell>
      <TableCell align='center'>
        <InfiniteText
          noTable
          sub_question_id={'payments_per_month'}
          type='currency'
          isPreview={isPreview}
          {...props}
        />
      </TableCell>
    </>
  )
}

const FinancesBenefits = React.memo(({ isPreview }) => {
  const receivedCalworksPreviousIsYes = useSelector(
    isPreview ? selectPreviewReceivedCalworksPrevious : selectReceivedCalworksPrevious
  )
  const receivingSsiIsNo = useSelector(isPreview ? selectPreviewReceivingSsi : selectReceivingSsi)
  const indicator = useSelector(isPreview ? selectPreviewPaymentsDebtsTableIndicator : selectPaymentsDebtsTableIndicator)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Finances / Benefits'
          domain='Demographics'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Benefits Received</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Please select all benefits the client is receiving and
          indicate the monthly allowance he or she is receiving.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CustomTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableHeaderCell key={index} colwidth={header.colWidth}>
                      {header.name}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {questionIdsI.map((id, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={id}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Text
                          question_id={id}
                          type='currency'
                          disableDescriptionLabel={true}
                          sawsIndicator={true}
                          indicatorStyle={currencyStyle}
                          isPreview={isPreview}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell>
                    <TableRowDescription question_id='medi_cal_covered_cc' isPreview={isPreview}></TableRowDescription>
                  </TableCell>
                  <TableCell>
                    <Select
                      question_id='medi_cal_covered_cc'
                      disableDescription={true}
                      sawsIndicator={true}
                      noLabelAndDescription={true}
                      indicatorStyle={mediCalStyle}
                      isPreview={isPreview}
                    />
                  </TableCell>
                </TableRow>
                {questionIdsII.map((id, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={id}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Text
                          question_id={id}
                          type='currency'
                          disableDescriptionLabel={true}
                          sawsIndicator={true}
                          indicatorStyle={currencyStyle}
                          isPreview={isPreview}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <Select
            question_id='received_calworks_previous'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Dropdown
            question_id='county_from'
            isDisabled={!receivedCalworksPreviousIsYes}
            width={'450px'}
            sawsIndicator={true}
            subquestion
            isPreview={isPreview}
          />
          <Text
            question_id='welfare_to_work_plan'
            isDisabled={!receivedCalworksPreviousIsYes}
            multiline
            rows='4'
            subquestion
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select
            question_id='receiving_ssi'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Select
            question_id='ever_applied_ssi'
            isDisabled={!receivingSsiIsNo}
            subquestion
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
        </form>
        <Grid item xs={12}>
          <Typography variant='h5'>Payments / Debt</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>
            Do you have any large payments that you make on a regular basis? If
            so, how much per month/payment?
          </Typography>
        </Grid>
        <form>
          <InfiniteTable
            rowComponent={<PaymentDebtRow isPreview={isPreview} />}
            headers={headers}
            question_id='payments_debts_table'
            indicator={<Indicator indicator={indicator} isPreview={isPreview} />}
            isPreview={isPreview}
          />
          <Grid item xs={12}>
            <Typography variant='h5'>Financial Issues</Typography>
          </Grid>
          <Select
            question_id='have_bank_account'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Select question_id='trouble_paying_bills' isPreview={isPreview} />
          <Select question_id='money_management_class' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})
export default FinancesBenefits
