import { createSlice } from '@reduxjs/toolkit'
import { openDialog } from 'ducks/dialogSlice'

const initialState = {}

const validationErrorsSlice = createSlice({
  name: 'validationErrors',
  initialState,
  reducers: {
    addValidationError: (state, action) => {
      if (action.payload.admin) {
        state[action.payload.field] = action.payload.error
      } else {
        state[action.payload.question_id] = action.payload.error
      }
    },
    removeValidationError: (state, action) => {
      delete state[action.payload]
    },
    removeAllValidationErrors: (state, action) => initialState,
    bulkAddValidationErrors: (state, action) => {
      for (let question of action.payload) {
        state[question.question_id] = question.error
      }
    },
  },
})

export const checkValidation = disableNotifications => {
  return async (dispatch, getState) => {
    const validationErrors = getState().validationErrors

    const numValidationErrors = Object.keys(validationErrors).length

    // Check validation -- if bad, cant proceed, show snackbar
    if (numValidationErrors > 0) {
      // Construct validation error objects for ValidationDialog
      const cleanValidationErrors = Object.entries(validationErrors).map(
        entry => {
          const [key, value] = entry
          const interview = getState().interview[key]
          return {
            id: key,
            field: interview.description,
            value: value,
          }
        }
      )
      if (!disableNotifications) {
        // Open ValidationDialog
        dispatch(
          openDialog({
            type: 'validation',
            title: `Validation Errors`,
            description: 'There were problems with the following fields:',
            props: { errors: cleanValidationErrors },
          })
        )
      }

      return false
    }

    return true
  }
}

export const {
  addValidationError,
  removeValidationError,
  removeAllValidationErrors,
  bulkAddValidationErrors,
} = validationErrorsSlice.actions

export default validationErrorsSlice.reducer
