import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import CreateClientQueries from 'components/createClient/CreateClientQueries'
import { openDialog } from '../../ducks/dialogSlice'

const OuterContainer = styled.div`
  margin: 20px 0;
`

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 10px;
`
const ClientGender = ({
  genderID,
  setGenderID,
  genderError,
  handleFormErrors,
}) => {
  const dispatch = useDispatch()

  const {
    loading: gendersLoading,
    error: gendersError,
    data: gendersData,
  } = useQuery(CreateClientQueries.GENDERS_LIST_QUERY)

  useEffect(() => {
    if (gendersError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error: gendersError,
          },
        })
      )
    }
  }, [gendersError, dispatch])

  if (gendersLoading) return <div>Loading gender selections...</div>

  const handleGenderBlur = () => {
    if (genderID?.length < 1) {
      handleFormErrors('genderID', true)
    } else {
      handleFormErrors('genderID', false)
    }
  }

  const GenderSelections =
    gendersData &&
    gendersData?.genders
      .sort((a, b) => a.id - b.id)
      .map((gender, index) => {
        return (
          <FormControlLabel
            key={gender.id}
            value={`${gender.id}`}
            control={
              <Radio
                inputProps={{
                  'data-testid': `CreateClientGender-radio-${gender.id}`,
                  'aria-label': `Option ${gender.description} ${index}`,
                }}
                id={gender.description}
                defaultValue={null}
                checked={gender.id === genderID}
              />
            }
            label={gender.description}
          />
        )
      })

  // ADA requires uncheck functionality
  const handleClick = e => {
    if (genderID === parseInt(e.target.value)) {
      setGenderID('')
      handleFormErrors('genderID', true)
    } else {
      handleFormErrors('genderID', false)
      setGenderID(parseInt(e.target.value))
    }
  }

  // ADA requires enter key accessibility
  const handleKeyPress = e => {
    const keyCode = e.which || e.code
    const enterKeyCode = 13
    if (keyCode === enterKeyCode) {
      if (genderID === parseInt(e.target.value)) {
        setGenderID('')
        handleFormErrors('genderID', true)
      } else {
        handleFormErrors('genderID', false)
        setGenderID(parseInt(e.target.value))
      }
    }
  }

  return (
    <OuterContainer>
      <FormControl component='fieldset' error={genderError} required>
        <StyledFormLabel component='legend'>Gender</StyledFormLabel>
        <RadioGroup
          onBlur={handleGenderBlur}
          aria-label='gender'
          name='gender'
          onChange={e => setGenderID(parseInt(e.target.value))}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          row
          value={genderID}
        >
          {GenderSelections}
        </RadioGroup>
        {genderError && (
          <FormHelperText>This field must not be blank</FormHelperText>
        )}
      </FormControl>
    </OuterContainer>
  )
}

export default ClientGender
