import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { selectLastSaved } from 'ducks/lastSavedSlice'

const LastSavedDisplay = () => {
  const timestamp = useSelector(selectLastSaved)

  return <Typography variant='body2'>{timestamp}</Typography>
}

export default LastSavedDisplay
