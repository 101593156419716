import { openDialog } from 'ducks/dialogSlice'
import { format, parseISO } from 'date-fns'
import { decodeHtml } from 'utilTools/decodeHtml'
export const createHandler = ({ query, key = 'id', payload = false }) => {
  if (payload) {
    return searchParams => {
      query({
        variables: searchParams,
        fetchPolicy: 'network-only',
      })
    }
  }
  return searchParams => {
    query({
      variables: { [key]: searchParams },
      fetchPolicy: 'network-only',
    })
  }
}

export const filterData = (data, error, queryName, type, dispatch) => {
  if (data && data[queryName].length) {
    if (type === 'dropdown') {
      if (queryName === 'getCountyByOrgLevelCode') {
        return data[queryName].map(item => ({
          name: item.value,
          id: item.code,
        }))
      } else {
        return data[queryName].map(item => ({
          name: item.name,
          id: item.id,
        }))
      }
    } else {
      return data[queryName].map(item => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }))
    }
  } else {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
    return []
  }
}

export const formatUsersForTable = data => {
  const formattedUser = []

  data.forEach(user => {
    user.role.sort((a, b) => b.roleDetails.code - a.roleDetails.code)
    user.role.forEach(userRole => {
      const decodedFirstName = decodeHtml(user.firstName)
      const decodedLastName = decodeHtml(user.lastName)
      formattedUser.push({
        id: user.id,
        county: user.orgLevelID.code,
        firstName: decodedFirstName,
        lastName: decodedLastName,
        regionID: user.details.regionID,
        officeID: user.details.officeID,
        dataExtract: user.details.dataExtract,
        supervisorID: user.supervisor?.id || null,
        name: `${decodedFirstName} ${decodedLastName}`,
        email: user.email,
        roleTitle: userRole.roleDetails.value,
        roles: user.role,
        supervisor: user.supervisor
          ? `${decodeHtml(user.supervisor?.firstName)} ${decodeHtml(user.supervisor?.lastName)}`
          : '',
        dateAdded: userRole.effectiveStartDate
          ? format(new Date(userRole.effectiveStartDate), 'MM/dd/yyyy')
          : '',
        status: user.isDisabled ? 'Inactive' : 'Active',
        disabledDate: user.disabledDate
          ? format(parseISO(user.disabledDate), 'MM/dd/yyyy')
          : null,
      })
    })
  })
  return formattedUser
}