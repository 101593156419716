import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import ClientListDropDown from 'components/clientList/ClientListDropDown'
import ClientListQueries from 'components/clientList/ClientListQueries'
import { openDialog } from 'ducks/dialogSlice'

const ClientListCountyDropdown = ({
  handleChange,
  defaultValue,
  menuIsOpen,
}) => {
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(
    ClientListQueries.COUNTIES_LIST_QUERY
  )

  const counties = data?.counties || []

  // If there is an error returning client list data, show the error as a modal
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  if (loading) return <div data-testid='loading'>Loading counties...</div>
  const dropdownOptionsGenerator = dataArray => {
    return (dataArray || []).map(element => {
      return { value: element.id, label: element.description }
    })
  }
  const countyDropdownOptions = dropdownOptionsGenerator(counties)

  // Add the first value of "none" per requirements, none has no filtering effect
  countyDropdownOptions.unshift({ value: '', label: 'None' })
  return (
    <ClientListDropDown
      handleChange={handleChange}
      options={countyDropdownOptions}
      defaultValue={defaultValue}
      menuIsOpen={menuIsOpen}
      searchable={true}
    />
  )
}

export default ClientListCountyDropdown
