import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import NavLink from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from 'ducks/dialogSlice'
import { createSelector } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { colors } from 'app/theme'

const StyledLink = styled.div`
  && {
    cursor: pointer;
  }

  &&:hover {
    text-decoration: underline;
  }
`
const GlobalNavButtons = styled(NavLink)`
  color: ${p => p.currentpage === 'true' && colors.turquoise};
  :hover {
    color: ${colors.turquoise};
    cursor: pointer;
    text-decoration: none;
  }
  :focus {
    color: ${colors.turquoise};
    cursor: pointer;
    text-decoration: none;
  }
  :active {
    color: ${colors.turquoise};
    text-decoration: none;
  }
`

const unsavedChangesSelector = state => state.unsavedChanges.answers
const unsavedActionPlanSelector = state => state.unsavedActionPlans.actionPlans
const unsavedInterviewChanges = createSelector(
  unsavedChangesSelector,
  unsavedChanges => {
    return Object.keys(unsavedChanges).length > 0
  }
)

const unsavedActionPlanChanges = createSelector(
  unsavedActionPlanSelector,
  unsavedChanges => {
    return Object.keys(unsavedChanges).length > 0
  }
)

const LinkButton = ({
  testId,
  description,
  route,
  className,
  currentPage = false,
  defaultAction = null,
  isButton = false,
}) => {
  const dispatch = useDispatch()
  const unsavedChanges = useSelector(unsavedInterviewChanges)
  const unsavedActionPlans = useSelector(unsavedActionPlanChanges)
  const creatingClient = useSelector(state => state.createClient.creatingClient)
  const discardActionType = unsavedChanges || unsavedActionPlans? 'interview' : 'createClient'
  const shouldInterceptNavigation = unsavedChanges || creatingClient || unsavedActionPlans
  const generateLink = () => {
    if (shouldInterceptNavigation && isButton) {
      return (
        <Button
          data-testid={testId}
          onClick={e => handleClick(e, route, discardActionType)}
          variant='outlined'
        >
          {description}
        </Button>
      )
    } else if (shouldInterceptNavigation) {
      return (
        <div
          data-testid={testId}
          onClick={e => {
            handleClick(e, route, discardActionType)
          }}
        >
          {description}
        </div>
      )
    } else {
      if (isButton) {
        return (
          <RouterLink data-testid={testId} to={route}>
            <Button onClick={defaultAction} variant='outlined'>
              {description}
            </Button>
          </RouterLink>
        )
      }
      return (
        <GlobalNavButtons
          data-testid={testId}
          onClick={defaultAction}
          component={RouterLink}
          to={route}
          currentpage={currentPage.toString()}
        >
          {description}
        </GlobalNavButtons>
      )
    }
  }

  const handleClick = (e, route, discardActionType) => {
    dispatch(
      openDialog({
        type: 'discard',
        title: `Unsaved Changes`,
        description: '',
        props: { subdomainId: route, discardActionType },
      })
    )
  }

  return (
    <StyledLink className={className} currentPage={currentPage}>
      {generateLink()}
    </StyledLink>
  )
}

export default LinkButton
