import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { colors } from 'app/theme'

const Container = styled.div`
  height: 84px;
  padding-top: 4px;
`

const StyledSelect = styled(Select)`
  width: 250px;
  margin-top: 20px;
  margin-right: 40px;
  max-width: 500px;
`

const customStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    height: `56px`,
    border: isFocused
      ? `2px solid ${colors.calsawsBlue} `
      : `1px solid #4a4335`,
    borderRadius: '4px',
    boxShadow: isFocused ? 0 : 0,
    ':hover': {
      border: isFocused
        ? `2px solid ${colors.calsawsBlue} `
        : `1px solid #4a4335`,
      borderRadius: '4px',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px 14px',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: `16px`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.54)',
    ':hover': {
      color: `${colors.calsawsBlue}`,
    },
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ':hover': {
      outline: `1px solid ${colors.saffron}`,
    },
    ':active': {
      outline: `1px solid ${colors.saffron}`,
    },
    backgroundColor: 'rgba(255, 255, 255, 1)',
    outline: isFocused ? `1px solid ${colors.saffron}` : null,
    color: '#000',
  }),
}

const SearchSelect = ({ handleChange, options, searchable = false, label }) => {
  return (
    <Container>
      <StyledSelect
        options={options}
        styles={customStyles}
        isSearchable={searchable}
        onChange={handleChange}
        isClearable={true}
        placeholder={label}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  )
}

export default SearchSelect
