import checkForEmpty from 'side-effects/checkForEmpty'

// Helper class to build and perform validations
class Validator {
  constructor() {
    this.validators = {}
  }

  add = (question_id, validator) => {
    this.validators[question_id] = validator
  }

  validate = (question_id, answer) => {
    // Get the validation function
    const validationFunc = this.validators[question_id]
    // Ensure that the various 'empty' responses are translated to null
    let cleanAnswer = checkForEmpty(answer)
    // If there is a non-falsy answer, convert it to a string. This is because
    // This validation is only occuring inside of text inputs, and so their
    // value will always be a string
    if (cleanAnswer && !Array.isArray(cleanAnswer)) {
      cleanAnswer = cleanAnswer.toString()
    }
    // Return the response if there is one, otherwise null
    const response = validationFunc(cleanAnswer)
    if (response) {
      return response
    }
    return null
  }

  hasValidation = question_id => {
    if (this.validators[question_id]) {
      return true
    }
    return false
  }
}

export default Validator
