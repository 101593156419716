import NumberFormat from 'react-number-format'
import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import validation from 'validation/specialCharacterValidator'

const CustomTextField = styled(TextField)`
  && {
    width: ${p => p.width};
    margin-top: ${p => (p.label ? '20px' : '3px')};
    > input: {
      color: white;
    }
    margin-right: 40px;
  }
`

const StyledSSNAdornment = styled(InputAdornment)`
  && {
    min-width: 250px;
    padding-bottom: 7px;
  }
`

const baseInputFactory = (props, additionalSettings) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      {...additionalSettings}
    />
  )
}

const ssnInput = props => {
  const additionalSettings = {
    format: '###-##-####',
    allowEmptyFormatting: true,
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

const SearchText = React.memo(
  ({
    type,
    width = '250px',
    value = '',
    label,
    variant,
    handleChange,
    autoFocus,
  }) => {
    let inputVariant
    if (variant) {
      inputVariant = variant
    } else {
      inputVariant = 'outlined'
    }

    // control code for secrecy masking
    const [maskShown, setMaskShown] = useState(true)
    const fieldsNeedMask = ['ssn']

    const showTheMask = () =>
      fieldsNeedMask.includes(type) && setMaskShown(true)

    const hideTheMask = () =>
      fieldsNeedMask.includes(type) && setMaskShown(false)

    // secrecy masks
    const maskSSNValue = ssnData => {
      if (!ssnData) return ''
      const ssnLength = ssnData && ssnData.length
      if (ssnLength) {
        const result = ssnData.replace(/^\d{1,5}/, x => x.replace(/./g, '*'))
        return ssnLength > 3
          ? `${result.substr(0, 3)}-${result.substr(3, 2)}-${result.substr(
              5,
              4
            )}`
          : result
      }
    }
    const iniMaskedSSN = maskSSNValue(value)
    const ssnSecrecyMask = maskShown ? (
      <StyledSSNAdornment position='start'>
        {iniMaskedSSN?.length > 0 ? iniMaskedSSN : '___-__-____'}
      </StyledSSNAdornment>
    ) : null

    const calculateInputProps = type => {
      return type === 'ssn'
        ? {
            inputComponent: ssnInput,
            startAdornment: ssnSecrecyMask,
          }
        : {}
    }

    const inputProps = calculateInputProps(type)

    const handleValueChange = event => {
      const isInvalidInput = validation.hasSpecialCharacter(event.target.value)
      if (!isInvalidInput) {
        handleChange(event)
      }
    }

    return (
      <div>
        <CustomTextField
          inputProps={{
            'aria-label': `Text field ${label}`,
          }}
          type='text'
          id={label}
          width={width}
          label={label}
          variant={inputVariant}
          value={value}
          onChange={handleValueChange}
          InputProps={inputProps}
          onBlur={showTheMask}
          onClick={hideTheMask}
          autoFocus={autoFocus}
          autoComplete='off'
        />
      </div>
    )
  }
)

export default SearchText
