import React, { useState } from 'react'
import { isValid, format, parse, isBefore, isAfter } from 'date-fns'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { KeyboardDatePicker } from '@material-ui/pickers'

const StyledPicker = styled(KeyboardDatePicker)`
  width: 250px;
  margin-bottom: 20px;
`

const CreateClientDatePicker = ({
  dobError,
  handleFormErrors,
  setBirthDate,
  birthDate,
  validateField,
}) => {
  const [dateInvalid, setDateInvalid] = useState(false)

  const checkIfDateOutOfRange = date => {
    // We have a requirement to have the date be before 01/01/1000
    // and to be not in the future
    // normally, the picker would handle this error, but since we have
    // such customized error handling, we need to do the check ourselves

    const isBeforeOldest = isBefore(date, new Date('01/01/1000'))
    const isFutureDate = isAfter(date, new Date())
    if (isBeforeOldest || isFutureDate) {
      return true
    } else {
      return false
    }
  }
  const handleDOBChange = (date, value) => {
    const dateOutOfRange = checkIfDateOutOfRange(date)
    // If it's a valid date, we send the date formatted "MM/dd/yyyy"
    if (isValid(date) && !dateOutOfRange) {
      setBirthDate(format(date, 'MM/dd/yyyy'))
      handleFormErrors('birthDate', false)
      setDateInvalid(false)
    } else {
      // Otherwise we set the appropriate errors, and set the value
      handleFormErrors('birthDate', true)
      setDateInvalid(true)
      setBirthDate(value)
    }
  }

  const parsedValue = parse(birthDate, 'MM/dd/yyyy', new Date())

  const error = dobError || dateInvalid
  const errorMessage = dateInvalid
    ? 'Date format must be MM/DD/YYYY and between 01/01/1000 and today.'
    : 'This field is required'
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledPicker
          KeyboardButtonProps={{
            'aria-label': `Button of the date picker for Date of Birth`,
          }}
          label='Date of Birth'
          id='birthDate'
          value={null}
          variant='inline'
          inputVariant='outlined'
          required
          disableFuture
          format='MM/dd/yyyy'
          inputValue={
            isValid(parsedValue) ? format(parsedValue, 'MM/dd/yyyy') : birthDate
          }
          error={error}
          onChange={(date, value) => handleDOBChange(date, value)}
          onBlur={e => validateField(e, 'date')}
          helperText={error ? errorMessage : null}
          inputProps={{
            'data-testid': `CreateClientDatePicker-input`,
          }}
        />
      </Grid>
    </>
  )
}

export default CreateClientDatePicker
