import React from 'react'
import { colors } from 'app/theme'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import PrintIcon from '@material-ui/icons/Print'

const StyledIconButton = styled(IconButton)`
  color: ${colors.calsawsBlue};
  :hover {
    color: ${colors.saffron};
  }
  padding-bottom: 15px;
  margin: 0;
`
const PrintQuestionnaireButton = ({ rowIndex, interviewId }) => {
  const dispatch = useDispatch()
  return (
    <StyledIconButton
      aria-label={`Print questionnaire button ${rowIndex}`}
      onClick={() =>
        dispatch(
          openDialog({
            type: 'printQuestionnaire',
            title: 'Print Questionnaire',
            props: { interviewId },
          })
        )
      }
    >
      <PrintIcon />
    </StyledIconButton>
  )
}

export default PrintQuestionnaireButton
