import client from 'apollo/Apollo'
import {
  setBreRoleNotAssigned,
  setUser,
  setUserNotFound,
} from 'ducks/userSlice'
import AuthQueries from 'components/auth/AuthQueries'
import { signOut } from './signOut'

const fetchUser = () => {
  return async (dispatch, getState) => {
    try {
      const hasAuthErrors = checkForAuthErrors()

      if (hasAuthErrors) {
        throw new Error()
      }

      const { data } = await client.query({
        query: AuthQueries.AUTH,
        fetchPolicy: 'network-only',
      })
      const {
        id,
        firstName,
        lastName,
        email,
        orgLevelID,
        role,
        details,
        supervisor,
        isDisabled,
      } = data.auth.user

      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('user', id, `${firstName} ${lastName}`, email)
      // eslint-disable-next-line no-undef
      ineum('meta', 'role', role)
      
      const countyId = data.auth.county?.id
      const countyCode = data.auth.county?.code
      const countyName = data.auth.county?.description

      // eslint-disable-next-line no-undef
      ineum('meta', 'county', `${countyCode} - ${countyName}`)

      // 00 is org code for a statewide user
      const isStatewide = data.auth.user.orgLevelID?.code === '00'

      // eslint-disable-next-line no-undef
      ineum('meta', 'isStatewideUser', isStatewide)
      // eslint-disable-next-line no-undef
      ineum('meta', 'office', details?.officeDetails?.name)
      // eslint-disable-next-line no-undef
      ineum('meta', 'region', details?.regionDetails?.name)

      dispatch(
        setUser({
          id,
          firstName,
          lastName,
          email,
          countyId,
          countyName,
          isStatewide,
          orgLevelID: orgLevelID?.id,
          orgLevelCode: orgLevelID?.code,
          orgLevelName: orgLevelID?.value,
          region: details?.regionDetails?.name,
          office: details?.officeDetails?.name,
          dataExtract: details?.dataExtract,
          loggedIn: true,
          isDisabled: isDisabled === 1,
          roles: role?.map(item => {
            return {
              name: item.roleDetails?.value,
              code: item.roleDetails?.code,
            }
          }),
          supervisor: `${supervisor?.firstName} ${supervisor?.lastName}`,
        })
      )
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      const autoRedirect = false
      if (JSON.stringify(error).match(/BRE_FR_ROLE_NOT_EXIST/)) {
        dispatch(setBreRoleNotAssigned(true))
      } else {
        dispatch(setUserNotFound(true))
      }
      dispatch(signOut(autoRedirect))
    }
  }
}

const checkForAuthErrors = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('error') ? true : false
}

export default fetchUser
