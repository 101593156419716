import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { isEmpty, isNumber } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch, batch } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import ClientGender from 'components/createClient/CreateClientGender'
import ClientCIN from 'components/createClient/ClientCIN'
import CreateClientDatePicker from 'components/createClient/CreateClientDatePicker'
import CreateClientDialog from 'components/createClient/CreateClientDialog'
import CreateClientMutation from 'components/createClient/CreateClientMutations'
import { setClient } from 'ducks/clientSlice'
import { setCreatingClient } from 'ducks/createClientSlice'
import { openDialog } from 'ducks/dialogSlice'
import { openSnackbar } from 'ducks/snackbarSlice'
import { validateCIN } from 'utilTools/valueCheckers'
import SearchDropdown from 'components/clientSearch/SearchDropdown'
import ClientListQueries from 'components/clientList/ClientListQueries'
import CreateClientQueries from 'components/createClient/CreateClientQueries'
import validation from 'validation/specialCharacterValidator'

const StyledButton = styled(Button)`
  && {
    margin: 16px 16px 16px 0;
    min-width: 100px;
  }
`
const StyledInput = styled(TextField)`
  && {
    width: 250px;
    margin-bottom: 20px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 15px;
`

const filterCountiesByOrgLevel = (payload, code, isStatewide) => {
  const filteredPayload = isStatewide
    ? payload
    : payload.filter(county => county.interfaceCode === code)
  const options = filteredPayload.map(county => ({
    optionId: county.interfaceCode,
    optionLabel: county.description,
  }))
  return options
}
const CreateClient = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isStatewide, orgLevelCode } = useSelector(state => state.user)
  const [wantsToCreate, setWantsToCreate] = useState(false)
  const [wantsToClear, setWantsToClear] = useState(false)
  const [cin, setCIN] = useState('')
  const [cinExists, setCINExists] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [sawsCaseNumber, setSawsCaseNumber] = useState('')
  const [birthDate, setBirthDate] = useState(null)
  const [genderID, setGenderID] = useState('')
  const [cinError, setCINError] = useState(false)
  const [county, setCounty] = useState('')
  const [formErrors, setFormErrors] = useState({
    cin: false,
    firstName: false,
    lastName: false,
    birthDate: false,
    sawsCaseNumber: false,
    genderID: false,
    county: false,
  })

  // Used to check if a client with the same cin exists in the system
  const [cinExistsQuery, { loading: cinExistsLoading, error: cinExistsError }] =
    useLazyQuery(CreateClientQueries.CIN_EXISTS_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        // The data returned from the query to see if a client exists with this cin
        const clientExists = data.clientByCIN?.id
        if (clientExists) {
          setCINExists(true)
          handleFormErrors('cin', true)
        } else {
          setCINExists(false)
          handleFormErrors('cin', false)
        }
      },
    })

  // Used to create the new client
  const [createClient, { loading, error }] = useMutation(
    CreateClientMutation.CREATE_CLIENT_MUTATION
  )

  // Query for county list for dropwdown
  const { error: countyFetchingError, data: countiesPayload } = useQuery(
    ClientListQueries.COUNTIES_LIST_QUERY
  )

  // Handle error for fetching counties
  useEffect(() => {
    if (countyFetchingError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error: countyFetchingError,
          },
        })
      )
    }
  }, [dispatch, countyFetchingError])

  const countyOptions = countiesPayload
    ? filterCountiesByOrgLevel(
        countiesPayload.counties,
        orgLevelCode,
        isStatewide
      )
    : []

  // Set the County
  useEffect(() => {
    if (!isStatewide && countiesPayload) setCounty(orgLevelCode)
  }, [isStatewide, orgLevelCode, countiesPayload])

  const handleSave = async () => {
    // In order for the save dialog to open, we need to meet several criteria
    // 1. All inputs have data
    // 2. There are no errors in the inputs
    // 3. There is not a matching CIN in the system

    // Check for errors in form data
    const shouldDisableSave =
      Object.values(formErrors).includes(true) || cinError

    // Check all values for data
    const allValuesPresent =
      cin &&
      firstName &&
      lastName &&
      sawsCaseNumber &&
      birthDate &&
      genderID &&
      county

    // If the CIN matches, dispatch the snackbar
    if (cinExists) {
      dispatch(
        openSnackbar(
          'A matching CIN has been found! Enter a new CIN or find existing client using Client Search.'
        )
      )
      setCINError(true)
    } else if (!allValuesPresent) {
      // If there a missing fields, set appropriate error messages and dispatch the snackbar
      const fields = {
        cin,
        firstName,
        lastName,
        sawsCaseNumber,
        birthDate,
        genderID,
        county,
      }
      const newErrors = {
        ...formErrors,
      }
      Object.keys(fields).forEach(field => {
        if (isEmpty(fields[field]) && !isNumber(fields[field])) {
          newErrors[field] = true
        }
      })
      setFormErrors(newErrors)
      dispatch(
        openSnackbar(
          'Please ensure required fields are completed with proper values.'
        )
      )
    } else if (!shouldDisableSave && allValuesPresent) {
      // All conditions have been met, go ahead and open the dialog
      setWantsToCreate(true)
    }
  }

  // Performs the mutation to create the client
  const handleCreateClient = async () => {
    try {
      const client = await createClient({
        variables: {
          cin,
          firstName,
          lastName,
          sawsCaseNumber,
          birthDate,
          genderID,
          county,
        },
      })

      // There is a requirement that if a client is created in OCAT, and the resulting
      // interview is not saved, the client and all info will be deleted
      // The client has been created, do the following:
      // 1. Set the client info in state
      // 2. Create an interview and navigate
      if (client.data?.createClient) {
        const { clientID } = client.data.createClient
        batch(() => {
          // Set the client
          dispatch(
            setClient({
              id: clientID,
              firstName,
              lastName,
              cin,
              dob: birthDate,
              caseNumber: sawsCaseNumber,
            })
          )
          dispatch(setCreatingClient(false))
          dispatch(openSnackbar('Client created!'))
          // Navigate to this new client's interview list
          history.push('/interview_list')
        })
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // An additional error handling if the error doesn't get caught by apollo client
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }

  // Handle the form error state based on input
  const handleFormErrors = (input, hasError) => {
    const newFormErrors = { ...formErrors }
    newFormErrors[input] = hasError
    setFormErrors(newFormErrors)
  }

  // // As we have custom logic to handle required fields, we check for
  // // empty on exiting the input
  // const checkForEmpty = e => {
  //   const inputId = e.target.id
  //   const inputValueLength = e.target.value.length
  //   const isInvalid = validation.hasSpecialCharacter(e.target.value)
  //   if (inputValueLength < 1 || !e.target.value.trim() || isInvalid) {
  //     handleFormErrors(inputId, true)
  //   } else if (inputId !== 'sawsCaseNumber') {
  //     handleFormErrors(inputId, false)
  //   }
  // }
  const validateField = (e, fieldType) => {
    const inputId = e.target.id
    const inputValueLength = e.target.value.length
    const validationCallback = validation.getValidationCallBack(fieldType)
    const isInvalid = validationCallback(e.target.value)
    if (inputValueLength < 1 || !e.target.value.trim() || isInvalid) {
      handleFormErrors(inputId, true)
    } else if (inputId !== 'sawsCaseNumber') {
      handleFormErrors(inputId, false)
    }
  }

  const handleCIN = cin => {
    const isValid = validateCIN(cin)
    if (!isValid) {
      setCINError(true)
    } else {
      setCINError(false)
    }
    setCIN(cin)
  }

  const handleCaseNumber = caseNumber => {
    // Only allow alphanumeric
    if (!caseNumber || (caseNumber && caseNumber.match(/^[0-9a-zA-Z]+$/))) {
      setSawsCaseNumber(caseNumber)
    }
    // check for the length to determine error
    if (caseNumber.length === 7) {
      handleFormErrors('sawsCaseNumber', false)
    } else {
      handleFormErrors('sawsCaseNumber', true)
    }
  }

  const handleFirstName = event => {
    const value = event.target.value
    setFirstName(value)
    const isInvalid = validation.hasSpecialCharacter(value)
    handleFormErrors('firstName', isInvalid)
  }

  const handleLastName = event => {
    const value = event.target.value
    setLastName(value)
    const isInvalid = validation.hasSpecialCharacter(value)
    handleFormErrors('lastName', isInvalid)
  }

  const requiredFieldErrorText = 'This field is required'

  const getErrorText = value => {
    return validation.hasSpecialCharacter(value)
      ? 'Invalid character'
      : requiredFieldErrorText
  }

  const sawsErrorText =
    sawsCaseNumber.length !== 0
      ? 'Must be 7 alphanumeric characters'
      : requiredFieldErrorText

  // Check if any values are in the form
  const anyValuesPresent =
    cin || firstName || lastName || sawsCaseNumber || birthDate || genderID

  useEffect(() => {
    // If so, let the store know that the user is attempting
    // to create a client - this is used to prevent navigation before confirming
    // the loss of unsaved data
    if (anyValuesPresent) {
      dispatch(setCreatingClient(true))
    } else {
      dispatch(setCreatingClient(false))
    }

    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, anyValuesPresent, dispatch])

  if (loading) return <div>Creating new client...</div>

  return (
    <>
      <Typography variant='h3'>Create New Client</Typography>
      <Typography variant='h6'>
        Please enter the client's information in the following fields. All
        fields are required.
      </Typography>
      <ClientCIN
        cin={cin}
        cinError={cinError}
        cinExists={cinExists}
        setCINError={setCINError}
        handleCIN={handleCIN}
        cinExistsQuery={cinExistsQuery}
        cinExistsLoading={cinExistsLoading}
        cinExistsError={cinExistsError}
      />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInput
            label='SAWS Case Number'
            id='sawsCaseNumber'
            value={sawsCaseNumber}
            variant='outlined'
            required
            error={formErrors.sawsCaseNumber}
            onChange={e => handleCaseNumber(e.target.value)}
            onBlur={e => validateField(e, 'text')}
            helperText={formErrors.sawsCaseNumber ? sawsErrorText : null}
            inputProps={{
              'data-testid': `CreateClient-input-sawsCaseNumber`,
              maxLength: 7,
            }}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInput
            label='First Name'
            id='firstName'
            value={firstName}
            variant='outlined'
            required
            error={formErrors.firstName}
            onChange={handleFirstName}
            onBlur={e => validateField(e, 'text')}
            helperText={formErrors.firstName ? getErrorText(firstName) : null}
            autoComplete='off'
            inputProps={{
              'data-testid': `CreateClient-input-firstName`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledInput
            label='Last Name'
            id='lastName'
            value={lastName}
            variant='outlined'
            required
            error={formErrors.lastName}
            onChange={handleLastName}
            onBlur={e => validateField(e, 'text')}
            helperText={formErrors.lastName ? getErrorText(lastName) : null}
            autoComplete='off'
            inputProps={{
              'data-testid': `CreateClient-input-lastName`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <CreateClientDatePicker
            dobError={formErrors.birthDate}
            handleFormErrors={handleFormErrors}
            setBirthDate={setBirthDate}
            validateField={validateField}
            birthDate={birthDate}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SearchDropdown
            label='County *'
            value={county}
            handleChange={e => {
              const value = e.target.value ? e.target.value : ''
              setCounty(value)
              const hasError = !e.target.value ? true : false
              handleFormErrors('county', hasError)
            }}
            options={countyOptions}
            dropdownProps={{
              'data-testid': `SearchDropdown-input-county`,
              onBlur: e => {
                const hasError = !e.target.value ? true : false
                handleFormErrors('county', hasError)
              },
              onClose: e => {
                const hasError = !e.target.value ? true : false
                handleFormErrors('county', hasError)
              },
              required: true,
              disabled: !isStatewide,
              error: formErrors.county,
            }}
            errorText={formErrors.county ? requiredFieldErrorText : null}
          />
        </Grid>
      </Grid>
      <ClientGender
        genderID={genderID}
        setGenderID={setGenderID}
        genderError={formErrors.genderID}
        handleFormErrors={handleFormErrors}
      />
      <Grid item xs={12}>
        <ButtonContainer>
          <StyledButton
            data-testid={`CreateClient-button-cancel`}
            onClick={() => setWantsToClear(true)}
            variant='contained'
            color='secondary'
          >
            Cancel
          </StyledButton>
          <StyledButton
            data-testid={`CreateClient-button-save`}
            onClick={handleSave}
            color='primary'
            variant='contained'
            type='submit'
          >
            Save
          </StyledButton>
        </ButtonContainer>
      </Grid>
      <CreateClientDialog
        wantsToCreate={wantsToCreate}
        setWantsToCreate={setWantsToCreate}
        handleCreateClient={handleCreateClient}
        wantsToClear={wantsToClear}
        setWantsToClear={setWantsToClear}
      />
    </>
  )
}

export default CreateClient
