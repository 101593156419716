import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Dropdown from 'components/general/Dropdown'

const currentlyWorkingSelector = state =>
  state.interview.currently_working.answer

const previewCurrentlyWorkingSelector = state =>
  state.preview.currently_working.answer

const additionalEducationDesiredSelector = state =>
  state.interview.additional_education_desired.answer

const previewAdditionalEducationDesiredSelector = state =>
  state.preview.additional_education_desired.answer

const selectCurrentlyWorking = createSelector(
  currentlyWorkingSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectPreviewCurrentlyWorking = createSelector(
  previewCurrentlyWorkingSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectNoCurrentlyWorking = createSelector(
  currentlyWorkingSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const selectPreviewNoCurrentlyWorking = createSelector(
  previewCurrentlyWorkingSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const selectAdditionalEducationDesired = createSelector(
  additionalEducationDesiredSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectPreviewAdditionalEducationDesired = createSelector(
  previewAdditionalEducationDesiredSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const EmploymentCurrentActivities = React.memo(({ isPreview }) => {
  const currentlyWorking = useSelector(isPreview ? selectPreviewCurrentlyWorking : selectCurrentlyWorking)
  const notCurrentlyWorking = useSelector(isPreview ? selectPreviewNoCurrentlyWorking : selectNoCurrentlyWorking)
  const additionalEducationDesired = useSelector(
    isPreview ? selectPreviewAdditionalEducationDesired : selectAdditionalEducationDesired
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Current Activities'
          domain='Employment'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Dropdown question_id='currently_working' isPreview={isPreview} />
          <Select
            question_id='previously_held_job'
            isDisabled={!notCurrentlyWorking}
            isPreview={isPreview}
          />
          <CheckboxGrid
            question_id='job_improvement_desired'
            isDisabled={currentlyWorking}
            isPreview={isPreview}
          />
          <Select
            question_id='additional_education_desired'
            isDisabled={currentlyWorking}
            isPreview={isPreview}
          />
          <Text
            multiline
            rows={4}
            question_id='if_yes_specify_activities'
            isDisabled={additionalEducationDesired}
            subquestion
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select question_id='have_clothing_for_job' isPreview={isPreview} />
          <CheckboxGrid question_id='access_to_resources_job_seeking' isPreview={isPreview} />
          <Text multiline rows={4} question_id='short_and_long_term_goals' shouldDecode={true} isPreview={isPreview} />
          <Text
            multiline
            rows={4}
            question_id='main_concerns_looking_for_job'
            shouldDecode={true}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})

export default EmploymentCurrentActivities
