import React from 'react'
import Typography from '@material-ui/core/Typography'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import SkipButton from 'components/general/SkipButton'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
  }
`

const clientAlreadyCompleteScreeningSelector = state =>
  state.interview.client_already_complete_screening.answer

const previewClientAlreadyCompleteScreeningSelector = state =>
  state.preview.client_already_complete_screening.answer

const clientWaivesScreeningSelector = state =>
  state.interview.client_waives_screening.answer

const previewClientWaivesScreeningSelector = state =>
  state.preview.client_waives_screening.answer

const clientLimitedEnglishSelector = state =>
  state.interview.client_limited_english.answer

const previewClientLimitedEnglishSelector = state =>
  state.preview.client_limited_english.answer

const selectClientAlreadyCompleteScreening = createSelector(
  clientAlreadyCompleteScreeningSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('CM')
)

const selectPreviewClientAlreadyCompleteScreening = createSelector(
  previewClientAlreadyCompleteScreeningSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('CM')
)

const selectClientWaivesScreening = createSelector(
  clientWaivesScreeningSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('WA')
)

const selectPreviewClientWaivesScreening = createSelector(
  previewClientWaivesScreeningSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('WA')
)

const selectClientLimitedEnglish = createSelector(
  clientLimitedEnglishSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('LM')
)

const selectPreviewClientLimitedEnglish = createSelector(
  previewClientLimitedEnglishSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('LM')
)

const canSkipSelector = createSelector(
  [
    selectClientAlreadyCompleteScreening,
    selectClientWaivesScreening,
    selectClientLimitedEnglish,
  ],
  (clientAlreadyComplete, clientWaivesScreening, clientLimitedEnglish) =>
    clientAlreadyComplete || clientWaivesScreening || clientLimitedEnglish
)

const previewCanSkipSelector = createSelector(
  [
    selectPreviewClientAlreadyCompleteScreening,
    selectPreviewClientWaivesScreening,
    selectPreviewClientLimitedEnglish,
  ],
  (clientAlreadyComplete, clientWaivesScreening, clientLimitedEnglish) =>
    clientAlreadyComplete || clientWaivesScreening || clientLimitedEnglish
)

const LearningNeedsIndicatorQuestions = React.memo(({ isPreview }) => {
  const canSkip = useSelector(isPreview ? previewCanSkipSelector : canSkipSelector)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Learning Needs Indicator Questions'
          domain='Education'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='client_already_complete_screening' isPreview={isPreview} />
          <CheckboxGrid question_id='client_waives_screening' isPreview={isPreview} />
          <Typography variant='body2'>
            Instructions to worker: If the client makes the decision not to be
            screened, read the WTW 17 form (Waiver of CalWORKs Learning
            Disabilities Screening and/or Evaluation) to the client and explain
            the importance and benefits of a learning disabilities screening
            and/or a learning disabilities evaluation. Do not offer the waiver
            in lieu of offering the screening or evaluation. If the client still
            does not want to be screened or evaluated, have the client sign the
            WTW 17 form. Give a copy of the form to the client and retain the
            original in the case file. Link to Waiver of Learning Disabilities
            Screening/Evaluation form (WTW 17):
            <StyledLink
              href={process.env.REACT_APP_WTW_17_FORM}
              target='_blank'
            >
              {process.env.REACT_APP_WTW_17_FORM ?? ''}
            </StyledLink>
          </Typography>
          <CheckboxGrid
            question_id='client_limited_english'
            disableDescription={true}
            isPreview={isPreview}
          />
        </form>
        <SkipButton isDisabled={!canSkip}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default LearningNeedsIndicatorQuestions
