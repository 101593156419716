import sideEffectsList from 'side-effects/sideEffectsList'
import checkForEmpty from 'side-effects/checkForEmpty'

// Check each changed value to determine if any additional pieces of data
// need to change as a result of a change in the initiating data point.
export const calculateChanges = payload => {
  // Start with the original change
  const initialChange = [
    {
      question_id: payload.question_id,
      answer: checkForEmpty(payload.answer),
      indicator: 'ocat',
    },
  ]
  const additionalEffects = []
  // push(...array) results in a "concatenate in place" so we don't need to define
  // any new variables for each item. This is because each calculation function
  // returns an array, so we can use the spread operator on it.
  for (let sideEffectFunction of sideEffectsList) {
    additionalEffects.push(...sideEffectFunction(payload))
  }

  return [...initialChange, ...additionalEffects]
}

export const calculatePreviewChanges = payload => {
  // Start with the original change
  const initialChange = [
    {
      question_id: payload.question_id,
      answer: checkForEmpty(payload.answer),
      indicator: 'ocat',
    },
  ]
  const additionalEffects = []
  // push(...array) results in a "concatenate in place" so we don't need to define
  // any new variables for each item. This is because each calculation function
  // returns an array, so we can use the spread operator on it.
  for (let sideEffectFunction of sideEffectsList) {
    additionalEffects.push(...sideEffectFunction(payload, true))
  }

  return [...initialChange, ...additionalEffects]
}
