import EffectCollector from 'side-effects/effectCollector'

const effects =  ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()

  if (
    question_id === 'transporation_situation_other' &&
    (answer === 'NO' || answer === null)
  ) {
    effectCollector.add('transportation_explain', null)
  }

  return effectCollector.effects
}

export default effects;