import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { createSelector } from '@reduxjs/toolkit'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import NewAppraisalExistingCase from 'components/interviewList/NewAppraisalExistingCase'
import NewAppraisalNewCase from 'components/interviewList/NewAppraisalNewCase'
import { closeDialog, openDialog } from 'ducks/dialogSlice'
import {
  setExistingCases,
  clearNewInterviewSlice,
  setCanCreateInterview,
} from 'ducks/newInterviewSlice'
import { createNewAppraisal } from 'thunks/createNewAppraisal'
import { colors } from 'app/theme'

//                            Styled Components
const Container = styled.div`
  margin: 28px 0 20px 0;
  display: flex;
  align-items: center;
`

const StyledIcon = styled(InfoIcon)`
  && {
    color: ${colors.saffron};
    margin-right: 6px;
  }
`

//                                Queries
const GET_CASES = gql`
  query CASES($countyID: Int, $clientID: Int!) {
    casesByClientIdAndUserCountyId(countyID: $countyID, clientID: $clientID) {
      caseNumber
      id
    }
  }
`

//                           State Selectors
const newInterview = state => state.newInterview
const newInterviewSelect = createSelector([newInterview], state => {
  const { newCase, newCaseNumber, existingCases } = state
  // Calculate if there is an invalid character length.
  const newCaseNumberInvalidLength = newCase
    ? (newCaseNumber && newCaseNumber.length !== 7) || !newCaseNumber
    : false

  // Create an array of all of the existing case numbers and check if
  // the new case number is already an existing case number.
  const isExistingCaseNumber = existingCases
    .map(clientCase => clientCase.caseNumber)
    .includes(newCaseNumber)

  // Switch messages depending on the error.
  const errorMessage =
    (newCaseNumberInvalidLength && 'Must be 7 alphanumeric characters') ||
    (isExistingCaseNumber && 'Case number matches existing case') ||
    ''

  return {
    ...state,
    newCaseNumberError: newCaseNumberInvalidLength || isExistingCaseNumber,
    errorMessage,
  }
})

//                            Dialog Content
export const NewAppraisalDialogContent = () => {
  const dispatch = useDispatch()

  // Get the newInterview state props
  const {
    existingCase,
    existingCases,
    newCase,
    existingCaseNumber,
    newCaseNumber,
    newCaseCounty,
    newCaseNumberError,
    errorMessage,
  } = useSelector(newInterviewSelect)

  // Get the users county ID and the clients ID.
  const { countyId: countyID } = useSelector(state => state.user)
  const { currentClient: clientID } = useSelector(state => state.client)
  // Query to get clients cases that are in the users county.
  // On complete, set all cases into redux store.
  const { error } = useQuery(GET_CASES, {
    variables: { countyID, clientID },
    fetchPolicy: 'network-only',
    onCompleted: data =>
      dispatch(setExistingCases(data.casesByClientIdAndUserCountyId)),
  })

  // If there is an error fetching data, the dialog will switch over to
  // the error dialog. Initiating a new appraisal will not be possible.
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  return (
    <>
      <NewAppraisalExistingCase
        existingCase={existingCase}
        existingCases={existingCases}
        existingCaseNumber={existingCaseNumber}
        newCase={newCase}
        newCaseNumber={newCaseNumber}
      />
      <NewAppraisalNewCase
        newCase={newCase}
        existingCase={existingCase}
        newCaseNumber={newCaseNumber}
        newCaseNumberError={newCaseNumberError}
        errorMessage={errorMessage}
        newCaseCounty={newCaseCounty}
      />
      <Container>
        <StyledIcon />
        <Typography>
          <strong>This is not a linked record.</strong>
        </Typography>
      </Container>
    </>
  )
}

//                            Dialog Actions
export const NewAppraisalDialogActions = () => {
  const dispatch = useDispatch()
  const {
    existingCase,
    newCase,
    existingCaseNumber,
    newCaseNumber,
    newCaseCounty,
    newCaseNumberError,
    hasExistingCase,
  } = useSelector(newInterviewSelect)
  const history = useHistory()
  const { isStatewide } = useSelector(state => state.user)

  const handleClose = () => {
    dispatch(closeDialog())
    dispatch(clearNewInterviewSlice())
  }

  // A new case created from a statewide user requires a county
  const newCaseStatewideError = isStatewide && !newCaseCounty
  const newCaseError = newCaseNumberError || newCaseStatewideError

  const handleClick = () => {
    // Depending on what the user has selected, switch what the
    // new case information is.
    const newCaseInformation =
      newCase && newCaseNumber && !newCaseError
        ? {
            isNewCaseNumber: true,
            caseCounty: isStatewide && newCaseCounty ? newCaseCounty : null,
            caseNumber: newCaseNumber,
          }
        : {
            isNewCaseNumber: false,
            caseID: existingCaseNumber,
          }
    // If there are no exisiting cases that can be used to prepopulate
    // from, do not show the prepopulate modal and proceed to create an
    // interview.
    if (!hasExistingCase) {
      dispatch(
        createNewAppraisal({
          history,
          ...newCaseInformation,
          prepopulateInterview: 'new',
        })
      )
      dispatch(closeDialog())
    } else {
      dispatch(closeDialog())
      dispatch(setCanCreateInterview(true))
      dispatch(
        openDialog({
          type: 'newAppraisalPopulate',
          title: `Initating New Appraisal`,
          description: 'Please select from the following options.',
          props: { ...newCaseInformation },
        })
      )
    }
  }
  const isDisabled = !(
    (newCase && newCaseNumber && !newCaseError) ||
    (existingCase && existingCaseNumber)
  )

  return (
    <>
      <Button variant='contained' color='secondary' onClick={handleClose}>
        Cancel
      </Button>
      <Button
        disabled={isDisabled}
        variant='contained'
        color='primary'
        onClick={handleClick}
      >
        Continue
      </Button>
    </>
  )
}
