import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import styled, { css } from 'styled-components'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteSelect from 'components/table/InfiniteSelect'
import InfiniteTableRowDescription from 'components/table/InfiniteTableRowDescription'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import cloneDeep from 'lodash/cloneDeep'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

import { colors } from 'app/theme'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'

// Styled Components
const sharedStyle = css`
  width: 100%;
  margin: 0 8px;
`

const StyledContainer = styled.div`
  & {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px 15px;
    width: 100%;
  }
`

const StyledDropdown = styled(InfiniteDropdown)`
  margin-right: 16px;
`
const StyledSelect = styled(InfiniteSelect)`
  && {
    ${sharedStyle}
  }
`
const StyledRow = styled(TableRow)`
  && {
    height: 54px;
  }
`
const NameRelationshipContainer = styled.div`
  display: flex;
  align-content: start;
`
const StyledText = styled(InfiniteText)`
  && {
    ${sharedStyle}
  }
`
const CardTitle = styled(CardHeader)`
  && {
    background: ${colors.lightgrey};
  }
`
const StyledTable = styled(Table)`
  && {
    margin: 15px 0 0;
  }
`
const StyledCard = styled(Card)`
  margin: 16px 0;
  overflow: auto;
`
const StyledColumnHeader = styled(TableCell)`
  text-align: center;
  min-width: 200px;
`
const StyledDescriptionHeader = styled(TableCell)`
  min-width: 280px;
`
const StyledSubHeader = styled(Typography)`
  margin: 10px 0;
`
const AddCardContainer = styled.div`
  margin: 10px 0;
`

const cardQuestions = [
  {
    sub_question_id: 'a_have_disabilities',
    specify: 'if_yes_specify_disabilities',
  },
  {
    sub_question_id: 'b_have_health_prob',
    specify: 'if_yes_specify_health_problems',
  },
  {
    sub_question_id: 'c_have_mental_health',
    specify: 'if_yes_specify_mental_health',
  },
  {
    sub_question_id: 'd_have_behavioral_prob',
    specify: 'if_yes_specify_behavioral_prob',
  },
  {
    sub_question_id: 'e_have_special_needs',
    specify: 'if_yes_specify_special_needs',
  },
  {
    sub_question_id: 'f_discipline_prob',
    specify: 'if_yes_specify_discipline_prob',
  },
  {
    sub_question_id: 'g_miss_school',
    specify: 'if_yes_specify_miss_school',
  },
  {
    sub_question_id: 'h_suspension_expulsion',
    specify: 'if_yes_specify_suspension_expulsion',
  },
  {
    sub_question_id: 'i_face_charges',
    specify: 'if_yes_specify_face_charges',
  },
  {
    sub_question_id: 'j_safety_issues',
    specify: 'if_yes_specify_safety_issues',
  },
  {
    sub_question_id: 'k_history_assault',
    specify: 'if_yes_specify_history_assault',
  },
]

const ChildCardRow = ({ answer, question, index, isPreview }) => {
  const { sub_question_id, specify } = question
  const answerIsNo = answer[sub_question_id]?.toUpperCase() === 'NO'

  return (
    <StyledRow key={index}>
      <InfiniteTableRowDescription
        question_id='child_issues_card'
        sub_question_id={sub_question_id}
        index={index}
        isPreview={isPreview} 
      />
      <StyledSelect
        question_id='child_issues_card'
        sub_question_id={sub_question_id}
        index={index}
        isPreview={isPreview} 
      />
      <StyledText
        question_id='child_issues_card'
        sub_question_id={specify}
        isDisabled={!answer[sub_question_id] || answerIsNo}
        hideLabel={false}
        index={index}
        shouldDecode={true}
        isPreview={isPreview} 
      />
    </StyledRow>
  )
}

// Selectors and createSelectors
const dataSelector = (state, question_id) => {
  return state.interview[question_id]
}

const dataPreviewSelector = (state, question_id) => {
  return state.preview[question_id]
}

// createSelector for getting data for the form/table.
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? dataPreviewSelector : dataSelector), data => ({
    answers: data.answer || [],
    question_id: data.question_id,
    description: data.description,
  }))
}

// Creates the household member dropdown selections based
// on what household members are in state
const createHouseholdMemberDropdownSelector = (isPreview) => {
  return createSelector((isPreview ? dataPreviewSelector : dataSelector), data => {
    if (!data.answer) return []
    return data?.answer?.map((answer, index) => {
      return {
        order: index + 1,
        optionId: answer.id,
        optionLabel: answer.household_name,
      }
    })
  })
}

const ChildCareAndParentingChildrensIssues = React.memo(({ isPreview }) => {
  const dispatch = useDispatch()
  const dataSelector = createDataSelector(isPreview)
  const householdMemberDropdownSelector = createHouseholdMemberDropdownSelector(isPreview)
  const householdMemberDropdownOptions = useSelector(state =>
    householdMemberDropdownSelector(state, 'household_members_table')
  )
  const { answers, description, question_id } = useSelector(state =>
    dataSelector(state, 'child_issues_card')
  )
  const { roles } = useSelector(state => isPreview ? {} : state.user)

  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )

  const handleAddRow = () => {
    dispatch(
      isPreview
      ? capturePreviewFormData({ question_id, answer: answers.concat({}) })
      : captureFormData({ question_id, answer: answers.concat({}) })
    )
  }

  const handleDelete = idx => {
    // Clone the current answers
    const currentAnswers = cloneDeep(answers)
    // Filter out the desired answer based on id
    const newAnswers = currentAnswers.filter((_, index) => index !== idx)

    dispatch(
      isPreview
      ? capturePreviewFormData({ question_id, answer: newAnswers })
      : captureFormData({ question_id, answer: newAnswers })
    )
  }

  return (
    <>
      <QuestionnaireHeader
        subdomain={`Children's Issues`}
        domain='Child Care and Parenting'
      />
      <StyledSubHeader variant='h6'>{description}</StyledSubHeader>
      <form>
        {answers &&
          answers.map((answer, cardIndex) => {
            return (
              <StyledCard key={`child-issues-card-${cardIndex}`}>
                <CardTitle
                  title={<Typography variant='body1'>Child Issues</Typography>}
                />
                <CardContent>
                  <NameRelationshipContainer>
                    <StyledDropdown
                      noTable
                      question_id='child_issues_card'
                      sub_question_id='id'
                      isDisabled={answer.chld_name !== undefined}
                      altMenuItems={householdMemberDropdownOptions}
                      hideLabel={false}
                      index={cardIndex}
                      isPreview={isPreview}
                    />
                    <InfiniteText
                      noTable
                      question_id='child_issues_card'
                      sub_question_id='relationship'
                      isDisabled={true}
                      index={cardIndex}
                      hideLabel={false}
                      shouldDecode={true}
                      isPreview={isPreview}
                    />
                  </NameRelationshipContainer>
                  <StyledTable>
                    <TableHead>
                      <StyledRow>
                        <StyledDescriptionHeader>
                          Does this child:
                        </StyledDescriptionHeader>
                        <StyledColumnHeader>Indicated</StyledColumnHeader>
                        <StyledColumnHeader>Specify</StyledColumnHeader>
                      </StyledRow>
                    </TableHead>
                    <TableBody>
                      {cardQuestions.map((question, index) => (
                        <ChildCardRow
                          question={question}
                          answer={answer}
                          index={cardIndex}
                          key={`childrens-issues-row-${index}`}
                          isPreview={isPreview}
                        />
                      ))}
                    </TableBody>
                  </StyledTable>
                </CardContent>
                <Can
                  roles={roles}
                  perform={isPreview ? 'preview:edit' : 'interview:edit'}
                  no={() => {
                    return (
                      <StyledContainer>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled
                          aria-label={`Delete button for ${answer?.chld_name}`}
                        >
                          Remove Item
                        </Button>
                      </StyledContainer>
                    )
                  }}
                  yes={() => {
                    return (
                      <StyledContainer>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={cancelledOrCompletedInterview}
                          aria-label={`Delete button for ${answer?.chld_name}`}
                          onClick={() => handleDelete(cardIndex)}
                        >
                          Remove Item
                        </Button>
                      </StyledContainer>
                    )
                  }}
                />
              </StyledCard>
            )
          })}

        <AddCardContainer>
          <Can
            roles={roles}
            perform={isPreview ? 'preview:edit' : 'interview:edit'}
            no={() => {
              return (
                <Button disabled={true} variant='contained' color='primary'>
                  Add
                </Button>
              )
            }}
            yes={() => {
              return (
                <Button
                  variant='contained'
                  disabled={
                    cancelledOrCompletedInterview ||
                    householdMemberDropdownOptions?.length === 0
                  }
                  color='primary'
                  onClick={() => {
                    if (cancelledOrCompletedInterview) return
                    handleAddRow()
                  }}
                >
                  Add
                </Button>
              )
            }}
          />
        </AddCardContainer>
      </form>
    </>
  )
})

export default ChildCareAndParentingChildrensIssues
