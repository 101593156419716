import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  const question_ids = [
    'how_transportation_to_places',
    'reliable_vehicle_wtw_activites',
    'transportation_backup_plan',
    'registered_own_vehicle',
  ]
  let uppercaseAnswers = []
  if (answer && question_ids.includes(question_id) && Array.isArray(answer)) {
    uppercaseAnswers = answer.map(ans => ans.toUpperCase())
  }
  if (
    question_id === 'how_transportation_to_places' &&
    !uppercaseAnswers.includes('OT')
  ) {
    effectCollector.add('how_get_around_specify', null)
  }

  if (
    question_id === 'how_transportation_to_places' &&
    !uppercaseAnswers.includes('PB')
  ) {
    effectCollector.add('public_transporation_convenient', null)
    effectCollector.add('public_transprtation_available', null)
    effectCollector.add('public_transportation_frequent', null)
    effectCollector.add('other_family_member_transportation_needs', null)
  }

  if (
    question_id === 'reliable_vehicle_wtw_activites' &&
    ((answer && answer.toUpperCase() === 'NO') || !answer)
  ) {
    effectCollector.add('what_vehicle_own', null)
  }

  if (
    question_id === 'transportation_backup_plan' &&
    ((answer && answer.toUpperCase() === 'NO') || !answer)
  ) {
    effectCollector.add('transportation_back_up_explain', null)
  }

  if (
    question_id === 'registered_own_vehicle' &&
    ((answer && answer.toUpperCase() === 'YS') || !answer)
  ) {
    effectCollector.add('transportation_method_explain', null)
  }

  return effectCollector.effects
}

export default effects;