import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import store from 'app/store.js'
import { resetNewUser, setGlobalIsDirty, setNavPath } from 'ducks/adminSlice'
import { resetEntities } from 'ducks/entitiesSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import Can from 'app/Can'
import NavLink from '@material-ui/core/Link'
import { colors } from 'app/theme'
import { getPreviewQuestionnaireContent } from '../../thunks/retrievePreviewData'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

// ADA: have to use ListItem and ListItemText
// to make navbar having a correct ul li structure
const StyledListItem = withStyles({
  root: {
    marginTtop: 0,
    maxWidth: '230px',
    marginLeft: '16px',
    fontWeight: 'normal',
  },
})(ListItem)

// ADA: Have to use the NavLink to override the inherent color of ListItemText
// and achieve same hover, focus effect as top nav bar
const StyledNavLink = styled(NavLink)`
  font-size: 14px;
  text-decoration: underline;
  :hover {
    color: ${colors.turquoise};
    cursor: pointer;
  }
  :focus {
    color: ${colors.turquoise};
    cursor: pointer;
  }
  :active {
    color: ${colors.turquoise};
    outline: 0;
  }
`
const StyledListHeadline = styled(ListItem)`
  margin-bottom: -3px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  cursor: auto;
`
const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const PrimaryButton = styled(Button)`
  && {
    margin: 5px;
    display: inline-block;
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`
const SecondaryButton = styled(Button)`
  && {
    margin: 5px;
    background-color: ${colors.turquoise};
    display: inline-block;
    color: ${colors.white};
  }
`

export default React.memo(() => {
  const history = useHistory()
  const { roles } = useSelector(state => state.user)
  const [showWarning, setWarning] = useState(false)
  const { 
    globalIsDirty, 
    navPath
  } = useSelector(state => state.admin)
  
  const handleExit = (path) => {
    if (!globalIsDirty) {
      handleClick(path)
    }
    else {
      store.dispatch(setNavPath(path))
      setWarning(true)
    }
  }

  const handleCancel = () => {
    setWarning(false)
  }

  const handleClick = path => {
    if (history.location.pathname !== path) {
    setWarning(false)
    store.dispatch(resetNewUser())
      store.dispatch(resetEntities())
      store.dispatch(removeAllValidationErrors())
      store.dispatch(setGlobalIsDirty(false))
      store.dispatch(setNavPath(null))
      history.push(path)
    }
  }

  const WarningDialog = props => {
    const { open, title, body, onClose, onCancel } = props
    const handleConfirm = () => {
      onClose()
    }
    const handleCancel = () => {
      onCancel()
    }
    return (
      <>
        <Dialog onCancel={handleCancel} onClose={handleConfirm} open={open}>
          <DialogTitle>{title}</DialogTitle>
          <StyledDialogContent>{body}</StyledDialogContent>
          <StyledDialogActions>
            <PrimaryButton variant='contained' onClick={() => handleClick(navPath)}>
              Leave
            </PrimaryButton>
            <SecondaryButton variant='contained' onClick={() => handleCancel()}>
              Cancel
            </SecondaryButton>
          </StyledDialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <Can
      roles={roles}
      perform='BRE:view'
      yes={() => {
        return (
          <>
            <List dense>
            <StyledListHeadline></StyledListHeadline>
            <StyledListItem>
              <ListItemText
                data-testid="BRE-view_pending_changes"
                onClick={() => {
                  handleExit('/bre')
                }}
              >
                <StyledNavLink tabIndex='0'>
                  View Pending Changes
                </StyledNavLink>
              </ListItemText>
            </StyledListItem>
            <StyledListHeadline>Rules</StyledListHeadline>
            <StyledListItem>
              <ListItemText
                data-testid="BRE-manage-rules"
                onClick={() => {
                  handleExit('/bre_rule_list')
                }}
              >
                <StyledNavLink tabIndex='0'>
                  Manage/Edit Rules
                </StyledNavLink>
              </ListItemText>
            </StyledListItem>
            <StyledListHeadline>Questions</StyledListHeadline>
            <StyledListItem>
              <ListItemText
                data-testid="BRE-manage-questions"
                onClick={() => {
                  handleExit('/bre_question_list')
                }}
              >
                <StyledNavLink tabIndex='0'>
                  Manage/Edit Questions
                </StyledNavLink>
              </ListItemText>
            </StyledListItem>
            <StyledListHeadline>Previews</StyledListHeadline>
            <StyledListItem>
              <ListItemText
                data-testid="BRE-preview_questionnaire"
                onClick={() => {
                  store.dispatch(getPreviewQuestionnaireContent()).then(() => {
                    handleExit('/preview/demographics_general_information')
                  })
                }}
              >
                <StyledNavLink tabIndex='0'>
                  Preview Questionnaire
                </StyledNavLink>
              </ListItemText>
            </StyledListItem>
            </List>
            <WarningDialog
              open={showWarning}
              onCancel={handleCancel}
              body='You have unsaved changes on this page that will be lost if you
              continue.'

            />
          </>
        )
      }}
    />
  )
})
