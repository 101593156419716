import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  existingCase: null,
  existingCases: [],
  newCase: null,
  existingCaseNumber: null,
  newCaseNumber: null,
  newCaseCounty: null,
  prepopulateInterview: null,
  hasExistingCase: null,
  canCreateInterview: false,
  interviewLoading: false,
}

const newInterviewSlice = createSlice({
  name: 'newInterview',
  initialState,
  reducers: {
    setExistingCase: (state, action) => {
      state.existingCase = action.payload
    },
    setExistingCases: (state, action) => {
      state.existingCases = action.payload
    },
    setNewCase: (state, action) => {
      state.newCase = action.payload
    },
    setExistingCaseNumber: (state, action) => {
      state.existingCaseNumber = action.payload
    },
    setNewCaseNumber: (state, action) => {
      state.newCaseNumber = action.payload
    },
    setNewCaseCounty: (state, action) => {
      state.newCaseCounty = action.payload
    },
    setPrepopulateInterview: (state, action) => {
      state.prepopulateInterview = action.payload
    },
    setHasExistingCase: (state, action) => {
      state.hasExistingCase = action.payload
    },
    setInterviewLoading: (state, action) => {
      state.interviewLoading = action.payload
    },
    setCanCreateInterview: (state, action) => {
      state.canCreateInterview = action.payload
    },
    clearNewInterviewSlice: (state, action) => initialState,
  },
})

export const {
  setExistingCase,
  setExistingCases,
  setNewCase,
  setExistingCaseNumber,
  setNewCaseNumber,
  setNewCaseCounty,
  setPrepopulateInterview,
  setHasExistingCase,
  setInterviewLoading,
  setCanCreateInterview,
  clearNewInterviewSlice,
} = newInterviewSlice.actions

export default newInterviewSlice.reducer
