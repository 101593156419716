import checkForEmpty from 'side-effects/checkForEmpty'

// Basic class for collecting side-effects into an array
// in the appropriate format
export default class EffectCollector {
  constructor() {
    this.effects = []
  }

  add = (question_id, answer) => {
    this.effects.push({
      question_id: question_id,
      answer: checkForEmpty(answer),
    })
  }
}
