import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import find from 'lodash/find'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { createSelector } from '@reduxjs/toolkit'
import { colors } from 'app/theme'
import NavIcon from 'components/navigation/NavIcon'
import Subdomain from 'components/navigation/Subdomain'
import { navigateToPage } from 'ducks/navigationSlice'
import { navigateWithoutSave } from 'thunks/navigateWithoutSave'
import { withStyles } from '@material-ui/core/styles'
import Can from 'app/Can'

// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledListItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },

    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(ListItem)

const visitedSubdomainsSelector = (state, subdomains) => state.interviewProgress
const subdomainsSelector = (state, subdomains) => subdomains

const DomainIcon = styled(ListItemIcon)`
  && {
    font-size: 16px;
    min-width: 32px;
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      font-weight: 700;
      color: ${p => p.active && colors.turquoise};
    }
  }
`

const domainStatusSelector = createSelector(
  [visitedSubdomainsSelector, subdomainsSelector],
  (visitedSubdomains, subdomains) => {
    const numComplete = subdomains.reduce((acc, current) => {
      if (visitedSubdomains[current.code]?.visited === 1) {
        return (acc += 1)
      }
      return acc
    }, 0)

    if (numComplete === subdomains.length) {
      return 'complete'
    } else if (numComplete > 0) {
      return 'in progress'
    }

    return ''
  }
)

const Domain = React.memo(({ name, subdomains, domainCode, isPreview }) => {
  const currentInterviewPage = useSelector(state => state.currentInterviewPage)
  const history = useHistory()
  const dispatch = useDispatch()
  const validationErrors = useSelector(state => state.validationErrors)
  const roles = useSelector(state => state.user.roles)

  const shouldBeOpen = find(subdomains, { code: currentInterviewPage })
    ? true
    : false

  const domainStatus = useSelector(state =>
    domainStatusSelector(state, subdomains)
  )
  const [open, setOpen] = React.useState(false)
  useEffect(() => setOpen(shouldBeOpen), [shouldBeOpen])

  const handleClick = () => {
    
    if (!open) {
      // If opening a domain, navigate to the first subdomain in that domain
      const firstSubdomain = subdomains.find(subdomain => {
        return subdomain.order === 1
      })
      dispatch(navigateToPage(firstSubdomain.code, history, isPreview))
    }

    // Do not change nav UI if the page has validation errors
    if (isEmpty(validationErrors)) {
      setOpen(!open)
    }
  }

  const subdomainElements = subdomains.map(subdomain => {
    return (
      <Subdomain
        code={subdomain.code}
        name={subdomain.name}
        key={subdomain.code}
        active={currentInterviewPage === subdomain.code}
        isPreview={isPreview}
      />
    )
  })

  const handleClickReadOnly = () => {
    
    if (!open) {
      // If opening a domain, navigate to the first subdomain in that domain
      const firstSubdomain = subdomains.find(subdomain => {
        return subdomain.order === 1
      })
      dispatch(navigateWithoutSave(firstSubdomain.code, history, isPreview))
    }

    setOpen(!open)
  }

  return (
    <Can
      perform={isPreview ? 'BRE:view' : 'interview:edit'}
      roles={roles}
      yes={() => {
        return (
          <>
            <StyledListItem
              data-testid={`Domain-item-${domainCode}`}
              id={`leftNavNode-${domainCode}`}
              disableRipple
              disableTouchRipple
              button
              dense
              onClick={handleClick}
              open={open}
              divider={open}
            >
              <DomainIcon>
                <DomainIcon>
                  <NavIcon status={domainStatus} />
                </DomainIcon>
              </DomainIcon>
              <StyledListItemText primary={name} active={open ? 1 : 0} />
              {open ? (
                <ExpandLess fontSize='small' />
              ) : (
                <ExpandMore fontSize='small' />
              )}
            </StyledListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {subdomainElements}
              </List>
            </Collapse>
          </>
        )
      }}
      no={() => {
        return (
          <>
            <StyledListItem
              data-testid={`Domain-item-readonly-${domainCode}`}
              id={`leftNavNode-${domainCode}`}
              disableRipple
              disableTouchRipple
              button
              dense
              onClick={handleClickReadOnly}
              open={open}
              divider={open}
            >
              <DomainIcon>
                <DomainIcon>
                  <NavIcon status={domainStatus} />
                </DomainIcon>
              </DomainIcon>
              <StyledListItemText primary={name} active={open ? 1 : 0} />
              {open ? (
                <ExpandLess fontSize='small' />
              ) : (
                <ExpandMore fontSize='small' />
              )}
            </StyledListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {subdomainElements}
              </List>
            </Collapse>
          </>
        )
      }}
    />
  )
})

export default Domain
