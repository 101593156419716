import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../app/theme'

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const Container = styled.div`
  margin: 10px 0;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
  }
`
const PreviewQuestionnaire = () => {
  return (
    <>
      <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle variant='h4'>Preview Questionnaire</SectionTitle>
        </Grid>
          <Container>
          </Container>
      </Grid>
    </>
  )
}

export default PreviewQuestionnaire
