import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TableCell from '@material-ui/core/TableCell'
import AddIcon from '@material-ui/icons/Add'
import { createActionPlan } from 'thunks/createActionPlan'
import IconButton from '@material-ui/core/IconButton'

import { updateActionPlan } from 'ducks/unsavedActionPlansSlice'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

const StyledTableCell = styled(TableCell)`
  padding-left: '30px';
`

const AddActionPlanButton = styled(IconButton)`
  margin-left: 6px;
`

const SelectContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const StyledRadio = styled(Radio)`
  padding-right: 0px;
`

const RecommendationSelectCell = React.memo(
  ({ actionPlan, property, id, label, firstActionPlan, disableSelect, isPreview }) => {
    const dispatch = useDispatch()
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )

    // Collect change and dispatch to Redux
    const handleChange = e => {
      if (e.target.value === '') return
      storeChange(e.target.value === 'yes' ? 1 : 0)
    }

    // Clicking a previously selected radio won't trigger onChange, so we watch for onClick as well
    const handleClick = (e, oldValue) => {
      // When user toggles a radio button, clear the value
      // e.detail checks for a double click, in IE e.detail is always zero on click
      // so we only check for double click in modern browsers
      if (e.target.value === oldValue && e.detail <= 1) {
        storeChange(null)
      }
    }
    const storeChange = newValue => {
      if (cancelledOrCompletedInterview) return
      let newActionPlan = { ...actionPlan }
      newActionPlan[property] = newValue
      dispatch(updateActionPlan(newActionPlan))
    }

    const handleCreate = actionPlan => {
      if (cancelledOrCompletedInterview) return
      dispatch(createActionPlan(actionPlan.recommendationID))
    }

    // ADA requires enter key accessibility
    const handleKeyPress = (e, oldValue) => {
      const keyCode = e.which || e.keyCode
      const enterKeyCode = 13
      const { value } = e.target
      if (keyCode === enterKeyCode) {
        storeChange(oldValue === value ? null : value)
      }
    }

    let propertyValue = ''
    if (actionPlan) {
      switch (actionPlan[property]) {
        case 1:
        case '1':
          propertyValue = 'yes'
          break
        case 0:
        case '0':
          propertyValue = 'no'
          break
        default:
          propertyValue = ''
          break
      }
    }

    return (
      <StyledTableCell
        style={!firstActionPlan ? { paddingBottom: '45px' } : {}}
      >
        <Can
          roles={roles}
          perform={isPreview ? 'BRE:view' : 'interview:edit'}
          no={() => {
            return (
              <>
                <SelectContainer>
                  <RadioGroup
                    row
                    name={label}
                    value={propertyValue}
                    aria-label={label}
                  >
                    <FormControlLabel
                      control={
                        <StyledRadio
                          inputProps={{ 'aria-label': 'Option Yes' }}
                        />
                      }
                      disabled={true}
                      value={'yes'}
                      label='Yes'
                      name={id}
                    />
                    <FormControlLabel
                      control={
                        <StyledRadio
                          inputProps={{ 'aria-label': 'Option No' }}
                        />
                      }
                      disabled={true}
                      value={'no'}
                      label='No'
                      name={id}
                    />
                  </RadioGroup>
                </SelectContainer>
              </>
            )
          }}
          yes={() => {
            return (
              <>
                <SelectContainer>
                  <RadioGroup
                    row
                    name={label}
                    value={propertyValue}
                    onChange={handleChange}
                    onClick={e => handleClick(e, propertyValue)}
                    onKeyPress={e => handleKeyPress(e, propertyValue)}
                    aria-label={label}
                  >
                    <FormControlLabel
                      control={
                        <StyledRadio
                          inputProps={{ 'aria-label': 'Option Yes' }}
                        />
                      }
                      disabled={cancelledOrCompletedInterview || disableSelect || isPreview}
                      value={'yes'}
                      label='Yes'
                      name={id}
                    />
                    <FormControlLabel
                      control={
                        <StyledRadio
                          inputProps={{ 'aria-label': 'Option No' }}
                        />
                      }
                      disabled={cancelledOrCompletedInterview || disableSelect || isPreview}
                      value={'no'}
                      label='No'
                      name={id}
                    />
                  </RadioGroup>
                </SelectContainer>
              </>
            )
          }}
        />

        {firstActionPlan ? (
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <AddActionPlanButton
                  disabled={true}
                  size='small'
                  onClick={e => handleCreate(actionPlan)}
                  aria-label='add action plan'
                >
                  <AddIcon />
                </AddActionPlanButton>
              )
            }}
            yes={() => {
              return (
                <AddActionPlanButton
                  disabled={cancelledOrCompletedInterview || isPreview}
                  size='small'
                  onClick={e => handleCreate(actionPlan)}
                  aria-label='add action plan'
                >
                  <AddIcon />
                </AddActionPlanButton>
              )
            }}
          />
        ) : null}
      </StyledTableCell>
    )
  }
)

export default RecommendationSelectCell
