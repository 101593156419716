import React from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const emotionallyChallengedSelector = state =>
  state.interview.emotional_mental_health_challenge_other.answer

const previewEmotionallyChallengedSelector = state =>
  state.preview.emotional_mental_health_challenge_other.answer

const selectEmotionalChallenge = createSelector(
  emotionallyChallengedSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)
const selectPreviewEmotionalChallenge = createSelector(
  previewEmotionallyChallengedSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const WrapUp = React.memo(({ isPreview }) => {
  const isEmotionallyChallenged = useSelector(isPreview ? selectPreviewEmotionalChallenge : selectEmotionalChallenge)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Wrap Up'
          domain='Emotional and Mental Health'
        />
        <form>
          <Grid item xs={12}>
            <Select question_id='emotional_mental_health_challenge_other' isPreview={isPreview} />
          </Grid>
          <Grid item xs={12}>
            <Text
              question_id='wrap_up_specify'
              multiline
              rows='4'
              isDisabled={isEmotionallyChallenged}
              shouldDecode={true}
              subquestion
              isPreview={isPreview}
            />
          </Grid>
          <Grid item xs={12}>
            <Select question_id='referral_for_mental_health' isPreview={isPreview} />
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
})
export default WrapUp
