import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import Text from 'components/general/Text'
import CheckboxGrid from 'components/general/CheckboxGrid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const currentHousingSituationSelector = state =>
  state.interview.current_housing_situation.answer

const previewCurrentHousingSituationSelector = state =>
  state.preview.current_housing_situation.answer

const selectCurrentHousingSituation = createSelector(
  currentHousingSituationSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const selectPreviewCurrentHousingSituation = createSelector(
  previewCurrentHousingSituationSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)


const CurrentHousing = React.memo(({ isPreview }) => {
  const isOther = useSelector(isPreview ? selectPreviewCurrentHousingSituation : selectCurrentHousingSituation)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader subdomain='Current Housing' domain='Housing' />
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='current_housing_situation' isPreview={isPreview} />
          <Text
            subquestion
            question_id='other_housing_challenge'
            isDisabled={!isOther}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default CurrentHousing
