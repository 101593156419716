import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'emotional_mental_health_challenge_other' &&
    (answer === 'NO' || !answer)
  ) {
    effectCollector.add('wrap_up_specify', '')
  }
  return effectCollector.effects
}

export default effects;
