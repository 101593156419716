import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import isEmpty from 'lodash/isEmpty'
import { openDialog } from 'ducks/dialogSlice'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'
import { deleteScheduledChange } from  './deleteSheduledChange'
import { setPendingChangesList } from 'ducks/pendingChangesSlice'
import { clearPendingChanges } from 'ducks/pendingChangesSlice'
import { refreshUpcomingChangeDate } from './refreshUpcomingChangeDate'
import { batch } from 'react-redux'


const SCHEDULE_CHANGES = gql`
  mutation scheduleChanges($changes: [ChangeInformation]!) {
    scheduleChanges(changes: $changes)
  }
`
const GET_PENDING_CHANGES = gql`
  query pendingChanges {
  pendingChanges {
    id
    changeType
    recordId
    changeDetails
    publishDate
    createdBy
    createdAt
    createUser
    rule
  }
}
`
export const savePendingChanges = () => {
  return async (dispatch, getState) => {
    // Gather unsaved changes from state
    const {pendingChanges, currentPendingChanges} = getState().pendingChanges
    // Don't attempt to save without changes
    if (isEmpty(pendingChanges)) {
      return
    }
  const selectedEntity = pendingChanges[0].recordId
  const currentlyScheduledChanges= currentPendingChanges.filter(x => x.recordId === selectedEntity)

  dispatch(clearPendingChanges())
    
    try {
      dispatch(setLoadingTimestamp(true))
      await client.mutate({
        mutation: SCHEDULE_CHANGES,
        variables: {
          changes: pendingChanges,
      },
     })
     currentlyScheduledChanges.forEach(currentScheduledChange => {
        const existingChange = pendingChanges.find(x => x.recordId === currentScheduledChange.recordId 
              && x.changeType === currentScheduledChange.changeType
              && Object.keys(x.changeDetails)[0].toUpperCase() === Object.keys(currentScheduledChange.changeDetails)[0].toUpperCase())

        if (existingChange) {
          dispatch(deleteScheduledChange(currentScheduledChange))
        }
      })

      const { data } = await client.query ({
        query: GET_PENDING_CHANGES,
        fetchPolicy: 'network-only'
      })

      batch(() => {
        dispatch(setPendingChangesList(data.pendingChanges))
        dispatch(refreshUpcomingChangeDate())
      })

    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(setLoadingTimestamp(false))
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
