import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'

export const AdminRequiredFieldsDialogContent = () => {
  return null
}
export const AdminRequiredFieldsDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeDialog())

  return (
    <Button onClick={handleClose} variant='contained' color='primary'>
      OK
    </Button>
  )
}
