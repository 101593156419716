import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deepClone from 'lodash/cloneDeep'
import { createSelector } from '@reduxjs/toolkit'
import styled, { css } from 'styled-components'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import CheckboxGrid from 'components/general/CheckboxGrid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteCheckboxGrid from 'components/table/InfiniteCheckboxGrid'
import InfiniteSelect from 'components/table/InfiniteSelect'
import InfiniteDatePicker from 'components/table/InfiniteDatePicker'
import ControlDescription from 'components/general/ControlDescription'

import { colors } from 'app/theme'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'

import Indicator from 'components/general/Indicator'
import Can from 'app/Can'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'

import { didNotProvideReasonConditionalFields } from 'side-effects/employmentJobHistoryEffects'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

const noWorkHistoryStyle = { marginLeft: '334px', marginBottom: '-29px' }

// Styled Components
const StyledLink = styled.div`
  && {
    color: ${colors.black};
    text-decoration: underline;
    cursor: pointer;
  }
`
const sharedStyle = css`
  width: 100%;
  margin: 0 8px;
`
const StyledDatePicker = styled(InfiniteDatePicker)`
  && {
    ${sharedStyle}
  }
`
const StyledDropdown = styled(InfiniteDropdown)`
  && {
    ${sharedStyle}
  }
`
const StyledSelect = styled(InfiniteSelect)`
  && {
    ${sharedStyle}
  }
`
const StyledCheckboxGrid = styled(InfiniteCheckboxGrid)`
  && {
    ${sharedStyle}
  }
`
const StyledText = styled(InfiniteText)`
  && {
    ${sharedStyle}
  }
`
const StyledTypography = styled(Typography)`
  && {
    color: ${p => p.disabled && `${colors.grey}`};
    padding-top: 0px;
    margin-top: 20px;
    ${sharedStyle}
  }
`
const StyledControlDescription = styled(ControlDescription)`
  && {
    ${sharedStyle}
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: ${p => (p.top ? 'flex-start' : 'center')};
  padding: 8px;
`

const CardTitle = styled(CardHeader)`
  && {
    background: ${colors.lightgrey};
  }
`
const StyledTable = styled(Table)`
  && {
    margin: 15px 0;
  }
`
const StyledRow = styled(TableRow)`
  && {
    height: 54px;
  }
`
const RemoveRowContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
  }
`
const AddRowContainer = styled.div`
  margin: 10px 0;
`

const jobHistoryTableIndicatorSelector = state =>
  state.interview.job_history_table.indicator

const previewJobHistoryTableIndicatorSelector = state =>
  state.preview.job_history_table.indicator

const selectJobHistoryTableIndicator = createSelector(
  jobHistoryTableIndicatorSelector,
  indicator => indicator
)

const selectPreviewJobHistoryTableIndicator = createSelector(
  previewJobHistoryTableIndicatorSelector,
  indicator => indicator
)

// Selectors and createSelectors
const dataSelector = (state, question_id) => state.interview[question_id]
const previewDataSelector = (state, question_id) => state.preview[question_id]

// createSelector for getting data for the form/table.
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewDataSelector : dataSelector), data => ({
    answer: data.answer ? data.answer : [],
    question_id: data.question_id,
    description: data.description,
    inputsDisabled: data.answer ? false : true,
  }))
}

// createSelector for checking if the user has selected no work history.
const createWorkHistorySelector = (isPreview) => {
  return createSelector((isPreview ? previewDataSelector : dataSelector), data => {
    return {
      formDisabled: data.answer ? data.answer.includes('NW') : false,
    }
  })
}

// createSelector for checking if the user has selected the "Other" option
// and needs to specify.
const createOtherSelector = (index, isPreview) => {
  return createSelector((isPreview ? previewDataSelector : dataSelector), data => {
    const specificReasonDisabled =
      index >= 0 &&
      data.answer &&
      didNotProvideReasonConditionalFields
        .map(k => data.answer[index]?.[k])
        .filter(Boolean)
        .every(v => v.length === 0)

    return {
      isCurrentJob:
        index >= 0 && data.answer
          ? data.answer[index]?.current_job?.includes('CJ')
          : false,
      isDidNotSpecifyReasonSelected:
        index >= 0 && data.answer
          ? data.answer[index]?.did_not_provide_reason_2?.includes('NR')
          : false,
      isOtherSelected:
        index >= 0 && data.answer
          ? data.answer[index]?.wages_job_end_2?.includes('OT')
          : false,
      isBenefitsReceivedSelected:
        index >= 0 && data.answer
          ? data.answer[index]?.benefits_received_job
              ?.toUpperCase()
              .includes('BN')
          : false,
      specificReasonDisabled,
    }
  })
}

// Summary table of job history.
const DisplayTable = ({ answer, jobIndex, setJobIndex, isPreview }) => {
  // Row component for display table.
  const displayRows =
    answer &&
    answer.map((rowData, index) => {
      const { begin_date_job, end_date_job, employer_job_history } = rowData
      return (
        <StyledRow
          selected={index === jobIndex}
          key={`Display Table Row ${index}`}
          onClick={() => setJobIndex(index)}
        >
          <TableCell>
            <StyledLink>{decodeHtml(employer_job_history)}</StyledLink>
          </TableCell>
          <TableCell>{begin_date_job}</TableCell>
          <TableCell>{end_date_job}</TableCell>
        </StyledRow>
      )
    })
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell>Employer</TableCell>
          <TableCell>Begin Date</TableCell>
          <TableCell>End Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{displayRows}</TableBody>
    </StyledTable>
  )
}

const EmploymentJobHistory = React.memo(({ isPreview }) => {
  const indicator = useSelector(isPreview ? selectPreviewJobHistoryTableIndicator : selectJobHistoryTableIndicator)
  // Dispatch and createSelectors
  const dispatch = useDispatch()
  const dataSelector = createDataSelector(isPreview)
  const noWorkHistorySelector = createWorkHistorySelector(isPreview)

  // Selectors for form disabling and data.
  const { formDisabled } = useSelector(state =>
    noWorkHistorySelector(state, 'no_work_history')
  )
  const {
    question_id,
    answer,
    description,
    inputsDisabled,
  } = useSelector(state => dataSelector(state, 'job_history_table'))

  // Set the index of the job the user has selected.
  const [jobSelected, setJobSelected] = useState(
    answer.length === 0 ? null : answer.length - 1
  )

  // If all jobs in job history have been removed, revert the index to null.
  useEffect(() => {
    answer.length === 0 && setJobSelected(null)
  }, [answer])

  // Selector for if "Other" is selected in the form.
  const otherSelector = createOtherSelector(jobSelected, isPreview)
  const { roles } = useSelector(state => state.user)
  const {
    isCurrentJob,
    isDidNotSpecifyReasonSelected,
    isOtherSelected,
    isBenefitsReceivedSelected,
    specificReasonDisabled,
  } = useSelector(state => otherSelector(state, 'job_history_table'))
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )

  // Handlers for adding and removing rows
  const handleRemoveRow = () => {
    // Clone answer, splice out
    const updatedAnswer = deepClone(answer)
    updatedAnswer.splice(jobSelected, 1)

    // Conditions
    const isAnswerEmpty = updatedAnswer.length === 0
    const inValidIndex = jobSelected - 1 === -1

    // Set job index appropriately.
    if (isAnswerEmpty) {
      setJobSelected(null)
    } else if (inValidIndex) {
      setJobSelected(0)
    } else {
      setJobSelected(jobSelected - 1)
    }
    // Dispatch the action.
    dispatch(
      isPreview
      ? capturePreviewFormData({ question_id, answer: updatedAnswer })
      : captureFormData({ question_id, answer: updatedAnswer })
    )
  }
  const handleAddRow = () => {
    dispatch(
      isPreview
      ? capturePreviewFormData({ question_id, answer: answer.concat({}) })
      : captureFormData({ question_id, answer: answer.concat({}) })
    )
    jobSelected === null ? setJobSelected(0) : setJobSelected(answer.length)
  }

  return (
    <>
      <QuestionnaireHeader subdomain='Job History' domain='Employment' />
      <Typography variant='body2'>
        Instructions: Collect information on client's most recent jobs first.
        Indicate if current job, omit the end date. Not all fields are required
        - skip questions that are not applicable.
      </Typography>
      <CheckboxGrid
        question_id='no_work_history'
        sawsIndicator={true}
        indicatorStyle={noWorkHistoryStyle}
        isPreview={isPreview}
      />
      <DisplayTable
        answer={answer}
        jobIndex={jobSelected}
        setJobIndex={setJobSelected}
        isPreview={isPreview}
      />
      <Typography variant='h6'>
        I would like information about your work history, starting with your
        current or most recent job.
      </Typography>
      <Typography variant='h5'>Job History Details</Typography>
      <AddRowContainer>
        <Can
          roles={roles}
          perform={isPreview ? 'BRE:view' : 'interview:edit'}
          no={() => {
            return (
              <Button variant='contained' color='primary' disabled={true}>
                Add Item
              </Button>
            )
          }}
          yes={() => {
            return (
              <Button
                variant='contained'
                color='primary'
                disabled={cancelledOrCompletedInterview || formDisabled}
                onClick={() => {
                  if (cancelledOrCompletedInterview) return
                  handleAddRow()
                }}
              >
                Add Item
              </Button>
            )
          }}
        />

        <Indicator indicator={indicator} />
      </AddRowContainer>
      <form>
        <Card>
          <CardTitle
            title={<Typography variant='body1'>{description}</Typography>}
          />
          <CardContent>
            <Row>
              <StyledCheckboxGrid
                isDisabled={formDisabled || inputsDisabled}
                noTable
                question_id='job_history_table'
                sub_question_id='current_job'
                index={jobSelected}
                hideOptionLabelDescription={false}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='employer_job_history'
                index={jobSelected}
                shouldDecode={true}
                isPreview={isPreview}
              />
              <StyledDatePicker
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='begin_date_job'
                datePickerOptions={{
                  disableFuture: true,
                  format: 'MM/yyyy',
                  views: ['month'],
                }}
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledDatePicker
                isDisabled={formDisabled || inputsDisabled || isCurrentJob}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='end_date_job'
                datePickerOptions={{
                  disableFuture: true,
                  format: 'MM/yyyy',
                  views: ['month'],
                }}
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='job_title'
                index={jobSelected}
                shouldDecode={true}
                isPreview={isPreview}
              />
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='type_of_work_job'
                index={jobSelected}
                shouldDecode={true}
                isPreview={isPreview}
              />
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='special_skills_job'
                index={jobSelected}
                shouldDecode={true}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='contact_person_job'
                index={jobSelected}
                shouldDecode={true}
                isPreview={isPreview}
              />
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='contact_phone_job'
                index={jobSelected}
                type='phone'
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledDropdown
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='shift_usually_worked'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='hrs_per_week'
                type='number'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                type='currency'
                question_id='job_history_table'
                sub_question_id='pay_amount'
                index={jobSelected}
                inputVariant='outlined'
                isPreview={isPreview}
              />
              <StyledDropdown
                isDisabled={formDisabled || inputsDisabled}
                hideLabel={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='pay_frequency'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledText
                isDisabled={formDisabled || inputsDisabled}
                noTable
                width='50%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='employer_address'
                index={jobSelected}
                minRows={5}
                multiline
                shouldDecode={true}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledSelect
                isDisabled={formDisabled || inputsDisabled}
                hideDescription={false}
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='benefits_received_job'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled || inputsDisabled || !isBenefitsReceivedSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='benefits_received_job_details'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row>
              <Can
                roles={roles}
                perform={isPreview ? 'BRE:view' : 'interview:edit'}
                no={() => {
                  return (
                    <StyledTypography disabled={true} variant='body2'>
                      Instructions: Do not read list aloud. Check all that
                      apply. If customer states they quit or were fired, ask for
                      the reason and select the appropriate response below.
                    </StyledTypography>
                  )
                }}
                yes={() => {
                  return (
                    <StyledTypography
                      disabled={
                        cancelledOrCompletedInterview ||
                        formDisabled ||
                        inputsDisabled
                      }
                      variant='body2'
                    >
                      Instructions: Do not read list aloud. Check all that
                      apply. If customer states they quit or were fired, ask for
                      the reason and select the appropriate response below.
                    </StyledTypography>
                  )
                }}
              />
            </Row>
            <Row>
              <Can
                roles={roles}
                perform={isPreview ? 'BRE:view' : 'interview:edit'}
                no={() => {
                  return (
                    <StyledControlDescription disabled={true} variant='body1'>
                      Why did the job end?
                    </StyledControlDescription>
                  )
                }}
                yes={() => {
                  return (
                    <StyledControlDescription
                      disabled={
                        cancelledOrCompletedInterview ||
                        formDisabled ||
                        inputsDisabled
                      }
                      variant='body1'
                    >
                      Why did the job end?
                    </StyledControlDescription>
                  )
                }}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled || inputsDisabled || !specificReasonDisabled
                }
                noTable
                width='100%'
                question_id='job_history_table'
                sub_question_id='did_not_provide_reason_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='employer_initiated_termination_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='job_opportunity_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='lack_satisfaction_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='compensation_issue_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                hideDescription={false}
                question_id='job_history_table'
                sub_question_id='worksite_behavior_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='experience_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='health_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='household_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='child_care_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='100%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='housing_transportation_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledCheckboxGrid
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='33%'
                question_id='job_history_table'
                hideDescription={false}
                sub_question_id='wages_job_end_2'
                index={jobSelected}
                isPreview={isPreview}
              />
            </Row>
            <Row top>
              <StyledText
                isDisabled={
                  formDisabled ||
                  inputsDisabled ||
                  !isOtherSelected ||
                  isDidNotSpecifyReasonSelected
                }
                noTable
                width='33%'
                disableLabel={true}
                question_id='job_history_table'
                sub_question_id='job_history_other_job_end'
                index={jobSelected}
                hideLabel={false}
                shouldDecode={true}
                isPreview={isPreview}
              />
            </Row>
            <StyledDropdown
              isDisabled={formDisabled || inputsDisabled}
              noTable
              hideDescription={false}
              question_id='job_history_table'
              sub_question_id='employer_rehire'
              index={jobSelected}
              isPreview={isPreview}
            />

            <RemoveRowContainer>
              <Can
                roles={roles}
                perform={isPreview ? 'BRE:view' : 'interview:edit'}
                no={() => {
                  return (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={true}
                      onClick={handleRemoveRow}
                    >
                      Remove Item
                    </Button>
                  )
                }}
                yes={() => {
                  return (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={
                        cancelledOrCompletedInterview ||
                        formDisabled ||
                        inputsDisabled
                      }
                      onClick={() => {
                        if (cancelledOrCompletedInterview) return
                        handleRemoveRow()
                      }}
                    >
                      Remove Item
                    </Button>
                  )
                }}
              />
            </RemoveRowContainer>
          </CardContent>
        </Card>
      </form>
    </>
  )
})

export default EmploymentJobHistory
