import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const LearningNeedsIndicatorQuestions = React.memo(({ isPreview }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Learning Needs Instructions'
          domain='Education'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography display='block' variant='h5'>
          Preamble
        </Typography>
        <br />

        <Typography display='block' component='span'>
          Instructions to worker: Before beginning the learning needs screening,
          give the client form WTW 19 (Learning Needs Screening – Client Copy)
          so he or she can follow along and read the preamble and questions
          silently as you read them aloud.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Link to Learning Needs Screening – Client Copy form (WTW 19):
          <br />
          <a
            href={process.env.REACT_APP_WTW_19_FORM}
            target='_blank'
            rel='noopener noreferrer'
          >
            {process.env.REACT_APP_WTW_19_FORM}
          </a>
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Read to client: I am going to ask you questions about your school
          experiences and your health. Your answers will help me figure out
          what, if anything, is getting in your way of training and working.
          Your answers will also help me develop your Welfare-to-Work plan and
          help me figure out what services you may need to be successfully
          employed. It is very important that you answer these questions so that
          I can determine the right kind of Welfare-to-Work activities for you,
          and to get you the help and services you may need to succeed. These
          questions are not intended to determine the existence of a learning
          disability. They are only the first step in the evaluation process.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Please keep in mind that most people with learning disabilities are
          intelligent and many are gifted. Individuals with a learning
          disability may have difficulty with the following:
        </Typography>
        <br />
        <Typography display='block' component='ul'>
          <li>Reading</li>
          <li>Listening</li>
          <li>Understanding directions</li>
          <li>Writing</li>
          <li>Spelling</li>
          <li>Math</li>
          <li>Organizing things</li>
          <li>Getting along with others</li>
          <li>Expressing ideas out loud</li>
          <li>Paying attention</li>
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Individuals with a learning disability can be taught to use their
          strengths and find ways to make it easier to learn and be more
          successful at school and on the job. I can help individuals get the
          appropriate Welfare-to-Work activities, including accommodations once
          a learning disability is identified.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Please keep in mind this screening is a very simple and short test. It
          will help you decide if you would like a referral to a learning
          disability specialist for an evaluation to find out if a learning
          disability exists.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          The areas that will be tested at evaluation are the following:
        </Typography>
        <br />
        <Typography display='block' component='ul'>
          <li>Natural talents and abilities</li>
          <li>Ability to follow verbal and written information</li>
          <li>Achievement</li>
          <li>Job and career interests</li>
        </Typography>
        <br />
        <Typography display='block' component='span'>
          The specialist can help identify strengths and weaknesses so that we
          can make referrals to the appropriate services and accommodations for
          you. Please remember that you have the right to file for a fair
          hearing if you disagree with a county action including actions related
          to learning disabilities.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          If you are Limited-English proficient and a Learning Needs Screening
          is not available in your primary language, you have the right to
          request a referral directly for a learning disabilities evaluation.
        </Typography>
        <br />
        <Typography display='block' component='span'>
          Note to worker: The Learning Needs Screening tool is not intended to
          determine the existence of a learning disability. It is only the first
          step in the evaluation process.
        </Typography>
        <br />
      </Grid>
    </Grid>
  )
})

export default LearningNeedsIndicatorQuestions
