export const nameMarkerRemover = name => {
  return name.split('-')[0]
}

export const validateCIN = cin => {
  const numIntegers = cin ? parseInt(cin).toString().length : 0
  if (numIntegers === 8 && cin.length === 9) {
    return true
  } else {
    return false
  }
}
