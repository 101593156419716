import gql from 'graphql-tag'
const queries = {
  AUTH: gql`
    query {
      auth {
        user {
          id
          firstName
          lastName
          email
          supervisor {
            firstName
            lastName
          }
          details {
            regionDetails {
              name
            }
            officeDetails {
              name
            }
            dataExtract
          }
          orgLevelID {
            id
            code
            value
          }
          role {
            roleDetails {
              code
              value
            }
          }
          isDisabled
        }
        county {
          id
          description
          code
        }
      }
    }
  `,
}

export default queries;