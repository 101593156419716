import { clone } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import store from 'app/store.js'
import { openDialog } from 'ducks/dialogSlice'
import { navigateWithoutSave } from 'thunks/navigateWithoutSave'
import { setJustPopped } from 'ducks/navigationSlice'
import { setCurrentPathname } from 'ducks/navigationSlice'
import { setPreviousPathname } from 'ducks/navigationSlice'
import { setNavigationHistory } from 'ducks/navigationSlice'
import { setHistoryIndex } from 'ducks/navigationSlice'
import { resetNewUser } from 'ducks/adminSlice'

const backListener = history => {
  const currentHistory = [history.location.pathname?.split('/').pop()]
  store.dispatch(setNavigationHistory(currentHistory))
  store.dispatch(setHistoryIndex(0))

  // Ensure browser back button presses are handled correctly
  history.listen((newLocation, action) => {
    const historyIndex = store.getState().navigation.historyIndex
    const currentPathname = store.getState().navigation.currentPathname
    const previousPathname = store.getState().navigation.previousPathname
    const justPopped = store.getState().navigation.justPopped
    const isInterviewReady = store.getState().ready.interview
    let navigationHistory = clone(store.getState().navigation.navigationHistory)

    const unsavedChanges = store.getState().unsavedChanges

    // Ensure each proper navigation is accompanied by a duplicate entry in history
    // Push is triggered by the 'navigateToPage' action. When this occurs we need to both:
    // -- duplicate that action (assuming its a new route) to give us a buffer if the back button is pressed
    // -- record the history of routes to properly reformat a back button press as an official navigation request
    if (action === 'PUSH') {
      if (newLocation.pathname !== currentPathname) {
        // Save a duplicate of the new location
        store.dispatch(setPreviousPathname(currentPathname))
        const newPathname = newLocation.pathname
        store.dispatch(setCurrentPathname(newPathname))
        const subdomainID = newPathname?.split('/').pop()

        // Pop actions will trigger a push that has already been recorded in history
        if (justPopped) {
          store.dispatch(setJustPopped(false))
        } else {
          navigationHistory.push(subdomainID)
          store.dispatch(setHistoryIndex(historyIndex + 1))
          store.dispatch(setNavigationHistory(navigationHistory))
        }

        history.push({
          pathname: subdomainID,
        })
      }
    }

    // Pop is triggered by history.go and by the browsers back button. When this occurs we need to:
    // -- undo the back button press
    // -- Translate the back button process into an official request to move to a specific page
    if (action === 'POP') {
      // Can't go back if there is no further history
      if (historyIndex === 0) {
        history.go(1)
        return
      }

      store.dispatch(setJustPopped(true))
      store.dispatch(resetNewUser())
      const subdomainID = previousPathname?.split('/').pop()
      // If previous page is not available, don't try to go back
      if (subdomainID) {
        // If the user was on in the interview, but navigates away. Rather than
        // go back to an undefined route, don't navigate.
        if (!isInterviewReady) return
        // If there are no unsaved changes, navigate to that page
        else if (isEmpty(unsavedChanges.answers)) {
          const newIndex = historyIndex - 1
          navigationHistory.pop()
          store.dispatch(setHistoryIndex(newIndex))
          store.dispatch(setNavigationHistory(navigationHistory))
          store.dispatch(
            navigateWithoutSave(navigationHistory[newIndex], history)
          )
        } else {
          // Else, pop up a modal asking if they want to leave
          history.go(1)
          store.dispatch(
            openDialog({
              type: 'discard',
              title: `Unsaved Changes`,
              description: '',
              props: { subdomainId: subdomainID },
            })
          )
        }
      }
    }
  })
}

export default backListener
