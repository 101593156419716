import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  actionPlans: {},
}

const unsavedActionPlansSlice = createSlice({
  name: 'unsavedActionPlans',
  initialState,
  reducers: {
    updateActionPlan: (state, action) => {
      action.payload.accepted =
        parseInt(action.payload.referralMade) === 1
          ? action.payload.accepted
          : null
      state.actionPlans[action.payload.id] = action.payload
    },
    clearUnsavedActionPlans: (state, action) => initialState,
  },
})

export const {
  updateActionPlan,
  clearUnsavedActionPlans,
} = unsavedActionPlansSlice.actions

export default unsavedActionPlansSlice.reducer
