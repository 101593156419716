import store from 'app/store.js'
import config from '../config'
import activityListener from 'app/activityListener'
import { setUserToken } from 'ducks/userSlice'
import fetchUser from 'thunks/fetchUser'
import { getCookie } from 'utilTools/cookiesHelper'

const initializeAuth = async () => {
  console.log(`starting initializeAuth`)

  const DEVELOPMENT_ENV = process.env.NODE_ENV === 'development'
  console.log(`found DEVELOPMENT_ENV: ${DEVELOPMENT_ENV}`)

  // Set JWT token
  const forgeRockToken = DEVELOPMENT_ENV ? config.token : getCookie('ocatJwt')
  // this function is declared in the index file for application monitoring
  // eslint-disable-next-line no-undef
  ineum('reportEvent', 'initializeAuth', {
    meta: {
      frToken: forgeRockToken,
    },
  })

  // Check if ForgeRock cookie and JWT exist, else redirect to ForgeRock login
  if (forgeRockToken) {
    store.dispatch(setUserToken(forgeRockToken))
    console.log(`fetching user`)
    store
      .dispatch(fetchUser())
      .then(() => {
        console.log(`fetched user success`)
        activityListener()
      })
      .catch(err => console.log(`failed to fetch user: ${err}`))
  } else {
    console.log(`something failed, expiring session`)
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportEvent', 'unidentifiedUser')

    // Clear cookies, then redirect
    // deleteForgeRockCookies()
    // await fetch('/restful/expire', {
    //   method: 'POST',
    // })
    const currentUrl = window.location.href
    const redirect = `redirect=${encodeURIComponent(currentUrl)}`
    window.location.href = `${
      config.oidcUrl
    }?t=${new Date().getTime()}&${redirect}`
  }
}

export default initializeAuth
