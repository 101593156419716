import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { signOut } from 'thunks/signOut'
import config from '../../config'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const AuthErrorDialogContent = ({ err }) => {
  return (
    <Container>
      <Typography>{err}</Typography>
    </Container>
  )
}

export const AuthErrorDialogActions = ({ onErrorAction }) => {
  const dispatch = useDispatch()
  const { userNotFound } = useSelector(state => state.user)

  const handleLogout = () => {
    if (userNotFound) {
      window.location.href = `${config.oidcUrl}?t=${new Date().getTime()}`
    } else {
      dispatch(signOut())
    }
  }

  return (
    <>
      <Button data-testid="AuthErrorDialogActions-logout" onClick={handleLogout} color='primary'>
        Log Out
      </Button>
    </>
  )
}
