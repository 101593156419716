import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const ErrorDialogContent = props => {
  // The vast majority of the app uses this modal for handling graphql errors, but we also use this
  // for front end only errors, so we do a check
  let refId
  let fullError
  let explanationForUser

  if (props.error?.graphQLErrors.length > 0) {
    refId = props.error?.graphQLErrors[0].refId
    // The FULL error will only be returned in development and test environments, we do a check for it below
    fullError = props.error?.graphQLErrors[0].error

    // With certain errors, we only wish to explain the situation and direct them to load the client list
    // ExplanationForUser is the description from the server on why the user cannot perform this operation.
    explanationForUser = props.error?.graphQLErrors[0]?.explanationForUser
  }

  // If explanationForUser is present we should display nothing else.
  if (explanationForUser) {
    return (
      <Container>
        <Typography>{explanationForUser}<br/><br/>Reference ID: {refId}</Typography>
      </Container>
    )
  }

  // If there is no refID (error created in the frontend), use the frontEndErrorTrace that is sent to api
  // via the logError function, worst case scenario use a timestamp
  const referenceID = refId || props.frontEndErrorTrace || Date.now()
  // this function is declared in the index file for application monitoring
  // eslint-disable-next-line no-undef
  ineum('meta', 'error-reference', referenceID);

  // this function is declared in the index file for application monitoring
  // eslint-disable-next-line no-undef
  ineum('reportError', JSON.stringify(fullError));

  return (
    <Container>
      <Typography>
        OCAT has encountered an error. If page reload does not resolve, please
        contact the OCAT Help Desk at{' '}
        <a href='mailto:ocat.support@calsaws.org'>ocat.support@calsaws.org</a>{' '}
        with the following reference id: {referenceID}.
      </Typography>
      {fullError && (
        <Typography>Full Error: {JSON.stringify(fullError)}</Typography>
      )}
    </Container>
  )
}

export const ErrorDialogActions = props => {
  const dispatch = useDispatch()
  const client = useSelector(state => state.client)

  const handleClose = () => {
    dispatch(closeDialog())
    // If an explanation of the error was provided to the user,
    // return to the same client's interview list page, not the generic client list
    if (props.error?.graphQLErrors[0]?.explanationForUser && client?.currentClient) {
      window.location.href = "/interview_list/" + client?.currentClient
    } else {
      window.location.reload()
    }
  }

  return (
    <>
      <Button onClick={handleClose} variant='contained' color='primary'>
        Close
      </Button>
    </>
  )
}
