import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from 'app/theme'

const StyledHeader = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-top: 0px;
  }
`

const HelpAndSupport = () => {
  return (
    <React.Fragment>
      <StyledHeader variant='h4'>Help and Support</StyledHeader>
      <br />
      <Grid item>
        <Typography variant='body1'>Contact the Help Desk</Typography>
      </Grid>
      <br />
      <Grid item>
        <Typography variant='body1'>Email: ocat.support@calsaws.org</Typography>
      </Grid>
      <Grid item>
        <Typography variant='body1'>Phone: 1-833-719-0559</Typography>
      </Grid>
      <Grid item>
        <Typography variant='body1'>
          Please follow your county's Service Desk procedures to create a
          service ticket
        </Typography>
      </Grid>
    </React.Fragment>
  )
}

export default HelpAndSupport
