import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Select from 'components/general/Select'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableRowDescription from 'components/general/TableRowDescription'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteText from 'components/table/InfiniteText'
import { previewInterviewSelector } from 'utilTools/reduxHelper'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const tableHeaders = [
  { name: '', colWidth: '50%' },
  { name: 'Has this ever been a problem?', colWidth: '25%' },
  { name: 'Issue now?', colWidth: '25%' },
]

export const tableQuestions = [
  {
    problem: 'vic_a_ever_prob',
    issue: 'vic_a_issue_now',
  },
  {
    problem: 'vic_b_ever_prob',
    issue: 'vic_b_issue_now',
  },
  {
    problem: 'vic_c_ever_prob',
    issue: 'vic_c_issue_now',
  },
  {
    problem: 'vic_d_ever_prob',
    issue: 'vic_d_issue_now',
  },
  {
    problem: 'vic_e_ever_prob',
    issue: 'vic_e_issue_now',
  },
  {
    problem: 'vic_f_ever_prob',
    issue: 'vic_f_issue_now',
  },
  {
    problem: 'vic_g_ever_prob',
    issue: 'vic_g_issue_now',
  },
  {
    problem: 'vic_h_ever_prob',
    issue: 'vic_h_issue_now',
  },
  {
    problem: 'vic_i_ever_prob',
    issue: 'vic_i_issue_now',
  },
  {
    problem: 'vic_j_ever_prob',
    issue: 'vic_j_issue_now',
  },
  {
    problem: 'vic_k_ever_prob',
    issue: 'vic_k_issue_now',
  },
  {
    problem: 'vic_l_ever_prob',
    issue: 'vic_l_issue_now',
  },
  {
    problem: 'vic_m_ever_prob',
    issue: 'vic_m_issue_now',
  },
  {
    problem: 'vic_n_ever_prob',
  },
  {
    problem: 'vic_o_ever_prob',
    issue: 'vic_o_issue_now',
  },
  {
    problem: 'vic_p_ever_prob',
    issue: 'vic_p_issue_now',
  },
  {
    problem: 'vic_q_ever_prob',
    issue: 'vic_q_issue_now',
  },
]

const rcvdCounslngSelector = state => state.interview.rcvd_counslng.answer
const previewRcvdCounslngSelector = state => state.preview.rcvd_counslng.answer

const counselingTableSelector = state => state.interview.counseling_table.answer
const previewCounselingTableSelector = state => state.preview.counseling_table.answer

const selectRcvdCounslng = createSelector(
  rcvdCounslngSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectPreviewRcvdCounslng = createSelector(
  previewRcvdCounslngSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectCounselingTable = createSelector(
  counselingTableSelector,
  answer => answer && answer.length !== 0
)

const selectPreviewCounselingTable = createSelector(
  previewCounselingTableSelector,
  answer => answer && answer.length !== 0
)

const CounselingRow = props => {
  const { isDisabled, isPreview } = props
  return (
    <>
      <TableCell align='center'>
        <InfiniteText
          noTable
          isDisabled={isDisabled}
          sub_question_id={'counseling_used_name'}
          {...props}
          shouldDecode={true}
          isPreview={isPreview} 
        />
      </TableCell>
      <TableCell align='center'>
        <InfiniteText
          noTable
          isDisabled={isDisabled}
          sub_question_id={'counseling_used_contact_info'}
          {...props}
          shouldDecode={true}
          isPreview={isPreview} 
        />
      </TableCell>
    </>
  )
}

const DomesticAbuseVictimQuestions = React.memo(({ isPreview }) => {
  const rcvdCounslngIsNo = useSelector(isPreview ? selectPreviewRcvdCounslng : selectRcvdCounslng)
  const counselingTable = useSelector(isPreview ? selectPreviewCounselingTable : selectCounselingTable)
  const headers = ['Name of Service Used Before', 'Contact Information']
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  const problemIsYes = tableQuestions.map(question => 
    selectorState[question.problem].answer?.toUpperCase() === 'YS'
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Victim Questions'
          domain='Domestic Abuse'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Conflict in your life can sometimes hinder your efforts to participate
          in work or WTW activities. The next set of questions ask about
          conflict you may experience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CustomTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableHeaderCell key={index} colwidth={header.colWidth}>
                      {header.name}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableQuestions.map((question, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={question.problem}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Select
                          question_id={question.problem}
                          disableDescription={true}
                          isPreview={isPreview}
                        />
                      </TableCell>
                      <TableCell>
                        {question.issue && (
                          <Select
                            question_id={question.issue}
                            disableDescription={true}
                            isDisabled={!problemIsYes[index]}
                            isPreview={isPreview}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <Select isDisabled={counselingTable} question_id='rcvd_counslng' isPreview={isPreview} />
          <InfiniteTable
            rowComponent={<CounselingRow isDisabled={!rcvdCounslngIsNo} isPreview={isPreview} />}
            headers={headers}
            question_id='counseling_table'
            isDisabled={!rcvdCounslngIsNo}
            isPreview={isPreview}
          />
          <Select question_id='want_counslng' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default DomesticAbuseVictimQuestions
