import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { openDialog } from 'ducks/dialogSlice'

import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SearchDropDown from 'components/clientSearch/SearchDropdown'
import ClientListQueries from 'components/clientList/ClientListQueries'

import {
  setExistingCase,
  setExistingCaseNumber,
  setNewCase,
  setNewCaseNumber,
  setNewCaseCounty,
} from 'ducks/newInterviewSlice'

//                            Styled Components
const InputContainer = styled.div`
  margin-left: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
`

const StyledText = styled(TextField)`
  && {
    width: 200px;
  }
`
const FormContainer = styled.div`
  padding-left: 2px;
`

const CountyContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
  }
`

const CountyHeader = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
`

// Component for the new case form.
const NewAppraisalNewCase = ({
  newCase,
  newCaseCounty,
  newCaseNumber,
  newCaseNumberError,
  errorMessage,
}) => {
  const dispatch = useDispatch()
  const { isStatewide } = useSelector(state => state.user)

  // Get the county list
  const { error, data } = useQuery(ClientListQueries.COUNTIES_LIST_QUERY)

  // Labels and ids.
  const newCaseLabel = 'Create new case'
  const textLabel = 'New Case Number'

  const handleClick = () => {
    const answer = newCase === newCaseLabel ? null : newCaseLabel
    dispatch(setNewCase(answer))
    if (!answer && newCaseNumber) {
      dispatch(setNewCaseNumber(null))
      dispatch(setNewCaseCounty(null))
    }
    if (answer) {
      dispatch(setExistingCase(null))
      dispatch(setExistingCaseNumber(null))
    }
  }

  // Handle errors
  // If there is an error fetching data, the dialog will switch over to
  // the error dialog. Initiating a new appraisal will not be possible.
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: `Error retrieving data`,
          description:
            'Please provide your administrator with the information below:',
          props: { err: JSON.stringify(error.message) },
        })
      )
    }
  }, [error, dispatch])

  const dropdownOptionsGenerator = dataArray => {
    return (dataArray || []).map(element => {
      return { optionId: element.id, optionLabel: element.description }
    })
  }
  return (
    <FormContainer>
      <FormControlLabel
        label={newCaseLabel}
        control={
          <Radio checked={newCase === newCaseLabel} onClick={handleClick} />
        }
        value={newCaseLabel}
      />
      <InputContainer>
        <StyledText
          variant='outlined'
          disabled={!newCase}
          error={newCaseNumberError}
          label={textLabel}
          helperText={errorMessage}
          value={newCaseNumber || ''}
          inputProps={{ maxLength: 7 }}
          onChange={e => {
            const { value } = e.target
            if (!value || (value && value.match(/^[0-9a-zA-Z]+$/)))
              dispatch(setNewCaseNumber(value))
          }}
          autoComplete='off'
        />
      </InputContainer>
      {isStatewide && newCase && (
        <CountyContainer>
          <CountyHeader>Select a County for this case</CountyHeader>
          <SearchDropDown
            label='County'
            value={newCaseCounty || ''}
            width='200px'
            handleChange={e => dispatch(setNewCaseCounty(e.target.value))}
            options={dropdownOptionsGenerator(data?.counties || [])}
          />
        </CountyContainer>
      )}
    </FormContainer>
  )
}

export default NewAppraisalNewCase
