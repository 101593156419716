import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (question_id === 'no_grade' && answer) {
    effectCollector.add('hgh_grd_type', null)
    effectCollector.add('grade_number', null)
    effectCollector.add('highest_grade_start_date', null)
    effectCollector.add('highest_grade_end_date', null)
  }

  if (question_id === 'hgh_grd_type' && answer) {
    effectCollector.add('grade_number', null)
  }
  if (question_id === 'hgh_grd_type' && !answer) {
    effectCollector.add('grade_number', null)
    effectCollector.add('highest_grade_start_date', null)
    effectCollector.add('highest_grade_end_date', null)
  }

  if (
    question_id === 'curr_enrlld_schl_trng' &&
    answer?.toUpperCase() !== 'YS'
  ) {
    effectCollector.add('high_school_total_yrs', null)
    effectCollector.add('program_current_enroll', null)
  }

  return effectCollector.effects
}

export default effects;