import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Text from 'components/general/Text'
import Dropdown from 'components/general/Dropdown'
import DatePicker from 'components/general/DatePicker'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const noEducationSelector = state => state.interview.no_grade.answer
const previewNoEducationSelector = state => state.preview.no_grade.answer
const highestGradeTypeSelector = state => state.interview.hgh_grd_type.answer
const previewHighestGradeTypeSelector = state => state.preview.hgh_grd_type.answer
const currentlyEnrolledSelector = state =>
  state.interview.curr_enrlld_schl_trng.answer
const previewCurrentlyEnrolledSelector = state =>
  state.preview.curr_enrlld_schl_trng.answer

const selectNoEducation = createSelector(noEducationSelector, answer => !answer)
const selectPreviewNoEducation = createSelector(previewNoEducationSelector, answer => !answer)
// Filter dropdown options depending on grade type dropdown
const selectHighestGradeFilter = createSelector(
  highestGradeTypeSelector,
  gradeType => {
    if (!gradeType) return [0, 0]
    switch (gradeType.toUpperCase()) {
      case 'EL':
        return ['1', '2', '3', '4', '5', '6', '7', '8']
      case 'HS':
        return ['9', '10', '11', '12']
      case 'CO':
        return ['13', '14', '15', '16']
      case 'PO':
        return ['17', '18', '19', '20']
      default:
        return [0, 0]
    }
  }
)

const selectPreviewHighestGradeFilter = createSelector(
  previewHighestGradeTypeSelector,
  gradeType => {
    if (!gradeType) return [0, 0]
    switch (gradeType.toUpperCase()) {
      case 'EL':
        return ['1', '2', '3', '4', '5', '6', '7', '8']
      case 'HS':
        return ['9', '10', '11', '12']
      case 'CO':
        return ['13', '14', '15', '16']
      case 'PO':
        return ['17', '18', '19', '20']
      default:
        return [0, 0]
    }
  }
)

const selectCurrentlyEnrolled = createSelector(
  currentlyEnrolledSelector,
  answer => answer && answer.toUpperCase() === 'YS'
)

const selectPreviewCurrentlyEnrolled = createSelector(
  previewCurrentlyEnrolledSelector,
  answer => answer && answer.toUpperCase() === 'YS'
)

const EducationHighestGrade = React.memo(({ isPreview }) => {
  const noEducation = useSelector(isPreview ? selectPreviewNoEducation : selectNoEducation)
  const highestGradeFilter = useSelector(isPreview ? selectPreviewHighestGradeFilter : selectHighestGradeFilter)
  const highestGradeType = useSelector(isPreview ? previewHighestGradeTypeSelector : highestGradeTypeSelector)
  const currentlyEnrolled = useSelector(isPreview ? selectPreviewCurrentlyEnrolled : selectCurrentlyEnrolled)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader subdomain='Highest Grade' domain='Education' />
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='no_grade' isPreview={isPreview} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Dropdown question_id='hgh_grd_type' isDisabled={!noEducation} isPreview={isPreview} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Dropdown
                question_id='grade_number'
                filter={highestGradeFilter}
                isDisabled={!noEducation || !highestGradeType}
                isPreview={isPreview}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                question_id='highest_grade_start_date'
                datePickerOptions={{ format: 'MM/yyyy', views: ['month'] }}
                isDisabled={!highestGradeType}
                isPreview={isPreview}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                question_id='highest_grade_end_date'
                datePickerOptions={{ format: 'MM/yyyy', views: ['month'] }}
                isDisabled={!highestGradeType}
                isPreview={isPreview}
              />
            </Grid>
          </Grid>
          <Select question_id='curr_enrlld_schl_trng' isPreview={isPreview} />
          <Text
            question_id='high_school_total_yrs'
            type='number'
            isDisabled={!currentlyEnrolled}
            subquestion
            isPreview={isPreview}
          />
          <Text
            multiline
            rows={4}
            question_id='program_current_enroll'
            isDisabled={!currentlyEnrolled}
            subquestion
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Text
            multiline rows={4}
            question_id='educational_goal'
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select question_id='interest_in_ed_trng' isPreview={isPreview} />
          <Select question_id='pursue_ged_trng' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default EducationHighestGrade
