import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Select from 'components/general/Select'
import Grid from '@material-ui/core/Grid'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { selectStyle } from 'components/interview/DemographicsGeneralInformation'

const qIds = [
  'housing_situation_challenges_to_wtw_activities',
  'housing_situation_upcoming_changes',
]

const indicatorSelectors = qIds.map(question => {
  return state => state.interview[question].answer
})

const previewIndicatorSelectors = qIds.map(question => {
  return state => state.preview[question].answer
})

// Disable skip button if any of the indicator questions above are set to "Yes" or are blank
const canSkipSelector = createSelector(indicatorSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const previewCanSkipSelector = createSelector(previewIndicatorSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const HousingIndicatorQuestions = React.memo(({ isPreview }) => {
  // Disable skip button if any of the indicator questions above are set to "Yes" or are blank
  const canSkip = useSelector(isPreview ? previewCanSkipSelector : canSkipSelector)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader subdomain='Indicator Questions' domain='Housing' />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select
            question_id='housing_situation_challenges_to_wtw_activities'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Select question_id='housing_situation_upcoming_changes' isPreview={isPreview} />
        </form>
        <SkipButton isDisabled={!canSkip}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default HousingIndicatorQuestions
