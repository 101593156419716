import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSaving: false,
  savingQueue: [],
}

const interviewSavingQueueSlice = createSlice({
  name: 'interviewSavingQueue',
  initialState: initialState,
  reducers: {
    setIsSaving: (state, action) => {
      state.isSaving = action.payload
    },
    setSavingQueue: (state, action) => {
      state.savingQueue = action.payload
    },
    clearInterviewSavingQueue: (state, action) => initialState,
  },
})

export const {
  setIsSaving,
  setSavingQueue,
  clearInterviewSavingQueue,
} = interviewSavingQueueSlice.actions

export default interviewSavingQueueSlice.reducer
