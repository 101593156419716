import gql from 'graphql-tag'
const mutation = {
  CREATE_CLIENT_MUTATION: gql`
    mutation createClient(
      $cin: String!
      $sawsCaseNumber: String!
      $firstName: String!
      $lastName: String!
      $birthDate: DateTime!
      $genderID: Int!
      $county: String!
    ) {
      createClient(
        cin: $cin
        sawsCaseNumber: $sawsCaseNumber
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        genderID: $genderID
        county: $county
      ) {
        clientID
        caseID
      }
    }
  `,
}

export default mutation;
