import { gql } from 'apollo-boost'
import { resetUser } from 'ducks/userSlice'
import client from 'apollo/Apollo'
import config from '../config'
import { openDialog } from 'ducks/dialogSlice'
import { deleteForgeRockCookies } from 'utilTools/cookiesHelper'

const LOGOUT = gql`
  mutation {
    logout
  }
`

export const signOut = (autoRedirect = true) => {
  return async (dispatch, getState) => {
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportEvent', 'signOut'); 
    try {
      if (process.env.NODE_ENV !== 'development' && autoRedirect) {
        await client.mutate({ mutation: LOGOUT })
        await client.clearStore()
      }
      // Expire cookies from ForgeRock
      deleteForgeRockCookies()

      // Expire cookie from ALB
      await fetch('/restful/expire', {
        method: 'POST',
      })

      dispatch(resetUser())
      if (autoRedirect) {
        // this function is declared in the index file for application monitoring
        // eslint-disable-next-line no-undef
        ineum('reportEvent', 'autoRedirectToLogin'); 
        window.location.href = `${config.oidcUrl}?t=${new Date().getTime()}`
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
