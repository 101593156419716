import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components'
import { setNewUser } from 'ducks/adminSlice'
import { colors } from 'app/theme'

export const StyledSelect = styled(Select)`
  && {
    margin-top: 20px;
    margin-right: 20px;
    width: ${p => p.width};
    .react-select__control {
      height: 56px;
      border-color: rgba(0, 0, 0, 0.54);
    }
    .react-select__control--is-focused {
      border-color: ${colors.calsawsBlue};
      box-shadow: 0 0 0 1px ${colors.calsawsBlue};
    }
    .react-select__indicator-separator {
      background-color: rgba(0, 0, 0, 0.54);
    }
    .react-select__option--is-focused {
      background-color: rgba(255, 255, 255, 1);
      outline: 1px solid ${colors.saffron};
    }
    .react-select__option--is-selected {
      outline: 1px solid ${colors.saffron};
      background-color: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 1);
    }
    .react-select__indicator {
      color: rgba(0, 0, 0, 0.54);
    }
    .react-select__value-container--has-value {
      color: rgba(0, 0, 0, 0.87);
    }
    .react-select__value-container {
      color: rgba(0, 0, 0, 0.54);
    }
    .react-select__placeholder {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`
const InputContainer = styled.div`
  display: inline-block;
  margin-left: 8px;
`

const AdminSelect = ({ options, field, width = '250px', selectOpts = {} }) => {
  const dispatch = useDispatch()
  const answer = useSelector(state => state.admin[field])
  const handleChange = selectedOption => {
    dispatch(setNewUser({ field: field, value: selectedOption }))
  }
  return (
    <InputContainer>
      <StyledSelect
        aria-label='Supervisor dropdown'
        {...selectOpts}
        className='react-select-container'
        classNamePrefix='react-select'
        width={width}
        value={answer}
        onChange={handleChange}
        options={options}
      />
    </InputContainer>
  )
}

export default AdminSelect
