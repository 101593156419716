import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  // Flag to check if we need to add a side effect.

  if (question_id === 'are_you_pregnant' && answer) {
    if (answer.toUpperCase() === 'NO') {
      effectCollector.add('due_date', null)
      effectCollector.add('see_doctor_prenatal_care', null)
    }
  }
  return effectCollector.effects
}

export default effects;