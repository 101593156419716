import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { batch } from 'react-redux'
import {
  setPreviewRecommendationsLoading,
  setPreviewRecommendations,
  setPreviewQuestionnaireLoading,
  setPreviewQuestionnaireLoaded,
  storeInterviewData,
} from '../ducks/previewSlice'

const GET_PREVIEW_RECOMMENDATIONS = gql`
  query getPreviewRecommendations($answers: [AnswerInput!]) {
    generateRecommendationsWithPendingChanges(answers: $answers) {
      domainID
      categoryID
      typeID
      englishRecommendationText
      englishCategoryText
      englishTypeText
      actionPlans {
        recommendationID
        accepted
        referralMade
        actionText
      }
      domain {
        id
        description
        code
      }
      category {
        id
        description
        code
      }
      type {
        id
        description
        code
      }
    }
  }
`

const GET_PREVIEW_QUESTIONNAIRE_CONTENT = gql`
  query getPreviewQuestionnaireContent {
    previewQuestionnaireContent {
      payload
    }
  }
`

export const getPreviewRecommendationsAndActionPlans = () => {
  return async (dispatch, getState) => {
    try {
      // Query the server for all recommendations and action plans associated to this interview
      // Set a flag to let the recommendations page to wait
      dispatch(setPreviewRecommendationsLoading(true))

      const previewData = getState().preview
      const answers = Object.keys(previewData)
        .filter(key => previewData[key] && previewData[key].answer)
        .map(key => ({
          questionID: key,
          answerValue: { question_id: key, answer: previewData[key].answer },
        }))

      const recommendationData = await client.query({
        query: GET_PREVIEW_RECOMMENDATIONS,
        variables: { answers },
        fetchPolicy: 'network-only',
      })

      const rawRecommendations =
        recommendationData.data.generateRecommendationsWithPendingChanges
      const recommendations = {}

      let i = 0
      rawRecommendations.forEach(recommendation => {
        recommendations[++i] = recommendation
      })
      batch(() => {
        dispatch(setPreviewRecommendations(recommendations))
        // dispatch(seedInitialActionPlans())
        dispatch(setPreviewRecommendationsLoading(false))
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(setPreviewRecommendationsLoading(false))
    }
  }
}

export const getPreviewQuestionnaireContent = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPreviewQuestionnaireLoading(true))

      const questionnaireData = await client.query({
        query: GET_PREVIEW_QUESTIONNAIRE_CONTENT,
        fetchPolicy: 'network-only',
      })

      const questionsAndAnswers =
        questionnaireData.data.previewQuestionnaireContent.payload

      batch(() => {
        dispatch(storeInterviewData(questionsAndAnswers))
        dispatch(setPreviewQuestionnaireLoaded(true))
        dispatch(setPreviewQuestionnaireLoading(false))
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(setPreviewRecommendationsLoading(false))
    }
  }
}
