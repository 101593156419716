import React from 'react'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Select from 'components/general/Select'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Dropdown from 'components/general/Dropdown'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const qIds = [
  'reliable_support_system',
  'identity_recs_that_away',
  'interested_in_recieving_support_for_relationship',
]

const statusOfRelationshipSelector = state =>
  state.interview.status_of_relationship.answer

const previewStatusOfRelationshipSelector = state =>
  state.preview.status_of_relationship.answer

const selectRelationshipIsMarriedOrDating = createSelector(
  statusOfRelationshipSelector,
  answer => {
    if (!answer && !Array.isArray(answer)) {
      return false
    }
    const uppercaseAnswers = answer.map(ans => ans.toUpperCase())
    if (uppercaseAnswers.includes('DA') || uppercaseAnswers.includes('MA')) {
      return true
    }
    return false
  }
)

const selectPreviewRelationshipIsMarriedOrDating = createSelector(
  previewStatusOfRelationshipSelector,
  answer => {
    if (!answer && !Array.isArray(answer)) {
      return false
    }
    const uppercaseAnswers = answer.map(ans => ans.toUpperCase())
    if (uppercaseAnswers.includes('DA') || uppercaseAnswers.includes('MA')) {
      return true
    }
    return false
  }
)

const Relationships = React.memo(({ isPreview }) => {
  const isMarriedOrDating = useSelector(isPreview ? selectPreviewRelationshipIsMarriedOrDating : selectRelationshipIsMarriedOrDating)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Relationship Questions'
          domain='Relationships'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='status_of_relationship' isPreview={isPreview} />
          <Dropdown
            question_id='rate_relationship'
            isDisabled={!isMarriedOrDating}
            subquestion
            width={'320px'}
            isPreview={isPreview}
          />
          {qIds.map(id => (
            <Select key={id} question_id={id} row={true} isPreview={isPreview} />
          ))}
        </form>
      </Grid>
    </Grid>
  )
})

export default Relationships
