import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { closeSnackbar } from 'ducks/snackbarSlice'

export default function SnackbarRoot() {
  const message = useSelector(state => state.snackbar)
  const dispatch = useDispatch()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={message !== null}
      autoHideDuration={2500}
      onClose={() => dispatch(closeSnackbar())}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id='message-id'>{message}</span>}
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={() => dispatch(closeSnackbar())}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}
