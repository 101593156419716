import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()

  if (
    question_id === 'involved_cps' &&
    (!answer || answer?.toUpperCase() === 'NO')
  ) {
    effectCollector.add('involved_cps_explain', null)
  }

  return effectCollector.effects
}

export default effects;