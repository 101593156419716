import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { signOut } from 'thunks/signOut'
import { setActiveSession, setTimedOut } from 'ducks/sessionSlice'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const TimeoutWarningDialogContent = ({ err }) => {
  const dispatch = useDispatch()

  const [counter, setCounter] = useState(60)

  useEffect(() => {
    // Signs the user out when 60 seconds is up
    if (counter === 0) {
      const autoRedirect = false
      dispatch(closeDialog())
      dispatch(signOut(autoRedirect))
      dispatch(setTimedOut(true))
      return
    }

    if (!counter) return

    const intervalId = setInterval(() => {
      setCounter(counter - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [counter, dispatch])

  return (
    <Container>
      <Typography>
        Your session will expire in {counter} seconds! Click Log Out to be
        logged out of OCAT or Continue to return to the previous page.
      </Typography>
    </Container>
  )
}

export const TimeoutWarningDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setActiveSession(true))
    dispatch(closeDialog())
  }
  const handleSignout = () => {
    dispatch(signOut())
  }

  return (
    <>
      <Button onClick={handleSignout} variant='contained' color='secondary'>
        Log Out
      </Button>
      <Button onClick={handleClose} variant='contained' color='primary'>
        Continue Session
      </Button>
    </>
  )
}
