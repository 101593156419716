import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { navigateToNextSection } from 'ducks/navigationSlice'
import { colors } from 'app/theme'

const Container = styled(Grid)`
  && {
    padding-top: 40px;
  }
`
const StyledButton = styled(Button)`
  &:hover {
    background-color: ${colors.saffron};
  }
`
const SkipButton = React.memo(({ isDisabled }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClick = () => {
    dispatch(navigateToNextSection(history))
  }
  return (
    <>
      {!isDisabled && (
        <Container container spacing={1}>
          <Grid item xs={12}>
            <StyledButton
              color='secondary'
              variant='contained'
              onClick={handleClick}
            >
              Skip Section
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              Based on the answers provided, further information is not
              necessary to collect. Click on 'Skip Section' to continue to the
              next section.
            </Typography>
          </Grid>
        </Container>
      )}
    </>
  )
})

export default SkipButton
