import EffectCollector from 'side-effects/effectCollector'
import store from 'app/store'

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()
  const currentStatusTableQuestions = [
    {
      id: 'depressed_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    {
      id: 'fearful_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    {
      id: 'worried_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    { id: 'nervous_anxious_status', AL: 4, MO: 3, SO: 2, LI: 1, NO: 0 },
    {
      id: 'concerned_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    {
      id: 'tired_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    {
      id: 'not_motivated_status',
      AL: 4,
      MO: 3,
      SO: 2,
      LI: 1,
      NO: 0,
    },
    { id: 'scared_status', AL: 4, MO: 3, SO: 2, LI: 1, NO: 0 },
    { id: 'angry_status', AL: 4, MO: 3, SO: 2, LI: 1, NO: 0 },
  ]
  const stateProp = isPreview ? 'preview' : 'interview'
  if (currentStatusTableQuestions.some(item => question_id === item.id)) {
    const question = currentStatusTableQuestions.find(
      item => question_id === item.id
    )
    const previousAnswer = store.getState()[stateProp][question.id].answer
    const score =
      parseInt(
        store.getState()[stateProp]['emotional_mental_health_total_score'].answer
      ) || 0
    let newScore
    const previousAnswerValue = question[previousAnswer] || 0
    if (answer?.toUpperCase() === 'AL') {
      newScore = score + question.AL - previousAnswerValue
    } else if (answer?.toUpperCase() === 'MO') {
      newScore = score + question.MO - previousAnswerValue
    } else if (answer?.toUpperCase() === 'SO') {
      newScore = score + question.SO - previousAnswerValue
    } else if (answer?.toUpperCase() === 'LI') {
      newScore = score + question.LI - previousAnswerValue
    } else if (answer?.toUpperCase() === 'NO') {
      newScore = score + question.NO - previousAnswerValue
    } else {
      newScore = score - previousAnswerValue
    }
    effectCollector.add(
      'emotional_mental_health_total_score',
      newScore.toString()
    )
  }
  if (
    question_id === 'currently_getting_help_status' &&
    (answer === 'NO' || !answer)
  ) {
    effectCollector.add('emotional_mental_health_specify', null)
  }
  return effectCollector.effects
}

export default effects