import { createSlice, createSelector } from '@reduxjs/toolkit'
import { format, addDays } from 'date-fns'

const initialState = {
  currentClient: null,
  currentInterview: null,
  currentInterviewStatus: null,
  currentInterviewCounty: null,
  currentClientCin: null,
  currentClientDob: null,
  currentClientCaseNumber: null,
  currentInterviewStartDate: null,
  currentInterviewEndDate: null,
  assignedInterviewer: null,
  currentClientRelatedCases: null,
  clientList: null,
}

const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    setClient: (state, action) => {
      state.currentClient = action.payload.id
      state.currentClientFirstName = action.payload.firstName
      state.currentClientLastName = action.payload.lastName
      state.currentClientCin = action.payload.cin
      state.currentClientDob =
        action.payload.dob && format(new Date(action.payload.dob), 'MM/dd/yyyy')
      state.currentClientCaseNumber = action.payload.caseNumber
      state.assignedInterviewer = action.payload.assignedInterviewer
      state.currentInterviewStartDate =
        action.payload.startDate &&
        format(new Date(action.payload.startDate), 'MM/dd/yyyy')
      state.currentInterviewEndDate =
        action.payload.endDate &&
        format(new Date(action.payload.endDate), 'MM/dd/yyyy')
    },
    updateClient: (state, action) => {
      const startDate =
        action.payload.startDate || action.payload.currentInterviewStartDate
      const endDate =
        action.payload.endDate || action.payload.currentInterviewEndDate
      state.currentClientFirstName =
        action.payload.currentClientFirstName || state.currentClientFirstName
      state.currentClientLastName =
        action.payload.currentClientLastName || state.currentClientLastName
      state.currentClientCin = action.payload.cin || state.currentClientCin
      state.currentClientDob =
        (action.payload.dob &&
          format(new Date(action.payload.dob), 'MM/dd/yyyy')) ||
        state.currentClientDob
      state.currentClientSsn = action.payload.ssn
      state.currentInterviewStartDate =
        startDate && format(new Date(startDate), 'MM/dd/yyyy')
      state.currentInterviewEndDate =
        endDate && format(new Date(endDate), 'MM/dd/yyyy')
      state.assignedInterviewer = action.payload.assignedInterviewer
      state.currentClientCaseNumber =
        action.payload.caseNumber || state.currentClientCaseNumber
    },
    setRelatedCases: (state, action) => {
      state.currentClientRelatedCases = action.payload
    },
    setAssignedInterviewer: (state, action) => {
      state.assignedInterviewer = action.payload.assignedInterviewer
    },
    setClientList: (state, action) => {
      state.clientList = action.payload
    },
    setInterview: (state, action) => {
      state.currentInterview = action.payload
    },
    setInterviewStatus: (state, action) => {
      state.currentInterviewStatus = action.payload
    },
    setInterviewStartAndEnd: (state, action) => {
      state.currentInterviewStartDate = format(new Date(), 'MM/dd/yyyy')
      state.currentInterviewEndDate = format(
        addDays(new Date(), 30),
        'MM/dd/yyyy'
      )
    },
    clearClient: (state, action) => initialState,
  },
})

const interviewStatus = state => state.client.currentInterviewStatus
export const cancelledOrCompletedSelector = createSelector(
  interviewStatus,
  status =>
    status?.toLowerCase() === 'cancelled' ||
    status?.toLowerCase() === 'complete'
)

export const {
  setClient,
  updateClient,
  setInterview,
  setInterviewStatus,
  setClientList,
  clearClient,
  setAssignedInterviewer,
  setInterviewStartAndEnd,
  setRelatedCases,
} = clientSlice.actions

export default clientSlice.reducer
