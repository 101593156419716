import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { saveData } from 'thunks/saveData'
import { saveActionPlans } from 'thunks/saveActionPlans'

const StyledButton = styled(Button)`
  && {
    margin: 16px;
  }
`

const SaveButton = React.memo(({ label }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportEvent', 'manualSave');    
    dispatch(saveData({ disableNotifications: false }))
    dispatch(saveActionPlans())
  }
  return (
    <StyledButton variant='contained' color='secondary' onClick={handleClick}>
      {label}
    </StyledButton>
  )
})

export default SaveButton
