import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const sharingCountiesSlice = createSlice({
  name: 'sharingCounties',
  initialState,
  reducers: {
    setCheckedCounties: (state, action) => {
      const { checkedCounties } = action.payload
      if (checkedCounties) {
        state.checkedCounties = checkedCounties
      }
      return state
    },
    cleanCheckedCounties: (state, action) => initialState
  }
})

export const {
  setCheckedCounties,
  cleanCheckedCounties
} = sharingCountiesSlice.actions

export default sharingCountiesSlice.reducer
