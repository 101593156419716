import { createSlice } from '@reduxjs/toolkit'
import { captureData, storeInterviewData } from 'ducks/interviewSlice'

const initialState = {
  originalAnswers: {},
  answers: {},
}

const unsavedChangesSlice = createSlice({
  name: 'unsavedChanges',
  initialState,
  reducers: {
    clearUnsavedChangesSlice: (state, action) => initialState,
    clearUnsavedChanges: (state, action) => {
      state.answers = {}
    },
    updateOriginalAnswers: (state, action) => {
      // Now that we have successfully saved, we can update our
      // original version of each answer. It will now be the same
      // value that is in the database, so future changes to each
      // of these fields will reflect that it has changed appropriately.
      for (let change of Object.values(state.answers)) {
        state.originalAnswers[change.question_id] = change?.answer || null
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeInterviewData, (state, action) => {
        const originalAnswers = {}
        // Build an object that contains the initial answers for each
        // question without any other properties from the large initial
        // data load. This allows us to keep track of which future changes
        // need to be sent to the database, and which have been reverted
        // back to their initial values on the frontend prior to a save
        // being triggered.
        for (let question of Object.values(
          action.payload.questionsWithAnswers.payload
        )) {
          originalAnswers[question.question_id] = question?.answer || null
        }
        state.originalAnswers = originalAnswers
      })
      .addCase(captureData, (state, action) => {
        // Changes are generated inside of the captureFormData thunk
        // For each change, update state's value
        for (let change of action.payload) {
          const originalAnswer = state.originalAnswers[change.question_id]
          // If this change makes the value for this question_id
          // equivalent to its original value, then remove
          // this from the unsavedChanges object
          if (originalAnswer === change.answer) {
            delete state.answers[change.question_id]
          }
          // Otherwise add it to our unsavedChanges object
          else {
            state.answers[change.question_id] = change
          }
        }
      })
  },
})

export const {
  clearUnsavedChangesSlice,
  clearUnsavedChanges,
  saveOriginalValue,
  updateOriginalAnswers,
} = unsavedChangesSlice.actions

export default unsavedChangesSlice.reducer
