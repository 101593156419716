import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { discardChangesAndNavigate } from 'thunks/discardChangesAndNavigate'
import { resetNewUser } from 'ducks/adminSlice'

import routes from 'app/routes'
import Link from 'components/general/LinkButton'
import CalsawsLogo from 'components/header/CalsawsLogo'
import UserMenu from 'components/header/UserMenu'
import { colors } from 'app/theme'
import Can from 'app/Can'

const Container = styled.div`
  grid-area: header;
  -ms-grid-row: ${props => (props.banner ? '2' : '1')};
  -ms-grid-column: 2;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 3px solid ${colors.calsawsBlue};
  height: 79px;
`

const LinkContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  height: 100%;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledLinkButton = styled(Link)`
  && {
    flex: auto;
    margin: auto 25px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    cursor: pointer;
    :hover {
      color: ${colors.turquoise};
      cursor: pointer;
      text-decoration: none;
    }
    :focus {
      color: ${colors.turquoise};
      cursor: pointer;
      text-decoration: none;
    }
    :active {
      color: ${colors.turquoise};
      text-decoration: none;
    }
  }
`

const StyledHelpAndAdmin = styled(Link)`
  && {
    flex: auto;
    margin: auto 25px;
    text-align: right;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    cursor: pointer;
    :hover {
      color: ${colors.turquoise};
      cursor: pointer;
      text-decoration: none;
    }
    :focus {
      color: ${colors.turquoise};
      cursor: pointer;
      text-decoration: none;
    }
    :active {
      color: ${colors.turquoise};
      text-decoration: none;
    }
  }
`

const Header = React.memo(({ showContent = true, banner }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { roles } = useSelector(state => state.user)
  const { client_list, search, reports, help, administration, resources, BRE } = routes
  const handleClick = route => {
    dispatch(discardChangesAndNavigate(route.path, history))
    dispatch(resetNewUser())
  }
  return (
    <Container id='container' banner={banner}>
      {showContent ? (
        <>
          <LinkContainer>
            <Can
              roles={roles}
              perform='clientList:view'
              yes={() => {
                return (
                  <StyledLinkButton
                    testId='header-link-client-list'
                    description='Client List'
                    route={client_list.path}
                    currentPage={client_list.path === history.location.pathname}
                    defaultAction={() => handleClick(client_list)}
                  />
                )
              }}
            />
            <Can
              roles={roles}
              perform='clientSearch:view'
              yes={() => {
                return (
                  <StyledLinkButton
                    testId='header-link-client-search'
                    description='Client Search'
                    route={search.path}
                    currentPage={search.path === history.location.pathname}
                    defaultAction={() => handleClick(search)}
                  />
                )
              }}
            />
            <Can
              roles={roles}
              perform='reports:view'
              yes={() => {
                return (
                  <StyledLinkButton
                    testId='header-link-reports'
                    description='Reports'
                    route={reports.path}
                    currentPage={reports.path === history.location.pathname}
                    defaultAction={() => handleClick(reports)}
                  />
                )
              }}
            />
             <Can
              roles={roles}
              perform='resourcesHeaderNav:view'
              yes={() => {
                return (
                  <StyledLinkButton
              testId='header-link-resources'
              description='Resources'
              route={resources.path}
              currentPage={resources.path === history.location.pathname}
              defaultAction={() => handleClick(resources)}
                  />
                )
              }}
            />
             <Can
              roles={roles}
              perform='BRE:view'
              yes={() => {
                return (
                  <StyledHelpAndAdmin
                    testId='header-link-BRE'
                    description='BRE'
                    route={BRE.path}
                    currentPage={BRE.path === history.location.pathname}
                    defaultAction={() => handleClick(BRE)}
                  />
                )
              }}
            ></Can>
            <Can
              roles={roles}
              perform='user:list'
              yes={() => {
                return (
                  <StyledHelpAndAdmin
                    testId='header-link-admin'
                    description='Admin'
                    route={administration.path}
                    currentPage={administration.path === history.location.pathname}
                    defaultAction={() => handleClick(administration)}
                  />
                )
              }}
            ></Can>
             <Can
              roles={roles}
              perform='helpHeaderNav:view'
              yes={() => {
                return (
                  <StyledHelpAndAdmin
                  testId='header-link-help'
                  description='Help'
                  route={help.path}
                  currentPage={help.path === history.location.pathname}
                  defaultAction={() => handleClick(help)}
                />
                )
              }}
            ></Can>
           
          </LinkContainer>
          <div>
            <div>
              <CalsawsLogo />
            </div>
            <div>
              <UserMenu />
            </div>
          </div>
        </>
      ) : null}
    </Container>
  )
})

export default Header
