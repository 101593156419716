import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import AdminDropdown from './AdminDropdown'
import AdminText from './AdminText'

import { resetNewUser } from 'ducks/adminSlice'

const StyledButton = styled(Button)`
  && {
    margin: 20px 16px 16px 8px;
    display: inline-block;
    width: 113px;
  }
`
const GridContainer = styled(Grid)`
  margin-bottom: 8px;
`

const searchVarSelector = state => state.admin

const selectSearchVariables = createSelector(searchVarSelector, adminState => {
  const {
    secondaryRole,
    supervisor,
    sectionMembers,
    status,
    ...rest
  } = adminState

  const searchList = {}
  Object.entries(rest).forEach(([key, value]) => {
    if (rest[key]) {
      searchList[key] = value
    }
  })
  return searchList
})
const AdminManageFilterMenu = ({ dropdownValues, lazyQueries }) => {
  const dispatch = useDispatch()
  const searchVariables = useSelector(selectSearchVariables)

  const { regionOptions, orgLevelOptions, officeOptions } = dropdownValues
  const { queryOffices, manageUserSearch, queryRegions } = lazyQueries
  const { orgLevelCode } = useSelector(state => state.user)
  const { organization, region } = useSelector(state => state.admin)
  const STATE_WIDE = '00'
  const STATEWIDE_OPTION = 59

  const handleClear = () => dispatch(resetNewUser())

  // The search button has the lazy query callback attached to it directly.
  return (
    <>
      <form>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <AdminText label='First Name' field='firstName' />
          <AdminText label='Last Name' field='lastName' />
          <AdminDropdown
            query={queryRegions}
            label={'County'}
            field='organization'
            values={orgLevelOptions}
            disabled={orgLevelCode !== STATE_WIDE}
          />
          <AdminDropdown
            label={'Role'}
            field='role'
            multiple
            values={[
              { name: 'Case Worker', id: 'CW' },
              { name: 'Supervisor', id: 'SP' },
              { name: 'Administrator', id: 'AD' },
              { name: 'Executive', id: 'EX' },
              { name: 'QA', id: 'QA' },
            ]}
          />
        </GridContainer>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <AdminText label='Email' field='email'></AdminText>
          <AdminDropdown
            multiple
            query={queryOffices}
            disabled={!organization || organization === STATEWIDE_OPTION}
            label={'Region'}
            field='region'
            values={regionOptions}
          />
          <AdminDropdown
            disabled={!region || organization === STATEWIDE_OPTION}
            multiple
            label={'Office'}
            field='office'
            values={officeOptions}
          />
        </GridContainer>
        <GridContainer container justifyContent='flex-start' spacing={2}>
          <StyledButton
            onClick={handleClear}
            variant='contained'
            color='secondary'
          >
            Clear
          </StyledButton>
          <StyledButton
            onClick={() => {
              manageUserSearch(searchVariables)
            }}
            color='primary'
            variant='contained'
          >
            Search
          </StyledButton>
        </GridContainer>
      </form>
    </>
  )
}

export default AdminManageFilterMenu
