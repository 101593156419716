import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { resetNewUser, setShouldRefetch } from 'ducks/adminSlice'
import { openSnackbar } from 'ducks/snackbarSlice'
import fetchUser from './fetchUser'

const CREATE_USER = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      firstName
      lastName
      email
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser($userId: Int!, $data: UserInput!) {
    updateUser(userId: $userId, data: $data) {
      firstName
      lastName
      email
    }
  }
`

export const saveAdmin = (isNewUser, confirmedExtract = null) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().admin
      const currentUserID = getState().user.id
      if (isNewUser) {
        await client.mutate({
          mutation: CREATE_USER,
          variables: {
            data: {
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role,
              secondaryRole: user.secondaryRole,
              email: user.email,
              organization: user.organization,
              region: user.region,
              office: user.office,
              supervisor: user.supervisor?.value ?? null,
              dataExtract: confirmedExtract ? 1 : user.dataExtract,
              // Filter out blank rows and then create
              // an array of user ids.
              sectionMembers: user.sectionMembers
                ?.filter(member => member)
                .map(member => member?.value),
              status: 'active',
            },
          },
        })
        // Being that this is a new user, we need to let the queries know to refetch
        dispatch(setShouldRefetch(true))
        dispatch(resetNewUser())
      } else {
        await client.mutate({
          mutation: UPDATE_USER,
          variables: {
            userId: user.id,
            data: {
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role,
              secondaryRole: user.secondaryRole,
              email: user.email,
              organization: user.organization,
              region: user.region,
              office: user.office,
              supervisor: user.supervisor?.value ?? null,
              dataExtract: confirmedExtract ? 1 : user.dataExtract,
              // Filter out blank section member rows.
              // Create an array of user ids.
              sectionMembers: user.sectionMembers
                ?.filter(member => member)
                .map(member => member.value),
              status: user.status,
              disabledDate: user.disabledDate,
            },
          },
        })
      }
      // If the user modifies themself, re-fetch user data.
      if (user.id === currentUserID) dispatch(fetchUser())
      dispatch(openSnackbar('User saved!'))

      return true
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(resetNewUser())
      return false
    }
  }
}
