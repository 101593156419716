import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import InterviewListMutation from './InterviewListMutations'
import { openDialog } from 'ducks/dialogSlice'
import { openSnackbar } from 'ducks/snackbarSlice'
import AssigneeEditList from './AssigneeEditList'
import { colors } from 'app/theme'
import Can from 'app/Can'
import { decodeHtml } from '../../utilTools/decodeHtml'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
`

const NameContainer = styled.div`
  flex: 1 0 auto;
  text-align: left;
`

const StyledSave = styled(Button)`
  && {
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`

const StyledCancel = styled(Button)`
  && {
    background-color: ${colors.turquoise};
    color: ${colors.white};
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    flex: 0 1 auto;
    color: ${colors.calsawsBlue};
    background-color: transparent;
    &:hover {
      color: ${colors.saffron};
    }
  }
`

const StyledEditIcon = styled(EditIcon)`
  && {
    font-size: 20px;
    padding-bottom: 4px;
    &:hover {
      color: ${colors.saffron};
    }
  }
`

const EditAssignee = ({
  refetchInterviewList,
  index,
  assignee,
  countyId,
  interviewId,
  readonly,
}) => {
  const dispatch = useDispatch()
  const { id, roles } = useSelector(state => state.user)
  const [wantsToUpdate, setWantsToUpdate] = useState(false)
  const [shouldEdit, setShouldEdit] = useState(false)
  const [newAssignee, setNewAssignee] = useState(null)
  const [currentInterviewID, setCurrentInterviewID] = useState(null)
  const [updateAssignee, { loading, error }] = useMutation(
    InterviewListMutation.UPDATE_ASSIGNEE
  )

  const handleUpdateAssignee = async () => {
    await updateAssignee({
      variables: {
        interviewID: interviewId,
        userID: id,
        assigneeID: newAssignee.value.id,
      },
    })
    refetchInterviewList()
    setWantsToUpdate(false)
    setShouldEdit(false)
    dispatch(openSnackbar('Assignee updated!'))
  }

  const handleCheckSelect = () => {
    const assigneeNeedsUpdating = assignee?.value.id !== newAssignee?.value.id
    if (assigneeNeedsUpdating && newAssignee?.value) {
      setWantsToUpdate(true)
    } else {
      dispatch(openSnackbar('Please select a new assignee'))
    }
  }
  useEffect(() => {
    if (currentInterviewID !== interviewId) {
      setCurrentInterviewID(interviewId)
      setShouldEdit(false)
    }
  }, [currentInterviewID, interviewId])

  // Something went wrong with the query, notify the user
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  return (
    <Container>
      {shouldEdit ? (
        <AssigneeEditList
          assignee={assignee}
          setShouldEdit={setShouldEdit}
          setNewAssignee={setNewAssignee}
          handleCheckSelect={handleCheckSelect}
          countyId={countyId}
        />
      ) : (
        <>
          <NameContainer>
            {decodeHtml(assignee.label)}
            {!readonly && (
              <Can
                roles={roles}
                perform='interview:assign'
                yes={() => {
                  return (
                    <StyledIconButton
                      onClick={() => setShouldEdit(true)}
                      aria-label={`Edit button ${index}`}
                    >
                      <StyledEditIcon />
                    </StyledIconButton>
                  )
                }}
              />
            )}
          </NameContainer>
        </>
      )}

      <Dialog
        open={wantsToUpdate}
        onClose={() => setWantsToUpdate(false)}
        aria-labelledby='confirm-new-assignee'
        aria-describedby='confirm-new-assignee'
      >
        <DialogTitle>Confirm New Assignee</DialogTitle>
        <DialogContent>
          {!loading ? (
            <>
              <DialogContentText>
                Reassign interview to {newAssignee?.label}?
              </DialogContentText>
              <DialogActions>
                <StyledCancel onClick={() => setWantsToUpdate(false)}>
                  CANCEL
                </StyledCancel>
                <StyledSave onClick={() => handleUpdateAssignee()}>
                  SAVE
                </StyledSave>
              </DialogActions>
            </>
          ) : (
            <DialogContentText>Updating...</DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default EditAssignee
