import React from 'react'
import styled from 'styled-components'
import ocatLogo from 'images/ocat-logo.png'

const LogoImage = styled.div`
  grid-area: logo;
  -ms-grid-row: ${props => (props.banner ? '2' : '1')};
  -ms-grid-column: 1;
  background: url(${p => p.src}) no-repeat center/cover;
`

const Logo = React.memo(props => {
  return <LogoImage {...props} src={ocatLogo} />
})

export default Logo
