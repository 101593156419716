import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../app/theme'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from '../../ducks/dialogSlice'
import NavLink from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { setQuestionList, setSelectedQuestion } from 'ducks/questionSlice'
import { decodeHtml } from '../../utilTools/decodeHtml'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory } from 'react-router-dom'
import { setUpcomingChangeDate, setPendingChangesList } from 'ducks/pendingChangesSlice'

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const Container = styled.div`
  margin: 10px 0;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
  }
`
const StyledNavLink = styled(NavLink)`
  && {
    font-size: 14px;
    text-decoration: underline;
    :hover {
      color: ${colors.turquoise};
      cursor: pointer;
    }
    :focus {
      color: ${colors.turquoise};
      cursor: pointer;
    }
    :active {
      color: ${colors.turquoise};
      outline: 0;
    }
  }
`
const StyledListItem = styled(ListItem)`
  && {
    margin: 0 15px 15px 15px;
    padding: 0;
    height: 25px;
  }
`
const GET_QUESTION = gql`
  query getQuestions {
    getQuestions {
      questions {
        id
        questionID
        questionConfig
        subDomainCode
        subDomain
        domain
        questionType
        questionOrder
      }
    }
  }
`
const GET_PENDING_CHANGES = gql`
  query pendingChanges {
  pendingChanges {
    id
    changeType
    recordId
    changeDetails
    publishDate
    createdBy
    createdAt
    createUser
    rule
  }
}
`
const UPCOMING_CHANGE_DATE = gql`
query upcomingChangeDate {
  upcomingChangeDate
}
`
const buildQuestionElement = (question, index, handleClick) => {
  let description = ''
 if (question.questionType === 'checkbox') {
    description = question.questionConfig.options[0].optionLabel
 }
 else if (question.questionType === 'textbox' || 
          question.questionType ==='dropdown_label') {
    description = question.questionConfig.label
 } 
 else
 {
    description = question.questionConfig.description
 }
    
  return (
    <StyledListItem key={index}>
      <ListItemText  onClick={() => {
          handleClick(question, '/bre_question_details', description)
        }}
        >
        Question {question.id}:{' '}
        <StyledNavLink tabIndex='0'>{decodeHtml(description)}</StyledNavLink>
      </ListItemText>
    </StyledListItem>
  )
}

const QuestionList = () => {
  const questionList = useSelector(state => state.questionManagement.questionList)
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, data, error } = useQuery(GET_QUESTION, {
    fetchPolicy: 'network-only',
  })

  const { 
    data: changesData, 
    error: changesError 
  } = useQuery(GET_PENDING_CHANGES)

  const {
    data: upcomingChangeDateData,
    error: upcomingChangeDateError
  }= useQuery(UPCOMING_CHANGE_DATE)

  useEffect(() => {
    if (error || upcomingChangeDateError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
            upcomingChangeDateError
          },
        })
      )
    }
    if (data && upcomingChangeDateData) {
      dispatch(setQuestionList(data.getQuestions.questions),
      dispatch(setPendingChangesList(changesData.pendingChanges),
        dispatch(setUpcomingChangeDate(upcomingChangeDateData))))
    }
  }, [data, error, changesData, changesError, upcomingChangeDateError,upcomingChangeDateData, dispatch])

  const [expanded, setExpanded] = React.useState(false);
  const [internalExpanded, setInternalExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInternalChange = (panel) => (event, isInternalExpanded) => {
    setInternalExpanded(isInternalExpanded ? panel : false);
  };

  const demographicsGeneralInformation = questionList.filter(v => v.subDomainCode === "demographics_general_information").sort((a, b) => a.questionOrder - b.questionOrder)
  const demographicsGenderIdentity = questionList.filter(v => v.subDomainCode === "demographics_gender_identity").sort((a, b) => a.questionOrder - b.questionOrder)
  const demographicsHouseholdComposition = questionList.filter(v => v.subDomainCode === "demographics_household_composition").sort((a, b) => a.questionOrder - b.questionOrder)
  const demographicsFinancesAndBenefits = questionList.filter(v => v.subDomainCode === "demographics_finances_benefits").sort((a, b) => a.questionOrder - b.questionOrder)

  const employmentCurrentActivities = questionList.filter(v => v.subDomainCode === "employment_current_activities").sort((a, b) => a.questionOrder - b.questionOrder)
  const employmentReasonsNotWorking = questionList.filter(v => v.subDomainCode === "employment_reasons_not_working").sort((a, b) => a.questionOrder - b.questionOrder)
  const employmentJobHistory = questionList.filter(v => v.subDomainCode === "employment_job_history").sort((a, b) => a.questionOrder - b.questionOrder)
  const employmentWorkExperience = questionList.filter(v => v.subDomainCode === "employment_work_experience_interests").sort((a, b) => a.questionOrder - b.questionOrder)
  const employmentLegalBarriers = questionList.filter(v => v.subDomainCode === "employment_legal_barriers").sort((a, b) => a.questionOrder - b.questionOrder)

  const educationHighestGrade = questionList.filter(v => v.subDomainCode === "education_highest_grade").sort((a, b) => a.questionOrder - b.questionOrder)
  const educationDiplomaDegreeCertficate = questionList.filter(v => v.subDomainCode === "education_diploma_degree_certifications").sort((a, b) => a.questionOrder - b.questionOrder)
  const educationOtherEducation = questionList.filter(v => v.subDomainCode === "education_other_education").sort((a, b) => a.questionOrder - b.questionOrder)
  const educationLearningNeedsIndicator = questionList.filter(v => v.subDomainCode === "education_learning_needs_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const educationLearningNeedsAssessment = questionList.filter(v => v.subDomainCode === "education_learning_needs_sub_section").sort((a, b) => a.questionOrder - b.questionOrder)

  const housingIndicator = questionList.filter(v => v.subDomainCode === "housing_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const housingCurrentHousing = questionList.filter(v => v.subDomainCode === "housing_current_housing").sort((a, b) => a.questionOrder - b.questionOrder)
  const housingSituation = questionList.filter(v => v.subDomainCode === "housing_situation").sort((a, b) => a.questionOrder - b.questionOrder)

  const transportationIndicator = questionList.filter(v => v.subDomainCode === "transportation_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const transportationMethod = questionList.filter(v => v.subDomainCode === "transportation_method").sort((a, b) => a.questionOrder - b.questionOrder)
  const transportationDrivingLicense = questionList.filter(v => v.subDomainCode === "transportation_driving_license").sort((a, b) => a.questionOrder - b.questionOrder)
  const transportationChallenge = questionList.filter(v => v.subDomainCode === "transportation_challenge").sort((a, b) => a.questionOrder - b.questionOrder)

  const generalHealthIndicator = questionList.filter(v => v.subDomainCode === "general_health_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)

  const mentalHealthIndicator = questionList.filter(v => v.subDomainCode === "emotional_and_mental_health_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const mentalHealthCurrentStatus = questionList.filter(v => v.subDomainCode === "emotional_mental_health_current_status").sort((a, b) => a.questionOrder - b.questionOrder)
  const mentalHealthWrapUp = questionList.filter(v => v.subDomainCode === "emotional_mental_health_wrap_up").sort((a, b) => a.questionOrder - b.questionOrder)

  const substanceUseIndicator = questionList.filter(v => v.subDomainCode === "substance_use_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const substanceUseAlcoholDrugs = questionList.filter(v => v.subDomainCode === "substance_use_alcohol_drugs").sort((a, b) => a.questionOrder - b.questionOrder)
  const substanceUseHousehold = questionList.filter(v => v.subDomainCode === "substance_use_in_household").sort((a, b) => a.questionOrder - b.questionOrder)

  const domesticAbuseIndicator = questionList.filter(v => v.subDomainCode === "domestic_abuse_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const domesticAbuseVictim = questionList.filter(v => v.subDomainCode === "domestic_abuse_victim").sort((a, b) => a.questionOrder - b.questionOrder)
  const domesticAbuseSafetyConcerns = questionList.filter(v => v.subDomainCode === "domestic_abuse_safety_concerns_victim").sort((a, b) => a.questionOrder - b.questionOrder)
  const domesticAbusePerpetrator = questionList.filter(v => v.subDomainCode === "domestic_abuse_perpetrator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const domesticAbuseHumanTrafficking = questionList.filter(v => v.subDomainCode === "domestic_abuse_trafficking").sort((a, b) => a.questionOrder - b.questionOrder)

  const pregnancyIndicator = questionList.filter(v => v.subDomainCode === "pregnancy_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const pregnancyQuestions = questionList.filter(v => v.subDomainCode === "pregnancy").sort((a, b) => a.questionOrder - b.questionOrder)

  const childCareIndicator = questionList.filter(v => v.subDomainCode === "child_care_parenting_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareIssues = questionList.filter(v => v.subDomainCode === "child_care_parenting_childrens_issues").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareCaregiver = questionList.filter(v => v.subDomainCode === "child_care_parenting_primary_caregiver").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareChildSupport = questionList.filter(v => v.subDomainCode === "child_care_parenting_parenting_child_support").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareChildCare = questionList.filter(v => v.subDomainCode === "child_care_parenting_child_care").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareChildCareStatus = questionList.filter(v => v.subDomainCode === "child_care_parenting_child_care_status").sort((a, b) => a.questionOrder - b.questionOrder)
  const childCareBackup = questionList.filter(v => v.subDomainCode === "child_care_parenting_backup_child_care_plan").sort((a, b) => a.questionOrder - b.questionOrder)

  const relationshipsIndicator = questionList.filter(v => v.subDomainCode === "relationship_indicator_questions").sort((a, b) => a.questionOrder - b.questionOrder)
  const relationshipsRelationships = questionList.filter(v => v.subDomainCode === "relationships").sort((a, b) => a.questionOrder - b.questionOrder)

  const finalThoughtsClosingQuestion = questionList.filter(v => v.subDomainCode === "final_closing_question").sort((a, b) => a.questionOrder - b.questionOrder)

  const handleClick = (question, path, description) => {
    dispatch(
      setSelectedQuestion({
        ...question
      })
    )

    if (history.location.pathname !== path) {
      history.push(path)
    }
  }

  return (
    <>
      <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle variant='h4'>Manage Questions</SectionTitle>
          <Typography variant='body1'>
            Select a domain and subdomain to view/edit questions.
          </Typography>
        </Grid>
        {loading ? (
          <Container>
            <div>Loading Question List...</div>
          </Container>
        ) : (
          <Container>
            <Grid item xs={12}>
              {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'demographics'} onChange={handleChange('demographics')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Demographics</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'generalInformation'} onChange={handleInternalChange('generalInformation')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>General Information</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {demographicsGeneralInformation.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                           </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'genderIdentity'} onChange={handleInternalChange('genderIdentity')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Gender Identity</SectionTitle>
                                </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {demographicsGenderIdentity.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                               </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'householdComposition'} onChange={handleInternalChange('householdComposition')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Household Composition</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                              <List dense>
                                {demographicsHouseholdComposition.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'householdComposition'} onChange={handleInternalChange('householdComposition')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Finances/Benefits</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                            <List dense>
                              {demographicsFinancesAndBenefits.map((v, i) => {
                                return buildQuestionElement(v, i, handleClick)
                              })}
                            </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'employment'} onChange={handleChange('employment')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Employment</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'currentActivities'} onChange={handleInternalChange('currentActivities')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                               <SectionTitle>Current Activities</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {employmentCurrentActivities.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'reasonsNotWorking'} onChange={handleInternalChange('reasonsNotWorking')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Reasons Not Working</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                              <List dense>
                                {employmentReasonsNotWorking.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                            </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'jobHistory'} onChange={handleInternalChange('jobHistory')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Job History</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                              <List dense>
                                {employmentJobHistory.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'jobHistory'} onChange={handleInternalChange('jobHistory')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Work Experience and Interests</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {employmentWorkExperience.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'legalBarriers'} onChange={handleInternalChange('legalBarriers')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Legal Barriers</SectionTitle>
                              </AccordionSummary>
                                  <AccordionDetails>
                              <List dense>
                                {employmentLegalBarriers.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'education'} onChange={handleChange('education')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Education</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'highestGrade'} onChange={handleInternalChange('highestGrade')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Highest Grade</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {educationHighestGrade.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'diplomaDegreeCertification'} onChange={handleInternalChange('diplomaDegreeCertification')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Diploma, Degree, Certification</SectionTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                <List dense>
                                  {educationDiplomaDegreeCertficate.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                                </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'otherEducation'} onChange={handleInternalChange('otherEducation')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Other Education, Training, Certification</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {educationOtherEducation.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'learningNeedsIndicators'} onChange={handleInternalChange('learningNeedsIndicators')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Learning Needs Indicator Questions</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {educationLearningNeedsIndicator.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                                </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'learningNeedsAssessment'} onChange={handleInternalChange('learningNeedsAssessment')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <SectionTitle>Learning Needs Assessment</SectionTitle>
                              </AccordionSummary>
                                    <AccordionDetails>
                              <List dense>
                                {educationLearningNeedsAssessment.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                                })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
                {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'housing'} onChange={handleChange('housing')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Housing</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'housingIndicator'} onChange={handleInternalChange('housingIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {housingIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'housingCurrentHousing'} onChange={handleInternalChange('housingCurrentHousing')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Current Housing</SectionTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                <List dense>
                                  {housingCurrentHousing.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                                </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'housingSituation'} onChange={handleInternalChange('housingSituation')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Housing Situation</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {housingSituation.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'transportation'} onChange={handleChange('transportation')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Transportation</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'transportationIndicator'} onChange={handleInternalChange('transportationIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {transportationIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'transportationMethod'} onChange={handleInternalChange('transportationMethod')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Transportation Method</SectionTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                <List dense>
                                  {transportationMethod.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                                </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'transportationDrivingLicense'} onChange={handleInternalChange('transportationDrivingLicense')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Driving License</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {transportationDrivingLicense.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                          <Accordion internalExpanded={internalExpanded === 'transportationChallenge'} onChange={handleInternalChange('transportationChallenge')}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <SectionTitle>Transportation Challenge</SectionTitle>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {transportationChallenge.map((v, i) => {
                                    return buildQuestionElement(v, i, handleClick)
                                  })}
                              </List>
                              </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'generalHealth'} onChange={handleChange('generalHealth')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>General Health</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'generalHealthIndicator'} onChange={handleInternalChange('generalHealthIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {generalHealthIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
              {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'mentalHealth'} onChange={handleChange('mentalHealth')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Emotional and Mental Health</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'mentalHealthIndicator'} onChange={handleInternalChange('mentalHealthIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {mentalHealthIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'mentalHealthCurrentStatus'} onChange={handleInternalChange('mentalHealthCurrentStatus')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Current Status</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {mentalHealthCurrentStatus.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'mentalHealthWrapUp'} onChange={handleInternalChange('mentalHealthWrapUp')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Wrap Up</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {mentalHealthWrapUp.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
              {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'substanceUse'} onChange={handleChange('substanceUse')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Substance Use</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'substanceUseIndicator'} onChange={handleInternalChange('substanceUseIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {substanceUseIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'substanceUseAlcoholDrugs'} onChange={handleInternalChange('substanceUseAlcoholDrugs')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Alcohol and Drugs</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {substanceUseAlcoholDrugs.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'substanceUseHousehold'} onChange={handleInternalChange('substanceUseHousehold')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Household/By Others</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {substanceUseHousehold.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
              {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'domesticAbuse'} onChange={handleChange('domesticAbuse')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Domestic Abuse</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'domesticAbuseIndicator'} onChange={handleInternalChange('domesticAbuseIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {domesticAbuseIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'domesticAbuseVictim'} onChange={handleInternalChange('domesticAbuseVictim')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Victim Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {domesticAbuseVictim.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'domesticAbuseSafety'} onChange={handleInternalChange('domesticAbuseSafety')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Safety Concerns</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {domesticAbuseSafetyConcerns.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'domesticAbusePerpetrator'} onChange={handleInternalChange('domesticAbusePerpetrator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Perpetrator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {domesticAbusePerpetrator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'domesticAbuseHumanTrafficking'} onChange={handleInternalChange('domesticAbuseHumanTrafficking')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Human Trafficking Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {domesticAbuseHumanTrafficking.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
              {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'pregnancy'} onChange={handleChange('pregnancy')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Pregnancy</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'pregnancyIndicator'} onChange={handleInternalChange('pregnancyIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {pregnancyIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'pregnancyQuestions'} onChange={handleInternalChange('pregnancyQuestions')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Pregnancy Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {pregnancyQuestions.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'childCareAndParenting'} onChange={handleChange('childCareAndParenting')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Child Care and Parenting</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareIndicator'} onChange={handleInternalChange('childCareIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareIssues'} onChange={handleInternalChange('childCareIssues')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Children's Issues</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareIssues.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareCaregiver'} onChange={handleInternalChange('childCareCaregiver')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Primary Caregiver</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareCaregiver.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareChildSupport'} onChange={handleInternalChange('childCareChildSupport')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Parenting and Child Support</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareChildSupport.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareChildCare'} onChange={handleInternalChange('childCareChildCare')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Child Care</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareChildCare.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareStatus'} onChange={handleInternalChange('childCareStatus')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Child Care Status</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareChildCareStatus.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'childCareBackup'} onChange={handleInternalChange('childCareBackup')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Backup Child Care Plan</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {childCareBackup.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'relationships'} onChange={handleChange('relationships')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Relationships</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'relationshipsIndicator'} onChange={handleInternalChange('relationshipsIndicator')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Indicator Questions</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {relationshipsIndicator.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'relationshipsRelationships'} onChange={handleInternalChange('relationshipsRelationships')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Relationships</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {relationshipsRelationships.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
               {questionList.length > 0 && (
                  <Accordion expanded={expanded === 'finalThoughts'} onChange={handleChange('finalThoughts')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SectionTitle variant='h6'>Final Thoughts</SectionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Grid item xs={12}>
                        <Accordion internalExpanded={internalExpanded === 'finalThoughtsClosingQuestion'} onChange={handleInternalChange('finalThoughtsClosingQuestion')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SectionTitle>Closing Question</SectionTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List dense>
                                {finalThoughtsClosingQuestion.map((v, i) => {
                                  return buildQuestionElement(v, i, handleClick)
                              })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Container>          
                    </AccordionDetails>
                  </Accordion>
              )}
            </Grid>
          </Container>
        )}
      </Grid>
    </>
  )
}

export default QuestionList
