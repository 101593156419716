import NumberFormat from 'react-number-format'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { createSelector } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import InputAdornment from '@material-ui/core/InputAdornment'
import TableCell from '@material-ui/core/TableCell'
import ControlDescription from 'components/general/ControlDescription'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import validation from 'validation/specialCharacterValidator'

const CustomTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
    width: ${p => p.width};
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  && {
    padding-bottom: 7px;
  }
`

// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const dataSelector = (state, question_id) => state.interview[question_id]
const previewDataSelector = (state, question_id) => state.preview[question_id]
const createDataSelector = (index, sub_question_id, isPreview) => {
  return createSelector(
    isPreview ? previewDataSelector : dataSelector,
    data => {
      return {
        tableAnswers: data.answer || [],
        description: data.sub_question_ids[sub_question_id]?.description || '',
        // Don't rely on the answer, convert to appropriate
        // 'falsy' value for this input (empty string)
        subQuestionAnswer:
          (data.answer &&
            index !== null &&
            data.answer[index][sub_question_id]) ||
          (data.answer && data.answer[index][sub_question_id] === 0)
            ? data.answer[index][sub_question_id]
            : '',
        label: data.sub_question_ids[sub_question_id]?.label || '',
      }
    }
  )
}

// Helpers

// Uses the third party library 'react-number-format' for additional custom formatting
// https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries

// Accepts additionalSettings from the more specific helpers to handle the various specific formatting/restriction requirements
// for each of the different types of input
const baseInputFactory = (props, additionalSettings) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      {...additionalSettings}
    />
  )
}

const currencyInput = props => {
  const additionalSettings = {
    thousandSeparator: true,
    isNumericString: true,
  }
  return baseInputFactory(props, additionalSettings)
}

const numberInput = props => {
  const additionalSettings = {
    thousandSeparator: true,
    isNumericString: true,
  }
  return baseInputFactory(props, additionalSettings)
}

const phoneInput = props => {
  const additionalSettings = {
    format: '(###) ###-####',
    allowEmptyFormatting: true,
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

const ssnInput = props => {
  const additionalSettings = {
    format: '###-##-####',
    allowEmptyFormatting: true,
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

const shortDateInput = props => {
  const additionalSettings = {
    format: '##/####',
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}
const dateInput = props => {
  const additionalSettings = {
    format: '##/##/####',
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}
// Determines what type of Input is required, and selects the appropriate inputComponent (from react-number-format) to supply to the material-ui input
const calculateInputProps = type => {
  switch (type) {
    case 'currency':
      return {
        inputComponent: currencyInput,
        startAdornment: (
          <StyledInputAdornment position='start'>$</StyledInputAdornment>
        ),
      }
    case 'phone':
      return { inputComponent: phoneInput }
    case 'ssn':
      return { inputComponent: ssnInput }
    case 'number':
      return { inputComponent: numberInput }
    case 'short date':
      return { inputComponent: shortDateInput }
    case 'date':
      return { inputComponent: dateInput }
    case 'text':
    default:
      return {}
  }
}

const checkForDate = (type, input) => {
  if (type === 'date' && input) {
    if (input.length >= 4) {
      return input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4)
    } else if (input.length >= 2) {
      return input.slice(0, 2) + '/' + input.slice(2)
    }
  } else if (type === 'short date' && input && input.length >= 2) {
    return input.slice(0, 2) + '/' + input.slice(2)
  } else {
    return input
  }
}

const unformatDate = date => {
  return date.replace('/', '')
}

const InfiniteText = ({
  question_id,
  type,
  isDisabled,
  variant,
  multiline,
  rows,
  index,
  sub_question_id,
  hideDescription = true,
  hideLabel = true,
  noTable = false,
  width = '250px',
  textOptions = {},
  className,
  defaultValue = '',
  shouldDecode = false,
  isPreview,
}) => {
  const dispatch = useDispatch()
  // Create a selector for each instance of the Text component.
  // Fetch data from redux store using selector
  const dataSelector = createDataSelector(index, sub_question_id, isPreview)
  const { description, label, subQuestionAnswer, tableAnswers } = useSelector(
    state => dataSelector(state, question_id)
  )
  const cancelledOrCompletedInterview = useSelector(
    isPreview
      ? previewCancelledOrCompletedSelector
      : cancelledOrCompletedSelector
  )
  const { roles } = useSelector(state => state.user)

  let inputVariant
  if (variant) {
    inputVariant = variant
  } else if (type === 'currency') {
    inputVariant = 'standard'
  } else {
    inputVariant = 'outlined'
  }

  // Label for ADA
  const inputId = `${sub_question_id}-input-${index}`

  // Local data for storing updates while waiting for debounce
  const answer =
    type === 'date' || type === 'short date'
      ? unformatDate(subQuestionAnswer)
      : subQuestionAnswer
  const [localData, setLocalData] = useState(answer)

  const sendData = useCallback(
    value => {
      let updatedTableAnswers = tableAnswers.map(answer => {
        const rowAnswers = {}
        Object.keys(answer).forEach(
          sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
        )
        return rowAnswers
      })
      if (index !== null) {
        updatedTableAnswers[index][sub_question_id] = value
        dispatch(
          isPreview
            ? capturePreviewFormData({
                question_id,
                answer: updatedTableAnswers,
              })
            : captureFormData({ question_id, answer: updatedTableAnswers })
        )
      }
    },
    [dispatch, index, isPreview, question_id, sub_question_id, tableAnswers]
  )

  // We don't want to read/write from store on each keystroke,
  // so instead we debounce the saving of data to redux to once
  // every 500ms. Local state is used for display to the user,
  // so saving still appears instantaneous.

  // tableAnswers added as a dependency to allow
  // See: https://reactjs.org/docs/hooks-reference.html#usecallback
  const delayedSave = useMemo(
    () => debounce(value => sendData(value), 300),
    [sendData]
  )

  // useEffect ensures that local state is overwritten whenever
  // Redux changes, and only re-renders when redux changes.
  useEffect(() => {
    if (type === 'date' || type === 'short date') {
      const formattedDate = unformatDate(subQuestionAnswer)
      setLocalData(formattedDate)
    } else {
      if (subQuestionAnswer || !defaultValue) {
        setLocalData(subQuestionAnswer)
      } else {
        setLocalData(defaultValue)
      }
    }
  }, [subQuestionAnswer, type, defaultValue, delayedSave])

  const handleChange = event => {
    if (cancelledOrCompletedInterview) return
    if (!validation.hasSpecialCharacter(event.target.value)) {
      setLocalData(event.target.value)
      const screenedAnswer = checkForDate(type, event.target.value)
      delayedSave(screenedAnswer)
    }
  }

  const inputProps = calculateInputProps(type)
  const inputLabelProps = type === 'phone' ? { shrink: true } : {}
  const canDecode =
    localData && (type === 'text' || type === undefined) && shouldDecode
  const displayLabel = !hideLabel && decodeHtml(label)
  const textField = (
    <div className={className}>
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <>
              {!hideDescription && description && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomTextField
                InputLabelProps={inputLabelProps}
                inputProps={{
                  'aria-label': `Text field ${displayLabel} ${inputId}`,
                }}
                type='text'
                disabled={true}
                multiline={multiline}
                id={inputId}
                variant={inputVariant}
                label={displayLabel || undefined}
                value={canDecode ? decodeHtml(localData) : localData}
                rows={rows}
                InputProps={inputProps}
                width={width}
                {...textOptions}
                autoComplete='off'
              />
            </>
          )
        }}
        yes={() => {
          return (
            <>
              {!hideDescription && description && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomTextField
                InputLabelProps={inputLabelProps}
                inputProps={{
                  'aria-label': `Text field ${displayLabel} ${inputId}`,
                }}
                type='text'
                disabled={cancelledOrCompletedInterview || isDisabled}
                multiline={multiline}
                id={inputId}
                variant={inputVariant}
                label={displayLabel || undefined}
                value={canDecode ? decodeHtml(localData) : localData}
                rows={rows}
                onChange={handleChange}
                InputProps={inputProps}
                width={width}
                {...textOptions}
                autoComplete='off'
              />
            </>
          )
        }}
      />
    </div>
  )

  if (noTable) {
    return textField
  }
  return <TableCell align='center'>{textField}</TableCell>
}

export default InfiniteText
