import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/react-hooks'

import ClientListQueries from './ClientListQueries'
import ClientList from './ClientList'
import { openDialog } from 'ducks/dialogSlice'
import {
  setClientListData,
  changePageIndex,
  changePageSize,
  changeSort,
  changeCurrentRow,
  initializeClientListForUser,
  changeSearchCriteria,
  changeLocalFilters,
} from 'ducks/clientListSlice'
import {
  getFilterValues,
  getDateRange,
  SPECIAL_VALUES,
  FILTER_TYPES,
} from './clientListHelper'

const ClientListDataLoader = () => {
  const dispatch = useDispatch()
  const { id, countyId, isStatewide } = useSelector(state => state.user)

  const {
    clientListPageIndex,
    clientListPageSize,
    clientListInputFilters,
    clientListServerFilters,
    clientListTotalRecordCount,
    clientListRecords,
    clientListOrderBy,
    clientListOrder,
    clientListCurrentRow,
  } = useSelector(state => state.clientList)
  const [
    getInterviewers,
    { data: interviewerData, error: interviewerError },
  ] = useLazyQuery(ClientListQueries.INTERVIEWER_QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getInterviewers({
      variables: {
        isStatewide,
        countyId,
      },
    })
  }, [isStatewide, countyId, getInterviewers])

  const [
    getClientList,
    {
      loading: clientListLoading,
      error: clientListError,
      data: clientListData,
    },
  ] = useLazyQuery(ClientListQueries.FEED_QUERY_PAGING, {
    fetchPolicy: 'network-only',
  })

  // initialize the search filters to set the current user, and county
  useEffect(() => {
    if (!isStatewide) {
      dispatch(
        initializeClientListForUser({
          userId: id,
          userCountyId: countyId,
        })
      )
    }
  }, [id, countyId, dispatch, isStatewide])

  useEffect(() => {
    const { interviewer, status, dates, countyFilter } = clientListServerFilters
    const pageIndex = clientListPageIndex
    const pageSize = clientListPageSize

    let startDate = ''
    let endDate = ''

    if (dates && dates.toLowerCase() !== 'all dates') {
      const { start, end } = getDateRange(dates.toLowerCase())

      if (start) {
        startDate = start.toISOString()
      }

      if (end) {
        endDate = end.toISOString()
      }
    }

    const allSelected =
      interviewer.find(
        interviewerId => interviewerId === SPECIAL_VALUES.ALL_OPTION_ID
      ) !== undefined
    const allSpecificInterviewers = interviewer
      .filter(
        interviewerId =>
          interviewerId !== SPECIAL_VALUES.UNASSIGNED_OPTION_ID &&
          interviewerId !== SPECIAL_VALUES.ALL_OPTION_ID
      )
      .map(item => parseInt(item))
    const excludeUnassigned =
      !allSelected &&
      !interviewer.find(
        interviewerId => interviewerId === SPECIAL_VALUES.UNASSIGNED_OPTION_ID
      )

    const interviewerFilter =
      allSpecificInterviewers.length > 0
        ? allSpecificInterviewers.map(item => parseInt(item))
        : allSelected
        ? []
        : [-1] // we need to request an impossible id, given an empty array the server will assume no filter is intended
    const county = countyFilter ?? countyId
    if (county)
      getClientList({
        variables: {
          countyId: county,
          pageIndex,
          pageSize,
          statusFilterValues: status,
          startDate,
          endDate,
          interviewerFilterValues: interviewerFilter,
          excludeUnassigned,
          sortBy: clientListOrderBy,
          sortAscending: clientListOrder === 'asc',
        },
      })
  }, [
    clientListServerFilters,
    clientListPageIndex,
    clientListPageSize,
    countyId,
    getClientList,
    clientListOrderBy,
    clientListOrder,
    dispatch,
  ])

  const handlePage = newPageIndex => {
    dispatch(changePageIndex(newPageIndex))
  }

  const handleRowsPerPage = rowsPerPage => {
    dispatch(changePageSize(rowsPerPage))
  }

  const handleFilterChange = (filter, type) => {
    const shouldUseRawValues =
      type === FILTER_TYPES.DATES || type === FILTER_TYPES.COUNTY
    const filterValues = shouldUseRawValues ? filter : getFilterValues(filter)
    switch (type) {
      case FILTER_TYPES.INTERVIEWER:
      case FILTER_TYPES.STATUS: {
        const updatedFilters = {
          ...clientListServerFilters,
          [type]: filterValues,
        }
        dispatch(changeSearchCriteria(updatedFilters))
        return
      }
      case FILTER_TYPES.DATES: {
        const updatedFilters = { ...clientListServerFilters, [type]: filter }
        dispatch(changeSearchCriteria(updatedFilters))
        return
      }
      case FILTER_TYPES.COUNTY: {
        const updatedFilters = {
          ...clientListServerFilters,
          countyFilter: filter,
        }
        dispatch(changeSearchCriteria(updatedFilters))
        return
      }
      default:
        return
    }
  }

  const handleInputFilterChange = inputFilters => {
    dispatch(changeLocalFilters(inputFilters))
  }

  const handleSelectedRowIndexChange = rowIndex => {
    dispatch(changeCurrentRow(rowIndex))
  }

  const handleSortChange = newSort => {
    dispatch(changeSort(newSort))
  }

  const errorOccurred = interviewerError || clientListError

  // If there is an error returning client list data, show the error as a modal
  useEffect(() => {
    if (errorOccurred) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error: errorOccurred,
          },
        })
      )
    }
    if (clientListData) {
      dispatch(setClientListData(clientListData.interviewsByCountyPage))
    }
  }, [errorOccurred, clientListData, dispatch])

  const sort = { order: clientListOrder, orderBy: clientListOrderBy }

  return (
    <>
      <ClientList
        loading={clientListLoading}
        records={clientListRecords || []}
        handlePage={handlePage}
        handleRowsPerPage={handleRowsPerPage}
        page={clientListPageIndex}
        rowsPerPage={clientListPageSize}
        totalRecordCount={clientListTotalRecordCount}
        filters={clientListServerFilters}
        filtersChanged={handleFilterChange}
        interviewers={interviewerData?.getInterviewers ?? []}
        inputFilters={clientListInputFilters}
        handleInputFiltersChanged={handleInputFilterChange}
        selectedRowIndex={clientListCurrentRow}
        handleSelectedRowIndexChange={handleSelectedRowIndexChange}
        sort={sort}
        handleSortChange={handleSortChange}
      />
    </>
  )
}

export default ClientListDataLoader
