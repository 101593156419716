import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { setInterviewProgress } from 'ducks/interviewProgressSlice'
import find from 'lodash/find'

// Create an answer based on the unsaved data in state
const SET_PAGE_VISITED = gql`
  mutation handleInterviewProgress($data: InterviewProgressCreateInput!) {
    handleInterviewProgress(data: $data) {
      domain
    }
  }
`

const COMPLETED_CANCELLED = ['complete', 'cancelled']

export const setPageVisited = subdomainId => {
  return async (dispatch, getState) => {
    // If the subdomain is already marked as visited, there is no need to mark it again

    if (
      getState().interviewProgress[subdomainId] ||
      COMPLETED_CANCELLED.includes(getState().client.currentInterviewStatus)
    ) {
      return
    }

    try {
      let subDomain = null

      getState().navigation.structure.forEach(category => {
        category.domains.forEach(domain => {
          const foundSubDomain = find(domain.subDomains, slotSubdomain => {
            return slotSubdomain.code === subdomainId
          })

          subDomain = foundSubDomain ? foundSubDomain : subDomain
        })
      })

      const payload = {
        visited: 1,
        subdomain: subdomainId,
        domain: subDomain.domain,
        interview_id: getState().client.currentInterview,
      }

      // Send the array of answers off to the graphql server to update interview data
      await client.mutate({
        mutation: SET_PAGE_VISITED,
        variables: {
          data: payload,
        },
      })

      // Update the interviewProgress slice
      dispatch(setInterviewProgress(payload))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
