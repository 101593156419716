import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ResourceTable from 'components/resources/ResourceTable'
import { openDialog } from 'ducks/dialogSlice'
import ClientListQueries from '../clientList/ClientListQueries'
import { useQuery } from '@apollo/react-hooks'
import { setCountyId } from 'ducks/resourceSlice'
import { getResources } from 'thunks/resourcesThunks'
import CustomDropdown from 'components/clientSearch/SearchDropdown'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-between;
`

const CountyDropdown = ({ countyId, setCountyName }) => {
  const dispatch = useDispatch()
  // load the counties
  const { data } = useQuery(ClientListQueries.COUNTIES_LIST_QUERY, {
    onError: error => {
      openDialog({
        type: 'error',
        title: 'System Error',
        props: {
          error,
        },
      })
    },
  })
  const counties =
    data?.counties?.map(county => ({
      optionId: county.id,
      optionLabel: county.description,
    })) || []
  const handleChange = e => {
    dispatch(setCountyId(e.target.value))
    const countyName = counties.find(
      county => county.optionId === e.target.value
    )?.optionLabel
    setCountyName(countyName)
  }
  return (
    <CustomDropdown
      label='County'
      value={countyId || ''}
      handleChange={handleChange}
      options={counties}
    />
  )
}

function Resources() {
  const dispatch = useDispatch()
  const { currentResourceType, countyId, resourceTypes } = useSelector(
    state => state.resources
  )
  const {
    countyId: userCountyId,
    countyName: userCounty,
    isStatewide,
    roles,
  } = useSelector(state => state.user)
  const editable = roles.find(role => role.code === 'AD')
  const [countyName, setCountyName] = useState('')

  //load the resources
  useEffect(() => {
    if (!isStatewide) dispatch(setCountyId(userCountyId))
  }, [isStatewide, dispatch, userCountyId])
  const selectedCountyName = isStatewide ? countyName : userCounty
  const showTable = (isStatewide && countyId) || !isStatewide

  useEffect(() => {
    if (countyId && resourceTypes) {
      dispatch(getResources())
    }
  }, [countyId, dispatch, resourceTypes])

  return (
    <>
      {showTable && (
        <Container>
          <Typography variant='h3'>
            {currentResourceType} {selectedCountyName}
          </Typography>
        </Container>
      )}
      {isStatewide && (
        <Container>
          <CountyDropdown countyId={countyId} setCountyName={setCountyName} />
        </Container>
      )}
      {showTable && (
        <ResourceTable
          description='Resources'
          isDisabled={false}
          editable={editable}
        />
      )}
    </>
  )
}

export default Resources
