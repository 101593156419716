import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    captureData: (state, action) => {
      // Changes are generated inside of the captureFormData thunk
      // For each change, update state's value
      for (let change of action.payload) {
        state[change.question_id].answer = change.answer
        state[change.question_id].indicator = change.indicator
      }
    },
    clearInterviewData: (state, action) => initialState,
    storeInterviewData: (state, action) =>
      action.payload.questionsWithAnswers.payload,
    revertInterviewData: (state, action) => {
      Object.keys(action.payload.unsavedChanges).forEach(questionId => {
        const originalValue = action.payload.originalAnswers[questionId]
        // Set current interview data to its original value
        state[questionId].answer = originalValue
      })
    },
  },
})

export const {
  captureData,
  storeInterviewData,
  revertInterviewData,
  clearInterviewData,
} = interviewSlice.actions

export default interviewSlice.reducer
