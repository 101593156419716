import React from 'react'
import { colors } from 'app/theme'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import { Grid } from '@material-ui/core'

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  && {
    font-size: 18px;
    color: ${colors.calsawsBlue};
  }
`
const StyledIconButton = styled(IconButton)`
  && {
    padding-bottom: 15px;
    margin: 0;

    &:hover {
      & svg {
        color: ${colors.saffron};
      }
    }
  }
`

const SharingButton = ({ rowIndex, interviewId, clientId, countyId }) => {
  const dispatch = useDispatch()
  return (
    <Grid item>
      <StyledIconButton
        aria-label={`Sharing button ${rowIndex}`}
        onClick={() =>
          dispatch(
            openDialog({
              type: 'sharingCounties',
              title: `Share Interview with Selected Counties`,
              props: { interviewId, clientId, countyId },
            })
          )
        }
      >
        <StyledFontAwesomeIcon icon={faShareSquare} />
      </StyledIconButton>
    </Grid>
  )
}

export default SharingButton
