import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import { saveSharingCounties } from 'thunks/saveSharingCounties'
import {
  setCheckedCounties,
  cleanCheckedCounties,
} from 'ducks/sharingCountiesSlice'
import { useQuery } from '@apollo/react-hooks'
import InterviewListQuery from 'components/interviewList/InterviewListQueries'
import MultiSelectDropdown from 'components/clientList/MultiSelectDropdown'

import { Button } from '@material-ui/core'
import { openDialog } from 'ducks/dialogSlice'
import { openSnackbar } from 'ducks/snackbarSlice'

const Label = styled.label`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`

const StyledMenuPortal = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
`
const outsidePortalDomNodeID = 'sharing-dialog-portal'

export const SharingCountiesDialogContent = ({
  interviewId,
  countyId: userCountyId,
}) => {
  const dispatch = useDispatch()
  const checkedCounties = useSelector(
    state => state.sharingCounties?.checkedCounties
  )

  const {
    loading: currentSharedRecordsLoading,
    error: currentSharedRecordsErr,
    data: currentSharedRecordsData,
  } = useQuery(InterviewListQuery.CURRENT_SHARED_RECORDS, {
    variables: { interviewId },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (currentSharedRecordsErr) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error: currentSharedRecordsErr,
          },
        })
      )
    }
  }, [currentSharedRecordsErr, dispatch])

  const getCheckedCountyIdList = checkedCountiesRecords => {
    return checkedCountiesRecords.map(record => {
      return record.countyID
    })
  }

  // users are not allowed to share their own county
  const getValidCounties = counties => {
    return counties.filter(county => {
      return county.id !== userCountyId
    })
  }

  // get {countyDescription : countID} mapping
  const getCountyIdMapping = validCounties => {
    let result = {}
    validCounties.forEach(county => {
      result[`${county.description}`] = county.id
    })
    return result
  }

  const getSharingCountiesInput = (
    countiesDropdownOptions,
    countyIdMapping
  ) => {
    const sharingCountiesInput = []
    for (const key in countiesDropdownOptions) {
      if (countiesDropdownOptions[key])
        sharingCountiesInput.push(countyIdMapping[key])
    }
    return sharingCountiesInput
  }

  const countiesDropdownOptionsGenerator = (counties, checkedCountyIdList) => {
    let dropdownOptions = {}
    counties.forEach(county => {
      checkedCountyIdList.includes(county.id)
        ? (dropdownOptions[`${county.description}`] = true)
        : (dropdownOptions[`${county.description}`] = false)
    })
    return dropdownOptions
  }

  const [countiesDropdownOptions, setCountiesDropdownOptions] = React.useState(
    null
  )

  const originalCounties = currentSharedRecordsData?.counties || []
  const validCounties = getValidCounties(originalCounties)
  // set local state for first time render
  useEffect(() => {
    if (
      currentSharedRecordsData?.counties &&
      currentSharedRecordsData?.interviewSharingRecords &&
      countiesDropdownOptions === null
    ) {
      const options = countiesDropdownOptionsGenerator(
        validCounties,
        getCheckedCountyIdList(currentSharedRecordsData.interviewSharingRecords)
      )
      setCountiesDropdownOptions(options)
    }
  }, [currentSharedRecordsData, countiesDropdownOptions, validCounties])

  // set global state for first time render
  useEffect(() => {
    if (countiesDropdownOptions && !checkedCounties) {
      const countyIdMapping = getCountyIdMapping(validCounties)
      dispatch(
        setCheckedCounties({
          checkedCounties: getSharingCountiesInput(
            countiesDropdownOptions,
            countyIdMapping
          ),
        })
      )
    }
  })

  // Handle county dropdown selections
  const handleSharingCountiesChange = (county, selected) => {
    let newOptions = { ...countiesDropdownOptions }
    newOptions[county] = selected
    setCountiesDropdownOptions(newOptions)
    const countyIdMapping = getCountyIdMapping(validCounties)
    dispatch(
      setCheckedCounties({
        checkedCounties: getSharingCountiesInput(newOptions, countyIdMapping),
      })
    )
  }

  if (currentSharedRecordsLoading) return <div>Loading county list...</div>

  // transform data to an array of objects to teh list of items
  // and a list of values for the selections to support the new behavior that
  // MultiSelectDropdown requires
  const menuItems =
    countiesDropdownOptions &&
    Object.keys(countiesDropdownOptions).map(key => ({
      label: key,
      value: key,
    }))
  const selectedItems =
    countiesDropdownOptions &&
    Object.keys(countiesDropdownOptions).filter(
      key => countiesDropdownOptions[key]
    )

  return (
    <>
      <Label htmlFor='counties'>Counties</Label>
      <StyledMenuPortal id={outsidePortalDomNodeID} />
      {countiesDropdownOptions && (
        <MultiSelectDropdown
          name='counties'
          id='counties'
          portalId={outsidePortalDomNodeID}
          menuItems={menuItems}
          selectedValues={selectedItems}
          displayField='label'
          valueField='value'
          handleChange={handleSharingCountiesChange}
        />
      )}
    </>
  )
}

export const SharingCountiesDialogActions = ({ interviewId }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(cleanCheckedCounties())
    dispatch(closeDialog())
  }
  const handleSave = () => {
    dispatch(closeDialog())
    dispatch(saveSharingCounties(interviewId))
    dispatch(cleanCheckedCounties())
    dispatch(openSnackbar('Sharing counties saved!'))
  }
  return (
    <>
      <Button onClick={handleClose} color='secondary' variant='contained'>
        Cancel
      </Button>
      <Button onClick={handleSave} color='primary' variant='contained'>
        Save
      </Button>
    </>
  )
}
