import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import isEmpty from 'lodash/isEmpty'
import { openDialog } from 'ducks/dialogSlice'
import { clearPendingChanges } from 'ducks/pendingChangesSlice'
import { refreshPendingChanges } from './refreshPendingChanges'

const DELETE_SCHEDULED_CHANGE = gql`
  mutation deleteScheduledChange($id: Int!) {
    deleteScheduledChange(id: $id)
  }
`

export const deleteScheduledChange = change => {
  return async (dispatch, getState) => {
    // Don't attempt to save without changes
    if (isEmpty(change)) {
      return
    }

  dispatch(clearPendingChanges())

    try {
      await client.mutate({
        mutation: DELETE_SCHEDULED_CHANGE,
        variables: {
          id: change.id,
      },
     })

     dispatch(refreshPendingChanges())

    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
