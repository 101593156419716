import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import ControlDescription from 'components/general/ControlDescription'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

const howTransportationToPlacesSelector = state =>
  state.interview.how_transportation_to_places.answer

const previewHowTransportationToPlacesSelector = state =>
  state.preview.how_transportation_to_places.answer

const reliableVehicleWtwActivitesSelector = state =>
  state.interview.reliable_vehicle_wtw_activites.answer

const previewReliableVehicleWtwActivitesSelector = state =>
  state.preview.reliable_vehicle_wtw_activites.answer

const transportationBackupPlanSelector = state =>
  state.interview.transportation_backup_plan.answer

const previewTransportationBackupPlanSelector = state =>
  state.preview.transportation_backup_plan.answer

const registeredOwnVehicleSelector = state =>
  state.interview.registered_own_vehicle.answer

const previewRegisteredOwnVehicleSelector = state =>
  state.preview.registered_own_vehicle.answer

const selectUsePublicTransportation = createSelector(
  howTransportationToPlacesSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('PB')
)

const selectPreviewUsePublicTransportation = createSelector(
  previewHowTransportationToPlacesSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('PB')
)

const selectOther = createSelector(
  howTransportationToPlacesSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const selectPreviewOther = createSelector(
  previewHowTransportationToPlacesSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const selectYes = arg =>
  createSelector(arg, answer => answer && answer.toUpperCase() === 'YS')

const TransportationMethod = React.memo(({ isPreview }) => {
  const { roles } = useSelector(state => state.user)
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )
  const isOther = useSelector(isPreview ? selectPreviewOther : selectOther)
  const isUsePublicTransportation = useSelector(isPreview ? selectPreviewUsePublicTransportation : selectUsePublicTransportation)
  const isreliableVehicleWtwActivites = useSelector(
    isPreview ? selectYes(previewReliableVehicleWtwActivitesSelector) : selectYes(reliableVehicleWtwActivitesSelector)
  )
  const istransportationBackupPlan = useSelector(
    isPreview ? selectYes(previewTransportationBackupPlanSelector) : selectYes(transportationBackupPlanSelector)
  )
  const isRegisteredOwnVehicle = useSelector(
    isPreview ? selectYes(previewRegisteredOwnVehicleSelector) : selectYes(registeredOwnVehicleSelector)
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Transportation Method'
          domain='Transportation'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Check all that apply.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='how_transportation_to_places' isPreview={isPreview} />
          <Text
            question_id='how_get_around_specify'
            isDisabled={!isOther}
            shouldDecode={true}
            subquestion
            isPreview={isPreview}
          />
          <Select question_id='reliable_vehicle' isPreview={isPreview} />
          <Select question_id='reliable_vehicle_wtw_activites' isPreview={isPreview} />
          <Text
            question_id='what_vehicle_own'
            isDisabled={!isreliableVehicleWtwActivites}
            shouldDecode={true}
            subquestion
            isPreview={isPreview}
          />
          <Select question_id='transportation_backup_plan' isPreview={isPreview} />
          <Text
            question_id='transportation_back_up_explain'
            isDisabled={!istransportationBackupPlan}
            shouldDecode={true}
            subquestion
            isPreview={isPreview}
          />
          <Select question_id='registered_own_vehicle' isPreview={isPreview} />
          <Text
            question_id='transportation_method_explain'
            isDisabled={isRegisteredOwnVehicle}
            shouldDecode={true}
            subquestion
            isPreview={isPreview}
          />
          <Select question_id='vehicle_insurance' isPreview={isPreview} />
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <ControlDescription disabled>
                  If you rely on public transportation:
                </ControlDescription>
              )
            }}
            yes={() => {
              return (
                <ControlDescription disabled={cancelledOrCompletedInterview}>
                  If you rely on public transportation:
                </ControlDescription>
              )
            }}
          />

          <Select
            question_id='public_transporation_convenient'
            subquestion
            isDisabled={!isUsePublicTransportation}
            isPreview={isPreview}
          />
          <Select
            question_id='public_transprtation_available'
            subquestion
            isDisabled={!isUsePublicTransportation}
            isPreview={isPreview}
          />
          <Select
            question_id='public_transportation_frequent'
            subquestion
            isDisabled={!isUsePublicTransportation}
            isPreview={isPreview}
          />
          <Select
            question_id='other_family_member_transportation_needs'
            subquestion
            isDisabled={!isUsePublicTransportation}
            isPreview={isPreview}
          />
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <ControlDescription disabled>
                  How many times have you used public transportation?
                </ControlDescription>
              )
            }}
            yes={() => {
              return (
                <ControlDescription disabled={cancelledOrCompletedInterview}>
                  How many times have you used public transportation?
                </ControlDescription>
              )
            }}
          />
          <Text question_id='transportation_in_past_week' type='number' isPreview={isPreview} />
          <Text question_id='transportation_in_past_month' type='number' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default TransportationMethod
