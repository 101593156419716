import { format, parseISO } from 'date-fns'

export const isoToLocalDate = datetime => {
  if (!datetime) {
    return datetime
  }
  return format(parseISO(datetime), 'MM/dd/yyyy')
}

export const getTomorrow = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const formatTomorrow = (((tomorrow.getMonth() > 8) ? (tomorrow.getMonth() + 1)
  : ('0' + (tomorrow.getMonth() + 1))) + '/' + ((tomorrow.getDate() > 9) 
  ? tomorrow.getDate() : ('0' + tomorrow.getDate())) + '/' + tomorrow.getFullYear())

  return formatTomorrow
}
