import React from 'react'
import Grid from '@material-ui/core/Grid'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const ChildCareBackup = ({ isPreview }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Backup Child Care Plan'
          domain='Child Care and Parenting'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Text multiline rows='4' question_id='back_up_child_care_plan' shouldDecode={true} isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
}

export default ChildCareBackup
