import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { colors } from 'app/theme'
import AdminTableText from './AdminTableText'
import AdminTableSelect, { StyledSelectPortal } from './AdminTableSelect'
import { setNewUser } from 'ducks/adminSlice'

const StyledTableContainer = styled(Paper)`
  && {
    width: 50%;
    margin: 10px 0;
  }
`
const ToolbarHeader = styled(Toolbar)`
  && {
    justify-content: space-between;
    padding-right: 8px;
  }
`

const ActionCell = styled(TableCell)`
  && {
    width: 50px;
  }
  border: ${p => p.border};
`
const ControlDescription = styled(Typography)`
  && {
    font-weight: ${p => !p.disabled && 700};
    margin-top: 20px;
  }
`
const ControlTableHead = styled(TableHead)`
  && * {
    color: ${p => p.disabled && colors.grey};
  }
`
const AdminTable = React.memo(({ headers, inputs, isDisabled }) => {
  const dispatch = useDispatch()
  const values = useSelector(state => state.admin[inputs[0].field])
  const portalID = 'react-select-portal'

  const handleRowAdd = () => {
    dispatch(setNewUser({ field: inputs[0].field, value: values.concat('') }))
  }

  const handleRowDelete = index => e => {
    let updatedAnswers = [...values]
    updatedAnswers.splice(index, 1)
    dispatch(setNewUser({ field: inputs[0].field, value: updatedAnswers }))
  }

  return (
    <StyledTableContainer>
      <ToolbarHeader>
        <ControlDescription variant='h6'></ControlDescription>
        <IconButton
          aria-label='Add'
          onClick={handleRowAdd}
          disabled={isDisabled}
        >
          <AddBoxIcon />
        </IconButton>
      </ToolbarHeader>
      <TableContainer>
        <Table>
          <ControlTableHead disabled={isDisabled}>
            <TableRow>
              <ActionCell align='center'>Action</ActionCell>
              {headers.map((header, index) => (
                <TableCell align='center' key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </ControlTableHead>
          <TableBody>
            {values?.length > 0 && !isDisabled ? (
              values.map((rowValues, index) => {
                return (
                  <TableRow key={index}>
                    <ActionCell align='center'>
                      <IconButton
                        aria-label={`Delete button ${index}`}
                        onClick={handleRowDelete(index)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </ActionCell>
                    {inputs.map(input => {
                      if (input.type === 'text') {
                        return (
                          <TableCell align='center' key={index}>
                            <AdminTableText
                              field={input.field}
                              index={index}
                            ></AdminTableText>
                          </TableCell>
                        )
                      } else if (input.type === 'select') {
                        return (
                          <TableCell align='center' key={index}>
                            <AdminTableSelect
                              portalID={portalID}
                              field={input.field}
                              index={index}
                              options={input.options}
                              selectOpts={input.selectOpts}
                            ></AdminTableSelect>
                          </TableCell>
                        )
                      } else {
                        return ''
                      }
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableRow key={1}>
                <ActionCell align='center' border='0'>
                  <IconButton
                    disabled={true}
                    aria-label='Disabled Delete Button'
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </ActionCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledSelectPortal id={portalID} />
    </StyledTableContainer>
  )
})

export default AdminTable
