import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: null,
  title: null,
  description: null,
  props: {},
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialState,
  reducers: {
    openDialog: (state, action) => {
      state.type = action.payload.type
      state.props = action.payload.props
      state.title = action.payload.title
      state.description = action.payload.description
    },
    closeDialog: (state, action) => initialState,
  },
})

export const { openDialog, closeDialog } = dialogSlice.actions

export default dialogSlice.reducer
