import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'othr_prb_w_drgs' &&
    (answer === 'NO' || answer === null)
  ) {
    effectCollector.add('othr_prb_w_drgs_expl', null)
  }
  return effectCollector.effects
}

export default effects;