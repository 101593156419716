import React from 'react'

// Demographics
import DemographicsGeneralInformation from 'components/interview/DemographicsGeneralInformation'
import DemographicsGenderIdentity from 'components/interview/DemographicsGenderIdentity'
import DemographicsHouseholdComposition from 'components/interview/DemographicsHouseholdComposition'

// Education
import EducationHighestGrade from 'components/interview/EducationHighestGrade'
import EducationLearningNeedsAssessment from 'components/interview/EducationLearningNeedsAssessment'
import EducationDiplomaDegreeCertification from 'components/interview/EducationDiplomaDegreeCertification'
// Employment
import EmploymentCurrentActivities from 'components/interview/EmploymentCurrentActivities'
import EmploymentJobHistory from 'components/interview/EmploymentJobHistory'

// Transportation
import TransportationChallenge from 'components/interview/TransportationChallenge'
import TransportationDriverLicense from 'components/interview/TransportationDriverLicense'

// Emotional & Mental Health
import EmotionalAndMentalHealthIndicator from 'components/interview/EmotionalAndMentalHealthIndicator'

import DomesticAbuseHumanTrafficking from 'components/interview/DomesticAbuseHumanTrafficking'
import SubstanceUseInHousehold from 'components/interview/SubstanceUseInHousehold'
import TransportationMethod from 'components/interview/TransportationMethod'
import Relationships from 'components/interview/Relationships'
import ClosingQuestion from 'components/interview/ClosingQuestion'
import SafetyConcernsVictim from 'components/interview/SafetyConcernsVictim'
import DomesticAbuseIndicatorQuestions from 'components/interview/DomesticAbuseIndicatorQuestions'
import LearningNeedsIndicatorQuestions from 'components/interview/LearningNeedsIndicatorQuestions'
import TransportationIndicatorQuestions from 'components/interview/TransportationIndicatorQuestions'
import HousingIndicatorQuestions from 'components/interview/HousingIndicatorQuestions'
import HousingSituation from 'components/interview/HousingSituation'
import SubstanceUseIndicatorQuestions from 'components/interview/SubstanceUseIndicatorQuestions'
import DomesticAbuseVictimQuestions from 'components/interview/DomesticAbuseVictimQuestions'
import DomesticAbusePerpetratorQuestions from 'components/interview/DomesticAbusePerpetratorQuestions'
import OtherEducationTraining from 'components/interview/OtherEducationTraining'
import ReasonsNotWorking from 'components/interview/ReasonsNotWorking'
import FinancesBenefits from 'components/interview/FinancesBenefits'
import CurrentStatus from 'components/interview/CurrentStatus'
import LearningNeedsInstructions from 'components/interview/LearningNeedsInstructions'

// Child Care and Parenting
import ChildCareAndParentingIndicator from 'components/interview/ChildCareAndParentingIndicator'
import ChildCareParentingChildrensIssues from 'components/interview/ChildCareParentingChildrensIssues'
import ChildCarePrimaryCaregiver from 'components/interview/ChildCarePrimaryCaregiver'
import ChildCare from 'components/interview/ChildCare'
import ChildCareBackup from 'components/interview/ChildCareBackup'
import WrapUp from 'components/interview/WrapUp'
import GeneralHealthIndicator from 'components/interview/GeneralHealthIndicator'
import LegalBarriers from 'components/interview/LegalBarriers'
import RelationshipIndicator from 'components/interview/RelationshipIndicator'
import AlcoholAndDrugs from 'components/interview/AlcoholAndDrugs'
import ParentingAndChildSupport from 'components/interview/ParentingAndChildSupport'
import ChildCareStatus from 'components/interview/ChildCareStatus'

// Employment
import EmploymentWorkExperienceInterests from 'components/interview/EmploymentWorkExperienceInterests'

// Pregnancy
import PregnancyIndicatorQuestions from 'components/interview/PregnancyIndicatorQuestions'
import PregnancyPregnancy from 'components/interview/PregnancyPregnancy'

// Recommendation
import RecommendationsStrengths from 'components/recommendation/RecommendationsStrengths'
import RecommendationsBarrier from 'components/recommendation/RecommendationsBarrier'
import RecommendationsFamily from 'components/recommendation/RecommendationsFamily'
import RecommendationsException from 'components/recommendation/RecommendationsException'

import RuleList from 'components/admin/RuleList'
import RuleDetails from 'components/admin/RuleDetails'
import ViewPendingChanges from 'components/admin/ViewPendingChanges'
import PreviewQuestionnaire from 'components/admin/PreviewQuestionnaire'
import PreviewASR from 'components/admin/PreviewASR'
import ClientList from 'components/clientList/ClientListDataLoader'
import InterviewList from 'components/interviewList/InterviewListDataLoader'
import CurrentHousing from 'components/interview/CurrentHousing'
import ClientSearch from 'components/clientSearch/ClientSearchDataLoader'
import CreateClient from 'components/createClient/CreateClient'
import AdminManage from 'components/admin/AdminManage'
import AdminManageEntities from 'components/admin/AdminManageEntities'
import AdminCreate from 'components/admin/AdminCreate'
import AdminEdit from 'components/admin/AdminEdit'

import ViewProfile from 'components/admin/ViewProfile'
import Help from 'components/help/Help'
import Resources from 'components/resources'
import AppraisalPreview from 'components/interview/AppraisalPreview'
import Report from 'components/report/Report'
import TimedOut from 'components/general/TimedOut'

import InterviewListDirect from '../components/interviewList/InterviewListDirect'
import QuestionList from 'components/admin/QuestionList'
import QuestionDetails from 'components/admin/QuestionDetails'
// The order in this object is important -- it determines in which order
// react-router's <Switch> will create <Route> components.
const routes = {
  client_list: {
    id: 'client_list',
    exact: true,
    path: '/client_list',
    component: ClientList,
  },
  interview_list_direct: {
    id: 'interview_list_direct',
    exact: true,
    path: '/interview_list/:clientId',
    component: InterviewListDirect,
  },
  interview_list: {
    id: 'interview_list',
    exact: true,
    path: '/interview_list',
    component: InterviewList,
    render: (props) => <InterviewList />
  },
  help: {
    id: 'help',
    exact: true,
    path: '/help',
    component: Help,
  },
  search: {
    id: 'search',
    exact: true,
    path: '/search',
    component: ClientSearch,
  },
  reports: {
    id: 'reports',
    exact: true,
    path: '/reports',
    component: Report,
  },
  resources: {
    id: 'resources',
    exact: true,
    path: '/resources',
    component: Resources,
  },
  BRE: {
    id: 'bre',
    exact: true,
    path: '/bre',
    component: ViewPendingChanges,
  },
  BRE_rule_list: {
    id: 'bre_rule_list',
    exact: true,
    path: '/bre_rule_list',
    component: RuleList,
  },
  BRE_rule_details: {
    id: 'bre_rule_details',
    exact: true,
    path: '/bre_rule_details',
    component: RuleDetails,
  },
  BRE_question_list: {
    id: 'bre_question_list',
    exact: true,
    path: '/bre_question_list',
    component: QuestionList,
  },
  BRE_question_details: {
    id: 'bre_question_details',
    exact: true,
    path: '/bre_question_details',
    component: QuestionDetails,
  },
  BRE_preview_questionnaire: {
    id: 'bre_preview_questionnaire',
    exact: true,
    path: '/bre_preview_questionnaire',
    component: PreviewQuestionnaire,
  },
  BRE_preview_ASR: {
    id: 'bre_preview_asr',
    exact: true,
    path: '/bre_preview_asr',
    component: PreviewASR,
  },
  administration: {
    id: 'administration',
    exact: true,
    path: '/administration',
    component: AdminManage,
  },
  administration_manage: {
    id: 'administration',
    exact: true,
    path: '/administration_manage',
    component: AdminManage,
  },
  administration_create: {
    id: 'administration',
    exact: true,
    path: '/administration_create',
    component: AdminCreate,
  },
  administration_edit: {
    id: 'administration',
    exact: true,
    path: '/administration_edit',
    component: AdminEdit,
  },
  administration_manage_entities: {
    id: 'administration',
    exact: true,
    path: '/administration_manage_entities',
    component: AdminManageEntities,
  },
  create_client: {
    id: 'create_client',
    exact: true,
    path: '/create_client',
    component: CreateClient,
  },
  demographics_general_information: {
    id: 'demographics_general_information',
    exact: true,
    path: '/interview/demographics_general_information',
    // component: DemographicsGeneralInformation,
    render: (props) => 
      <DemographicsGeneralInformation isPreview={false} />
  },
  demographics_general_information_preview: {
    id: 'demographics_general_information_preview',
    exact: true,
    path: '/preview/demographics_general_information',
    // component: DemographicsGeneralInformation,
    render: (props) => <DemographicsGeneralInformation isPreview={true} />
  },
  demographics_gender_identity: {
    id: 'demographics_gender_identity',
    exact: true,
    path: '/interview/demographics_gender_identity',
    render: (props) => <DemographicsGenderIdentity isPreview={false} />
  },
  demographics_gender_identity_preview: {
    id: 'demographics_gender_identity_preview',
    exact: true,
    path: '/preview/demographics_gender_identity',
    render: (props) => <DemographicsGenderIdentity isPreview={true} />
  },
  demographics_household_composition: {
    id: 'demographics_household_composition',
    exact: true,
    path: '/interview/demographics_household_composition',
    render: (props) => <DemographicsHouseholdComposition isPreview={false} />
  },
  demographics_household_composition_preview: {
    id: 'demographics_household_composition_preview',
    exact: true,
    path: '/preview/demographics_household_composition',
    render: (props) => <DemographicsHouseholdComposition isPreview={true} />
  },
  demographics_finances_benefits: {
    id: 'demographics_finances_benefits',
    exact: true,
    path: '/interview/demographics_finances_benefits',
    render: (props) => <FinancesBenefits isPreview={false} />
  },
  demographics_finances_benefits_preview: {
    id: 'demographics_finances_benefits_preview',
    exact: true,
    path: '/preview/demographics_finances_benefits',
    render: (props) => <FinancesBenefits isPreview={true} />
  },
  employment_current_activities: {
    id: 'employment_current_activities',
    exact: true,
    path: '/interview/employment_current_activities',
    render: (props) => <EmploymentCurrentActivities isPreview={false} />
  },
  employment_current_activities_preview: {
    id: 'employment_current_activities_preview',
    exact: true,
    path: '/preview/employment_current_activities',
    render: (props) => <EmploymentCurrentActivities isPreview={true} />
  },
  employment_reasons_not_working: {
    id: 'employment_reasons_not_working',
    exact: true,
    path: '/interview/employment_reasons_not_working',
    render: (props) => <ReasonsNotWorking isPreview={false} />
  },
  employment_reasons_not_working_preview: {
    id: 'employment_reasons_not_working_preview',
    exact: true,
    path: '/preview/employment_reasons_not_working',
    render: (props) => <ReasonsNotWorking isPreview={true} />
  },
  employment_job_history: {
    id: 'employment_job_history',
    exact: true,
    path: '/interview/employment_job_history',
    render: (props) => <EmploymentJobHistory isPreview={false} />
  },
  employment_job_history_preview: {
    id: 'employment_job_history_preview',
    exact: true,
    path: '/preview/employment_job_history',
    render: (props) => <EmploymentJobHistory isPreview={true} />
  },
  employment_work_experience_interests: {
    id: 'employment_work_experience_interests',
    exact: true,
    path: '/interview/employment_work_experience_interests',
    render: (props) => <EmploymentWorkExperienceInterests isPreview={false} />
  },
  employment_work_experience_interests_preview: {
    id: 'employment_work_experience_interests_preview',
    exact: true,
    path: '/preview/employment_work_experience_interests',
    render: (props) => <EmploymentWorkExperienceInterests isPreview={true} />
  },
  employment_legal_barriers: {
    id: 'employment_legal_barriers',
    exact: true,
    path: '/interview/employment_legal_barriers',
    render: (props) => <LegalBarriers isPreview={false} />
  },
  employment_legal_barriers_preview: {
    id: 'employment_legal_barriers_preview',
    exact: true,
    path: '/preview/employment_legal_barriers',
    render: (props) => <LegalBarriers isPreview={true} />
  },
  education_highest_grade: {
    id: 'education_highest_grade',
    exact: true,
    path: '/interview/education_highest_grade',
    render: (props) => <EducationHighestGrade isPreview={false} />
  },
  education_highest_grade_preview: {
    id: 'education_highest_grade_preview',
    exact: true,
    path: '/preview/education_highest_grade',
    render: (props) => <EducationHighestGrade isPreview={true} />
  },
  education_diploma_degree_certifications: {
    id: 'education_diploma_degree_certifications',
    exact: true,
    path: '/interview/education_diploma_degree_certifications',
    render: (props) => <EducationDiplomaDegreeCertification isPreview={false} />
  },
  education_diploma_degree_certifications_preview: {
    id: 'education_diploma_degree_certifications_preview',
    exact: true,
    path: '/preview/education_diploma_degree_certifications',
    render: (props) => <EducationDiplomaDegreeCertification isPreview={true} />
  },
  education_other_education: {
    id: 'education_other_education',
    exact: true,
    path: '/interview/education_other_education',
    render: (props) => <OtherEducationTraining isPreview={false} />
  },
  education_other_education_preview: {
    id: 'education_other_education_preview',
    exact: true,
    path: '/preview/education_other_education',
    render: (props) => <OtherEducationTraining isPreview={true} />
  },
  education_learning_needs_instructions: {
    id: 'education_learning_needs_instructions',
    exact: true,
    path: '/interview/education_learning_needs_instructions',
    render: (props) => <LearningNeedsInstructions isPreview={false} />
  },
  education_learning_needs_instructions_preview: {
    id: 'education_learning_needs_instructions_preview',
    exact: true,
    path: '/preview/education_learning_needs_instructions',
    render: (props) => <LearningNeedsInstructions isPreview={true} />
  },
  education_learning_needs_indicator_questions: {
    id: 'education_learning_needs_indicator_questions',
    exact: true,
    path: '/interview/education_learning_needs_indicator_questions',
    render: (props) => <LearningNeedsIndicatorQuestions isPreview={false} />
  },
  education_learning_needs_indicator_questions_preview: {
    id: 'education_learning_needs_indicator_questions_preview',
    exact: true,
    path: '/preview/education_learning_needs_indicator_questions',
    render: (props) => <LearningNeedsIndicatorQuestions isPreview={true} />
  },
  education_learning_needs_sub_section: {
    id: 'education_learning_needs_sub_section',
    exact: true,
    path: '/interview/education_learning_needs_sub_section',
    render: (props) => <EducationLearningNeedsAssessment isPreview={false} />
  },
  education_learning_needs_sub_section_preview: {
    id: 'education_learning_needs_sub_section_preview',
    exact: true,
    path: '/preview/education_learning_needs_sub_section',
    render: (props) => <EducationLearningNeedsAssessment isPreview={true} />
  },
  housing_indicator_questions: {
    id: 'housing_indicator_questions',
    exact: true,
    path: '/interview/housing_indicator_questions',
    render: (props) => <HousingIndicatorQuestions isPreview={false} />
  },
  housing_indicator_questions_preview: {
    id: 'housing_indicator_questions_preview',
    exact: true,
    path: '/preview/housing_indicator_questions',
    render: (props) => <HousingIndicatorQuestions isPreview={true} />
  },
  housing_current_housing: {
    id: 'housing_current_housing',
    exact: true,
    path: '/interview/housing_current_housing',
    render: (props) => <CurrentHousing isPreview={false} />
  },
  housing_current_housing_preview: {
    id: 'housing_current_housing_preview',
    exact: true,
    path: '/preview/housing_current_housing',
    render: (props) => <CurrentHousing isPreview={true} />
  },
  housing_situation: {
    id: 'housing_situation',
    exact: true,
    path: '/interview/housing_situation',
    render: (props) => <HousingSituation isPreview={false} />
  },
  housing_situation_preview: {
    id: 'housing_situation_preview',
    exact: true,
    path: '/preview/housing_situation',
    render: (props) => <HousingSituation isPreview={true} />
  },
  transportation_indicator_questions: {
    id: 'transportation_indicator_questions',
    exact: true,
    path: '/interview/transportation_indicator_questions',
    render: (props) => <TransportationIndicatorQuestions isPreview={false} />
  },
  transportation_indicator_questions_preview: {
    id: 'transportation_indicator_questions_preview',
    exact: true,
    path: '/preview/transportation_indicator_questions',
    render: (props) => <TransportationIndicatorQuestions isPreview={true} />
  },
  transportation_method: {
    id: 'transportation_method',
    exact: true,
    path: '/interview/transportation_method',
    render: (props) => <TransportationMethod isPreview={false} />
  },
  transportation_method_preview: {
    id: 'transportation_method_preview',
    exact: true,
    path: '/preview/transportation_method',
    render: (props) => <TransportationMethod isPreview={true} />
  },
  transportation_driving_license: {
    id: 'transportation_driving_license',
    exact: true,
    path: '/interview/transportation_driving_license',
    render: (props) => <TransportationDriverLicense isPreview={false} />
  },
  transportation_driving_license_preview: {
    id: 'transportation_driving_license_preview',
    exact: true,
    path: '/preview/transportation_driving_license',
    render: (props) => <TransportationDriverLicense isPreview={true} />
  },
  transportation_challenge: {
    id: 'transportation_challenge',
    exact: true,
    path: '/interview/transportation_challenge',
    render: (props) => <TransportationChallenge isPreview={false} />
  },
  transportation_challenge_preview: {
    id: 'transportation_challenge_preview',
    exact: true,
    path: '/preview/transportation_challenge',
    render: (props) => <TransportationChallenge isPreview={true} />
  },
  general_health_indicator_questions: {
    id: 'general_health_indicator_questions',
    exact: true,
    path: '/interview/general_health_indicator_questions',
    render: (props) => <GeneralHealthIndicator isPreview={false} />
  },
  general_health_indicator_questions_preview: {
    id: 'general_health_indicator_questions_preview',
    exact: true,
    path: '/preview/general_health_indicator_questions',
    render: (props) => <GeneralHealthIndicator isPreview={true} />
  },
  emotional_and_mental_health_indicator_questions: {
    id: 'emotional_and_mental_health_indicator_questions',
    exact: true,
    path: '/interview/emotional_and_mental_health_indicator_questions',
    render: (props) => <EmotionalAndMentalHealthIndicator isPreview={false} />
  },
  emotional_and_mental_health_indicator_questions_preview: {
    id: 'emotional_and_mental_health_indicator_questions_preview',
    exact: true,
    path: '/preview/emotional_and_mental_health_indicator_questions',
    render: (props) => <EmotionalAndMentalHealthIndicator isPreview={true} />
  },
  emotional_mental_health_current_status: {
    id: 'emotional_mental_health_current_status',
    exact: true,
    path: '/interview/emotional_mental_health_current_status',
    render: (props) => <CurrentStatus isPreview={false} />
  },
  emotional_mental_health_current_status_preview: {
    id: 'emotional_mental_health_current_status_preview',
    exact: true,
    path: '/preview/emotional_mental_health_current_status',
    render: (props) => <CurrentStatus isPreview={true} />
  },
  emotional_mental_health_wrap_up: {
    id: 'emotional_mental_health_wrap_up',
    exact: true,
    path: '/interview/emotional_mental_health_wrap_up',
    render: (props) => <WrapUp isPreview={false} />
  },
  emotional_mental_health_wrap_up_preview: {
    id: 'emotional_mental_health_wrap_up_preview',
    exact: true,
    path: '/preview/emotional_mental_health_wrap_up',
    render: (props) => <WrapUp isPreview={true} />
  },
  substance_use_indicator_questions: {
    id: 'substance_use_indicator_questions',
    exact: true,
    path: '/interview/substance_use_indicator_questions',
    render: (props) => <SubstanceUseIndicatorQuestions isPreview={false} />
  },
  substance_use_indicator_questions_preview: {
    id: 'substance_use_indicator_questions_preview',
    exact: true,
    path: '/preview/substance_use_indicator_questions',
    render: (props) => <SubstanceUseIndicatorQuestions isPreview={true} />
  },
  substance_use_alcohol_drugs: {
    id: 'substance_use_alcohol_drugs',
    exact: true,
    path: '/interview/substance_use_alcohol_drugs',
    render: (props) => <AlcoholAndDrugs isPreview={false} />
  },
  substance_use_alcohol_drugs_preview: {
    id: 'substance_use_alcohol_drugs_preview',
    exact: true,
    path: '/preview/substance_use_alcohol_drugs',
    render: (props) => <AlcoholAndDrugs isPreview={true} />
  },
  substance_use_in_household: {
    id: 'substance_use_in_household',
    exact: true,
    path: '/interview/substance_use_in_household',
    render: (props) => <SubstanceUseInHousehold isPreview={false} />
  },
  substance_use_in_household_preview: {
    id: 'substance_use_in_household_preview',
    exact: true,
    path: '/preview/substance_use_in_household',
    render: (props) => <SubstanceUseInHousehold isPreview={true} />
  },
  domestic_abuse_indicator_questions: {
    id: 'domestic_abuse_indicator_questions',
    exact: true,
    path: '/interview/domestic_abuse_indicator_questions',
    render: (props) => <DomesticAbuseIndicatorQuestions isPreview={false} />
  },
  domestic_abuse_indicator_questions_preview: {
    id: 'domestic_abuse_indicator_questions_preview',
    exact: true,
    path: '/preview/domestic_abuse_indicator_questions',
    render: (props) => <DomesticAbuseIndicatorQuestions isPreview={true} />
  },
  domestic_abuse_victim: {
    id: 'domestic_abuse_victim',
    exact: true,
    path: '/interview/domestic_abuse_victim',
    render: (props) => <DomesticAbuseVictimQuestions isPreview={false} />
  },
  domestic_abuse_victim_preview: {
    id: 'domestic_abuse_victim_preview',
    exact: true,
    path: '/preview/domestic_abuse_victim',
    render: (props) => <DomesticAbuseVictimQuestions isPreview={true} />
  },
  domestic_abuse_safety_concerns_victim: {
    id: 'domestic_abuse_safety_concerns_victim',
    exact: true,
    path: '/interview/domestic_abuse_safety_concerns_victim',
    render: (props) => <SafetyConcernsVictim isPreview={false} />
  },
  domestic_abuse_safety_concerns_victim_preview: {
    id: 'domestic_abuse_safety_concerns_victim_preview',
    exact: true,
    path: '/preview/domestic_abuse_safety_concerns_victim',
    render: (props) => <SafetyConcernsVictim isPreview={true} />
  },
  domestic_abuse_perpetrator_questions: {
    id: 'domestic_abuse_perpetrator_questions',
    exact: true,
    path: '/interview/domestic_abuse_perpetrator_questions',
    render: (props) => <DomesticAbusePerpetratorQuestions isPreview={false} />
  },
  domestic_abuse_perpetrator_questions_preview: {
    id: 'domestic_abuse_perpetrator_questions_preview',
    exact: true,
    path: '/preview/domestic_abuse_perpetrator_questions',
    render: (props) => <DomesticAbusePerpetratorQuestions isPreview={true} />
  },
  domestic_abuse_trafficking: {
    id: 'domestic_abuse_trafficking',
    exact: true,
    path: '/interview/domestic_abuse_trafficking',
    render: (props) => <DomesticAbuseHumanTrafficking isPreview={false} />
  },
  domestic_abuse_trafficking_preview: {
    id: 'domestic_abuse_trafficking_preview',
    exact: true,
    path: '/preview/domestic_abuse_trafficking',
    render: (props) => <DomesticAbuseHumanTrafficking isPreview={true} />
  },
  pregnancy_indicator_questions: {
    id: 'pregnancy_indicator_questions',
    exact: true,
    path: '/interview/pregnancy_indicator_questions',
    render: (props) => <PregnancyIndicatorQuestions isPreview={false} />
  },
  pregnancy_indicator_questions_preview: {
    id: 'pregnancy_indicator_questions_preview',
    exact: true,
    path: '/preview/pregnancy_indicator_questions',
    render: (props) => <PregnancyIndicatorQuestions isPreview={true} />
  },
  pregnancy: {
    id: 'pregnancy',
    exact: true,
    path: '/interview/pregnancy',
    render: (props) => <PregnancyPregnancy isPreview={false} />
  },
  pregnancy_preview: {
    id: 'pregnancy_preview',
    exact: true,
    path: '/preview/pregnancy',
    render: (props) => <PregnancyPregnancy isPreview={true} />
  },
  child_care_parenting_indicator_questions: {
    id: 'child_care_parenting_indicator_questions',
    exact: true,
    path: '/interview/child_care_parenting_indicator_questions',
    render: (props) => <ChildCareAndParentingIndicator isPreview={false} />
  },
  child_care_parenting_indicator_questions_preview: {
    id: 'child_care_parenting_indicator_questions_preview',
    exact: true,
    path: '/preview/child_care_parenting_indicator_questions',
    render: (props) => <ChildCareAndParentingIndicator isPreview={true} />
  },
  child_care_parenting_childrens_issues: {
    id: 'child_care_parenting_childrens_issues',
    exact: true,
    path: '/interview/child_care_parenting_childrens_issues',
    render: (props) => <ChildCareParentingChildrensIssues isPreview={false} />
  },
  child_care_parenting_childrens_issues_preview: {
    id: 'child_care_parenting_childrens_issues_preview',
    exact: true,
    path: '/preview/child_care_parenting_childrens_issues',
    render: (props) => <ChildCareParentingChildrensIssues isPreview={true} />
  },
  child_care_parenting_primary_caregiver: {
    id: 'child_care_parenting_primary_caregiver',
    exact: true,
    path: '/interview/child_care_parenting_primary_caregiver',
    render: (props) => <ChildCarePrimaryCaregiver isPreview={false} />
  },
  child_care_parenting_primary_caregiver_preview: {
    id: 'child_care_parenting_primary_caregiver_preview',
    exact: true,
    path: '/preview/child_care_parenting_primary_caregiver',
    render: (props) => <ChildCarePrimaryCaregiver isPreview={true} />
  },
  child_care_parenting_parenting_child_support: {
    id: 'child_care_parenting_parenting_child_support',
    exact: true,
    path: '/interview/child_care_parenting_parenting_child_support',
    render: (props) => <ParentingAndChildSupport isPreview={false} />
  },
  child_care_parenting_parenting_child_support_preview: {
    id: 'child_care_parenting_parenting_child_support_preview',
    exact: true,
    path: '/preview/child_care_parenting_parenting_child_support',
    render: (props) => <ParentingAndChildSupport isPreview={true} />
  },
  child_care_parenting_child_care: {
    id: 'child_care_parenting_child_care',
    exact: true,
    path: '/interview/child_care_parenting_child_care',
    render: (props) => <ChildCare isPreview={false} />
  },
  child_care_parenting_child_care_preview: {
    id: 'child_care_parenting_child_care_preview',
    exact: true,
    path: '/preview/child_care_parenting_child_care',
    render: (props) => <ChildCare isPreview={true} />
  },
  child_care_parenting_child_care_status: {
    id: 'child_care_parenting_child_care_status',
    exact: true,
    path: '/interview/child_care_parenting_child_care_status',
    render: (props) => <ChildCareStatus isPreview={false} />
  },
  child_care_parenting_child_care_status_preview: {
    id: 'child_care_parenting_child_care_status_preview',
    exact: true,
    path: '/preview/child_care_parenting_child_care_status',
    render: (props) => <ChildCareStatus isPreview={true} />
  },
  child_care_parenting_backup_child_care_plan: {
    id: 'child_care_parenting_backup_child_care_plan',
    exact: true,
    path: '/interview/child_care_parenting_backup_child_care_plan',
    render: (props) => <ChildCareBackup isPreview={false} />
  },
  child_care_parenting_backup_child_care_plan_preview: {
    id: 'child_care_parenting_backup_child_care_plan_preview',
    exact: true,
    path: '/preview/child_care_parenting_backup_child_care_plan',
    render: (props) => <ChildCareBackup isPreview={true} />
  },
  relationship_indicator_questions: {
    id: 'relationship_indicator_questions',
    exact: true,
    path: '/interview/relationship_indicator_questions',
    render: (props) => <RelationshipIndicator isPreview={false} />
  },
  relationship_indicator_questions_preview: {
    id: 'relationship_indicator_questions_preview',
    exact: true,
    path: '/preview/relationship_indicator_questions',
    render: (props) => <RelationshipIndicator isPreview={true} />
  },
  relationships: {
    id: 'relationships',
    exact: true,
    path: '/interview/relationships',
    render: (props) => <Relationships isPreview={false} />
  },
  relationships_preview: {
    id: 'relationships_preview',
    exact: true,
    path: '/preview/relationships',
    render: (props) => <Relationships isPreview={true} />
  },
  final_closing_question: {
    id: 'final_closing_question',
    exact: true,
    path: '/interview/final_closing_question',
    render: (props) => <ClosingQuestion isPreview={false} />
  },
  final_closing_question_preview: {
    id: 'final_closing_question_preview',
    exact: true,
    path: '/preview/final_closing_question',
    render: (props) => <ClosingQuestion isPreview={true} />
  },
  recommendations_strengths: {
    id: 'recommendations_strengths',
    exact: true,
    path: '/interview/recommendations_strengths',
    render: (props) => <RecommendationsStrengths isPreview={false} />
  },
  recommendations_strengths_preview: {
    id: 'recommendations_strengths_preview',
    exact: true,
    path: '/preview/recommendations_strengths',
    render: (props) => <RecommendationsStrengths isPreview={true} />
  },
  recommendations_work_education_barrier: {
    id: 'recommendations_work_education_barrier',
    exact: true,
    path: '/interview/recommendations_work_education_barrier',
    render: (props) => <RecommendationsBarrier isPreview={false} />
  },
  recommendations_work_education_barrier_preview: {
    id: 'recommendations_work_education_barrier_preview',
    exact: true,
    path: '/preview/recommendations_work_education_barrier',
    render: (props) => <RecommendationsBarrier isPreview={true} />
  },
  recommendations_wtw_exemption_exception: {
    id: 'recommendations_wtw_exemption_exception',
    exact: true,
    path: '/interview/recommendations_wtw_exemption_exception',
    render: (props) => <RecommendationsException isPreview={false} />
  },
  recommendations_wtw_exemption_exception_preview: {
    id: 'recommendations_wtw_exemption_exception_preview',
    exact: true,
    path: '/preview/recommendations_wtw_exemption_exception',
    render: (props) => <RecommendationsException isPreview={true} />
  },
  recommendations_family_stabilization: {
    id: 'recommendations_family_stabilization',
    exact: true,
    path: '/interview/recommendations_family_stabilization',
    render: (props) => <RecommendationsFamily isPreview={false} />
  },
  recommendations_family_stabilization_preview: {
    id: 'recommendations_family_stabilization_preview',
    exact: true,
    path: '/preview/recommendations_family_stabilization',
    render: (props) => <RecommendationsFamily isPreview={true} />
  },
  appraisal: {
    id: 'appraisal',
    exact: true,
    path: '/interview/appraisal',
    render: (props) => <AppraisalPreview isPreview={false} />
  },
  appraisal_preview: {
    id: 'appraisal_preview',
    exact: true,
    path: '/preview/appraisal',
    render: (props) => <AppraisalPreview isPreview={true} />
  },
  view_profile: {
    id: 'view_profile',
    exact: true,
    path: '/view_profile',
    component: ViewProfile,
  },
  timed_out: {
    id: 'timed_out',
    exact: true,
    path: '/timed_out',
    component: TimedOut,
  },
}
 export default routes;