import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import Grid from '@material-ui/core/Grid'
import DialogDatePicker from 'components/general/DialogDatePicker'
import * as date from 'date-fns'
export const DialogDatePickerContent = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form>
          <DialogDatePicker
             label = {props.label }
             field = {props.field}
             minDate = {props.minDate}
             isDisabled = {props.isDisabled}
             defaultValue = {props.defaultValue}
          />
        </form>
      </Grid>
    </Grid>
  )
}

export const DialogDatePickerActions = (props) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }
  let updatedValue = useSelector(state => state.datePicker[props.field]) || props.defaultValue
  
  const isFuture = date.isFuture(new Date(updatedValue))

  const handleSave = () => {
    props.action(updatedValue)
    dispatch(closeDialog())
  }

  return (
    <>
      <Button data-testid="DatePickerDialogActions-close" onClick={handleClose}  variant='contained' color='secondary'>Cancel</Button>
      <Button data-testid="DatePickerDialogActions-confirm" disabled={!isFuture} onClick={handleSave} variant='contained'  color='primary'>Save</Button>
    </>
  )
}
