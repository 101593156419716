import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { createSelector } from '@reduxjs/toolkit'
import xor from 'lodash/xor'
import uniqBy from 'lodash/uniqBy'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ControlDescription from 'components/general/ControlDescription'
import Indicator from 'components/general/Indicator'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import {previewCancelledOrCompletedSelector} from 'ducks/previewSlice'

import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { decodeHtml } from 'utilTools/decodeHtml'

const Container = styled.div`
  padding-left: ${p => p.subquestion && '30px'};
`
// a little wider row spacing will make the alignment more clear when having multiple lines.
const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 5px;
`
// Data selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      description: data.description,
      label: data.label,
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty array)
      answer: data.answer ? data.answer : [],
      // This is a sanity check just in case there are duplicates
      options: uniqBy(data.options, 'optionId'),
      indicator: data.indicator,
    }
  })
}
// See the Xor documentation at Lodash: https://lodash.com/docs/4.17.15#xor
// toggle([1, 2], 3)
// => [1, 2, 3]
// toggle([1, 2], 2)
// => [1]
// This is defined outside of the component so it isn't rebuilt on each re-render
const toggle = (array, item) => xor(array, [item])

const CheckboxGrid = React.memo(
  ({
    question_id,
    isDisabled,
    disableDescription = false,
    subquestion,
    sawsIndicator,
    indicatorStyle,
    isPreview
  }) => {
    const dispatch = useDispatch()

    // We create a selector for each instance of the CheckboxGrid component, and
    // get current data from redux.
    const dataSelector = createDataSelector(isPreview)
    const {
      description,
      answer,
      options,
      label,
      indicator,
    } = useSelector(state => dataSelector(state, question_id))

    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )

    const hasIndicator = sawsIndicator ? indicator : 'noIndicator'

    const sendData = data => {
      dispatch(
        isPreview
        ? capturePreviewFormData({
          question_id: question_id,
          answer: data,
        })
        : captureFormData({
          question_id: question_id,
          answer: data,
        })
      )
    }

    // Collect change and dispatch to Redux
    // For Checkbox grids, we need to toggle (see above) items in and out
    // of arrays immutably before saving the new array of answers
    const handleChange = name => event => {
      if (cancelledOrCompletedInterview) return
      const updatedData = toggle(answer, name)
      sendData(updatedData)
    }

    // ADA requires enter key accessibility
    const handleKeyPress = name => e => {
      if (cancelledOrCompletedInterview) return
      const keyCode = e.which || e.keyCode
      const enterKeyCode = 13
      if (keyCode === enterKeyCode) {
        const updatedData = toggle(answer, name)
        sendData(updatedData)
      }
    }

    return (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                {sawsIndicator && (
                  <Indicator indicator={hasIndicator} style={indicatorStyle} />
                )}
                <FormGroup>
                  {options
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }, index) => {
                      return (
                        <StyledFormControlLabel
                          value={optionId}
                          control={
                            <Checkbox
                              disabled={true}
                              checked={answer.includes(optionId)}
                              value={optionLabel}
                            />
                          }
                          label={optionLabel}
                          key={`${optionId}-${index}`}
                        />
                      )
                    })}
                </FormGroup>
              </FormControl>
            </Container>
          )
        }}
        yes={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                {sawsIndicator && (
                  <Indicator indicator={hasIndicator} style={indicatorStyle} />
                )}
                <FormGroup>
                  {options
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }, index) => {
                      return (
                        <StyledFormControlLabel
                          value={optionId}
                          control={
                            <Checkbox
                              disabled={
                                cancelledOrCompletedInterview || isDisabled
                              }
                              checked={answer.includes(optionId)}
                              onChange={handleChange(optionId)}
                              onKeyPress={handleKeyPress(optionId)}
                              value={decodeHtml(optionLabel)}
                            />
                          }
                          label={optionLabel}
                          key={`${optionId}-${index}`}
                        />
                      )
                    })}
                </FormGroup>
              </FormControl>
            </Container>
          )
        }}
      />
    )
  }
)

export default CheckboxGrid
