import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import CheckboxGrid from 'components/general/CheckboxGrid'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import ControlDescription from 'components/general/ControlDescription'
import Text from 'components/general/Text'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Can from 'app/Can'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { previewInterviewSelector } from 'utilTools/reduxHelper'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`

export const qIdsI = [
  'employer_initiated_termination',
  'job_opportunity_job_end',
  'lack_satisfaction_job_end',
  'compensation_issue_job_end',
  'worksite_behavior_job_end',
  'experience_job_end',
]

export const qIdsII = [
  'health_job_end',
  'household_job_end',
  'child_care_job_end',
  'housing_transportation_job_end',
  'wages_job_end',
  'other_job_end',
]

const didNotProvideSpecificReasonSelector = state =>
  state.interview.did_not_provide_specific_reason.answer

const previewDidNotProvideSpecificReasonSelector = state =>
  state.preview.did_not_provide_specific_reason.answer

const otherJobEndSelector = state => state.interview.other_job_end.answer

const previewOtherJobEndSelector = state => state.preview.other_job_end.answer

const selectDidNotProvideSpecificReason = createSelector(
  didNotProvideSpecificReasonSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NR')
)

const selectPreviewDidNotProvideSpecificReason = createSelector(
  previewDidNotProvideSpecificReasonSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NR')
)

const selectOtherJobEnd = createSelector(
  otherJobEndSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const selectPreviewOtherJobEnd = createSelector(
  previewOtherJobEndSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const currentlyWorkingSelector = state =>
  state.interview.currently_working.answer

const previewCurrentlyWorkingSelector = state =>
  state.preview.currently_working.answer

const selectCurrentlyWorking = createSelector(
  currentlyWorkingSelector,
  answer =>
    answer?.toUpperCase() === 'YS' ||
    answer?.toUpperCase() === 'SL' ||
    answer?.toUpperCase() === 'SE'
)

const selectPreviewCurrentlyWorking = createSelector(
  previewCurrentlyWorkingSelector,
  answer =>
    answer?.toUpperCase() === 'YS' ||
    answer?.toUpperCase() === 'SL' ||
    answer?.toUpperCase() === 'SE'
)

const ReasonsNotWorking = React.memo(({ isPreview }) => {
  const didNotProvideSpecificReasonIsChecked = useSelector(
    isPreview ? selectPreviewDidNotProvideSpecificReason : selectDidNotProvideSpecificReason
  )
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  const hasReasonForNotWorking = [...qIdsI, ...qIdsII]
    .map(qId => {
      const answer = selectorState[qId]?.answer;
      return answer ?? false
    })
    .some(answer => answer)
  const otherJobEndIsChecked = useSelector(isPreview ? selectPreviewOtherJobEnd : selectOtherJobEnd)
  const { roles } = useSelector(state => state.user)
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )
  const currentlyWorking = useSelector(isPreview ? selectPreviewCurrentlyWorking : selectCurrentlyWorking)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Reasons Not Working'
          domain='Employment'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Do not read list aloud. Check all that apply.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <ControlDescription disabled>
                  Why are you not currently working?
                </ControlDescription>
              )
            }}
            yes={() => {
              return (
                <ControlDescription disabled={cancelledOrCompletedInterview}>
                  Why are you not currently working?
                </ControlDescription>
              )
            }}
          />
          <CheckboxGrid
            question_id='did_not_provide_specific_reason'
            isDisabled={currentlyWorking || hasReasonForNotWorking}
            isPreview={isPreview}
          />
          <CustomTableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {qIdsI.map((id, index) => {
                      return (
                        <Grid key={index}>
                          <CheckboxGrid
                            question_id={id}
                            isDisabled={
                              didNotProvideSpecificReasonIsChecked ||
                              currentlyWorking
                            }
                            isPreview={isPreview}
                          />
                        </Grid>
                      )
                    })}
                  </TableCell>
                  <TableCell>
                    {qIdsII.map((id, index) => {
                      return (
                        <Grid key={index}>
                          <CheckboxGrid
                            question_id={id}
                            isDisabled={
                              didNotProvideSpecificReasonIsChecked ||
                              currentlyWorking
                            }
                            isPreview={isPreview}
                          />
                        </Grid>
                      )
                    })}
                    <Text
                      question_id='specify_reason_not_working'
                      isDisabled={!otherJobEndIsChecked || currentlyWorking}
                      isPreview={isPreview}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CustomTableContainer>
        </form>
      </Grid>
    </Grid>
  )
})

export default ReasonsNotWorking
