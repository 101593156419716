import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resourceTypes: [],
  resourceData: {},
  currentResource: [],
  currentResourceType: 'Comprehensive Assessment',
  countyId: null,
}

const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setCurrentResourceType: (state, action) => {
      state.currentResourceType = action.payload.currentResourceType
    },
    setCurrentResourceId: (state, action) => {
      state.currentResourceId = action.payload.currentResourceId
    },
    setResourceTypes: (state, action) => {
      state.resourceTypes = action.payload.resourceTypes
    },
    loadResources: (state, action) => {
      state.resourceData = action.payload.resources
    },
    loadCurrentResource: (state, action) => {
      state.currentResource =
        state.resourceData[state.currentResourceType] || []
    },
    addResource: (state, action) => {
      state.currentResource.push({
        name: '',
        address: '',
        phone: '',
        url: '',
        description: '',
        dirty: true,
      })
    },
    updateResource: (state, action) => {
      state.currentResource[action.payload.index][action.payload.key] =
        action.payload.value
      state.currentResource[action.payload.index].dirty = action.payload.dirty
    },
    deleteResource: (state, action) => {
      state.currentResource = action.payload
      state.resourceData[state.currentResourceType] = action.payload.filter(
        resource => !resource.dirty
      )
    },
    cancelResourceEdits: (state, action) => {
      state.currentResource =
        state.resourceData[state.currentResourceType] || []
    },
    setCountyId: (state, action) => {
      state.countyId = action.payload
    },
    setCurrentResource: (state, action) => {
      state.currentResource = action.payload
    },
    setCurrentResourceData: (state, action) => {
      state.resourceData[
        state.currentResourceType
      ] = state.currentResource.filter(resource => !resource.dirty)
    },
  },
})

export const {
  setCurrentResourceType,
  setCurrentResourceId,
  setResourceTypes,
  loadResources,
  loadCurrentResource,
  addResource,
  updateResource,
  deleteResource,
  cancelResourceEdits,
  setCountyId,
  setCurrentResource,
  setCurrentResourceData,
} = resourceSlice.actions

export default resourceSlice.reducer
