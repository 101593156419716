import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styled from 'styled-components'
import { ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'app/theme'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import NavIcon from 'components/navigation/NavIcon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import {
  setCurrentResourceType,
  setCurrentResourceId,
  cancelResourceEdits,
  loadCurrentResource,
  setResourceTypes,
} from 'ducks/resourceSlice'
import { useQuery } from '@apollo/react-hooks'
import resourceQueries from 'components/resources/ResourceQueries'
import { openDialog } from 'ducks/dialogSlice'
import Can from 'app/Can'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_RESOURCE_LINK } from '../help/Help'

const StyledListItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },

    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(ListItem)

const DomainIcon = styled(ListItemIcon)`
  && {
    font-size: 16px;
    min-width: 32px;
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      font-weight: 700;
      color: ${p => p.active && colors.turquoise};
    }
  }
`

const ResourceCategory = ({ name, domains }) => {
  const [open, setOpen] = useState(true)
  const handleClick = () => setOpen(!open)
  return (
    <>
      <StyledListItem
        disableRipple
        disableTouchRipple
        button
        dense
        onClick={handleClick}
        open={open}
        divider={open}
      >
        <StyledListItemText primary={name} active={open ? 1 : 0} />
        {open ? (
          <ExpandLess fontSize='small' />
        ) : (
          <ExpandMore fontSize='small' />
        )}
      </StyledListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {domains}
        </List>
      </Collapse>
    </>
  )
}

const ResourceDomain = ({ name }) => {
  const dispatch = useDispatch()
  const currentResourceType = useSelector(
    state => state.resources.currentResourceType
  )
  const currentResourceId = useSelector(state =>
    state.resources.resourceTypes.find(
      resourceType => resourceType.name === currentResourceType
    )
  )?.id
  const data = useSelector(state => state.resources.resourceData[name])
  useEffect(() => {
    dispatch(setCurrentResourceId({ currentResourceId }))
  }, [dispatch, currentResourceId])
  const isDirty = useSelector(state =>
    state.resources.currentResource.some(resource => resource.dirty)
  )
  const handleClick = () => {
    if (isDirty) {
      dispatch(
        openDialog({
          type: 'discardResources',
          title: `Discard Changes?`,
          description: '',
          props: {
            newResourceType: name,
            newResourceId: currentResourceId,
          },
        })
      )
    } else {
      dispatch(cancelResourceEdits())
      dispatch(setCurrentResourceType({ currentResourceType: name }))
      dispatch(setCurrentResourceId({ currentResourceId }))
      dispatch(loadCurrentResource())
    }
  }
  const domainStatus = data ? 'complete' : 'in progress'
  const open = currentResourceType === name

  return (
    <>
      <StyledListItem
        disableRipple
        disableTouchRipple
        button
        dense
        onClick={handleClick}
        open={open}
        divider={open}
      >
        <DomainIcon>
          <DomainIcon>
            <NavIcon status={domainStatus} />
          </DomainIcon>
        </DomainIcon>
        <StyledListItemText primary={name} active={open ? 1 : 0} />
      </StyledListItem>
    </>
  )
}

const userSelector = state => state.user
const resourceSelector = state => state.resources
const selectResourcesSideBarState = createSelector(
  [userSelector, resourceSelector],
  (user, resource) => ({
    roles: user.roles,
    resourceTypes: resource.resourceTypes,
  })
)

const ResourceSideBar = props => {
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(
    resourceQueries.GET_RESOURCE_TYPES_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )
  const { roles, resourceTypes } = useSelector(selectResourcesSideBarState)

  React.useEffect(() => {
    if (error)
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    if (data) {
      const resourceTypes = data?.resourceTypes.map(resourceType => ({
        id: resourceType.id,
        name: resourceType.name,
      }))
      dispatch(setResourceTypes({ resourceTypes }))
    }
  }, [error, data, dispatch])
  if (loading) return <></>
  const domainElements = resourceTypes.map((resourceType, index) => (
    <ResourceDomain name={resourceType.name} key={`resource-domain-${index}`} />
  ))
  return (
    <>
      <Can
        roles={roles}
        perform='resourcesSideBar:view'
        yes={() => {
          return (
            <>
              <ResourceCategory name='Resources' domains={domainElements} />
              <RightsAndPrivacy />
            </>
          )
        }}
      />
    </>
  )
}

const RightsAndPrivacy = props => {
  const [open, setOpen] = React.useState(false)

const [queryStaticResource, { data: resourceData, loading }] = useLazyQuery(
  GET_RESOURCE_LINK,
  {
    fetchPolicy: 'network-only',
  }
)

const getResourceLink = resourceId => {
  queryStaticResource({
    variables: { resourceId }
  })
}

useEffect(() => {
  if(resourceData && !loading) {
    setOpen(true)
    setTimeout(() => {
      window.open(resourceData.getResourceLink)
    })
  }
  else {
    setOpen(false)
  }
}, [resourceData, loading])

  const handleClick = () => 
    getResourceLink('rightsAndPrivacy')
  
  return (
    <>
      <StyledListItem
        disableRipple
        disableTouchRipple
        button
        dense
        onClick={handleClick}
        open={open}
        divider={open}
        component='a'
        target='_blank'
        rel='noopener noreferrer'
      >
        <StyledListItemText
          primary='OCAT Rights and Privacy Form'
          active={open ? 1 : 0}
        />
      </StyledListItem>
    </>
  )
}

export default ResourceSideBar
