import React from 'react'
import styled from 'styled-components'
import MultiSelectDropdown from './MultiSelectDropdown'
import ClientListDropDown from './ClientListDropDown'
import ClientListCountyDropdown from './ClientListCountyDropdown'
import { FILTER_TYPES, SPECIAL_VALUES } from './clientListHelper'
import { decodeHtml } from 'utilTools/decodeHtml'

const Container = styled.div`
  display: flex;
  padding: 0;
  margin-top: 10px;
`
const InnerContainer = styled.div`
  flex-direction: column;
`

const Label = styled.label`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`

const ClientListFilterMenu = ({
  defaultFilters,
  updateFilters,
  isStatewide,
  interviewers,
}) => {
  const statusFilter = defaultFilters.status
  const interviewerFilters = defaultFilters.interviewer
  const dateFilter = defaultFilters.dates
  const countyFilter = defaultFilters.countyFilter

  // Handle interviewer filter selections
  const handleInterviewerChange = (interviewer, selected) => {
    // Clone interviewer filter and update with most recent selection
    let newInterviewerFilters = [...interviewerFilters]
    if (selected) {
      newInterviewerFilters.push(interviewer)
    } else {
      const indexToRemove = interviewerFilters.findIndex(
        val => val === interviewer
      )
      newInterviewerFilters = [
        ...interviewerFilters.slice(0, indexToRemove),
        ...interviewerFilters.slice(indexToRemove + 1),
      ]
    }

    // Check to see if the user selected "All" or removed all options
    const userSelectedAll = interviewer === SPECIAL_VALUES.ALL_OPTION_ID && selected
    const userRemovedAllItems =
      newInterviewerFilters.filter(val => val !== SPECIAL_VALUES.ALL_OPTION_ID).length === 0

    if (userSelectedAll || userRemovedAllItems) {
      let allInterviewsFilter = [SPECIAL_VALUES.ALL_OPTION_ID]
      updateFilters(allInterviewsFilter, FILTER_TYPES.INTERVIEWER)
      return
    } else {
      // there is a value in the filter list other than 'all' so remove all if it exists
      updateFilters(
        newInterviewerFilters.filter(val => val !== SPECIAL_VALUES.ALL_OPTION_ID),
        FILTER_TYPES.INTERVIEWER
      )
    }
  }

  // Handle status filter selections
  const handleStatusChange = (status, selected) => {
    let newStatusFilter = [...statusFilter]
    if (selected) {
      newStatusFilter.push(status)
    } else {
      const indexToRemove = statusFilter.findIndex(val => val === status)
      newStatusFilter = [
        ...statusFilter.slice(0, indexToRemove),
        ...statusFilter.slice(indexToRemove + 1),
      ]
    }

    // Check to see if the user selected "All" or removed all options
    const userSelectedAll = status?.toLowerCase() === SPECIAL_VALUES.ALL_OPTION_ID && selected
    const userRemovedAllItems =
      newStatusFilter.filter(val => val.toLowerCase() !== SPECIAL_VALUES.ALL_OPTION_ID).length === 0

    if (userSelectedAll || userRemovedAllItems) {      
      let allStatusFilter = [SPECIAL_VALUES.ALL_OPTION_DISPLAY]
      updateFilters(allStatusFilter, FILTER_TYPES.STATUS)
      return
    } else {
      // there is a value in the filter list other than 'all' so remove all if it exists
      updateFilters(
        newStatusFilter.filter(val => val.toLowerCase() !== SPECIAL_VALUES.ALL_OPTION_ID),
        FILTER_TYPES.STATUS
      )
    }
  }

  // Handle selection of dates
  const handleDateChange = dates => {
    updateFilters(dates, FILTER_TYPES.DATES)
  }

  const handleCountyChange = countyId => {
    updateFilters(countyId, FILTER_TYPES.COUNTY)
  }

  const allInterviewersMenuItem = {
    id: SPECIAL_VALUES.ALL_OPTION_ID,
    name: SPECIAL_VALUES.ALL_OPTION_DISPLAY,
  }

  const interviewerMenuItems = [
    allInterviewersMenuItem,
    ...interviewers.map(interviewer => {
      return interviewer.id === null || interviewer.id === undefined
        ? {
            id: SPECIAL_VALUES.UNASSIGNED_OPTION_ID,
            name: SPECIAL_VALUES.UNASSIGNED_OPTION_DISPLAY,
          }
        : {
            id: interviewer.id,
            name: `${decodeHtml(interviewer.firstName)} ${decodeHtml(interviewer.lastName)}`,
          }
    }),
  ]

  const portalInterviewer = 'portal-interviewer'
  const portalStatus = 'portal-status'

  const statusMenuItems = [
    { name: SPECIAL_VALUES.ALL_OPTION_DISPLAY },
    { name: 'New' },
    { name: 'In Process' },
    { name: 'Ready to Generate' },
    { name: 'Complete' },
    { name: 'Cancelled' },
  ]

  return (
    <Container>
      <InnerContainer>
        <Label htmlFor='interviewer'>
          Interviewer
          <MultiSelectDropdown
            portalId={portalInterviewer}
            name='interviewer'
            id='interviewer'
            menuItems={interviewerMenuItems}
            handleChange={handleInterviewerChange}
            displayField='name'
            valueField='id'
            selectedValues={interviewerFilters ?? []}
          />
          <div id={portalInterviewer} />
        </Label>
      </InnerContainer>
      <InnerContainer>
        <Label htmlFor='status'>
          Status
          <MultiSelectDropdown
            portalId={portalStatus}
            name='Status'
            id='Status'
            menuItems={statusMenuItems}
            handleChange={handleStatusChange}
            displayField='name'
            valueField='name'
            selectedValues={statusFilter}
          />
          <div id={portalStatus} />
        </Label>
      </InnerContainer>
      <InnerContainer>
        <Label htmlFor='dates' data-testid="ClientListFilterMenu-filter-dates">
          Dates
          <ClientListDropDown
            name='dates'
            id='dates'
            options={[
              {
                label: 'All Dates',
                value: 'All Dates',
              },
              {
                label: 'Last 7 Days',
                value: 'Last 7 Days',
              },
              {
                label: 'Last 30 Days',
                value: 'Last 30 Days',
              },
              {
                label: 'Last Month',
                value: 'Last Month',
              },
            ]}
            handleChange={handleDateChange}
            defaultValue={dateFilter}
          />
        </Label>
      </InnerContainer>
      {isStatewide && (
        <InnerContainer>
          <Label htmlFor='county'>
            County
            <ClientListCountyDropdown
              name='county'
              id='county'
              handleChange={handleCountyChange}
              defaultValue={countyFilter}
            />
          </Label>
        </InnerContainer>
      )}
    </Container>
  )
}

export default ClientListFilterMenu
