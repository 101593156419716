import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import MaterialLink from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import LockIcon from '@material-ui/icons/Lock'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditAssignee from 'components/interviewList/EditAssignee'
import { formatInterviewRecords } from './InterviewListHelper'
import { openDialog } from 'ducks/dialogSlice'
import {
  setHasExistingCase,
  setCanCreateInterview,
} from 'ducks/newInterviewSlice'
import { colors } from 'app/theme'
import { withStyles } from '@material-ui/core/styles'
import { handleExistingInterview } from 'thunks/handleExistingInterview'

import SharingButton from './SharingButton'
import PrintQuestionnaireButton from './PrintQuestionnaireButton'
import Can from 'app/Can'
import RetrieveAsrPdfButton from '../general/RetrieveAsrPdfButton'
import { decodeHtml } from '../../utilTools/decodeHtml'

const sharedStyle = css`
  display: flex;
  font-size: 16px;
  justify-content: center;
  color: ${colors.calsawsBlue};

  &:focus {
    outline: 1px solid ${colors.saffron};
  }
  &:active {
    outline: 0;
  }
`

const StyledDivComponent = styled(Grid)`
  && {
    padding-top: 60px;
  }
`

const StyledDoubleArrowIcon = styled(DoubleArrowIcon)`
  && {
    &:hover {
      color: ${colors.saffron};
    }
  }
`

const StyledMaterialLink = styled(MaterialLink)`
  && {
    font-size: 16px;
    text-decoration: underline;
    color: #1a3360;
  }
`
const StyledLockIcon = styled(LockIcon)`
  color: ${colors.saffron};
  cursor: pointer;
`

const StyledLinkComponent = styled(Link)`
  && {
    ${sharedStyle}
  }
`
const StyledIconContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  display: flex;
`

const StyledContainer = styled(IconButton)`
  && {
    background-color: transparent;
    width: 100%;
    ${sharedStyle}
  }
`

const StyledTableHeader = withStyles({
  root: {
    color: colors.calsawsBlue,
    fontSize: '16px',
  },
})(TableCell)

// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledRow = withStyles({
  root: {
    '&:hover': {
      outline: `1px solid ${colors.saffron}`,
      outlineOffset: '-1px',
    },
  },
  selected: {
    outline: `1px solid ${colors.saffron}`,
    backgroundColor: `${colors.white} !important`,
  },
})(TableRow)

const headCells = [
  {
    id: 'dateInitiated',
    label: 'Initiated Date',
  },
  { id: 'appraisalDate', label: 'Appraisal Date' },
  { id: 'county', label: 'County' },
  { id: 'sawsCaseNumber', label: 'SAWS Case Num.' },
  { id: 'appraisalStatus', label: 'Appraisal Status' },
  { id: 'questionnaire', label: 'Questionnaire' },
  { id: 'asr', label: 'ASR' },
  { id: 'interviewer', label: 'Interviewer' },
  { id: 'action', label: 'Action' },
  { id: 'locked', label: 'Locked' },
]

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableHeader key={headCell.id}>
            {headCell.label}
          </StyledTableHeader>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function InterviewList({ records, refetchInterviewList }) {
  const dispatch = useDispatch()
  const { countyId, isStatewide, roles } = useSelector(state => state.user)
  const { hasExistingCase } = useSelector(state => state.newInterview)
  const {
    currentClientFirstName,
    currentClientLastName,
    currentClientDob,
    currentClientCin,
  } = useSelector(state => state.client)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { rows, existingCase } = formatInterviewRecords({
    records,
    dispatch,
    countyId,
    isStatewide
  })

  // Check if there are existing cases. If there was an existing case found,
  // the redux has no recollection of it, update the store.
  useEffect(() => {
    if (existingCase !== hasExistingCase)
      dispatch(setHasExistingCase(existingCase))
  }, [dispatch, existingCase, hasExistingCase])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const title = decodeHtml(`${currentClientFirstName} ${currentClientLastName}`)

  const handleNewInterview = () => {
    dispatch(setCanCreateInterview(true))
    dispatch(
      openDialog({
        type: 'newAppraisal',
        title: `Initiating New Appraisal`,
        description: 'Please select from the following options.',
      })
    )
  }

  const handleInterviewLaunch = rowData => {
    dispatch(setCanCreateInterview(true))
    dispatch(
      openDialog({
        type: 'newAppraisalPopulate',
        title: `Initiating New Appraisal`,
        description: 'Please select from the following options.',
        props: {
          isNewCaseNumber: false,
          caseNumber: rowData.caseNumber,
          isExistingInterview: true,
          rowData,
        },
      })
    )
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // ADA requires selecting the tableRow when any element in this row is selected
  const [currentRow, setCurrentRow] = React.useState(null)

  const handleTableRowFocus = index => {
    currentRow !== index && setCurrentRow(index)
  }
  const handleTableRowBlur = () => {
    currentRow !== null && setCurrentRow(null)
  }

  const isStatusCompleteOrCancelled = status =>
    status?.toLowerCase() === 'cancelled' ||
    status?.toLowerCase() === 'complete'

  const canEdit = row =>
    row.countyId === countyId ||
    isStatewide ||
    row.sharingCounties.includes(countyId)

  const handleMouseLeave = () => currentRow !== null && setCurrentRow(null)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h3'>{title}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h5'>Interview List</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h5'>{`CIN: ${currentClientCin}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h5'>{`DOB: ${currentClientDob}`}</Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Can
          roles={roles}
          perform='interview:edit'
          yes={() => {
            return (
              <Grid
                item
                xs={3}
                container
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
              >
                <StyledDivComponent>
                  <StyledMaterialLink
                    component='button'
                    onClick={handleNewInterview} 
                  >
                    Initiate New Appraisal
                  </StyledMaterialLink>
                </StyledDivComponent>
              </Grid>
            )
          }}
        />
      </Grid>
      <Paper>
        <TableContainer>
          <Table aria-label='interview table'>
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledRow
                      tabIndex={`row-${index}`}
                      onFocus={() => handleTableRowFocus(index)}
                      onMouseLeave={handleMouseLeave}
                      onBlur={handleTableRowBlur}
                      key={`${row.caseNumber}-${index}`}
                      selected={currentRow === index}
                    >
                      <TableCell component='th' scope='row'>
                        {row.initiatedDate
                          ? format(new Date(row.initiatedDate), 'MM/dd/yyyy')
                          : ''}
                      </TableCell>
                      <TableCell>{row.asrDate}</TableCell>
                      <TableCell>{row.county}</TableCell>
                      <TableCell>{row.caseNumber}</TableCell>
                      <TableCell>{row.asrStatus}</TableCell>
                      <TableCell>
                        {canEdit(row) &&
                          row.asrStatus?.toLowerCase() === 'complete' && (
                            <PrintQuestionnaireButton
                              rowIndex={index}
                              interviewId={row.interviewId}
                            />
                          )}
                      </TableCell>
                      <TableCell>
                        {canEdit(row) &&
                        row.asrStatus?.toLowerCase() === 'complete' ? (
                          <RetrieveAsrPdfButton interviewId={row.interviewId} />
                        ) : null}
                      </TableCell>
                      <TableCell align='center'>
                        <EditAssignee
                          refetchInterviewList={refetchInterviewList}
                          index={index}
                          assignee={row.assigneeEditData}
                          interviewId={row.interviewId}
                          countyId={row.countyId}
                          readonly={
                            isStatusCompleteOrCancelled(row.asrStatus) ||
                            !canEdit(row)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <StyledIconContainer>
                          {
                            // Display the launch arrows that prompts a user to either populate
                            // an interview with a previous interviews answers or just enter an
                            // interview.
                            canEdit(row) &&
                            row.asrStatus?.toLowerCase() === 'new' &&
                            hasExistingCase ? (
                              <Can
                                roles={roles}
                                perform='interview:edit'
                                yes={() => {
                                  return (
                                    <StyledContainer
                                      disableTouchRipple={true}
                                      disableRipple={true}
                                      onClick={() => handleInterviewLaunch(row)}
                                    >
                                      <StyledDoubleArrowIcon />
                                    </StyledContainer>
                                  )
                                }}
                                no={() => {
                                  return (
                                    <StyledLinkComponent
                                      aria-label={`Link to interview page ${index}`}
                                      to={`/interview/demographics_general_information`}
                                      onClick={() =>
                                        dispatch(handleExistingInterview(row))
                                      }
                                    >
                                      <DoubleArrowIcon />
                                    </StyledLinkComponent>
                                  )
                                }}
                              />
                            ) : // Displays the launch arrows for interviews that are in the county, if the user is statewide,
                            // or being shared with that county.
                            canEdit(row) ? (
                              <StyledLinkComponent
                                aria-label={`Link to interview page ${index}`}
                                to={`/interview/demographics_general_information`}
                                onClick={() =>
                                  dispatch(handleExistingInterview(row))
                                }
                              >
                                <StyledDoubleArrowIcon />
                              </StyledLinkComponent>
                            ) : // Else, don't display that interview.
                            null
                          }
                          {(row.countyId === countyId || isStatewide) &&
                            row.asrStatus?.toLowerCase() === 'complete' && (
                              <Can
                                roles={roles}
                                perform='interview:share'
                                yes={() => {
                                  return (
                                    <SharingButton
                                      rowIndex={index}
                                      interviewId={row.interviewId}
                                      clientId={row.clientId}
                                      countyId={row.countyId}
                                    />
                                  )
                                }}
                              />
                            )}
                        </StyledIconContainer>
                      </TableCell>
                      <TableCell>
                        {row.asrStatus?.toLowerCase() === 'complete' ||
                        row.asrStatus?.toLowerCase() === 'cancelled' ? (
                          <StyledLockIcon aria-label={`Locked ASR ${index}`} />
                        ) : null}
                      </TableCell>
                    </StyledRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
