import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { isAfter, isPast, isFuture, isValid, parse, format } from 'date-fns'

import TableCell from '@material-ui/core/TableCell'
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers'

import ControlDescription from 'components/general/ControlDescription'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { decodeHtml } from 'utilTools/decodeHtml'

const StyledPicker = styled(DatePicker)`
  && {
     ${p => p.error && !p.notable && `margin-top: 42px;`}
    width: ${p => p.width};
    margin-bottom: 10px;
  }
`

const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (index, sub_question_id, isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      tableAnswers: data.answer || [],
      label: data.sub_question_ids[sub_question_id]?.label || '',
      description: data.sub_question_ids[sub_question_id]?.description || '',
      subQuestionAnswer:
        data.answer &&
        index !== undefined &&
        data.answer[index][sub_question_id]
          ? data.answer[index][sub_question_id]
          : null,
    }
  })
}
const validationSelector = (state, question_id) =>
  state.validationErrors[question_id]
const createValidationSelector = () => {
  return createSelector(validationSelector, validation => {
    return {
      hasValidationError: validation ? true : false,
      validationError: validation ? validation : null,
    }
  })
}

const InfiniteDatePicker = ({
  datePickerOptions = { disableFuture: true, format: 'MM/dd/yyyy' },
  index,
  width = '250px',
  question_id,
  isDisabled,
  hideDescription = true,
  hideLabel = false,
  sub_question_id,
  noTable = false,
  ariaForButton = null,
  ariaForInput = null,
  className,
  isPreview
}) => {
  // Dispatch and Selectors
  const dispatch = useDispatch()
  const dataSelector = createDataSelector(index, sub_question_id, isPreview)
  const {
    description,
    tableAnswers,
    subQuestionAnswer,
    label,
  } = useSelector(state => dataSelector(state, question_id))
  const validationSelector = createValidationSelector()
  const { roles } = useSelector(state => state.user)
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )
  const { hasValidationError, validationError } = useSelector(state =>
    validationSelector(state, question_id)
  )

  // Input Handler
  const handleDateChange = (date, value) => {
    if (cancelledOrCompletedInterview) return
    let updatedTableAnswers = tableAnswers.map(answer => {
      const rowAnswers = {}
      Object.keys(answer).forEach(
        sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
      )
      return rowAnswers
    })
    updatedTableAnswers[index][sub_question_id] = value
    dispatch(
      isPreview
      ? capturePreviewFormData({ question_id, answer: updatedTableAnswers })
      : captureFormData({ question_id, answer: updatedTableAnswers })
    )
  }

  const parsedAnswer = parse(
    subQuestionAnswer,
    datePickerOptions.format,
    new Date()
  )
  const isValidDate =
    isValid(parsedAnswer) && isAfter(parsedAnswer, new Date('01/01/1000'))

  const isPastDate = datePickerOptions.disablePast && isPast(parsedAnswer)

  const isFutureDate = datePickerOptions.disableFuture && isFuture(parsedAnswer)

  const doesSubQuestionHaveError = [!isValidDate, isPastDate, isFutureDate]

  // Label for ADA
  const inputId = `${sub_question_id}-input-${index + 1}`
  const buttonLabel = ariaForButton
    ? ariaForButton
    : `Button of the date picker ${inputId}`
  const inputLabel = ariaForInput ? ariaForInput : `Date input ${inputId}`

  const datePicker = (
    <Can
      roles={roles}
      perform={isPreview ? 'BRE:view' : 'interview:edit'}
      no={() => {
        return (
          <div className={className}>
            {!hideDescription && description && (
              <ControlDescription disabled={true}>
                {decodeHtml(description)}
              </ControlDescription>
            )}

            <StyledPicker
              KeyboardButtonProps={{
                'aria-label': buttonLabel,
              }}
              inputProps={{
                'aria-label': inputLabel,
              }}
              notable={noTable ? 1 : 0}
              disabled={true}
              id={inputId}
              width={width}
              label={hideLabel ? undefined : decodeHtml(label)}
              variant='inline'
              inputVariant='outlined'
              inputValue={subQuestionAnswer}
              value={
                isValidDate
                  ? format(
                      parse(
                        subQuestionAnswer,
                        datePickerOptions.format,
                        new Date()
                      ),
                      'MM/dd/yyyy'
                    )
                  : subQuestionAnswer
              }
              error={
                !subQuestionAnswer
                  ? false
                  : doesSubQuestionHaveError.some(param => param) &&
                    hasValidationError
              }
              helperText={
                !subQuestionAnswer
                  ? null
                  : doesSubQuestionHaveError.some(param => param) &&
                    validationError
              }
              initialFocusedDate={null}
              maxDateMessage={null}
              minDateMessage={null}
              {...datePickerOptions}
            />
          </div>
        )
      }}
      yes={() => {
        return (
          <div className={className}>
            {!hideDescription && description && (
              <ControlDescription
                disabled={cancelledOrCompletedInterview || isDisabled}
              >
                {decodeHtml(description)}
              </ControlDescription>
            )}

            <StyledPicker
              KeyboardButtonProps={{
                'aria-label': buttonLabel,
              }}
              inputProps={{
                'aria-label': inputLabel,
              }}
              notable={noTable ? 1 : 0}
              disabled={cancelledOrCompletedInterview || isDisabled}
              id={inputId}
              width={width}
              label={hideLabel ? undefined : decodeHtml(label)}
              variant='inline'
              inputVariant='outlined'
              inputValue={subQuestionAnswer}
              value={
                isValidDate
                  ? format(
                      parse(
                        subQuestionAnswer,
                        datePickerOptions.format,
                        new Date()
                      ),
                      'MM/dd/yyyy'
                    )
                  : subQuestionAnswer
              }
              error={
                !subQuestionAnswer
                  ? false
                  : doesSubQuestionHaveError.some(param => param) &&
                    hasValidationError
              }
              helperText={
                !subQuestionAnswer
                  ? null
                  : doesSubQuestionHaveError.some(param => param) &&
                    validationError
              }
              initialFocusedDate={null}
              onChange={(date, value) => handleDateChange(date, value)}
              maxDateMessage={null}
              minDateMessage={null}
              {...datePickerOptions}
            />
          </div>
        )
      }}
    />
  )
  if (noTable) {
    return datePicker
  }
  return <TableCell align='center'>{datePicker}</TableCell>
}

export default InfiniteDatePicker
