export const PENDING_CHANGE_TYPES = {
    disableRule: 'DISABLE_RULE', 
    enableRule: 'ENABLE_RULE',
    updateRuleDescription: 'UPDATE_RULE_DESCRIPTION',
    updateRuleType: 'UPDATE_RULE_TYPE',
    updateRuleCategory: 'UPDATE_RULE_CATEGORY',
    updateRuleDomain: 'UPDATE_RULE_DOMAIN',
    updateQuestionText: 'UPDATE_QUESTION_TEXT',
    updateQuestionAnswerText: 'UPDATE_QUESTION_ANSWER_TEXT',
    addQuestionAnswer: 'ADD_QUESTION_ANSWER',
    removeQuestionAnswer: 'REMOVE_QUESTION_ANSWER'
}

export const CHANGE_TYPE_DESCRIPTIONS = {
    DISABLE_RULE: 'Disable Rule',
    ENABLE_RULE: 'Enable Rule',
    UPDATE_RULE_DESCRIPTION: 'Recommendation Update',
    UPDATE_RULE_TYPE: 'Type Update',
    UPDATE_RULE_CATEGORY: 'Category Update',
    UPDATE_RULE_DOMAIN: 'Domain Update',
    UPDATE_QUESTION_TEXT: 'Question Text Update',
    UPDATE_QUESTION_ANSWER_TEXT: 'Answer Text Update',
    ADD_QUESTION_ANSWER: 'Add Answer Option',
    REMOVE_QUESTION_ANSWER: 'Remove Answer Option'
}

export const RECORD_TYPES = {
    RULE: 'Rule',
    QUESTION: 'Question'
}

export const QUESTION_TYPE_DESCRIPTIONS = { 
    radio: 'Radio',
    checkbox: 'Checkbox',
    checkbox_list: 'Checkbox List',
    textbox: 'Textbox',
    dropdown: 'Dropdown',
    dropdown_label: 'Dropdown Label',
    textarea: 'Textarea',
    table_question: 'Table Question',
}
