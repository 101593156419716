import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'convicted_criminal_offense' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('type_of_offense', null)
    effectCollector.add('explain_legal_barrier', null)
    effectCollector.add('on_parole_now', null)
    effectCollector.add('upcoming_court_dates', null)
    effectCollector.add('court_dates_table', null)
  }
  return effectCollector.effects
}

export default effects;