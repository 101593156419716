import React from 'react'
import Typography from '@material-ui/core/Typography'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Select from 'components/general/Select'
import SkipButton from 'components/general/SkipButton'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const victimIds = [
  'previously_been_abused',
  'previously_applied_restraining_order',
  'current_restraining_order',
  'previous_partner_control_life',
  'current_concerns_safety',
]

const perpetratorIds = [
  'previously_caused_fear',
  'previously_witnessed_domestic_abuse',
  'temper_concern_friends',
  'current_restraining_order_self',
]

const previouslyAppliedRestrainingOrderSelector = state =>
  state.interview.previously_applied_restraining_order.answer

const previewPreviouslyAppliedRestrainingOrderSelector = state =>
  state.preview.previously_applied_restraining_order.answer

const clientPartnerPresentSelector = state =>
  state.interview.client_partner_present.answer

const previewClientPartnerPresentSelector = state =>
  state.preview.client_partner_present.answer

const selectPreviouslyAppliedRestrainingOrder = createSelector(
  previouslyAppliedRestrainingOrderSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewPreviouslyAppliedRestrainingOrder = createSelector(
  previewPreviouslyAppliedRestrainingOrderSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectClientPartnerPresent = createSelector(
  clientPartnerPresentSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('FP')
)

const selectPreviewClientPartnerPresent = createSelector(
  previewClientPartnerPresentSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('FP')
)

const indicatorSelectors = [
  ...victimIds,
  ...perpetratorIds,
].map(question => state => state.interview[question])

const previewIndicatorSelectors = [
  ...victimIds,
  ...perpetratorIds,
].map(question => state => state.preview[question])

const canSkipSelector = createSelector(indicatorSelectors, (...questions) => {
  for (let question of questions) {
    if (question.question_id !== 'current_restraining_order') {
      if (!question.answer || question.answer.toUpperCase() === 'YS') {
        return false
      }
    }
  }
  return true
})

const previewCanSkipSelector = createSelector(previewIndicatorSelectors, (...questions) => {
  for (let question of questions) {
    if (question.question_id !== 'current_restraining_order') {
      if (!question.answer || question.answer.toUpperCase() === 'YS') {
        return false
      }
    }
  }
  return true
})

const DomesticAbuseIndicatorQuestions = React.memo(({ isPreview }) => {
  const previouslyAppliedRestrainingOrderIsYes = useSelector(
    isPreview ? selectPreviewPreviouslyAppliedRestrainingOrder : selectPreviouslyAppliedRestrainingOrder
  )
  const clientPartnerPresentIsChecked = useSelector(
    isPreview ? selectPreviewClientPartnerPresent : selectClientPartnerPresent
  )
  // Disable skip button if client_partner_present is checked
  // OR All responses for Victim and Perpetrator questions are NO
  const canSkip = useSelector(
    isPreview ? previewCanSkipSelector : canSkipSelector
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Domestic Abuse'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions to worker: Following is guidance, not intended to be read
          to the client. Refer individual to his or her copy of the OCAT Rights
          and Privacy Overview form. Remind individual that the interview is
          confidential except for mandated reporting of suspected elder and
          child abuse. Worker can ask the Victim and/or Perpetrator Indicator
          questions; however if client expressed discomfort, the worker can skip
          some of these Indicator questions and proceed to the appropriate
          subsection.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Indicator Questions</Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='client_partner_present' isPreview={isPreview} />
          <Grid item xs={12}>
            <Typography variant='h5'>Victim Indicator Questions:</Typography>
          </Grid>
          <Grid item xs={12}>
            {victimIds.map(id => {
              if (id === 'current_restraining_order') {
                return (
                  <Select
                    key={id}
                    question_id={id}
                    isDisabled={!previouslyAppliedRestrainingOrderIsYes}
                    subquestion
                    isPreview={isPreview}
                  />
                )
              } else {
                return (
                  <Select
                    key={id}
                    question_id={id}
                    isDisabled={clientPartnerPresentIsChecked}
                    isPreview={isPreview}
                  />
                )
              }
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>
              Perpetrator Indicator Questions:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {perpetratorIds.map(id => (
              <Select
                key={id}
                question_id={id}
                isDisabled={clientPartnerPresentIsChecked}
                isPreview={isPreview}
              />
            ))}
          </Grid>
        </form>
        <SkipButton
          isDisabled={!clientPartnerPresentIsChecked && !canSkip}
        ></SkipButton>
      </Grid>
    </Grid>
  )
})

export default DomesticAbuseIndicatorQuestions
