import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import styled from 'styled-components'
import uniqBy from 'lodash/uniqBy'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ControlDescription from './ControlDescription'
import Indicator from 'components/general/Indicator'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { colors } from 'app/theme'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { decodeHtml } from 'utilTools/decodeHtml'

const Container = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  padding-left: ${p => p.subquestion && '30px'};
`

const CustomDropdown = styled(Select)`
  && {
    width: ${p => p.width};
  }
`

const NoSelection = styled(MenuItem)`
  && {
    color: ${colors.grey};
  }
`

const style = {
  marginTop: '14px',
  marginRight: '-10px',
}

// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      description: data.description,
      label: data.label,
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty string)
      answer: data.answer ? data.answer : '',
      // This is a sanity check just in case there are duplicates
      options: uniqBy(data.options, 'optionId'),
      indicator: data.indicator,
    }
  })
}
const Dropdown = React.memo(
  ({
    question_id,
    isDisabled,
    width = '250px',
    filter,
    disableDescription = false,
    subquestion,
    sawsIndicator,
    isPreview,
  }) => {
    const dispatch = useDispatch()

    // We create a selector for each instance of the Dropdown component.
    const dataSelector = createDataSelector(isPreview)
    const {
      description,
      answer,
      options,
      label,
      indicator,
    } = useSelector(state => dataSelector(state, question_id))
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )

    const hasIndicator = sawsIndicator ? indicator : 'noIndicator'

    const sendData = data => {
      dispatch(
        isPreview
        ? capturePreviewFormData({
          question_id: question_id,
          answer: data,
        })
        : captureFormData({
          question_id: question_id,
          answer: data,
        })
      )
    }

    // This is required to position label appropriately in the outlined
    // Dropdown type, per MUI: https://material-ui.com/components/selects/#select
    const [labelWidth, setLabelWidth] = React.useState(0)
    const inputLabel = React.useRef(null)
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth)
    }, [])

    // Required labels for ADA
    const labelid = `${question_id}-label`
    const selectid = `${question_id}-select`

    // Dispatch change to Redux
    const handleChange = e => {
      if (cancelledOrCompletedInterview) return
      sendData(e.target.value)
    }

    // Create dropdown options
    return (
      <Can
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        roles={roles}
        no={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl variant='outlined'>
                <InputLabel ref={inputLabel} id={labelid}>
                  {decodeHtml(label)}
                </InputLabel>
                <CustomDropdown
                  disabled={true}
                  width={width}
                  labelId={labelid}
                  id={selectid}
                  value={answer}
                  labelWidth={labelWidth}
                >
                  <NoSelection
                    component='em'
                    disableRipple
                    disableTouchRipple
                    value={null}
                  >
                    None
                  </NoSelection>
                  {options
                    .filter(item =>
                      filter ? filter.includes(item.optionId) : true
                    )
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }) => (
                      <MenuItem
                        disableRipple
                        disableTouchRipple
                        value={optionId}
                        key={optionId}
                      >
                        {optionLabel}
                      </MenuItem>
                    ))}
                </CustomDropdown>
              </FormControl>
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={style} />
              )}
            </Container>
          )
        }}
        yes={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl variant='outlined'>
                <InputLabel ref={inputLabel} id={labelid}>
                  {decodeHtml(label)}
                </InputLabel>
                <CustomDropdown
                  disabled={cancelledOrCompletedInterview || isDisabled}
                  width={width}
                  labelId={labelid}
                  id={selectid}
                  value={answer}
                  onChange={handleChange}
                  labelWidth={labelWidth}
                >
                  <MenuItem
                    component={'em'}
                    disableRipple
                    disableTouchRipple
                    value={null}
                  >
                    None
                  </MenuItem>
                  {options
                    .filter(item =>
                      filter ? filter.includes(item.optionId) : true
                    )
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }) => (
                      <MenuItem
                        disableRipple
                        disableTouchRipple
                        value={optionId}
                        key={optionId}
                      >
                        {decodeHtml(optionLabel)}
                      </MenuItem>
                    ))}
                </CustomDropdown>
              </FormControl>
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={style} />
              )}
            </Container>
          )
        }}
      />
    )
  }
)

export default Dropdown
