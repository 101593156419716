import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import CheckboxGrid from 'components/general/CheckboxGrid'

import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDatepicker from 'components/table/InfiniteDatePicker'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { Typography } from '@material-ui/core'
import Indicator from 'components/general/Indicator'

const noOtherHouseHoldStyle = { marginLeft: '246px', marginBottom: '-29px' }

const HouseholdCompositionRow = props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText
        sub_question_id={'household_name'}
        width={'240px'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteDatepicker
        sub_question_id={'household_dob'}
        hideLabel={true}
        width={'240px'}
        ariaForButton='choose date'
        ariaForInput='date entry field'
        isPreview={isPreview}
        {...props}
      />
      <InfiniteText
        isDisabled={true}
        sub_question_id={'household_age'}
        width={'60px'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteDropdown
        hideOptionLabel
        width={'100px'}
        sub_question_id={'household_gender'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteDropdown
        hideOptionLabel
        sub_question_id={'household_relationship'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteDropdown
        hideOptionLabel
        width={'100px'}
        sub_question_id={'financially_responsible'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteDropdown
        hideOptionLabel
        width={'100px'}
        sub_question_id={'person_in_household'}
        isPreview={isPreview}
        {...props}
      />
    </>
  )
}

const noOtherHouseholdSelector = state =>
  state.interview.no_other_household.answer

const previewNoOtherHouseholdSelector = state =>
  state.preview.no_other_household.answer

const householdMembersTableSelector = state =>
  state.interview.household_members_table.answer

const previewHouseholdMembersTableSelector = state =>
  state.preview.household_members_table.answer

const householdMembersTableIndicatorSelector = state =>
  state.interview.household_members_table.indicator

const previewHouseholdMembersTableIndicatorSelector = state =>
  state.preview.household_members_table.indicator

const selectNoOtherHousehold = createSelector(
  noOtherHouseholdSelector,
  answer => (answer ? Array.isArray(answer) && answer.includes('NM') : false)
)

const selectPreviewNoOtherHousehold = createSelector(
  previewNoOtherHouseholdSelector,
  answer => (answer ? Array.isArray(answer) && answer.includes('NM') : false)
)

const selectHouseholdMembersTable = createSelector(
  householdMembersTableSelector,
  answer => answer && answer.length > 0
)

const selectPreviewHouseholdMembersTable = createSelector(
  previewHouseholdMembersTableSelector,
  answer => answer && answer.length > 0
)

const selectHouseholdMembersTableIndicator = createSelector(
  householdMembersTableIndicatorSelector,
  indicator => indicator
)

const selectPreviewHouseholdMembersTableIndicator = createSelector(
  previewHouseholdMembersTableIndicatorSelector,
  indicator => indicator
)

const householdMembersTableQuestion = state =>
  state.interview.household_members_table

const previewHouseholdMembersTableQuestion = state =>
  state.preview.household_members_table

const selectHouseholdMembersTableQuestion = createSelector(
  householdMembersTableQuestion,
  indicator => indicator
)

const selectPreviewHouseholdMembersTableQuestion = createSelector(
  previewHouseholdMembersTableQuestion,
  indicator => indicator
)

/*
{"impacts": {"rule": false, "report": false, "interface": true, "questionnaire": true}, "description": "Household Members Table", "question_id": "household_members_table", "spanishText": "Miembros del hogar", "sub_question_ids": {
  "household_age": {"label": "Age", "order": 3, "spanishText": "Edad", "questionType": "textbox"}, 
  "household_dob": {"label": "DOB", "order": 2, "spanishText": "Fecha de nacimiento (Fech. nac.)", "questionType": "textbox"}, 
  "household_name": {"label": "Name", "order": 1, "spanishText": "Nombre", "questionType": "textbox"}, 
  "household_gender": {"label": "Gender", "order": 4, "options": [{"order": 1, "optionId": "MA", "optionLabel": "Male", "spanishLabel": "Hombre"}, {"order": 2, "optionId": "FE", "optionLabel": "Female", "spanishLabel": "Mujer"}, {"order": 3, "optionId": "OT", "optionLabel": "Other", "spanishLabel": "Otro"}], "spanishText": "Género", "questionType": "dropdown_label"}, 
  "person_in_household": {"label": "Is this person in the household?", "order": 7, "options": [{"order": 1, "optionId": "YS", "optionLabel": "Yes", "spanishLabel": "Si"}, {"order": 2, "optionId": "NO", "optionLabel": "No", "spanishLabel": "No"}], "questionType": "dropdown_label"}, 
  "household_relationship": {"label": "Relationship", "order": 5, "options": [{"order": 1, "optionId": "AU", "optionLabel": "Aunt/ Uncle (up to 3 greats)", "spanishLabel": ""}, {"order": 2, "optionId": "CH", "optionLabel": "Child", "spanishLabel": ""}, {"order": 3, "optionId": "CL", "optionLabel": "Common Law", "spanishLabel": ""}, {"order": 4, "optionId": "CO", "optionLabel": "Cousin (1st or 1st once removed)", "spanishLabel": ""}, {"order": 5, "optionId": "DP", "optionLabel": "Registered Domestic Partner", "spanishLabel": ""}, {"order": 6, "optionId": "FR", "optionLabel": "Fictive Relative", "spanishLabel": ""}, {"order": 7, "optionId": "GC", "optionLabel": "Grandchild", "spanishLabel": ""}, {"order": 8, "optionId": "GP", "optionLabel": "Grandparent (up to 3 greats)", "spanishLabel": ""}, {"order": 9, "optionId": "NN", "optionLabel": "Niece/ Nephew", "spanishLabel": ""}, {"order": 10, "optionId": "OR", "optionLabel": "Other Relative", "spanishLabel": ""}, {"order": 11, "optionId": "PB", "optionLabel": "Parent (Biological/ Adoptive)", "spanishLabel": ""}, {"order": 12, "optionId": "PS", "optionLabel": "Parent (Step)", "spanishLabel": ""}, {"order": 13, "optionId": "SB", "optionLabel": "Sibling (full or half)", "spanishLabel": ""}, {"order": 14, "optionId": "SC", "optionLabel": "Stepchild", "spanishLabel": ""}, {"order": 15, "optionId": "SS", "optionLabel": "Spouse", "spanishLabel": ""}, {"order": 16, "optionId": "ST", "optionLabel": "Stepsibling", "spanishLabel": ""}, {"order": 17, "optionId": "UR", "optionLabel": "Unrelated", "spanishLabel": ""}], "questionType": "dropdown_label"}, 
  "financially_responsible": {"label": "Are you financially responsible for this person?", "order": 6, "options": [{"order": 1, "optionId": "YS", "optionLabel": "Yes", "spanishLabel": "Si"}, {"order": 2, "optionId": "NO", "optionLabel": "No", "spanishLabel": "No"}], "questionType": "dropdown_label"}}}
*/

const DemographicsHouseholdComposition = ({ isPreview }) => {
  const householdMembersTableQuestion = useSelector(isPreview ? selectPreviewHouseholdMembersTableQuestion : selectHouseholdMembersTableQuestion)
  const headers = [
    householdMembersTableQuestion.sub_question_ids.household_name.label,
    householdMembersTableQuestion.sub_question_ids.household_dob.label,
    householdMembersTableQuestion.sub_question_ids.household_age.label,
    householdMembersTableQuestion.sub_question_ids.household_gender.label,
    householdMembersTableQuestion.sub_question_ids.household_relationship.label,
    householdMembersTableQuestion.sub_question_ids.financially_responsible.label,
    householdMembersTableQuestion.sub_question_ids.person_in_household.label
  ]
  const noOtherHousehold = useSelector(isPreview ? selectPreviewNoOtherHousehold : selectNoOtherHousehold)
  const householdMembersTable = useSelector(isPreview ? selectPreviewHouseholdMembersTable : selectHouseholdMembersTable)
  const indicator = useSelector(isPreview ? selectPreviewHouseholdMembersTableIndicator : selectHouseholdMembersTableIndicator)
  return (
    <>
      <QuestionnaireHeader
        subdomain='Household Composition'
        domain='Demographics'
      />
      <form>
        <CheckboxGrid
          question_id='no_other_household'
          isDisabled={householdMembersTable}
          sawsIndicator={true}
          indicatorStyle={noOtherHouseHoldStyle}
          isPreview={isPreview}
        />
      </form>
      <Typography variant='h6'>
        Please list all members of the household, including children who may not
        live at your current residence.
      </Typography>
      <InfiniteTable
        rowComponent={<HouseholdCompositionRow isPreview={isPreview} />}
        headers={headers}
        question_id='household_members_table'
        isDisabled={noOtherHousehold}
        indicator={<Indicator indicator={indicator} />}
        isPreview={isPreview}
      />
    </>
  )
}

export default DemographicsHouseholdComposition
