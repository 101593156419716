const rules = {
  BRE: {
    static: [
      'BRE:view',
    ]
  },
  AD: {
    static: [
      'user:list',
      'user:create',
      'user:edit',
      'help:viewAdmin',
      'resourcesSideBar:view',
      'resourcesHeaderNav:view',
      'resources:edit',
      'reports:view',
      'reports:viewAdministrative',
      'reports:viewEducationalAttainment',
      'reports:viewDemographics',
      'reports:viewEmploymentHistory',
      'reports:viewChildCare',
      'reports:viewHealthAndSubstance',
      'reports:viewLegal',
      'reports:viewAppraisalAging',
      'reports:viewAppraisalReferral',
      'reports:viewAppraisalWorkload',
      'reports:viewAppraisalCancellation',
      'reports:viewDataExtract',
      'reports:viewAuditLog',
      'helpHeaderNav:view',
    ],
  },
  QA: {
    static: [
      'interview:view',
      'clientSearch:view',
      'clientList:view',
      'reports:view',
      'reports:viewEducationalAttainment',
      'reports:viewDemographics',
      'reports:viewEmploymentHistory',
      'reports:viewChildCare',
      'reports:viewHealthAndSubstance',
      'reports:viewLegal',
      'reports:viewAppraisalAging',
      'reports:viewAppraisalReferral',
      'reports:viewAppraisalWorkload',
      'reports:viewAppraisalCancellation',
      'reports:viewDataExtract',
      'resourcesHeaderNav:view',
      'resourcesSideBar:view',
      'interviewList:view',
      'helpHeaderNav:view',
    ],
  },
  EX: {
    static: [
      'interview:view',
      'clientSearch:view',
      'clientList:view',
      'reports:view',
      'reports:viewEducationalAttainment',
      'reports:viewDemographics',
      'reports:viewEmploymentHistory',
      'reports:viewChildCare',
      'reports:viewHealthAndSubstance',
      'reports:viewLegal',
      'reports:viewAppraisalAging',
      'reports:viewAppraisalReferral',
      'reports:viewAppraisalWorkload',
      'reports:viewAppraisalCancellation',
      'reports:viewDataExtract',
      'resourcesHeaderNav:view',
      'resourcesSideBar:view',
      'helpHeaderNav:view',
    ],
  },
  SP: {
    static: [
      'interview:view',
      'interview:edit',
      'interviewList:view',
      'interview:create',
      'interview:assign',
      'interview:share',
      'clientSearch:view',
      'client:create',
      'clientList:view',
      'reports:view',
      'reports:viewEducationalAttainment',
      'reports:viewDemographics',
      'reports:viewEmploymentHistory',
      'reports:viewChildCare',
      'reports:viewHealthAndSubstance',
      'reports:viewLegal',
      'reports:viewAppraisalAging',
      'reports:viewAppraisalReferral',
      'reports:viewAppraisalWorkload',
      'reports:viewAppraisalCancellation',
      'reports:viewDataExtract',
      'resourcesHeaderNav:view',
      'resourcesSideBar:view',
      'asr:create',
      'helpHeaderNav:view',
    ],
  },
  CW: {
    static: [
      'interview:view',
      'interview:edit',
      'interviewList:view',
      'interview:create',
      'interview:assign',
      'interview:share',
      'clientSearch:view',
      'client:create',
      'clientList:view',
      'reports:view',
      'reports:viewEducationalAttainment',
      'reports:viewDemographics',
      'reports:viewEmploymentHistory',
      'reports:viewChildCare',
      'reports:viewHealthAndSubstance',
      'reports:viewLegal',
      'reports:viewAppraisalAging',
      'reports:viewAppraisalReferral',
      'reports:viewAppraisalWorkload',
      'reports:viewAppraisalCancellation',
      'reports:viewDataExtract',
      'resourcesHeaderNav:view',
      'resourcesSideBar:view',
      'asr:create',
      'helpHeaderNav:view',
    ],
  },
}

export default rules
