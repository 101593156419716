import React from 'react'
import RecommendationsPage from 'components/recommendation/RecommendationsPage'

const RecommendationsException = React.memo(({ isPreview }) => {
  return (
    <RecommendationsPage
      domainCode='WT'
      domain='Recommendations'
      subdomain='WTW Exemption, Exception, and Program Waiver Evaluation'
      isPreview={isPreview}
    />
  )
})

export default RecommendationsException
