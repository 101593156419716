var userInitials = ''
var firstTime = true

// Open the O-Bot Chat window by injecting HTML elements into the page and then start it running.
export const openObot = initials => {
  userInitials = initials
  // Build the empty chatbot window frame.
  const parent = document.getElementById('chatbot-trigger-frame')
  var ocatBot = document.createElement('div')
  ocatBot.setAttribute('class', 'ocatBot')
  ocatBot.setAttribute('id', 'ocatBot')
  ocatBot.setAttribute('data-testid', 'ocatBot')
  var chatBotFrame = document.createElement('div')
  chatBotFrame.setAttribute('class', 'chatBotFrame')
  var navbar = document.createElement('div')
  navbar.setAttribute('class', 'navbar')
  var navTitle = document.createElement('div')
  navTitle.innerHTML = 'Chat with O-Bot'
  var closeButton = document.createElement('button')
  closeButton.setAttribute('aria-label', 'Close')
  closeButton.setAttribute('class', 'closeButton')
  closeButton.addEventListener('click', e => closeChat())
  closeButton.setAttribute('id', 'chatbot-close-button')
  closeButton.setAttribute('data-testid', 'chatbot-close-button')
  var svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svgImage.setAttribute('class', 'svgImage')
  svgImage.setAttribute('focusable', 'false')
  svgImage.setAttribute('viewbox', '0 0 24 24')
  svgImage.setAttribute('aria-hidden', 'true')

  var scrollable = document.createElement('div')
  scrollable.setAttribute('class', 'scrollable')
  var chatting = document.createElement('div')
  chatting.setAttribute('class', 'chatting')
  chatting.setAttribute('id', 'chatting')
  var listUL = document.createElement('ul')
  listUL.setAttribute('id', 'listUL')

  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttributeNS(
    null,
    'd',
    'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
  )
  svgImage.appendChild(path)
  navbar.appendChild(navTitle)
  closeButton.appendChild(svgImage)
  navbar.appendChild(closeButton)
  chatBotFrame.appendChild(navbar)
  chatting.appendChild(listUL)
  scrollable.appendChild(chatting)
  chatBotFrame.appendChild(scrollable)
  ocatBot.appendChild(chatBotFrame)
  parent.appendChild(ocatBot)

  // Start the Chatbot running
  startChatbot()

  // Inject this structure into the page for the O-Bot frame
  //    <div id="ocatBot" class="ocatBot">
  //        <div class="chatBotFrame">
  //            <div class="navbar">
  //                <div>Chat with O-Bot</div>
  //                <button class="closeButton" onclick="closeChat()">
  //                    <svg class="svgImage" focusable="false" viewbox="0 0 24 24" aria-hidden="true">
  //                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  //                    </svg>
  //                </button>
  //            </div>
  //            <div class="scrollable" id="myScrollable">
  //                <div id="chatting" class="chatting">
  //                    <ul id="listUL">
  //                    </ul>
  //                </div>
  //            </div>
  //        </div>
  //    </div>
}

// Start the chatbot by displaying the initial introduction.
function startChatbot() {
  firstTime = true
  processButtonRequest('1', 'Hello O-Bot, I need some help.')
  // Update the activity time every 30 seconds
  setInterval(refreshChatTime, 30000)
}

// Process the message text that the user has selected using a button click.
function processButtonRequest(key, input) {
  //console.log("key = " + key);
  if (input.innerText === '') {
    return
  }
  // Once a button has been selected change it's appearance.
  if (input.innerText !== undefined) {
    input.setAttribute('class', 'btn btnSelected')
  }
  // Create an entry for a user request
  var myLI = document.createElement('li')
  var chatEntryRight = document.createElement('div')
  var chatArea = document.createElement('div')
  var chatNameCircle = document.createElement('div')
  var chatMsgRight = document.createElement('div')
  chatEntryRight.setAttribute('class', 'chatEntryRight')
  chatNameCircle.setAttribute('class', 'chatNameCircleRight')
  chatNameCircle.innerText = userInitials
  chatMsgRight.setAttribute('class', 'chatMsgRight')
  if (input.innerText !== undefined) {
    chatMsgRight.innerText = input.innerText
  } else {
    chatMsgRight.innerText = input
  }
  chatArea.appendChild(chatMsgRight)
  chatEntryRight.appendChild(chatArea)
  chatEntryRight.appendChild(chatNameCircle)
  myLI.appendChild(chatEntryRight)
  var chatTimeRight = document.createElement('div')
  var chatTimeHidden = document.createElement('div')
  var chatTimeDisplay = document.createElement('div')
  chatTimeHidden.innerText = Date.now()
  chatTimeRight.setAttribute('class', 'chatTime chatTimeRight')
  chatTimeHidden.setAttribute('class', 'chatTimeHidden')
  chatTimeDisplay.setAttribute('class', 'chatTimeDisplay')
  chatTimeRight.appendChild(chatTimeDisplay)
  chatTimeRight.appendChild(chatTimeHidden)
  myLI.appendChild(chatTimeRight)
  document.getElementById('listUL').appendChild(myLI)
  refreshChatTime()
  var s = document.getElementById('chatting')
  s.scrollTop = s.scrollHeight
  setTimeout(function () {
    waitAndResponce(key)
  }, 300)
}

// Send a "response" back from the chatbox.
function sendTextMessage(textToSend) {
  var myLI = document.createElement('li')
  var chatEntryLeft = document.createElement('div')
  var chatArea = document.createElement('div')
  var chatNameCircle = document.createElement('div')
  var chatMsgLeft = document.createElement('div')
  chatEntryLeft.setAttribute('class', 'chatEntryLeft')
  chatNameCircle.setAttribute('class', 'chatNameCircleLeft')
  chatNameCircle.innerText = 'OCAT'
  chatMsgLeft.setAttribute('class', 'chatMsgLeft')
  chatMsgLeft.innerHTML = textToSend
  chatArea.appendChild(chatMsgLeft)
  chatEntryLeft.appendChild(chatNameCircle)
  chatEntryLeft.appendChild(chatArea)
  myLI.appendChild(chatEntryLeft)
  var chatTimeLeft = document.createElement('div')
  var chatTimeHidden = document.createElement('div')
  var chatTimeDisplay = document.createElement('div')
  chatTimeHidden.innerText = Date.now()
  chatTimeLeft.setAttribute('class', 'chatTime chatTimeLeft')
  chatTimeHidden.setAttribute('class', 'chatTimeHidden')
  chatTimeDisplay.setAttribute('class', 'chatTimeDisplay')
  chatTimeLeft.appendChild(chatTimeHidden)
  chatTimeLeft.appendChild(chatTimeDisplay)
  myLI.appendChild(chatTimeLeft)
  document.getElementById('listUL').appendChild(myLI)
  refreshChatTime()
  var s = document.getElementById('chatting')
  s.scrollTop = s.scrollHeight
}

// Append an additional "response" back from the chatbox.
function appendTextMessage(textToSend) {
  var lastChatMsgLeft = Array.from(
    document.querySelectorAll('.chatMsgLeft')
  ).pop()
  var chatMsgLeft = document.createElement('div')
  chatMsgLeft.setAttribute('class', 'chatMsgLeftAppend')
  chatMsgLeft.innerHTML = textToSend
  lastChatMsgLeft.parentElement.appendChild(chatMsgLeft)
  refreshChatTime()
  var s = document.getElementById('chatting')
  s.scrollTop = s.scrollHeight
}

// This function controls all of the possible navigation flows for the chatbot.
// The setTimeout delays are just to emulate conversational wait and can be reduced or removed as needed.
function waitAndResponce(inputText) {
  switch (inputText.toUpperCase().trim()) {
    // 1 - Main Menu
    case '1':
      if (firstTime === true) {
        setTimeout(() => {
          sendTextMessage('Hello')
        }, 200)
        setTimeout(() => {
          appendTextMessage(
            'Thank you for contacting the OCAT ChatBot.  Which of the following can I help you with?'
          )
        }, 400)
      } else {
        setTimeout(() => {
          sendTextMessage('Which of the following can I help you with?')
        }, 400)
      }
      setTimeout(() => {
        const btn_1_1 = createResponseButton('Access to OCAT', '2')
        const btn_1_2 = createResponseButton('Report a Problem', '7')
        const btn_1_3 = createResponseButton('Training Question', '12')
        appendButtonResponse([btn_1_1, btn_1_2, btn_1_3])
      }, 600)
      firstTime = false
      break
    // 2 - Access to OCAT
    case '2':
      setTimeout(() => {
        sendTextMessage('What type of issue are you having?')
      }, 400)
      setTimeout(() => {
        const btn_2_1 = createResponseButton('Forgotten Password', '3')
        const btn_2_2 = createResponseButton('Forgotten User Name', '4')
        const btn_2_3 = createResponseButton('OCAT Login Page', '5')
        const btn_2_4 = createResponseButton('Something Else', '9')
        appendButtonResponse([btn_2_1, btn_2_2, btn_2_3, btn_2_4])
      }, 400)
      break
    // 3 - Forgotten Password
    case '3':
      setTimeout(() => {
        sendTextMessage(
          "Log out of OCAT and navigate to '<a href='https://ocat.calsaws.net' rel='noopener noreferrer' target='_blank' title='Opens in a new window; external.'>ocat.calsaws.net <img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' alt='Opens in a new window; external.' class='external-link-icon'></a>'.  Enter your user name and click Log In.  Then you will see a button to reset your password.  This will send you an email and allow you to reset your password automatically."
        )
      }, 400)
      setTimeout(() => {
        const btn_3_1 = createResponseButton('This Resolves My Issue', '11B')
        const btn_3_2 = createResponseButton("I Don't Know My User Name", '4')
        const btn_3_3 = createResponseButton(
          "I Don't Know the OCAT Login Page",
          '5'
        )
        appendButtonResponse([btn_3_1, btn_3_2, btn_3_3])
      }, 400)
      break
    // 4 - Forgotten User Name
    case '4':
      setTimeout(() => {
        sendTextMessage(
          'Your User Name is your email address that you use to access your CalSAWS Case Management System.'
        )
      }, 400)
      setTimeout(() => {
        const btn_4_1 = createResponseButton('This Resolves My Issue', '11B')
        const btn_4_2 = createResponseButton(
          "I Don't Know My Email Address",
          '6'
        )
        const btn_4_3 = createResponseButton(
          "I Don't Know the OCAT Login Page",
          '5'
        )
        const btn_4_4 = createResponseButton(
          "I'd Like to Contact the Service Desk",
          '9'
        )
        appendButtonResponse([btn_4_1, btn_4_2, btn_4_3, btn_4_4])
      }, 400)
      break
    // 5 - OCAT Login page
    case '5':
      setTimeout(() => {
        sendTextMessage(
          "The OCAT Login Page is '<a href='https://ocat.calsaws.net' rel='noopener noreferrer' target='_blank' title='Opens in a new window; external.'>ocat.calsaws.net <img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' alt='Opens in a new window; external.' class='external-link-icon'></a>'.<br><br>Is there anything else I can help you with?"
        )
      }, 400)
      setTimeout(() => {
        const btn_5_1 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        const btn_5_2 = createResponseButton('No', '11A')
        appendButtonResponse([btn_5_1, btn_5_2])
      }, 400)
      break
    // 6 - Don't know email
    case '6':
      setTimeout(() => {
        sendTextMessage(
          'Contact your Supervisor for information on your email address to access OCAT.<br><br>Is there anything else I can help you with?'
        )
      }, 400)
      setTimeout(() => {
        const btn_6_1 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        const btn_6_2 = createResponseButton('No', '11A')
        appendButtonResponse([btn_6_1, btn_6_2])
      }, 400)
      break
    // 7 - Report a problem
    case '7':
      setTimeout(() => {
        sendTextMessage('What kind of problem are you experiencing?')
      }, 400)
      setTimeout(() => {
        const btn_7_1 = createResponseButton(
          'Cannot find an interview from CalSAWS',
          '8'
        )
        const btn_7_2 = createResponseButton('Something Else', '9')
        appendButtonResponse([btn_7_1, btn_7_2])
      }, 400)
      break
    // 8 - Cannot find interface data
    case '8':
      setTimeout(() => {
        sendTextMessage(
          'Did you confirm that the data was successfully sent from your source system, within the last 30 days?'
        )
      }, 400)
      setTimeout(() => {
        const btn_8_1 = createResponseButton(
          "No - I haven't confirmed this",
          '10'
        )
        const btn_8_2 = createResponseButton(
          'Yes - I am still unable to find the interview',
          '9'
        )
        appendButtonResponse([btn_8_1, btn_8_2])
      }, 400)
      break
    // 9 - Something else / Service desk
    case '9':
      setTimeout(() => {
        sendTextMessage(
          "OK, you can enter a Ticket with our Service Desk by emailing <a href='mailto:ocat.support@calsaws.org' rel='noopener noreferrer' target='_blank'>ocat.support@calsaws.org</a>.<br><br>If you need immediate assistance, call <strong>1-833-719-0559</strong><br><br>Is there anything else I can help you with?"
        )
      }, 400)
      setTimeout(() => {
        const btn_9_1 = createResponseButton('No', '11A')
        const btn_9_2 = createResponseButton('Yes', '1')
        appendButtonResponse([btn_9_1, btn_9_2])
      }, 400)
      break
    case '9B':
      setTimeout(() => {
        sendTextMessage(
          "OK, you can enter a Ticket with our Service Desk by emailing <a href='mailto:ocat.support@calsaws.org' rel='noopener noreferrer' target='_blank'>ocat.support@calsaws.org</a>.<br><br>If you need immediate assistance, call <strong>1-833-719-0559</strong><br><br>Is there anything else I can help you with?"
        )
      }, 400)
      setTimeout(() => {
        const btn_9B_1 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        const btn_9B_2 = createResponseButton('No - I am good for now', '11A')
        appendButtonResponse([btn_9B_1, btn_9B_2])
      }, 400)
      break
    // 10 - I haven't confirmed this
    case '10':
      setTimeout(() => {
        sendTextMessage(
          "OK, I recommend you navigate back to the CalSAWS system, find this Client and Confirm that there is an OCAT Record.  If there is an OCAT Record, you can enter a helpdesk ticket with the OCAT Team by emailing <a href='mailto:ocat.support@calsaws.org' rel='noopener noreferrer' target='_blank'>ocat.support@calsaws.org</a><br><br>Is there anything else I can help you with?"
        )
      }, 400)
      setTimeout(() => {
        const btn_10_1 = createResponseButton('No - I am good for now', '11B')
        const btn_10_2 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        appendButtonResponse([btn_10_1, btn_10_2])
      }, 400)
      break
    // 11A - Resolved Issue
    case '11A':
      setTimeout(() => {
        sendTextMessage('Thank you for using OCAT Chatbot.  Have a nice day!')
      }, 400)
      break
    // 11B - Resolved Issue
    case '11B':
      setTimeout(() => {
        sendTextMessage(
          'Thank you for contacting the OCAT Chatbot.  Please come back if you need any further assistance.'
        )
      }, 400)
      break
    // 12 - Training question
    case '12':
      setTimeout(() => {
        sendTextMessage('What do you have a question about?')
      }, 400)
      setTimeout(() => {
        const btn_12_1 = createResponseButton('Finding a Completed ASR', '14')
        const btn_12_2 = createResponseButton(
          'Finding a CalSAWS Interview',
          '22'
        )
        const btn_12_3 = createResponseButton('Accessing Reports', '13')
        const btn_12_4 = createResponseButton('Administrator Question', '25')
        const btn_12_5 = createResponseButton('Something Else', '9')
        appendButtonResponse([btn_12_1, btn_12_2, btn_12_3, btn_12_4, btn_12_5])
      }, 400)
      break
    // 13 - Accessing reports
    case '13':
      setTimeout(() => {
        sendTextMessage(
          'To access OCAT Reports, click on Reports on the Navigation Bar.<br><br>Is there anything else I can help you with?'
        )
      }, 400)
      setTimeout(() => {
        const btn_13_1 = createResponseButton(
          'Yes - there is something else',
          '12'
        )
        const btn_13_2 = createResponseButton('No', '11A')
        appendButtonResponse([btn_13_1, btn_13_2])
      }, 400)
      break
    // 14 - Finding a completed ASR
    case '14':
      setTimeout(() => {
        sendTextMessage(
          'The fastest way to find a Completed ASR is to navigate to the Client Search Page at the top of the Navigation Bar.<br><br>Navigate to Client Search, and use the search criteria to find the Client.  If you know the CIN, enter the CIN to find the client quickly.<br><br>Did you find the Client?'
        )
      }, 400)
      setTimeout(() => {
        const btn_14_1 = createResponseButton('Yes - I found the Client', '15')
        const btn_14_2 = createResponseButton(
          "No - I still can't find it",
          '16'
        )
        appendButtonResponse([btn_14_1, btn_14_2])
      }, 400)
      break
    // 15 - Yes I found the Client
    case '15':
      setTimeout(() => {
        sendTextMessage(
          "Once you find the Client, click the Client's name to navigate to their Interview List page.  From here, you should see all interviews previously completed.<br><br>If the interview was completed, you can click on the print icon in the ASR column.<br><br>Did you find the Completed ASR?"
        )
      }, 400)
      setTimeout(() => {
        const btn_15_1 = createResponseButton(
          "No - I still can't find it",
          '18'
        )
        const btn_15_2 = createResponseButton(
          'Yes - I found the completed ASR',
          '17'
        )
        appendButtonResponse([btn_15_1, btn_15_2])
      }, 400)
      break
    // 16 - No, I still can't find it
    case '16':
      setTimeout(() => {
        sendTextMessage(
          "If you cannot find the Client, It's possible that they don't exist in OCAT.<br><br>Is there anything else I can help you with?"
        )
      }, 400)
      setTimeout(() => {
        const btn_16_1 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        const btn_16_2 = createResponseButton('No', '11A')
        appendButtonResponse([btn_16_1, btn_16_2])
      }, 400)
      break
    // 17 - Yes, I found the completed ASR
    case '17':
      setTimeout(() => {
        sendTextMessage('OK - Is there anything else I can help you with?')
      }, 400)
      setTimeout(() => {
        const btn_17_1 = createResponseButton(
          'No thanks, I am good for now',
          '11A'
        )
        const btn_17_2 = createResponseButton('Yes, I still need help', '1')
        appendButtonResponse([btn_17_1, btn_17_2])
      }, 400)
      break
    // 18 - No, I still can't find it
    case '18':
      setTimeout(() => {
        sendTextMessage(
          'OK, do you see the interview, but do not see the icon?'
        )
      }, 400)
      setTimeout(() => {
        const btn_18_1 = createResponseButton(
          "Yes, I can see the Interview, but I can't open the PDF",
          '20'
        )
        const btn_18_2 = createResponseButton(
          "No - I can't see the Interview at all",
          '19'
        )
        appendButtonResponse([btn_18_1, btn_18_2])
      }, 400)
      break
    // 19 - No I can\t see the interview at all
    case '19':
      setTimeout(() => {
        sendTextMessage(
          'This might mean there was no interview created for this Client in your county.<br><br>Did you confirm that an interview was initiated from CalSAWS?'
        )
      }, 400)
      setTimeout(() => {
        const btn_19_1 = createResponseButton('No', '10')
        const btn_19_2 = createResponseButton('Yes', '9B')
        appendButtonResponse([btn_19_1, btn_19_2])
      }, 400)
      break
    // 20 - Yes - I can see the interview, but can't open the PDF
    case '20':
      setTimeout(() => {
        sendTextMessage(
          'Is the Interview in your County or has it been shared with your County?'
        )
      }, 400)
      setTimeout(() => {
        const btn_20_1 = createResponseButton('Yes', '9B')
        const btn_20_2 = createResponseButton('No', '21')
        appendButtonResponse([btn_20_1, btn_20_2])
      }, 400)
      break
    // 21 - No
    case '21':
      setTimeout(() => {
        sendTextMessage(
          'If the Interview is not in your County or has not been shared with your County, you will not be able to view it.<br><br>Refer to the OCAT User Manual to learn about shared interviews.<br><br>Is there anything else I can help you with?'
        )
      }, 400)
      setTimeout(() => {
        const btn_21_1 = createResponseButton(
          'Yes - there is something else',
          '1'
        )
        const btn_21_2 = createResponseButton('No', '11A')
        appendButtonResponse([btn_21_1, btn_21_2])
      }, 400)
      break
    // 22 Finding a CalSAWS Interview
    case '22':
      setTimeout(() => {
        sendTextMessage(
          'Navigate to the Client List Page.  From here, if the Client has an initiated Interview, it should be in the status of "New".  If you can\'t find the Client, use the filters at the top of the page to show all Assignees, and all Statuses.<br><br>You can also use the filters inside the table to match Name, DOB, or SSN.<br><br>Once you find the Client, click their name and that will take you to the Interview List Page where you can start the Interview.<br><br>Did this help?'
        )
      }, 400)
      setTimeout(() => {
        const btn_22_1 = createResponseButton(
          'Yes - I found my interview',
          '23'
        )
        const btn_22_2 = createResponseButton(
          "No - I still can't find it",
          '24'
        )
        appendButtonResponse([btn_22_1, btn_22_2])
      }, 400)
      break
    // 23 - Exit or something else
    case '23':
      setTimeout(() => {
        sendTextMessage('OK - Is there anything else I can help with today?')
      }, 400)
      setTimeout(() => {
        const btn_23_1 = createResponseButton(
          'No thanks, I am good for now',
          '11A'
        )
        const btn_23_2 = createResponseButton('Yes, I still need help', '1')
        appendButtonResponse([btn_23_1, btn_23_2])
      }, 400)
      break
    // No, I still can't find it
    case '24':
      setTimeout(() => {
        sendTextMessage(
          'If you can\'t find your client in the Client List Page, navigate to the Client Search page using the Navigation Bar at the top of the screen.<br><br>From this page, you can search for clients.<br><br>If you find the Client this way, when you get to the Interview List Page, make sure they already have a "New" interview from your county.<br><br>Did this help?'
        )
      }, 400)
      setTimeout(() => {
        const btn_24_1 = createResponseButton(
          "No - I still can't find it",
          '10'
        )
        const btn_24_2 = createResponseButton(
          'Yes - I found my interview',
          '23'
        )
        appendButtonResponse([btn_24_1, btn_24_2])
      }, 400)
      break
    // Administrator questions
    case '25':
      setTimeout(() => {
        sendTextMessage('Select from one of the Administrator topics:')
      }, 400)
      setTimeout(() => {
        const btn_25_1 = createResponseButton('Create New User', '26')
        const btn_25_2 = createResponseButton('Manage User', '27')
        const btn_25_3 = createResponseButton('Manage/Edit Entities', '28')
        const btn_25_4 = createResponseButton('Community Resources', '29')
        const btn_25_5 = createResponseButton('Something Else', '1')
        appendButtonResponse([btn_25_1, btn_25_2, btn_25_3, btn_25_4, btn_25_5])
      }, 400)
      break
    // 26 - Create new user
    case '26':
      setTimeout(() => {
        sendTextMessage(
          'To create a new user, click on Admin on the Navigation Bar then click Create New User.<br><br>For step by step instructions, refer to the OCAT Administrator User Manual.<br><br>Did this resolve your issue?'
        )
      }, 400)
      setTimeout(() => {
        const btn_26_1 = createResponseButton('Yes', '11A')
        const btn_26_2 = createResponseButton('No', '9')
        appendButtonResponse([btn_26_1, btn_26_2])
      }, 400)
      break
    // 27 - Manage User
    case '27':
      setTimeout(() => {
        sendTextMessage(
          'To manage or edit a user, click on Admin on the Navigation Bar then click  Manage/Edit User.<br><br>For step by step instructions, refer to the OCAT Administrator User Manual.<br><br>Did this resolve your issue?'
        )
      }, 400)
      setTimeout(() => {
        const btn_27_1 = createResponseButton('Yes', '11A')
        const btn_27_2 = createResponseButton('No', '9')
        appendButtonResponse([btn_27_1, btn_27_2])
      }, 400)
      break
    // 28 - Manage / Edit entities
    case '28':
      setTimeout(() => {
        sendTextMessage(
          'To create or edit an Entity, click on Admin on the Navigation Bar then click Manage/Edit Entities.<br><br>For step by step instructions, refer to the OCAT Administrator User Manual.<br><br>Did this resolve your issue?'
        )
      }, 400)
      setTimeout(() => {
        const btn_28_1 = createResponseButton('Yes', '11A')
        const btn_28_2 = createResponseButton('No', '9')
        appendButtonResponse([btn_28_1, btn_28_2])
      }, 400)
      break
    // Community resources
    case '29':
      setTimeout(() => {
        sendTextMessage(
          'To create or edit Community Resources, click on Resources on the Navigation Bar then select a Resource from the left navigation.<br><br>For step by step instructions, refer to the OCAT Administrator User Manual.<br><br>Did this resolve your issue?'
        )
      }, 400)
      setTimeout(() => {
        const btn_29_1 = createResponseButton('Yes', '11A')
        const btn_29_2 = createResponseButton('No', '9')
        appendButtonResponse([btn_29_1, btn_29_2])
      }, 400)
      break
    default:
      setTimeout(() => {
        sendTextMessage("I didn't understand that last request.")
      }, 300)
      break
  }
}

// Close the Chat window and remove the elements from the DOM.
export const closeChat = () => {
  document.getElementById('ocatBot').remove()
}

// Refresh all of the activity message times that are displayed on the screen.
function refreshChatTime() {
  var now = Date.now()
  const chatTimes = document.getElementsByClassName('chatTime')
  for (let i = 0; i < chatTimes.length; i++) {
    var childTimeHidden = chatTimes.item(i).querySelector('.chatTimeHidden')
    var diff = now - childTimeHidden.innerHTML
    var minutesAgo = Math.floor((diff / 60000) % 60)
    var timeText = ''
    //minutesAgo = 360;
    if (minutesAgo < 1) {
      timeText = 'Just now'
    } else if (minutesAgo >= 1 && minutesAgo < 2) {
      timeText = 'A minute ago'
    } else if (minutesAgo >= 2 && minutesAgo < 60) {
      timeText = minutesAgo + ' minutes ago'
    } else if (minutesAgo >= 60 && minutesAgo < 120) {
      timeText = 'An hour ago'
    } else {
      timeText = Math.floor((minutesAgo / 60) % 60) + ' hours ago'
    }
    var childTimeDisplay = chatTimes.item(i).querySelector('.chatTimeDisplay')
    childTimeDisplay.innerText = timeText
    //console.log("now = " + now + "   childTimeHidden.innerHTML = " + childTimeHidden.innerHTML + " = " + (now - childTimeHidden.innerHTML) + "    timeText = " + timeText);
  }
}

// Create a response button
const createResponseButton = (title, key, text) => {
  const btn = document.createElement('button')
  btn.addEventListener('click', e => {
    processButtonRequest(key, btn)
  })
  btn.setAttribute('title', title)
  btn.setAttribute('class', 'btn')
  btn.innerHTML = text ? text : title
  return btn
}

// Append the new buttons to the page
const appendButtonResponse = buttons => {
  var lastChatMsgLeft = Array.from(
    document.querySelectorAll('.chatMsgLeft')
  ).pop()
  var chatMsgLeft = document.createElement('div')
  chatMsgLeft.setAttribute('class', 'chatMsgLeftAppend')
  Array.from(buttons).forEach(button => {
    const breakElement = document.createElement('br')
    chatMsgLeft.appendChild(button)
    chatMsgLeft.appendChild(breakElement)
  })
  lastChatMsgLeft.parentElement.appendChild(chatMsgLeft)
  refreshChatTime()
  var s = document.getElementById('chatting')
  s.scrollTop = s.scrollHeight
}
