import EffectCollector from 'side-effects/effectCollector'
import store from 'app/store'

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()
  // Flag to check if we need to add a side effect.
  let hasConditionalAnswerChanged = false
  const stateProp = isPreview ? 'preview' : 'interview'
  if (question_id === 'pregnancy_table' && answer && Array.isArray(answer)) {
    // Create a list of household members that could potentially be pregnant.
    // Key is uuid and value is the display name of the person.
    const householdMembersTableAnswers =
      store.getState()[stateProp].household_members_table?.answer || []

    const householdMembers = {}
    householdMembersTableAnswers.forEach(member => {
      householdMembers[member.id] = {
        name: member.household_name,
        relationship: member.household_relationship,
      }
    })

    // Filter the answer and check for side-effects.
    const filteredAnswer = answer.map(row => {
      // Copy the row into a mutable object.
      const rowCopy = { ...row }

      // Check for side effects in this row.
      // If the the household member checkbox was previously checked.
      if (rowCopy.household_mem) {
        // If household member checkbox is unselected.
        if (rowCopy.household_mem.length === 0) {
          hasConditionalAnswerChanged = true
          delete rowCopy.household_mem
          delete rowCopy.household_mem_name
          delete rowCopy.household_mem_rel

          // If household member is selected but the name does not match
          // a household member.
        } else if (
          rowCopy.household_mem.includes('HM') &&
          !householdMembers[rowCopy.id]
        ) {
          hasConditionalAnswerChanged = true
          delete rowCopy.household_mem_name
          delete rowCopy.household_mem_rel

          // If household member is selected and the name matches a household
          // member.
        } else if (
          rowCopy.household_mem.includes('HM') &&
          householdMembers[rowCopy.id]
        ) {
          hasConditionalAnswerChanged = true
          rowCopy.household_mem_name = householdMembers[rowCopy.id].name
          rowCopy.household_mem_rel = householdMembers[rowCopy.id].relationship
        }
      }
      return rowCopy
    })

    if (hasConditionalAnswerChanged) {
      effectCollector.add('pregnancy_table', filteredAnswer)
    }
  }
  return effectCollector.effects
}

export default effects;