import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'

// We want to consistently convert the UI's view of "empty"
// to the database's view of "empty". We have to perform this
// conversion because our front-end components (via MUI) require
// a specific empty state. For example, a Dropdown component
// is considered empty when it's value is an empty string ("").
// in checkForEmpty, we convert each "empty" value to null
const checkForEmpty = value => {
  // If it's already null, return it without any additional checks
  if (value === null) {
    return value
  }
  // If it's undefined, it's always empty
  if (typeof value === 'undefined') {
    return null
  }

  // If it's a string, check for empty string
  if (isString(value) && value === '') {
    return null
  }
  // If it's an array, check for an empty array (length = 0)
  if (isArray(value) && value.length === 0) {
    return null
  }
  // If it's an object, use lodash's isEmpty function
  // We can't use this for every value type because it would return
  // true when we don't want it to.
  // See: https://lodash.com/docs/4.17.15#isEmpty
  if (isPlainObject(value) && isEmpty(value)) {
    return null
  }

  // Otherwise, not empty! Proceed with current value
  return value
}

export default checkForEmpty
