import gql from 'graphql-tag'
const mutations = {
  UPDATE_ASSIGNEE: gql`
    mutation UpdateAssignee(
      $interviewID: Int!
      $userID: Int!
      $assigneeID: Int!
    ) {
      updateAssignee(
        interviewID: $interviewID
        userID: $userID
        assigneeID: $assigneeID
      ) {
        id
      }
    }
  `,
  CREATE_QUESTIONNAIRE_PDF: gql`
    mutation(
      $interviewId: Int!
      $domains: [String!]!
      $includeNotes: Boolean!
    ) {
      generateQuestionnairePdf(
        interviewId: $interviewId
        domains: $domains
        includeNotes: $includeNotes
      )
    }
  `,
}

export default mutations;