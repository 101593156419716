import { removeActionPlan } from 'ducks/recommendationsSlice'
import { openDialog } from 'ducks/dialogSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { getLastSavedDate } from 'thunks/getLastSavedDate'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'

const DELETE_ACTION_PLAN = gql`
  mutation($id: Int!, $interviewID: Int!) {
    deleteActionPlan(id: $id, interviewID: $interviewID) {
      deletedActionPlanId
    }
  }
`

export const deleteActionPlan = actionPlan => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingTimestamp(true))
      const { currentInterview: interviewID } = getState().client

      await client.mutate({
        mutation: DELETE_ACTION_PLAN,
        variables: {
          id: actionPlan.id,
          interviewID,
        },
      })
      dispatch(removeActionPlan(actionPlan))
      dispatch(getLastSavedDate())
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
