import { createSlice } from '@reduxjs/toolkit'
import { storeInterviewData, clearInterviewData } from 'ducks/interviewSlice'
import { resetUser, setUser } from 'ducks/userSlice'
import { setResourceTypes } from 'ducks/resourceSlice'

// This slice maintains a view of all of the app's
// 'readiness' values -- i.e. if something is still loading.
// This allows us to set all of our flags in one place so that
// we know where to find them. We also don't need to load full
// data sets to evaluate if they are loaded or not, which would
// be expensive.

const initialState = { interview: false, user: false, resourcesType: false }

const readySlice = createSlice({
  name: 'ready',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setResourceTypes, (state, action) => {
        state.resourcesType = true
      })
      .addCase(setUser, (state, action) => {
        state.user = true
      })
      .addCase(storeInterviewData, (state, action) => {
        state.interview = true
      })
      .addCase(clearInterviewData, (state, action) => {
        state.interview = false
      })
      .addCase(resetUser, (state, action) => initialState)
  }
})

export default readySlice.reducer
