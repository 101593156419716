import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'received_calworks_previous' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('county_from', null)
    effectCollector.add('welfare_to_work_plan', null)
  }

  if (
    question_id === 'receiving_ssi' &&
    (answer?.toUpperCase() === 'YS' || !answer)
  ) {
    effectCollector.add('ever_applied_ssi', null)
  }

  if (
    question_id === 'payments_debts_table' &&
    answer &&
    Array.isArray(answer)
  ) {
    const filteredAnswer = answer.map(row => {
      const rowCopy = { ...row }
      if (rowCopy.payment_debt_type !== 'OT') {
        rowCopy.specify_finances = null
      }
      return rowCopy
    })
    effectCollector.add('payments_debts_table', filteredAnswer)
  }
  return effectCollector.effects
}

export default effects;