import NumberFormat from 'react-number-format'
import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { createSelector } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import ControlDescription from 'components/general/ControlDescription'
import InputAdornment from '@material-ui/core/InputAdornment'
import Indicator from 'components/general/Indicator'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { useMemo } from 'react'

const CustomTextField = styled(TextField)`
  && {
    width: ${p => p.width};
    margin-top: ${p => (p.label ? '20px' : '3px')};
    > input: {
      color: white;
    }
    margin-right: 20px;
  }
`

const Container = styled.div`
  padding-left: ${p =>
    (p.subquestion && '30px') || (p.subSubquestion && '90px')};
`

const StyledInputAdornment = styled(InputAdornment)`
  && {
    padding-bottom: 7px;
  }
`
const StyledSSNAdornment = styled(InputAdornment)`
  && {
    min-width: 250px;
    padding-bottom: 7px;
  }
`
// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const answerSelector = (state, question_id) => state.interview[question_id]
const previewAnswerSelector = (state, question_id) => state.preview[question_id]
const validationSelector = (state, question_id) =>
  state.validationErrors[question_id]
const createDataSelector = isPreview => {
  return createSelector(
    isPreview ? previewAnswerSelector : answerSelector,
    answer => {
      return {
        description: answer.description,
        label: answer.label,
        // Don't rely on the answer, convert to appropriate
        // 'falsy' value for this input (empty string)
        answer: answer.answer ? answer.answer : '',
        indicator: answer.indicator,
      }
    }
  )
}

const createValidationSelector = () => {
  return createSelector(validationSelector, validation => {
    return {
      hasValidationError: validation ? true : false,
      validationError: validation ? validation : null,
    }
  })
}

// Helpers

// Uses the third party library 'react-number-format' for additional custom formatting
// https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries

// Generates the basic settings for formatting validation
// Accepts additionalSettings from the more specific helpers to handle the various specific formatting/restriction requirements
// for each of the different types of input
const baseInputFactory = (props, additionalSettings) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      {...additionalSettings}
    />
  )
}

const currencyInput = props => {
  const additionalSettings = {
    thousandSeparator: true,
    isNumericString: true,
  }
  return baseInputFactory(props, additionalSettings)
}

const numberInput = props => {
  const additionalSettings = {
    thousandSeparator: true,
    isNumericString: true,
  }
  return baseInputFactory(props, additionalSettings)
}

const phoneInput = props => {
  const additionalSettings = {
    format: '(###) ###-####',
    allowEmptyFormatting: true,
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

const ssnInput = props => {
  const additionalSettings = {
    format: '###-##-####',
    allowEmptyFormatting: true,
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

const zipInput = props => {
  const additionalSettings = {
    format: '#####',
    isNumericString: true,
  }
  return baseInputFactory(props, additionalSettings)
}
const shortDateInput = props => {
  const additionalSettings = {
    format: '##/####',
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}
const dateInput = props => {
  const additionalSettings = {
    format: '##/##/####',
    mask: '_',
  }
  return baseInputFactory(props, additionalSettings)
}

// Determines what type of Input is required, and selects the appropriate inputComponent (from react-number-format) to supply to the material-ui input

const checkForDate = (type, input) => {
  if (type === 'date' && input) {
    if (input.length >= 4) {
      return input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4)
    } else if (input.length >= 2) {
      return input.slice(0, 2) + '/' + input.slice(2)
    }
  } else if (type === 'short date' && input && input.length >= 2) {
    return input.slice(0, 2) + '/' + input.slice(2)
  } else {
    return input
  }
}
const unformatDate = date => {
  return date.replace('/', '')
}

const Text = React.memo(
  ({
    question_id,
    type,
    width = '250px',
    isDisabled,
    variant,
    multiline,
    rows,
    subquestion,
    subSubquestion,
    defaultValue = '',
    disableDescriptionLabel = false,
    className,
    passedRef,
    sawsIndicator,
    indicatorStyle,
    shouldDecode = false,
    isPreview,
    shouldCheckCharacters = true,
  }) => {
    const dispatch = useDispatch()

    const cancelledOrCompletedInterview = useSelector(
      isPreview
        ? previewCancelledOrCompletedSelector
        : cancelledOrCompletedSelector
    )

    let inputVariant
    if (variant) {
      inputVariant = variant
    } else if (type === 'currency') {
      inputVariant = 'standard'
    } else {
      inputVariant = 'outlined'
    }
    // Label for ADA
    const inputId = `${question_id}-input`

    // We create a selector for each instance of the Text component.
    // Fetch data from redux store using selector
    const dataSelector = createDataSelector(isPreview)
    const validationSelector = createValidationSelector()
    const { roles } = useSelector(state => state.user)
    // validationError represents error returned from backend validation
    const { hasValidationError, validationError } = useSelector(state =>
      validationSelector(state, question_id)
    )
    const { answer, description, label, indicator } = useSelector(state => {
      return dataSelector(state, question_id)
    })

    const sendData = useCallback(
      data => {
        dispatch(
          isPreview
            ? capturePreviewFormData({
                question_id: question_id,
                answer: data,
              })
            : captureFormData({
                question_id: question_id,
                answer: data,
                fieldName: label,
                shouldCheckCharacters,
              })
        )
      },
      [dispatch, isPreview, question_id, label, shouldCheckCharacters]
    )
    // We don't want to read/write from store on each keystroke,
    // so instead we debounce the saving of data to redux to once
    // every 500ms. Local state is used for display to the user,
    // so saving still appears instantaneous.
    // See: https://reactjs.org/docs/hooks-reference.html#usecallback

    const delayedSave = useMemo(
      () => debounce(value => sendData(value), 300),
      [sendData]
    )

    const hasIndicator = sawsIndicator ? indicator : 'noIndicator'

    const filteredAnswer =
      type === 'date' || type === 'short date' ? unformatDate(answer) : answer

    // Local data for storing updates while waiting for debounce
    const [localData, setLocalData] = useState(filteredAnswer)

    // useEffect ensures that local state is overwritten whenever
    // Redux changes, and only re-renders when redux changes.

    useEffect(() => {
      if (filteredAnswer) {
        setLocalData(filteredAnswer)
      } else {
        setLocalData(defaultValue)
      }
    }, [filteredAnswer, defaultValue])

    const handleChange = event => {
      if (cancelledOrCompletedInterview) return
      setLocalData(event.target.value)
      const screenedAnswer = checkForDate(type, event.target.value)
      delayedSave(screenedAnswer)
    }

    // control code for secrecy masking
    const [maskShown, setMaskShown] = useState(true)
    const fieldsNeedMask = ['ssn']

    const showTheMask = () =>
      fieldsNeedMask.includes(type) && setMaskShown(true)

    const hideTheMask = () =>
      fieldsNeedMask.includes(type) && setMaskShown(false)

    // secrecy masks
    const maskSSNValue = ssnData => {
      if (!ssnData) return ''
      const ssnLength = ssnData && ssnData.length
      if (ssnLength) {
        const result = ssnData.replace(/^\d{1,5}/, x => x.replace(/./g, '*'))
        return ssnLength > 3
          ? `${result.substr(0, 3)}-${result.substr(3, 2)}-${result.substr(
              5,
              4
            )}`
          : result
      }
    }
    const iniMaskedSSN = maskSSNValue(localData)
    const ssnSecrecyMask = maskShown ? (
      <StyledSSNAdornment position='start'>
        {iniMaskedSSN?.length > 0 ? iniMaskedSSN : '___-__-____'}
      </StyledSSNAdornment>
    ) : null

    const calculateInputProps = type => {
      switch (type) {
        case 'currency':
          return {
            inputComponent: currencyInput,
            startAdornment: (
              <StyledInputAdornment position='start'>$</StyledInputAdornment>
            ),
          }
        case 'phone':
          return { inputComponent: phoneInput }
        case 'ssn':
          return {
            inputComponent: ssnInput,
            startAdornment: ssnSecrecyMask,
          }
        case 'number':
          return { inputComponent: numberInput }
        case 'zip':
          return { inputComponent: zipInput }
        case 'short date':
          return { inputComponent: shortDateInput }
        case 'date':
          return { inputComponent: dateInput }
        case 'text':
        default:
          return {}
      }
    }

    const inputProps = calculateInputProps(type)
    const inputLabelProps = type === 'phone' ? { shrink: true } : {}
    const canDecode =
      (type === 'text' || type === undefined) && shouldDecode && localData
    const displayLabel = !disableDescriptionLabel && decodeHtml(label)
    return (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <Container
              className={className}
              subquestion={subquestion ? 1 : 0}
              subSubquestion={subSubquestion ? 1 : 0}
            >
              {description && !disableDescriptionLabel && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomTextField
                inputProps={{
                  'aria-label': `Text field ${displayLabel} ${inputId}`,
                }}
                type='text'
                disabled={true}
                multiline={multiline}
                id={inputId}
                width={width}
                label={displayLabel || undefined}
                variant={inputVariant}
                value={canDecode ? decodeHtml(localData) : localData}
                minRows={rows}
                error={hasValidationError}
                helperText={validationError}
                InputProps={inputProps}
                ref={passedRef}
                onBlur={showTheMask}
                onClick={hideTheMask}
                autoComplete='off'
              />
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={indicatorStyle} />
              )}
            </Container>
          )
        }}
        yes={() => {
          return (
            <Container
              className={className}
              subquestion={subquestion ? 1 : 0}
              subSubquestion={subSubquestion ? 1 : 0}
            >
              {description && !disableDescriptionLabel && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomTextField
                inputProps={{
                  'aria-label': `Text field ${displayLabel} ${inputId}`,
                }}
                InputLabelProps={inputLabelProps}
                type='text'
                disabled={cancelledOrCompletedInterview || isDisabled}
                multiline={multiline}
                id={inputId}
                width={width}
                label={displayLabel || undefined}
                variant={inputVariant}
                value={canDecode ? decodeHtml(localData) : localData}
                minRows={rows}
                onChange={handleChange}
                error={hasValidationError}
                helperText={validationError}
                InputProps={inputProps}
                ref={passedRef}
                onBlur={showTheMask}
                onClick={hideTheMask}
                autoComplete='off'
              />
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={indicatorStyle} />
              )}
            </Container>
          )
        }}
      />
    )
  }
)

export default Text
