import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { discardChangesAndNavigate } from 'thunks/discardChangesAndNavigate'
import { useHistory } from 'react-router-dom'
import { colors } from 'app/theme'

const Discard = styled.div`
  flex: 1 0 100%;
  display: block;
  padding-bottom: 16px;
`

const DiscardTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`

const DiscardButton = styled(Button)`
  && {
    color: ${colors.red};
  }
`

export const DiscardDialogContent = () => {
  return (
    <div>
      <Discard data-testid="DiscardDialogContent">
        <DiscardTitle variant='body1'>
          You have unsaved changes on this page that will be lost if you
          continue.
        </DiscardTitle>
        <Typography variant='body2'>
          Are you sure you want to leave this page?
        </Typography>
      </Discard>
    </div>
  )
}

export const DiscardDialogActions = ({ subdomainId, discardActionType }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClose = () => {
    dispatch(closeDialog())
  }

  const handleDiscard = () => {
    dispatch(discardChangesAndNavigate(subdomainId, history, discardActionType))
    dispatch(closeDialog())
  }

  return (
    <>
      <DiscardButton data-testid="DiscardDialogActions-discard" onClick={handleDiscard}>Discard Changes</DiscardButton>
      <Button data-testid="DiscardDialogActions-close" onClick={handleClose}>Cancel</Button>
    </>
  )
}
