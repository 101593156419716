import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import { saveNote } from 'thunks/saveNote'
import { setNote } from 'ducks/noteSlice'
import { setUnsavedNote, clearUnsavedNote } from 'ducks/unsavedNotesSlice'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import { TextField, Button } from '@material-ui/core'
import { decodeHtml } from './../../utilTools/decodeHtml'

export const AddNotesDialogContent = () => {
  const dispatch = useDispatch()
  const currentInterviewPage = useSelector(state => state.currentInterviewPage)

  const savedNoteValue = useSelector(
    state => state.notes[currentInterviewPage]?.note
  )
  const unsavedNoteValue = useSelector(state => state.unsavedNote?.note)

  const noteValue =
    unsavedNoteValue || unsavedNoteValue === ''
      ? unsavedNoteValue
      : decodeHtml(savedNoteValue)
  const cancelledOrCompletedInterview = useSelector(
    cancelledOrCompletedSelector
  )
  const handleChange = e => {
    if (cancelledOrCompletedInterview) return
    dispatch(
      setUnsavedNote({
        subdomain: currentInterviewPage,
        note: e.target.value,
      })
    )
  }
  return (
    <>
      <TextField
        disabled={cancelledOrCompletedInterview}
        onChange={handleChange}
        value={noteValue}
        id='notes_text'
        label='Notes'
        type='text'
        variant='outlined'
        minRows={5}
        multiline
        autoFocus
        autoComplete='off'
        fullWidth
        inputProps={{
          'data-testid': `AddNotesDialogContent-input`
        }}
      />
    </>
  )
}

export const AddNotesDialogActions = () => {
  const dispatch = useDispatch()
  const unsavedNote = useSelector(state => state.unsavedNote)
  const cancelledOrCompletedInterview = useSelector(
    cancelledOrCompletedSelector
  )
  const handleClose = () => {
    dispatch(closeDialog())
    dispatch(clearUnsavedNote())
  }
  const handleSave = () => {
    if (cancelledOrCompletedInterview) return
    dispatch(closeDialog())
    dispatch(setNote(unsavedNote))
    dispatch(saveNote())
  }
  return (
    <>
      <Button 
        data-testid="AddNotesDialogActions-button-cancel"
        onClick={handleClose} 
        color='secondary' 
        variant='contained'>
        Cancel
      </Button>
      <Button
        data-testid="AddNotesDialogActions-button-save"
        disabled={cancelledOrCompletedInterview}
        onClick={handleSave}
        color='primary'
        variant='contained'
      >
        Save
      </Button>
    </>
  )
}
