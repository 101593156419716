import EffectCollector from 'side-effects/effectCollector'

export const didNotProvideReasonConditionalFields = [
  'employer_initiated_termination_2',
  'job_opportunity_job_end_2',
  'lack_satisfaction_job_end_2',
  'compensation_issue_job_end_2',
  'worksite_behavior_job_end_2',
  'experience_job_end_2',
  'health_job_end_2',
  'household_job_end_2',
  'child_care_job_end_2',
  'housing_transportation_job_end_2',
  'wages_job_end_2',
  'job_history_other_job_end',
]
const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (question_id === 'no_work_history' && answer) {
    effectCollector.add('job_history_table', null)
  }

  // Check if the table is being edited.
  if (question_id === 'job_history_table' && answer && Array.isArray(answer)) {
    // Flag to check if we actually need to apply an effect for the conditional.
    let isChangeConditional = false

    // Loop through each answer row, make a copy, and check if sub-question
    // wages_job_end_2 was unselected and job_history_other_job_end was answered.
    // Clear job_history_other_job_end if true. and add the effect.
    const filteredAnswer = answer.map(row => {
      const rowCopy = { ...row }
      const {
        current_job,
        wages_job_end_2,
        did_not_provide_reason_2,
        benefits_received_job,
      } = rowCopy
      if (did_not_provide_reason_2 && did_not_provide_reason_2.includes('NR')) {
        isChangeConditional = true
        didNotProvideReasonConditionalFields.forEach(
          conditionalField => delete rowCopy[conditionalField]
        )
      }
      if (wages_job_end_2 && !wages_job_end_2.includes('OT')) {
        isChangeConditional = true
        delete rowCopy.job_history_other_job_end
      }
      if (current_job && current_job.includes('CJ')) {
        isChangeConditional = true
        delete rowCopy.end_date_job
      }
      if (benefits_received_job !== 'BN') {
        isChangeConditional = true
        delete rowCopy.benefits_received_job_details
      }

      return rowCopy
    })
    if (isChangeConditional) {
      effectCollector.add('job_history_table', filteredAnswer)
    }
  }

  return effectCollector.effects
}

export default effects;