import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { colors } from 'app/theme'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: bottom;
  margin: 2px 8px 8px 4px;
`

const StyledSelect = styled(Select)`
  min-width: 150px;
  max-width: 500px;
  padding: 0px;
`

const customStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    border: isFocused
      ? `1px solid ${colors.saffron}`
      : `1px solid ${colors.white}`,
    borderBottom: isFocused
      ? `1px solid ${colors.saffron}`
      : '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: isFocused ? '4px' : '0px',
    boxShadow: isFocused ? 0 : 0,
    ':hover': {
      border: `1px solid ${colors.saffron}`,
      borderRadius: '4px',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px 2px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.54)',
    ':hover': {
      color: `${colors.saffron}`,
    },
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ':hover': {
      outline: `1px solid ${colors.saffron}`,
    },
    ':active': {
      outline: `1px solid ${colors.saffron}`,
    },
    backgroundColor: 'rgba(255, 255, 255, 1)',
    outline: isFocused ? `1px solid ${colors.saffron}` : null,
    color: '#000',
  }),
}

const ClientListDropDown = ({
  handleChange,
  options,
  defaultValue,
  menuIsOpen,
  searchable = false,
}) => {
  const defaultOption = defaultValue
    ? options.find(opt => opt.value === defaultValue)
    : options[0]

  return (
    <Container>
      <StyledSelect
        menuIsOpen={menuIsOpen}
        options={options}
        styles={customStyles}
        isSearchable={searchable}
        defaultValue={defaultOption}
        onChange={selection => handleChange(selection.value)}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  )
}

export default ClientListDropDown
