import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import AddNotesButton from 'components/general/AddNotesButton'
import { colors } from 'app/theme'

const StyledDomain = styled.div`
  && {
    color: ${colors.grey};
  }
`

const StyledSubdomain = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-top: 0px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-bottom: 8px;
  &:focus {
    outline: 0;
  }
`

const QuestionnaireHeader = React.memo(
  ({ domain, subdomain, hideNotes = false }) => {
    // ADA required that after a user first Tab action the focus should be on "ADD NOTES"
    // So, the header container should be focused when first render
    const questionnaireHeaderContainer = React.useRef(null)
    React.useEffect(() => {
      questionnaireHeaderContainer.current.focus()
    }, [questionnaireHeaderContainer])
    return (
      <Container tabIndex='-1' ref={questionnaireHeaderContainer}>
        <div>
          <StyledDomain>{domain}:</StyledDomain>
          <StyledSubdomain variant='h4'>{subdomain}</StyledSubdomain>
        </div>
        {!hideNotes && (
          <AddNotesButton label='Add Notes' subdomain={subdomain} />
        )}
      </Container>
    )
  }
)

export default QuestionnaireHeader
