import { removeRegion } from 'ducks/entitiesSlice'
import { openDialog } from 'ducks/dialogSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'

const DELETE_REGION = gql`
  mutation($id: Int!) {
    deleteRegion(id: $id) {
      deletedRegionId
    }
  }
`

export const deleteRegion = region => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: DELETE_REGION,
        variables: {
          id: region.regionID,
        },
      })

      dispatch(removeRegion(region))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
