import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  regions: {},
  offices: {},
  county: null,
}

const entitiesSlice = createSlice({
  name: 'entities',
  initialState: initialState,
  reducers: {
    setOffices: (state, action) => {
      state.offices = {}
      action.payload.entities.forEach(({ oldOfficeName, ...office }) => {
        state.offices[office.officeID] = office
        state.offices[office.officeID].oldOfficeName = office.officeName
      })
    },
    setRegions: (state, action) => {
      state.regions = {}
      action.payload.entities.forEach(({ oldRegionName, ...region }) => {
        state.regions[region.regionID] = region
        state.regions[region.regionID].oldRegionName = region.regionName
      })
    },
    setCounty: (state, action) => {
      state.county = action.payload
    },
    updateRegion: (state, action) => {
      const { updatedRegion } = action.payload
      state.regions[updatedRegion.regionID].dirty = updatedRegion.dirty
      state.regions[updatedRegion.regionID].regionName =
        updatedRegion.regionName
        const officesToUpdate = Object.keys(state.offices)
          .filter(key => state.offices[key].regionID === updatedRegion.regionID)
          .map(key => state.offices[key])
        if (officesToUpdate) {
          officesToUpdate.forEach((officeToUpdate) => {
              officeToUpdate.regionName = updatedRegion.regionName
              state.offices[officeToUpdate.officeID] = officeToUpdate
            }
          )}
    },
    addRegion: (state, action) => {
      state.regions[action.payload.region.regionID] = action.payload.region
      state.regions[action.payload.region.regionID].oldRegionName =
        action.payload.region.regionName
    },
    removeRegion: (state, action) => {
      delete state.regions[action.payload.regionID]
    },
    addOffice: (state, action) => {
      state.offices[action.payload.office.officeID] = action.payload.office
      state.offices[action.payload.office.officeID].oldOfficeName =
        action.payload.office.officeName
      // make sure the region is marked as having an office
      state.regions[action.payload.office.regionID].hasOffices = true
    },
    removeOffice: (state, action) => {
      delete state.offices[action.payload.officeID]
      // if this is the last office for a region, mark as having no offices
      state.regions[action.payload.regionID].hasOffices = Object.values(state.offices).some(o => o.regionID === action.payload.regionID)
    },
    updateOffice: (state, action) => {
      const { updatedOffice } = action.payload
      state.offices[updatedOffice.officeID].dirty = updatedOffice.dirty
      state.offices[updatedOffice.officeID].officeName =
        updatedOffice.officeName
      state.offices[updatedOffice.officeID].regionName =
        updatedOffice.regionName

      const oldRegionId = state.offices[updatedOffice.officeID].regionID 
      state.offices[updatedOffice.officeID].regionID = updatedOffice.regionID

      state.regions[oldRegionId].hasOffices = Object.values(state.offices).some(o => o.regionID === oldRegionId)
      state.regions[updatedOffice.regionID].hasOffices = Object.values(state.offices).some(o => o.regionID === updatedOffice.regionID)

    },
    setEntity: (state, action) => {
      const { index, entity } = action.payload
      if (index >= 0 && entity) {
        const current = state.offices[index]
        state.offices[index] = {
          ...current,
          ...entity,
        }
      }
    },
    markDirty: (state, action) => {
      const { index, dirty } = action.payload
      if (state.offices[index]) state.offices[index].dirty = dirty
    },
    resetEntities: (state, action) => initialState,
  },
})

export const {
  setOffices,
  setRegions,
  updateRegion,
  removeRegion,
  addOffice,
  updateOffice,
  removeOffice,
  setEntity,
  markDirty,
  setCounty,
  addRegion,
  resetEntities,
} = entitiesSlice.actions

export default entitiesSlice.reducer
