import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  firstName: null,
  lastName: null,
  role: null,
  secondaryRole: null,
  email: null,
  organization: null,
  region: null,
  office: null,
  supervisor: null,
  sectionMembers: [],
  status: null,
  disabledDate: null,
  dataExtract: 0,
  confirmedForExtract: false,
  shouldRefetch: false,
  globalIsDirty: false,
  navPath: null
}

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    setNewUser: (state, action) => {
      if (action.payload.table) {
        const newState = state
        newState[action.payload.field][action.payload.index] =
          action.payload.value
        return newState
      } else {
        if (action.payload.field === 'organization') {
          state['region'] = null
          state['office'] = null
          state['supervisor'] = null
          state['sectionMembers'] = []
        }

        if (action.payload.field === 'role') {
          // Ensure user does not end up with primary role admin and secondary role admin
          if (action.payload.value === 'AD' || action.payload.value === 'BRE') {
            state['secondaryRole'] = null
          }

          // We do not allow admins to self-demote themselves out of being an admin
          // If an admin is editing their own user roles and change their primaryRole from admin to not-admin,
          // set secondaryRole to admin
          if (state['id'] === action.payload.userId && action.payload.value !== 'AD' && state['role'] === 'AD') {
            state['secondaryRole'] = 'AD'
          }
        }

        if (
          action.payload.field === 'region' &&
          action.payload.value === null
        ) {
          state['office'] = null
        }
        if (
          action.payload.field === 'status' &&
          action.payload.value === 'Active'
        ) {
          state['disabledDate'] = null
        }
        state[action.payload.field] = action.payload.value
      }
    },
    resetNewUser: (state, action) => initialState,
    setEditUser: (state, action) => {
      state.id = action.payload?.id
      state.firstName = action.payload?.firstName
      state.lastName = action.payload?.lastName
      state.email = action.payload?.email
      state.role = action.payload?.role
      state.secondaryRole = action.payload?.secondaryRole
      state.organization = action.payload?.organization
      state.region = action.payload?.regionID
      state.office = action.payload?.officeID
      state.supervisor = action.payload?.supervisor
      state.status = action.payload?.status
      state.disabledDate = action.payload?.disabledDate
      state.dataExtract = action.payload?.dataExtract
      state.confirmedForExtract =
        action.payload?.dataExtract === 1 ? true : false
    },
    confirmDataExtract: state => {
      state.confirmedForExtract = true
    },
    setShouldRefetch: (state, action) => {
      state.shouldRefetch = action.payload
    },
    setGlobalIsDirty: (state, action) => {
      state.globalIsDirty = action.payload
    },
    setNavPath: (state, action) => {
      state.navPath = action.payload
    }
  },
})
export const {
  setNewUser,
  resetNewUser,
  setEditUser,
  confirmDataExtract,
  setShouldRefetch,
  setGlobalIsDirty,
  setNavPath
} = adminSlice.actions

export default adminSlice.reducer
