import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { colors } from 'app/theme'
import AdminDropdown from './AdminDropdown'
import AdminText from './AdminText'
import AdminTable from './AdminTable'
import AdminSelect from './AdminSelect'
import AdminSaveButton from './AdminSaveButton'
import AdminCancelButton from './AdminCancelButton'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import AdminQueries from './AdminQueries'
import { setNewUser, setShouldRefetch } from 'ducks/adminSlice'
import { filterData } from 'components/admin/AdminHelperFunctions'
import Can from 'app/Can'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import { openDialog } from 'ducks/dialogSlice'

const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
  }
`

const GridContainer = styled(Grid)`
  margin-bottom: 8px;
`

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`

const AdminCreate = React.memo(() => {
  const STATEWIDE = '00'
  const { organization, region, role, shouldRefetch } = useSelector(
    state => state.admin
  )
  const { orgLevelCode, roles, id } = useSelector(state => state.user)
  const dispatch = useDispatch()

  // Set up lazy query. Fires off when an organization is selected.
  // querySupervisors callback is passed into the dropdown component.
  // Once there is a value for organizationLevel,
  const [
    querySupervisors,
    { data, error, refetch: supervisorRefetch },
  ] = useLazyQuery(AdminQueries.SUPERVISORS_IN_COUNTY, {
    fetchPolicy: 'network-only',
  })
  // Query for regions. Fires off after an organziation is selected.
  const [queryRegions, { data: regionData, error: regionError }] = useLazyQuery(
    AdminQueries.GET_REGIONS,
    {
      fetchPolicy: 'network-only',
    }
  )
  // Query for offices. Fires off after a region is selected.
  const [queryOffices, { data: officeData, error: officeError }] = useLazyQuery(
    AdminQueries.GET_OFFICES,
    {
      fetchPolicy: 'network-only',
    }
  )
  // Query for uses by county. Fires off after supervisor role is selected.
  const [
    queryUsersByCounty,
    { data: usersData, error: usersError, refetch: usersRefetch },
  ] = useLazyQuery(AdminQueries.USERS_BY_COUNTY, {
    fetchPolicy: 'network-only',
  })

  // Handler for making the supervisor and region queries.
  const querySupervisorsRegionAndUsers = useCallback(
    organization => {
      if (organization) {
        querySupervisors({
          variables: { id: organization },
        })
        queryRegions({
          variables: { id: organization },
        })
        queryUsersByCounty({
          variables: { orgLevelCode: organization },
        })
      }
    },
    [queryRegions, querySupervisors, queryUsersByCounty]
  )
  const queryOfficesCallback = useCallback(
    region => {
      if (region) {
        queryOffices({
          variables: { id: region },
        })
      }
    },
    [queryOffices]
  )

  // Query for the counties associated with that organization level.
  // Fires off immediately.
  const { data: orgLevelData, error: orgLevelError } = useQuery(
    AdminQueries.GET_COUNTIES,
    {
      variables: { id: orgLevelCode },
      fetchPolicy: 'network-only',
    }
  )

    useEffect(() => { 
      if(role === 'BRE') {
        dispatch(setNewUser({ field: 'organization', value: STATEWIDE, userId: id }))
        dispatch(setNewUser({ field: 'dataExtract', value: 0, userId: id }))
      }
     }, [role, id, dispatch])

const handleChange = (value) => {
  if (value === 'BRE') {
    dispatch(
    openDialog({
      type: 'information',
      title: 'Attention',
      description: 'This role is restricted to Consortium staff. Once this profile is saved, submit a request to the CalSAWS Help Desk Team to complete registration and enable the user’s access.',
    })
    )
  }
}

  // Remove any validation errors when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(removeAllValidationErrors())
    }
  }, [dispatch])

  // Make this query if the user is not a statewide admin user.
  useEffect(() => {
    if (orgLevelCode !== '00' && !region) {
      dispatch(setNewUser({ field: 'organization', value: orgLevelCode }))
    }
    querySupervisorsRegionAndUsers(organization)
    queryOfficesCallback(region)
  }, [
    querySupervisorsRegionAndUsers,
    queryOfficesCallback,
    dispatch,
    organization,
    region,
    orgLevelCode,
  ])

  // If the user has just been created, we refetch the supervisors and users
  useEffect(() => {
    if (shouldRefetch) {
      supervisorRefetch()
      usersRefetch()
      dispatch(setShouldRefetch(false))
    }
  }, [usersRefetch, supervisorRefetch, shouldRefetch, dispatch])

  // Filter the options for the dropdowns/typeahead.
  const orgLevelOptions = filterData(
    orgLevelData,
    orgLevelError,
    'getCountyByOrgLevelCode',
    'dropdown',
    dispatch
  )

  const usersOptions = filterData(
    usersData,
    usersError,
    'usersByCounty',
    'typeahead',
    dispatch
  )
  const supervisorOptions = filterData(
    data,
    error,
    'supervisorByCounty',
    'typeahead',
    dispatch
  )
  const regionOptions = filterData(
    regionData,
    regionError,
    'getRegionsByOrganizationLevelID',
    'dropdown',
    dispatch
  )
  const officeOptions = filterData(
    officeData,
    officeError,
    'getOfficesByRegionID',
    'dropdown',
    dispatch
  )

  const { isStatewide } = useSelector(state => state.user)

  const roleOptions = [
    { name: 'Case Worker', id: 'CW' },
    { name: 'Supervisor', id: 'SP' },
    { name: 'Administrator', id: 'AD' },
    { name: 'Executive', id: 'EX' },
    { name: 'QA', id: 'QA' },
  ]
  if (isStatewide) {
    roleOptions.push({name: 'Business Rules Editor', id: 'BRE'})
  }

  const hasBRERoleAssigned = role === 'BRE'

  return (
    <Can
      roles={roles}
      perform='user:create'
      yes={() => {
        return (
          <Grid container spacing={2}>
            <PageTitleContainer>
              <Typography variant='h3'>OCAT Administration</Typography>
              <div>
                <AdminSaveButton isNewUser={true} />
                <AdminCancelButton isNewUser={true} />
              </div>
            </PageTitleContainer>
            <Grid item xs={12}>
              <SectionTitle variant='h4'>Create New User</SectionTitle>
            </Grid>
            <Grid item xs={12}>
              <Typography display='block' component='span'>
                Enter new user's information in the following fields. *Required
                Fields
              </Typography>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminText
                  label='First Name*'
                  field='firstName'
                  isRequired={true}
                ></AdminText>
                <AdminText
                  label='Last Name*'
                  field='lastName'
                  isRequired={true}
                ></AdminText>
                <AdminText
                  validateEmail
                  label='Email*'
                  field='email'
                  isRequired={true}
                ></AdminText>
              </GridContainer>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminDropdown
                  label={'Role*'}
                  field='role'
                  values={roleOptions}
                  isRequired={true}
                  onChange={value => handleChange(value)}
                ></AdminDropdown>
                <AdminDropdown
                  label={'Secondary Role'}
                  field='secondaryRole'
                  values={[{ name: 'Administrator', id: 'AD' }]}
                  disabled={role === 'AD' || role === 'BRE'}
                  ></AdminDropdown>
                <AdminDropdown
                  query={querySupervisorsRegionAndUsers}
                  label={'Organization*'}
                  field='organization'
                  values={orgLevelOptions}
                  isRequired={true}
                  disabled={orgLevelCode !== '00' || hasBRERoleAssigned}
                />
              </GridContainer>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminDropdown
                  query={queryOfficesCallback}
                  disabled={!organization || organization === STATEWIDE}
                  label={'Region'}
                  field='region'
                  values={regionOptions || []}
                ></AdminDropdown>
                <AdminDropdown
                  disabled={!region}
                  label={'Office'}
                  field='office'
                  values={officeOptions || []}
                ></AdminDropdown>
                <AdminDropdown
                  label={'Database Extract'}
                  field='dataExtract'
                  disabled={hasBRERoleAssigned}
                  showNoneSelection={false}
                  values={[
                    { name: 'No', id: 0 },
                    { name: 'Yes', id: 1 },
                  ]}
                ></AdminDropdown>
              </GridContainer>
              <SectionTitle variant='h6'>Supervisor</SectionTitle>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminSelect
                  field={'supervisor'}
                  options={supervisorOptions || []}
                  selectOpts={{ isClearable: true, isDisabled: !organization }}
                />
              </GridContainer>
              <SectionTitle variant='h6'>Section Members</SectionTitle>
              <AdminTable
                headers={['Name']}
                isDisabled={role !== 'SP'}
                inputs={[
                  {
                    type: 'select',
                    field: 'sectionMembers',
                    options: usersOptions || [],
                    selectOpts: { isClearable: true, isDisabled: false },
                  },
                ]}
              ></AdminTable>
            </Grid>
          </Grid>
        )
      }}
    />
  )
})

export default AdminCreate
