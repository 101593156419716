import { setCurrentInterviewPage } from 'ducks/currentInterviewPageSlice'
import routes from 'app/routes'

// Navigates to route, without attempting save/validation
export const navigateWithoutSave = (subdomainId, history, isPreview) => {
  return async (dispatch, getState) => {
    
    const name = isPreview && !subdomainId.endsWith('_preview') ? `${subdomainId}_preview` : subdomainId
    // Ensure navigation path exists in routes, if fails, send to "missing page"
    if (routes[name]?.path) {
      // Exists, navigate to the selected page
      history.push(routes[name].path)
      dispatch(setCurrentInterviewPage(name))
    } else {
      // Otherwise, we'll land on the 'page not found' page
      history.push(`${name}`)
      dispatch(setCurrentInterviewPage(name))
    }
  }
}
