import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pendingChangesLoading: false,
  pendingChangesLoaded: false,
  pendingChanges: [],
  currentPendingChanges: [],
  upcomingChangeDate: ''
}

const pendingChangesSlice = createSlice({
  name: 'pendingChanges',
  initialState: initialState,
  reducers: {
    setPendingChangesLoading: (state, action) => {
      state.pendingChangesLoading = action.payload
    },
    setPendingChangesLoaded: (state, action) => {
      state.pendingChangesLoaded = action.payload
    },
    handlePendingChange: (state, action) => {
      state.pendingChanges.push(action.payload)
    },
    setPendingChangesList: (state, action) => {
      state.currentPendingChanges = action.payload
    },
    setUpcomingChangeDate: (state, action) => {
      state.upcomingChangeDate = action.payload
    },
    clearPendingChanges: (state, action) => {
      state.pendingChanges = initialState.pendingChanges
    }
  }
})

export const {
  setPendingChangesLoading,
  setPendingChangesLoaded,
  handlePendingChange,
  setPendingChangesList,
  setUpcomingChangeDate,
  clearPendingChanges
} = pendingChangesSlice.actions

export default pendingChangesSlice.reducer
