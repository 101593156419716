import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import {
  setExistingCase,
  setExistingCaseNumber,
  setNewCase,
  setNewCaseNumber,
  setNewCaseCounty,
} from 'ducks/newInterviewSlice'

import { colors } from 'app/theme'

//                            Styled Components
const InputContainer = styled.div`
  margin-left: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
`
const StyledSelect = styled(Select)`
  && {
    width: 150px;
  }
`
const FormContainer = styled.div`
  padding-left: 2px;
`
const NoSelection = styled.em`
  && {
    color: ${colors.lightgrey};
  }
`

// Component for the exisiting case form.
const NewAppraisalExistingCase = ({
  newCase,
  existingCase,
  existingCaseNumber,
  existingCases,
}) => {
  const dispatch = useDispatch()
  // This is required to position label appropriately in the outlined
  // Dropdown type, per MUI: https://material-ui.com/components/selects/#select
  const [selectLabelWidth, setSelectLabelWidth] = useState(0)
  const selectLabel = useRef(null)
  useEffect(() => {
    setSelectLabelWidth(selectLabel.current.offsetWidth)
  }, [])

  // Labels and ids
  const existingCaseLabel = 'Use existing case'
  const selectLabelId = 'existing-case-number-label'
  const selectId = 'existing-case-number-select'

  const handleClick = () => {
    const answer = existingCase === existingCaseLabel ? null : existingCaseLabel
    dispatch(setExistingCase(answer))
    !answer && existingCaseNumber && dispatch(setExistingCaseNumber(null))
    if (answer) {
      dispatch(setNewCase(null))
      dispatch(setNewCaseNumber(null))
      dispatch(setNewCaseCounty(null))
    }
  }
  return (
    <FormContainer>
      <FormControlLabel
        label={existingCaseLabel}
        disabled={existingCases.length === 0}
        control={
          <Radio
            checked={existingCase === existingCaseLabel}
            onClick={handleClick}
          />
        }
        value={existingCaseLabel}
      />
      <div>
        <InputContainer>
          <FormControl variant='outlined'>
            <InputLabel ref={selectLabel} id={selectLabelId}>
              Select Case
            </InputLabel>
            <StyledSelect
              required
              labelWidth={selectLabelWidth}
              labelId={selectLabelId}
              value={existingCaseNumber || ''}
              onChange={e => dispatch(setExistingCaseNumber(e.target.value))}
              id={selectId}
              disabled={!existingCase}
            >
              <MenuItem value={null}>
                <NoSelection>None</NoSelection>
              </MenuItem>
              {existingCases.map(clientCase => (
                <MenuItem key={clientCase.caseNumber} value={clientCase.id}>
                  {clientCase.caseNumber}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </InputContainer>
      </div>
    </FormContainer>
  )
}

export default NewAppraisalExistingCase
