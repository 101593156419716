import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import config from '../config'
import store from 'app/store.js'

const omitTypename = (key, value) => {
  return key === '__typename' ? undefined : value
}

const authLink = (operation, forward) => {
  operation.setContext(context => ({
    ...context,
    headers: {
      ...context.headers,
      Authorization: store.getState().user.token,
    },
  }))

  return forward(operation)
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    omitTypenameLink,
    new HttpLink({
      uri: config.apiName,
    }),
  ]),
})

export default client
