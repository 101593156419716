import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { colors } from '../../app/theme'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { decodeHtml } from '../../utilTools/decodeHtml'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ClearIcon from '@material-ui/icons/Clear'
import {
  handleQuestionChange,
  handleSpanishQuestionChange,
  removeDeleteOption,
  addDeleteOption,
  addEditOption,
  removeEditOption,
  handleUpdateOption,
  handleAddOption,
  removeNewOption,
  removeOptionFromQuestion,
  addSubQuestionEdit,
  removeSubQuestionEdit,
  handleSubQuestionTextChange,
  addSubQuestionOptionsUnderEdit,
  removeSubQuestionOptionsUnderEdit,
  handleSubQuestionUpdateOption,
  addSubQuestionDeleteOption,
  removeSubQuestionDeleteOption,
  handleSubQuestionAddOption,
  removeNewSubQuestionOption,
  removeOptionFromSubQuestion,
  emptyEditArrays,
  clearQuestionClient
} from 'ducks/questionSlice'
import { handlePendingChange } from 'ducks/pendingChangesSlice'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {
  PENDING_CHANGE_TYPES,
  CHANGE_TYPE_DESCRIPTIONS,
  QUESTION_TYPE_DESCRIPTIONS,
} from '../general/Constants'
import { openDialog } from 'ducks/dialogSlice'
import { savePendingChanges } from 'thunks/savePendingChanges'
import { getTomorrow } from 'utilTools/dateHelper'
import List from '@material-ui/core/List'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { isoToLocalDate } from '../../utilTools/dateHelper'
import { deleteScheduledChange } from '../../thunks/deleteSheduledChange'
import { setGlobalIsDirty } from 'ducks/adminSlice'

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`

const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-bottom: 10px;
    margin: 0;
  }
`
const InstructionText = styled(Typography)`
  && {
    color: ${colors.black};
    padding-bottom: 10px;
    margin-top: -20px;
    font-weight: normal;
  }
`
const Crumbs = styled(Typography)`
  && {
    color: ${colors.grey};
    font-size: 14px;
    font-weight: normal;
  }
`
const CategoryTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
    font-size: small;
  }
`
const Container = styled.div`
  && {
    margin: -5px 0 25px 10px;
    font-size: smaller;
    overflow-wrap: break-word;
    .language {
      font-size: small;
      font-weight: 600;
      border-bottom: 1px solid #dcdcdc;
      margin: 5px 0 10px;
    }
  }
`
const StyledHeaderElement = styled.div`
  && {
    font-weight: bold;
   }
  }
`
const StyledTextareaAutosize = styled(TextareaAutosize)`
  && {
    width: 100%;
    &:focus {
      outline: 2px solid ${colors.saffron};
    }
    > input: {
      color: white;
    }
    resize: none;
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const PrimaryButton = styled(Button)`
  && {
    margin: 5px;
    display: inline-block;
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`
const SecondaryButton = styled(Button)`
  && {
    margin: 5px;
    background-color: ${colors.turquoise};
    display: inline-block;
    color: ${colors.white};
  }
`
const StyledIconButton = styled(IconButton)`
  padding: 6px;
`
const ChangeWarning = styled(Typography)`
&& {
  font-size: 14px;
  color: ${colors.red};
}
`

const getQuestionText = (questionType, questionConfig) => {
  switch (questionType) {
    case 'checkbox': {
      return questionConfig.options[0].optionLabel
    }
    case 'table_question':
    case 'checkbox_list':
    case 'dropdown':
    case 'radio':
    case 'textarea': {
      return questionConfig.description
    }
    case 'dropdown_label':
    case 'textbox': {
      return questionConfig.label
    }
    default:
      throw new Error('Unhandled question type')
  }
}

function updateQuestionText(
  questionType,
  questionConfig,
  newText
) {
  switch (questionType) {
    case 'checkbox': {
      const newConfig = {
        ...questionConfig,
        options: [...questionConfig.options.map(val => ({ ...val }))]
      }
      newConfig.options[0].optionLabel = newText
      return newConfig
    }
    case 'table_question':
    case 'checkbox_list':
    case 'dropdown':
    case 'radio':
    case 'textarea': {
      return {
        ...questionConfig,
        description: newText
      }
    }
    case 'dropdown_label':
    case 'textbox': {
      return {
        ...questionConfig,
        label: newText
      }
    }
    default:
      break
  }
}

const DeleteChangeDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}
const SuccessDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}
const WarningDialog = props => {
  const { open, title, body, onClose, onCancel } = props
  const handleConfirm = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  const handleCancel = () => {
    onCancel()
  }
  return (
    <>
      <Dialog onCancel={handleCancel} onClose={handleConfirm} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleConfirm()}>
            Leave
          </PrimaryButton>
          <SecondaryButton variant='contained' onClick={() => handleCancel()}>
            Cancel
          </SecondaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}

const QuestionDetails = () => {
  const dispatch = useDispatch()
  const [editDescription, setEditDescription] = useState(false)
  const {
    selectedQuestion,
    updatedQuestionText,
    updatedSpanishQuestionText,
    optionsToDelete,
    optionsUnderEdit,
    optionsToAdd,
    isDirty,
    subQuestionsUnderEdit,
    subQuestionOptionsUnderEdit,
    subQuestionOptionsToDelete,
    subQuestionOptionsToAdd,
  } = useSelector(state => state.questionManagement)
  const currentPendingChanges = useSelector(
    state => state.pendingChanges.currentPendingChanges
  )
  const [showDeleteDialog, setDeleteDialog] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [showWarning, setWarning] = useState(false)
  const history = useHistory()
  const upcomingChangeDate = useSelector(
    state => state.pendingChanges.upcomingChangeDate.upcomingChangeDate
  )
  const currentlyScheduledChangesForQuestion = currentPendingChanges.filter(
    x => x.recordId === selectedQuestion.id
  )

  const selectedQuestionId = selectedQuestion?.id

  // if the selected question is updated force clear state
  useEffect(() => {
    if(selectedQuestionId != null) {
      dispatch(emptyEditArrays())
    }
  }, [selectedQuestionId, dispatch])

  const redirect = path => {
    history.push(path)
  }

  if (selectedQuestionId === null) {
    redirect('bre_question_list')
    return null
  }

  

  const handleExit = () => {
    if (!isDirty) {
      setSuccess(false)
      setWarning(false)
      setDeleteDialog(false)
      dispatch(setGlobalIsDirty(false))
      setEditDescription(false)
      dispatch(emptyEditArrays())
      
      dispatch(clearQuestionClient())
      redirect('bre_question_list')
    } else {
      setWarning(true)
    }
  }

  const handleConfirm = (returnToList) => {
    setSuccess(false)
    setWarning(false)
    setDeleteDialog(false)
    dispatch(setGlobalIsDirty(false))
    setEditDescription(false)
    dispatch(emptyEditArrays())

    if(returnToList) {
      dispatch(clearQuestionClient())
      redirect('bre_question_list')
    }
  }

  const handleCancel = () => {
    setWarning(false)
  }

  if (!selectedQuestion.domain) {
    redirect('bre_question_list')
  }

  const handleTextChange = (updatedText, type, language, order) => {
      dispatch(setGlobalIsDirty(true))
      switch (type) {
      case PENDING_CHANGE_TYPES.updateQuestionText:
        if (language === 'english') {
          dispatch(handleQuestionChange(updatedText))
        } else {
          dispatch(handleSpanishQuestionChange(updatedText))
        }
        break
      case PENDING_CHANGE_TYPES.updateQuestionAnswerText:
        let optionToEdit = optionsUnderEdit.find(x => x.order === order)
        let x = JSON.parse(JSON.stringify(optionToEdit))
        if (language === 'english') {
          x.optionLabel = updatedText
        } else {
          x.spanishLabel = updatedText
        }
        dispatch(handleUpdateOption(x))
        break
      case PENDING_CHANGE_TYPES.addQuestionAnswer:
        let newOption = optionsUnderEdit.find(x => x.order === order)
        let o = JSON.parse(JSON.stringify(newOption))
        o.optionId = updatedText
        dispatch(handleUpdateOption(o))
        break
      default:
        break
    }
  }

  const gatherPendingChanges = effectiveDate => {
    if (updatedQuestionText) {
      dispatch(
        handlePendingChange({
          recordId: selectedQuestion.id,
          changeType: PENDING_CHANGE_TYPES.updateQuestionText,
          publishDate: effectiveDate,
          changeDetails: { English: updatedQuestionText },
        })
      )
    }
    if (updatedSpanishQuestionText) {
      dispatch(
        handlePendingChange({
          recordId: selectedQuestion.id,
          changeType: PENDING_CHANGE_TYPES.updateQuestionText,
          publishDate: effectiveDate,
          changeDetails: { Spanish: updatedSpanishQuestionText },
        })
      )
    }
    if (optionsToDelete.length > 0) {
      optionsToDelete.forEach(option => {
        const changeDetail = { option: option }
        dispatch(
          handlePendingChange({
            recordId: selectedQuestion.id,
            changeType: PENDING_CHANGE_TYPES.removeQuestionAnswer,
            publishDate: effectiveDate,
            changeDetails: changeDetail,
          })
        )
      })
    }
    if (optionsUnderEdit.length > 0) {
      optionsUnderEdit.forEach(option => {
        const changeDetail = { option: option }
        let changeType = PENDING_CHANGE_TYPES.updateQuestionAnswerText
        const isNew = optionsToAdd.findIndex(x => x.order === option.order) > -1
        
        if (isNew) {
          changeType = PENDING_CHANGE_TYPES.addQuestionAnswer
        }
        if(option.optionId) {
          dispatch(
            handlePendingChange({
              recordId: selectedQuestion.id,
              changeType: changeType,
              publishDate: effectiveDate,
              changeDetails: changeDetail,
            })
          )
        }
      })
    }
    if (subQuestionsUnderEdit.length > 0) {
      subQuestionsUnderEdit.forEach(update => {
        const newUpdate = JSON.parse(JSON.stringify(update[1]))
        newUpdate.subQuestionId = update[0]
        const originalSubQuestion = subQuestions.find(x => x[0] === newUpdate.subQuestionId)
        const originalEnglishText = getQuestionText(originalSubQuestion[1].questionType, originalSubQuestion[1])
        const originalSpanishText = originalSubQuestion[1].spanishText
        const updatedEnglishText = getQuestionText(newUpdate.questionType, newUpdate)
        const updatedSpanishText = newUpdate.spanishText

        if (originalEnglishText !== updatedEnglishText) {
          dispatch(
            handlePendingChange({
              recordId: selectedQuestion.id,
              changeType: PENDING_CHANGE_TYPES.updateQuestionText,
              publishDate: effectiveDate,
              changeDetails: {
                English: getQuestionText(newUpdate.questionType, newUpdate),
                subQuestionId: newUpdate.subQuestionId,
              },
            })
          )
        }
        if (originalSpanishText !== updatedSpanishText) {
          dispatch(
            handlePendingChange({
              recordId: selectedQuestion.id,
              changeType: PENDING_CHANGE_TYPES.updateQuestionText,
              publishDate: effectiveDate,
              changeDetails: {
                Spanish: newUpdate.spanishText,
                subQuestionId: newUpdate.subQuestionId,
              },
            })
          )
        }
      })
    }
    if (subQuestionOptionsToDelete.length > 0) {
      subQuestionOptionsToDelete.forEach(option => {
        dispatch(
          handlePendingChange({
            recordId: selectedQuestion.id,
            changeType: PENDING_CHANGE_TYPES.removeQuestionAnswer,
            publishDate: effectiveDate,
            changeDetails: option,
          })
        )
      })
    }
    if (subQuestionOptionsUnderEdit.length > 0) {
      subQuestionOptionsUnderEdit.forEach(option => {
        let changeType = PENDING_CHANGE_TYPES.updateQuestionAnswerText
        const isNew =
          subQuestionOptionsToAdd.findIndex(
            x =>
              x.option.order === option.option.order &&
              x.subQuestionId === option.subQuestionId
          ) > -1
        if (isNew) {
          changeType = PENDING_CHANGE_TYPES.addQuestionAnswer
        }
        if (option.option.optionId){
          dispatch(
            handlePendingChange({
              recordId: selectedQuestion.id,
              changeType: changeType,
              publishDate: effectiveDate,
              changeDetails: option,
            })
          )
        }
      })
    }
    dispatch(savePendingChanges()).then(() => setSuccess(true))
  }

  const checkForDuplicateOption = () => {

    const duplicatedSubQuestionOption = subQuestionOptionsUnderEdit.some(({subQuestionId, option}) => {
      const isNew = subQuestionOptionsToAdd.findIndex(x => x.subQuestionId === subQuestionId && x.option.order === option.order) > -1
      
      const found =
        selectedQuestion.questionConfig.sub_question_ids[subQuestionId].options.findIndex(
          x =>
            (x.optionId === option.optionId ||
              x.optionLabel === option.optionLabel) &&
            x.order !== option.order
        ) > -1
        
      return isNew && found
    })

    const duplicatedQuestionOption = optionsUnderEdit.some(opt => {
      const isNew = optionsToAdd.findIndex(x => x.order === opt.order) > -1
      const found =
        selectedQuestion.questionConfig.options.findIndex(
          x =>
            (x.optionId === opt.optionId ||
              x.optionLabel === opt.optionLabel) &&
            x.order !== opt.order
        ) > -1
      return isNew && found
    })

    return duplicatedQuestionOption || duplicatedSubQuestionOption
  }

  const deleteChange = change => {
    dispatch(deleteScheduledChange(change)).then(() => setDeleteDialog(true))
  }

  const handleDeleteChangeConfirmation = change => {
    const changeDescription = CHANGE_TYPE_DESCRIPTIONS[change.changeType]
    const publishDate = change.publishDate
    dispatch(
      openDialog({
        type: 'confirmation',
        title: 'Please confirm',
        description: `Click OK to delete the '${changeDescription}' scheduled for ${publishDate}`,
        props: {
          action: () => deleteChange(change),
        },
      })
    )
  }

  const handleSave = value => {
    if (optionsToAdd.length > 0 || subQuestionOptionsToAdd.length > 0) {
      const duplicateCheck = checkForDuplicateOption()
      
      if (duplicateCheck) {
        dispatch(
          openDialog({
            type: 'information',
            title: 'Duplicate Detected',
            description: `The code or description you added already exists for this question.`,
          })
        )
        return
      }
    }

    const formatTomorrow = getTomorrow()

    // will need to add logic to determine if there are current
    // pending changes and use that date as the default
    const existingPendingChangeDate = upcomingChangeDate

    let defaultValue = formatTomorrow
    let isDisabled = false

    if (upcomingChangeDate) {
      defaultValue = existingPendingChangeDate
      isDisabled = true
    }

    dispatch(
      openDialog({
        type: 'dialogDatePicker',
        title: 'Pending Change Effective Date',
        description: 'Please choose an effective date:',
        props: {
          label: 'Effective Date',
          field: 'effectiveDate',
          minDate: formatTomorrow,
          defaultValue: defaultValue,
          isDisabled: isDisabled,
          action: value => gatherPendingChanges(value),
        },
      })
    )
  }

  const handleDeleteToggle = option => {
    if (optionsToDelete.findIndex(x => x.optionId === option.optionId) > -1) {
      const index = optionsUnderEdit.findIndex(
        x => x.optionId === option.optionId
      )
      dispatch(removeDeleteOption(index))
    } else {
      dispatch(addDeleteOption(option))
    }
  }

  const handleEditToggle = option => {
    const isNewOption = optionsToAdd.findIndex(x => x.order === option.order)
    if (isNewOption > -1) {
      dispatch(removeNewOption(isNewOption))
      const editIndex = optionsUnderEdit.findIndex(
        x => x.order === option.order
      )
      dispatch(removeEditOption(editIndex))
      const questionOptionIndex = selectedQuestion.questionConfig.options.findIndex(
        x => x.order === option.order
      )
      dispatch(removeOptionFromQuestion(questionOptionIndex))
    } else {
      if (optionsUnderEdit.findIndex(x => x.order === option.order) > -1) {
        const index = optionsUnderEdit.findIndex(x => x.order === option.order)
        dispatch(removeEditOption(index))
      } else {
        dispatch(addEditOption(option))
      }
    }
  }

  let description = getQuestionText(
    selectedQuestion.questionType,
    selectedQuestion.questionConfig
  )
  let spanishText = selectedQuestion.questionConfig.spanishText

  const questionsWithAnswerOptions = [
    'radio',
    'checkbox_list',
    'dropdown',
    'dropdown_label',
  ]

  let subQuestions = []
  if (selectedQuestion.questionType === 'table_question') {
    subQuestions = Object.entries(
      selectedQuestion.questionConfig.sub_question_ids
    )
  }

  const buildAnswerOptionsList = (option, index) => {
    return (
      <Grid container>
        <Grid
          item
          xs={2}
          style={{ 'borderRight': '1px solid #000', 'paddingTop': '8px' }}
        >
          {optionsToAdd.find(x => x.order === option.order) ? (
            <StyledTextareaAutosize
              aria-label='newEnglishOptionCode'
              rowsMax={1}
              maxLength={2}
              value={
                optionsUnderEdit.find(x => x.order === option.order)
                  .optionId              }
              onChange={e =>
                handleTextChange(
                  e.target.value,
                  PENDING_CHANGE_TYPES.addQuestionAnswer,
                  'english',
                  option.order
                )
              }
            />
          ) : (
            option.optionId
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ 'paddingLeft': '10px', 'paddingTop': '8px' }}
        >
          {optionsUnderEdit.findIndex(x => x.order === option.order) > -1 ? (
            <StyledTextareaAutosize
              aria-label='englishOptionLabel'
              rowsMax={1}
              value={
                decodeHtml(optionsUnderEdit.find(x => x.order === option.order)
                  .optionLabel || option.optionLabel)
              }
              onChange={e =>
                handleTextChange(
                  e.target.value,
                  PENDING_CHANGE_TYPES.updateQuestionAnswerText,
                  'english',
                  option.order
                )
              }
            />
          ) : (
            decodeHtml(option.optionLabel)
          )}
        </Grid>
        {spanishText ? (
          <React.Fragment>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={2}
              style={{ 'borderRight': '1px solid #000', 'paddingTop': '8px' }}
            >
              {option.optionId ||
                optionsUnderEdit.find(x => x.order === option.order).optionId}
            </Grid>
            <Grid
              item
              xs={2}
              style={{ 'paddingLeft': '10px', 'paddingTop': '8px' }}
            >
              {optionsUnderEdit.findIndex(x => x.order === option.order) >
              -1 ? (
                <StyledTextareaAutosize
                  aria-label='spanishOptionLabel'
                  rowsMax={1}
                  value={
                    decodeHtml(optionsUnderEdit.find(x => x.order === option.order)
                      .spanishLabel || option.spanishLabel)
                  }
                  onChange={e =>
                    handleTextChange(
                      e.target.value,
                      PENDING_CHANGE_TYPES.updateQuestionAnswerText,
                      'spanish',
                      option.order
                    )
                  }
                />
              ) : (
                decodeHtml(option.spanishLabel)
              )}
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid item xs={1}>
          <StyledIconButton
            aria-label='EditAnswerOption'
            onClick={() => handleEditToggle(option)}
          >
            {optionsUnderEdit.findIndex(x => x.order === option.order) > -1 ? (
              <ClearIcon />
            ) : (
              <EditIcon />
            )}
          </StyledIconButton>
          <StyledIconButton
            aria-label='DeleteAnswerOption'
            onClick={() => handleDeleteToggle(option)}
          >
            <DeleteOutlineIcon />
          </StyledIconButton>
        </Grid>
        <Grid item xs={1}>
          {optionsToDelete.includes(option) ? (
            <Typography
              style={{ color: 'red', 'font-size': '12px', cursor: 'pointer' }}
              onClick={() => handleDeleteToggle(option)}
            >
              Marked for Delete <strong>X</strong>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    )
  }

  const addOptionRow = () => {
    const orders = selectedQuestion.questionConfig.options
      .map(x => x.order)
      .sort()
    const newOrder = orders.reduce((acc, val) => val > acc ? val : acc, 0) + 1
    const newOption = { order: newOrder }
    dispatch(handleAddOption(newOption))
  }

  const addSubQuestionOptionRow = subQuestion => {
    const orders = subQuestion[1].options.map(x => x.order).sort()
    const newOrder = orders.reduce((acc, val) => val > acc ? val : acc, 0) + 1
    const newOption = {
      subQuestionId: subQuestion[0],
      option: { order: newOrder },
    }
    dispatch(handleSubQuestionAddOption(newOption))
  }

  const handleSubQuestionOptionDeleteToggle = (option, subQuestionId) => {
    if (
      subQuestionOptionsToDelete.findIndex(
        x =>
          x.option.optionId === option.optionId &&
          x.option.order === option.order &&
          x.subQuestionId === subQuestionId
      ) > -1
    ) {
      const index = subQuestionOptionsToDelete.findIndex(
        x =>
          x.option.optionId === option.optionId &&
          x.option.order === option.order &&
          x.subQuestionId === subQuestionId
      )
      dispatch(removeSubQuestionDeleteOption(index))
    } else {
      const payload = { subQuestionId: subQuestionId, option: option }
      dispatch(addSubQuestionDeleteOption(payload))
    }
  }

  const handleSubQuestionOptionEditToggle = (option, subQuestionId) => {
    
    const isNewOption = subQuestionOptionsToAdd.findIndex(
      x => x.subQuestionId === subQuestionId && x.option.order === option.order
    )
    if (isNewOption > -1) {
      dispatch(removeNewSubQuestionOption(isNewOption))
      const editIndex = subQuestionOptionsUnderEdit.findIndex(
        x =>
          x.subQuestionId === subQuestionId && x.option.order === option.order
      )
      dispatch(removeSubQuestionOptionsUnderEdit(editIndex))
      const subQuestion = subQuestions.find(x => x[0] === subQuestionId)
      const optionIndex = subQuestion[1].options.findIndex(
        x => x.order === option.order
      )
      const payload = { subQuestionId: subQuestionId, optionIndex: optionIndex }
      dispatch(removeOptionFromSubQuestion(payload))
    } else {
      const payload = { subQuestionId: subQuestionId, option: option }
      if (subQuestionOptionsUnderEdit.findIndex(x => x.subQuestionId === subQuestionId && x.option.order === option.order) > - 1) {
        const index = subQuestionOptionsUnderEdit.findIndex(x => x.subQuestionId === subQuestionId && x.option.order === option.order)
        dispatch(removeSubQuestionOptionsUnderEdit(index))
      } else {
        dispatch(addSubQuestionOptionsUnderEdit(payload))
      }
    }
  }

  const handleSubQuestionUpdate = (updatedText, type, subQuestion, order, language) => {
    switch (type) {
      case PENDING_CHANGE_TYPES.updateQuestionText:
        let subQuestionToEdit = subQuestionsUnderEdit.find(
          x => x[0] === subQuestion[0]
        )
        let x = JSON.parse(JSON.stringify(subQuestionToEdit))
        if(language === 'spanish') {
          x[1].spanishText = updatedText
        }
        else {
          x[1] = updateQuestionText(x[1].questionType, x[1], updatedText)
        }
        dispatch(handleSubQuestionTextChange(x))
        break
      case PENDING_CHANGE_TYPES.updateQuestionAnswerText:
        let subQuestionOptionToEdit = subQuestionOptionsUnderEdit.find(
          x => x.subQuestionId === subQuestion[0] && x.option.order === order
        )
        let o = JSON.parse(JSON.stringify(subQuestionOptionToEdit))
        if(language === 'spanish') {
          o.option.spanishLabel = updatedText
        } else {
          o.option.optionLabel = updatedText
        }
        dispatch(handleSubQuestionUpdateOption(o))
        break
      case PENDING_CHANGE_TYPES.addQuestionAnswer:
        let newOption = subQuestionOptionsUnderEdit.find(
          x => x.subQuestionId === subQuestion[0] && x.option.order === order
        )
        let p = JSON.parse(JSON.stringify(newOption))
        p.option.optionId = updatedText
        dispatch(handleSubQuestionUpdateOption(p))
        break
      default:
        break
    }
  }

  const buildSubQuestionAnswerOptionsList = (option, index, subQuestion) => {
    return (
      <Grid container key={index}>
        <Grid
          item
          xs={2}
          style={{ 'borderRight': '1px solid #000', 'paddingTop': '8px' }}
        >
          {subQuestionOptionsToAdd.findIndex(
            x =>
              x.subQuestionId === subQuestion[0] &&
              x.option.order === option.order
          ) > -1 ? (
            <StyledTextareaAutosize
              aria-label='newEnglishOptionCode'
              rowsMax={1}
              maxLength={2}
              value={
                subQuestionOptionsUnderEdit.find(
                  x =>
                    x.subQuestionId === subQuestion[0] &&
                    x.option.order === option.order
                ).option.optionId
              }
              onChange={e =>
                handleSubQuestionUpdate(
                  e.target.value,
                  PENDING_CHANGE_TYPES.addQuestionAnswer,
                  subQuestion,
                  option.order
                )
              }
            />
          ) : (
            option.optionId
          )}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ 'paddingLeft': '10px', 'paddingTop': '8px' }}
        >
          {subQuestionOptionsUnderEdit.findIndex(
            x =>
              x.subQuestionId === subQuestion[0] &&
              x.option.order === option.order
          ) > -1 ? (
            <StyledTextareaAutosize
              aria-label='englishOptionLabel'
              rowsMax={1}
              value={
                subQuestionOptionsUnderEdit.find(
                  x =>
                    x.subQuestionId === subQuestion[0] &&
                    x.option.order === option.order
                ).option.optionLabel || option.optionLabel
              }
              onChange={e =>
                handleSubQuestionUpdate(
                  e.target.value,
                  PENDING_CHANGE_TYPES.updateQuestionAnswerText,
                  subQuestion,
                  option.order
                )
              }
            />
          ) : (
            option.optionLabel
          )}
        </Grid>
        {subQuestion[1].options[0].spanishLabel ? (
          <React.Fragment>
            <Grid item xs={1}></Grid>
        <Grid
          item
          xs={2}
          style={{ 'borderRight': '1px solid #000', 'paddingTop': '8px' }}
        >
          {option.optionId ||
                subQuestionOptionsUnderEdit.find(x => x.subQuestionId === subQuestion[0] && x.option.order === option.order).option.optionId}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ 'paddingLeft': '10px', 'paddingTop': '8px' }}
        >
          {subQuestionOptionsUnderEdit.findIndex(
            x =>
              x.subQuestionId === subQuestion[0] &&
              x.option.order === option.order
          ) > -1 ? (
            <StyledTextareaAutosize
              aria-label='spanishOptionLabel'
              rowsMax={1}
              value={
                subQuestionOptionsUnderEdit.find(
                  x =>
                    x.subQuestionId === subQuestion[0] &&
                    x.option.order === option.order
                ).option.spanishLabel || option.spanishLabel
              }
              onChange={e =>
                handleSubQuestionUpdate(
                  e.target.value,
                  PENDING_CHANGE_TYPES.updateQuestionAnswerText,
                  subQuestion,
                  option.order,
                  'spanish'
                )
              }
            />
          ) : (
            option.spanishLabel
          )}
        </Grid>
        </React.Fragment>
        ) : (
          null
        )}
        <Grid item xs={1}>
          <StyledIconButton
            aria-label='EditAnswerOption'
            onClick={() =>
              handleSubQuestionOptionEditToggle(option, subQuestion[0])
            }
          >
            {subQuestionOptionsUnderEdit.findIndex(
              x =>
                x.subQuestionId === subQuestion[0] &&
                x.option.order === option.order
            ) > -1 ? (
              <ClearIcon />
            ) : (
              <EditIcon />
            )}
          </StyledIconButton>
          <StyledIconButton
            aria-label='DeleteAnswerOption'
            onClick={() =>
              handleSubQuestionOptionDeleteToggle(option, subQuestion[0])
            }
          >
            <DeleteOutlineIcon />
          </StyledIconButton>
        </Grid>
        <Grid item xs={2}>
          {subQuestionOptionsToDelete.findIndex(
            x =>
              x.subQuestionId === subQuestion[0] &&
              x.option.order === option.order
          ) > -1 ? (
            <Typography
              style={{ color: 'red', 'font-size': '12px', cursor: 'pointer' }}
              onClick={() =>
                handleSubQuestionOptionDeleteToggle(option, subQuestion[0])
              }
            >
              Marked for Delete <strong>X</strong>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    )
  }

  const handleSubQuestionDescriptionEditToggle = subQuestion => {
    const index =
      subQuestionsUnderEdit.findIndex(x => x[0] === subQuestion[0]) > -1
    if (index) {
      dispatch(removeSubQuestionEdit(subQuestion))
    } else {
      dispatch(addSubQuestionEdit(subQuestion))
    }
  }

  const buildSubQuestionsList = (subQuestion, index) => {
    const [question_id, questionConfig] = subQuestion
    const [, modifiedConfig] =
      subQuestionsUnderEdit.find(([id]) => id === question_id) || []
    const isBeingEdited = modifiedConfig != null

    return (
      <React.Fragment key={index}>
        <Grid container spacing={2}>
          <Grid item xs={5} style={{ 'marginTop': '10px' }}>
            {isBeingEdited ? (
              <StyledTextareaAutosize
                aria-label='description'
                id={question_id}
                minRows={2}
                value={getQuestionText(
                  questionConfig.questionType,
                  modifiedConfig || questionConfig
                )}
                onChange={e =>
                  handleSubQuestionUpdate(
                    e.target.value,
                    PENDING_CHANGE_TYPES.updateQuestionText,
                    subQuestion
                  )
                }
              />
            ) : (
              getQuestionText(questionConfig.questionType, questionConfig)
            )}
          </Grid>
          {subQuestion[1].spanishText ? (
          <Grid item xs={5} style={{ 'marginTop': '10px' }}>
            {isBeingEdited ? (
              <StyledTextareaAutosize
                aria-label='description'
                id={question_id}
                minRows={2}
                value={(modifiedConfig || questionConfig).spanishText}
                onChange={e =>
                  handleSubQuestionUpdate(
                    e.target.value,
                    PENDING_CHANGE_TYPES.updateQuestionText,
                    subQuestion,
                    '',
                    'spanish'
                  )
                }
              />
            ) : (
              questionConfig.spanishText
            )}
          </Grid>
          ) : (
            null
          )}
          <Grid item xs={1}>
            <IconButton
              aria-label='Edit'
              onClick={() =>
                handleSubQuestionDescriptionEditToggle(subQuestion)
              }
            >
              {isBeingEdited ? <ClearIcon /> : <EditIcon />}
            </IconButton>
          </Grid>
        </Grid>
        {questionConfig.options ? (
          <Grid container style={{ 'marginLeft': '20px' }}>
            <Grid item xs={10}>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  style={{
                    'borderRight': '1px solid #000',
                    'borderBottom': '1px solid #000',
                  }}
                >
                  <StyledHeaderElement>Code</StyledHeaderElement>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    'borderBottom': '1px solid #000',
                    'paddingLeft': '10px',
                  }}
                >
                  <StyledHeaderElement>Label</StyledHeaderElement>
                </Grid>
              <Grid item xs={1}></Grid>
              {subQuestion[1].options[0].spanishLabel ? (
                <React.Fragment>
                  <Grid
                    item
                    xs={2}
                    style={{
                      'borderRight': '1px solid #000',
                      'borderBottom': '1px solid #000',
                    }}
                  >
                    <StyledHeaderElement>Code</StyledHeaderElement>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      'borderBottom': '1px solid #000',
                      'paddingLeft': '10px',
                    }}
                  >
                    <StyledHeaderElement>Label</StyledHeaderElement>
                  </Grid>
                </React.Fragment>
                ) : (
                  null
                )}
              </Grid>
              <List dense style={{ 'paddingTop': '0px' }}>
                {subQuestion[1].options.map((v, i) => {
                  return buildSubQuestionAnswerOptionsList(v, i, subQuestion)
                })}
              </List>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                <IconButton
                  aria-label='Edit'
                  onClick={() => addSubQuestionOptionRow(subQuestion)}
                >
                  <AddBoxIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    )
  }

  const buildPendingChangesList = (
    change,
    index,
    handleDeleteChangeConfirmation
  ) => {
    if (!currentlyScheduledChangesForQuestion.length > 0) {
      return
    }

    let type = Object.keys(change.changeDetails)[0]
    let changeDetails = Object.values(change.changeDetails)[0]
    let update = ''
    if (type === 'option') {
      update = Object.values(change.changeDetails)[0]
      changeDetails =
        'Question: ' + change.recordId + ' -- Option: ' + update.optionId
    }

    return (
      <Grid container>
        <Grid item xs={1} style={{ 'text-align': 'center' }}>
          <DeleteOutlineIcon
            className='icon'
            onClick={() => {
              handleDeleteChangeConfirmation(change)
            }}
          />
        </Grid>
        <Grid item xs={1}>
          {change.publishDate}
        </Grid>
        <Grid item xs={3}>
          {CHANGE_TYPE_DESCRIPTIONS[change.changeType]}
        </Grid>
        <Grid item xs={3}>
          {decodeHtml(changeDetails)}
        </Grid>
        <Grid item xs={2}>
          {change.createUser.firstName + ' ' + change.createUser.lastName}
        </Grid>
        <Grid item xs={2}>
          {isoToLocalDate(change.createdAt)}
        </Grid>
      </Grid>
    )
  }

  function compareQuestionOrder(a, b) {

    return a[1].order - b[1].order;
  }

  return (
    <>
     <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle variant='h4'>Manage Question and Answers</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <InstructionText variant='body1'>
            Click the Edit button to enable the question and/or answer fields.
          </InstructionText>
        </Grid>
        <Grid item xs={12}>
          <Crumbs>
            {decodeHtml(selectedQuestion.domain)} {'>'}{' '}
            {decodeHtml(selectedQuestion.subDomain)}
          </Crumbs>
        </Grid>
        <Grid item xs={12}>
          <SectionTitle variant='h5'>Existing Question and Answer Options</SectionTitle>
          <Grid container>
            <Grid item xs={5}>
              <CategoryTitle variant='h6'>Question</CategoryTitle>
            </Grid>
            {spanishText ? (
              <Grid item xs={5}>
                <CategoryTitle variant='h6'>Spanish Question</CategoryTitle>
              </Grid>
            ) : null}
          </Grid>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={spanishText ? 5 : 10}>
                {editDescription ? (
                  <StyledTextareaAutosize
                    aria-label='description'
                    minRows={2}
                    value={updatedQuestionText || description}
                    onChange={e =>
                      handleTextChange(
                        e.target.value,
                        PENDING_CHANGE_TYPES.updateQuestionText,
                        'english'
                      )
                    }
                  />
                ) : (
                  description
                )}
              </Grid>
              {spanishText ? (
                <React.Fragment>
                  <Grid item xs={5}>
                    {editDescription && spanishText ? (
                      <StyledTextareaAutosize
                        aria-label='spanishText'
                        minRows={2}
                        value={updatedSpanishQuestionText || spanishText}
                        onChange={e =>
                          handleTextChange(
                            e.target.value,
                            PENDING_CHANGE_TYPES.updateQuestionText,
                            'spanish'
                          )
                        }
                      />
                    ) : (
                      spanishText
                    )}
                  </Grid>
                </React.Fragment>
              ) : null}
              <Grid item xs={1}>
                { !selectedQuestion.questionConfig.sharedLabel &&
                <IconButton
                  aria-label='Edit'
                  onClick={() => setEditDescription(!editDescription)}
                >
                  {editDescription ? <ClearIcon /> : <EditIcon />}
                </IconButton> }
              </Grid>
              { selectedQuestion.questionConfig.sharedLabel && <ChangeWarning>
              This question is displayed under a shared label and cannot have it's label modified.
              </ChangeWarning> }
            </Grid>
          </Container>
          {questionsWithAnswerOptions.includes(
            selectedQuestion.questionType
          ) ? (
            <Grid item xs={12}>
              <CategoryTitle variant='h6'>Answer Choices</CategoryTitle>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        style={{
                          'borderRight': '1px solid #000',
                          'borderBottom': '1px solid #000',
                        }}
                      >
                        <StyledHeaderElement>Code</StyledHeaderElement>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          'borderBottom': '1px solid #000',
                          'paddingLeft': '10px',
                        }}
                      >
                        <StyledHeaderElement>Label</StyledHeaderElement>
                      </Grid>
                      <Grid item xs={2}></Grid>
                      {spanishText ? (
                        <React.Fragment>
                          <Grid
                            item
                            xs={2}
                            style={{
                              'borderRight': '1px solid #000',
                              'borderBottom': '1px solid #000',
                            }}
                          >
                            <StyledHeaderElement>Code</StyledHeaderElement>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              'borderBottom': '1px solid #000',
                              'paddingLeft': '10px',
                            }}
                          >
                            <StyledHeaderElement>Label</StyledHeaderElement>
                          </Grid>
                        </React.Fragment>
                      ) : null}
                    </Grid>
                    <List dense style={{ 'paddingTop': '0px' }}>
                      {selectedQuestion.questionConfig.options.map((v, i) => {
                        return buildAnswerOptionsList(v, i)
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Container>
              <Grid container>
                <Grid item xs={1}>
                  <IconButton aria-label='Edit' onClick={() => addOptionRow()}>
                    <AddBoxIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {selectedQuestion.questionType === 'table_question' ? (
            <Grid item xs={12}>
              <CategoryTitle variant='h6'>Sub Questions</CategoryTitle>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <List dense style={{ 'paddingTop': '0px' }}>
                      {subQuestions.sort(compareQuestionOrder).map((v, i) => {
                        return buildSubQuestionsList(v, i)
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <SectionTitle variant='h5'>Specifications</SectionTitle>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={3}>
                      <StyledHeaderElement>Type</StyledHeaderElement>
                    </Grid>
                    <Grid item xs={2}>
                      <StyledHeaderElement>
                        Interface Impact
                      </StyledHeaderElement>
                    </Grid>
                    <Grid item xs={2}>
                      <StyledHeaderElement>Report Impact</StyledHeaderElement>
                    </Grid>
                    <Grid item xs={2}>
                      <StyledHeaderElement>
                        Questionnaire Impact
                      </StyledHeaderElement>
                    </Grid>
                    <Grid item xs={2}>
                      <StyledHeaderElement>Rule Impact</StyledHeaderElement>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      {
                        QUESTION_TYPE_DESCRIPTIONS[
                          selectedQuestion.questionType
                        ]
                      }
                    </Grid>
                    <Grid item xs={2}>
                      {selectedQuestion.questionConfig.impacts.interface
                        ? 'Yes'
                        : 'No'}
                    </Grid>
                    <Grid item xs={2}>
                      {selectedQuestion.questionConfig.impacts.report
                        ? 'Yes'
                        : 'No'}
                    </Grid>
                    <Grid item xs={2}>
                      {selectedQuestion.questionConfig.impacts.questionnaire
                        ? 'Yes'
                        : 'No'}
                    </Grid>
                    <Grid item xs={2}>
                      {selectedQuestion.questionConfig.impacts.rule
                        ? 'Yes'
                        : 'No'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
            {currentlyScheduledChangesForQuestion.length > 0 ? (
              <React.Fragment>
                <SectionTitle>Pending Changes</SectionTitle>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={1} style={{ 'text-align': 'center' }}>
                          <StyledHeaderElement>Cancel</StyledHeaderElement>
                        </Grid>
                        <Grid item xs={1}>
                          <StyledHeaderElement>
                            Effective Date
                          </StyledHeaderElement>
                        </Grid>
                        <Grid item xs={3}>
                          <StyledHeaderElement>Action</StyledHeaderElement>
                        </Grid>
                        <Grid item xs={3}>
                          <StyledHeaderElement>Details</StyledHeaderElement>
                        </Grid>
                        <Grid item xs={2}>
                          <StyledHeaderElement>Updated By</StyledHeaderElement>
                        </Grid>
                        <Grid item xs={2}>
                          <StyledHeaderElement>
                            Date Updated
                          </StyledHeaderElement>
                        </Grid>
                      </Grid>
                      <List dense>
                        {currentlyScheduledChangesForQuestion.map((v, i) => {
                          return buildPendingChangesList(
                            v,
                            i,
                            handleDeleteChangeConfirmation
                          )
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Container>
              </React.Fragment>
            ) : null}
          </Grid>
          <Grid container justifyContent='flex-end'>
            <PrimaryButton
              variant='contained'
              onClick={() => {
                handleSave()
              }}
            >
              Save
            </PrimaryButton>
            <SecondaryButton
              variant='contained'
              onClick={() => {
                handleExit()
              }}
            >
              Exit
            </SecondaryButton>
          </Grid>
          <DeleteChangeDialog
            open={showDeleteDialog}
            onClose={handleConfirm}
            title='Pending Change Deleted Successfully'
            body='Click OK to continue.'
          />
          <SuccessDialog
            open={showSuccess}
            onClose={handleConfirm}
            title='Pending Question Change Saved Successfully'
            body='Click OK to continue.'
          />
          <WarningDialog
            open={showWarning}
            onClose={() => handleConfirm(true) }
            onCancel={handleCancel}
            body='You have unsaved changes on this page that will be lost if you
          continue.'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default QuestionDetails
