import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import xor from 'lodash/xor'
import uniqBy from 'lodash/uniqBy'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import ControlDescription from 'components/general/ControlDescription'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { decodeHtml } from 'utilTools/decodeHtml'

// Data selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (index, sub_question_id, isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      tableAnswers: data.answer || [],
      label: data.sub_question_ids[sub_question_id]?.label || '',
      description: data.sub_question_ids[sub_question_id]?.description || '',
      subQuestionAnswer:
        data.answer && index >= 0 && data.answer[index][sub_question_id]
          ? data.answer[index][sub_question_id]
          : [],
      // This is a sanity check just in case there are duplicates
      options: uniqBy(
        data.sub_question_ids[sub_question_id].options,
        'optionId'
      ),
    }
  })
}
// See the Xor documentation at Lodash: https://lodash.com/docs/4.17.15#xor
// toggle([1, 2], 3)
// => [1, 2, 3]
// toggle([1, 2], 2)
// => [1]
// This is defined outside of the component so it isn't rebuilt on each re-render
const toggle = (array, item) => xor(array, [item])

const InfiniteCheckboxGrid = React.memo(
  ({
    checkboxGridOptions = {},
    index,
    hideDescription = true,
    hideOptionLabel = false,
    hideLabel = true,
    isDisabled,
    question_id,
    sub_question_id,
    noTable = false,
    className,
    isPreview
  }) => {
    const dispatch = useDispatch()

    // We create a selector for each instance of the CheckboxGrid component, and
    // get current data from redux.
    const dataSelector = createDataSelector(index, sub_question_id, isPreview)
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )
    const {
      description,
      label,
      tableAnswers,
      subQuestionAnswer,
      options,
    } = useSelector(state => dataSelector(state, question_id))

    // Collect change and dispatch to Redux
    // For Checkbox grids, we need to toggle (see above) items in and out
    // of arrays immutably before saving the new array of answers
    const handleChange = name => event => {
      if (cancelledOrCompletedInterview) return
      const updatedData = toggle(subQuestionAnswer, name)
      let updatedTableAnswers = tableAnswers.map(answer => {
        const rowAnswers = {}
        Object.keys(answer).forEach(
          sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
        )
        return rowAnswers
      })
      updatedTableAnswers[index][sub_question_id] = updatedData
      dispatch(isPreview
        ? capturePreviewFormData({ question_id, answer: updatedTableAnswers })
        : captureFormData({ question_id, answer: updatedTableAnswers }))
    }

    const checkboxGrid = (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                {!hideLabel && (
                  <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                )}
                <FormGroup>
                  {options
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }) =>
                      !hideOptionLabel ? (
                        <FormControlLabel
                          value={optionId}
                          control={
                            <Checkbox
                              inputProps={{
                                'aria-label': `Checkbox ${optionId}`,
                              }}
                              {...checkboxGridOptions}
                              key={optionId}
                              disabled={true}
                              checked={subQuestionAnswer.includes(optionId)}
                              value={optionLabel}
                            />
                          }
                          label={decodeHtml(optionLabel)}
                          key={optionId}
                        />
                      ) : (
                        <Checkbox
                          inputProps={{ 'aria-label': `Checkbox ${optionId}` }}
                          {...checkboxGridOptions}
                          key={optionId}
                          disabled={true}
                          checked={subQuestionAnswer.includes(optionId)}
                          onChange={handleChange(optionId)}
                          value={decodeHtml(optionLabel)}
                        />
                      )
                    )}
                </FormGroup>
              </FormControl>
            </div>
          )
        }}
        yes={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                {!hideLabel && (
                  <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                )}
                <FormGroup>
                  {options
                    .sort((a, b) => a.order - b.order)
                    .map(({ optionId, optionLabel }) =>
                      !hideOptionLabel ? (
                        <FormControlLabel
                          value={optionId}
                          control={
                            <Checkbox
                              inputProps={{
                                'aria-label': `Checkbox ${optionId}`,
                              }}
                              {...checkboxGridOptions}
                              key={optionId}
                              disabled={
                                cancelledOrCompletedInterview || isDisabled
                              }
                              checked={subQuestionAnswer.includes(optionId)}
                              onChange={handleChange(optionId)}
                              value={decodeHtml(optionLabel)}
                            />
                          }
                          label={decodeHtml(optionLabel)}
                          key={optionId}
                        />
                      ) : (
                        <Checkbox
                          inputProps={{ 'aria-label': `Checkbox ${optionId}` }}
                          {...checkboxGridOptions}
                          key={optionId}
                          disabled={cancelledOrCompletedInterview || isDisabled}
                          checked={subQuestionAnswer.includes(optionId)}
                          onChange={handleChange(optionId)}
                          value={optionLabel}
                        />
                      )
                    )}
                </FormGroup>
              </FormControl>
            </div>
          )
        }}
      />
    )

    if (noTable) {
      return checkboxGrid
    }
    return <TableCell align='center'>{checkboxGrid}</TableCell>
  }
)

export default InfiniteCheckboxGrid
