import React from 'react'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import SyncIcon from '@material-ui/icons/Sync'
import { colors } from '../../app/theme'
import styled from 'styled-components'

const StyledSyncDisabledIcon = styled(SyncDisabledIcon)`
  && {
    color: ${colors.lightgrey};
  }
`

const altTextActive = 'Populated through Interface'
const altTextDisabled = 'Not populated through the Interface'

const Indicator = ({ indicator, style }) => {
  return indicator === 'saws' ? (
    <SyncIcon style={style} color='secondary' titleAccess={altTextActive} aria-label={altTextActive} />
  ) : (
    indicator !== 'noIndicator' && <StyledSyncDisabledIcon style={style} titleAccess={altTextDisabled} aria-label={altTextDisabled} />
  )
}

export default Indicator
