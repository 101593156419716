import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import ClientListFilterMenu from './ClientListFilterMenu'
import ClientListTable from './ClientListTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  formatClientsForTable,
  filterByTextInputs,
  handleInputFilters,
} from './clientListHelper'

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const ClientList = ({
  records,
  totalRecordCount,
  interviewers,
  filters,
  filtersChanged,
  inputFilters,
  handleInputFiltersChanged,
  loading,
  ...ClientTablePassthroughProps
}) => {
  const alert = useRef()
  // Get the user's id and isStatewide properties
  const { isStatewide } = useSelector(state => state.user)

  // Format the records for viewing specific for this table
  const displayData = formatClientsForTable(records).filter(record =>
    filterByTextInputs(record, inputFilters)
  )

  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Alert_Role
  // We leverage the alert role of an element for informing the user relying on a screenreader
  // what changed in the client list table based on filters, solutioning using only react and
  // redux state proved innefective at first pass
  useEffect(() => {
    alert.current = document.createElement('p')
    alert.current.setAttribute('role', 'alert')
    const alertText = document.createTextNode(
      `Filters applied, ${displayData.length} rows in client list table.`
    )
    alert.current.appendChild(alertText)
    document.body.appendChild(alert.current)
    return () => document.body.removeChild(alert.current)
  }, [displayData])

  return (
    <Container>
      <Typography variant='h3'>
        Client List {loading ? <CircularProgress /> : null}
      </Typography>
      <ClientListFilterMenu
        updateFilters={filtersChanged}
        defaultFilters={filters}
        isStatewide={isStatewide}
        interviewers={interviewers}
      />
      <ClientListTable
        displaySource='true'
        tableData={displayData}
        inputFilters={inputFilters}
        handleInputFilters={handleInputFilters(
          inputFilters,
          handleInputFiltersChanged
        )}
        totalRecordCount={totalRecordCount}
        {...ClientTablePassthroughProps}
      />
    </Container>
  )
}
export default ClientList
