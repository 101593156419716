import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import styled from 'styled-components'
import uniqBy from 'lodash/uniqBy'
import TableCell from '@material-ui/core/TableCell'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { v4 as uuidv4 } from 'uuid'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

import ControlDescription from 'components/general/ControlDescription'

import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { colors } from 'app/theme'
import { decodeHtml } from '../../utilTools/decodeHtml'
const CustomFormControl = styled(FormControl)`
  && {
    width: ${p => p.width};
  }
`

const CustomDropdown = styled(Select)`
  && {
    text-align: left;
    margin-bottom: 10px;
    width: 100%;
  }
`

const NoSelection = styled(MenuItem)`
  && {
    color: ${colors.grey};
  }
`
// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (index, sub_question_id, altMenuItems, isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    const options =
      altMenuItems.length === 0
        ? uniqBy(data.sub_question_ids[sub_question_id]?.options, 'optionId')
        : altMenuItems
    const optionIds = options.map(option => option.optionId)
    return {
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty string)
      tableAnswers: data.answer || [],
      label: data.sub_question_ids[sub_question_id]?.label || '',
      description: data.sub_question_ids[sub_question_id]?.description || '',
      // If there is an answer, a row exists for that answer, and the answer
      // in the store is an option within the provided options, display that
      // answer, else display nothing.
      subQuestionAnswer:
        data.answer &&
        index >= 0 &&
        optionIds.includes(data.answer[index][sub_question_id])
          ? data.answer[index][sub_question_id]
          : '',
      // This is a sanity check just in case there are duplicates
      options,
    }
  })
}
const InfiniteDropdown = React.memo(
  ({
    dropdownOptions = {},
    hideDescription = true,
    hideLabel = true,
    index,
    isDisabled,
    question_id,
    sub_question_id,
    noTable = false,
    width = '250px',
    altMenuItems = [],
    className,
    isPreview
  }) => {
    // Data selector and dispatch
    const dispatch = useDispatch()
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )
    const dataSelector = createDataSelector(
      index,
      sub_question_id,
      altMenuItems,
      isPreview
    )
    const {
      tableAnswers,
      subQuestionAnswer,
      options,
      description,
      label,
    } = useSelector(state => dataSelector(state, question_id))

    // This is required to position label appropriately in the outlined
    // Dropdown type, per MUI: https://material-ui.com/components/selects/#select
    const [labelWidth, setLabelWidth] = React.useState(0)
    const inputLabel = React.useRef(null)
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth)
    }, [])

    // Input Handler
    const handleChange = e => {
      if (cancelledOrCompletedInterview) return
      let updatedTableAnswers = tableAnswers.map(answer => {
        const rowAnswers = {}
        Object.keys(answer).forEach(
          sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
        )
        return rowAnswers
      })
      updatedTableAnswers[index][sub_question_id] = e.target.value
      dispatch(isPreview
        ? capturePreviewFormData({ question_id, answer: updatedTableAnswers })
        : captureFormData({ question_id, answer: updatedTableAnswers }))
    }

    // Required labels for ADA
    const labelid = `${question_id}-label-${uuidv4()}`
    const selectid = `${question_id}-select-${uuidv4()}`

    // Create dropdown options
    const controlOptions = options
      .sort((a, b) => a.order - b.order)
      .map(({ optionId, optionLabel }) => (
        <MenuItem
          disableRipple
          disableTouchRipple
          value={optionId}
          key={optionId}
        >
          {decodeHtml(optionLabel)}
        </MenuItem>
      ))

    // Render
    const dropdown = (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomFormControl width={width} variant='outlined'>
                <InputLabel ref={inputLabel} id={labelid}>
                  {hideLabel ? '' : decodeHtml(label)}
                </InputLabel>
                <CustomDropdown
                  {...dropdownOptions}
                  disabled={true}
                  variant='outlined'
                  labelId={labelid}
                  width={width}
                  id={selectid}
                  labelWidth={labelWidth}
                  value={subQuestionAnswer}
                >
                  <NoSelection component='em' value={null}>
                    None
                  </NoSelection>
                  {controlOptions}
                </CustomDropdown>
              </CustomFormControl>
            </div>
          )
        }}
        yes={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomFormControl width={width} variant='outlined'>
                <InputLabel ref={inputLabel} id={labelid}>
                  {hideLabel ? '' : decodeHtml(label)}
                </InputLabel>
                <CustomDropdown
                  {...dropdownOptions}
                  disabled={cancelledOrCompletedInterview || isDisabled}
                  variant='outlined'
                  labelId={labelid}
                  width={width}
                  id={selectid}
                  labelWidth={labelWidth}
                  value={subQuestionAnswer}
                  onChange={handleChange}
                >
                  <NoSelection
                    component='em'
                    disableRipple
                    disableTouchRipple
                    value={null}
                  >
                    None
                  </NoSelection>
                  {controlOptions}
                </CustomDropdown>
              </CustomFormControl>
            </div>
          )
        }}
      />
    )
    if (noTable) {
      return dropdown
    }
    return <TableCell align='center'>{dropdown}</TableCell>
  }
)

export default InfiniteDropdown
