import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TableCell from '@material-ui/core/TableCell'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import ControlDescription from 'components/general/ControlDescription'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteText from 'components/table/InfiniteText'

const limitedEnglishSelector = state =>
  state.interview.client_limited_english.answer

const previewLimitedEnglishSelector = state =>
  state.preview.client_limited_english.answer

const learningDisabilitySelector = state =>
  state.interview.diagnosed_with_learning_disability.answer

const previewLearningDisabilitySelector = state =>
  state.preview.diagnosed_with_learning_disability.answer

const adhdSelector = state => state.interview.diagnosed_with_adhd.answer

const previewAdhdSelector = state => state.preview.diagnosed_with_adhd.answer

const medicationFeelingsSelector = state =>
  state.interview.taking_medications_affect_actions_feelings.answer

const previewMedicationFeelingsSelector = state =>
  state.preview.taking_medications_affect_actions_feelings.answer

const medicationsTableSelector = state =>
  state.interview.medications_table.answer

const previewMedicationsTableSelector = state =>
  state.preview.medications_table.answer

const selectLimitedEnglish = createSelector(
  limitedEnglishSelector,
  answer => answer && answer.includes('LM')
)

const selectPreviewLimitedEnglish = createSelector(
  previewLimitedEnglishSelector,
  answer => answer && answer.includes('LM')
)

const selectLearningDisability = createSelector(
  learningDisabilitySelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewLearningDisability = createSelector(
  previewLearningDisabilitySelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectAdhd = createSelector(
  adhdSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewAdhd = createSelector(
  previewAdhdSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectMedicationFeelings = createSelector(
  medicationFeelingsSelector,
  answer => answer?.toUpperCase() !== 'YS'
)

const selectPreviewMedicationFeelings = createSelector(
  previewMedicationFeelingsSelector,
  answer => answer?.toUpperCase() !== 'YS'
)

const selectMedicationsTable = createSelector(
  medicationsTableSelector,
  answer => Array.isArray(answer) && answer.length > 0
)

const selectPreviewMedicationsTable = createSelector(
  previewMedicationsTableSelector,
  answer => Array.isArray(answer) && answer.length > 0
)

const limitedEnglishQ = [
  'problems_learning_middle_school',
  'difficult_working_with_test_booklet',
  'difficulty_working_with_numbers_',
  'trouble_judging_distances',
  'family_members_learning_problems',
  'problems_learning_elementary_school',
  'difficulty_mixing_mathematical_signs',
  'difficulty_filling_out_forms',
  'difficulty_memorizing_numbers',
  'difficulty_remembering_spelling',
  'problems_taking_notes',
  'trouble_adding_subtracking_numbers',
  'special_program_extra_help',
]

const healthIssuesQ = [
  'ear_infections',
  'sinus_problems',
  'high_fevers_long_lasting',
  'diabetes',
  'severe_allergies',
  'headaches_or_migraines',
  'head_injury',
  'convulsions_seizures',
  'serious_health_problems',
]

const MedicationsRow = props => {
  const { isPreview } = props
  return (
    <>
      <TableCell align='center'>
        <InfiniteText noTable sub_question_id={'what_medication'} {...props} shouldDecode={true} isPreview={isPreview} />
      </TableCell>
      <TableCell align='center'>
        <InfiniteText
          noTable
          sub_question_id={'medication_frequency'}
          {...props}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </TableCell>
    </>
  )
}

const medicationsTableQuestionSelector = state => state.interview.medications_table
const previewMedicationsTableQuestionSelector = state => state.preview.medications_table

const medicationsQuestion = createSelector(medicationsTableQuestionSelector, q => q)
const previewMedicationsQuestion = createSelector(previewMedicationsTableQuestionSelector, q => q)

const EducationLearningNeedsAssessment = React.memo(({ isPreview }) => {
  const limitedEnglish = useSelector(isPreview ? selectPreviewLimitedEnglish : selectLimitedEnglish)
  const learningDisability = useSelector(isPreview ? selectPreviewLearningDisability : selectLearningDisability)
  const adhd = useSelector(isPreview ? selectPreviewAdhd : selectAdhd)
  const medicationFeelings = useSelector(isPreview ? selectPreviewMedicationFeelings : selectMedicationFeelings)
  const medicationsTable = useSelector(isPreview ? selectPreviewMedicationsTable : selectMedicationsTable)

  const medicationsTableQuestion = useSelector(isPreview ? previewMedicationsQuestion : medicationsQuestion)

  const headers = [
    medicationsTableQuestion.sub_question_ids.what_medication.label,
    medicationsTableQuestion.sub_question_ids.medication_frequency.label
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Learning Needs Assessment'
          domain='Education'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          {limitedEnglishQ.map(id => (
            <Select question_id={id} key={id} isDisabled={limitedEnglish} isPreview={isPreview} />
          ))}
          <Typography variant='h5'>Education</Typography>
          <Select
            question_id='special_education_classes'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Select
            question_id='diagnosed_with_learning_disability'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Text
            question_id='diagnosed_learning_by_whom'
            isDisabled={!learningDisability || limitedEnglish}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Text
            question_id='diagnosed_learning_when'
            isDisabled={!learningDisability || limitedEnglish}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select
            question_id='diagnosed_with_adhd'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Text
            question_id='diagnosed_adhd_by_whom'
            isDisabled={!adhd || limitedEnglish}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Text
            question_id='diagnosed_adhd_when'
            isDisabled={!adhd || limitedEnglish}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Typography variant='h5'>Glasses</Typography>
          <Select question_id='wear_glasses' isDisabled={limitedEnglish} isPreview={isPreview} />
          <Select
            question_id='vision_tested_last_two_years'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Typography variant='h5'>Hearing</Typography>
          <Select question_id='hearing_aid' isDisabled={limitedEnglish} isPreview={isPreview} />
          <Select
            question_id='hearing_tested_last_two_years'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Typography variant='h5'>Speech</Typography>
          <Select
            question_id='seen_speech_therapist'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Typography variant='h5'>Medical/Physical</Typography>
          <ControlDescription disabled={limitedEnglish}>
            22. Have you ever had any of the following?
          </ControlDescription>
          {healthIssuesQ.map(qId => (
            <Select question_id={qId} key={qId} isDisabled={limitedEnglish} isPreview={isPreview} />
          ))}
          <Select
            question_id='taking_medications_affect_actions_feelings'
            isDisabled={medicationsTable || limitedEnglish}
            isPreview={isPreview}
          />
          <InfiniteTable
            rowComponent={
              <MedicationsRow
                isDisabled={medicationFeelings || limitedEnglish}
                isPreview={isPreview}
              />
            }
            headers={headers}
            question_id='medications_table'
            isDisabled={medicationFeelings || limitedEnglish}
            isPreview={isPreview}
          />
          <Select
            question_id='medical_follow_up_services'
            isDisabled={limitedEnglish}
            isPreview={isPreview}
          />
          <Text question_id='total_score' defaultValue='0' isDisabled={true} isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})
export default EducationLearningNeedsAssessment
