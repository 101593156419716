import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import styled from 'styled-components'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { decodeHtml } from '../../utilTools/decodeHtml'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`

const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const recommendationsSelector = state => state.recommendation.recommendations
const previewRecommendationsSelector = state => state.preview.recommendations

const RecommendationsStrengths = ({ isPreview }) => {
  
  const recommendations = useSelector(isPreview ? previewRecommendationsSelector : recommendationsSelector)
  const { recommendationsLoading } = useSelector(isPreview ? state => state.preview : state => state.recommendation)

  if (recommendationsLoading) return <div>Loading recommendations...</div>
  return (
    <Grid container spacing={2}>
      <QuestionnaireHeader
        subdomain='Strengths'
        domain='Recommendations'
        hideNotes={true}
      />

      <Grid item xs={12}>
        <CustomTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  Category <br /> Type
                </TableHeaderCell>
                <TableHeaderCell>Recommendation</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(recommendations)
                .sort((a, b) => {
                  // If category matches, use type
                  if (
                    a.englishCategoryText.toLowerCase() ===
                    b.englishCategoryText.toLowerCase()
                  ) {
                    return a.englishTypeText.toLowerCase() >
                      b.englishTypeText.toLowerCase()
                      ? 1
                      : -1
                  }

                  // Else use description
                  return a.englishCategoryText.toLowerCase() >
                    b.englishCategoryText.toLowerCase()
                    ? 1
                    : -1
                })
                .map((recommendation, index) => {
                  // Filtering to only show 'Strengths' relevant recommendations
                  if (recommendation.domain.code !== 'ST') {
                    return null
                  }
                  return (
                    <TableRow
                      key={index}
                      data-domain={recommendation.domainID}
                      data-category={recommendation.categoryID}
                      data-type={recommendation.typeID}
                    >
                      <TableCell>
                        <b>{decodeHtml(recommendation.englishCategoryText)}</b>
                        <br />
                        {decodeHtml(recommendation.englishTypeText)}
                      </TableCell>

                      <TableCell
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(
                            recommendation.englishRecommendationText
                          ),
                        }}
                      />
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </CustomTableContainer>
      </Grid>
    </Grid>
  )
}
export default RecommendationsStrengths
