import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { colors } from 'app/theme'
import Domain from 'components/navigation/Domain'
import { setCurrentlyExpandedCategoryCode } from 'ducks/navigationSlice'
import { saveData } from 'thunks/saveData'
import { withStyles } from '@material-ui/core/styles'

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      font-weight: 700;
      font-size: 18px;
      color: ${p => p.active && colors.calsawsBlue};
    }
  }
`
// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledListItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },

    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(ListItem)

const Category = React.memo(({ name, code, domains, isPreview }) => {
  const dispatch = useDispatch()

  const currentlyExpandedCategoryCode = useSelector(
    state => state.navigation.currentlyExpandedCategoryCode
  )
  const interviewStatus = useSelector(
    state => state.client.currentInterviewStatus
  )
  const shouldBeOpen = currentlyExpandedCategoryCode === code
  const canSave =
    interviewStatus?.toLowerCase() !== 'complete' &&
    interviewStatus?.toLowerCase() !== 'cancelled'

  const [open, setOpen] = useState(false)
  useEffect(() => setOpen(shouldBeOpen), [shouldBeOpen])

  const handleClick = () => {
    
    // When opening a category accordion, update state to ensure other categories close
    if (!open) {
      dispatch(setCurrentlyExpandedCategoryCode(code))
      
      // If the user has clicked on recommendations, save the interview so that the
      // BRE can run
      if (code === 'recommendations' && canSave && !isPreview) {
        // this function is declared in the index file for application monitoring
        // eslint-disable-next-line no-undef
        ineum('reportEvent', 'saveOnRecommendation');
        dispatch(
          saveData({
            recommendationsClicked: true,
            disableNotifications: true,
          })
        )
      }
    }
    setOpen(!open)
  }

  const domainElements = domains.map(domain => {
    return (
      <Domain
        domainCode={domain.code}
        name={domain.name}
        subdomains={domain.subDomains}
        key={domain.code}
        isPreview={isPreview}
      />
    )
  })

  return (
    <>
      <StyledListItem
        data-testid={`Category-item-${code}`}
        disableRipple
        disableTouchRipple
        button
        dense
        onClick={handleClick}
        open={open}
        divider={open}
      >
        <StyledListItemText primary={name} active={open ? 1 : 0} />
        {open ? (
          <ExpandLess fontSize='small' />
        ) : (
          <ExpandMore fontSize='small' />
        )}
      </StyledListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {domainElements}
        </List>
      </Collapse>
    </>
  )
})

export default Category
