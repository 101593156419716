import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from 'components/general/Select'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Dropdown from 'components/general/Dropdown'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const noAnswerSubsUseSelector = state =>
  state.interview.no_answer_subs_use.answer

const previewNoAnswerSubsUseSelector = state =>
  state.preview.no_answer_subs_use.answer

const regularConsumeAlcoholSelector = state =>
  state.interview.regular_consume_alcohol.answer

const previewRegularConsumeAlcoholSelector = state =>
  state.preview.regular_consume_alcohol.answer

const consumeOtherDrugsSelector = state =>
  state.interview.consume_other_drugs.answer

const previewConsumeOtherDrugsSelector = state =>
  state.preview.consume_other_drugs.answer

const selectNoAnswerSubsUse = createSelector(
  noAnswerSubsUseSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NA')
)
const selectPreviewNoAnswerSubsUse = createSelector(
  previewNoAnswerSubsUseSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NA')
)

const selectRegularConsumeAlcohol = createSelector(
  regularConsumeAlcoholSelector,
  answer => answer?.toUpperCase() === 'YS'
)
const selectPreviewRegularConsumeAlcohol = createSelector(
  previewRegularConsumeAlcoholSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectConsumeOtherDrugs = createSelector(
  consumeOtherDrugsSelector,
  answer => answer?.toUpperCase() === 'NO'
)
const selectPreviewConsumeOtherDrugs = createSelector(
  previewConsumeOtherDrugsSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const selectRegularConsumeAlcoholIsNo = createSelector(
  regularConsumeAlcoholSelector,
  answer => answer?.toUpperCase() === 'NO' || !answer
)

const selectPreviewRegularConsumeAlcoholIsNo = createSelector(
  previewRegularConsumeAlcoholSelector,
  answer => answer?.toUpperCase() === 'NO' || !answer
)

const canSkipSelector = createSelector(
  [
    selectRegularConsumeAlcoholIsNo,
    selectConsumeOtherDrugs,
    noAnswerSubsUseSelector,
  ],
  (regularConsumeAlcoholIsNo, consumeOtherDrugsIsNo, noAnswerSubsUseSelector) =>
    noAnswerSubsUseSelector ||
    (regularConsumeAlcoholIsNo && consumeOtherDrugsIsNo)
)

const previewCanSkipSelector = createSelector(
  [
    selectPreviewRegularConsumeAlcoholIsNo,
    selectPreviewConsumeOtherDrugs,
    previewNoAnswerSubsUseSelector,
  ],
  (regularConsumeAlcoholIsNo, consumeOtherDrugsIsNo, noAnswerSubsUseSelector) =>
    noAnswerSubsUseSelector ||
    (regularConsumeAlcoholIsNo && consumeOtherDrugsIsNo)
)

const SubstanceUseIndicatorQuestions = React.memo(({ isPreview }) => {
  const noAnswerSubsUseChecked = useSelector(isPreview ? selectPreviewNoAnswerSubsUse : selectNoAnswerSubsUse)
  const regularConsumeAlcoholIsYes = useSelector(isPreview ? selectPreviewRegularConsumeAlcohol : selectRegularConsumeAlcohol)
  const canSkip = useSelector(isPreview ? previewCanSkipSelector : canSkipSelector)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Substance Use'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instruction to worker: Following is guidance, not intended to be read
          to the client. Refer individual to his or her copy of the OCAT Rights
          and Privacy Overview form. Remind individual that the interview is
          confidential except for mandated reporting of suspected elder and
          child abuse.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Indicator Questions</Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='no_answer_subs_use' isPreview={isPreview} />
          <Select
            question_id='regular_consume_alcohol'
            isDisabled={noAnswerSubsUseChecked}
            isPreview={isPreview}
          />
          <Dropdown
            question_id='consume_alcohol_frequency'
            isDisabled={!regularConsumeAlcoholIsYes}
            subquestion
            isPreview={isPreview}
          />
          <Select
            question_id='consume_other_drugs'
            isDisabled={noAnswerSubsUseChecked}
            isPreview={isPreview}
          />
        </form>
        <SkipButton isDisabled={!canSkip}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default SubstanceUseIndicatorQuestions
