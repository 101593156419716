import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  TextField,
  Link,
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import styled from 'styled-components'
import { colors } from 'app/theme'
import { useDispatch, useSelector } from 'react-redux'
import { addResource, updateResource } from 'ducks/resourceSlice'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  handleResourceDelete,
  handleResourceSave,
} from 'thunks/resourcesThunks'
import Can from 'app/Can'
import validation from 'validation/specialCharacterValidator'

const Container = styled(Paper)`
  && {
    margin: 10px 0;
  }
`

const ToolbarHeader = styled(Toolbar)`
  && {
    justify-content: space-between;
    padding-right: 8px;
  }
`

const ActionCell = styled(TableCell)`
  && {
    width: 50px;
  }
  border: ${p => p.border};
`
const ControlDescription = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`

const CustomTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
    width: ${p => p.width};
  }
`

const LinkTypography = styled(Typography)`
  && {
    text-decoration: underline;
    font-weight: 400;
  }
`

// if we get a non empty url value without a protocol add http:// at the front
const setupUrl = value => {
  if (!value || value.trim() === '') return ''

  if (value.indexOf('http://') !== 0 && value.indexOf('https://') !== 0)
    return `http://${value}`

  return value
}

const ResourceToolBar = ({
  isDisabled,
  description,
  handleRowAdd,
  editable,
}) => {
  return (
    <>
      <ToolbarHeader>
        <ControlDescription variant='h6' disabled={isDisabled}>
          {description}
        </ControlDescription>
      </ToolbarHeader>
      {editable && (
        <>
          <IconButton
            aria-label='Add'
            onClick={handleRowAdd}
            disabled={isDisabled}
          >
            <AddBoxIcon />
          </IconButton>
        </>
      )}
    </>
  )
}

const ResourceTableHeader = ({ editable }) => (
  <TableHead>
    <TableRow>
      {editable && <TableCell></TableCell>}
      <TableCell>Name</TableCell>
      <TableCell>Address</TableCell>
      <TableCell>Phone</TableCell>
      <TableCell>Website</TableCell>
      <TableCell>Description</TableCell>
      {editable && <TableCell></TableCell>}
    </TableRow>
  </TableHead>
)

const DeleteButton = ({ isDisabled, index }) => {
  const dispatch = useDispatch()
  return (
    <ActionCell align='center'>
      <IconButton
        aria-label={`Delete button ${index}`}
        onClick={() => {
          dispatch(handleResourceDelete(index))
        }}
        disabled={isDisabled}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </ActionCell>
  )
}

const ResourceEditableCell = ({
  editable,
  edit,
  resourceIndex,
  resourceName,
  resourceValue,
}) => {
  const dispatch = useDispatch()
  const handleChange = e => {
    const isInvalidInput = validation.hasSpecialCharacter(e.target.value)
    if (!isInvalidInput) {
      dispatch(
        updateResource({
          index: resourceIndex,
          key: resourceName,
          value: e.target.value,
          dirty: true,
        })
      )
    }
  }

  if (editable && edit)
    return (
      <TableCell>
        <CustomTextField
          type='text'
          variant='outlined'
          onChange={handleChange}
          value={resourceValue}
          autoComplete='off'
        />
      </TableCell>
    )
  if (resourceName === 'url') {
    const finalResourceValue = setupUrl(resourceValue)
    return (
      <TableCell>
        <Link href={finalResourceValue} target='_blank' rel='noreferrer'>
          <LinkTypography>{finalResourceValue}</LinkTypography>
        </Link>
      </TableCell>
    )
  }
  return (
    <TableCell>
      <Typography>{resourceValue}</Typography>
    </TableCell>
  )
}

const ResourceEditableRow = ({ resource, index: resourceIndex, editable }) => {
  const dispatch = useDispatch()
  const isDirty = resource.dirty
  const headers = ['name', 'address', 'phone', 'url', 'description']

  return (
    <TableRow>
      {editable && <DeleteButton isDisabled={false} index={resourceIndex} />}
      {headers.map((header, index) => {
        return (
          <ResourceEditableCell
            editable={editable}
            edit={isDirty}
            index={index}
            resourceIndex={resourceIndex}
            resourceName={header}
            resourceValue={resource[header]}
            key={`resource-table-cell-${index}`}
          />
        )
      })}
      {editable && (
        <ActionCell>
          <IconButton
            aria-label={`Edit resource button ${resourceIndex}`}
            onClick={() => {
              dispatch(
                updateResource({ index: resourceIndex, dirty: !isDirty })
              )
              if (isDirty) dispatch(handleResourceSave(resourceIndex))
            }}
            disabled={false}
          >
            {isDirty === true ? <CheckIcon /> : <EditIcon />}
          </IconButton>
        </ActionCell>
      )}
    </TableRow>
  )
}

const ResourceTableBody = ({ editable }) => {
  const currentResource =
    useSelector(state => state.resources.currentResource) || []
  return (
    <TableBody>
      {currentResource.map((resource, resourceIndex) => (
        <ResourceEditableRow
          resource={resource}
          index={resourceIndex}
          editable={editable}
          key={`resource-table-row-${resourceIndex}`}
        />
      ))}
    </TableBody>
  )
}

const ResourceTable = ({ isDisabled, description, editable }) => {
  const resourceType = useSelector(state => state.resources.currentResourceType)
  const roles = useSelector(state => state.user.roles)

  const dispatch = useDispatch()
  const handleRowAdd = () =>
    dispatch(
      addResource({
        resourceType,
      })
    )
  return (
    <Container>
      <Can
        roles={roles}
        perform='resources:edit'
        yes={() => {
          return (
            <>
              <ResourceToolBar
                isDisabled={isDisabled}
                description={description}
                handleRowAdd={handleRowAdd}
                editable={editable}
              />
              <TableContainer>
                <Table aria-label='resource-table'>
                  <ResourceTableHeader editable={editable} />
                  <ResourceTableBody editable={editable} />
                </Table>
              </TableContainer>
            </>
          )
        }}
        no={() => {
          return (
            <>
              <ResourceToolBar
                isDisabled={true}
                description={description}
                handleRowAdd={handleRowAdd}
                editable={false}
              />
              <TableContainer>
                <Table aria-label='resource-table'>
                  <ResourceTableHeader editable={false} />
                  <ResourceTableBody editable={false} />
                </Table>
              </TableContainer>
            </>
          )
        }}
      />
    </Container>
  )
}

export default ResourceTable
