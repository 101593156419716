import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import { resetNewUser } from 'ducks/adminSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'

export const AdminCancelDialogContent = () => {
  return null
}

export const AdminCancelDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }
  const handleCancelSave = () => {
    dispatch(resetNewUser())
    dispatch(removeAllValidationErrors())
    dispatch(closeDialog())
  }

  return (
    <>
      <Button onClick={handleCancelSave} variant='contained' color='primary'>
        Yes
      </Button>
      <Button onClick={handleClose} variant='contained' color='secondary'>
        No
      </Button>
    </>
  )
}
