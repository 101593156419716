import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  countyId: null,
  countyName: '',
  isStatewide: false,
  orgLevelID: null,
  orgLevelCode: null,
  region: null,
  office: null,
  dataExtract: null,
  roles: null,
  supervisor: null,
  loggedIn: null,
  isDisabled: false,
  userNotFound: false,
  token: null,
  breRoleNotAssigned: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      Object.keys(action.payload).forEach(function (key) {
        state[key] = action.payload[key]
      })
    },
    setUserToken: (state, action) => {
      state.token = action.payload
    },
    setUserNotFound: (state, action) => {
      state.userNotFound = action.payload
    },
    setBreRoleNotAssigned: (state, action) => {
      state.breRoleNotAssigned = action.payload
    },
    resetUser: (state, action) => {
      state = initialState
    },
  },
})
export const {
  setUser,
  setUserNotFound,
  setBreRoleNotAssigned,
  resetUser,
  setUserToken,
} = userSlice.actions

export default userSlice.reducer
