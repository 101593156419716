import EffectCollector from 'side-effects/effectCollector'
import { tableQuestions } from 'components/interview/EmploymentWorkExperienceInterests'

const effects = ({ question_id, answer }) => {
    const effectCollector = new EffectCollector()
    tableQuestions.forEach(tableQuestion => {
        if (question_id === tableQuestion.experience && !answer) {
            effectCollector.add(tableQuestion.interest, null)
        }
    })
    return effectCollector.effects
}

export default effects;