import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const qIds = [
  'child_issues_challenge_wtw_activites',
  'child_care_under_13',
  'child_care_special_needs',
  'child_care_arrengements_meet_needs',
  'proving_care_family_member_disability',
]

const qIdsRelatedToHouseholdMembers = [
  'child_issues_challenge_wtw_activites',
  'child_care_under_13',
  'child_care_special_needs',
  'child_care_arrengements_meet_needs',
]

const indicatorSelectors = qIds.map(question => {
  return state => state.interview[question].answer
})

const indicatorPreviewSelectors = qIds.map(question => {
  return state => state.preview[question].answer
})

const householdMembersTableSelector = state =>
  state.interview.household_members_table.answer

const householdMembersTablePreviewSelector = state =>
  state.preview.household_members_table.answer

const canSkipSelector = createSelector(indicatorSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const canSkipPreviewSelector = createSelector(indicatorPreviewSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const checkForChildInHousehold = createSelector(
  householdMembersTableSelector,
  householdMembers =>
    householdMembers?.some(member => member.household_age < 18) || false
)

const checkForChildInHouseholdPreview = createSelector(
  householdMembersTablePreviewSelector,
  householdMembers =>
    householdMembers?.some(member => member.household_age < 18) || false
)

const ChildCareAndParentingIndicator = React.memo(({ isPreview }) => {
  const canSkip = useSelector(isPreview ? canSkipPreviewSelector : canSkipSelector)
  const isChildInHousehold = useSelector(isPreview ? checkForChildInHouseholdPreview : checkForChildInHousehold)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Child Care and Parenting'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          {qIds.map(id =>
            qIdsRelatedToHouseholdMembers.includes(id) ? (
              <Select
                isDisabled={!isChildInHousehold}
                key={id}
                question_id={id}
                isPreview={isPreview}
              />
            ) : (
              <Select key={id} question_id={id} isPreview={isPreview} />
            )
          )}
        </form>
        <SkipButton isDisabled={!canSkip}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default ChildCareAndParentingIndicator
