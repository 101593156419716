import React, { useState, useRef, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import { colors } from 'app/theme'
import { setNewUser } from 'ducks/adminSlice'
import {
  addValidationError,
  removeValidationError,
} from 'ducks/validationErrorsSlice'
import isEmpty from 'lodash/isEmpty'

const DropDownContainer = styled.div`
  margin-left: 8px;
  margin-top: 20px;
  margin-right: 20px;
`

const CustomDropdown = styled(Select)`
  && {
    width: ${p => p.width};
  }
`
const NoSelection = styled(MenuItem)`
  && {
    color: ${colors.grey};
  }
`

const AdminDropdown = memo(
  ({
    multiple = false,
    showNoneSelection = true,
    label,
    width = '250px',
    values,
    field,
    isRequired,
    disabled,
    query,
    onChange
  }) => {
    const answer = useSelector(state => state.admin[field])
    const error = useSelector(state => state.validationErrors[field])
    const userId = useSelector(state => state.user.id)

    const dispatch = useDispatch()
    const [labelWidth, setLabelWidth] = useState(0)
    const inputLabel = useRef(null)
    useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth)
    }, [])
    const validation = event => {
      const notValid = isRequired && !event.target.value
      if (notValid) {
        dispatch(
          addValidationError({
            admin: true,
            field,
            error: isRequired ? 'This field is required.' : 'Error',
          })
        )
      } else {
        dispatch(removeValidationError(field))
      }
    }
    const handleChange = event => {
      validation(event)
      const { value } = event.target
      if (Array.isArray(value)) {
        if ((value.length && value[value.length - 1]) || value.length === 0) {
          dispatch(setNewUser({ field, value, userId }))
          value && !isEmpty(value) && query && query(value)
        } else {
          dispatch(setNewUser({ field: field, value: null, userId }))
        }
      } else {
        dispatch(setNewUser({ field, value: value, userId }))
        value && query && query(value)
      }
      if (onChange) {
        onChange(value)
      }
    }
    // Ensure answer value loaded in state exists in dropdown options
    const answerInOptions = values.find(item => item.id === answer)
    const ensureArray = answer => {
      if (Array.isArray(answer)) return answer
      return []
    }
    return (
      <DropDownContainer>
        <FormControl variant='outlined' error={error ? true : false}>
          <InputLabel ref={inputLabel} id={field}>
            {label}
          </InputLabel>
          <CustomDropdown
            labelId={field}
            id={`${field}-select`}
            width={width}
            value={multiple ? ensureArray(answer) : answerInOptions?.id ?? ''}
            onBlur={validation}
            onChange={handleChange}
            labelWidth={labelWidth}
            disabled={disabled}
            multiple={multiple}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {showNoneSelection && (
              <NoSelection component='em' value={null}>
                None
              </NoSelection>
            )}
            {values.map((value, index) => (
              <MenuItem value={value.id} key={index}>
                {value.name}
              </MenuItem>
            ))}
          </CustomDropdown>
          {error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
      </DropDownContainer>
    )
  }
)

export default AdminDropdown
