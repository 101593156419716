import gql from 'graphql-tag'
const queries = {
  GENDERS_LIST_QUERY: gql`
    query {
      genders {
        id
        interfaceCode
        description
      }
    }
  `,
  CIN_EXISTS_QUERY: gql`
    query clientByCIN($cin: String!) {
      clientByCIN(cin: $cin) {
        id
      }
    }
  `,
}

export default queries;