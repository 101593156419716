import React from 'react'

import { Typography, Link } from '@material-ui/core'
import styled from 'styled-components'
import config from '../../config'

const Container = styled.div`
  margin-left: 20px;
`

const StyledTypography = styled(Typography)`
  && {
    padding-top: 16px;
  }
`
const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
  }
`

const TimedOut = () => {
  return (
    <Container>
      <StyledTypography variant='h4'>Timeout Error</StyledTypography>
      <Typography>
        You have been timed out of OCAT. Click {` `}
        <StyledLink
          href={`${config.oidcUrl}?t=${new Date().getTime()}`}
          title='Login'
        >
          here
        </StyledLink>
        {` `}
        to login.
      </Typography>
    </Container>
  )
}

export default TimedOut
