import { decodeHtml } from '../utilTools/decodeHtml'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ruleList: [],
  selectedRule:{
    id: null,
    domainCode: '',
    ruleDomain: {
      id: null,
      description: '',
      spanishDescription: ''
    },
    ruleCategory: {
      id: null,
      description: '',
      spanishDescription: ''
    },
    ruleLabel:{
      id: null,
      description: '',
      spanishDescription: ''
    },
    englishRecommendationText: '',
    spanishRecommendationText: ''
  },
  originalSelectedRule: null,
  isDirty: false,
  selectedRuleSharesLabelWith: [],
  selectedRuleSharesCategoryWith: [],
  selectedRuleSharesDomainWith: [],
  updatedEnglishDomainText: '',
  updatedSpanishDomainText: '',
  updatedEnglishCategoryText: '',
  updatedSpanishCategoryText: '',
  updatedEnglishTypeText: '',
  updatedSpanishTypeText: '',
  updatedEnglishDescriptionText: '',
  updatedSpanishDescriptionText: '',
  englishDomainWarning: false,
  spanishDomainWarning: false,
  englishCategoryWarning: false,
  spanishCategoryWarning: false,
  englishTypeWarning: false,
  spanishTypeWarning: false,
  englishDescriptionWarning: false,
  spanishDescriptionWarning: false,
  latestChange: [],
  latestChangeType: null,
  latestChangeLoading: false,
  latestChangeLoaded: false
}

const ruleManagementSlice = createSlice({
  name: 'ruleManagement',
  initialState: initialState,
  reducers: {
    setSelectedRule: (state, action) => {
      state.originalSelectedRule = { ...action.payload }
      state.selectedRule = JSON.parse(JSON.stringify(action.payload))
      state.isDirty = false
      state.selectedRuleSharesLabelWith = state.ruleList.filter((v => v.ruleLabel.code === state.selectedRule.ruleLabel.code && v.id !== state.selectedRule.id)).map(v => v.name + '-' + decodeHtml(v.ruleLabel.description))
      state.selectedRuleSharesCategoryWith = state.ruleList.filter((v => v.ruleCategory.code === state.selectedRule.ruleCategory.code && v.id !== state.selectedRule.id)).map(v => v.name + '-' + decodeHtml(v.ruleLabel.description))
      state.selectedRuleSharesDomainWith = state.ruleList.filter((v => v.ruleDomain.code === state.selectedRule.ruleDomain.code && v.id !== state.selectedRule.id)).map(v => v.name + '-' + decodeHtml(v.ruleLabel.description))
    },
    setRuleList: (state, action) => {
      state.ruleList = action.payload
      state.selectedRule = initialState.selectedRule
    },
    setLatestChange: (state, action) => {
      state.latestChange = action.payload
    },
    setLatestChangeLoading: (state, action) => {
      state.latestChangeLoading = action.payload
    },
    setLatestChangeLoaded: (state, action) => {
      state.latestChangeLoaded = action.payload
    },
    setLatestChangeType: (state, action) => {
      state.latestChangeType = action.payload
    },
    handleDomainChange: (state, action) => {
      state.isDirty = true
      if (action.payload.language === 'englishDomain') {
        state.englishDomainWarning = true
        state.updatedEnglishDomainText = action.payload.update
      } else {
        state.spanishDomainWarning = true
        state.updatedSpanishDomainText = action.payload.update
      }
    },
    handleCategoryChange: (state, action) => {
      state.isDirty = true
      if (action.payload.language === 'englishCategory') {
        state.englishCategoryWarning = true
        state.updatedEnglishCategoryText = action.payload.update
      } else {
        state.spanishCategoryWarning = true
        state.updatedSpanishCategoryText = action.payload.update
      }
    },
    handleTypeChange: (state, action) => {
      state.isDirty = true
      if (action.payload.language === 'englishType') {
        state.englishTypeWarning = true
        state.updatedEnglishTypeText = action.payload.update
      } else {
        state.spanishTypeWarning = true
        state.updatedSpanishTypeText = action.payload.update
      }
    },
    handleDescriptionChange: (state, action) => {
      state.isDirty = true
      if (action.payload.language === 'englishDescription') {
        state.englishDescriptionWarning = true
        state.updatedEnglishDescriptionText = action.payload.update
      } else {
        state.spanishDescriptionWarning = true
        state.updatedSpanishDescriptionText = action.payload.update
      }
    },
    updateSelectedRule: (state, action) => {
      state.selectedRule = {
        ...action.payload
      }
      state.isDirty = true
    },
    clearRuleClient: (state, action) => initialState,
    clearTempChanges: (state, action) => {
      state.selectedRule = JSON.parse(JSON.stringify(state.originalSelectedRule))
      state.updatedEnglishDomainText = ''
      state.updatedSpanishDomainText = ''
      state.updatedEnglishCategoryText = ''
      state.updatedSpanishCategoryText = ''
      state.updatedEnglishTypeText = ''
      state.updatedSpanishTypeText = ''
      state.updatedEnglishDescriptionText = ''
      state.updatedSpanishDescriptionText = ''
      state.isDirty = false
    }
  }
})

export const {
  setSelectedRule,
  setRuleList,
  updateSelectedRule,
  handleDomainChange,
  handleCategoryChange,
  handleTypeChange,
  handleDescriptionChange,
  setLatestChange,
  setLatestChangeLoaded,
  setLatestChangeLoading,
  setLatestChangeType,
  clearRuleClient,
  clearTempChanges
} = ruleManagementSlice.actions

export default ruleManagementSlice.reducer
