import { gql } from 'apollo-boost'
import isEmpty from 'lodash/isEmpty'
import client from 'apollo/Apollo'
import { batch } from 'react-redux'
import { openDialog } from 'ducks/dialogSlice'
import {
  clearUnsavedChanges,
  updateOriginalAnswers,
} from 'ducks/unsavedChangesSlice'
import { checkValidation } from 'ducks/validationErrorsSlice'
import {
  setAssignedInterviewer,
  updateClient,
  setInterviewStartAndEnd,
} from 'ducks/clientSlice'
import InterviewListMutation from 'components/interviewList/InterviewListMutations'
import { getLastSavedDate } from 'thunks/getLastSavedDate'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'
import { storeInterviewProgress } from 'ducks/interviewProgressSlice'
import { setRecommendationsLoading } from 'ducks/recommendationsSlice'
import { setSavingQueue, setIsSaving } from 'ducks/interviewSavingQueueSlice'

// Create an answer based on the unsaved data in state
const HANDLE_ANSWER = gql`
  mutation handleAnswer($data: [AnswerCreateInput!], $interviewID: Int) {
    handleAnswer(data: $data, interviewID: $interviewID)
  }
`

const HANDLE_CLIENT = gql`
  mutation handleClient($data: ClientInput!) {
    handleClient(data: $data) {
      id
      firstName
      lastName
      ssn
      birthDate
    }
  }
`

const sendData = ({
  assignedInterviewer,
  currentInterviewStatus,
  unsavedAnswers,
  shouldUpdateClient,
  currentClient,
  disableNotifications,
  recommendationsClicked,
  interviewId,
}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingTimestamp(true))
      const questionnairePromises = []
      // Send the array of answers off to the graphql server to update interview data
      questionnairePromises.push(
        client.mutate({
          mutation: HANDLE_ANSWER,
          variables: {
            data: unsavedAnswers,
            interviewID: interviewId,
          },
        })
      )

      // Update client info since it can be changed from the general info section of the questionnaire if need be
      if (shouldUpdateClient) {
        questionnairePromises.push(
          client.mutate({
            mutation: HANDLE_CLIENT,
            variables: {
              data: {
                id: currentClient.currentClient,
                firstName: currentClient.currentClientFirstName,
                lastName: currentClient.currentClientLastName,
                birthDate: currentClient.currentClientDob,
                ssn: currentClient.currentClientSsn,
              },
            },
          })
        )
      }

      const currentUser = getState().user

      await Promise.all(questionnairePromises)

      // Check if there is no assignee
      if (!assignedInterviewer) {
        // If there is no assigned interviewer, assign to the current user
        await client.mutate({
          mutation: InterviewListMutation.UPDATE_ASSIGNEE,
          variables: {
            interviewID: interviewId,
            userID: currentUser.id,
            assigneeID: currentUser.id,
          },
        })
      }

      // If the interview status is 'new' previous to saving the data, and saving the data
      // Succeeded, we know that the status has changed to in process, and that we are able to
      // set the start and end dates
      const shouldSetStartAndEnd =
        currentInterviewStatus?.toLowerCase() === 'new'

      // On success, do a few things simultaneously..
      batch(() => {
        //If the interview was just assigned, update the assignee in state
        !assignedInterviewer &&
          dispatch(
            setAssignedInterviewer({
              assignedInterviewer: `${currentUser.firstName} ${currentUser.lastName}`,
            })
          )
        shouldUpdateClient && dispatch(updateClient(currentClient))
        recommendationsClicked && dispatch(setRecommendationsLoading(true))
        // Get the last saved date and set it.
        dispatch(getLastSavedDate())
        // If the interview just had its status from new to n process, set the start and end dates
        // Reset interview recommendations section progress
        const currentInterviewProgress = getState().interviewProgress
        const updatedProgress = Object.values(currentInterviewProgress).filter(
          item => item.domain !== 'recommendations'
        )
        dispatch(storeInterviewProgress({ interviewProgress: updatedProgress }))
        // If the interview just had its status from new to in process, set the start and end dates
        shouldSetStartAndEnd && dispatch(setInterviewStartAndEnd())
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      if (!disableNotifications) {
        // If error, show error dialog

        dispatch(
          openDialog({
            type: 'error',
            title: `System Error`,
            props: {
              error,
            },
          })
        )
      }
    }
  }
}

// Sends each payload in the saving queue to the database
const sendDataProcessor = () => {
  return async (dispatch, getState) => {
    dispatch(setIsSaving(true))
    while (getState().interviewSavingQueue.savingQueue.length > 0) {
      const { savingQueue } = getState().interviewSavingQueue
      let payloads = [...savingQueue]
      const lastPayload = payloads.pop()
      await dispatch(sendData(lastPayload))
      dispatch(setSavingQueue(payloads))
    }
    dispatch(setIsSaving(false))
  }
}

export const saveData = ({ disableNotifications, recommendationsClicked }) => {
  return async (dispatch, getState) => {
    // Double check to see if the interview is able to be saved
    const interviewStatus = getState().client.currentInterviewStatus
    console.log(interviewStatus)
    if (
      interviewStatus?.toLowerCase() === 'complete' ||
      interviewStatus?.toLowerCase() === 'cancelled'
    ) {
      return false
    }
    // Check validation -- if not valid, exit immediately
    const isValid = await dispatch(checkValidation(disableNotifications))
    if (!isValid) {
      return false
    }

    // Gather unsaved changes and interview id from state
    const unsavedChanges = getState().unsavedChanges
    const {
      currentInterview,
      currentInterviewStatus,
      assignedInterviewer,
    } = getState().client
    const interviewId = currentInterview
    // If there aren't any unsaved answers, let the user know
    if (isEmpty(unsavedChanges.answers) && !recommendationsClicked) {
      return true
    }

    const unsavedAnswers = []
    // The questions below need to update the client information in the client table as well
    const clientInfoQuestions = {
      first_name: 'currentClientFirstName',
      last_name: 'currentClientLastName',
      dob: 'currentClientDob',
      ssn: 'currentClientSsn',
    }
    let shouldUpdateClient = false
    const currentClient = { ...getState().client }

    for (const questionId in unsavedChanges.answers) {
      // Format the question for saving by appending the interviewId
      const answerData = {
        interviewID: interviewId,
        answerValue: unsavedChanges.answers[questionId],
        questionID: questionId,
      }
      unsavedAnswers.push(answerData)

      // Check to see if this question needs to update the client table
      const containsClientInfo = Object.keys(clientInfoQuestions).includes(
        questionId
      )
      // If so, set the flag to update, and modify the current client state to be updated
      if (containsClientInfo) {
        const clientProperty = clientInfoQuestions[questionId]
        currentClient[clientProperty] =
          unsavedChanges.answers[questionId].answer
        shouldUpdateClient = true
      }
    }

    // Clear out the unsavedChangesSlice to be ready for the next set of changes.
    batch(() => {
      // Reset the unsavedChangesSlice
      dispatch(updateOriginalAnswers())
      // Reset the unsavedChangesSlice
      dispatch(clearUnsavedChanges())
    })

    const { isSaving, savingQueue } = getState().interviewSavingQueue
    const newSavePayload = {
      unsavedAnswers,
      shouldUpdateClient,
      currentClient,
      disableNotifications,
      recommendationsClicked,
      assignedInterviewer,
      currentInterviewStatus,
      interviewId,
    }

    dispatch(setSavingQueue([newSavePayload, ...savingQueue]))

    // If the isSaving flag is not set, start up the processor which
    // will pick off the next item in queue to be sent to the database.
    if (!isSaving) {
      dispatch(sendDataProcessor())
    }

    return true
  }
}
