import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { colors } from '../../app/theme'
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import ClearIcon from '@material-ui/icons/Clear'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { useHistory } from 'react-router-dom'
import store from 'app/store.js'
import { resetNewUser } from 'ducks/adminSlice'
import { resetEntities } from 'ducks/entitiesSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import { 
  handleDomainChange, 
  handleCategoryChange, 
  handleTypeChange, 
  handleDescriptionChange, 
  updateSelectedRule,
  clearTempChanges
} from 'ducks/ruleSlice'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { getTomorrow } from 'utilTools/dateHelper'
import { openDialog } from 'ducks/dialogSlice'
import { handlePendingChange } from 'ducks/pendingChangesSlice'
import { PENDING_CHANGE_TYPES, CHANGE_TYPE_DESCRIPTIONS, RECORD_TYPES } from '../general/Constants'
import { savePendingChanges } from 'thunks/savePendingChanges'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { deleteScheduledChange } from  '../../thunks/deleteSheduledChange'
import { setGlobalIsDirty } from 'ducks/adminSlice'
import { isoToLocalDate } from '../../utilTools/dateHelper'
import { refreshLastUpdateData } from 'thunks/refreshLastUpdateData'


const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-bottom: 10px;
    margin: 0;
  }
`
const RuleTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-bottom: 10px;
    margin-top: -20px;
    font-weight: normal;
  }
`
const CategoryTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
    font-size: small;
  }
`
const Container = styled.div`
  && {
    margin: -5px 0 25px 10px;
    font-size: smaller;
    overflow-wrap: break-word;
    .language {
      font-size: small;
      font-weight: 600;
      border-bottom: 1px solid #dcdcdc;
      margin: 5px 0 10px;
    }

  }
`
const StyledInfoIcon = styled(InfoIcon)`
&& {
  color: ${colors.turquoise};
  margin-top: 10px;
  font-size: large;
  vertical-align: text-bottom;
}
`
const PrimaryButton = styled(Button)`
  && {
    margin: 5px;
    display: inline-block;
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`
const SecondaryButton = styled(Button)`
  && {
    margin: 5px;
    background-color: ${colors.turquoise};
    display: inline-block;
    color: ${colors.white};
  }
`
const StyledTextareaAutosize = styled(TextareaAutosize)`
  && {
    width: 90%;
    &:focus {
      outline: 2px solid ${colors.saffron};
    }
    > input: {
      color: white;
    }
    margin-right: 20px;
    resize: none;
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ChangeWarning = styled(Typography)`
&& {
  font-size: 14px;
  color: ${colors.red};
}
`
const PendingChangesTable = styled.div`
&& {
  padding-top: 20px;
  margin-left: 30px;
  :after {
    content: "";
    display: block;
    width: 87%;
    border-bottom: 1px solid #dcdcdc;
    padding-top: 10px;
  }
  .headerElement {
    font-size: small;
    font-weight: 600;
    margin: 5px 0 10px;
  }
  .tableElement {
    font-size: small;
  }
  .icon {
    cursor: pointer;
  }
}
`

const DeleteChangeDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}
const SuccessDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}

const WarningDialog = props => {
  const { open, title, body, onClose, onCancel } = props
  const handleConfirm = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  const handleCancel = () => {
    onCancel()
  }
  return (
    <>
      <Dialog onCancel={handleCancel} onClose={handleConfirm} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleConfirm()}>
            Leave
          </PrimaryButton>
          <SecondaryButton variant='contained' onClick={() => handleCancel()}>
            Cancel
          </SecondaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}

const RuleDetails = () => {
  const [editDomain, setEditDomain] = useState(false)
  const [editCategory, setEditCategory] = useState(false)
  const [editType, setEditType] = useState(false)
  const [editRecommendation, setEditRecommendation] = useState(false)
  const englishDomainWarning = useSelector(state => state.ruleManagement.englishDomainWarning)
  const spanishDomainWarning = useSelector(state => state.ruleManagement.spanishDomainWarning)
  const englishCategoryWarning = useSelector(state => state.ruleManagement.englishCategoryWarning)
  const spanishCategoryWarning = useSelector(state => state.ruleManagement.spanishCategoryWarning)
  const englishTypeWarning = useSelector(state => state.ruleManagement.englishTypeWarning)
  const spanishTypeWarning = useSelector(state => state.ruleManagement.spanishTypeWarning)
  const englishDescriptionWarning = useSelector(state => state.ruleManagement.englishDescriptionWarning)
  const spanishDescriptionWarning = useSelector(state => state.ruleManagement.spanishDescriptionWarning)
  const warningCollection = {englishDomainWarning, spanishDomainWarning, englishCategoryWarning, spanishCategoryWarning, englishTypeWarning, spanishTypeWarning, englishDescriptionWarning, spanishDescriptionWarning}
  const [showSuccess, setSuccess] = useState(false)
  const [showWarning, setWarning] = useState(false)
  const currentPendingChanges = useSelector(state => state.pendingChanges.currentPendingChanges)
  const upcomingChangeDate = useSelector(state => state.pendingChanges.upcomingChangeDate.upcomingChangeDate)
  const { 
    selectedRule, 
    isDirty, 
    selectedRuleSharesLabelWith, 
    selectedRuleSharesCategoryWith, 
    selectedRuleSharesDomainWith,
    updatedEnglishDomainText,
    updatedSpanishDomainText,
    updatedEnglishCategoryText,
    updatedSpanishCategoryText,
    updatedEnglishTypeText,
    updatedSpanishTypeText,
    updatedEnglishDescriptionText,
    updatedSpanishDescriptionText,
    latestChange,
    latestChangeLoading,
    latestChangeLoaded,
  } = useSelector(state => state.ruleManagement)
  const [showDeleteDialog, setDeleteDialog] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const currentlyScheduledChangesForRule = currentPendingChanges.filter(x => x.recordId === selectedRule.id)
  const recordID = selectedRule.id
  const recordType = RECORD_TYPES.RULE

  useEffect(() => {
    if(recordID)
      dispatch(refreshLastUpdateData({ recordType, recordID }))
  }, [recordID, recordType, dispatch])



  const handleExit = () => {
    if (!isDirty) {
      handleConfirm(true)
    }
    else {
      setWarning(true)
    }
  }

  const redirect = path => {
    if (history.location.pathname !== path) {
      store.dispatch(resetNewUser())
      store.dispatch(resetEntities())
      store.dispatch(removeAllValidationErrors())
      history.push(path)
    }
  }

if (!selectedRule.domainCode) {
  redirect('bre_rule_list')
}

// const latestChange = useSelector(state => state.ruleManagement.latestChange)

const gatherPendingChanges = (effectiveDate) => {
  if (updatedEnglishDomainText) {
  let domainUpdates = {'English' : updatedEnglishDomainText}
    dispatch(
      handlePendingChange({
        recordId: selectedRule.id,
        changeType: PENDING_CHANGE_TYPES.updateRuleDomain,
        publishDate: effectiveDate,
        changeDetails: domainUpdates

      })
    )
  }
  if (updatedSpanishDomainText) {
    let domainUpdates = {'Spanish' : updatedSpanishDomainText}
      dispatch(
        handlePendingChange({
          recordId: selectedRule.id,
          changeType: PENDING_CHANGE_TYPES.updateRuleDomain,
          publishDate: effectiveDate,
          changeDetails: domainUpdates
  
        })
      )
    }
  if (updatedEnglishCategoryText) {
  let categoryUpdates = {'English' : updatedEnglishCategoryText}
    dispatch(
      handlePendingChange({
        recordId: selectedRule.id,
        changeType: PENDING_CHANGE_TYPES.updateRuleCategory,
        publishDate: effectiveDate,
        changeDetails: categoryUpdates
      })
    )
  }
  if (updatedSpanishCategoryText) {
    let categoryUpdates = {'Spanish' : updatedSpanishCategoryText}
      dispatch(
        handlePendingChange({
          recordId: selectedRule.id,
          changeType: PENDING_CHANGE_TYPES.updateRuleCategory,
          publishDate: effectiveDate,
          changeDetails: categoryUpdates
        })
      )
    }
  if (updatedEnglishTypeText) {
  let typeUpdates = {'English' : updatedEnglishTypeText}
    dispatch(
      handlePendingChange({
        recordId: selectedRule.id,
        changeType: PENDING_CHANGE_TYPES.updateRuleType,
        publishDate: effectiveDate,
        changeDetails: typeUpdates
      })
    )
  }
  if (updatedSpanishTypeText) {
    let typeUpdates = {'Spanish' : updatedSpanishTypeText}
      dispatch(
        handlePendingChange({
          recordId: selectedRule.id,
          changeType: PENDING_CHANGE_TYPES.updateRuleType,
          publishDate: effectiveDate,
          changeDetails: typeUpdates
        })
      )
    }
  if (updatedEnglishDescriptionText) {
    let descriptionUpdates = {'English' : updatedEnglishDescriptionText}
      dispatch(
        handlePendingChange({
          recordId: selectedRule.id,
          changeType: PENDING_CHANGE_TYPES.updateRuleDescription,
          publishDate: effectiveDate,
          changeDetails: descriptionUpdates
        })
      )
    }
    if (updatedSpanishDescriptionText) {
      let descriptionUpdates = {'Spanish' : updatedSpanishDescriptionText}
        dispatch(
          handlePendingChange({
            recordId: selectedRule.id,
            changeType: PENDING_CHANGE_TYPES.updateRuleDescription,
            publishDate: effectiveDate,
            changeDetails: descriptionUpdates
          })
        )
      }
  dispatch(savePendingChanges())
    .then(() => dispatch(refreshLastUpdateData({ recordType, recordID })))
    .then(() => setSuccess(true))
}

const handleSave = (value) => {
  const formatTomorrow = getTomorrow()
  let existingPendingChangeDate = ''

  if(currentPendingChanges.length !== 0) {
    existingPendingChangeDate = upcomingChangeDate
  }
  
  let defaultValue = formatTomorrow
  let isDisabled = false

  if (currentPendingChanges.length > 0) {
    defaultValue = existingPendingChangeDate
    isDisabled = true
  }
  let dialogDesc = 'Please choose an effective date:'

    if (existingPendingChangeDate) {
      dialogDesc = 'There are current pending changes scheduled. This update will occur along with the other scheduled updates.'
      //this seems to always show the day before the existingPendingChangeDate in the datepicker...may need to address
      defaultValue = existingPendingChangeDate
      isDisabled = true
    }

  dispatch(
  openDialog({
    type: 'dialogDatePicker',
    title: 'Pending Change Effective Date',
    description: dialogDesc,
    props: {
      label: 'Effective Date',
      field: 'effectiveDate',
      minDate: formatTomorrow,
      defaultValue: defaultValue,
      isDisabled: isDisabled,
      action: (value) => gatherPendingChanges(value)
    }
  })
  )
}

  const handleConfirm = (performRedirect) => {
    setEditCategory(false)
    setEditDomain(false)
    setEditRecommendation(false)
    setEditType(false)
    setSuccess(false)
    setWarning(false)
    setDeleteDialog(false)
    dispatch(clearTempChanges())
    dispatch(setGlobalIsDirty(false))
    if(performRedirect) {
      redirect('bre_rule_list')
    }
  }

  const handleCancel = () => {
    setWarning(false)
  }

  const propChangeScheduled = (fieldName) => {
    const splitFieldName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")
    const language = splitFieldName[0].toUpperCase()
    const entity = splitFieldName[1].toUpperCase()
    const testForMatch = currentlyScheduledChangesForRule.filter(x => x.changeType.includes(entity) && Object.keys(x.changeDetails)[0].toUpperCase() === language)
    if(testForMatch.length > 0) {
      const affectedEntity = fieldName + 'Warning'
      if(!warningCollection[affectedEntity]) {
      dispatch(
        openDialog({
          type: 'information',
          title: 'Warning',
          description: `There is a change currently scheduled for this rule property. Proceeding with this change will overwrite the currently scheduled change.`
        })
      )
    }
    }
  }

  const handleTextChange = (updatedText, fieldName) => {
      store.dispatch(setGlobalIsDirty(true))
      propChangeScheduled(fieldName)
    switch (fieldName) {
      case 'englishDomain':
        dispatch(
          handleDomainChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            ruleDomain: {
              ...selectedRule.ruleDomain,
              description: updatedText,
            }
          })
        )
        break
        case 'spanishDomain':
        propChangeScheduled(fieldName)
        dispatch(
            handleDomainChange({language: fieldName, update: updatedText}),
          )
          dispatch(
            updateSelectedRule({
              ...selectedRule,
              ruleDomain: {
                ...selectedRule.ruleDomain,
                spanishDescription: updatedText,
              }
            })
          )
          break
        case 'englishCategory':
        propChangeScheduled(fieldName)
        dispatch(
            handleCategoryChange({language: fieldName, update: updatedText}),
          )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            ruleCategory: {
              ...selectedRule.ruleCategory,
              description: updatedText,
            }
          })
        )
        break
        case 'spanishCategory':
        propChangeScheduled(fieldName)
        dispatch(
          handleCategoryChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            ruleCategory: {
              ...selectedRule.ruleCategory,
              spanishDescription: updatedText,
            }
          })
        )
        break
      case 'englishType':
        propChangeScheduled(fieldName)
        dispatch(
          handleTypeChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            ruleLabel: {
              ...selectedRule.ruleLabel,
              description: updatedText,
            },
          })
        )
        break
      case 'spanishType':
        propChangeScheduled(fieldName)
        dispatch(
          handleTypeChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            ruleLabel: {
              ...selectedRule.ruleLabel,
              spanishDescription: updatedText,
            },
          })
        )
        break
      case 'englishDescription':
        propChangeScheduled(fieldName)
        dispatch(
          handleDescriptionChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            englishRecommendationText: updatedText,
          })
        )
        break
      case 'spanishDescription':
        propChangeScheduled(fieldName)
        dispatch(
          handleDescriptionChange({language: fieldName, update: updatedText}),
        )
        dispatch(
          updateSelectedRule({
            ...selectedRule,
            spanishRecommendationText: updatedText,
          })
        )
        break
      default:
        break
    }
  }

  const customStyle = {
    'textAlign': 'right',
    'marginRight': '10px'
  }

  const deleteChange = (change) => {
    dispatch(deleteScheduledChange(change))
    .then(() => dispatch(refreshLastUpdateData({ recordType, recordID })))
    .then(() => setDeleteDialog(true))
  }
  const handleDelete = (change) => {
    const changeDescription = CHANGE_TYPE_DESCRIPTIONS[change.changeType]
    const scheduledChange = decodeHtml(Object.values(change.changeDetails)[0])
    const publishDate = change.publishDate
    dispatch(
      openDialog({
        type: 'confirmation',
        title: 'Please confirm',
        description: `Click OK to delete the '${changeDescription}' scheduled for ${publishDate}: ${scheduledChange}`,
        props: {
          action: () => deleteChange(change)
        }
      })
    )
  }

  const buildPendingChangeTable = entity => {
    const entityChanges = currentlyScheduledChangesForRule.filter(x => x.changeType.includes(entity.toUpperCase()))
    
    if(!entityChanges.length > 0) {
      return
    }

    const englishChange = entityChanges.find(x => Object.keys(x.changeDetails)[0] === 'English')
    const spanishChange = entityChanges.find(x => Object.keys(x.changeDetails)[0] === 'Spanish')
    return (
      <PendingChangesTable>
        <Grid item xs={12}><CategoryTitle variant='h6'>Pending Changes</CategoryTitle></Grid>
        <Grid container>
          <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='headerElement'>Cancel</Typography></Grid>
          <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='headerElement'>Effective Date</Typography></Grid>
          <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='headerElement'>Language</Typography></Grid>
          <Grid item xs={4}><Typography variant='body1' className='headerElement'>Updated Text</Typography></Grid>
          <Grid item xs={2} style={{'textAlign': 'center '}}><Typography variant='body1' className='headerElement'>Updated By</Typography></Grid>
          <Grid item xs={2} style={{'textAlign': 'center '}}><Typography variant='body1' className='headerElement'>Date Updated</Typography></Grid>
        </Grid>
        {englishChange && (
          <Grid container>
            <Grid item xs={1} style={{'textAlign': 'center '}}><DeleteOutlineIcon className='icon' onClick={() => {handleDelete(englishChange)}} /></Grid>
            <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='tableElement'>{entityChanges[0].publishDate}</Typography></Grid>
            <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='tableElement'>English</Typography></Grid>
            <Grid item xs={4}>
              <Typography variant='body1' className='tableElement'>
                  {decodeHtml(Object.values(englishChange.changeDetails)[0])}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{'textAlign': 'center '}}>
              <Typography variant='body1' className='tableElement'>{englishChange.createUser.firstName + ' ' + englishChange.createUser.lastName}</Typography>
            </Grid>
            <Grid item xs={2} style={{'textAlign': 'center '}}>
              <Typography variant='body1' className='tableElement'>{isoToLocalDate(englishChange.createdAt)}</Typography>
            </Grid>
          </Grid>
        )}
          {spanishChange && (
          <Grid container>
            <Grid item xs={1} style={{'textAlign': 'center '}}><DeleteOutlineIcon className='icon' onClick={() => {handleDelete(spanishChange)}} /></Grid>
            <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='tableElement'>{entityChanges[0].publishDate}</Typography></Grid>
            <Grid item xs={1} style={{'textAlign': 'center '}}><Typography variant='body1' className='tableElement'>Spanish</Typography></Grid>
            <Grid item xs={4}>
              <Typography variant='body1' className='tableElement'>
                  {decodeHtml(Object.values(spanishChange.changeDetails)[0])}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{'textAlign': 'center '}}>
              <Typography variant='body1' className='tableElement'>{spanishChange.createUser.firstName + ' ' + spanishChange.createUser.lastName}</Typography>
            </Grid>
            <Grid item xs={2} style={{'textAlign': 'center '}}>
              <Typography variant='body1' className='tableElement'>{isoToLocalDate(spanishChange.createdAt)}</Typography>
            </Grid>
          </Grid>
        )}
      </PendingChangesTable>
    )
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#eeeeee',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      border: '1px solid #000000',
    },
  }))(Tooltip);
  
  const customToolTip = (tipContent, entity) => {
    const list = []
    tipContent.forEach((rule) => {
      list.push(<li style={{'list-style-type': 'none'}}>{rule}</li>)
    })
    return (
      <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit"><b>Rules Sharing This {entity}</b></Typography>
          <Typography color="inherit">
            {list}
          </Typography>
          
        </React.Fragment>
      }
    >
      <StyledInfoIcon />
    </HtmlTooltip>
    )
  }

  let changeAffectingDomain = ''
  let changeAffectingCategory = ''
  let changeAffectingType = ''

  if(currentPendingChanges.length > 0) {
    const currentCategoryChanges = currentPendingChanges.filter(x => x.changeType === PENDING_CHANGE_TYPES.updateRuleCategory)
    const sharedCategoryRuleNames = []
    selectedRuleSharesCategoryWith.forEach(rule => {
      let name = parseInt(rule.substring(0, rule.indexOf('-')))
      sharedCategoryRuleNames.push(name)
    })
    changeAffectingCategory = currentCategoryChanges.find(x => sharedCategoryRuleNames.includes(x.rule.ruleJSON.name))

    const currentDomainChanges = currentPendingChanges.filter(x => x.changeType === PENDING_CHANGE_TYPES.updateRuleDomain)
    const sharedDomainRuleNames = []
    selectedRuleSharesDomainWith.forEach(rule => {
      let name = parseInt(rule.substring(0, rule.indexOf('-')))
      sharedDomainRuleNames.push(name)
    })
    changeAffectingDomain = currentDomainChanges.find(x => sharedDomainRuleNames.includes(x.rule.ruleJSON.name))

    const currentTypehanges = currentPendingChanges.filter(x => x.changeType === PENDING_CHANGE_TYPES.updateRuleType)
    const sharedTypeRuleNames = []
    selectedRuleSharesLabelWith.forEach(rule => {
      let name = parseInt(rule.substring(0, rule.indexOf('-')))
      sharedTypeRuleNames.push(name)
    })
    changeAffectingType = currentTypehanges.find(x => sharedTypeRuleNames.includes(x.rule.ruleJSON.name))
  }

  return (
    <>
    <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle variant='h4'>Manage Rule Descriptions</SectionTitle>
        </Grid>
        <Grid item xs={5}>
          <RuleTitle variant='h5'>
            Rule {selectedRule.name}: {decodeHtml(selectedRule.ruleLabel.description)}
          </RuleTitle>
        </Grid>
        <Grid item xs={2} style={{'paddingTop':'20px'}}>
        <Typography variant='body1'><b>Status:</b> {selectedRule.disabled ? 'Disabled' : 'Enabled'}</Typography>
        </Grid>
        <Grid item xs={2} style={{'paddingTop':'20px'}}>
          <Typography variant='body1' style={customStyle}><b>Pending Action(s):</b></Typography>
        </Grid>
        <Grid item xs={3} style={{'paddingTop':'20px'}}>
            {currentlyScheduledChangesForRule.length > 0 ?  
            <Typography variant='body1'>Yes</Typography> 
            : 
            <Typography variant='body1'>No</Typography>}
        </Grid>
        <Grid item xs={8}></Grid>
        { !latestChangeLoading && latestChangeLoaded
          ? <Grid item xs={4} style={{ 'paddingTop':'10px' }}>Last updated by <b>{latestChange.createdBy}</b> on <b>{isoToLocalDate(latestChange.createdAt)}</b></Grid>
          : <Grid item xs={4} style={{ 'paddingTop':'10px' }}>Loading...</Grid>
        }
        <Grid item xs={12}>
        {editDomain && selectedRuleSharesDomainWith.length > 0 ? (
          <CategoryTitle variant='h6'>Domain 
            <div style={{color: "red"}}>
              Warning! This rule shares a domain with {selectedRuleSharesDomainWith.length} rule(s).
              {customToolTip(selectedRuleSharesDomainWith, 'Domain')}
            </div>
          </CategoryTitle>)
          :
          (<CategoryTitle variant='h6'>Domain</CategoryTitle>)}
          <Container>
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography variant='body1' className='language'>
                  English
                </Typography>
                {editDomain? (
                  <StyledTextareaAutosize
                    aria-label='englishDomain'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleDomain.description)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'englishDomain')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleDomain.description)
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant='body1' className='language'>
                  Spanish
                </Typography>
                {editDomain? (
                  <StyledTextareaAutosize
                    aria-label='spanishDomain'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleDomain.spanishDescription)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'spanishDomain')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleDomain.spanishDescription)
                )}
              </Grid>
              <Grid item xs={1}>
                {changeAffectingDomain ? (
                  <IconButton
                    disabled>
                    <EditIcon />
                  </IconButton>
                ):(
                  <IconButton
                    aria-label='Edit'
                    onClick={() => setEditDomain(!editDomain)}
                  >
                    {editDomain ? <ClearIcon /> : <EditIcon />}
                </IconButton>
                )}
                
              </Grid>
            </Grid>
            {changeAffectingDomain ? (
              <ChangeWarning>
                There is a current pending change for <strong>{changeAffectingDomain.rule.description}</strong> that will also affect this domain. Please go to View Pending Changes to review.
              </ChangeWarning>
            ) : null}
            {buildPendingChangeTable('domain')}
          </Container>
        </Grid>
        <Grid item xs={12}>
      
        {editCategory && selectedRuleSharesCategoryWith.length > 0 ? (
          <CategoryTitle variant='h6'>Category 
          <div style={{color: "red"}}>
            Warning! This rule shares a category with {selectedRuleSharesCategoryWith.length} rule(s).
            {customToolTip(selectedRuleSharesCategoryWith, 'Category')}
          </div>
          </CategoryTitle>)
          :
          (<CategoryTitle variant='h6'>Category</CategoryTitle>)}
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body1' className='language'>
                  English
                </Typography>
                {editCategory? (
                  <StyledTextareaAutosize
                    aria-label='englishCategory'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleCategory.description)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'englishCategory')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleCategory.description)
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant='body1' className='language'>
                  Spanish
                </Typography>
                {editCategory? (
                  <StyledTextareaAutosize
                    aria-label='spanishCategory'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleCategory.spanishDescription)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'spanishCategory')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleCategory.spanishDescription)
                )}
              </Grid>
              <Grid item xs={1}>
              {changeAffectingCategory ? <EditIcon style={{'opacity':'0.3'}}/> 
              :
                <IconButton
                  aria-label='Edit'
                  onClick={() => setEditCategory(!editCategory)}
                >
                  {editCategory ? <ClearIcon /> : <EditIcon />}
                </IconButton>
            }
              </Grid>
            </Grid>
            {changeAffectingCategory ? (
              <ChangeWarning>
                There is a current pending change for <strong>{changeAffectingCategory.rule.description}</strong> that will also affect this category. Please go to View Pending Changes to review.
              </ChangeWarning>
            ) : null}
            {buildPendingChangeTable('category')}
          </Container>
        </Grid>
        <Grid item xs={12}>
        {editType && selectedRuleSharesLabelWith.length > 0 ? (
          <CategoryTitle variant='h6'>Type 
            <div style={{color: "red"}}>
              Warning! This rule shares a type with {selectedRuleSharesLabelWith.length} rule(s).
              {customToolTip(selectedRuleSharesLabelWith, 'Type')}
            </div>
          </CategoryTitle>)
          :
          (<CategoryTitle variant='h6'>Type</CategoryTitle>)}

          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body1' className='language'>
                  English
                </Typography>
                {editType ? (
                  <StyledTextareaAutosize
                    aria-label='englishType'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleLabel.description)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'englishType')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleLabel.description)
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant='body1' className='language'>
                  Spanish
                </Typography>
                {editType ? (
                  <StyledTextareaAutosize
                    aria-label='spanishType'
                    minRows={4}
                    value={decodeHtml(selectedRule.ruleLabel.spanishDescription)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'spanishType')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.ruleLabel.spanishDescription)
                )}
              </Grid>
              <Grid item xs={1}>
              {changeAffectingType ? (
                  <IconButton
                    disabled>
                    <EditIcon />
                  </IconButton>
                ):(
                <IconButton
                  aria-label='Edit'
                  onClick={() => setEditType(!editType)}
                >
                  {editType ? <ClearIcon /> : <EditIcon />}
                </IconButton>
                )}
              </Grid>
            </Grid>
            {changeAffectingType ? (
              <ChangeWarning>
                There is a current pending change for <strong>{changeAffectingType.rule.description}</strong> that will also affect this type. Please go to View Pending Changes to review.
              </ChangeWarning>
            ) : null}
            {buildPendingChangeTable('type')}
          </Container>
        </Grid>
        <Grid item xs={12}>
          <CategoryTitle variant='h6'>Recommendation</CategoryTitle>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='body1' className='language'>
                  English
                </Typography>
                {editRecommendation ? (
                  <StyledTextareaAutosize
                    aria-label='englishDescription'
                    minRows={4}
                    value={decodeHtml(selectedRule.englishRecommendationText)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'englishDescription')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.englishRecommendationText)
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant='body1' className='language'>
                  Spanish
                </Typography>
                {editRecommendation ? (
                  <StyledTextareaAutosize
                    aria-label='spanishDescription'
                    minRows={4}
                    value={decodeHtml(selectedRule.spanishRecommendationText)}
                    onChange={e =>
                      handleTextChange(e.target.value, 'spanishDescription')
                    }
                  />
                ) : (
                  decodeHtml(selectedRule.spanishRecommendationText)
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label='Edit'
                  onClick={() => setEditRecommendation(!editRecommendation)}
                >
                  {editRecommendation ? <ClearIcon /> : <EditIcon />}
                </IconButton>
              </Grid>
            </Grid>
            {buildPendingChangeTable('description')}
          </Container>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <PrimaryButton
            variant='contained'
            onClick={() => {
              handleSave()
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            variant='contained'
            onClick={() => {
              handleExit()
            }}
          >
            Exit
          </SecondaryButton>
        </Grid>
      </Grid>
      <DeleteChangeDialog
          open={showDeleteDialog}
          onClose={() => handleConfirm(false)}
          title='Pending Change Deleted Successfully'
          body='Click OK to continue.'
        />
      <SuccessDialog
        open={showSuccess}
        onClose={() => handleConfirm(false)}
        title='Rule Change Successfully Scheduled'
        body='Click OK to continue.'
      />
       <WarningDialog
        open={showWarning}
        onClose={() => handleConfirm(true)}
        onCancel={handleCancel}
        body='You have unsaved changes on this page that will be lost if you
        continue.'
      />
    </>
  )
}

export default RuleDetails
