import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'current_housing_situation' &&
    answer &&
    Array.isArray(answer) &&
    !answer?.map(option => option.toUpperCase()).includes('OT')
  ) {
    effectCollector.add('other_housing_challenge', null)
  }
  return effectCollector.effects
}

export default effects