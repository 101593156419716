import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import LinkButton from 'components/general/LinkButton'
import { discardChangesAndNavigate } from 'thunks/discardChangesAndNavigate'

const StyledLink = styled(LinkButton)`
  && {
    display: inline-block;
    a {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
`

const ExitButton = React.memo(({ label }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(discardChangesAndNavigate('/interview_list', history))
  }
  return (
    <StyledLink
      description={label}
      isButton
      route={'/interview_list'}
      defaultAction={handleClick}
    />
  )
})

export default ExitButton
