import gql from 'graphql-tag'
const resourceQueries = {
  UPDATE_RESOURCE: gql`
    mutation updateResource($resource: ResourceInput) {
      updateResource(resource: $resource)
    }
  `,
  CREATE_RESOURCE: gql`
    mutation createResource($resource: ResourceInput) {
      createResource(resource: $resource) {
        id
        address
        name
        phone
        url
        description
      }
    }
  `,
  DELETE_RESOURCE: gql`
    mutation deleteResource($resourceID: Int!) {
      deleteResource(resourceID: $resourceID)
    }
  `,
  GET_RESOURCE_TYPES_QUERY: gql`
    {
      resourceTypes {
        id
        name
      }
    }
  `,
  GET_RESOURCE_TYPE_QUERY: gql`
    query resourceType($resourceTypeId: Int) {
      resourceTypeById(id: $resourceTypeId) {
        id
        name
        resources {
          id
          name
          address
          url
          description
          phone
          county {
            id
          }
          type {
            id
          }
        }
      }
    }
  `,
  GET_RESOURCES_BY_COUNTY_ID: gql`
    query resources($countyId: Int) {
      resources(countyId: $countyId) {
        id
        name
        address
        phone
        url
        description
        typeID
      }
    }
  `,
}
export default resourceQueries;