import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { setNewUser } from 'ducks/adminSlice'
import { colors } from 'app/theme'

// All the important tags are to keep it consistent with the
// AdminSelect component. Doesn't properly override base styling
// otherwise.
const StyledSelect = styled(Select)`
  margin-top: 20px;
  margin-right: 20px;
  width: ${p => p.width};
  .react-select-table__control {
    height: 56px;
    border-color: rgba(0, 0, 0, 0.54) !important;
  }
  .react-select-table__control--is-focused {
    border-color: ${colors.calsawsBlue} !important;
    box-shadow: 0 0 0 1px ${colors.calsawsBlue};
  }
  .react-select-table__indicator {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .react-select-table__indicator-separator {
    background-color: rgba(0, 0, 0, 0.54) !important;
  }
  .react-select-table__placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
`

// The react-select library uses Portals to be able to attach the
// menu to an element. Attaching the element directly to the select
// in our case, places the menu within the table for undesired results.
// Using this div allows the menu to be placed in any spot chosem.
export const StyledSelectPortal = styled.div`
  .react-select-table__option--is-focused {
    background-color: rgba(255, 255, 255, 1);
    outline: 1px solid ${colors.saffron};
  }
  .react-select-table__option--is-selected {
    outline: 1px solid ${colors.saffron};
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
  }
  .react-select-table__indicator {
    color: rgba(0, 0, 0, 0.54);
  }
  .react-select-table__value-container--has-value {
    color: rgba(0, 0, 0, 0.87);
  }
  .react-select-table__value-container {
    color: rgba(0, 0, 0, 0.54);
  }
`

const InputContainer = styled.div`
  margin-left: 8px;
  display: inline-block;
`

const AdminTableSelect = React.memo(
  ({ field, width = '250px', index, options, portalID, selectOpts = {} }) => {
    const dispatch = useDispatch()
    const answer = useSelector(state => state.admin[field])
    const handleChange = selectedOption => {
      dispatch(
        setNewUser({ field: field, index, value: selectedOption, table: true })
      )
    }

    return (
      <InputContainer>
        <StyledSelect
          aria-label={`Admin table dropdown ${index}`}
          {...selectOpts}
          menuPortalTarget={document.getElementById(portalID)}
          className='react-select-table-container'
          classNamePrefix='react-select-table'
          width={width}
          value={answer[index]}
          onChange={handleChange}
          options={options}
        />
      </InputContainer>
    )
  }
)

export default AdminTableSelect
