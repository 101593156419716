import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const finalThoughtsConcernsSelector = state =>
  state.interview.final_thoughts_concerns.answer

  const finalThoughtsConcernsPreviewSelector = state =>
  state.preview.final_thoughts_concerns.answer

const closingQuestionSelector = createSelector(
  finalThoughtsConcernsSelector,
  answer => (answer && answer.toUpperCase() === 'NO') || !answer
)

const closingQuestionPreviewSelector = createSelector(
  finalThoughtsConcernsPreviewSelector,
  answer => (answer && answer.toUpperCase() === 'NO') || !answer
)

const ClosingQuestion = React.memo(({ isPreview }) => {
  const closingQuestionIsNo = useSelector(isPreview ? closingQuestionPreviewSelector : closingQuestionSelector)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Closing Question'
          domain='Final Thoughts'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select question_id='final_thoughts_concerns' isPreview={isPreview} />
          <Text
            isDisabled={closingQuestionIsNo}
            question_id='what_would_that_be'
            multiline
            rows='4'
            subquestion
            shouldDecode={true}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default ClosingQuestion
