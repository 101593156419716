import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import isEmpty from 'lodash/isEmpty'
import { openDialog } from 'ducks/dialogSlice'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'
import { clearQuestionClient } from 'ducks/questionSlice'
import { clearRuleClient } from 'ducks/ruleSlice'
import { clearPendingChanges } from 'ducks/pendingChangesSlice'
import { refreshPendingChanges } from './refreshPendingChanges'


const UPDATE_PUBLISH_DATE = gql`
  mutation updatePublishDate($date: DateTime!) {
    updatePublishDate(date: $date)
  }
`
export const updatePublishDate = (value) => {
  return async (dispatch, getState) => {

    if (isEmpty(value)) {
      return
    }

  dispatch(clearQuestionClient())
  dispatch(clearRuleClient())
  dispatch(clearPendingChanges())
    
    try {
      dispatch(setLoadingTimestamp(true))
      await client.mutate({
        mutation: UPDATE_PUBLISH_DATE,
        variables: {
          date: value,
      },
     })
     dispatch(refreshPendingChanges())
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(setLoadingTimestamp(false))
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
