import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()

  if (
    question_id === 'other_safety_concerns' &&
    (answer?.toUpperCase() === 'NO' || answer === null)
  ) {
    effectCollector.add('desire_information_safety', null)
    effectCollector.add('current_safety_plan', null)
    effectCollector.add('interest_in_safety_plan', null)
  }
  if (
    question_id === 'current_safety_plan' &&
    (answer?.toUpperCase() === 'YS' || answer === null)
  ) {
    effectCollector.add('interest_in_safety_plan', null)
  }

  return effectCollector.effects
}

export default effects;