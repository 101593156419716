import React from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const otherSafetyConcernsSelector = state =>
  state.interview.other_safety_concerns.answer

const previewOtherSafetyConcernsSelector = state =>
  state.preview.other_safety_concerns.answer

const currentSafetyPlanSelector = state =>
  state.interview.current_safety_plan.answer

const previewCurrentSafetyPlanSelector = state =>
  state.preview.current_safety_plan.answer

const selectOtherSafetyConcerns = createSelector(
  otherSafetyConcernsSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)
const selectPreviewOtherSafetyConcerns = createSelector(
  previewOtherSafetyConcernsSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)
const selectCurrentSafetyPlan = createSelector(
  currentSafetyPlanSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)
const selectPreviewCurrentSafetyPlan = createSelector(
  previewCurrentSafetyPlanSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)

const SafetyConcernsVictim = React.memo(({ isPreview }) => {
  const otherSafetyConcerns = useSelector(isPreview ? selectPreviewOtherSafetyConcerns : selectOtherSafetyConcerns)
  const currentSafetyPlan = useSelector(isPreview ? selectPreviewCurrentSafetyPlan : selectCurrentSafetyPlan)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Safety Concerns'
          domain='Domestic Abuse'
        />
        <form>
          <Select question_id='other_safety_concerns' isPreview={isPreview} />
          <Select
            question_id='desire_information_safety'
            isDisabled={otherSafetyConcerns}
            subquestion
            isPreview={isPreview}
          />
          <Select
            question_id='current_safety_plan'
            isDisabled={otherSafetyConcerns}
            subquestion
            isPreview={isPreview}
          />
          <Select
            question_id='interest_in_safety_plan'
            isDisabled={currentSafetyPlan}
            subquestion
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default SafetyConcernsVictim
