import React from 'react'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Text from 'components/general/Text'
import Select from 'components/general/Select'
import Dropdown from 'components/general/Dropdown'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableRowDescription from 'components/general/TableRowDescription'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const currentlyGettingHelpStatusSelector = state =>
  state.interview.currently_getting_help_status.answer

const previewCurrentlyGettingHelpStatusSelector = state =>
  state.preview.currently_getting_help_status.answer

const selectCurrentlyGettingHelpStatus = createSelector(
  currentlyGettingHelpStatusSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewCurrentlyGettingHelpStatus = createSelector(
  previewCurrentlyGettingHelpStatusSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const tableQuestions = [
  'depressed_status',
  'fearful_status',
  'worried_status',
  'nervous_anxious_status',
  'concerned_status',
  'tired_status',
  'not_motivated_status',
  'scared_status',
  'angry_status',
]

const qIds = [
  'frightening_violent_event_status',
  'self_harm_status',
  'harm_others_status',
  'trouble_sleeping_status',
  'currently_getting_help_status',
]
const CurrentStatus = React.memo(({ isPreview }) => {
  const currentlyGettingHelpStatusIsChecked = useSelector(
    isPreview ? selectPreviewCurrentlyGettingHelpStatus : selectCurrentlyGettingHelpStatus
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Current Status'
          domain='Emotional and Mental Health'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Read each item aloud. Read response categories as
          needed.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Now I would like to talk a little about your emotional and mental
          health. I would like to know during the last 30 days, about how often
          did you feel…
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CustomTableContainer component={Paper}>
            <Table>
              <TableBody>
                {tableQuestions.map((question, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={question}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Dropdown
                          isTable
                          disableLabel
                          question_id={question}
                          disableDescription={true}
                          isPreview={isPreview}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
          {qIds.map(id => (
            <Select key={id} question_id={id} isPreview={isPreview} />
          ))}
          <Text
            question_id='emotional_mental_health_specify'
            isDisabled={!currentlyGettingHelpStatusIsChecked}
            shouldDecode={true}
            subquestion
            rows='4'
            multiline
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default CurrentStatus
