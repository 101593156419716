import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'

import InfiniteText from 'components/table/InfiniteText'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteSelect from 'components/table/InfiniteSelect'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const StyledSubHeader = styled(Typography)`
  margin: 10px 0;
`

const StyledTable = styled(Table)`
  && {
    margin: 15px 0;
  }
`
const StyledRow = styled(TableRow)`
  && {
    height: 54px;
  }
`

const ChildStatusRows = ({ index, isPreview }) => {
  return (
    <StyledRow>
      <InfiniteText
        question_id='child_care_status_table'
        sub_question_id='childcare_name'
        isDisabled={true}
        hideLabel={false}
        index={index}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteText
        question_id='child_care_status_table'
        sub_question_id='childcare_relationship'
        hideLabel={false}
        isDisabled={true}
        index={index}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteText
        question_id='child_care_status_table'
        sub_question_id='childcare_age'
        isDisabled={true}
        hideLabel={false}
        index={index}
        isPreview={isPreview}
      />
      <InfiniteSelect
        question_id='child_care_status_table'
        sub_question_id='childcare_in_school'
        hideLabel={false}
        index={index}
        isPreview={isPreview}
      />
      <InfiniteSelect
        question_id='child_care_status_table'
        sub_question_id='childcare_has_provider'
        hideLabel={false}
        index={index}
        isPreview={isPreview}
      />
      <InfiniteDropdown
        question_id='child_care_status_table'
        sub_question_id='childcare_type'
        isDisabled={false}
        hideLabel={false}
        index={index}
        isPreview={isPreview}
      />
    </StyledRow>
  )
}

// Selectors and createSelectors
const dataSelector = (state, question_id) => {
  return state.interview[question_id]
}
const previewDataSelector = (state, question_id) => {
  return state.preview[question_id]
}
const ChildCareStatus = React.memo(({ isPreview }) => {
  const { answer, description } = useSelector(state =>
    isPreview ? previewDataSelector(state, 'child_care_status_table') : dataSelector(state, 'child_care_status_table')
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Child Care Status'
          domain='Child Care and Parenting'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Now I'd like to review your child care arrangments for your children
          while you're participating in work or WTW activities.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Child Care Status</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Note child care arrangements for children. Any gaps
          identified in care should be addressed in the self-sufficiency plan
        </Typography>
      </Grid>
      {!answer && (
        <Grid item xs={12}>
          <StyledSubHeader variant='h6'>
            No Children in Household
          </StyledSubHeader>
        </Grid>
      )}

      {answer && (
        <>
          <Grid item xs={12}>
            <StyledSubHeader variant='h6'>{description}</StyledSubHeader>
          </Grid>
          <Grid item xs={12}>
            <StyledTable>
              <TableHead>
                {answer.map((child, index) => (
                  <ChildStatusRows index={index} key={child.id} isPreview={isPreview} />
                ))}
              </TableHead>
            </StyledTable>
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default ChildCareStatus
