import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const otherProblemsWithDrugsSelector = state =>
  state.interview.othr_prb_w_drgs.answer

const previewOtherProblemsWithDrugsSelector = state =>
  state.preview.othr_prb_w_drgs.answer

const useOfSubstanceSelector = createSelector(
  otherProblemsWithDrugsSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)
const previewUseOfSubstanceSelector = createSelector(
  previewOtherProblemsWithDrugsSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const SubstanceUseInHousehold = React.memo(({ isPreview }) => {
  const substanceUseIsYes = useSelector(isPreview ? previewUseOfSubstanceSelector : useOfSubstanceSelector)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Household / By Others'
          domain='Substance Use'
        />
        <form>
          <Grid>
            <Select question_id='othr_prb_w_drgs' isPreview={isPreview} />
          </Grid>
          <Text
            isDisabled={substanceUseIsYes}
            question_id='othr_prb_w_drgs_expl'
            shouldDecode={true}
            multiline
            subquestion
            rows='4'
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default SubstanceUseInHousehold
