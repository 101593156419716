import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { KeyboardDatePicker as Picker } from '@material-ui/pickers'
import { setValue } from 'ducks/datePickerSlice'

const CustomDatePicker = styled(Picker)`
  && {
    margin-top: 20px;
    margin-right: 20px;
    width: 250px;
  }
`
const Container = styled.div`
  padding-left: ${p => p.subquestion && '30px'};
`

// The "datePickerOptions" prop is used to pass props to the date picker
// component from material-picker programatically. Look to material-picker
// docs for additional props if needed:

// https://material-ui-pickers.dev/api/KeyboardDatePicker
const DialogDatePicker = React.memo(
  ({
    label,
    field,
    minDate,
    isDisabled,
    defaultValue,
    datePickerOptions = { 
      format: 'MM/dd/yyyy',
      minDate: minDate,
    },
  }) => {

    const value = useSelector(state => state.datePicker[field]) || defaultValue

    const dispatch = useDispatch()

    const handleChange = (value) => {
      dispatch(setValue({ field: field, value: value }))
      }
   
    const inputId = `${field}-input`

    return (
      <Container>
        <CustomDatePicker
          KeyboardButtonProps={{
            'aria-label': `Button of the date picker ${label} ${inputId}`,
          }}
          disabled={isDisabled}
          id={inputId}
          value={value}
          label={label}
          variant='inline'
          inputVariant='outlined'
          onChange={(value) => handleChange(value)}
          {...datePickerOptions}
        />
      </Container>
    )
  }
)

export default DialogDatePicker
