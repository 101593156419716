import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'client_partner_present' &&
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('FP')
  ) {
    effectCollector.add('previously_been_abused', null)
    effectCollector.add('previously_applied_restraining_order', null)
    effectCollector.add('current_restraining_order', null)
    effectCollector.add('previous_partner_control_life', null)
    effectCollector.add('current_concerns_safety', null)
    effectCollector.add('previously_caused_fear', null)
    effectCollector.add('previously_witnessed_domestic_abuse', null)
    effectCollector.add('temper_concern_friends', null)
    effectCollector.add('current_restraining_order_self', null)
  }

  if (
    question_id === 'previously_applied_restraining_order' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('current_restraining_order', null)
  }
  return effectCollector.effects
}

export default effects;