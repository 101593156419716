import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { KeyboardDatePicker as Picker } from '@material-ui/pickers'
import { setNewUser } from 'ducks/adminSlice'
import { isValid } from 'date-fns'

const InputContainer = styled.div`
  margin-left: 8px;
  display: inline-block;
`

const CustomDatePicker = styled(Picker)`
  && {
    margin-top: 20px;
    margin-right: 20px;
    width: 250px;
  }
`

const AdminDatePicker = React.memo(
  ({
    label,
    field,
    datePickerOptions = { format: 'MM/dd/yyyy' },
    isDisabled,
  }) => {
    const value = useSelector(state => state.admin[field])
    const dispatch = useDispatch()
    const handleChange = value => {
      if (isValid(value)) dispatch(setNewUser({ field: field, value }))
      else dispatch(setNewUser({ field: field, value }))
    }

    const inputId = `${field}-input`

    return (
      <InputContainer>
        <CustomDatePicker
          KeyboardButtonProps={{
            'aria-label': `Button of the date picker ${label} ${inputId}`,
          }}
          disabled={isDisabled}
          id={inputId}
          value={value}
          label={label}
          variant='inline'
          inputVariant='outlined'
          onChange={(date, value) => handleChange(date, value)}
          maxDateMessage={null}
          minDateMessage={null}
          {...datePickerOptions}
        />
      </InputContainer>
    )
  }
)

export default AdminDatePicker
