import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNote: (state, action) => {
      const { subdomain, note } = action.payload
      if (subdomain) {
        state[subdomain] = {
          subdomain,
          note: note ?? '',
        }
      }
    },
    setNotes: (state, action) => {
      action.payload.notes.map(note => {
        const { subdomain, Notes } = note
        if (subdomain && Notes) {
          return (state[subdomain] = {
            subdomain: subdomain,
            note: Notes,
          })
        }
        return state
      })
    },
    clearNotes: (state, action) => initialState,
  },
})

export const { setNote, setNotes, clearNotes } = noteSlice.actions

export default noteSlice.reducer
