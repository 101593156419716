import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Text from 'components/general/Text'
import Dropdown from 'components/general/Dropdown'
import Select from 'components/general/Select'
import TableRowDescription from 'components/general/TableRowDescription'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { previewInterviewSelector } from 'utilTools/reduxHelper'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`
const tableHeaders = [
  { name: 'Job Type', colWidth: '40%' },
  { name: 'Skill Level', colWidth: '30%' },
  { name: 'Does this type of work interest you?', colWidth: '20%' },
]

export const tableQuestions = [
  {
    experience: 'admin_assistant_experience',
    interest: 'admin_assistant_interest',
  },
  {
    experience: 'bookkeeping_accounting_experience',
    interest: 'bookkeeping_accounting_interest',
  },
  {
    experience: 'call_center_experience',
    interest: 'call_center_interest',
  },
  {
    experience: 'caring_for_others_experience',
    interest: 'caring_for_others_interest',
  },
  {
    experience: 'cleaning_custodial_experience',
    interest: 'cleaning_custodial_interest',
  },
  {
    experience: 'construction_experience',
    interest: 'construction_interest',
  },
  {
    experience: 'customer_service_experience',
    interest: 'customer_service_interest',
  },
  {
    experience: 'dental_hygenist_experience',
    interest: 'dental_hygenist_interest',
  },
  {
    experience: 'driving_experience',
    interest: 'driving_interest',
  },
  {
    experience: 'education_experience',
    interest: 'education_interest',
  },
  {
    experience: 'entertainment_experience',
    interest: 'entertainment_interest',
  },
  {
    experience: 'farming_experience',
    interest: 'farming_interest',
  },
  {
    experience: 'fishing_experience',
    interest: 'fishing_interest',
  },
  {
    experience: 'harvesting_local_resources_experience',
    interest: 'harvesting_local_resources_interest',
  },
  {
    experience: 'healthcare_experience',
    interest: 'healthcare_interest',
  },
  {
    experience: 'helping_with_events_experience',
    interest: 'helping_with_events_interest',
  },
  {
    experience: 'high_tech_experience',
    interest: 'high_tech_interest',
  },
  {
    experience: 'landscaping_experience',
    interest: 'landscaping_interest',
  },
  {
    experience: 'logging_experience',
    interest: 'logging_interest',
  },
  {
    experience: 'managing_experience',
    interest: 'managing_interest',
  },
  {
    experience: 'manufacturing_experience',
    interest: 'manufacturing_interest',
  },
  {
    experience: 'mechanical_experience',
    interest: 'mechanical_interest',
  },
  {
    experience: 'natural_resource_employment_experience',
    interest: 'natural_resource_interest',
  },
  {
    experience: 'office_work_experience',
    interest: 'office_work_interest',
  },
  {
    experience: 'business_owner_experience',
    interest: 'business_owner_interest',
  },
  {
    experience: 'personal_care_experience',
    interest: 'personal_care_interest',
  },
  {
    experience: 'professional_office_worker_experience',
    interest: 'professional_office_worker_interest',
  },
  {
    experience: 'restaurant_job_experience',
    interest: 'restaurant_job_interest',
  },
  {
    experience: 'retail_job_experience',
    interest: 'retail_job_interest',
  },
  {
    experience: 'sales_experience',
    interest: 'sales_interest',
  },
  {
    experience: 'seasonal_field_work_experience',
    interest: 'seasonal_field_work_interest',
  },
  {
    experience: 'security_experience',
    interest: 'security_interest',
  },
  {
    experience: 'social_services_experience',
    interest: 'social_services_interest',
  },
  {
    experience: 'teaching_experience',
    interest: 'teaching_interest',
  },
  {
    experience: 'tourism_experience',
    interest: 'tourism_interest',
  },
  {
    experience: 'trades_experience',
    interest: 'trades_interest',
  },
  {
    experience: 'traditional_skills_experience',
    interest: 'traditional_skills_interest',
  },
  {
    experience: 'warehouse_experience',
    interest: 'warehouse_interest',
  },
  {
    experience: 'working_with_animals_experience',
    interest: 'working_with_animals_interest',
  },
  {
    experience: 'working_with_government_experience',
    interest: 'working_with_government_interest',
  },
]

const EmploymentWorkExperienceInterests = React.memo(({ isPreview }) => {
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  const experienceSelected = tableQuestions.map(question => {
    const answer = selectorState[question.experience].answer
    return answer === undefined || answer === null;
  })
  return (
    <>
      <QuestionnaireHeader
        subdomain='Work Experience and Interests'
        domain='Employment'
      />
      <Typography variant='body2'>
        Instructions: Probe for interest and experience in the following job
        types. Each category does not have to be read aloud.
      </Typography>
      <Typography variant='h6'>
        Thinking about all the work you have ever done, whether at a job or
        through community service, what is your level of skill and interest in
        the following types of jobs?
      </Typography>
      <CustomTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableHeaderCell key={index} colwidth={header.colWidth}>
                  {header.name}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableQuestions.map((question, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <TableRowDescription
                    question_id={question.experience}
                    isPreview={isPreview}
                    />
                  </TableCell>
                  <TableCell>
                    <Dropdown
                    question_id={question.experience}
                    disableDescription={true}
                    isPreview={isPreview}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                    question_id={question.interest}
                    disableDescription={true}
                    isDisabled={experienceSelected[index]}
                    isPreview={isPreview}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CustomTableContainer>
      <Text question_id='other_job_type' multiline rows='4' shouldDecode={true} isPreview={isPreview}/>
    </>
  )
})

export default EmploymentWorkExperienceInterests
