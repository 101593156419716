import { createSlice } from '@reduxjs/toolkit'

// This slice maintains a view of which interview page is currently active
const initialState = null

const currentInterviewPageSlice = createSlice({
  name: 'currentInterviewPage',
  initialState,
  reducers: {
    setCurrentInterviewPage: (state, action) => action.payload,
    clearCurrentInterviewPage: (state, action) => initialState,
  },
})

export const {
  setCurrentInterviewPage,
  clearCurrentInterviewPage,
} = currentInterviewPageSlice.actions

export default currentInterviewPageSlice.reducer
