import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'

import routes from 'app/routes'
import PageNotFound from 'components/navigation/PageNotFound'
import { setCurrentInterviewPage } from 'ducks/currentInterviewPageSlice'
import { resetInterviewData } from 'thunks/resetInterviewData'
import PrivateRoute from 'app/PrivateRoute'

const currentInterviewSelector = state => state.client.currentInterview
const currentClientSelector = state => state.client.currentClient
const currentInterviewPageSelector = state => state.currentInterviewPage
const selectLoggedIn = state => state.user.loggedIn
const selectIsBRE = state => state.user.roles[0] && state.user.roles[0].code === "BRE"

const clientSelectedSelector = createSelector(
  [currentClientSelector],
  currentClient => currentClient !== null
)

const clientAndInterviewSelectedSelector = createSelector(
  [currentInterviewSelector, currentClientSelector],
  (currentInterview, currentClient) =>
    currentInterview !== null && currentClient !== null
)

const loggedInSelector = createSelector(
  selectLoggedIn,
  loggedIn => loggedIn === true
)

const BRESelector = createSelector(
  selectIsBRE,
  isBRE => isBRE === true
)

const isAnInterview = path => {
  return path.includes('/interview/')
}

const isInterviewPreview = path => {
  return path.includes('/preview/')
}

const RouteManager = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const clientSelected = useSelector(clientSelectedSelector)
  const interviewAndClientSelected = useSelector(
    clientAndInterviewSelectedSelector
  )
  const currentInterviewPage = useSelector(currentInterviewPageSelector)
  const loggedIn = useSelector(loggedInSelector)
  const isBRE = useSelector(BRESelector)

  // Pathname for the url user is about to navigate to
  const pathNavigatingTo = location?.pathname
  useEffect(() => {
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('page', pathNavigatingTo)
    // If there is not currently an active interview page and the user is about to
    // navigate to an interview page, be sure to set the current page they are visiting
    if (!currentInterviewPage && isAnInterview(pathNavigatingTo)) {
      const interviewLocation = find(routes, { path: location?.pathname })
      if (interviewLocation) {
        dispatch(setCurrentInterviewPage(interviewLocation.id))
      }
    }
    // If there is currently an active interview page and the user is about to navigate
    // away from the interview
    if (currentInterviewPage && !isAnInterview(pathNavigatingTo) && !isInterviewPreview(pathNavigatingTo)) {
      // clear the current page value
      dispatch(resetInterviewData())
    }
  }, [currentInterviewPage, dispatch, pathNavigatingTo, location])

  const authHandler = () => {
    if (loggedIn) {
      if (isBRE) {
        return <Redirect to='/bre' />
      }
      else{
        return <Redirect to='/client_list' />
      }
    }
  }
  return (
    <Switch>
      <Route exact path='/'>
        {authHandler()}
      </Route>
      {Object.values(routes).map(route => {
        // Globally, assume everything can access the route unless one of the below
        // pieces of logic overrides this
        let canAccess = true
        // If it's an interview, only allow access if interview and client is selected
        if (isAnInterview(route.path)) {
          canAccess = interviewAndClientSelected
        }
        // If it's the interview list, only allow access if client is selected
        else if (route.path === routes.interview_list.path) {
          canAccess = clientSelected
        }

        return (
          <PrivateRoute
            key={route.id}
            exact={route.exact}
            path={route.path}
            canAccess={canAccess}
            component={route.component}
            render={route.render}
          />
        )
      })}
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default RouteManager
