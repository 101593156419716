import { createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { saveData } from 'thunks/saveData'
import { setPageVisited } from 'thunks/setPageVisited'
import { navigateWithoutSave } from 'thunks/navigateWithoutSave'
import { saveActionPlans } from 'thunks/saveActionPlans'
import { getRecommendationsAndActionPlans } from 'thunks/retrieveInterviewData'
import { getPreviewRecommendationsAndActionPlans } from 'thunks/retrievePreviewData'
import routes from 'app/routes'

const initialState = {
  structure: [],
  currentPathname: null,
  previousPathname: null,
  navigationHistory: [],
  historyIndex: -1,
  justPopped: false,
  currentlyExpandedCategoryCode: 'questionnaire',
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialState,
  reducers: {
    setInterviewNavigation: (state, action) => {
      state.structure = action.payload
    },
    setCurrentPathname: (state, action) => {
      state.currentPathname = action.payload
    },
    setPreviousPathname: (state, action) => {
      state.previousPathname = action.payload
    },
    setNavigationHistory: (state, action) => {
      state.navigationHistory = action.payload
    },
    setHistoryIndex: (state, action) => {
      state.historyIndex = action.payload
    },
    setJustPopped: (state, action) => {
      state.justPopped = action.payload
    },
    setCurrentlyExpandedCategoryCode: (state, action) => {
      state.currentlyExpandedCategoryCode = action.payload
    },
    setNextListItemCode: (state, action) => {
      state.nextListItemCode = action.payload
    },
  },
})

export const navigateToNextSection = history => {
  return async (dispatch, getState) => {
    const currentPage = getState().currentInterviewPage
    if (!currentPage) {
      return
    }
    // Questionnaire is always first in the array of categories
    const questionnaire = getState().navigation.structure[0]

    // Get the domain for the current page
    const currentDomain = find(questionnaire.domains, domain => {
      return find(domain.subDomains, { code: currentPage })
    })

    // If the current domain is not the last one in the list of domains,
    // we want to go to the first subdomain inside of the next domain
    if (currentDomain.order < questionnaire.domains.length) {
      const nextPage = find(questionnaire.domains, {
        order: currentDomain.order + 1,
      })
      dispatch(navigateToPage(nextPage.subDomains[0].code, history))
    }
    // If we are on the final domain, we return.
    else {
      return
    }
  }
}

export const nextListItemCode = () => {
  return async (dispatch, getState) => {
    let code
    const currentPage = getState().currentInterviewPage || ''
    // Find Questionnaire by categorie code
    const questionnaire = getState().navigation.structure?.find(category => {
      return category.code === 'questionnaire'
    })

    let currentDomain = getCurrentDomain(
      getState().navigation.structure,
      currentPage
    )

    // Get the order for the current subdomain within that domain
    const currentSubdomainOrder = find(currentDomain.subDomains, {
      code: currentPage,
    }).order

    // If the current subdomain is not the last one in the list of domains,
    // return the next subdomain code in the list for the current domain
    if (currentSubdomainOrder < currentDomain.subDomains.length) {
      const nextPage = find(currentDomain.subDomains, {
        order: currentSubdomainOrder + 1,
      })
      code = nextPage.code
    }
    // Otherwise, we want to return next domain code
    else if (currentDomain.order < questionnaire.domains.length) {
      const nextPage = find(questionnaire.domains, {
        order: currentDomain.order + 1,
      })
      code = nextPage.code
    }
    // If we are on the final domain, we return empty string.
    else {
      code = ''
    }
    dispatch(navigationSlice.actions.setNextListItemCode(code))
  }
}

export const navigateToNextPage = (history, isPreview, save = true) => {
  return async (dispatch, getState) => {
    const currentPage = getState().currentInterviewPage
    if (!currentPage) {
      return
    }

    // Questionnaire is always first in the array of categories
    const questionnaire = getState().navigation.structure[0]
    const navData = isPreview ? getState().preview.navData : getState().navigation.structure
    let currentDomain = getCurrentDomain(
      navData,
      currentPage
    )

    // Get the order for the current subdomain within that domain
    const currentSubdomainOrder = find(currentDomain.subDomains, {
      code: currentPage,
    }).order

    // If the current subdomain is not the last one in the list of domains,
    // we want to go to the next subdomain in the list for the current
    // domain.
    if (currentSubdomainOrder < currentDomain.subDomains.length) {
      const nextPage = find(currentDomain.subDomains, {
        order: currentSubdomainOrder + 1,
      })
      save
        ? dispatch(navigateToPage(nextPage.code, history, isPreview))
        : dispatch(navigateWithoutSave(nextPage.code, history, isPreview))
    }
    // Otherwise, we want to go to the first subdomain inside of
    // the next domain
    else if (
      currentDomain.code !== 'recommendations' &&
      currentDomain.order < questionnaire.domains.length
    ) {
      const nextPage = find(questionnaire.domains, {
        order: currentDomain.order + 1,
      })
      save
        ? dispatch(navigateToPage(nextPage.subDomains[0].code, history, isPreview))
        : dispatch(navigateWithoutSave(nextPage.subDomains[0].code, history, isPreview))
    }
    // If the current subdomain is the last page of the questionnaire domain
    // then navigate to the first subdomain of the recommendations domain
    else if (
      currentSubdomainOrder === currentDomain.subDomains.length &&
      currentDomain.code !== 'recommendations'
    ) {
      const recommendationsFirstSubdomain = getState().navigation.structure[1]
        .domains[0].subDomains[0].code
      save
        ? dispatch(navigateToPage(recommendationsFirstSubdomain, history, isPreview))
        : dispatch(navigateWithoutSave(recommendationsFirstSubdomain, history, isPreview))
      dispatch(setCurrentlyExpandedCategoryCode('recommendations'))
    }
    // If the current subdomain is the last page of the recommendations domain
    // then navigate to the first subdomain of the appraisal domain
    else if (currentSubdomainOrder === currentDomain.subDomains.length) {
      const appraisalFirstSubdomain = getState().navigation.structure[2]
        .domains[0].subDomains[0].code
      save
        ? dispatch(navigateToPage(appraisalFirstSubdomain, history, isPreview))
        : dispatch(navigateWithoutSave(appraisalFirstSubdomain, history, isPreview))
      dispatch(setCurrentlyExpandedCategoryCode(appraisalFirstSubdomain))
    } else {
      return
    }
  }
}

// Navigates to route, assuming save/validation pass successfully
export const navigateToPage = (subdomainID, history, isPreview) => {
  return async (dispatch, getState) => {
    // Perform the save (and validation) -- if fails, cant proceed
    // Note: error handling is already handled inside of saveData()
    try {
      if(!isPreview) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportEvent', 'saveOnNavigate');
      
      const success = await dispatch(saveData({ disableNotifications: false }))
      dispatch(saveActionPlans())

      if (!success) {
        // If the back button was pressed, and they've moved from the duplicate in history, to the original.
        // This will send them back to the clone.
        // In normal nav button based navigation, this has no effect (they're already at '1')
        history.go(1)
        return
      }
    }
    } catch (err) {
      // On error, we can simply return from this process because the
      // error is already being handled inside of saveData
      return
    }

    // If navigating to a recommendation page, re-query recommendations in case any have been added or removed
    const name = isPreview ? `${subdomainID}_preview` : subdomainID
    const navigationPath = routes[name]?.path
    if (navigationPath && navigationPath.includes('recommendations')) {
      dispatch(
        isPreview ? getPreviewRecommendationsAndActionPlans() : getRecommendationsAndActionPlans(getState().client.currentInterview)
      )
    }
    

    dispatch(navigateWithoutSave(name, history, isPreview))
    if(!isPreview) {
    // 4. Save navigation status
    // 5. Set UI navigation status on success, error popup on fail
    dispatch(setPageVisited(name))
    }
  }
}

const getCurrentDomain = (structure, currentPage) => {
  const questionnaire = structure[0]
  const recommendations = structure[1]

  // Get the domain for the current page
  let currentDomain = find(questionnaire.domains, domain => {
    return find(domain.subDomains, { code: currentPage })
  })

  // subDomain did not exist in questionnaire, check recommendations
  if (!currentDomain) {
    currentDomain = find(recommendations.domains, domain => {
      return find(domain.subDomains, { code: currentPage })
    })
  }

  return currentDomain
}

export const {
  setInterviewNavigation,
  setJustPopped,
  setCurrentPathname,
  setPreviousPathname,
  setNavigationHistory,
  setHistoryIndex,
  setCurrentlyExpandedCategoryCode,
  setNextListItemCode,
} = navigationSlice.actions

export default navigationSlice.reducer
