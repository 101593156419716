import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialState,
  reducers: {
    openSnackbar: (state, action) => action.payload,
    closeSnackbar: (state, action) => initialState,
  },
})

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
