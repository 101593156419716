import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { discardChangesAndNavigate } from 'thunks/discardChangesAndNavigate'

import grey from '@material-ui/core/colors/grey'
import Typography from '@material-ui/core/Typography'

import LastSavedDisplay from 'components/general/LastSavedDisplay'
import SaveButton from 'components/general/SaveButton'
import ExitButton from 'components/general/ExitButton'
import Link from 'components/general/LinkButton'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

const Container = styled.div`
  padding: 16px 16px 0 16px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${grey[300]};
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const StyledExitButton = styled(ExitButton)`
  && {
    display: inline-block;
  }
`

const StyledTypography = styled(Typography)`
  && {
    text-decoration: underline;
  }
`

const InterviewHeader = ({ isPreview }) => {
  const dispatch = useDispatch()
  const { first_name, last_name } = useSelector(
    isPreview 
      ? (() => ({ first_name: 'Preview', last_name: 'Client' }))
      : (state => state.unsavedChanges.originalAnswers)
  )
  const roles = useSelector(state => state.user.roles)
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )

  const history = useHistory()

  const handleClick = () => {
    dispatch(discardChangesAndNavigate('/interview_list', history))
  }
  return (
    <Can
      roles={roles}
      perform='interview:view'
      yes={() => {
        return (
          <Container>
            <Row>
              <StyledTypography variant='h3'>
                <Link
                description={decodeHtml(`${first_name} ${last_name}`)}
                defaultAction={handleClick}
                route={`/interview_list`}
                />
              </StyledTypography>
              <div>
                <Can
                  roles={roles}
                  perform={isPreview ? 'BRE:view' : 'interview:edit'}
                  yes={() => {
                    return (
                      <>
                        {!cancelledOrCompletedInterview && !isPreview && (
                          <SaveButton label={'Save'} />
                        )}
                      </>
                    )
                  }}
                />
                <StyledExitButton label={'Exit'} />
              </div>
            </Row>
            <Row end={1}>
              <LastSavedDisplay />
            </Row>
          </Container>
        )
      }}
    />
  )
}

export default InterviewHeader
