import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TableCell from '@material-ui/core/TableCell'
import ControlDescription from './ControlDescription'
import Indicator from 'components/general/Indicator'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { decodeHtml } from '../../utilTools/decodeHtml'

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    ${p => p.table && `margin: 0;`}
  }
`
const StyledRadioGroup = styled(RadioGroup)`
  && {
    justify-content: center;
  }
`

const Container = styled.div`
  padding-left: ${p => p.subquestion && '30px'};
`
// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    
    return {
      description: data.description,
      label: data.label,
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty string)
      answer: data.answer ? data.answer : '',
      // This is a sanity check just in case there are duplicates
      options: uniqBy(data.options, 'optionId'),
      indicator: data.indicator,
    }
  })
}

const validationSelector = (state, question_id) =>
  state.validationErrors[question_id]
const createValidationSelector = () => {
  return createSelector(validationSelector, validation => {
    return {
      hasValidationError: validation ? true : false,
      validationError: validation ? validation : null,
    }
  })
}

const Select = React.memo(
  ({
    question_id,
    isDisabled,
    isTable = false,
    disableLabel = false,
    disableDescription = false,
    subquestion,
    noLabelAndDescription,
    sawsIndicator,
    indicatorStyle,
    isPreview,
  }) => {
    const dispatch = useDispatch()

    // We create a selector for each instance of the Select component.
    const dataSelector = createDataSelector(isPreview)
    const validationSelector = createValidationSelector()
    const { description, answer, options, indicator } = useSelector(state =>
      dataSelector(state, question_id)
    )
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )

    const hasIndicator = sawsIndicator ? indicator : 'noIndicator'

    const { hasValidationError, validationError, label } = useSelector(state =>
      validationSelector(state, question_id)
    )

    const sendData = data => {
      dispatch(
        isPreview
        ? capturePreviewFormData({
          question_id: question_id,
          answer: data,
        })
        : captureFormData({
          question_id: question_id,
          answer: data,
        })
      )
    }

    // Label for ADA
    const labelid = `${question_id}-label`

    // Collect change and dispatch to Redux
    const handleChange = e => {
      if (cancelledOrCompletedInterview) return
      sendData(e.target.value)
    }

    // Clicking a previously selected radio won't trigger onChange, so we watch for onClick as well
    const handleClick = (e, oldValue) => {
      if (cancelledOrCompletedInterview) return
      // When user toggles a radio button, clear the value
      // e.detail checks for a double click, in IE e.detail is always zero on click
      // so we only check for double click in modern browsers
      if (e.target.value === oldValue && e.detail <= 1) {
        sendData(null)
      }
    }

    // ADA requires enter key accessibility
    const handleKeyPress = (e, oldValue) => {
      if (cancelledOrCompletedInterview) return
      const keyCode = e.which || e.keyCode
      const enterKeyCode = 13
      if (keyCode === enterKeyCode) {
        if (e.target.value === oldValue) {
          sendData(null)
        } else {
          sendData(e.target.value)
        }
      }
    }

    // In this block, StyledFormControlLabel have to be returned directly, otherwise, the keyboard accessibility (ADA) will break
    const controlOptions = options
      .sort((a, b) => a.order - b.order)
      .map(({ optionId, optionLabel }) => {
        return isTable ? (
          <TableCell key={optionId}>
            <Can
              no={() => {
                return (
                  <StyledRadioGroup
                    row
                    aria-label={label}
                    name={labelid}
                    value={answer}
                  >
                    <StyledFormControlLabel
                      table={isTable ? 1 : 0}
                      disabled={true}
                      value={optionId}
                      control={
                        <Radio
                          inputProps={{ 'aria-label': `Option ${optionId}` }}
                        />
                      }
                      label={disableLabel ? undefined : decodeHtml(optionLabel)}
                      key={optionId}
                    />
                  </StyledRadioGroup>
                )
              }}
              yes={() => {
                return (
                  <StyledRadioGroup
                    row
                    aria-label={label}
                    name={labelid}
                    value={answer}
                    onChange={handleChange}
                    onClick={e => {
                      handleClick(e, answer)
                    }}
                    onKeyPress={e => {
                      handleKeyPress(e, answer)
                    }}
                  >
                    <StyledFormControlLabel
                      table={isTable ? 1 : 0}
                      disabled={cancelledOrCompletedInterview || isDisabled}
                      value={optionId}
                      control={
                        <Radio
                          inputProps={{ 'aria-label': `Option ${optionId}` }}
                        />
                      }
                      label={disableLabel ? undefined : decodeHtml(optionLabel)}
                      key={optionId}
                    />
                  </StyledRadioGroup>
                )
              }}
            />
          </TableCell>
        ) : (
          <Can
            key={optionId}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            roles={roles}
            no={() => {
              return (
                <StyledFormControlLabel
                  table={isTable ? 1 : 0}
                  disabled={true}
                  value={optionId}
                  control={
                    <Radio
                      inputProps={{ 'aria-label': `Option ${optionId}` }}
                    />
                  }
                  label={disableLabel ? undefined : decodeHtml(optionLabel)}
                  key={optionId}
                />
              )
            }}
            yes={() => {
              return (
                <StyledFormControlLabel
                  table={isTable ? 1 : 0}
                  disabled={cancelledOrCompletedInterview || isDisabled}
                  value={optionId}
                  control={
                    <Radio
                      inputProps={{ 'aria-label': `Option ${optionId}` }}
                    />
                  }
                  label={disableLabel ? undefined : decodeHtml(optionLabel)}
                  key={optionId}
                />
              )
            }}
          />
        )
      })
    if (isTable) {
      return <>{controlOptions}</>
    }

    return (
      <Can
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        roles={roles}
        yes={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                  {sawsIndicator && (
                    <Indicator
                      indicator={hasIndicator}
                      style={indicatorStyle}
                    />
                  )}
                </ControlDescription>
              )}
              <FormControl component='fieldset' error={hasValidationError}>
                <FormLabel component='legend'>
                  {label}
                  {noLabelAndDescription && (
                    <Indicator
                      indicator={hasIndicator}
                      style={indicatorStyle}
                    />
                  )}
                </FormLabel>
                {hasValidationError && (
                  <FormLabel component='legend'>{validationError}</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-label={label}
                  name={labelid}
                  value={answer}
                  onChange={handleChange}
                  onClick={e => {
                    handleClick(e, answer)
                  }}
                  onKeyPress={e => {
                    handleKeyPress(e, answer)
                  }}
                >
                  {controlOptions}
                </RadioGroup>
              </FormControl>
            </Container>
          )
        }}
        no={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && !disableDescription && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                  {sawsIndicator && (
                    <Indicator
                      indicator={hasIndicator}
                      style={indicatorStyle}
                    />
                  )}
                </ControlDescription>
              )}
              <FormControl component='fieldset' error={hasValidationError}>
                <FormLabel component='legend'>
                  {label}
                  {noLabelAndDescription && (
                    <Indicator
                      indicator={hasIndicator}
                      style={indicatorStyle}
                    />
                  )}
                </FormLabel>
                {hasValidationError && (
                  <FormLabel component='legend'>{validationError}</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-label={label}
                  name={labelid}
                  value={answer}
                >
                  {controlOptions}
                </RadioGroup>
              </FormControl>
            </Container>
          )
        }}
      />
    )
  }
)

export default Select
