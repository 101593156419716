import { addRegion } from 'ducks/entitiesSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'

const CREATE_NEW_REGION = gql`
  mutation($orgLevelID: Int, $name: String) {
    createRegion(orgLevelID: $orgLevelID, name: $name) {
      createdRegionId
    }
  }
`

export const createRegion = orgLevelID => {
  return async (dispatch, getState) => {
    try {
      const orgLevelID = getState().entities.county
      let newRegionData = await client.mutate({
        mutation: CREATE_NEW_REGION,
        variables: {
          orgLevelID: parseInt(orgLevelID),
          name: '',
        },
      })

      const newRegion = {
        regionID: newRegionData.data.createRegion.createdRegionId,
        regionName: '',
        dirty: true,
      }

      dispatch(
        addRegion({
          region: newRegion,
        })
      )
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
