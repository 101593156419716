import React, { useState } from 'react'
import { Typography, 
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../app/theme'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TYPE_DESCRIPTIONS } from '../general/Constants'
import { openDialog } from '../../ducks/dialogSlice'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { decodeHtml } from '../../utilTools/decodeHtml'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { isoToLocalDate } from '../../utilTools/dateHelper'
import Button from '@material-ui/core/Button'
import { deleteScheduledChange } from  '../../thunks/deleteSheduledChange'
import { updatePublishDate } from  '../../thunks/updatePublishDate'
import { refreshPendingChanges } from 'thunks/refreshPendingChanges'

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const Container = styled.div`
  margin: 10px 0;
  display: contents;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
  }
`
const StyledLeftHeaderElement = styled(Typography) `
&& {
  color: ${colors.turquoise};
  font-weight: 600;
  text-align: left;
}
`
const StyledListContainer = styled.div`
  margin-bottom: 10px;
`
const StyledLeftTableElement = styled(ListItem)`
  && {
    font-size: 14px;
    padding-top: 10px;
    display: block;
    text-align: left;
    margin-left: -15px;
}
`
const StyledTableIconElement = styled(ListItem)`
&& {
  max-width: 0.1%;
}`
const PrimaryButton = styled(Button)`
  && {
    margin: 5px;
    display: inline-block;
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SuccessDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}
const buildListElement = (change, index, handleDelete) => {
  if(!change.createUser) {
    return
  }
  const user = change.createUser.firstName + ' ' + change.createUser.lastName
  const createdDate = isoToLocalDate(change.createdAt)
  const publishDate = change.publishDate
  let changeDescription = CHANGE_TYPE_DESCRIPTIONS[change.changeType]
  let changeDetails = ''
  let update = Object.values(change.changeDetails)[0]
  if(change.changeType.includes('RULE')) {
    changeDetails = 'Rule ' + change.rule.ruleJSON.name
    if(!change.changeType.includes('DISABLE') 
        && !change.changeType.includes('ENABLE')) {
          let language = decodeHtml(Object.keys(change.changeDetails)[0])
          changeDescription = language + " " + changeDescription
          changeDetails = changeDetails + ': ' + update 
    }
  } else {
    let type = Object.keys(change.changeDetails)[0]
    changeDetails = Object.values(change.changeDetails)[0]
    let update = ''
    if (type === 'option') {
      update = Object.values(change.changeDetails)[0]
      changeDetails =
        'Question: ' + change.recordId + ' -- Option: ' + update.optionId
    }
  }
      
  return (
    <Grid container key={change.id}>
      <Grid item xs={1}><StyledTableIconElement onClick={() => {
        handleDelete(change)
        }}
        >
        <Typography variant='body1'><DeleteOutlineIcon /></Typography></StyledTableIconElement></Grid>
      <Grid item xs={2}>
          <StyledLeftTableElement><Typography variant='body1'>{publishDate}</Typography></StyledLeftTableElement>
      </Grid>
      <Grid item xs={2}><StyledLeftTableElement><Typography variant='body1'>{changeDescription}</Typography></StyledLeftTableElement></Grid>
      <Grid item xs={3}><StyledLeftTableElement><Typography variant='body1'>{decodeHtml(changeDetails)}</Typography></StyledLeftTableElement></Grid>
      <Grid item xs={2}><StyledLeftTableElement><Typography variant='body1'>{user}</Typography></StyledLeftTableElement></Grid>
      <Grid item xs={2}><StyledLeftTableElement><Typography variant='body1'>{createdDate}</Typography></StyledLeftTableElement></Grid>
    </Grid>
  )
}

const ViewPendingChanges = () => {
  const dispatch = useDispatch()
  const [showSuccess, setSuccess] = useState(false)

  const loading = useSelector(state => state.pendingChanges.pendingChangesLoading)
  const pendingChangesLoaded = useSelector(state => state.pendingChanges.pendingChangesLoaded)
  const pendingChanges = useSelector(state => state.pendingChanges.currentPendingChanges)


  if(!pendingChangesLoaded) {
    dispatch(refreshPendingChanges())
    return null
  }

  // const { 
  //   loading,
  //   data: changesData, 
  //   error: changesError 
  // } = useQuery(GET_PENDING_CHANGES, {
  //   fetchPolicy: 'network-only',
  // })

  // useEffect(() => {
  //   if (changesError) {
  //     dispatch(
  //       openDialog({
  //         type: 'error',
  //         title: 'System Error',
  //         props: {
  //           changesError,
  //         },
  //       })
  //     )
  //   }
  //   if (changesData) {
  //     dispatch(setPendingChangesList(changesData.pendingChanges))
  //   }
  // }, [changesData, changesError, dispatch])

  const handleConfirm = () => {
    setSuccess(false)
  }
  
  const handleSave = (change) => {
    dispatch(deleteScheduledChange(change)).then(() => setSuccess(true))
  }

  const handleDelete = (change) => {
    dispatch(
      openDialog({
        type: 'confirmation',
        title: 'Are you sure you want to cancel the pending action?',
        props: {
          action: () => handleSave(change)
        }
      })
    )
  }

  const handleUpdatePublishDate = (value) => {
    dispatch(updatePublishDate(value)).then(() => setSuccess(true))
  }

  const handlePublishDateChange = (value) => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const formatTomorrow = (((tomorrow.getMonth() > 8) ? (tomorrow.getMonth() + 1)
    : ('0' + (tomorrow.getMonth() + 1))) + '/' + ((tomorrow.getDate() > 9) 
    ? tomorrow.getDate() : ('0' + tomorrow.getDate())) + '/' + tomorrow.getFullYear())
    
    let defaultValue = formatTomorrow
    let isDisabled = false

    dispatch(
    openDialog({
      type: 'dialogDatePicker',
      title: 'Update Publish Date',
      description: 'Please choose a new date for publishing all currently scheduled changes:',
      props: {
        label: 'Effective Date',
        field: 'effectiveDate',
        minDate: formatTomorrow,
        defaultValue: defaultValue,
        isDisabled: isDisabled,
        action: (value) => handleUpdatePublishDate(value),
      }
    })
    )
  }

  //const pendingChanges = useSelector(state => state.pendingChanges.currentPendingChanges)
  return (
    <>
      <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={8}>
          <SectionTitle variant='h4'>View Pending Changes</SectionTitle>
          <Typography variant='body1'>
            Below is a summary of the pending changes. <br /><br />
          </Typography>
        </Grid>
        <Grid item xs={3}>
        {pendingChanges.length > 0 ? (
        <PrimaryButton
            variant='contained'
            onClick={() => {
              handlePublishDateChange()
            }}
          >
            Change Effective Date
          </PrimaryButton>
        ) : (
          null
        )}
        </Grid>
        {loading ? (
          <Container>
            <div>Loading Pending Changes List...</div>
          </Container>
        ) : (
          <Grid container>
              {pendingChanges.length > 0 ? (
                <Grid item xs={12}>
                <StyledListContainer>
                <Grid container>
                    <Grid item xs={1}><StyledLeftHeaderElement>Cancel</StyledLeftHeaderElement></Grid>
                    <Grid item xs={2}><StyledLeftHeaderElement>Effective Date</StyledLeftHeaderElement></Grid>
                    <Grid item xs={2}><StyledLeftHeaderElement>Action</StyledLeftHeaderElement></Grid>
                    <Grid item xs={3}><StyledLeftHeaderElement>Details</StyledLeftHeaderElement></Grid>
                    <Grid item xs={2}><StyledLeftHeaderElement>Created By</StyledLeftHeaderElement></Grid>
                    <Grid item xs={2}><StyledLeftHeaderElement>Created Date</StyledLeftHeaderElement></Grid>
                  </Grid>
                  <List dense>
                    {pendingChanges.map((v, i) => {
                      return buildListElement(v, i, handleDelete)
                    })}
                  </List>
                </StyledListContainer>
            </Grid>
              ):(
                <Grid item xs={12}>There are no changes currently scheduled.</Grid>
              )}
            </Grid>
        )}
      </Grid>
      <SuccessDialog
        open={showSuccess}
        onClose={handleConfirm}
        title='Pending Change Successfully Deleted'
        body=''
      />
    </>
  )
}

export default ViewPendingChanges
