import EffectCollector from 'side-effects/effectCollector'
import { isAfter, isFuture, parse, differenceInYears } from 'date-fns'
import store from 'app/store'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { v4 as uuidv4 } from 'uuid'

const getLastChildData = listOfChildren => {
  const lastIndex = listOfChildren.length - 1
  const lastChild = listOfChildren[lastIndex]
  const { id, pcs_relationship, pcs_name, use_same_values, ...rest } = lastChild
  return rest
}

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()
  if (question_id === 'no_other_household' && answer) {
    effectCollector.add('household_members_table', null)
  }
  let isChangeConditional = false
  const stateProp = isPreview ? 'preview' : 'interview'
  // Calculate age from on dob
  if (question_id === 'household_members_table' && answer) {
    // Attach an id to each household member.
    if (answer && answer.length !== 0 && isEmpty(answer[answer.length - 1])) {
      answer[answer.length - 1].id = uuidv4()
    }

    const filteredAnswer = answer.map(row => {
      const rowCopy = { ...row }
      const { household_dob } = rowCopy
      const formattedDate = parse(household_dob, 'MM/dd/yyyy', new Date())
      if (
        formattedDate &&
        isAfter(formattedDate, new Date('01/01/1000')) &&
        !isFuture(formattedDate)
      ) {
        isChangeConditional = true
        const age = differenceInYears(Date.now(), formattedDate)
        rowCopy['household_age'] = age
      } else {
        isChangeConditional = true
        rowCopy['household_age'] = null
      }
      return rowCopy
    })
    // *********************************************************************************
    // Childcare and Parenting domain's tables that require information from household
    // composition
    // Tables include: Children's Issues, Child Care Status, Parenting and Child Support
    // *********************************************************************************
    // Children's issues answers
    const childrensIssuesAnswers =
      store.getState().unsavedChanges.originalAnswers.child_issues_card || []
    // Child care status answers
    const childcareStatusAnswers =
      store.getState().unsavedChanges.originalAnswers.child_care_status_table ||
      []
    const parentingAndChildSupportAnswers =
      store.getState().unsavedChanges.originalAnswers
        .parenting_and_child_support_table || []

    // As the children's issues and child care status only displays the child's relationship, we
    // pull the relationship info's optionId for display purposes
    const relationshipTypes = store.getState()[stateProp].household_members_table
      .sub_question_ids.household_relationship.options
    // Find the household members who are under 18
    const childrenInHouseholdMembers = filteredAnswer.filter(
      householdMember =>
        householdMember.household_dob &&
        parseInt(householdMember.household_age) < 18
    )
    // If there are no children in the household, set questions on the Child Care and
    // Parenting Indicator page to null.
    if (childrenInHouseholdMembers.length < 1) {
      const questionsToClear = [
        'child_issues_challenge_wtw_activites',
        'child_care_under_13',
        'child_care_special_needs',
        'child_care_arrengements_meet_needs',
      ]
      questionsToClear.forEach(questionID => {
        effectCollector.add(questionID, null)
      })
    }
    // The ids in the household composition table
    const idsInHousehold = filteredAnswer.map(
      householdMember => householdMember.id
    )
    // Retrieve the default children information for whichever table requests the data
    const retrieveDefaultChildrensData = (
      childrenInHouseholdMembers,
      table
    ) => {
      return childrenInHouseholdMembers.map(child => {
        const {
          id,
          household_name,
          household_relationship,
          household_age,
        } = child

        const relationshipToDisplay = find(relationshipTypes, {
          optionId: household_relationship,
        })

        if (table === 'child_care_status_table') {
          return {
            childcare_name: household_name || '',
            childcare_relationship: relationshipToDisplay?.optionLabel || '',
            id: id,
            childcare_age: household_age || '',
          }
        } else if (table === 'child_issues_card') {
          return {
            chld_name: household_name || '',
            relationship: relationshipToDisplay?.optionLabel || '',
            id: id,
          }
        } else if (table === 'parenting_and_child_support_table') {
          return {
            pcs_name: household_name || '',
            pcs_relationship: relationshipToDisplay?.optionLabel || '',
            id: id,
            use_same_values: ['SV'],
          }
        } else {
          return null
        }
      })
    }

    // *********************************************************************************
    //                         Child Care Status table
    // *********************************************************************************
    // If there are no answers created for the childcare status table, create
    // them based on the children present in the household composition
    if (childcareStatusAnswers.length === 0) {
      const defaultChildcareStatusAnswers = retrieveDefaultChildrensData(
        childrenInHouseholdMembers,
        'child_care_status_table'
      )
      effectCollector.add(
        'child_care_status_table',
        defaultChildcareStatusAnswers
      )
    } else {
      // Retrieve the children in the childcare status table by id
      const idsInChildcareStatusAnswers = childcareStatusAnswers.map(
        child => child.id
      )
      // Check to see if any more children have been added to the household
      const childrenForChildcare = []
      if (!isEqual(idsInHousehold, idsInChildcareStatusAnswers)) {
        const unAddedChildren = childrenInHouseholdMembers.filter(child => {
          return !idsInChildcareStatusAnswers.includes(child.id)
        })

        if (unAddedChildren) {
          unAddedChildren.forEach(child => {
            const {
              id,
              household_name,
              household_relationship,
              household_age,
            } = child

            const relationshipToDisplay = find(relationshipTypes, {
              optionId: household_relationship,
            })
            childrenForChildcare.push({
              childcare_name: household_name || '',
              childcare_relationship: relationshipToDisplay?.optionLabel || '',
              id: id,
              childcare_age: household_age || '',
            })
          })
        }
      }
      // Filter out the people in the childcare status table
      // that have id's that are no longer on the list
      const newChildrenForChildcareAnswers = [
        ...childrenForChildcare,
        ...childcareStatusAnswers,
      ]
        .filter(child => idsInHousehold.includes(child.id))
        .map(child => {
          const updatedChild = { ...child }
          // Check to see if the name or relationship changed for any of the ids
          // present in the childcare status table, update if needed
          const householdRowInformation = find(filteredAnswer, {
            id: child.id,
          })
          if (!householdRowInformation) {
            return child
          }
          const {
            household_name,
            household_relationship,
            household_age,
          } = householdRowInformation

          const relationshipToDisplay = find(relationshipTypes, {
            optionId: household_relationship,
          })

          updatedChild.childcare_name = household_name || ''
          updatedChild.childcare_relationship =
            relationshipToDisplay?.optionLabel || ''
          updatedChild.childcare_age = household_age

          return updatedChild
        })

      effectCollector.add(
        'child_care_status_table',
        newChildrenForChildcareAnswers
      )
    }

    // *********************************************************************************
    //                         Parenting and Child Support Table
    // *********************************************************************************
    if (parentingAndChildSupportAnswers.length === 0) {
      const defaultParentAndChildSupportAnswer = retrieveDefaultChildrensData(
        childrenInHouseholdMembers,
        'parenting_and_child_support_table'
      )
      effectCollector.add(
        'parenting_and_child_support_table',
        defaultParentAndChildSupportAnswer
      )
    } else {
      const idsInParentingAndChildSupportAnswers = parentingAndChildSupportAnswers.map(
        child => child.id
      )
      const childrenToAddParentingChildSupport = []

      if (!isEqual(idsInParentingAndChildSupportAnswers, idsInHousehold)) {
        const unAddedChildren = childrenInHouseholdMembers.filter(child => {
          return !idsInParentingAndChildSupportAnswers.includes(child.id)
        })
        if (unAddedChildren) {
          unAddedChildren.forEach(child => {
            const { id, household_name, household_relationship } = child

            const relationshipToDisplay = find(relationshipTypes, {
              optionId: household_relationship,
            })
            childrenToAddParentingChildSupport.push({
              pcs_name: household_name,
              id: id || '',
              pcs_relationship: relationshipToDisplay?.optionLabel || '',
              use_same_values: ['SV'],
            })
          })
        }
      }
      // Filter out the people in the children with issues table
      // that have id's that are no longer on the list
      const updatedParentingAndChildSupportAnswers = parentingAndChildSupportAnswers
        .filter(child => idsInHousehold.includes(child.id))
        .map((child, index) => {
          // Check to see if the name or relationship changed for any of the ids
          // present in the children with issues table, update if needed
          const updatedChild = { ...child }
          const householdRowInformation = find(filteredAnswer, {
            id: child.id,
          })
          const {
            id,
            household_name,
            household_relationship,
          } = householdRowInformation

          const relationshipToDisplay = find(relationshipTypes, {
            optionId: household_relationship,
          })
          updatedChild.id = id || ''
          updatedChild.pcs_relationship =
            relationshipToDisplay?.optionLabel || ''
          updatedChild.pcs_name = household_name
          return updatedChild
        })
      const hasNoChildrenToUpdate = isEmpty(
        updatedParentingAndChildSupportAnswers
      )
      const lastChildData = hasNoChildrenToUpdate
        ? {}
        : getLastChildData(updatedParentingAndChildSupportAnswers)
      const formattedNewChildren = childrenToAddParentingChildSupport.map(
        child => ({
          ...child,
          ...lastChildData,
        })
      )
      const newChildrenAnswers = [
        ...updatedParentingAndChildSupportAnswers,
        ...formattedNewChildren,
      ]
      effectCollector.add(
        'parenting_and_child_support_table',
        newChildrenAnswers
      )
    }

    // *********************************************************************************
    //                             Children's issues table
    // *********************************************************************************
    // If there are no answers created for the children's issues table, create
    // them based on the children present in the household composition
    if (childrensIssuesAnswers.length === 0) {
      const defaultChildIssuesAnswers = retrieveDefaultChildrensData(
        childrenInHouseholdMembers,
        'child_issues_card'
      )
      effectCollector.add('child_issues_card', defaultChildIssuesAnswers)
    } else {
      const idsInChildrensIssuesAnswers = childrensIssuesAnswers.map(
        child => child.id
      )
      // Check to see if any more children have been added to the household
      const childrenToAdd = []
      if (!isEqual(idsInChildrensIssuesAnswers, idsInHousehold)) {
        const unAddedChildren = childrenInHouseholdMembers.filter(child => {
          return !idsInChildrensIssuesAnswers.includes(child.id)
        })
        if (unAddedChildren) {
          unAddedChildren.forEach(child => {
            const { id, household_name, household_relationship } = child

            const relationshipToDisplay = find(relationshipTypes, {
              optionId: household_relationship,
            })
            childrenToAdd.push({
              chld_name: household_name || '',
              relationship: relationshipToDisplay?.optionLabel || '',
              id,
            })
          })
        }
      }
      // Filter out the people in the children with issues table
      // that have id's that are no longer on the list
      const newChildrenAnswers = [...childrensIssuesAnswers, ...childrenToAdd]
        .filter(child => idsInHousehold.includes(child.id))
        .map(child => {
          // Check to see if the name or relationship changed for any of the ids
          // present in the children with issues table, update if needed
          const updatedChild = { ...child }
          const householdRowInformation = find(filteredAnswer, {
            id: child.id,
          })
          const {
            id,
            household_name,
            household_relationship,
          } = householdRowInformation

          const relationshipToDisplay = find(relationshipTypes, {
            optionId: household_relationship,
          })
          updatedChild.chld_name = household_name || ''
          updatedChild.relationship = relationshipToDisplay?.optionLabel || ''
          updatedChild.id = id
          return updatedChild
        })
      effectCollector.add('child_issues_card', newChildrenAnswers)
    }

    // *********************************************************************************
    //                                PREGNANCY TABLE
    // *********************************************************************************
    // *********************************************************************************
    // If there is an answer in the Pregnancy table that is no longer valid (row was
    // deleted, user changes gender from Female/Other to Male, etc.) remove that row
    // from the answer.
    // *********************************************************************************
    const pregnancyTableAnswers =
      store.getState()[stateProp].pregnancy_table?.answer || []

    if (pregnancyTableAnswers.length !== 0) {
      // Filter out people that have been deleted from the household composition table
      // and then update their name.
      const updatedAnswer = pregnancyTableAnswers
        .filter(member => {
          // If the person was added to the pregnancy table but they're a free-form entry
          // (not listed in the household members table) else check if they're id is still
          // in the household members table.
          if (
            Array.isArray(member.household_mem) &&
            !member.household_mem.includes('HM')
          ) {
            return true
          } else {
            return idsInHousehold.includes(member.id)
          }
        })
        .map(member => {
          if (
            Array.isArray(member.household_mem) &&
            !member.household_mem.includes('HM')
          ) {
            return member
          } else {
            const memberCopy = { ...member }
            const householdRowInformation = find(filteredAnswer, {
              id: member.id,
            })
            // Update names and relationship in pregnancy table if it has changed.
            memberCopy.name = householdRowInformation.household_name
            memberCopy.household_mem_rel =
              householdRowInformation.household_relationship
            return memberCopy
          }
        })

      effectCollector.add('pregnancy_table', updatedAnswer)
    }

    if (isChangeConditional) {
      effectCollector.add('household_members_table', filteredAnswer)
    }
  }

  return effectCollector.effects
}

export default effects;