import React from 'react'
import { connect } from 'react-redux'
import { createSelector, compose, bindActionCreators } from '@reduxjs/toolkit'
import styled, { css } from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import DialogRoot from 'components/general/DialogRoot'
import Navbar from 'components/navigation/Navbar'
import AdminNavbar from 'components/navigation/AdminNavbar'
import BRENavbar from 'components/navigation/BRENavbar'
import InterviewHeader from 'components/interview/InterviewHeader'
import Header from 'components/header/Header'
import Logo from 'components/header/Logo'
import TimedOut from 'components/general/TimedOut'
import RouteManager from 'app/RouteManager'
import { navigateToPage } from 'ducks/navigationSlice'
import { openDialog } from 'ducks/dialogSlice'
import { withRouter } from 'react-router'
import backListener from 'app/backListener'
import NextButton from '../components/general/NextButton'
import { getCategoriesAndDomains } from 'thunks/retrieveInterviewData'
import { setPageVisited } from 'thunks/setPageVisited'
import SnackbarRoot from 'components/general/SnackbarRoot'
import ResourceSideBar from 'components/resources/ResourceSideBar'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'

import { saveData } from 'thunks/saveData'
import { saveActionPlans } from 'thunks/saveActionPlans'
import { getLastSavedDate } from 'thunks/getLastSavedDate'
import { fetchEnvironment } from 'thunks/fetchEnvironment'

const Container = styled.div`
  /* autoprefixer grid: autoplace */
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 280px 1fr;
  grid-template-columns: 280px 1fr;
  -ms-grid-rows: ${props =>
    props.banner ? '60px 79px calc(100vh - 139px)' : '79px calc(100vh - 80px)'};
  grid-template-rows: ${props =>
    props.banner ? '60px 79px calc(100vh - 139px)' : '79px calc(100vh - 80px)'};

  ${props => (props.showSidebar ? withSidebar : noSidebar)};
`

const Banner = styled.div`
  grid-area: banner;
  height: 60px;
  text-align: center;
  padding: 8px;
  font-size: 30px;
  font-weight: 700;
  width: 100vw;
  color: ${props => (props.environment === 'uat' ? '#fff' : '#000')};
  background-color: ${props =>
    props.environment === 'uat'
      ? '#66cc66'
      : props.environment === 'test'
      ? '#ff9933'
      : '#11b1eb'};
`

const Sidebar = styled.div`
  grid-area: sidebar;
  overflow: auto;
  padding: 16px;
`

const ContentContainer = styled.div`
  grid-area: main;
  overflow: auto;
`

const Content = styled.div`
  width: 100%;
  padding: 16px;
`

const noSidebar = css`
  grid-template-areas: ${props =>
    props.banner
      ? `'banner banner' 'logo header' 'main main'`
      : `'logo header' 'main main'`};
  > ${ContentContainer} {
    -ms-grid-row: ${props => (props.banner ? '3' : '2')};
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
  > ${Banner} {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
`

const withSidebar = css`
  grid-template-areas: ${props =>
    props.banner
      ? `'banner banner' 'logo main' 'sidebar main'`
      : `'logo main' 'sidebar main'`};
  > ${Sidebar} {
    -ms-grid-row: ${props => (props.banner ? '3' : '2')};
    -ms-grid-column: 1;
  }
  > ${ContentContainer} {
    -ms-grid-row: ${props => (props.banner ? '2' : '1')};
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
  }
  > ${Banner} {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
`

const environmentSelector = state => state.environment.name;

const interviewDataReadySelector = state => state.ready.interview
const interviewDataLoadedSelector = state => state.interview
const resourcesDataLoadedSelector = state =>
  !isEmpty(state.resources.resourceTypes)

// Loading is only complete when the ready state is set
// and interview data is loaded
const interviewLoadingCompleteSelector = createSelector(
  [
    interviewDataReadySelector,
    interviewDataLoadedSelector,
    resourcesDataLoadedSelector,
  ],
  (ready, interview) => ready === true && !isEmpty(interview)
)

const currentInterviewSelector = state => state.client.currentInterview
const currentClientSelector = state => state.client.currentClient
const userRolesSelector = state => state.user.roles

const interviewSelectedSelector = createSelector(
  [currentInterviewSelector, currentClientSelector],
  (currentInterview, currentClient) =>
    currentInterview !== null && currentClient !== null
)

const newInterviewSelector = state => state.newInterview.interviewLoading

const loggedInSelector = state => state.user.loggedIn

const userNotFoundSelector = state => state.user.userNotFound
const breRoleNotAssignedSelector = state => state.user.breRoleNotAssigned


const userTimedOutSelector = state => state.session.isTimedOut
const userInactiveSelector = state => state.user.isDisabled

const newInterview = createSelector(newInterviewSelector, loading => loading)

const userDataReadySelector = state => state.ready.user

const selectUserDataReady = createSelector(
  userDataReadySelector,
  userReady => userReady
)
const isLoggedIn = createSelector(loggedInSelector, loggedIn => loggedIn)

const userNotFound = createSelector(
  userNotFoundSelector,
  userNotFound => userNotFound
)

const breRoleNotAssigned = createSelector(
  breRoleNotAssignedSelector,
  breRoleNotAssigned => breRoleNotAssigned
)

const userTimedOut = createSelector(
  userTimedOutSelector,
  userTimedOut => userTimedOut
)

const userInactive = createSelector(
  userInactiveSelector,
  userInactive => userInactive
)

const userRoleCodes = createSelector(userRolesSelector, roles => roles)

const mapStateToProps = state => {
  return {
    interviewLoadingComplete: interviewLoadingCompleteSelector(state),
    interviewSelected: interviewSelectedSelector(state),
    newInterviewLoading: newInterview(state),
    interviewCompletedOrCancelled: cancelledOrCompletedSelector(state),
    userLoadingComplete: selectUserDataReady(state),
    isLoggedIn: isLoggedIn(state),
    userNotFound: userNotFound(state),
    userTimedOut: userTimedOut(state),
    userInactive: userInactive(state),
    userRoles: userRoleCodes(state),
    breRoleNotAssigned: breRoleNotAssigned(state),
    environment: environmentSelector(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCategoriesAndDomains,
        navigateToPage,
        openDialog,
        saveData,
        saveActionPlans,
        getLastSavedDate,
        setPageVisited,
        fetchEnvironment
      },
      dispatch
    ),
  }
}

class App extends React.Component {
  componentDidMount() {
    backListener(this.props.history)
    this.props.actions.fetchEnvironment()
  }

  componentDidUpdate() {
    const timerFrequency = 5
    const timerFrequencyMilliseconds = timerFrequency * 60 * 1000

    const onInterviewPage = this.props.location?.pathname.startsWith(
      '/interview/'
    )

    // Attempt to autosave every 5min if user is currently on an interview page and the
    // interview is not completed or cancelled.
    if (
      onInterviewPage &&
      !this.autoSaveIntervalId &&
      !this.props.interviewCompletedOrCancelled
    ) {
      this.autoSaveIntervalId = setInterval(
        this.autoSave,
        timerFrequencyMilliseconds
      )
    }

    if (!onInterviewPage && this.autoSaveIntervalId) {
      clearInterval(this.autoSaveIntervalId)
      this.autoSaveIntervalId = null
    }
  }

  autoSave = () => {
    // skip auto save if the user is invalid
    if(!this.props.isLoggedIn || this.props.userNotFound || this.props.userTimedOut) {
      return
    }

    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportEvent', 'autoSave');
    this.props.actions.saveData({ disableNotifications: true })
    this.props.actions.saveActionPlans()
    this.props.actions.getLastSavedDate()
  }

  render() {
    const environmentForBanner = this.props.environment

    const shouldShowBanner =
      environmentForBanner === 'test' ||
      environmentForBanner === 'training' ||
      environmentForBanner === 'uat'

    const bannerText =
      environmentForBanner === 'uat'
        ? 'UAT Environment'
        : environmentForBanner === 'test'
        ? 'Test Environment'
        : 'Training Environment'

    if (this.props.userNotFound || this.props.userInactive) {
      this.props.actions.openDialog({
        type: 'authError',
        title: `Access Not Granted to OCAT`,
        description:
          'You do not have access to OCAT with this account. Please contact your Supervisor to request OCAT access.',
      })
      return (
        <Container banner={shouldShowBanner}>
          {shouldShowBanner && (
            <Banner environment={environmentForBanner}>{bannerText}</Banner>
          )}
          <Logo banner={shouldShowBanner} />
          <Header showContent={false} banner={shouldShowBanner} />
          <DialogRoot />
        </Container>
      )
    }
    
    if (this.props.breRoleNotAssigned) {
      this.props.actions.openDialog({
        type: 'authError',
        title: `BRE Role Not Assigned`,
        description:
          'Access to BRE functionality is not enabled in ForgeRock. Please contact someone to grant access through ForgeRock.',
      })
      return (
        <Container banner={shouldShowBanner}>
          {shouldShowBanner && (
            <Banner environment={environmentForBanner}>{bannerText}</Banner>
          )}
          <Logo banner={shouldShowBanner} />
          <Header showContent={false} banner={shouldShowBanner} />
          <DialogRoot />
        </Container>
      )
    }

    if (this.props.userTimedOut) {
      return (
        <Container banner={shouldShowBanner}>
          {shouldShowBanner && (
            <Banner environment={environmentForBanner}>{bannerText}</Banner>
          )}
          <Logo banner={shouldShowBanner} />
          <Header showContent={false} banner={shouldShowBanner} />
          <ContentContainer banner={shouldShowBanner}>
            <TimedOut />
          </ContentContainer>
        </Container>
      )
    }

    if (!this.props.isLoggedIn) return null

    const onInterviewPage =
      this.props.location?.pathname.startsWith('/interview/') &&
      this.props.interviewLoadingComplete

    const onInterviewPreview =
      this.props.location?.pathname.startsWith('/preview/')

    const onAppraisalPage = this.props.location?.pathname.endsWith('appraisal')
    // const onPreviewAppraisalPage = this.props.location?.pathname.startsWith('/preview/') && this.props.location?.pathname.endsWith('appraisal')

    const onAdminPage = this.props.location?.pathname.startsWith('/administration')
    const onBRE = this.props.location?.pathname.startsWith('/bre')

    const onResourcesPage = this.props.location?.pathname.startsWith(
      '/resources'
    )
    const { getCategoriesAndDomains, setPageVisited } = this.props.actions

    const interviewLoadingComplete = this.props.interviewLoadingComplete
    const interviewSelected = this.props.interviewSelected
    const newInterviewLoading = this.props.newInterviewLoading
    const userLoadingComplete = this.props.userLoadingComplete

    if (newInterviewLoading || !userLoadingComplete) {
      // TODO -- improve loading
      return <div>Loading...</div>
    }
    // Only execute this logic if loading isn't complete, just in case
    // App re-renders for any reason, but doesn't need to re-fetch data
    if (!interviewLoadingComplete && interviewSelected) {
      // Get the interview categories and domains from the db as this only
      // needs to be fetched once
      getCategoriesAndDomains().then(() => {
        // Ensure the initial page's interview progress has been marked complete
        Can({
          roles: this.props.userRoles,
          perform: 'interview:edit',
          no: () => null,
          yes: () => {
            const subdomainId = this.props.location?.pathname?.split('/').pop()
            setPageVisited(subdomainId)
          },
        })
      })

      // TODO -- improve loading
      return <div>Loading...</div>
    }

    return (
      <Container
        showSidebar={onInterviewPage || onInterviewPreview || onAdminPage || onResourcesPage || onBRE ? 1 : 0}
        banner={shouldShowBanner}
      >
        {shouldShowBanner && (
          <Banner environment={environmentForBanner}>{bannerText}</Banner>
        )}
        <Logo data-testid='ocat-logo' banner={shouldShowBanner} />
        {onInterviewPage && (
          <Sidebar banner={shouldShowBanner}>
            <Navbar />
          </Sidebar>
        )}
        {onInterviewPreview && (
          <Sidebar banner={shouldShowBanner}>
            <Navbar isPreview="true" />
          </Sidebar>
        )}

      {onBRE && (
          <Sidebar banner={shouldShowBanner}>
            <BRENavbar></BRENavbar>
          </Sidebar>
        )}

        {onAdminPage && (
          <Sidebar banner={shouldShowBanner}>
            <AdminNavbar></AdminNavbar>
          </Sidebar>
        )}

        {onResourcesPage && (
          <Sidebar banner={shouldShowBanner}>
            <ResourceSideBar />
          </Sidebar>
        )}

        {!onAdminPage && !onInterviewPage && !onInterviewPreview && !onResourcesPage && !onBRE ? (
          <Header banner={shouldShowBanner} />
        ) : null}

        <ContentContainer banner={shouldShowBanner}>
          {onInterviewPage || onInterviewPreview || onAdminPage || onResourcesPage || onBRE ? (
            <Header banner={shouldShowBanner} />
          ) : null}

          {onInterviewPage && <InterviewHeader />}
          {onInterviewPreview && /* TODO: need to update/duplicate header */<InterviewHeader isPreview={onInterviewPreview} />}
          <Content>
            <RouteManager />
          </Content>
          {onInterviewPage && !onAppraisalPage && interviewLoadingComplete && (
            <NextButton label='Next' />
          )}
          {/* {onInterviewPreview && (
            // TODO: Need to update NextButton for Preview
            <NextButton label='Next' isPreview="true" />
          )} */}
        </ContentContainer>
        <SnackbarRoot />
        <DialogRoot />
      </Container>
    )
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App)
