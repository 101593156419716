import { createSlice, createSelector } from '@reduxjs/toolkit'
import navData from '../sample-json/categoriesAndDomains.json'

const initialState = {navData, recommendationsLoaded: false, questionnaireLoaded: false, questionnaireLoading: false, recommendations: [] }

// just a selector that will always return false because the preview should never be cancelled or completed
export const previewCancelledOrCompletedSelector = createSelector(
  _ => _,
  state => false
)

const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    captureData: (state, action) => {
      // Changes are generated inside of the captureFormData thunk
      // For each change, update state's value
      for (let change of action.payload) {
        state[change.question_id].answer = change.answer
        state[change.question_id].indicator = change.indicator
      }
    },
    clearInterviewData: (state, action) => initialState,
    storeInterviewData: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    revertInterviewData: (state, action) => {
      Object.keys(action.payload.unsavedChanges).forEach(questionId => {
        const originalValue = action.payload.originalAnswers[questionId]
        // Set current interview data to its original value
        state[questionId].answer = originalValue
      })
    },
    setPreviewRecommendationsLoading: (state, action) => {
      state.recommendationsLoaded = action.payload
    },
    setPreviewRecommendations: (state, action) => {
      state.recommendations = action.payload
    },
    setPreviewQuestionnaireLoading: (state, action) => {
      state.questionnaireLoading = action.payload
    },
    setPreviewQuestionnaireLoaded: (state, action) => {
      state.questionnaireLoaded = action.payload
    }
  },
})

export const {
  captureData,
  storeInterviewData,
  revertInterviewData,
  clearInterviewData,
  setPreviewRecommendationsLoading,
  setPreviewRecommendations,
  setPreviewQuestionnaireLoading,
  setPreviewQuestionnaireLoaded
} = previewSlice.actions

export default previewSlice.reducer
