import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Info = styled.div`
  flex: 1 0 100%;
  display: block;
  padding-bottom: 16px;
`

const InfoTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`

const getQuestionText = (questionType, questionConfig) => {
  switch (questionType) {
    case 'checkbox': {
      return questionConfig.options[0].optionLabel
    }
    case 'table_question':
    case 'checkbox_list':
    case 'dropdown':
    case 'radio':
    case 'textarea': {
      return questionConfig.description
    }
    case 'dropdown_label':
    case 'textbox': {
      return questionConfig.label
    }
    default:
      throw new Error('Unhandled question type')
  }
}

export const InterviewImpactsDialogContent = ({ impacts }) => {
  const { ruleImpacts, questionImpacts } = impacts.reduce((acc, impact) => {
      if(impact.changeType.includes('RULE')) {
          acc.ruleImpacts[impact.recordId] = impact
      }
      else {
          acc.questionImpacts[impact.recordId] = impact
      }
      return acc
  }, { ruleImpacts: {}, questionImpacts: {} })
  const questionImpactElements = Object.values(questionImpacts).map(impact => {
    return (
      <Info key={impact.id}>
        <InfoTitle variant='body1'>{impact.question.SubDomain.name}: {getQuestionText(impact.question.questionType.code, impact.question.questionConfig)}</InfoTitle>
      </Info>
    )
  })
  return (
    <div>
        {Object.values(questionImpactElements).length > 0 &&(
        <div>
            <div>The following questions were modified so any saved responses for these questions have been removed.</div>
            {questionImpactElements}
        </div>
        )}
        {ruleImpacts.length > 0 &&(
        <div>
            <div>Recommendations were regenerated and must be reviewed again.</div>
        </div>
        )}
    </div>
  )
}

export const InterviewImpactsDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }

  return (
    <>
      <Button onClick={handleClose} variant='contained' color='primary'>
        OK
      </Button>
    </>
  )
}
