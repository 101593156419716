import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { colors } from 'app/theme'
import {
  setCurrentResourceType,
  setCurrentResourceId,
  loadCurrentResource,
} from 'ducks/resourceSlice'

const Discard = styled.div`
  flex: 1 0 100%;
  display: block;
  padding-bottom: 16px;
`

const DiscardTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`

const DiscardButton = styled(Button)`
  && {
    color: ${colors.red};
  }
`

export const DiscardResourceDialogContent = () => {
  return (
    <div>
      <Discard>
        <DiscardTitle variant='body1'>
          You have unsaved changes that will be lost if you continue.
        </DiscardTitle>
        <Typography variant='body2'>
          Are you sure you want to leave this section?
        </Typography>
      </Discard>
    </div>
  )
}

export const DiscardResourceDialogActions = ({
  newResourceType,
  newResourceId,
}) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeDialog())
  }

  const handleDiscard = () => {
    dispatch(setCurrentResourceType({ currentResourceType: newResourceType }))
    dispatch(setCurrentResourceId({ currentResourceId: newResourceId }))
    dispatch(loadCurrentResource())
    dispatch(closeDialog())
  }

  return (
    <>
      <DiscardButton onClick={handleDiscard}>Discard Changes</DiscardButton>
      <Button onClick={handleClose}>Cancel</Button>
    </>
  )
}
