import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import Grid from '@material-ui/core/Grid'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteCheckboxGrid from 'components/table/InfiniteCheckboxGrid'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteText from 'components/table/InfiniteText'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import styled from 'styled-components'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Text from 'components/general/Text'
import DatePicker from 'components/general/DatePicker'
import Select from 'components/general/Select'
import Dropdown from 'components/general/Dropdown'
import ControlDescription from 'components/general/ControlDescription'
import Link from '@material-ui/core/Link'
import { colors } from 'app/theme'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Indicator from 'components/general/Indicator'
import AsrButton from 'components/general/RetrieveAsrPdfButton'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_RESOURCE_LINK } from '../help/Help'

const rowStyle = {
  marginTop: '64px',
  marginLeft: '-20px',
  marginRight: '10px',
}

const textStyle = {
  marginTop: '37px',
  marginLeft: '-20px',
  marginRight: '10px',
}

export const dobStyle = {
  marginTop: '35px',
  marginLeft: '-18px',
  marginRight: '10px',
}

export const selectStyle = {
  marginBottom: '-6px',
}

const raceStyle = { marginTop: '-25px', marginLeft: '146px' }

const ControlTypography = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`
const BlankSpacing = styled.div`
  margin: 2rem;
`

const GridContainer = styled(Grid)`
  margin-bottom: 8px;
`

const InputContainer = styled.div`
  display: inline-block;
`

const languageTableIndicatorSelector = state =>
  state.interview.language_table.indicator
const previewLanguageTableIndicatorSelector = state =>
  state.preview.language_table.indicator

const selectLanguageTableIndicator = createSelector(
  languageTableIndicatorSelector,
  indicator => indicator
)
const selectPreviewLanguageTableIndicator = createSelector(
  previewLanguageTableIndicatorSelector,
  indicator => indicator
)

const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
  }
`

const specifyOtherSelector = (state, question_id, index) =>
  state.interview[question_id].answer[index].language
const previewSpecifyOtherSelector = (state, question_id, index) =>
  state.preview[question_id].answer[index].language
const createSpecifyOtherSelector = (isPreview) => {
  return createSelector(
    (isPreview ? previewSpecifyOtherSelector : specifyOtherSelector),
    answer => answer !== undefined && answer?.toLowerCase() === '00'
  )
}



const LanguagesTableRow = props => {
  const { index, question_id, isPreview } = props
  const specifyOtherSelector = createSpecifyOtherSelector(isPreview)
  const specifyOther = useSelector(state =>
    specifyOtherSelector(state, question_id, index)
  )

  return (
    <>
      <TableCell align='center'>
        <InfiniteDropdown noTable sub_question_id={'language'} isPreview={isPreview} {...props} />
        {specifyOther && (
          <InfiniteText
            noTable
            isDisabled={!specifyOther}
            hideLabel={false}
            sub_question_id={'language_specify'}
            isPreview={isPreview}
            {...props}
          />
        )}
      </TableCell>
      <InfiniteCheckboxGrid
        hideOptionLabel
        sub_question_id={'speaking'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteCheckboxGrid
        hideOptionLabel
        sub_question_id={'reading'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteCheckboxGrid
        hideOptionLabel
        sub_question_id={'writing'}
        isPreview={isPreview}
        {...props}
      />
    </>
  )
}

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const OtherParentTableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const otherParentTableHeader = [
  { name: 'Name', colWidth: '20%' },
  { name: 'Client Index Number', colWidth: '20%' },
  { name: 'SAWS Case Number', colWidth: '20%' },
  { name: 'Appraisal Summary', colWidth: '20%' },
]

const otherParentTable = relatedCases => {
  const tableRows = relatedCases?.map((relatedCase, index) => {
    const { cin, name, caseNumber, id } = relatedCase
    return (
      <TableRow key={`otherParentTableRow-${index}`}>
        <TableCell>{name}</TableCell>
        <TableCell>{cin}</TableCell>
        <TableCell>{caseNumber}</TableCell>
        <TableCell>
          <AsrButton interviewId={id} />
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      <Typography variant='h5'>Other Parent</Typography>
      <CustomTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {otherParentTableHeader.map((header, index) => (
                <OtherParentTableHeaderCell
                  key={index}
                  colwidth={header.colWidth}
                >
                  {header.name}
                </OtherParentTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </CustomTableContainer>
    </>
  )
}

const RightsAndPrivacyLink = () => {
  const [queryStaticResource, { data: resourceData, loading }] = useLazyQuery(
    GET_RESOURCE_LINK,
    {
      fetchPolicy: 'network-only',
    }
  )
  
  const getResourceLink = resourceId => {
    queryStaticResource({
      variables: { resourceId }
    })
  }
  
  useEffect(() => {
    if(resourceData && !loading) {
      setTimeout(() => {
        window.open(resourceData.getResourceLink)
      })
    }
  }, [resourceData, loading])
  
  const handleClick = () => 
    getResourceLink('rightsAndPrivacy')
  return (
    <StyledLink
      onClick={handleClick}
      target='_blank'
      rel='noreferrer'
    >
      OCAT Rights and Privacy Notice
    </StyledLink>
  )
}

const InterviewPreamble = () => (
  <>
    <Typography variant='h5'>Preamble</Typography>
    <Typography variant='body1'>
      Instructions to worker: Following is guidance, not intended to be read to
      the client.
    </Typography>
    <Typography variant='body1'>
      Ensure that the individual to be interviewed has been given a copy of the
      OCAT Rights and Privacy Overview form. Remind the individual that he or
      she does not have to answer all the questions asked if uncomfortable, but
      more answers means a more accurate and complete appraisal. Also remind
      individual that the interview is confidential except for mandated
      reporting of suspected elder and child abuse.
    </Typography>
    <RightsAndPrivacyLink />
  </>
)


const clientAddress = (isPreview) =>  (
  <>
    <Grid item xs={12}>
      <Typography variant='h5'>Physical Address</Typography>
    </Grid>
    <GridContainer container justifyContent='flex-start' spacing={2}>
      <InputContainer>
        <Text
          question_id='address_1'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='address_2'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='city'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
    </GridContainer>
    <GridContainer container justifyContent='flex-start' spacing={2}>
      <InputContainer>
        <Dropdown question_id='state' sawsIndicator={true} isPreview={isPreview} />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='zip'
          type='zip'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          isPreview={isPreview}
        />
      </InputContainer>
    </GridContainer>

    <Grid item xs={12}>
      <Typography variant='h5'>Mailing Address</Typography>
    </Grid>
    <GridContainer container justifyContent='flex-start' spacing={2}>
      <InputContainer>
        <Text
          question_id='address_1_mail'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='address_2_mail'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='city_mail'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          shouldDecode={true}
          isPreview={isPreview}
        />
      </InputContainer>
    </GridContainer>
    <GridContainer container justifyContent='flex-start' spacing={2}>
      <InputContainer>
        <Dropdown question_id='state_mail' sawsIndicator={true} isPreview={isPreview} />
      </InputContainer>
      <InputContainer>
        <Text
          question_id='zip_mail'
          type='zip'
          sawsIndicator={true}
          indicatorStyle={textStyle}
          isPreview={isPreview}
        />
      </InputContainer>
    </GridContainer>
  </>
)

const clientSelector = state => {
  return state.client
}
const currentClient = createSelector(clientSelector, client => client)

const militaryExperienceSelector = state =>
  state.interview.military_experience.answer
const previewMilitaryExperienceSelector = state =>
  state.preview.military_experience.answer
const selectNoMilitaryExperience = createSelector(
  militaryExperienceSelector,
  answer => answer?.toUpperCase() !== 'YS'
)
const selectPreviewNoMilitaryExperience = createSelector(
  previewMilitaryExperienceSelector,
  answer => answer?.toUpperCase() !== 'YS'
)

const languageTableQuestionSelector = state => state.interview.language_table
const previewLanguageTableQuestionSelector = state => state.preview.language_table

const languageTableQuestion = createSelector(languageTableQuestionSelector, q => q)
const previewLanguageTableQuestion = createSelector(previewLanguageTableQuestionSelector, q => q)

const DemographicsGeneralInformation = ({ isPreview }) => {
  console.log(`rendering as preview: ${isPreview}`)
  const disableMilitaryRelatedQuestions = useSelector(
    isPreview ? selectPreviewNoMilitaryExperience : selectNoMilitaryExperience
  )

  const interviewReferenceData = useSelector(currentClient)

  const indicator = useSelector(isPreview ? selectPreviewLanguageTableIndicator : selectLanguageTableIndicator)
  const languageQuestion = useSelector(isPreview ? previewLanguageTableQuestion : languageTableQuestion)


  const languageTableHeaders = [
    languageQuestion.sub_question_ids.language.label,
    languageQuestion.sub_question_ids.speaking.options[0].optionLabel,
    languageQuestion.sub_question_ids.reading.options[0].optionLabel,
    languageQuestion.sub_question_ids.writing.options[0].optionLabel
  ]

  const interviewReferenceInfo = (
    <Grid container>
      <Grid item xs={12}>
        <ControlTypography variant='body1' disabled={true}>
          Assigned Interviewer: {decodeHtml(interviewReferenceData.assignedInterviewer)}
        </ControlTypography>
        <ControlTypography variant='body1' disabled={true}>
          Start Date: {interviewReferenceData.currentInterviewStartDate}
        </ControlTypography>
        <ControlTypography variant='body1' disabled={true}>
          End Date: {interviewReferenceData.currentInterviewEndDate}
        </ControlTypography>
        <ControlTypography variant='body1' disabled={true}>
          CIN: {interviewReferenceData.currentClientCin}
        </ControlTypography>
        <ControlTypography variant='body1' disabled={true}>
          SAWS Case Number: {interviewReferenceData.currentClientCaseNumber}
        </ControlTypography>
      </Grid>
    </Grid>
  )

  const clientBasicInfo = (
    <>
      <GridContainer container justifyContent='flex-start' spacing={2}>
        <InputContainer>
          <Text
            question_id='first_name'
            sawsIndicator={true}
            indicatorStyle={textStyle}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </InputContainer>
        <InputContainer>
          <Text
            question_id='last_name'
            sawsIndicator={true}
            indicatorStyle={textStyle}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </InputContainer>
      </GridContainer>
      <GridContainer container justifyContent='flex-start' spacing={2}>
        <InputContainer>
          <DatePicker
            question_id='dob'
            sawsIndicator={true}
            indicatorStyle={dobStyle}
            isPreview={isPreview}
          />
        </InputContainer>
        <InputContainer>
          <Text question_id='age' isDisabled={true} isPreview={isPreview} />
        </InputContainer>
      </GridContainer>
      <GridContainer container justifyContent='flex-start' spacing={2}>
        <InputContainer>
          <Text
            question_id='ssn'
            type='ssn'
            sawsIndicator={true}
            indicatorStyle={textStyle}
            isPreview={isPreview}
          />
        </InputContainer>
      </GridContainer>
      <BlankSpacing />
      <Grid item xs={12}>
        <Select
          question_id='gender'
          sawsIndicator={true}
          indicatorStyle={selectStyle}
          isPreview={isPreview}
        />
      </Grid>
    </>
  )

  const clientMilitaryInfo = (
    <>
      <Typography variant='h5'>Military Experience</Typography>
      <Select
        question_id='military_experience'
        sawsIndicator={true}
        indicatorStyle={selectStyle}
        isPreview={isPreview}
      />
      <Text
        multiline
        rows={4}
        question_id='explain_military_experience'
        isDisabled={disableMilitaryRelatedQuestions}
        sawsIndicator={true}
        indicatorStyle={rowStyle}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <Select
        question_id='honorable_discharge'
        isDisabled={disableMilitaryRelatedQuestions}
        sawsIndicator={true}
        indicatorStyle={selectStyle}
        isPreview={isPreview}
      />
    </>
  )
  return (
    <>
      <QuestionnaireHeader
        subdomain='General Information'
        domain='Demographics'
      />
      {interviewReferenceInfo}
      <Grid item xs={12}>
        <Typography variant='body2'>*Required Fields</Typography>
        <form>
          <CheckboxGrid question_id='client_no_show' isPreview={isPreview} />
          <InterviewPreamble />
          <BlankSpacing />
          {clientBasicInfo}
          {clientAddress(isPreview)}
          <Typography variant='h5'>Phone</Typography>
          <GridContainer container justifyContent='flex-start' spacing={2}>
            <InputContainer>
              <Text
                question_id='phone'
                type='phone'
                sawsIndicator={true}
                indicatorStyle={textStyle}
                isPreview={isPreview}
              />
            </InputContainer>
            <InputContainer>
              <Dropdown question_id='phone_type' sawsIndicator={true} isPreview={isPreview} />
            </InputContainer>
          </GridContainer>
          <BlankSpacing />
          <CheckboxGrid question_id='multiple_client_househousehold' isPreview={isPreview} />
          {otherParentTable(interviewReferenceData.currentClientRelatedCases)}
          <Typography variant='h5'>Race & Ethnicity</Typography>
          <ControlDescription>
            Please answer both questions about Hispanic origin and about race.
            For this interview, Hispanic origins are not races.
          </ControlDescription>
          <BlankSpacing />
          <Select
            question_id='hispanic_origin'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <CheckboxGrid
            question_id='race'
            sawsIndicator={true}
            indicatorStyle={raceStyle}
            isPreview={isPreview}
          />
          <Typography variant='h5'>Languages</Typography>

          <InfiniteTable
            rowComponent={<LanguagesTableRow isPreview={isPreview} />}
            headers={languageTableHeaders}
            question_id='language_table'
            indicator={<Indicator indicator={indicator} isPreview={isPreview} />}
            isPreview={isPreview}
          />
          {clientMilitaryInfo}
        </form>
      </Grid>
    </>
  )
}

export default DemographicsGeneralInformation
