import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'no_answer_subs_use' &&
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NA')
  ) {
    effectCollector.add('regular_consume_alcohol', null)
    effectCollector.add('consume_alcohol_frequency', null)
    effectCollector.add('consume_other_drugs', null)
  }

  if (
    question_id === 'regular_consume_alcohol' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('consume_alcohol_frequency', null)
  }

  return effectCollector.effects
}

export default effects