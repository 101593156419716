import React, { useEffect } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Can from 'app/Can'

const PrivateRoute = ({ component: Component, render, canAccess, ...rest }) => {
  const { roles, editID } = useSelector(
    createSelector(
      [state => state.user, state => state.admin],
      (user, admin) => ({
        roles: user.roles,
        editID: admin.id,
      })
    )
  )

  const privatePages = [
    { path: '/administration', perform: 'user:list', fallbackPath: '/client_list' },
    { path: '/search', perform: 'clientSearch:view', fallbackPath: '/administration' },
    { path: '/bre', perform: 'BRE:view', fallbackPath: '/administration' },
    {
      path: '/client_list',
      perform: 'clientList:view',
      fallbackPath: '/bre',
    },
    { path: '/interview/', perform: 'interview:view', fallbackPath: '/administration' },
    {
      path: '/create_client',
      perform: 'client:create',
      fallbackPath: '/administration',
    },
    {
      path: '/reports',
      perform: 'reports:view',
      fallbackPath: '/administration',
    },
    {
      path: '/resources',
    },
    {
      path: '/help',
    },
  ]
  const r = render
  // targeting root id to scroll to the top on every mount
  useEffect(() => {
    const element = document.getElementById('root')
    element.scrollIntoView()
  }, [])
  return (
    <Route
      {...rest}
      render={props => {
        if (canAccess) {
          for (let page of privatePages) {
            if (rest.path.includes(page.path)) {
              if (rest.path.includes('administration_edit') && !editID)
                return <Redirect to='/administration' />

              if (!page.perform) {
                return Component ? <Component {...props} /> : r(props)
              }
              return (
                <Can
                  perform={page.perform}
                  roles={roles}
                  no={() => <Redirect to={page.fallbackPath} />}
                  yes={() => {
                    return Component ? <Component {...props} /> : r(props)
                  }}
                />
              )
            }
          }
          return Component ? <Component {...props} /> : r(props)
        } else {
          return (
            <Redirect
              to={{
                pathname: '/client_list',
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
