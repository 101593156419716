import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { selectStyle } from 'components/interview/DemographicsGeneralInformation'

const qIds = [
  'are_you_pregnant',
  'anyone_in_house_pregnant',
  'someone_pregnant_your_child',
]

const indicatorSelectors = qIds.map(question => {
  return state => state.interview[question].answer
})

const previewIndicatorSelectors = qIds.map(question => {
  return state => state.preview[question].answer
})

const pregnancyTable = state => state.interview.pregnancy_table.answer
const previewPregnancyTable = state => state.preview.pregnancy_table.answer

// Disable skip button if any of the indicator questions above are set to "Yes" or are blank
const canSkipSelector = createSelector(indicatorSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const previewCanSkipSelector = createSelector(previewIndicatorSelectors, (...answers) => {
  for (let answer of answers) {
    if (!answer || answer.toUpperCase() === 'YS') {
      return false
    }
  }
  return true
})

const pregnancyTableSelector = createSelector(
  pregnancyTable,
  answer => answer && answer.length > 0
)

const previewPregnancyTableSelector = createSelector(
  previewPregnancyTable,
  answer => answer && answer.length > 0
)

const PregnancyIndicatoQuestions = React.memo(({ isPreview }) => {
  const canSkip = useSelector(isPreview ? previewCanSkipSelector : canSkipSelector)
  const pregnancyTableHasAnswer = useSelector(isPreview ? previewPregnancyTableSelector : pregnancyTableSelector)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Pregnancy'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select
            question_id='are_you_pregnant'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Select
            isDisabled={pregnancyTableHasAnswer}
            question_id='anyone_in_house_pregnant'
            sawsIndicator={true}
            indicatorStyle={selectStyle}
            isPreview={isPreview}
          />
          <Select question_id='someone_pregnant_your_child' isPreview={isPreview} />
        </form>
        <SkipButton isDisabled={!canSkip}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default PregnancyIndicatoQuestions
