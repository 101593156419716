import { isoToLocalDate } from '../../utilTools/dateHelper'
import {
  addDays,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { decodeHtml } from 'utilTools/decodeHtml'

// As the filters are formatted as an object for the checkboxes,
// we extract the keys for filter values
// Example: const interviewerFilterObject = { me: true, you: false}
// returns an array of filters ['me']
export const getFilterValues = filters => {
  const filterValues = []
  if (Array.isArray(filters)) {
    return filters
  }

  for (const filter in filters) {
    if (filters[filter]) {
      filterValues.push(filter)
    }
  }
  return filterValues
}

// Format ssn to only show last 4
const lastFourSsn = ssn => {
  return ssn ? ssn.substr(ssn.length - 4) : null
}

const formatAssignee = record =>
  record.Assignee?.User
    ? `${decodeHtml(record.Assignee.User.firstName)} ${decodeHtml(record.Assignee.User.lastName)}`
    : 'Unassigned'

const formatInterviewerId = record =>
  record.Assignee?.User ? record.Assignee.User.id : 'Unassigned'

const formatSource = record =>
  record.InterfaceInbound?.source ? record.InterfaceInbound.source : 'OCAT'

export const formatClientsForTable = records => {
  const displayClients = []
  records.forEach(record => {
    const decodedFirstName = record.Client ? decodeHtml(record.Client.firstName) : null
    const decodedLastName = record.Client ? decodeHtml(record.Client.lastName) : null
    const formattedClient = {
      clientId: record.Client?.id,
      name: record.Client
        ? `${decodedFirstName} ${decodedLastName}`
        : null,
      firstName: record.Client ? `${decodedFirstName}` : null,
      lastName: record.Client ? `${decodedLastName}` : null,
      dob: isoToLocalDate(record.Client?.birthDate),
      rawDob: record.Client?.birthDate,
      ssn: lastFourSsn(record.Client?.ssn),
      cin: record.Client?.cin,
      sawsCase: record.Case?.caseNumber,
      county: record.Case?.county?.description,
      countyId: record.Case?.county?.id,
      update: isoToLocalDate(record.initiatedDate),
      rawUpdate: record.updatedAt,
      asrStatus: record.currentStatus?.CVLType.description,
      interviewer: formatAssignee(record),
      source: formatSource(record),
      interviewerId: formatInterviewerId(record),
      status: record.currentStatus?.CVLType.description,
    }
    displayClients.push(formattedClient)
  })
  return displayClients
}

export const formatClientsFromSearch = records => {
  const displayClients = []
  records.forEach(record => {
    const decodedFirstName = decodeHtml(record.firstName)
    const decodedLastName = decodeHtml(record.lastName)
    const formattedClient = {
      clientId: record.clientId,
      name: `${decodedFirstName} ${decodedLastName}`,
      firstName: decodedFirstName,
      lastName: decodedLastName,
      dob: record.birthDate,
      gender: record.gender,
      ssn: lastFourSsn(record.ssn),
      cin: record.cin,
      sawsCase: record.caseNumber,
      county: record.county,
      update: isoToLocalDate(record.dateInitiated),
      asrStatus: record.appraisalStatus,
      interviewer: record.interviewer,
      interviewerId: record.interviewerId,
    }
    displayClients.push(formattedClient)
  })

  return displayClients
}

export const getDateRange = range => {
  const today = new Date()
  switch (range) {
    case 'last 7 days':
      return {
        start: startOfDay(subDays(today, 7)),
        end: endOfDay(addDays(today, 1)),
      }
    case 'last 30 days':
      return {
        start: startOfDay(subDays(today, 30)),
        end: endOfDay(addDays(today, 1)),
      }
    case 'last month':
      return {
        start: startOfMonth(subMonths(today, 1)),
        end: endOfMonth(subMonths(today, 1)),
      }
    default:
      return
  }
}

// Callback for filtering based on text inputs per column
// Each column's data values are checked based on that columns
// Text input
export const filterByTextInputs = (row, inputFilters) => {
  // If there are no input values return true
  if (isEmpty(inputFilters)) return true
  let shouldReturn = true
  // Check each filter to see if a particular row doesn't match
  // the input value for this column
  for (const filter in inputFilters) {
    const filterValueForColumn = decodeHtml(inputFilters[filter])?.toLowerCase()
    const columnValue = decodeHtml(row[filter])?.toLowerCase()
    // Check if row value for this column doesn't contain the filter value
    if (columnValue && !columnValue.includes(filterValueForColumn)) {
      // If it does not, filter out this row
      return false
    }
  }
  return shouldReturn
}

export const handleInputFilters = (filters, setFilters) => {
  return (row, text) => {
    const newInputFilters = { ...filters }
    newInputFilters[row] = text
    setFilters(newInputFilters)
  }
}

export const SPECIAL_VALUES = {
  ALL_OPTION_ID: 'all',
  ALL_OPTION_DISPLAY: 'All',
  UNASSIGNED_OPTION_ID: 'unassigned',
  UNASSIGNED_OPTION_DISPLAY: 'Unassigned',
}

export const FILTER_TYPES = {
  INTERVIEWER: 'interviewer',
  STATUS: 'status',
  DATES: 'dates',
  COUNTY: 'county',
}
