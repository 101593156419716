import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import { colors } from 'app/theme'
import { setEditUser } from 'ducks/adminSlice'
import { decodeHtml } from 'utilTools/decodeHtml'

// ADA required styling change, use Material-UI default styling method for active and focus behaviors
const StyledHeader = withStyles({
  root: {
    color: colors.turquoise,
    fontSize: '16px',
    '&:hover': {
      '& svg': {
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    '&:focus': {
      outline: `1px solid ${colors.saffron}`,
    },
  },
  active: {
    color: `${colors.turquoise}!important`,
  },
})(TableSortLabel)

// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledRow = withStyles({
  root: {
    '&:hover': {
      outline: `1px solid ${colors.saffron}`,
      outlineOffset: '-1px',
    },
  },
  selected: {
    outline: `1px solid ${colors.saffron}`,
    backgroundColor: `${colors.white} !important`,
  },
})(TableRow)

const StyledLink = styled(Link)`
  && {
    color: ${colors.black};
  }
  &:visited {
    color: ${colors.black};
  }
  &:focus {
    outline: 1px solid ${colors.saffron};
  }
  &:active {
    outline: 0;
  }
`

const adminManageHeadCells = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Email Address',
    id: 'email',
  },
  {
    label: 'Role',
    id: 'roleTitle',
  },
  {
    label: 'Supervisor',
    id: 'supervisor',
  },
  {
    label: 'Date Added',
    id: 'dateAdded',
  },
  {
    label: 'Status',
    id: 'status',
  },
]

// Handle descending sort coming from table head
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

// Leverage descendingComparator to sort descending, or the
// inverse of descendingComparator to sort ascending
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Sort the table depending on comparator
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

// Create the table header
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {adminManageHeadCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledHeader
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </StyledHeader>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// A custom sort for the first row of the table accepting text input
function TableFilterRow({ handleInputFilters }) {
  const handleChange = (e, rowId) => {
    handleInputFilters(rowId, e.target.value.toLowerCase())
  }
  return (
    <TableRow>
      {adminManageHeadCells.map((headCell, i) => {
        return (
          <TableCell key={`${headCell}-${i}`}>
            <TextField
              inputProps={{ 'aria-label': `Filter-${headCell.label}` }}
              onChange={e => handleChange(e, headCell.id)}
              placeholder='Filter'
              autoComplete='off'
            />
          </TableCell>
        )
      })}
    </TableRow>
  )
}

// ClientListTable does pagination locally (no server pagination)
const AdminSearchTable = ({ tableData, handleInputFilters }) => {
  const dispatch = useDispatch()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('update')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = row => {
    const roles = []
    for (let i = 0; i < row.roles.length; i++) {
      roles.push(row.roles[i].roleDetails.code)
    }

    // TODO: we need a better way to determine primary and secondary roles
    let userRoles
    if (roles.length === 2 && roles.indexOf('AD') === 0) {
      userRoles = [roles[1], roles[0]]
    } else {
      userRoles = [...roles]
    }

    // Sort roles by id since secondary roles is always admin and admin is id 1.
    dispatch(
      setEditUser({
        id: row.id,
        firstName: row.firstName,
        lastName: row.lastName,
        organization: row.county,
        regionID: row.regionID,
        officeID: row.officeID,
        supervisor: row.supervisor
          ? { label: row.supervisor, value: row.supervisorID }
          : '',
        status: row.status,
        email: row.email,
        role: userRoles[0],
        secondaryRole: userRoles.length > 1 ? userRoles[1] : null,
        disabledDate: row.disabledDate,
        dataExtract: row.dataExtract,
      })
    )
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage)

  return (
    <Paper>
      <TableContainer>
        <Table aria-label='admin search table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />
          <TableBody>
            <TableFilterRow handleInputFilters={handleInputFilters} />
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <StyledRow key={`${row.name}-${index}`}>
                    <TableCell>
                      <StyledLink
                        to={`/administration_edit`}
                        onClick={() => handleClick(row)}
                      >
                        {decodeHtml(row.name)}
                      </StyledLink>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.roleTitle}</TableCell>
                    <TableCell>{row.supervisor}</TableCell>
                    <TableCell>{row.dateAdded}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </StyledRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={adminManageHeadCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage=''
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AdminSearchTable
