import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ErrorIcon from '@material-ui/icons/Error'
import { colors } from 'app/theme'
import { openDialog } from 'ducks/dialogSlice'
import { validateCIN } from 'utilTools/valueCheckers'

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

const StyledTextField = styled(TextField)`
  && {
    width: 250px;
    margin-top: 5px;
    margin-right: 20px;
  }
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`

const StyledWarning = styled(ErrorIcon)`
  & {
    margin-right: 10px;
    padding-top: 5px;
    color: ${colors.saffron};
  }
`

const ClientCIN = ({
  cin,
  cinExistsQuery,
  cinError,
  setCINError,
  handleCIN,
  cinExistsLoading,
  cinExistsError,
  cinExists,
}) => {
  const dispatch = useDispatch()

  // Something went wrong, let the user know
  useEffect(() => {
    if (cinExistsError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error: cinExistsError,
          },
        })
      )
    }
  }, [cinExistsError, dispatch])

  const handleCINChange = e => {
    // Update CIN
    handleCIN(e.target.value)

    // If the CIN is valid, check to see if it exists
    const isValid = validateCIN(e.target.value)
    if (!isValid) {
      setCINError(true)
    } else {
      setCINError(false)
      cinExistsQuery({
        variables: { cin: e.target.value },
      })
    }
  }

  // Checks to see if the cin is valid, or is left empty on blur
  const handleCINBlur = async () => {
    if (cin.length < 1) {
      setCINError(true)
    }

    // If this cin is valid, make the query, else set the error
    const isValid = validateCIN(cin)
    if (isValid) {
      cinExistsQuery({
        variables: { cin },
      })
      setCINError(false)
    } else {
      setCINError(true)
    }
    handleCIN(cin)
  }

  let errorText
  if (cin.length === 0) {
    errorText = 'This field is required'
  } else if (cinExists) {
    errorText = 'CIN Exists'
  } else {
    errorText = 'Must contain 8 numbers and end with a letter'
  }

  return (
    <OuterContainer>
      <StyledTextField
        inputProps={{
          'data-testid': `CreateClient-ClientCIN-input`,
          'aria-label': `CIN input`,
          maxLength: 9,
        }}
        label='CIN'
        value={cin}
        variant='outlined'
        onChange={e => handleCINChange(e)}
        autoFocus={true}
        error={cinError}
        required
        onBlur={handleCINBlur}
        helperText={cinError ? errorText : null}
        autoComplete='off'
      />
      {!cinExistsLoading && cinExists && (
        <WarningContainer>
          <StyledWarning />
          <Typography>
            This CIN already exists. Please go back to Client Search to find the
            client.
          </Typography>
        </WarningContainer>
      )}
    </OuterContainer>
  )
}

export default ClientCIN
