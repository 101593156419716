import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { colors } from 'app/theme'
const ControlDescription = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
    font-weight: ${p => !p.disabled && 700};
    padding-top: 0px;
    margin-top: 20px;
  }
`

export default ControlDescription
