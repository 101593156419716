import React from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { saveAdmin } from 'thunks/saveAdmin'
import { useDispatch } from 'react-redux'
import { setNewUser } from 'ducks/adminSlice'
import { closeDialog } from 'ducks/dialogSlice'
import { confirmDataExtract } from 'ducks/adminSlice'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const AdminConfirmDataExtractContent = () => {
  return (
    <Container>
      <Typography>
        Are you sure you want to give this user access to the system database
        extract?
      </Typography>
    </Container>
  )
}

export const AdminConfirmDataExtractActions = ({ isNewUser }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }
  const handleConfirmation = () => {
    dispatch(confirmDataExtract())
    if (isNewUser) {
      dispatch(setNewUser({ field: 'status', value: 'Active' }))
    }
    dispatch(saveAdmin(isNewUser, true))
    dispatch(closeDialog())
  }

  return (
    <>
      <Button onClick={handleClose} variant='contained' color='secondary'>
        Cancel
      </Button>
      <Button onClick={handleConfirmation} variant='contained' color='primary'>
        Continue
      </Button>
    </>
  )
}
