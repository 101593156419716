import React from 'react'
import Button from '@material-ui/core/Button'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'

const AddNotesButton = ({ label, subdomain }) => {
  const dispatch = useDispatch()
  const { roles } = useSelector(state => state.user)
  const cancelledOrCompletedInterview = useSelector(
    cancelledOrCompletedSelector
  )
  return (
    <>
      { !cancelledOrCompletedInterview && <Can
        roles={roles}
        perform='interview:edit'
        yes={() => {
          return (
            <Button
              data-testid={`AddNotesButton-${subdomain}`}
              variant='outlined'
              onClick={() =>
                dispatch(
                  openDialog({
                    type: 'note',
                    title: `${subdomain} Notes`,
                  })
                )
              }
            >
              {label}
            </Button>
          )
        }}
      /> }
    </>
  )
}

export default AddNotesButton
