import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const transportationOtherSelector = state =>
  state.interview.transporation_situation_other.answer

const previewTransportationOtherSelector = state =>
  state.preview.transporation_situation_other.answer

const selectTransportationOther = createSelector(
  transportationOtherSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)
const selectPreviewTransportationOther = createSelector(
  previewTransportationOtherSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const TransportationChallenge = ({ isPreview }) => {
  const hasNoOtherTransportation = useSelector(isPreview ? selectPreviewTransportationOther : selectTransportationOther)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Transportation Challenge'
          domain='Transportation'
        />
        <form>
          <Select question_id='transporation_situation_other' isPreview={isPreview} />
          <Text
            multiline
            question_id='transportation_explain'
            isDisabled={hasNoOtherTransportation}
            shouldDecode={true}
            subquestion
            rows='4'
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
}

export default TransportationChallenge
