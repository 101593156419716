import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { colors } from 'app/theme'
import { useDispatch, useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createSelector } from '@reduxjs/toolkit'
import { navigateToPage } from 'ducks/navigationSlice'
import { navigateWithoutSave } from 'thunks/navigateWithoutSave'
import NavIcon from 'components/navigation/NavIcon'
import { withStyles } from '@material-ui/core/styles'
import Can from 'app/Can'

// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledListItem = withStyles({
  root: {
    paddingLeft: '36px',
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },

    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(ListItem)

const visitedSubdomainSelector = (state, id) => state.interviewProgress[id]

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      color: ${p => p.active && colors.turquoise};
    }
  }
`

const SubdomainIcon = styled(ListItemIcon)`
  && {
    min-width: 24px;
  }
`

const subdomainStatusSelector = createSelector(
  visitedSubdomainSelector,
  subdomain =>
    subdomain && subdomain.visited === 1 ? 'complete' : 'incomplete'
)

const Subdomain = React.memo(({ name, code, active, isPreview }) => {
  const dispatch = useDispatch()
  const subdomainStatus = useSelector(state =>
    subdomainStatusSelector(state, code)
  )
  const roles = useSelector(state => state.user.roles)
  const history = useHistory()
  const interviewStatus = useSelector(
    state => state.client.currentInterviewStatus
  )
  const canSave =
    interviewStatus?.toLowerCase() !== 'complete' &&
    interviewStatus?.toLowerCase() !== 'cancelled'

  const handleClick = () => {
    canSave ? dispatch(navigateToPage(code, history, isPreview)) : dispatch(navigateWithoutSave(code, history, isPreview))
  }

  const handleClickReadOnly = () => {
    dispatch(navigateWithoutSave(code, history, isPreview))
  }
  return (
    <Can
      perform={isPreview ? 'BRE:view' : 'interview:edit'}
      roles={roles}
      yes={() => {
        return (
          <StyledListItem
            data-testid={`Subdomain-item-${code}`}
            id={`leftNavNode-subdomain-${code}`}
            disableRipple
            disableTouchRipple
            button
            dense
            selected={active}
            onClick={handleClick}
          >
            <SubdomainIcon>
              <NavIcon status={subdomainStatus} />
            </SubdomainIcon>
            <StyledListItemText primary={name} active={active ? 1 : 0} />
          </StyledListItem>
        )
      }}
      no={() => {
        return (
          <StyledListItem
            data-testid={`Subdomain-item-readonly-${code}`}
            id={`leftNavNode-subdomain-${code}`}
            disableRipple
            disableTouchRipple
            button
            dense
            selected={active}
            onClick={handleClickReadOnly}
          >
            <SubdomainIcon>
              <NavIcon status={subdomainStatus} />
            </SubdomainIcon>
            <StyledListItemText primary={name} active={active ? 1 : 0} />
          </StyledListItem>
        )
      }}
    />
  )
})

export default Subdomain
