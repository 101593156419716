import { batch } from 'react-redux'
import {
  calculateChanges,
  calculatePreviewChanges,
} from 'side-effects/calculateChanges'
import { captureData } from 'ducks/interviewSlice'
import { captureData as capturePreviewData } from 'ducks/previewSlice'
import validation from 'thunks/validation'

export const captureFormData = payload => {
  return (dispatch, getState) => {
    const changes = calculateChanges(payload)
    batch(() => {
      // Perform any validation checks
      dispatch(validation(payload))
      // Compile the initial change as well as any side-effects
      // from that initial change. We perform this inside of the action
      // because multiple reducers require the computed values
      dispatch(captureData(changes))
    })
  }
}

export const capturePreviewFormData = payload => {
  return (dispatch, getState) => {
    const changes = calculatePreviewChanges(payload)
    batch(() => {
      // Perform any validation checks
      dispatch(validation(payload))
      // Compile the initial change as well as any side-effects
      // from that initial change. We perform this inside of the action
      // because multiple reducers require the computed values
      dispatch(capturePreviewData(changes))
    })
  }
}
