import { setEnvironment } from 'ducks/environmentSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'

const GET_ENVIRONMENT = gql`
    query {
        environment
    }
`

export const fetchEnvironment = () => {
    return async (dispatch, getState) => {
        try {
            const { data } = (await client.query({
                query: GET_ENVIRONMENT,
                fetchPolicy: 'network-only'
            }))
            const { environment } = data
            dispatch(setEnvironment(environment))
        } catch (error) {
            // this function is declared in the index file for application monitoring
            // eslint-disable-next-line no-undef
            ineum('reportError', error)
        }
    }
}