import { createSlice } from '@reduxjs/toolkit'
import { SPECIAL_VALUES } from 'components/clientList/clientListHelper'

const initialState = {
  clientListRecords: null,
  clientListPageIndex: 0,
  clientListPageSize: 25,
  clientListInputFilters: {},
  clientListServerFilters: {
    interviewer: [SPECIAL_VALUES.UNASSIGNED_OPTION_ID],
    status: ['New', 'In Process', 'Ready to Generate'],
    dates: 'All Dates',
    countyFilter: null,
  },
  clientListOrderBy: 'update',
  clientListOrder: 'desc',
  clientListCurrentRow: null,
  clientListTotalRecordCount: 0,
}

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {
    setClientListData: (state, action) => {
      state.clientListRecords = action.payload.records
      state.clientListTotalRecordCount = action.payload.totalRecordCount
      state.clientListPageIndex = action.payload.pageIndex
      state.clientListPageSize = action.payload.pageSize
      // check if the current page index is valid for the
      // the new dataset. If not reset them to the 1st page
      if (
        state.clientListTotalRecordCount <=
        state.clientListPageIndex * state.clientListPageSize
      ) {
        state.clientListPageIndex = 0
      }
    },
    changePageIndex: (state, action) => {
      state.clientListPageIndex = action.payload
    },
    changePageSize: (state, action) => {
      state.clientListPageIndex = 0
      state.clientListPageSize = action.payload
    },
    changeSort: (state, action) => {
      state.clientListOrderBy = action.payload.orderBy
      state.clientListOrder = action.payload.order
    },
    initializeClientListForUser: (state, action) => {
      const { userId, userCountyId } = action.payload
      state.clientListServerFilters = {
        interviewer: [SPECIAL_VALUES.UNASSIGNED_OPTION_ID, userId],
        status: ['New', 'In Process', 'Ready to Generate'],
        dates: 'All Dates',
        countyFilter: userCountyId,
      }
    },
    changeSearchCriteria: (state, action) => {
      state.clientListServerFilters = action.payload
    },
    changeLocalFilters: (state, action) => {
      state.clientListInputFilters = action.payload
    },
    changeCurrentRow: (state, action) => {
      state.clientListCurrentRow = action.payload
    },
  },
})

export const {
  setClientListData,
  initializeClientListForUser,
  changeSearchCriteria,
  changeLocalFilters,
  changeSort,
  changePageIndex,
  changePageSize,
  changeCurrentRow,
} = clientListSlice.actions

export default clientListSlice.reducer
