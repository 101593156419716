import EffectCollector from 'side-effects/effectCollector'
import { tableQuestions } from 'components/interview/OtherEducationTraining'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  tableQuestions.forEach(question => {
    if (question_id === question.yesNo && answer?.toUpperCase() !== 'YS') {
      question.when && effectCollector.add(question.when, null)
      question.explainSkillsLearned &&
        effectCollector.add(question.explainSkillsLearned, null)
    }
  })
  return effectCollector.effects
}

export default effects;