import isEmpty from 'lodash/isEmpty'
import { openDialog } from 'ducks/dialogSlice'
import { createActionPlan } from 'thunks/createActionPlan'
import Can from 'app/Can'

export const seedInitialActionPlans = () => {
  return async (dispatch, getState) => {
    const roles = getState().user.roles
    const interviewStatus = getState().client.currentInterviewStatus

    if (interviewStatus === 'complete' || interviewStatus === 'cancelled') {
      return
    }
    // Gather recommendations without action plans from state
    const recommendations = Object.values(
      getState().recommendation.recommendations
    )

    const recommendationsNeedingActionPlans = recommendations.filter(
      recommendation => {
        return (
          !recommendation.actionPlans || recommendation.actionPlans.length === 0
        )
      }
    )

    // Don't attempt to save without changes
    if (isEmpty(recommendationsNeedingActionPlans)) {
      return
    }

    try {
      // Seed actions plans if able to edit interviews
      Can({
        roles: roles,
        perform: 'interview:edit',
        no: () => null,
        yes: () =>
          recommendationsNeedingActionPlans.forEach(recommendation => {
            dispatch(createActionPlan(recommendation.id))
          }),
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
