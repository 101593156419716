import gql from 'graphql-tag'
const queries = {
  FEED_QUERY: gql`
    query Interview($id: Int) {
      interviewsByClient(clientId: $id) {
        id
        sharingCountyRecords {
          countyID
        }
        appraisalDate {
          effectiveDate
        }
        initiatedDate
        interfaceInboundID
        currentStatus {
          effectiveDate
          CVLType {
            description
          }
        }
        effectiveStartAndEndDates {
          end
          start
        }
        Client {
          id
          cin
          birthDate
          firstName
          lastName
          ssn
        }
        Case {
          id
          caseNumber
          county {
            id
            description
            code
          }
        }
        Assignee {
          id
          User {
            firstName
            lastName
            supervisorID
            details {
              officeID
              regionID
            }
          }
        }
        Appraisal {
          id
          reportLink
          spanishReportLink
          createdAt
          updatedAt
          createdBy
          updatedBy
        }
      }
    }
  `,
  ASSIGNABLE_USERS: gql`
    query assignableUsers {
      assignableUsers {
        id
        firstName
        lastName
      }
    }
  `,
  COUNTIES_LIST_QUERY: gql`
    query {
      counties {
        id
        interfaceCode
        description
      }
    }
  `,
  CURRENT_SHARED_RECORDS: gql`
    query interviewSharingRecords($interviewId: Int!) {
      interviewSharingRecords(interviewId: $interviewId) {
        id
        countyID
      }
      counties {
        id
        interfaceCode
        description
      }
    }
  `,
}

export default queries;