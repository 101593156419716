import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../app/theme'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from '../../ducks/dialogSlice'
import NavLink from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router-dom'
import { setSelectedRule, setRuleList } from 'ducks/ruleSlice'
import { decodeHtml } from '../../utilTools/decodeHtml'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { getTomorrow } from 'utilTools/dateHelper'
import { PENDING_CHANGE_TYPES, CHANGE_TYPE_DESCRIPTIONS } from '../general/Constants'
import { handlePendingChange, setPendingChangesList, setUpcomingChangeDate } from 'ducks/pendingChangesSlice'
import { savePendingChanges } from 'thunks/savePendingChanges'
import Button from '@material-ui/core/Button'
import { orderBy, groupBy } from 'lodash'


const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`
const Container = styled.div`
  margin: 10px 0;
`
const StyledListContainer = styled.div`
  margin-bottom: 10px;
`
const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
    margin: 0;
  }
`
const StyledNavLink = styled(NavLink)`
  && {
    font-size: 14px;
    text-decoration: underline;
    :hover {
      color: ${colors.turquoise};
      cursor: pointer;
    }
    :focus {
      color: ${colors.turquoise};
      cursor: pointer;
    }
    :active {
      color: ${colors.turquoise};
      outline: 0;
    }
  }
`
const StyledTableElement = styled(ListItem)`
  && {
    font-size: 14px;
    padding-top: 10px;
    display: block;
  }
  
`
const StyledDoubleArrowIcon = styled(DoubleArrowIcon)`
  && {
    color: ${colors.calsawsBlue};
    &:hover {
    color: ${colors.saffron};
      cursor: pointer;
    }
  }
`
const StyledDisabledDoubleArrowIcon = styled(DoubleArrowIcon)`
  && {
    color: ${colors.grey};
    cursor: default;
  }
`
const StyledHeaderElement = styled(Typography) `
&& {
  color: ${colors.turquoise};
  padding-left: 16px;
  font-weight: 600;
}
`
const PrimaryButton = styled(Button)`
  && {
    margin: 5px;
    display: inline-block;
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`
const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const GET_RULE = gql`
  query getRules {
    getRules {
      rules {
        id
        name
        domainCode
        ruleDomain {
          id
          code
          description
          spanishDescription
        }
        ruleCategory {
          id
          code
          description
          spanishDescription
        }
        ruleLabel {
          id
          code
          description
          spanishDescription
        }
        englishRecommendationText
        spanishRecommendationText
        disabled
      }
    }
  }
`
const GET_PENDING_CHANGES = gql`
  query pendingChanges {
  pendingChanges {
    id
    changeType
    recordId
    changeDetails
    publishDate
    createdBy
    createdAt
    createUser
    rule
  }
}
`
const UPCOMING_CHANGE_DATE = gql`
query upcomingChangeDate {
  upcomingChangeDate
}
`
const SuccessDialog = props => {
  const { open, title, body, onClose } = props
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <StyledDialogContent>{body}</StyledDialogContent>
        <StyledDialogActions>
          <PrimaryButton variant='contained' onClick={() => handleClose()}>
            OK
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
    </>
  )
}

const buildRuleElement = (rule, index, handleClick, handleAction, pendingRuleChanges) => {
  let status = 'Enabled'
  let pendingAction = ''
  if (rule.disabled) {
    status = 'Disabled'
  }

  const matchingChanges = pendingRuleChanges.filter(obj => {
    return obj.recordId === rule.id
  })

  const disableAction = matchingChanges.some(
    x => x.changeType === PENDING_CHANGE_TYPES.disableRule 
    || x.changeType === PENDING_CHANGE_TYPES.enableRule
  )

  if (matchingChanges.length !== 0) {
  const publishDate = matchingChanges[0].publishDate
  const desc = matchingChanges[0].changeType
    if (matchingChanges.length > 1) {
      if((matchingChanges.length === 2 &&
        matchingChanges[0].changeType !== matchingChanges[1].changeType) ||
        matchingChanges.length > 2) {
          pendingAction = 'Multiple Scheduled Updates'
        } else {
          pendingAction = CHANGE_TYPE_DESCRIPTIONS[desc] + ' ' + publishDate
        }
    } else {
      pendingAction = CHANGE_TYPE_DESCRIPTIONS[desc] + ' ' + publishDate
    }
  }

  return (
    <Grid container key={rule.id}>
      <Grid item xs={6} key={index}>
        <ListItemText
          onClick={() => {
            handleClick(rule, '/bre_rule_details')
          }}
        >
          <Typography variant='body1'>Rule {rule.name}:{' '}
          <StyledNavLink tabIndex='0'>{decodeHtml(rule.ruleLabel.description)}</StyledNavLink></Typography>
        </ListItemText>
      </Grid>
      <Grid item xs={2}><StyledTableElement><Typography variant='body1'>{status}</Typography></StyledTableElement></Grid>
      {!disableAction ? (
      <Grid item xs={1} onClick={() => {
            handleAction(rule)
          }}> 
      <StyledTableElement><StyledDoubleArrowIcon /></StyledTableElement></Grid>
      ):(
        <Grid item xs={1}><StyledTableElement><StyledDisabledDoubleArrowIcon /></StyledTableElement></Grid>
      )}
      <Grid item xs={3}><StyledTableElement style={{'textAlign':'left'}}><Typography variant='body1'>{pendingAction}</Typography></StyledTableElement></Grid>
    </Grid>
  )
}

function getOrderedAndDistinctRules(rules) {
  const grouped = groupBy(rules, rule => parseInt(rule.name))
  const distinct = Object.values(grouped)
    .reduce((acc, group) => {
      return [...acc, orderBy(group, item => item.id, "desc")[0]]
    }, [])

  const sorted = orderBy(distinct, item => parseInt(item.name), "asc")
  return sorted
}

const RuleList = () => {
  const ruleList = useSelector(state => state.ruleManagement.ruleList)
  const dispatch = useDispatch()
  const history = useHistory()
  const [showSuccess, setSuccess] = useState(false)
  const { loading, data, error } = useQuery(GET_RULE, {
    fetchPolicy: 'network-only',
  })

  const { 
    data: changesData, 
    error: changesError 
  } = useQuery(GET_PENDING_CHANGES)

  const {
    data: upcomingChangeDateData,
    error: upcomingChangeDateError
  }= useQuery(UPCOMING_CHANGE_DATE)

  useEffect(() => {
    if (error || changesError || upcomingChangeDateError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
            changesError,
            upcomingChangeDateError
          },
        })
      )
    }
    if (data && changesData) {
      dispatch(setRuleList(data.getRules.rules), 
      dispatch(setPendingChangesList(changesData.pendingChanges),
      dispatch(setUpcomingChangeDate(upcomingChangeDateData))))
    }
  }, [data, changesData, error, changesError,upcomingChangeDateData, upcomingChangeDateError, dispatch])

  const strengths = getOrderedAndDistinctRules(ruleList.filter(v => v.domainCode === 'ST'))
  const work = getOrderedAndDistinctRules(ruleList.filter(v => v.domainCode === 'BR'))
  const family = getOrderedAndDistinctRules(ruleList.filter(v => v.domainCode === 'FS'))
  const wtwExemption = getOrderedAndDistinctRules(ruleList.filter(v => v.domainCode === 'WT'))
  const pendingRuleChanges = useSelector(state => state.pendingChanges.currentPendingChanges).filter(r => r.changeType.includes('RULE'))
  const upcomingChangeDate = useSelector(state => state.pendingChanges.upcomingChangeDate.upcomingChangeDate)

  const handleConfirm = () => {
    setSuccess(false)
  }

  const handleSave = (effectiveDate, action, id) => {
    dispatch(
      handlePendingChange({
        recordId: id,
        changeType: action,
        publishDate: effectiveDate,
        changeDetails: {"rule": action}
      })
    )
    dispatch(savePendingChanges()).then(() => setSuccess(true))

  }

  const handleAction = (rule) => {
    dispatch(
      setSelectedRule({
        ...rule,
      })
    )
    let action = PENDING_CHANGE_TYPES.enableRule
    let dialogTitle = 'Enable Rule'
    let dialogDesc = 'Please select a future effective date to enable rule.'
    
    if(!rule.disabled) {
      action = PENDING_CHANGE_TYPES.disableRule
      dialogTitle = 'Disable Rule'
      dialogDesc = 'Please select a future effective date to disable rule.'
    }
    let existingPendingChangeDate = ''
    if(pendingRuleChanges.length !== 0) {
      existingPendingChangeDate = upcomingChangeDate
    }
    const formatTomorrow = getTomorrow()

    let defaultValue = formatTomorrow
    let isDisabled = false
  
    if (existingPendingChangeDate) {
      dialogDesc = 'There are current pending changes scheduled. This update will occur along with the other scheduled updates.'
      //this seems to always show the day before the existingPendingChangeDate in the datepicker...may need to address
      defaultValue = existingPendingChangeDate
      isDisabled = true
    }

    dispatch(
    openDialog({
      type: 'dialogDatePicker',
      title: dialogTitle,
      description: dialogDesc,
      props: {
        label: 'Effective Date',
        field: 'effectiveDate',
        minDate: formatTomorrow,
        defaultValue: defaultValue,
        isDisabled: isDisabled,
        action: (value) => handleSave(value, action, rule.id)
      }
    })
    )
  }

  const handleClick = (rule, path) => {
    dispatch(
      setSelectedRule({
        ...rule,
      })
    )

    if (history.location.pathname !== path) {
      history.push(path)
    }
  }

  return (
    <>
      <PageTitleContainer>
        <Typography variant='h3'>Business Rules Editor</Typography>
      </PageTitleContainer>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle variant='h4'>Manage Rules</SectionTitle>
          <Typography variant='body1'>
          Click the Action button to change the Status of a rule. Click the rule name link to view and edit the descriptions.
          </Typography>
        </Grid>
        {loading ? (
          <Container>
            <div>Loading Rule List...</div>
          </Container>
        ) : (
          <Grid container>
              {strengths.length > 0 && (
            <Grid item xs={12}>
            <StyledListContainer>
                  <Grid container>
                    <Grid item xs={6}>
                      <SectionTitle variant='h6'>Strengths</SectionTitle>
                    </Grid>
                    <Grid item xs={2}><StyledHeaderElement><SectionTitle variant='h6'>Status</SectionTitle></StyledHeaderElement></Grid>
                    <Grid item xs={1}><StyledHeaderElement style={{'paddingLeft':'0px'}}><SectionTitle variant='h6'>Action</SectionTitle></StyledHeaderElement></Grid>
                    <Grid item xs={3}><StyledHeaderElement style={{'textAlign':'left'}}><SectionTitle variant='h6'>Pending Action</SectionTitle></StyledHeaderElement></Grid>
                  </Grid>
                  <List dense>
                    {strengths.map((v, i) => {
                      return buildRuleElement(v, i, handleClick, handleAction, pendingRuleChanges)
                    })}
                  </List>
                </StyledListContainer>
            </Grid>
              )}
              {work.length > 0 && (
            <Grid item xs={12}>
            <StyledListContainer>
                  <SectionTitle variant='h6'>
                    Recommendations (Work, Education, and/or Barrier Removal)
                  </SectionTitle>
                  <List dense>
                    {work.map((v, i) => {
                      return buildRuleElement(v, i, handleClick, handleAction, pendingRuleChanges)
                    })}
                  </List>
                </StyledListContainer>
            </Grid>
              )}
              {family.length > 0 && (
            <Grid item xs={12}>
            <StyledListContainer>
                  <SectionTitle variant='h6'>
                    Family Stabilization Evaluation
                  </SectionTitle>
                  <List dense>
                    {family.map((v, i) => {
                      return buildRuleElement(v, i, handleClick, handleAction, pendingRuleChanges)
                    })}
                  </List>
                </StyledListContainer>
            </Grid>
              )}
              {wtwExemption.length > 0 && (
            <Grid item xs={12}>
            <StyledListContainer>
                  <SectionTitle variant='h6'>
                    WTW Exemption, Exception, and Program Waiver Evaluation
                  </SectionTitle>
                  <List dense>
                    {wtwExemption.map((v, i) => {
                      return buildRuleElement(v, i, handleClick, handleAction, pendingRuleChanges)
                    })}
                  </List>
                </StyledListContainer>
            </Grid>
              )}
          </Grid>
        )}
      </Grid>
      <SuccessDialog
        open={showSuccess}
        onClose={handleConfirm}
        title='Rule Change Successfully Scheduled'
        body=''
      />
    </>
  )
}

export default RuleList
