import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: null
}

const environmentSlice = createSlice({
    name: 'environment',
    initialState,
    reducers: {
        setEnvironment: (state, action) => {
            state.name = action.payload
        }
    }
})

export const {
    setEnvironment
} = environmentSlice.actions

export default environmentSlice.reducer