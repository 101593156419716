import { gql } from 'apollo-boost'
import isEmpty from 'lodash/isEmpty'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { clearUnsavedActionPlans } from 'ducks/unsavedActionPlansSlice'
import { getLastSavedDate } from 'thunks/getLastSavedDate'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'

const UPDATE_ACTION_PLANS = gql`
  mutation updateActionPlans(
    $updatedActionPlans: [ActionPlanInput!]
    $interviewID: Int
  ) {
    updateActionPlans(
      updatedActionPlans: $updatedActionPlans
      interviewID: $interviewID
    )
  }
`

export const saveActionPlans = () => {
  return async (dispatch, getState) => {
    // Gather unsaved changes from state
    const { currentInterview: interviewID } = getState().client
    const unsavedActionPlans = Object.values(
      getState().unsavedActionPlans.actionPlans
    )

    // Don't attempt to save without changes
    if (isEmpty(unsavedActionPlans)) {
      return
    }

    try {
      dispatch(setLoadingTimestamp(true))

      await client.mutate({
        mutation: UPDATE_ACTION_PLANS,
        variables: {
          updatedActionPlans: unsavedActionPlans,
          interviewID,
        },
      })

      // Reset the unsavedActionPlansSlice
      dispatch(clearUnsavedActionPlans())
      // Get and store the last saved date.
      dispatch(getLastSavedDate())
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(setLoadingTimestamp(false))
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
