import React from 'react'
import { useSelector } from 'react-redux'
import Category from 'components/navigation/Category'

export default React.memo(({ isPreview }) => {
  const selector = isPreview
    ? state => state.preview.navData
    : state => state.navigation.structure
  const categories = useSelector(selector)
  // For each category...
  const categoryElements = categories.map(category => {
    return (
      <Category
        name={category.name}
        domains={category.domains}
        code={category.code}
        key={category.name}
        isPreview={isPreview}
      />
    )
  })
  return categoryElements
})
