import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Select from 'components/general/Select'
import DatePicker from 'components/general/DatePicker'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteCheckboxGrid from 'components/table/InfiniteCheckboxGrid'
import InfiniteDatePicker from 'components/table/InfiniteDatePicker'
import Indicator from 'components/general/Indicator'
import { dobStyle } from 'components/interview/DemographicsGeneralInformation'

const householdMembersSelector = state =>
  state.interview.household_members_table.answer

const previewHouseholdMembersSelector = state =>
  state.preview.household_members_table.answer

const isHouseholdMemberSelector = state =>
  state.interview.pregnancy_table.answer

const previewIsHouseholdMemberSelector = state =>
  state.preview.pregnancy_table.answer

const isClientPregnantSelector = state =>
  state.interview.are_you_pregnant.answer

const previewIsClientPregnantSelector = state =>
  state.preview.are_you_pregnant.answer

const anyoneInHousePregnant = state =>
  state.interview.anyone_in_house_pregnant.answer

const previewAnyoneInHousePregnant = state =>
  state.preview.anyone_in_house_pregnant.answer

const pregnancyTableIndicatorSelector = state =>
  state.interview.pregnancy_table.indicator

const previewPregnancyTableIndicatorSelector = state =>
  state.preview.pregnancy_table.indicator

const pregnancyTableQuestionSelector = state =>
  state.interview.pregnancy_table

const previewPregnancyTableQuestionSelector = state =>
  state.preview.pregnancy_table

const createIsHouseholdMember = index => {
  return createSelector(isHouseholdMemberSelector, answers =>
    answers && answers[index]?.household_mem
      ? answers[index].household_mem.includes('HM')
      : false
  )
}

const createPreviewIsHouseholdMember = index => {
  return createSelector(previewIsHouseholdMemberSelector, answers =>
    answers && answers[index]?.household_mem
      ? answers[index].household_mem.includes('HM')
      : false
  )
}

const selectPregnancyTableIndicator = createSelector(
  pregnancyTableIndicatorSelector,
  indicator => indicator
)

const selectPreviewPregnancyTableIndicator = createSelector(
  previewPregnancyTableIndicatorSelector,
  indicator => indicator
)

const anyoneInHousePregnantSelector = createSelector(
  anyoneInHousePregnant,
  answer => answer && answer.toUpperCase() === 'YS'
)

const previewAnyoneInHousePregnantSelector = createSelector(
  previewAnyoneInHousePregnant,
  answer => answer && answer.toUpperCase() === 'YS'
)

const isPregnant = createSelector(
  isClientPregnantSelector,
  answer => answer && answer.toUpperCase() === 'YS'
)

const previewIsPregnant = createSelector(
  previewIsClientPregnantSelector,
  answer => answer && answer.toUpperCase() === 'YS'
)

const pregnancyTableQuestion = createSelector(pregnancyTableQuestionSelector, q => q)
const previewPregnancyTableQuestion = createSelector(previewPregnancyTableQuestionSelector, q => q)

const householdMembers = createSelector(householdMembersSelector, answers => {
  return answers
    ? answers.map((person, index) => {
        const { household_name: name, id } = person
        return {
          optionId: id,
          optionLabel: name ? name : 'No Name Given',
          order: index + 1,
        }
      })
    : []
})

const previewHouseholdMembers = createSelector(previewHouseholdMembersSelector, answers => {
  return answers
    ? answers.map((person, index) => {
        const { household_name: name, id } = person
        return {
          optionId: id,
          optionLabel: name ? name : 'No Name Given',
          order: index + 1,
        }
      })
    : []
})

const PregnancyRow = props => {
  const { isDisabled, isPreview, ...rest } = props
  const isHouseholdMemberSelector = isPreview ? createPreviewIsHouseholdMember : createIsHouseholdMember(props.index)
  const householdMembersList = useSelector(isPreview ? previewHouseholdMembers : householdMembers)
  const isHouseholdMember = useSelector(state =>
    isPreview ? previewIsHouseholdMemberSelector : isHouseholdMemberSelector(state)
  )

  return (
    <>
      <InfiniteCheckboxGrid
        hideOptionLabel={true}
        sub_question_id='household_mem'
        isPreview={isPreview}
        {...rest}
      />
      {isHouseholdMember ? (
        <InfiniteDropdown
          altMenuItems={householdMembersList}
          sub_question_id='id'
          isPreview={isPreview}
          {...rest}
        />
      ) : (
        <InfiniteText
          sub_question_id='household_mem_name'
          {...rest}
          shouldDecode={true}
          isPreview={isPreview}
        />
      )}
      <InfiniteDropdown
        isDisabled={isHouseholdMember}
        sub_question_id='household_mem_rel'
        isPreview={isPreview}
        {...rest}
      />
      <InfiniteDatePicker
        sub_question_id='household_member_due_date'
        width={'200px'}
        hideLabel={true}
        datePickerOptions={{
          disablePast: true,
          format: 'MM/yyyy',
          views: ['month'],
        }}
        isPreview={isPreview}
        {...rest}
      />
      <InfiniteDropdown sub_question_id='household_mem_see_doc' isPreview={isPreview} {...rest} />
      <InfiniteDropdown sub_question_id='accompanying_to_doctor' isPreview={isPreview} {...rest} />
    </>
  )
}

const PregnancyPregnancy = React.memo(({ isPreview }) => {

  const pregnancyQuestion = useSelector(isPreview ? previewPregnancyTableQuestion : pregnancyTableQuestion)

  const tableHeaders = [
    pregnancyQuestion.sub_question_ids.household_mem.options[0].optionLabel,
    pregnancyQuestion.sub_question_ids.household_mem_name.label,
    pregnancyQuestion.sub_question_ids.household_mem_rel.label,
    pregnancyQuestion.sub_question_ids.household_member_due_date.label,
    pregnancyQuestion.sub_question_ids.household_mem_see_doc.label,
    pregnancyQuestion.sub_question_ids.accompanying_to_doctor.label,
  ]
  const isClientPregnant = useSelector(isPreview ? previewIsPregnant : isPregnant)
  const anyoneInHousePregnant = useSelector(isPreview ? previewAnyoneInHousePregnantSelector : anyoneInHousePregnantSelector)
  const indicator = useSelector(isPreview ? selectPreviewPregnancyTableIndicator : selectPregnancyTableIndicator)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Pregnancy Questions'
          domain='Pregnancy'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <DatePicker
            isDisabled={!isClientPregnant}
            question_id='due_date'
            sawsIndicator={true}
            indicatorStyle={dobStyle}
            datePickerOptions={{
              disablePast: true,
              format: 'MM/yyyy',
              views: ['month'],
            }}
            isPreview={isPreview}
          />
          <Select
            isDisabled={!isClientPregnant}
            question_id='see_doctor_prenatal_care'
            isPreview={isPreview}
          />
          <Typography variant='h5'>
            Pregnancy Status of Other Individuals
          </Typography>
          <Typography variant='h6'>
            Some information in the table below is pre-populated from the
            Household Composition section. Please provide the information below
            regarding pregnant household members:
          </Typography>
          <InfiniteTable
            headers={tableHeaders}
            question_id='pregnancy_table'
            isDisabled={!anyoneInHousePregnant}
            rowComponent={<PregnancyRow isDisabled={!anyoneInHousePregnant} isPreview={isPreview} />}
            indicator={<Indicator indicator={indicator} />}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})

export default PregnancyPregnancy
