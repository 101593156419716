import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const movedLast12MonthsSelector = state =>
  state.interview.moved_last_12_months.answer

const previewMovedLast12MonthsSelector = state =>
  state.preview.moved_last_12_months.answer

const numberHousingMovesSelector = state =>
  state.interview.number_housing_moves.answer

const previewNumberHousingMovesSelector = state =>
  state.preview.number_housing_moves.answer

const selectHaveNotMoved = createSelector(
  movedLast12MonthsSelector,
  answer => answer?.toUpperCase() !== 'MV'
)

const selectPreviewHaveNotMoved = createSelector(
  previewMovedLast12MonthsSelector,
  answer => answer?.toUpperCase() !== 'MV'
)

const currentHousingSituationUnstableSelector = state =>
  state.interview.current_housing_situation_unstable.answer

const previewCurrentHousingSituationUnstableSelector = state =>
  state.preview.current_housing_situation_unstable.answer

const selectNumberHousingMoves = createSelector(
  numberHousingMovesSelector,
  answer => answer && Number(answer) >= 3
)

const selectPreviewNumberHousingMoves = createSelector(
  previewNumberHousingMovesSelector,
  answer => answer && Number(answer) >= 3
)

const selectCurrentHousingSituationUnstable = createSelector(
  currentHousingSituationUnstableSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectPreviewCurrentHousingSituationUnstable = createSelector(
  previewCurrentHousingSituationUnstableSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const HousingSituation = React.memo(({ isPreview }) => {
  const haveNotMoved = useSelector(isPreview ? selectPreviewHaveNotMoved : selectHaveNotMoved)
  const movedMoreThanThree = useSelector(isPreview ? selectPreviewNumberHousingMoves : selectNumberHousingMoves)
  const currentHousingSituationUnstable = useSelector(
    isPreview ? selectPreviewCurrentHousingSituationUnstable : selectCurrentHousingSituationUnstable
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader subdomain='Housing Situation' domain='Housing' />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: If client indicates 3 or more, ask for further
          explanation.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select question_id='moved_last_12_months' isPreview={isPreview} />
          <Text
            subquestion
            question_id='number_housing_moves'
            type='number'
            isDisabled={haveNotMoved}
            isPreview={isPreview}
          />
          <Text
            subSubquestion
            question_id='explain_housing_moves'
            multiline
            rows='4'
            isDisabled={!movedMoreThanThree}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select question_id='current_utility_shut_off' isPreview={isPreview} />
          <Select question_id='past_utility_shut_off' isPreview={isPreview} />
          <Select question_id='current_eviction_notice' isPreview={isPreview} />
          <Select question_id='past_eviction' isPreview={isPreview} />
          <Select question_id='current_housing_situation_unstable' isPreview={isPreview} />
          <Text
            subquestion
            question_id='housing_sitation_explain'
            multiline
            rows='4'
            isDisabled={!currentHousingSituationUnstable}
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select question_id='waitlist_shelter_section_8' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default HousingSituation
