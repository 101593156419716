import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const printQuestionnaireSlice = createSlice({
  name: 'printQuestionnaire',
  initialState,
  reducers: {
    setPrintQuestionnairePayload: (state, action) => {
      const { printQuestionnairePayload } = action.payload
      if (printQuestionnairePayload) {
        state.printQuestionnairePayload = printQuestionnairePayload
      }
      return state
    },
    cleanPrintQuestionnairePayload: (state, action) => initialState,
  },
})

export const {
  setPrintQuestionnairePayload,
  cleanPrintQuestionnairePayload,
} = printQuestionnaireSlice.actions

export default printQuestionnaireSlice.reducer
