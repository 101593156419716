import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Dropdown from 'components/general/Dropdown'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
// Selectors should be used for any computed data and should be defined
// OUTSIDE of the component itself so this data isn't recomputed
// on each re-render. createSelector will handle caching of these
// computed values for us as well. See: https://github.com/reduxjs/reselect
const validLicenseSelector = state =>
  state.interview.valid_driver_license.answer
const previewValidLicenseSelector = state =>
  state.preview.valid_driver_license.answer

const suspendedLicenseSelector = state =>
  state.interview.suspended_license.answer
const previewSuspendedLicenseSelector = state =>
  state.preview.suspended_license.answer

const otherLicenseSelector = state => state.interview.all_license_types.answer
const previewOtherLicenseSelector = state => state.preview.all_license_types.answer

const cleanLicenseSelector = state =>
  state.interview.clean_driving_record.answer
const previewCleanLicenseSelector = state =>
  state.preview.clean_driving_record.answer

const selectValidLicenseIsValid = createSelector(
  validLicenseSelector,
  answer => answer?.toUpperCase() === 'YS'
)
const selectPreviewValidLicenseIsValid = createSelector(
  previewValidLicenseSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectValidLicenseIsInvalid = createSelector(
  validLicenseSelector,
  answer => answer?.toUpperCase() === 'NO'
)
const selectPreviewValidLicenseIsInvalid = createSelector(
  previewValidLicenseSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const selectLicenseIsSuspended = createSelector(
  suspendedLicenseSelector,
  answer => answer?.toUpperCase() === 'YS'
)
const selectPreviewLicenseIsSuspended = createSelector(
  previewSuspendedLicenseSelector,
  answer => answer?.toUpperCase() === 'YS'
)

// Not falsy, is an array, and array includes 'other'
const selectLicenseIsOther = createSelector(
  otherLicenseSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)
const selectPreviewLicenseIsOther = createSelector(
  previewOtherLicenseSelector,
  answer =>
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('OT')
)

const selectCleanLicenseIsNo = createSelector(
  cleanLicenseSelector,
  answer => answer?.toUpperCase() === 'NO'
)
const selectPreviewCleanLicenseIsNo = createSelector(
  previewCleanLicenseSelector,
  answer => answer?.toUpperCase() === 'NO'
)

const TransportationDriverLicense = React.memo(({ isPreview }) => {
  const validDriverLicenseYes = useSelector(isPreview ? selectPreviewValidLicenseIsValid : selectValidLicenseIsValid)
  const licenseIsOther = useSelector(isPreview ? selectPreviewLicenseIsOther : selectLicenseIsOther)
  const cleanLicenseIsNo = useSelector(isPreview ? selectPreviewCleanLicenseIsNo : selectCleanLicenseIsNo)
  const validDriverLicenseNo = useSelector(isPreview ? selectPreviewValidLicenseIsInvalid : selectValidLicenseIsInvalid)
  const suspendedLicenseYes = useSelector(isPreview ? selectPreviewLicenseIsSuspended : selectLicenseIsSuspended)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Driving License'
          domain='Transportation'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select question_id='valid_driver_license' isPreview={isPreview} />
          <CheckboxGrid
            subquestion
            question_id='all_license_types'
            isDisabled={!validDriverLicenseYes}
            isPreview={isPreview}
          />
          <Text
            question_id='driving_license_specify'
            isDisabled={!licenseIsOther}
            shouldDecode={true}
            multiline
            rows='4'
            subquestion
            isPreview={isPreview}
          />
          <Dropdown
            subquestion
            question_id='issuing_state'
            isDisabled={!validDriverLicenseYes}
            isPreview={isPreview}
          />
          <Select
            question_id='restricted_license'
            isDisabled={!validDriverLicenseYes}
            isPreview={isPreview}
          />
          <Select
            question_id='clean_driving_record'
            isDisabled={!validDriverLicenseYes}
            isPreview={isPreview}
          />
          <Text
            question_id='driving_license_explain'
            isDisabled={!cleanLicenseIsNo}
            shouldDecode={true}
            multiline
            rows='4'
            subquestion
            isPreview={isPreview}
          />
          <Select
            question_id='other_forms_government_id'
            isDisabled={!validDriverLicenseNo}
            isPreview={isPreview}
          />
          <Select
            question_id='suspended_license'
            isDisabled={!validDriverLicenseNo}
            isPreview={isPreview}
          />
          <Text
            question_id='driving_license_why_revoked'
            isDisabled={!suspendedLicenseYes}
            shouldDecode={true}
            subquestion
            multiline
            rows='4'
            isPreview={isPreview}
          />
          <Text
            question_id='driving_license_revoked'
            isDisabled={!suspendedLicenseYes}
            shouldDecode={true}
            subquestion
            multiline
            rows='4'
            isPreview={isPreview}
          />
          <Text
            question_id='driving_license_reinstated'
            isDisabled={!suspendedLicenseYes}
            shouldDecode={true}
            subquestion
            multiline
            rows='4'
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})
export default TransportationDriverLicense
