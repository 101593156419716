import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { saveAdmin } from 'thunks/saveAdmin'
import { setNewUser } from 'ducks/adminSlice'
import { openDialog } from 'ducks/dialogSlice'
import isEmpty from 'lodash/isEmpty'

const StyledButton = styled(Button)`
  && {
    margin: 16px;
    display: inline-block;
  }
`

const AdminSaveButton = ({ isNewUser }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.admin)
  const error = useSelector(state => state.validationErrors)
  let saveDisabled = false
  // disable button if these fields are blank
  if (
    !user.firstName ||
    !user.lastName ||
    !user.email ||
    !user.role ||
    !user.organization ||
    !isEmpty(error)
  ) {
    saveDisabled = true
  }
  const handleSave = () => {
    if (user.dataExtract === 1 && !user.confirmedForExtract) {
      dispatch(
        openDialog({
          type: 'adminConfirmDataExtract',
          title: 'Confirm Data Extract Permission',
          props: { isNewUser },
        })
      )
      return
    }
    if (isNewUser) {
      dispatch(setNewUser({ field: 'status', value: 'Active' }))
    }
    dispatch(saveAdmin(isNewUser))
  }
  return (
    <StyledButton
      variant='contained'
      color='primary'
      onClick={() => {
        saveDisabled
          ? dispatch(
              openDialog({
                type: 'adminRequiredFields',
                title: 'Required Fields Missing',
                description: `There are missing required fields on this page. Please go back and complete those fields.`,
              })
            )
          : handleSave()
      }}
    >
      Save
    </StyledButton>
  )
}

export default AdminSaveButton
