import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Select from 'components/general/Select'
import Grid from '@material-ui/core/Grid'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const activitiesSelector = state =>
  state.interview.transportation_challenge_wtw_activities.answer
const previewActivitiesSelector = state =>
  state.preview.transportation_challenge_wtw_activities.answer

const selectActivities = createSelector(
  activitiesSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)
const selectPreviewActivities = createSelector(
  previewActivitiesSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)

const TransportationIndicatorQuestions = React.memo(({ isPreview }) => {
  const indicatorSelected = useSelector(isPreview ? selectPreviewActivities : selectActivities)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Question'
          domain='Transportation'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select question_id='transportation_challenge_wtw_activities' isPreview={isPreview} />
        </form>
        <SkipButton isDisabled={indicatorSelected}></SkipButton>
      </Grid>
    </Grid>
  )
})

export default TransportationIndicatorQuestions
