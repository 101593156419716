import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

const questionSelector = (state, question) =>
  state.interview[question.question_id]

const previewQuestionSelector = (state, question) =>
  state.preview[question.question_id]

const selectQuestion = (isPreview) => {
  return createSelector((isPreview ? previewQuestionSelector : questionSelector), question => question)
}

const TableRowDescription = question => {
  const dataSelector = selectQuestion(question.isPreview)
  const { description, label } = useSelector(state =>
    dataSelector(state, question)
  )
  // Show description or label based on the question type
  // (short text has label, regular text has description)
  return <>{description || label}</>
}

export default TableRowDescription
