import { addOffice } from 'ducks/entitiesSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'

const CREATE_NEW_OFFICE = gql`
  mutation($regionID: Int, $name: String) {
    createOffice(regionID: $regionID, name: $name) {
      createdOfficeId
    }
  }
`

export const createOffice = () => {
  return async (dispatch, getState) => {
    try {
      // When adding an office, default to first region
      const regionID = Object.values(getState().entities.regions)[0].regionID
      const regionName = Object.values(getState().entities.regions)[0]
        .regionName

      let newOfficeData = await client.mutate({
        mutation: CREATE_NEW_OFFICE,
        variables: {
          regionID,
          name: '',
        },
      })

      const newOffice = {
        officeID: newOfficeData.data.createOffice.createdOfficeId,
        officeName: '',
        regionID,
        regionName,
        dirty: true,
      }

      dispatch(
        addOffice({
          office: newOffice,
        })
      )
    } catch (error) {
      // this function is dseclared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
