import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

import Select from 'components/general/Select'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Text from 'components/general/Text'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteSelect from 'components/table/InfiniteSelect'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import InfiniteCheckboxGrid from 'components/table/InfiniteCheckboxGrid'

import { colors } from 'app/theme'
import { decodeHtml } from '../../utilTools/decodeHtml'
// Styled Components
const StyledCard = styled(Card)`
  && {
    margin: 18px 0;
    min-width: 800px;
  }
`
const sharedStyle = css`
  width: 100%;
  margin: 0 8px;
`

const StyledDropdown = styled(InfiniteDropdown)`
  && {
    ${sharedStyle}
  }
`
const StyledSelect = styled(InfiniteSelect)`
  && {
    ${sharedStyle}
  }
`
const StyledText = styled(InfiniteText)`
  && {
    ${sharedStyle}
  }
`

const StyledCheckboxGrid = styled(InfiniteCheckboxGrid)`
  && {
    ${sharedStyle}
  }
`
const StyledTypography = styled(Typography)`
  && {
    ${sharedStyle}
  }
`
const StyledGeneralText = styled(Text)`
  && {
    margin-top: 20px;
  }
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: ${p => (p.top ? 'flex-start' : 'center')};
  padding: 8px;
`

const CardTitle = styled(CardHeader)`
  && {
    background: ${colors.lightgrey};
  }
`

// Selectors and createSelectors
const dataSelector = (state, question_id) => {
  return state.interview[question_id]
}
const previewDataSelector = (state, question_id) => {
  return state.preview[question_id]
}

// createSelector for getting data for the form/table.
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewDataSelector : dataSelector), data => ({
    answers: data.answer || [],
  }))
}

const createConditionalsSelector = (index, isPreview) => {
  return createSelector((isPreview ? previewDataSelector : dataSelector), data => {
    const allAnswers = data.answer || [{parental_involvement_custody: '', share_responsibility: '', rate_relationship_other_parent: ''}]
    const {
      parental_involvement_custody,
      share_responsibility,
      rate_relationship_other_parent,
    } = allAnswers[index]
    return {
      isAnotherParty: parental_involvement_custody
        ? parental_involvement_custody.includes('AN')
        : false,
      isSharedReponsibility:
        share_responsibility && share_responsibility !== undefined
          ? share_responsibility.toUpperCase() === 'YS'
          : null,
      isRateOther:
        rate_relationship_other_parent &&
        rate_relationship_other_parent.includes('OT'),
    }
  })
}

const isPayingChildSupportSelector = createSelector(dataSelector, data =>
  data.answer ? data.answer.toUpperCase() === 'YS' : null
)

const previewIsPayingChildSupportSelector = createSelector(previewDataSelector, data =>
  data.answer ? data.answer.toUpperCase() === 'YS' : null
)

const isNotPayingChildSupportOtherSelector = createSelector(
  dataSelector,
  data => (data.answer ? data.answer.includes('OT') : null)
)

const previewIsNotPayingChildSupportOtherSelector = createSelector(
  previewDataSelector,
  data => (data.answer ? data.answer.includes('OT') : null)
)

const ParentingAndChildSupportCard = ({ answer, cardIndex, isPreview }) => {
  const conditionalSelector = createConditionalsSelector(cardIndex, isPreview)
  const {
    isAnotherParty,
    isSharedReponsibility,
    isRateOther,
  } = useSelector(state =>
    conditionalSelector(state, 'parenting_and_child_support_table')
  )
  return (
    <StyledCard>
      <CardTitle
        title={
          <Typography variant='body1'>Child Care and Parenting</Typography>
        }
      />
      <CardContent>
        <Row>
          <StyledTypography variant='body1'>
            Name: {decodeHtml(answer.pcs_name)}
          </StyledTypography>
        </Row>
        <Row>
          <StyledTypography variant='body1'>
            Relationship: {decodeHtml(answer.pcs_relationship)}
          </StyledTypography>
        </Row>
        {cardIndex !== 0 && (
          <Row>
            <StyledCheckboxGrid
              noTable
              question_id='parenting_and_child_support_table'
              sub_question_id='use_same_values'
              index={cardIndex}
              isPreview={isPreview}
            />
          </Row>
        )}
        <Row>
          <StyledDropdown
            noTable
            width='715px'
            hideLabel={false}
            question_id='parenting_and_child_support_table'
            sub_question_id='parental_involvement_custody'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledText
            noTable
            hideLabel={false}
            isDisabled={!isAnotherParty}
            question_id='parenting_and_child_support_table'
            sub_question_id='custody_other_name'
            index={cardIndex}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledSelect
            hideDescription={false}
            noTable
            question_id='parenting_and_child_support_table'
            sub_question_id='wish_to_regain_custody'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledSelect
            hideDescription={false}
            noTable
            question_id='parenting_and_child_support_table'
            sub_question_id='in_foster_care'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledSelect
            hideDescription={false}
            noTable
            question_id='parenting_and_child_support_table'
            sub_question_id='share_responsibility'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledSelect
            hideDescription={false}
            noTable
            isDisabled={
              isSharedReponsibility === null || !isSharedReponsibility
            }
            question_id='parenting_and_child_support_table'
            sub_question_id='equal_responsibility'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledText
            noTable
            width='590px'
            hideDescription={false}
            isDisabled={isSharedReponsibility === null || isSharedReponsibility}
            question_id='parenting_and_child_support_table'
            sub_question_id='other_parent_role'
            index={cardIndex}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledDropdown
            noTable
            width='590px'
            hideDescription={false}
            question_id='parenting_and_child_support_table'
            sub_question_id='rate_relationship_other_parent'
            index={cardIndex}
            isPreview={isPreview}
          />
        </Row>
        <Row>
          <StyledText
            noTable
            hideLabel={false}
            isDisabled={!isRateOther}
            question_id='parenting_and_child_support_table'
            sub_question_id='rate_relationship_other_parent_explain'
            index={cardIndex}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </Row>
      </CardContent>
    </StyledCard>
  )
}

const ParentingAndChildSupport = React.memo(({ isPreview }) => {
  const dataSelector = createDataSelector(isPreview)
  const isPayingChildSupport = useSelector(state =>
    isPreview ? previewIsPayingChildSupportSelector(state, 'paying_child_support') : isPayingChildSupportSelector(state, 'paying_child_support')
  )
  const isNotPayingChildSupportOther = useSelector(state =>
    isPreview ? previewIsNotPayingChildSupportOtherSelector(state, 'not_paying_child_spp_explain') : isNotPayingChildSupportOtherSelector(state, 'not_paying_child_spp_explain')
  )
  const { answers } = useSelector(state => {
    return dataSelector(state, 'parenting_and_child_support_table')
  })
  return (
    <>
      <QuestionnaireHeader
        subdomain='Parenting and Child Support '
        domain='Child Care and Parenting'
      />
      <form>
        {answers &&
          answers.map((answer, cardIndex) => (
            <ParentingAndChildSupportCard
              key={`parenting-and-child-support-${cardIndex}`}
              answer={answer}
              cardIndex={cardIndex}
              isPreview={isPreview}
            />
          ))}
        <Grid container alignContent={'center'}>
          <Grid item xs={5}>
            <Select question_id='paying_child_support' isPreview={isPreview} />
          </Grid>
          <Grid item xs={5}>
            <StyledGeneralText
              question_id='how_much_child_support'
              type='currency'
              variant='outlined'
              isDisabled={
                isPayingChildSupport === null || !isPayingChildSupport
              }
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Grid container alignContent={'flex-start'}>
          <Grid item xs={5}>
            <Select
              isDisabled={
                isPayingChildSupport === null || !isPayingChildSupport
              }
              question_id='chld_spp_formal_infrml'
              isPreview={isPreview}
            />
          </Grid>
          <Grid item xs={5}>
            <Select
              isDisabled={
                isPayingChildSupport === null || !isPayingChildSupport
              }
              question_id='chld_spp_wrttn_verbal'
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Select
          isDisabled={!isPayingChildSupport}
          question_id='help_with_modifying_child_support'
          isPreview={isPreview}
        />
        <Select
          isDisabled={isPayingChildSupport === null || isPayingChildSupport}
          question_id='responsible_child_support'
          isPreview={isPreview}
        />
        <Grid container alignContent={'flex-start'}>
          <Grid item xs={5}>
            <CheckboxGrid
              isDisabled={isPayingChildSupport === null || isPayingChildSupport}
              question_id='not_paying_child_spp_explain'
              isPreview={isPreview}
            />
          </Grid>

          <Grid item xs={5}>
            <Text
              multiline
              rows={5}
              isDisabled={
                isNotPayingChildSupportOther === null ||
                !isNotPayingChildSupportOther
              }
              question_id='not_paying_child_support_explain'
              shouldDecode={true}
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Select question_id='interest_in_supportgroup' isPreview={isPreview} />
      </form>
    </>
  )
})
export default ParentingAndChildSupport
