import { createSlice } from '@reduxjs/toolkit'
import { updateActionPlan } from './unsavedActionPlansSlice'

const initialState = {
  recommendations: {},
  recommendationsLoading: false,
}

const recommendationSlice = createSlice({
  name: 'recommendations',
  initialState: initialState,
  reducers: {
    setRecommendations: (state, action) => {
      state.recommendations = action.payload
    },
    addActionPlan: (state, action) => {
      const actionPlan = action.payload
      state.recommendations[actionPlan.recommendationID].actionPlans.push(
        actionPlan
      )
    },
    setRecommendationsLoading: (state, action) => {
      state.recommendationsLoading = action.payload
    },
    removeActionPlan: (state, action) => {
      let deletedActionPlan = action.payload
      const recommendation =
        state.recommendations[deletedActionPlan.recommendationID]

      recommendation.actionPlans = recommendation.actionPlans.filter(
        actionPlan => {
          return actionPlan.id !== deletedActionPlan.id
        }
      )
    },
    clearRecommendations: (state, action) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateActionPlan, (state, action) => {
      const newActionPlan = action.payload
      const recommendation =
        state.recommendations[newActionPlan.recommendationID]
      recommendation.actionPlans.forEach(actionPlan => {
        if (actionPlan.id === newActionPlan.id) {
          actionPlan.actionText = newActionPlan.actionText
          actionPlan.referralMade = newActionPlan.referralMade
          actionPlan.accepted =
            parseInt(actionPlan.referralMade) === 1
              ? newActionPlan.accepted
              : null
        }
      })
    })
  }
})

export const {
  setRecommendations,
  addActionPlan,
  removeActionPlan,
  setRecommendationsLoading,
  clearRecommendations,
} = recommendationSlice.actions

export default recommendationSlice.reducer
