import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'final_thoughts_concerns' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('what_would_that_be', null)
  }
  return effectCollector.effects
}

export default effects