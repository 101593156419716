import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const interviewProgressSlice = createSlice({
  name: 'interviewProgress',
  initialState: initialState,
  reducers: {
    storeInterviewProgress: (state, action) => {
      const newState = {}
      action.payload.interviewProgress.forEach(progress => {
        newState[progress.subdomain] = progress
      })
      return newState
    },
    setInterviewProgress: (state, action) => {
      state[action.payload.subdomain] = action.payload
    },
    clearInterviewProgress: (state, action) => initialState,
  },
})

export const {
  storeInterviewProgress,
  setInterviewProgress,
  clearInterviewProgress,
} = interviewProgressSlice.actions

export default interviewProgressSlice.reducer
