import {
  deleteResource,
  setCurrentResource,
  loadCurrentResource,
  setCurrentResourceData,
  loadResources,
} from 'ducks/resourceSlice'
import validator from 'validator'

import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import resourceQueries from 'components/resources/ResourceQueries'
import { batch } from 'react-redux'

const unescapeResources = resource => {
  const { id, name, address, phone, url, description } = resource
  return {
    id,
    name: name ? validator.unescape(name) : '',
    address: address ? validator.unescape(address) : '',
    phone: phone ? validator.unescape(phone) : '',
    url: url ? validator.unescape(url) : '',
    description: description ? validator.unescape(description) : '',
    dirty: false,
  }
}

// Gets and set resourceData and currentResources.
export const getResources = () => {
  return async (dispatch, getState) => {
    try {
      const { resourceTypes, countyId } = getState().resources
      const { isStatewide } = getState().user
      const showTable = (isStatewide && countyId) || !isStatewide

      const { data } = await client.query({
        query: resourceQueries.GET_RESOURCES_BY_COUNTY_ID,
        variables: { countyId: countyId },
        fetchPolicy: 'network-only',
      })
      // Take the resources and the resource types and transform them into resource data for the store
      // The output format is:
      // {
      //   [resourceType.name]: {
      //     id: resource.id,
      //     name: resource.name,
      //     address: resource.address,
      //     phone: resource.phone,
      //     url: resource.url,
      //     description: resource.description
      //     }
      // }
      const resources = data?.resources.reduce((accumulator, resource) => {
        const resourceType = resourceTypes.find(
          resourceType => resourceType.id === resource.typeID
        )
        if (resourceType) {
          const oldValues = accumulator[resourceType.name] || []
          return {
            ...accumulator,
            [resourceType.name]: [...oldValues, unescapeResources(resource)],
          }
        } else {
          return accumulator
        }
      }, {})
      // load the transformed resource data into the store
      if (data?.resources && showTable) {
        batch(() => {
          dispatch(loadResources({ resources }))
          dispatch(loadCurrentResource())
        })
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

export const handleResourceDelete = index => {
  return async (dispatch, getState) => {
    try {
      // Get the state. Needs to be mapped over because state is not mutable.
      const newState = getState().resources.currentResource.map(
        resource => resource
      )
      // Remove the resource.
      const [deletedResource] = newState.splice(index, 1)  

      // Remove the resource from both the currentResource state and the resourceData
      // state.
      dispatch(deleteResource(newState))

      // Make a database call only if the resource has an id. Having an id indicates
      // that there is a row in the database for that resource.
      if (deletedResource.id) {
        await client.mutate({
          mutation: resourceQueries.DELETE_RESOURCE,
          variables: {
            resourceID: deletedResource.id,
          },
        })

        dispatch(getResources())
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

export const handleResourceSave = index => {
  return async (dispatch, getState) => {
    try {
      const {
        currentResource,
        countyId,
        currentResourceId: typeID,
      } = getState().resources

      // Remove the dirty flag from the resource to be saved.
      const { dirty, ...resourceProps } = currentResource[index]

      // Add the typeID and countyID to the resource.
      const resource = { ...resourceProps, typeID, countyID: countyId }

      // If the resource already has an id, then it is already in the database.
      // Else it needs to be created.
      const mutation = resource.id
        ? resourceQueries.UPDATE_RESOURCE
        : resourceQueries.CREATE_RESOURCE

      // Make db call.
      const { data } = await client.mutate({
        mutation,
        variables: { resource },
      })

      // If the resource doesn't have an id, create a new current resources array,
      // and change the index of the resource that was created.
      if (!resource.id) {
        const updatedResource = currentResource.map((resource, idx) =>
          idx === index ? unescapeResources(data.createResource) : resource
        )
        batch(() => {
          dispatch(setCurrentResource(updatedResource))
          dispatch(setCurrentResourceData())
        })
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
