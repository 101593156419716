import { addActionPlan } from 'ducks/recommendationsSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'

const CREATE_NEW_ACTION_PLAN = gql`
  mutation($recommendationID: Int!, $actionText: String, $interviewID: Int!) {
    createActionPlan(
      recommendationID: $recommendationID
      actionText: $actionText
      interviewID: $interviewID
    ) {
      id
    }
  }
`

export const createActionPlan = recommendationID => {
  return async (dispatch, getState) => {
    try {
      const { currentInterview: interviewID } = getState().client

      let newActionPlanId = await client.mutate({
        mutation: CREATE_NEW_ACTION_PLAN,
        variables: {
          recommendationID: recommendationID,
          actionText: '',
          interviewID,
        },
      })

      dispatch(
        addActionPlan({
          id: newActionPlanId.data.createActionPlan.id,
          recommendationID: recommendationID,
          actionText: '',
          referralMade: null,
          accepted: null,
        })
      )
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
