import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { updateOffice } from 'ducks/entitiesSlice'

const UPDATE_OFFICE = gql`
  mutation updateOffice($id: Int, $name: String, $regionID: Int) {
    updateOffice(id: $id, name: $name, regionID: $regionID) {
      officeID
    }
  }
`
export const saveOffice = (office, officeIndex) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: UPDATE_OFFICE,
        variables: {
          id: office.officeID,
          name: office.officeName,
          regionID: office.regionID,
        },
      })

      let updatedOffice = { ...office, dirty: false }
      dispatch(updateOffice({ index: officeIndex, updatedOffice }))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
