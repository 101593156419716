import EffectCollector from 'side-effects/effectCollector'
import { parse, differenceInYears, isFuture, isAfter } from 'date-fns'
const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (question_id === 'language_table' && answer && Array.isArray(answer)) {
    const filteredAnswer = answer.map(row => {
      const rowCopy = { ...row }
      if (rowCopy.language !== '00') {
        rowCopy.language_specify = null
      }
      return rowCopy
    })
    effectCollector.add('language_table', filteredAnswer)
  }

  if (
    question_id === 'military_experience' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('explain_military_experience', null)
    effectCollector.add('honorable_discharge', null)
  }

  if (question_id === 'dob' && answer) {
    const formattedAnswer = answer
      ? parse(answer, 'MM/dd/yyyy', new Date())
      : answer
    if (
      formattedAnswer &&
      isAfter(formattedAnswer, new Date('01/01/1000')) &&
      !isFuture(formattedAnswer)
    ) {
      const result = differenceInYears(Date.now(), formattedAnswer)
      effectCollector.add('age', `${result}`)
    } else {
      effectCollector.add('age', '')
    }
  }
  return effectCollector.effects
}

export default effects;