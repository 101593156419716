import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const DomesticAbuseHumanTrafficking = React.memo(({ isPreview }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Human Trafficking Questions'
          domain='Domestic Abuse'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions to worker: Following is guidance, not intended to be read
          to the client. Refer individual to his or her copy of the OCAT Rights
          and Privacy Overview form. Remind individual that the interview is
          confidential except for mandated reporting of suspected elder and
          child abuse. For the following section, ask your client to think about
          the following questions in terms of people who are in their home (such
          as a partner) and people who are not in their home (such as someone
          they work for).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>
          Domestic Abuse - Human Trafficking Questions
        </Typography>
        <form>
          <Select question_id='forced_to_stay_in_job' isPreview={isPreview} />
          <Select question_id='family_threatened_to_leave' isPreview={isPreview} />
          <Select question_id='been_deprived_food_water' isPreview={isPreview} />
          <Select question_id='locks_on_doors_windows' isPreview={isPreview} />
          <Select question_id='identity_docs_taken_away' isPreview={isPreview} />
          <Select question_id='forced_to_work_long_hours' isPreview={isPreview} />
          <Select question_id='forcing_you_to_do_anything' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})
export default DomesticAbuseHumanTrafficking
