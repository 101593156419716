import { combineReducers } from '@reduxjs/toolkit'
import dialogReducer from 'ducks/dialogSlice'
import interviewReducer from 'ducks/interviewSlice'
import interviewProgressReducer from 'ducks/interviewProgressSlice'
import unsavedChangesReducer from 'ducks/unsavedChangesSlice'
import userReducer from 'ducks/userSlice'
import clientReducer from 'ducks/clientSlice'
import createClientSlice from 'ducks/createClientSlice'
import validationErrorsReducer from 'ducks/validationErrorsSlice'
import navigationReducer from 'ducks/navigationSlice'
import readyReducer from 'ducks/readySlice'
import recommendationReducer from 'ducks/recommendationsSlice'
import currentInterviewPageReducer from 'ducks/currentInterviewPageSlice'
import lastSavedReducer from 'ducks/lastSavedSlice'
import noteReducer from 'ducks/noteSlice'
import adminReducer from 'ducks/adminSlice'
import unsavedNotesReducer from 'ducks/unsavedNotesSlice'
import snackbarReducer from 'ducks/snackbarSlice'
import sharingCountiesReducer from 'ducks/sharingCountiesSlice'
import unsavedActionPlansReducer from 'ducks/unsavedActionPlansSlice'
import resourceReducer from 'ducks/resourceSlice'
import newInterviewReducer from 'ducks/newInterviewSlice'
import printQuestionnaireSliceReducer from 'ducks/printQuestionnaireSlice'
import sessionReducer from 'ducks/sessionSlice'
import entitiesSliceReducer from 'ducks/entitiesSlice'
import clientListReducer from 'ducks/clientListSlice'
import clientSearchReducer from 'ducks/clientSearchSlice'
import ruleReducer from 'ducks/ruleSlice'
import interviewSavingQueueReducer from 'ducks/interviewSavingQueueSlice'
import datePickerReducer from 'ducks/datePickerSlice'
import questionReducer from 'ducks/questionSlice'
import previewSlice from 'ducks/previewSlice'
import pendingChangesReducer from 'ducks/pendingChangesSlice'
import environmentReducer from 'ducks/environmentSlice'

const rootReducer = combineReducers({
  dialog: dialogReducer,
  interview: interviewReducer,
  interviewProgress: interviewProgressReducer,
  unsavedChanges: unsavedChangesReducer,
  user: userReducer,
  client: clientReducer,
  createClient: createClientSlice,
  validationErrors: validationErrorsReducer,
  navigation: navigationReducer,
  ready: readyReducer,
  currentInterviewPage: currentInterviewPageReducer,
  lastSaved: lastSavedReducer,
  notes: noteReducer,
  admin: adminReducer,
  entities: entitiesSliceReducer,
  unsavedNote: unsavedNotesReducer,
  snackbar: snackbarReducer,
  recommendation: recommendationReducer,
  sharingCounties: sharingCountiesReducer,
  unsavedActionPlans: unsavedActionPlansReducer,
  resources: resourceReducer,
  newInterview: newInterviewReducer,
  printQuestionnaire: printQuestionnaireSliceReducer,
  session: sessionReducer,
  clientList: clientListReducer,
  clientSearch: clientSearchReducer,
  ruleManagement: ruleReducer,
  interviewSavingQueue: interviewSavingQueueReducer,
  datePicker: datePickerReducer,
  questionManagement: questionReducer,
  preview: previewSlice,
  pendingChanges: pendingChangesReducer,
  environment: environmentReducer
})

export default rootReducer
