export const updateCookieExpiration = (cookieName, expirationSeconds) => {
  const value = getCookie(cookieName)
  if (!value) return
  var d = new Date()
  d = new Date(d.getTime() + 1000 * expirationSeconds)
  document.cookie = `${cookieName}=${value}; expires=${d.toGMTString()};`
}

export const getCookie = name => {
  // console.log(`looking for cookie: ${name}`)
  // const value = `; ${document.cookie}`
  // console.log(`cookie value retrieved: ${value}`)
  // const parts = value.split(`; ${name}=`)
  // console.log(`split parts: ${parts}`)
  // console.log(`part count: ${parts.length}`)
  // if (parts.length === 2) return parts.pop().split(';').shift()

  const cookies = document.cookie
    .split(';')
    .reduce((acc, val) => {
      const splitIndex =  val.indexOf('=');
      const cookieName = val.substring(0, splitIndex).trim();
      const cookieValue = val.substring(splitIndex + 1).trim();
      acc[cookieName] = cookieValue;
      return acc;
    }, {});

    return cookies[name];

}

export const deleteCookie = (name, domain) => {
  var d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * -1)
  document.cookie =
    name + '=;path=/;expires=' + d.toGMTString() + ';domain=' + domain
}

export const deleteForgeRockCookies = () => {
  const frCookies = [
    'iPlanetDirectoryPro',
    'dtCookie',
    'dtSa',
    'dtLatC',
    'dtPC',
    'AMPCookie',
    'rxVisitor',
    'amlbcookie',
    'rxvt',
  ]

  frCookies.forEach(cookie => deleteCookie(cookie, '.calsaws.net'))
}
