import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { setLatestChange, setLatestChangeLoaded, setLatestChangeLoading } from 'ducks/ruleSlice'

const GET_LATEST_CHANGE_INFO = gql`
  query GET_LATEST_CHANGE_INFO($recordID: Int!, $recordType: String!) {
    latestChange(recordID: $recordID, recordType: $recordType) {
      createdBy
      createdAt
    }
  }
`
export const refreshLastUpdateData = ({ recordType, recordID }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLatestChangeLoading(true))
      const lastUpdateData = await client.query({
        query: GET_LATEST_CHANGE_INFO,
        fetchPolicy: 'network-only',
        variables: { recordID, recordType }
      })

      const lastChangePayload = lastUpdateData.data.latestChange
      dispatch(setLatestChange(lastChangePayload))

      dispatch(setLatestChangeLoaded(true))
      dispatch(setLatestChangeLoading(false))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(setLatestChangeLoaded(true))
      dispatch(setLatestChangeLoading(false))
    }
  }
}
