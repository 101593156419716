import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import ClientListQueries from '../clientList/ClientListQueries'
import { openDialog } from '../../ducks/dialogSlice'
import { useDispatch } from 'react-redux'
import SearchText from './SearchText'
import SearchSelect from './SearchSelect'
import SearchDropDown from './SearchDropdown'
import SearchDatePicker from './SearchDatePicker'
import { isValid, format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { decodeHtml } from 'utilTools/decodeHtml'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: '1200px',
    marginLeft: '15px',
  },
}))

const StyledButton = styled(Button)`
  && {
    margin: 16px 16px 16px 0;
    min-width: 100px;
  }
`

const InputContainer = styled.div`
  max-width: 251px;
`
const DropdownContainer = styled.div`
  margin-top: 20px;
  max-width: 251px;
`

const ButtonContainer = styled.div`
  margin-top: 15px;
`

export default function ClientSearchFilterMenu({
  handleClientSearch,
  searchCriteria,
  searchCriteriaChanged,
  onReset,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    firstName,
    lastName,
    ssn,
    cin,
    birthDate,
    countyId,
    caseNumber,
  } = searchCriteria

  const handleFilterChange = (filter, value) => {
    searchCriteriaChanged(filter, value)
  }

  const {
    loading: countiesLoading,
    error: countiesErr,
    data: countiesData,
  } = useQuery(ClientListQueries.COUNTIES_LIST_QUERY)

  const {
    loading: interviewersLoading,
    error: interviewersError,
    data: interviewerOptions,
  } = useQuery(ClientListQueries.INTERVIEWER_OPTIONS)

  const loading = countiesLoading
  const error = countiesErr
  const counties = countiesData?.counties || []

  useEffect(() => {
    if (error || interviewersError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
            interviewersError,
          },
        })
      )
    }
  }, [error, interviewersError, dispatch])

  const clearAll = () => {
    onReset()
  }

  const submitSearch = e => {
    e.preventDefault()
    handleClientSearch()
  }

  const dropdownOptionsGenerator = dataArray => {
    return (dataArray || []).map(element => {
      return { optionId: element.id, optionLabel: decodeHtml(element.description) }
    })
  }

  const handleDOBChange = (date, value) => {
    // If it's a valid date, we send the date formatted "MM/dd/yyyy"
    if (isValid(date)) {
      handleFilterChange('birthDate', format(date, 'MM/dd/yyyy'))
    } else if (value === null) {
      handleFilterChange('birthDate', '')
      // Else just send the value
    } else {
      handleFilterChange('birthDate', value)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant='h4'>Client Search</Typography>
      <Typography variant='h6'>Search By</Typography>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchText
              label='First Name'
              value={firstName}
              handleChange={e =>
                handleFilterChange('firstName', e.target.value)
              }
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchText
              label='Last Name'
              value={lastName}
              handleChange={e => handleFilterChange('lastName', e.target.value)}
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchDatePicker
              label='Date of Birth'
              inputValue={birthDate}
              handleChange={handleDOBChange}
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchText
              label='SAWS Case Number'
              value={caseNumber}
              handleChange={e =>
                handleFilterChange('caseNumber', e.target.value)
              }
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchText
              label='SSN'
              value={ssn}
              handleChange={e => handleFilterChange('ssn', e.target.value)}
              type='ssn'
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchText
              label='CIN'
              value={cin}
              handleChange={e => handleFilterChange('cin', e.target.value)}
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <DropdownContainer>
            <SearchDropDown
              label='County'
              value={countyId}
              handleChange={e => handleFilterChange('countyId', e.target.value)}
              options={dropdownOptionsGenerator(counties)}
            />
          </DropdownContainer>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputContainer>
            <SearchSelect
              label='Interviewer'
              options={interviewerOptions?.clientSearchInterviewerOptions.map(o => ({...o, label: decodeHtml(o.label)}))}
              searchable={true}
              handleChange={option =>
                handleFilterChange('interviewerId', option?.value ?? null)
              }
            />
          </InputContainer>
        </Grid>

        <Grid item xs={12}>
          <ButtonContainer>
            <StyledButton
              onClick={clearAll}
              variant='contained'
              color='secondary'
              disabled={loading || interviewersLoading}
            >
              Clear
            </StyledButton>
            {/* without type=submit, hitting enter on the form inputs wont submit the form */}
            <StyledButton
              onClick={submitSearch}
              color='primary'
              variant='contained'
              disabled={loading || interviewersLoading}
              type='submit'
            >
              Search
            </StyledButton>
          </ButtonContainer>
        </Grid>
      </Grid>
    </div>
  )
}

ClientSearchFilterMenu.propTypes = {
  handleClientSearch: PropTypes.func.isRequired,
}
