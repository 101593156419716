import { openDialog } from 'ducks/dialogSlice'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'

const LOG_ERROR = gql`
  mutation logError($logData: LogDataInput!) {
    logError(logData: $logData) {
      userID
    }
  }
`

export const logError = logData => {
  return async (dispatch, getState) => {
    try {
      // Add countyID and userID
      logData.userID = getState().user?.id
      logData.countyID = getState().user.countyId

      // Uses various fallback methods for obtaining the stacktrace
      // err.stack works in modern chrome firefox
      // err.stacktrace works in opera
      // console.trace works in IE11
      logData.stackTrace =
        logData.err.stack ||
        logData.err.stacktrace ||
        (console.trace ? console.trace() : 'no stack info')

      // Adding timeStamp
      logData.timeStamp = Date.now()

      // Defaulting severity to error if not specified
      logData.severity = logData.severity || 'error'

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          logData: logData,
        },
      })

      dispatch(
        openDialog({
          type: 'error',
          title: `Error`,
          props: {
            frontEndErrorTrace: logData.stackTrace,
          },
        })
      )
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // In this situation, the front end was unable to relay the error log to the backend
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
