// import { revertInterviewData } from 'ducks/interviewSlice'
import { batch } from 'react-redux'
import { clearInterviewData } from 'ducks/interviewSlice'
import { setInterview, setInterviewStatus } from 'ducks/clientSlice'
import { setLastSaved } from 'ducks/lastSavedSlice'
import { setInterviewNavigation } from 'ducks/navigationSlice'
import { clearInterviewProgress } from 'ducks/interviewProgressSlice'
import { clearUnsavedChangesSlice } from 'ducks/unsavedChangesSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import { setCurrentInterviewPage } from 'ducks/currentInterviewPageSlice'
import { clearClient } from 'ducks/clientSlice'
import { navigateWithoutSave } from 'thunks/navigateWithoutSave'
import { clearNotes } from 'ducks/noteSlice'
import { setCreatingClient } from 'ducks/createClientSlice'
import { clearRecommendations } from 'ducks/recommendationsSlice'
import { clearUnsavedActionPlans } from 'ducks/unsavedActionPlansSlice'

export const discardChangesAndNavigate = (
  subdomainId,
  history,
  discardActionType = 'interview'
) => {
  return async (dispatch, getState) => {
    if (discardActionType === 'interview') {
      batch(() => {
        // Clear Interview Current Page
        dispatch(setCurrentInterviewPage(null))
        // Clear Interview Data Ready State Slice is tied to the
        // presence of interview data through extraReducers.
        dispatch(clearInterviewData())
        // Clear User Interview
        dispatch(setInterview(null))
        // Clear User Interview Status
        dispatch(setInterviewStatus(null))
        // Clear Last Saved State
        dispatch(setLastSaved(null))
        // Clear Navigation State
        dispatch(setInterviewNavigation([]))
        // Clear Interview Progress State
        dispatch(clearInterviewProgress())
        // Clear Unsaved Changes State
        dispatch(clearUnsavedChangesSlice())
        // Clear Validation Errors State
        dispatch(removeAllValidationErrors())
        // Clear Recommendations State
        dispatch(clearRecommendations())
        // Clear Unsaved Action Plans State
        dispatch(clearUnsavedActionPlans())
        if (subdomainId !== '/interview_list') {
          dispatch(clearClient())
        }
        // Clear Interview Notes
        dispatch(clearNotes())

        dispatch(navigateWithoutSave(subdomainId, history))
      })
    } else if (discardActionType === 'createClient') {
      batch(() => {
        dispatch(setCreatingClient(false))
        dispatch(navigateWithoutSave(subdomainId, history))
      })
    }
    // TODO: Include logic for navigating away from admin pages
  }
}
