import { createSlice } from '@reduxjs/toolkit'
import { SPECIAL_VALUES } from 'components/clientList/clientListHelper'

const initialState = {
  records: null,
  pageIndex: 0,
  pageSize: 25,
  inputFilters: {},
  serverFilters: null,
  orderBy: 'update',
  order: 'desc',
  currentRow: null,
  totalRecordCount: 0,
}

const clientSearchSlice = createSlice({
  name: 'clientSearch',
  initialState,
  reducers: {
    resetClientSearch: () => initialState,
    setClientSearchData: (state, action) => {
      state.records = action.payload.records
      state.totalRecordCount = action.payload.totalRecordCount
      state.pageIndex = action.payload.pageIndex
      state.pageSize = action.payload.pageSize
      // check if the current page index is valid for the
      // the new dataset. If not reset them to the 1st page
      if (state.totalRecordCount <= state.pageIndex * state.pageSize) {
        state.pageIndex = 0
      }
    },
    changePageIndex: (state, action) => {
      state.pageIndex = action.payload
    },
    changePageSize: (state, action) => {
      state.pageIndex = 0
      state.pageSize = action.payload
    },
    changeSort: (state, action) => {
      state.orderBy = action.payload.orderBy
      state.order = action.payload.order
    },
    initializeClientListForUser: (state, action) => {
      const { userId, userCountyId } = action.payload
      state.serverFilters = {
        interviewer: [SPECIAL_VALUES.UNASSIGNED_OPTION_ID, userId],
        status: ['New', 'In Process', 'Ready to Generate'],
        dates: 'All Dates',
        countyFilter: userCountyId,
      }
    },
    changeSearchCriteria: (state, action) => {
      state.serverFilters = action.payload
    },
    changeLocalFilters: (state, action) => {
      state.inputFilters = action.payload
    },
    changeCurrentRow: (state, action) => {
      state.currentRow = action.payload
    },
  },
})

export const {
  resetClientSearch,
  setClientSearchData,
  initializeClientListForUser,
  changeSearchCriteria,
  changeLocalFilters,
  changeSort,
  changePageIndex,
  changePageSize,
  changeCurrentRow,
} = clientSearchSlice.actions

export default clientSearchSlice.reducer
