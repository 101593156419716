import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isActiveSession: false,
  isTimedOut: false,
  lastActive: null,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setActiveSession: (state, action) => {
      state.isActiveSession = true
      state.lastActive = new Date()
    },
    setIdleSession: state => {
      state.isActiveSession = false
    },
    setTimedOut: (state, action) => {
      state.isTimedOut = action.payload
    },
  },
})
export const {
  setActiveSession,
  setIdleSession,
  setTimedOut,
} = sessionSlice.actions

export default sessionSlice.reducer
