import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteDropdown from 'components/table/InfiniteDropdown'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteSelect from 'components/table/InfiniteSelect'

const childCareCpsSelector = state => state.interview.involved_cps.answer
const childCareCpsPreviewSelector = state => state.preview.involved_cps.answer

const primaryCaregiverSelector = state =>
  state.interview.primary_caregiver.answer

const previewPrimaryCaregiverSelector = state =>
  state.preview.primary_caregiver.answer

const primaryCaregiverTableSelector = state =>
  state.interview.primary_caregiver_table.answer

const previewPrimaryCaregiverTableSelector = state =>
  state.preview.primary_caregiver_table.answer

const selectChildCareCps = createSelector(
  childCareCpsSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectPreviewChildCareCps = createSelector(
  childCareCpsPreviewSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectPrimaryCaregiver = createSelector(
  primaryCaregiverSelector,
  answer => !answer || (answer && answer?.toUpperCase() === 'NO')
)

const selectPreviewPrimaryCaregiver = createSelector(
  previewPrimaryCaregiverSelector,
  answer => !answer || (answer && answer?.toUpperCase() === 'NO')
)

const selectPrimaryCaregiverTable = createSelector(
  primaryCaregiverTableSelector,
  answer => Array.isArray(answer) && answer.length !== 0
)

const selectPreviewPrimaryCaregiverTable = createSelector(
  previewPrimaryCaregiverTableSelector,
  answer => Array.isArray(answer) && answer.length !== 0
)

const PrimaryCaregiverMembersRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteDropdown
        sub_question_id={'primary_caregiver_relationship'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteSelect
        sub_question_id={'primary_caregiver_in_home'}
        isPreview={isPreview}
        {...props}
      />
      <InfiniteText
        sub_question_id={'primary_caregiver_in_home_details'}
        isPreview={isPreview}
        shouldDecode={true}
        multiline
        rows='2'
        {...props}
      />
      <InfiniteSelect
        sub_question_id={'primary_caregiver_other_available'}
        isPreview={isPreview}
        {...props}
      />
    </>
  )
})

const primaryCaregiverTableQuestionSelector = state => state.interview.primary_caregiver_table
const previewPrimaryCaregiverTableQuestionSelector = state => state.preview.primary_caregiver_table

const caregiverTableQuestion = createSelector(primaryCaregiverTableQuestionSelector, q => q)
const previewCaregiverTableQuestion = createSelector(previewPrimaryCaregiverTableQuestionSelector, q => q)

const ChildCarePrimaryCaregiver = ({ isPreview }) => {
  const childCareCps = useSelector(isPreview ? selectPreviewChildCareCps : selectChildCareCps)
  const primaryCaregiver = useSelector(isPreview ? selectPreviewPrimaryCaregiver : selectPrimaryCaregiver)
  const primaryCaregiverTable = useSelector(isPreview ? selectPreviewPrimaryCaregiverTable : selectPrimaryCaregiverTable)
  const primaryCaregiverTableQuestion = useSelector(isPreview ? previewCaregiverTableQuestion : caregiverTableQuestion)
  
  const headers = [
    primaryCaregiverTableQuestion.sub_question_ids.primary_caregiver_relationship.label,
    primaryCaregiverTableQuestion.sub_question_ids.primary_caregiver_in_home.label,
    primaryCaregiverTableQuestion.sub_question_ids.primary_caregiver_in_home_details.label,
    primaryCaregiverTableQuestion.sub_question_ids.primary_caregiver_other_available.label
  ]
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Primary Caregiver'
          domain='Child Care and Parenting'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select
            isDisabled={primaryCaregiverTable}
            multiline
            rows='4'
            question_id='primary_caregiver'
            isPreview={isPreview}
          />
          <InfiniteTable
            isDisabled={primaryCaregiver}
            question_id='primary_caregiver_table'
            headers={headers}
            rowComponent={<PrimaryCaregiverMembersRow isPreview={isPreview} />}
            isPreview={isPreview}
          ></InfiniteTable>
          <Select multiline rows='4' question_id='involved_cps' isPreview={isPreview} />
          <Text
            multiline
            subquestion
            rows='4'
            question_id='involved_cps_explain'
            isDisabled={!childCareCps}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
}

export default ChildCarePrimaryCaregiver
