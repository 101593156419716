import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import TableCell from '@material-ui/core/TableCell'

const questionSelector = (state, question_id) => {
  return state.interview[question_id]
}

const previewQuestionSelector = (state, question_id) => {
  return state.preview[question_id]
}

const selectQuestion = () => {
  return createSelector(questionSelector, question_id => question_id)
}

const selectPreviewQuestion = () => {
  return createSelector(previewQuestionSelector, question_id => question_id)
}

const InfiniteTableRowDescription = ({
  question_id,
  sub_question_id,
  noTable = false,
  isPreview
}) => {
  const dataSelector = isPreview ? selectPreviewQuestion() : selectQuestion()
  const { sub_question_ids } = useSelector(state =>
    dataSelector(state, question_id)
  )
  const description = sub_question_ids[sub_question_id].description
  const label = sub_question_ids[sub_question_id].label
  // Show description or label based on the question type
  // (short text has label, regular text has description)
  if (noTable) {
    return <>{description || label}</>
  }
  return <TableCell>{description || label}</TableCell>
}

export default InfiniteTableRowDescription
