import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { openDialog } from 'ducks/dialogSlice'

const StyledButton = styled(Button)`
  && {
    margin: 16px;
    display: inline-block;
  }
`

const AdminCancelButton = ({ isNewUser }) => {
  const dispatch = useDispatch()
  const handleCancel = () => {
    dispatch(
      openDialog({
        type: 'adminCancel',
        title: 'Please confirm',
        description: `Are you sure you want to cancel ${
          isNewUser
            ? 'creating a new user?'
            : 'editing the user? Any changes made will be discarded.'
        }`,
      })
    )
  }
  return (
    <StyledButton variant='contained' color='secondary' onClick={handleCancel}>
      Cancel
    </StyledButton>
  )
}

export default AdminCancelButton
