import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  effectiveDate: null,
}

const datePickerSlice = createSlice({
  name: 'datePicker',
  initialState: initialState,
  reducers: {
    setValue: (state, action) => {
      const dateToUpdate = new Date(action.payload.value)
      const formatDate = (((dateToUpdate.getMonth() > 8) ? (dateToUpdate.getMonth() + 1)
      : ('0' + (dateToUpdate.getMonth() + 1))) + '/' + ((dateToUpdate.getDate() > 9) 
      ? dateToUpdate.getDate() : ('0' + dateToUpdate.getDate())) + '/' + dateToUpdate.getFullYear())
      state[action.payload.field] = formatDate
    },
  },
})

export const { setValue } = datePickerSlice.actions

export default datePickerSlice.reducer
