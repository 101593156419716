import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'

const UPDATE_INTERVIEW_SHARING = gql`
  mutation upsertInterviewSharing(
    $interviewId: Int!
    $sharingCounties: [Int]!
  ) {
    upsertInterviewSharing(
      interviewId: $interviewId
      sharingCounties: $sharingCounties
    ) {
      id
    }
  }
`

export const saveSharingCounties = interviewId => {
  return async (dispatch, getState) => {
    try {
      const sharingCounties = getState().sharingCounties.checkedCounties
      await client.mutate({
        mutation: UPDATE_INTERVIEW_SHARING,
        variables: {
          interviewId,
          sharingCounties,
        },
      })

      return true
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      return false
    }
  }
}
