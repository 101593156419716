import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'rg_drnk_alc' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('alc_how_often', null)
    effectCollector.add('alc_how_many', null)
  }
  if (
    question_id === 'used_meds' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('meds_how_often', null)
  }

  if (
    question_id === 'alc_3_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_3_issue_now', null)
  }
  if (
    question_id === 'alc_4_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_4_issue_now', null)
  }
  if (
    question_id === 'alc_5_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_5_issue_now', null)
  }
  if (
    question_id === 'alc_6_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_6_issue_now', null)
  }
  if (
    question_id === 'alc_7_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_7_issue_now', null)
  }
  if (
    question_id === 'alc_8_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_8_issue_now', null)
  }
  if (
    question_id === 'alc_9_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_9_issue_now', null)
  }
  if (
    question_id === 'alc_10_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_10_issue_now', null)
  }
  if (
    question_id === 'alc_11_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_11_issue_now', null)
  }
  if (
    question_id === 'alc_12_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_12_issue_now', null)
  }
  if (
    question_id === 'alc_13_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_13_issue_now', null)
  }
  if (
    question_id === 'alc_14_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_14_issue_now', null)
  }
  if (
    question_id === 'alc_15_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_15_issue_now', null)
  }
  if (
    question_id === 'alc_a_alc_depend' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_a_issue_now', null)
  }
  if (
    question_id === 'alc_b_drg_depend' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('alc_b_issue_now', null)
  }
  if (
    question_id === 'absd_presc_non_pres_prob' &&
    (!answer || answer.toUpperCase() === 'NO')
  ) {
    effectCollector.add('absd_presc_non_pres_issue_now', null)
  }
  return effectCollector.effects
}

export default effects