import React from 'react'
import { useSelector } from 'react-redux'
import Can from 'app/Can'
import PropTypes from 'prop-types'
import { colors } from '../../app/theme'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import ClientSearchFilterMenu from './ClientSearchFilterMenu'
import { Link } from 'react-router-dom'
import ClientListTable from '../clientList/ClientListTable'
import {
  formatClientsFromSearch,
  filterByTextInputs,
  handleInputFilters,
} from '../clientList/clientListHelper'

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`
const CreateClient = styled.span`
  float: right;
  font-size: 14px;
  font-weight: 400;
`
const StyledLink = styled(Link)`
  && {
    color: ${colors.turquoise};
  }
`

export default function ClientSearch({
  handleClientSearch,
  loading,
  records,
  totalRecordCount,
  filters,
  filtersChanged,
  inputFilters,
  handleInputFiltersChanged,
  onReset,
  ...ClientTablePassthroughProps
}) {
  const { roles } = useSelector(state => state.user)

  let noResults = null
  let resultsTable = null

  const shouldShowResultsOrFeedback = !loading && records
  if (shouldShowResultsOrFeedback) {
    if (!records.length) {
      noResults = <h4>No records were found which match all the filters.</h4>
    } else {
      const tableData = formatClientsFromSearch(records)
      const filteredTableData = tableData.filter(record =>
        filterByTextInputs(record, inputFilters)
      )

      resultsTable = (
        <ClientListTable
          displayGender='true'
          tableData={filteredTableData}
          totalRecordCount={totalRecordCount}
          inputFilters={inputFilters}
          handleInputFilters={handleInputFilters(
            inputFilters,
            handleInputFiltersChanged
          )}
          {...ClientTablePassthroughProps}
        />
      )
    }
  }

  return (
    <Container>
      <ClientSearchFilterMenu
        handleClientSearch={handleClientSearch}
        searchCriteria={filters}
        searchCriteriaChanged={filtersChanged}
        onReset={onReset}
      />

      {loading ? (
        <Typography variant='h6'>Fetching results...</Typography>
      ) : null}

      {!loading && records ? (
        <Typography variant='h6'>
          Search Results ({totalRecordCount || '0'})
          <Can
            roles={roles}
            perform='client:create'
            yes={() => (
              <CreateClient>
                <em style={{ marginRight: '13px' }}>Client not found?</em>
                <StyledLink to='/create_client'>Create New Client</StyledLink>
              </CreateClient>
            )}
          />
        </Typography>
      ) : null}

      {noResults}
      {resultsTable}
    </Container>
  )
}

ClientSearch.propTypes = {
  handleClientSearch: PropTypes.func.isRequired,
  records: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
}
