import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import gql from 'graphql-tag'
import { openDialog } from 'ducks/dialogSlice'
import { setClient } from 'ducks/clientSlice'

const clientQuery = gql`
  query Client($clientId: Int) {
    clientById(clientId: $clientId) {
      id
      cin
      firstName
      lastName
      birthDate
    }
  }
`

const InterviewListDirect = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const goToHome = useCallback(() => history.push('/'), [history])
  const goToInterviewList = useCallback(() => history.push('/interview_list'), [
    history,
  ])

  const clientId =
    match &&
    match.params &&
    match.params.clientId &&
    parseInt(match.params.clientId)

  const [getClientData, { data, error }] = useLazyQuery(clientQuery, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!clientId) {
      goToHome()
    } else {
      getClientData({ variables: { clientId } })
    }
  }, [clientId, goToHome, getClientData])

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )

      goToHome()
    }
  }, [error, dispatch, goToHome])

  useEffect(() => {
    if (data) {
      if (data.clientById) {
        const { id, cin, firstName, lastName, birthDate: dob } = data.clientById

        dispatch(
          setClient({
            id,
            firstName,
            lastName,
            cin,
            dob,
          })
        )

        goToInterviewList()
      } else {
        // no data found for the client so it is invalid, redirect to root.
        // If we want to display an error message this will need to be revisited.
        goToHome()
      }
    }
  }, [data, dispatch, goToHome, goToInterviewList])

  return <div>Loading Client...</div>
}

export default InterviewListDirect
