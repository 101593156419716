import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { colors } from 'app/theme'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const StyledTypography = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`
const childCareConcernsSelector = state =>
  state.interview.child_care_and_parenting_concerns.answer

const childCareConcernsPreviewSelector = state =>
  state.preview.child_care_and_parenting_concerns.answer

const childCareProblemsSelector = state =>
  state.interview.child_care_problems.answer

const childCareProblemsPreviewSelector = state =>
  state.preview.child_care_problems.answer

const selectChildCareConcerns = createSelector(
  childCareConcernsSelector,
  answer => answer === 'YS'
)

const selectChildCareConcernsPreview = createSelector(
  childCareConcernsPreviewSelector,
  answer => answer === 'YS'
)

const selectChildCareConcernsOther = createSelector(
  childCareProblemsSelector,
  answer => answer && answer.includes('OT')
)

const selectChildCareConcernsOtherPreview = createSelector(
  childCareProblemsPreviewSelector,
  answer => answer && answer.includes('OT')
)

const ChildCare = React.memo(({ isPreview }) => {
  const childCareIssues = useSelector(isPreview ? selectChildCareConcernsPreview : selectChildCareConcerns)
  const childCareIssuesOther = useSelector(isPreview ? selectChildCareConcernsOtherPreview : selectChildCareConcernsOther)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Child Care'
          domain='Child Care and Parenting'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Now I'd like to ask about child care arrangements and how they may
          affect your ability to participate in work or WTW activities.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Child Care</Typography>
      </Grid>

      <Grid item xs={12}>
        <form>
          <Select question_id='child_care_and_parenting_concerns' isPreview={isPreview} />
          <StyledTypography variant='body2' disabled={!childCareIssues}>
            Instructions: Do not read list aloud. Please check all that the
            customer client mentions.
          </StyledTypography>
          <CheckboxGrid
            question_id='child_care_problems'
            isDisabled={!childCareIssues}
            isPreview={isPreview}
          />
          <Text
            multiline
            rows='4'
            question_id='child_care_explain'
            isDisabled={!childCareIssuesOther}
            shouldDecode={true}
            isPreview={isPreview}
          />
        </form>
      </Grid>
    </Grid>
  )
})

export default ChildCare
