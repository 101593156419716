import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDatePicker from 'components/table/InfiniteDatePicker'

const convictedCriminalOffenseSelector = state =>
  state.interview.convicted_criminal_offense.answer

const previewConvictedCriminalOffenseSelector = state =>
  state.preview.convicted_criminal_offense.answer

const upcomingCourtDatesSelector = state =>
  state.interview.upcoming_court_dates.answer

const previewUpcomingCourtDatesSelector = state =>
  state.preview.upcoming_court_dates.answer

const courtDateTableSelector = state => state.interview.court_dates_table.answer
const previewCourtDateTableSelector = state => state.preview.court_dates_table.answer

const selectConvictedCriminalOffense = createSelector(
  convictedCriminalOffenseSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)
const selectPreviewConvictedCriminalOffense = createSelector(
  previewConvictedCriminalOffenseSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectUpcomingCourtDates = createSelector(
  upcomingCourtDatesSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectPreviewUpcomingCourtDates = createSelector(
  previewUpcomingCourtDatesSelector,
  answer => answer && answer?.toUpperCase() === 'YS'
)

const selectCourtDateTable = createSelector(
  courtDateTableSelector,
  answer => answer && answer.length !== 0
)

const selectPreviewCourtDateTable = createSelector(
  previewCourtDateTableSelector,
  answer => answer && answer.length !== 0
)

const courtDateTableQuestionSelector = state =>
  state.interview.court_dates_table

const previewCourtDateTableQuestionSelector = state =>
  state.preview.court_dates_table

const courtDateTableQuestion = createSelector(courtDateTableQuestionSelector, q => q)
const previewCourtDateTableQuestion = createSelector(previewCourtDateTableQuestionSelector, q => q)

const CourtDatesRow = props => {
  const { isDisabled, isPreview } = props
  return (
    <>
      <InfiniteText
        isDisabled={isDisabled}
        sub_question_id={'reason_legal_barrier'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteDatePicker
        isDisabled={isDisabled}
        hideLabel
        sub_question_id={'date_court'}
        datePickerOptions={{ format: 'MM/dd/yyyy' }}
        isPreview={isPreview}
        {...props}
      />
    </>
  )
}

const LegalBarriers = React.memo(({ isPreview }) => {
  const hasConvictedCriminalOffense = useSelector(
    isPreview ? selectPreviewConvictedCriminalOffense : selectConvictedCriminalOffense
  )
  const upcomingCourtDates = useSelector(isPreview ? selectPreviewUpcomingCourtDates : selectUpcomingCourtDates)
  const courtDates = useSelector(isPreview ? selectPreviewCourtDateTable : selectCourtDateTable)
  const courtDatesQuestion = useSelector(isPreview ? previewCourtDateTableQuestion : courtDateTableQuestion)
  
  const headers = [
    courtDatesQuestion.sub_question_ids.reason_legal_barrier.label,
    courtDatesQuestion.sub_question_ids.date_court.label
  ]
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader subdomain='Legal Barriers' domain='Employment' />
        <Typography variant='h5'>Preamble</Typography>
        <Typography variant='h6'>
          Sometimes having a criminal record can make it harder to get a job.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <Select question_id='convicted_criminal_offense' isPreview={isPreview} />
          <Select
            question_id='type_of_offense'
            isDisabled={!hasConvictedCriminalOffense}
            subquestion
            isPreview={isPreview}
          />
          <Text
            question_id='explain_legal_barrier'
            multiline
            rows={4}
            isDisabled={!hasConvictedCriminalOffense}
            subquestion
            shouldDecode={true}
            isPreview={isPreview}
          />
          <Select
            question_id='on_parole_now'
            isDisabled={!hasConvictedCriminalOffense}
            subquestion
            isPreview={isPreview}
          />
          <Select
            question_id='upcoming_court_dates'
            isDisabled={!hasConvictedCriminalOffense || courtDates}
            subquestion
            isPreview={isPreview}
          />
          <InfiniteTable
            rowComponent={<CourtDatesRow isDisabled={!upcomingCourtDates} isPreview={isPreview} />}
            headers={headers}
            question_id='court_dates_table'
            isDisabled={!upcomingCourtDates}
            isPreview={isPreview}
          />
          <Select question_id='outstanding_fines_warrents' isPreview={isPreview} />
          <Select question_id='help_expunging_record' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})
export default LegalBarriers
