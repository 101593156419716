import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  questionList: [],
  selectedQuestion: {
    id: null,
    questionID: '',
    questionConfig: '',
    subDomainCode: '',
    subDomain: '',
    domain: '',
    questionType: '',
    questionOrder: null,
    spanishText: ''
  },
  isDirty: false,
  updatedQuestionText: '',
  updatedSpanishQuestionText: '',
  optionsToDelete: [],
  optionsUnderEdit: [],
  optionsToAdd: [],
  subQuestionsUnderEdit: [],
  subQuestionOptionsUnderEdit: [],
  subQuestionOptionsToDelete: [],
  subQuestionOptionsToAdd: []
}

const questionManagementSlice = createSlice({
  name: 'questionManagement',
  initialState: initialState,
  reducers: {
    setQuestionList: (state, action) => {
      state.questionList = action.payload
      state.selectedQuestion = initialState.selectedQuestion
    },
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = {
        ...action.payload
      }
      state.isDirty = false
    },
    handleQuestionChange: (state, action) => {
      state.isDirty = true
      state.updatedQuestionText = action.payload
    },
    handleSpanishQuestionChange: (state, action) => {
      state.isDirty = true
      state.updatedSpanishQuestionText = action.payload
    },
    addDeleteOption: (state, action) => {
      state.optionsToDelete.push(action.payload) 
      state.isDirty = true
    },
    removeDeleteOption: (state, action) => {
      state.optionsToDelete.splice(action.payload, 1)
    },
    addEditOption: (state, action) => {
      state.optionsUnderEdit.push(action.payload) 
      state.isDirty = true
    },
    removeEditOption: (state, action) => {
      state.optionsUnderEdit.splice(action.payload, 1)
    },
    removeNewOption: (state, action) => {
      state.optionsToAdd.splice(action.payload, 1)
    },
    handleUpdateOption: (state, action) => {
      const index = state.optionsUnderEdit.findIndex(({ order }) => order === action.payload.order)
      state.optionsUnderEdit[index] = action.payload
      state.optionsUnderEdit[index].optionId = state.optionsUnderEdit[index].optionId.replace(/\d+/g, "").replace(/[^\w\s]/gi, '')
      state.isDirty = true
    },
    handleAddOption: (state, action) => {
      const placeholder = { ...action.payload, temp: true }
      state.selectedQuestion.questionConfig.options.push(placeholder) 
      state.optionsUnderEdit.push(action.payload) 
      state.optionsToAdd.push(action.payload)
    },
    handleSubQuestionAddOption: (state, action) => {
      let selectedSubQuestion = {}
      const subQuestions = Object.entries(state.selectedQuestion.questionConfig.sub_question_ids)
      selectedSubQuestion = subQuestions.find( x => x[0] === action.payload.subQuestionId)
      const placeholder = {...action.payload.option, temp: true }
      selectedSubQuestion[1].options.push(placeholder) 
      state.subQuestionOptionsUnderEdit.push(action.payload) 
      state.subQuestionOptionsToAdd.push(action.payload)
      state.isDirty = true
    },
    removeOptionFromQuestion: (state, action) => {
      state.selectedQuestion.questionConfig.options.splice(action.payload) 
      state.isDirty = true
    },
    addSubQuestionEdit: (state, action) => {
      state.subQuestionsUnderEdit.push(action.payload)
      state.isDirty = true
    },
    removeSubQuestionEdit: (state, action) => {
      state.subQuestionsUnderEdit.splice(action.payload, 1)
    },
    handleSubQuestionTextChange: (state, action) => {
      const index = state.subQuestionsUnderEdit.findIndex(x => x[0] === action.payload[0])
      state.subQuestionsUnderEdit[index] = action.payload
      state.isDirty = true
    },
    addSubQuestionOptionsUnderEdit: (state, action) => {
      state.subQuestionOptionsUnderEdit.push(action.payload)
      state.isDirty = true
    },
    removeSubQuestionOptionsUnderEdit: (state, action) => {
      state.subQuestionOptionsUnderEdit.splice(action.payload, 1)
    },
    removeNewSubQuestionOption: (state, action) => {
      state.subQuestionOptionsToAdd.splice(action.payload, 1)
    },
    handleSubQuestionUpdateOption: (state, action) => {
      const index = state.subQuestionOptionsUnderEdit.findIndex(x => x.subQuestionId === action.payload.subQuestionId && x.option.order === action.payload.option.order)
      state.subQuestionOptionsUnderEdit[index] = action.payload
      state.subQuestionOptionsUnderEdit[index].option.optionId = state.subQuestionOptionsUnderEdit[index].option.optionId.replace(/\d+/g, "").replace(/[^\w\s]/gi, '')
      state.isDirty = true
    },
    addSubQuestionDeleteOption: (state, action) => {
      state.subQuestionOptionsToDelete.push(action.payload)
      state.isDirty = true
    },
    removeSubQuestionDeleteOption: (state, action) => {
      state.subQuestionOptionsToDelete.splice(action.payload, 1)
    },
    removeOptionFromSubQuestion: (state, action) => {
      state.selectedQuestion.questionConfig.sub_question_ids[action.payload.subQuestionId].options.splice(action.payload.optionIndex, 1)
    },
    emptyEditArrays: (state, action) => {
      if(state.selectedQuestion.questionConfig.options) {
        state.selectedQuestion.questionConfig.options = state.selectedQuestion.questionConfig.options.filter(o => !o.temp)
      }

      if(state.selectedQuestion.questionConfig.sub_question_ids) {
        for(const subQuestion of Object.values(state.selectedQuestion.questionConfig.sub_question_ids)) {
          if(subQuestion.options) {
            subQuestion.options = subQuestion.options.filter(o => !o.temp)
          }
        }
      }
      
      state.isDirty = false
      state.updatedQuestionText = ''
      state.updatedSpanishQuestionText = ''
      state.optionsToDelete = []
      state.optionsUnderEdit = []
      state.optionsToAdd = []
      state.subQuestionsUnderEdit = []
      state.subQuestionOptionsUnderEdit = []
      state.subQuestionOptionsToDelete = []
      state.subQuestionOptionsToAdd = []
      
    },
    clearQuestionClient: (state, action) => initialState,
  }
})

export const {
  setSelectedQuestion,
  setQuestionList,
  handlePendingChange,
  handleQuestionChange,
  handleSpanishQuestionChange,
  addDeleteOption,
  removeDeleteOption,
  addEditOption,
  removeEditOption,
  handleUpdateOption,
  handleAddOption,
  removeNewOption,
  removeOptionFromQuestion,
  addSubQuestionEdit,
  removeSubQuestionEdit,
  handleSubQuestionTextChange,
  addSubQuestionOptionsUnderEdit,
  removeSubQuestionOptionsUnderEdit,
  handleSubQuestionUpdateOption,
  addSubQuestionDeleteOption,
  removeSubQuestionDeleteOption,
  handleSubQuestionAddOption,
  removeNewSubQuestionOption,
  removeOptionFromSubQuestion,
  emptyEditArrays,
  clearQuestionClient
} = questionManagementSlice.actions

export default questionManagementSlice.reducer
