import { isoToLocalDate } from 'utilTools/dateHelper'

// As the table requires a dropdown type ahead field for the assignee
// we format the assignee data to display the name of the assignee and
// pass any data needed for updating the assignee in the value property
const formatAssigneeData = assignee => {
  if (!assignee?.User) {
    // If there is no assignee, so we show the user that the interview is
    // unassigned
    return {
      label: 'Unassigned',
      value: {},
    }
  } else {
    // There is an assignee for the interview, format and return
    const { firstName, lastName, supervisorID, details } = assignee.User
    return {
      label: `${firstName} ${lastName}`,
      value: {
        id: assignee.id,
        firstName,
        lastName,
        supervisorID,
        officeID: details?.officeID,
        regionID: details?.regionID,
      },
    }
  }
}

// Format the interview data to display in the table
export const formatInterviewRecords = ({
  records = [],
  dispatch,
  countyId,
  isStatewide
}) => {
  if (records) {
    let hasExistingCase = false
    const interviews = records.map(record => {
      let reportLink = '#'
      const appraisal = record.Appraisal

      if (appraisal != null) {
        reportLink =
          appraisal.spanishReportLink !== ''
            ? appraisal?.spanishReportLink
            : appraisal?.reportLink
      }

      const inCounty = parseInt(record.Case?.county?.id) === countyId
      const sharingCounties =
        record.sharingCountyRecords?.map(sharingRecord => {
          return sharingRecord.countyID
        }) || []
      // Check if the interview is shared with the county that the user is in.

      if (
        (inCounty || sharingCounties.includes(countyId) || isStatewide) &&
        (record.currentStatus?.CVLType.description?.toLowerCase() === 'complete' || record.currentStatus?.CVLType.description?.toLowerCase() === 'cancelled')
      ) {
        hasExistingCase = true
      }

      const assignee = formatAssigneeData(record.Assignee)
      return {
        interviewId: record.id,
        sharingCounties,
        interfaceDate: isoToLocalDate(record.case?.interface?.createdAt),
        asrDate: record.appraisalDate?.effectiveDate
          ? isoToLocalDate(record.appraisalDate.effectiveDate)
          : '',
        county: record.Case?.county?.description,
        countyId: parseInt(record.Case?.county?.id),
        caseNumber: record.Case?.caseNumber,
        asrStatus: record.currentStatus?.CVLType.description,
        initiatedDate: record.initiatedDate,
        effectiveDate: record.currentStatus?.effectiveDate,
        interviewer: assignee.label,
        interviewerId: assignee.value.id,
        effectiveStartAndEndDates: record.effectiveStartAndEndDates,
        assigneeEditData: assignee,
        status: record.currentStatus,
        clientId: record.Client?.id,
        reportLink: reportLink,
        isInterfaceInterview: Boolean(record.interfaceInboundID),
      }
    })

    // dispatch(setHasExistingCase(hasExistingCase))
    return { rows: interviews, existingCase: hasExistingCase }
  }
}
