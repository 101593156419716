import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { colors } from 'app/theme'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: bottom;
  margin: 2px 10px 8px 2px;
`

const StyledSelect = styled(Select)`
  min-width: 200px;
  max-width: 500px;
  padding: 0px;
  :focus {
    border: 1px solid black;
  }
`

const customStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    border: isFocused
      ? `1px solid ${colors.saffron}`
      : `1px solid ${colors.white}`,
    borderBottom: isFocused
      ? `1px solid ${colors.saffron}`
      : '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: isFocused ? '4px' : '0px',
    boxShadow: isFocused ? 0 : 0,
    ':hover': {
      border: `1px solid ${colors.saffron}`,
      borderRadius: '4px',
    },
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  multiValue: (provided, { isFocused }) => ({
    ...provided,
    textTransform: 'capitalize',
    fontSize: '14px',
    backgroundColor: `none`,
    border: isFocused
      ? `1px solid ${colors.saffron}`
      : `1px solid ${colors.turquoise}`,
    ':hover': {
      border: `1px solid ${colors.saffron}`,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px 2px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.54)',
    ':hover': {
      color: `${colors.saffron}`,
    },
  }),
  multiValueRemove: provided => ({
    ...provided,
    border: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'none',
    ':hover': {
      backgroundColor: 'none',
      color: `${colors.saffron}`,
    },
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ':hover': {
      outline: `1px solid ${colors.saffron}`,
    },
    ':active': {
      outline: `1px solid ${colors.saffron}`,
    },
    backgroundColor: 'rgba(255, 255, 255, 1)',
    outline: isFocused ? `1px solid ${colors.saffron}` : null,
  }),
}

const createOptionsAndSelections = (
  menuItems,
  selectedValues,
  displayField,
  valueField
) => {
  // in order not to break existing uses that are not the client list
  // we preserve the behavior when passed an object instead of an array
  const options = Array.isArray(menuItems)
    ? menuItems.map(item => {
        const label = displayField ? item[displayField] : item
        const value = valueField ? item[valueField] : item

        return {
          label,
          value,
          name: value,
          key: value,
        }
      })
    : Object.keys(menuItems).map(item => {
        return {
          label: item,
          name: item,
          value: item,
          key: item,
        }
      })

  // another place where we need to handle the data differently based on the
  // type of incoming data.
  const selections = selectedValues
    ? selectedValues.map(value => options.find(opt => opt.value === value))
    : Object.keys(menuItems).filter(key => menuItems[key])
  return { options, selections }
}

const MultiSelectDropdown = ({
  portalId,
  menuItems,
  selectedValues,
  handleChange,
  displayField,
  valueField,
}) => {
  const { options, selections } = createOptionsAndSelections(
    menuItems,
    selectedValues,
    displayField,
    valueField
  )

  // Handle actions from multi select
  const handleSelect = (selection, { removedValue, action, option }) => {
    if (action === 'remove-value' || action === 'pop-value') {
      handleChange && handleChange(removedValue.value, false)
    } else {
      handleChange && handleChange(option.value, true)
    }
  }
  return (
    <Container>
      <StyledSelect
        options={options}
        styles={customStyles}
        onChange={handleSelect}
        value={selections}
        isMulti
        menuPortalTarget={document.getElementById(portalId)}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  )
}

export default MultiSelectDropdown
