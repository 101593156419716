import EffectCollector from 'side-effects/effectCollector'
import store from 'app/store'

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()

  const learningProblemQuestions = [
    { id: 'problems_learning_middle_school', score: 1 },
    { id: 'difficult_working_with_test_booklet', score: 1 },
    { id: 'difficulty_working_with_numbers_', score: 1 },
    { id: 'trouble_judging_distances', score: 1 },
    { id: 'family_members_learning_problems', score: 1 },
    { id: 'problems_learning_elementary_school', score: 2 },
    { id: 'difficulty_mixing_mathematical_signs', score: 2 },
    { id: 'difficulty_filling_out_forms', score: 3 },
    { id: 'difficulty_memorizing_numbers', score: 3 },
    { id: 'difficulty_remembering_spelling', score: 3 },
    { id: 'problems_taking_notes', score: 4 },
    { id: 'trouble_adding_subtracking_numbers', score: 4 },
    { id: 'special_program_extra_help', score: 4 },
  ]
  // Calculate the total score if questionid is in learningProblemQuestions array
  if (learningProblemQuestions.some(item => question_id === item.id)) {
    const question = learningProblemQuestions.find(
      item => question_id === item.id
    )

    const stateProp = isPreview ? 'preview' : 'interview'

    const previousAnswer = store.getState()[stateProp][question.id].answer
    const score =
      parseInt(store.getState()[stateProp]['total_score'].answer) || 0
    let newScore
    if (answer?.toUpperCase() === 'YS') {
      newScore = score + question.score
    } else if (
      (answer === null || answer?.toUpperCase() === 'NO') &&
      previousAnswer === 'YS'
    ) {
      // Subtract only when current answer is 'NO' or null (indeterminate)
      // and previous answer is 'YS'
      newScore = score - question.score
    } else {
      newScore = score
    }
    // Convert newScore to string in case the value of newScore is 0 (falsy)
    effectCollector.add('total_score', newScore.toString())
  }
  // Nullify learning problem questions if "limited-english" is selected
  if (question_id === 'client_limited_english' && answer) {
    learningProblemQuestions.forEach(item => effectCollector.add(item.id, null))
    effectCollector.add('total_score', 0)
  }

  if (
    question_id === 'diagnosed_with_learning_disability' &&
    !(answer?.toUpperCase() === 'YS')
  ) {
    effectCollector.add('diagnosed_learning_by_whom', null)
    effectCollector.add('diagnosed_learning_when', null)
  }

  if (
    question_id === 'diagnosed_with_adhd' &&
    !(answer?.toUpperCase() === 'YS')
  ) {
    effectCollector.add('diagnosed_adhd_by_whom', null)
    effectCollector.add('diagnosed_adhd_when', null)
  }

  if (
    question_id === 'taking_medications_affect_actions_feelings' &&
    answer?.toUpperCase() === 'NO'
  ) {
    effectCollector.add('medications_table', null)
  }
  return effectCollector.effects
}

export default effects;