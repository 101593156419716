import { batch } from 'react-redux'
import { clearInterviewData } from 'ducks/interviewSlice'
import { setInterview } from 'ducks/clientSlice'
import { setLastSaved } from 'ducks/lastSavedSlice'
import {
  setInterviewNavigation,
  setCurrentlyExpandedCategoryCode,
} from 'ducks/navigationSlice'
import { clearInterviewProgress } from 'ducks/interviewProgressSlice'
import { clearUnsavedChangesSlice } from 'ducks/unsavedChangesSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import { clearCurrentInterviewPage } from 'ducks/currentInterviewPageSlice'
import { clearNotes } from 'ducks/noteSlice'

export const resetInterviewData = () => {
  return async (dispatch, getState) => {
    batch(() => {
      // Clear Interview Current Page
      dispatch(clearCurrentInterviewPage())
      // Clear Interview Data
      dispatch(clearInterviewData())
      // Clear User Interview
      dispatch(setInterview(null))
      // Clear Last Saved State
      dispatch(setLastSaved(null))
      // Clear Navigation State
      dispatch(setInterviewNavigation([]))
      // Reset the interview navigation sidebar
      dispatch(setCurrentlyExpandedCategoryCode('questionnaire'))
      // Clear Interview Progress State
      dispatch(clearInterviewProgress())
      // Clear Unsaved Changes State
      dispatch(clearUnsavedChangesSlice())
      // Clear Validation Errors State
      dispatch(removeAllValidationErrors())
      // Clear Interview Notes
      dispatch(clearNotes())
    })
  }
}
