import gql from 'graphql-tag'
const queries = {
  GET_SECTION_MEMBERS: gql`
    query User($id: Int!) {
      getSectionMembers(supervisorID: $id) {
        id
        firstName
        lastName
      }
    }
  `,
  IS_DUP_EMAIL: gql`
    query Boolean($email: String!, $id: Int) {
      isDuplicateEmail(email: $email, id: $id)
    }
  `,
  SUPERVISORS_IN_COUNTY: gql`
    query User($id: String!) {
      supervisorByCounty(orgLevelCode: $id) {
        id
        firstName
        lastName
      }
    }
  `,
  GET_COUNTIES: gql`
    query OrganizationLevelType($id: String!) {
      getCountyByOrgLevelCode(orgLevelTypeCode: $id) {
        id
        code
        value
      }
    }
  `,
  GET_REGIONS: gql`
    query Regions($id: String!) {
      getRegionsByOrganizationLevelID(orgLevelCode: $id) {
        id
        name
      }
    }
  `,
  GET_OFFICES_IN_DIFFERENT_REGIONS: gql`
    query Offices($id: [Int!]) {
      getOfficesByRegionIDs(regionIDs: $id) {
        id
        name
      }
    }
  `,
  GET_OFFICES: gql`
    query Offices($id: Int!) {
      getOfficesByRegionID(regionID: $id) {
        id
        name
      }
    }
  `,
  USERS_BY_COUNTY: gql`
    query usersByCounty($orgLevelCode: String!) {
      usersByCounty(orgLevelCode: $orgLevelCode) {
        id
        firstName
        lastName
      }
    }
  `,
  SEARCH_FOR_USERS: gql`
    query Users(
      $firstName: String
      $lastName: String
      $organization: String
      $role: [String]
      $email: String
      $region: [Int]
      $office: [Int]
    ) {
      manageUsersQuery(
        firstName: $firstName
        lastName: $lastName
        county: $organization
        roles: $role
        email: $email
        regions: $region
        offices: $office
      ) {
        firstName
        lastName
        email
        details {
          id
          regionID
          officeID
          dataExtract
        }
        role {
          roleDetails {
            value
            code
          }
          effectiveStartDate
        }
        supervisor {
          id
          firstName
          lastName
        }
        isDisabled
        disabledDate
        id
        orgLevelID {
          code
        }
      }
    }
  `,
}

export default queries;