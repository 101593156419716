import React from 'react'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Grid from '@material-ui/core/Grid'
import Dropdown from 'components/general/Dropdown'

const questionIds = ['gender_identity', 'sexual_orientation', 'birth_gender']

const DemographicsGenderIdentity = React.memo(({ isPreview }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Gender Identity'
          domain='Demographics'
        />
      </Grid>
      <Grid item xs={12}>
        <form>
          {questionIds.map(id => (
            <Dropdown
              key={id}
              question_id={id}
              width={'320px'}
              sawsIndicator={true}
              isPreview={isPreview}
            ></Dropdown>
          ))}
        </form>
      </Grid>
    </Grid>
  )
})

export default DemographicsGenderIdentity
