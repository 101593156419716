const hasSpecialCharacter = value => {
  return /[&/\\#,+()$~%^":*?<>{}]/g.test(value)
}

const hasInvalidOfficeRegionCharacter = value => {
  return /[!@&/\\,+$~%^":*?<>{}_]/g.test(value)
}

const hasNonDateCharacters = value => {
  const replaced = value.replaceAll(/[0-9/]/g, '')
  return replaced.length !== 0
}

const getValidationCallBack = valueType => {
  let callback
  switch (valueType) {
    case 'date':
      callback = hasNonDateCharacters
      break
    case 'text':
    default:
      callback = hasSpecialCharacter
      break
  }
  return callback
}

const validator = {
  getValidationCallBack,
  hasInvalidOfficeRegionCharacter,
  hasNonDateCharacters,
  hasSpecialCharacter,
}

export default validator
