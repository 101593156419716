import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Error = styled.div`
  flex: 1 0 100%;
  display: block;
  padding-bottom: 16px;
`

const ErrorTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`

export const ValidationDialogContent = ({ errors }) => {
  const errorElements = errors.map(err => {
    return (
      <Error key={err.id}>
        <ErrorTitle variant='body1'>{err.field}</ErrorTitle>
        <Typography variant='body2' color='error'>
          {err.value}
        </Typography>
      </Error>
    )
  })
  return <div>{errorElements}</div>
}

export const ValidationDialogActions = () => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }

  return (
    <>
      <Button onClick={handleClose} color='primary'>
        Close
      </Button>
    </>
  )
}
