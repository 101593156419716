import EffectCollector from 'side-effects/effectCollector'
import { qIdsI, qIdsII } from 'components/interview/ReasonsNotWorking'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'did_not_provide_specific_reason' &&
    answer &&
    Array.isArray(answer) &&
    answer?.map(option => option.toUpperCase()).includes('NR')
  ) {
    qIdsI.forEach(id => effectCollector.add(id, null))
    qIdsII.forEach(id => effectCollector.add(id, null))
    effectCollector.add('specify_reason_not_working', null)
  }

  if (
    question_id === 'other_job_end' &&
    answer &&
    Array.isArray(answer) &&
    !answer?.map(option => option.toUpperCase()).includes('OT')
  ) {
    effectCollector.add('specify_reason_not_working', null)
  }

  if (
    question_id === 'currently_working' &&
    (answer?.toUpperCase() === 'YS' ||
      answer?.toUpperCase() === 'SL' ||
      answer?.toUpperCase() === 'SE')
  ) {
    effectCollector.add('did_not_provide_specific_reason', null)
    qIdsI.forEach(id => effectCollector.add(id, null))
    qIdsII.forEach(id => effectCollector.add(id, null))
    effectCollector.add('specify_reason_not_working', null)
  }
  return effectCollector.effects
}

export default effects;