import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import { colors } from 'app/theme'
import { withStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import { Grid, Typography, ListSubheader } from '@material-ui/core'
import Link from '@material-ui/core/Link'
// import Can from 'app/Can'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog, closeDialog } from 'ducks/dialogSlice'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'

const StyledHeader = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-top: 0px;
  }
`

const StyledContainer = styled.div`
  .back-to-all-years {
    padding-left: 15px;
    text-decoration: underline;
  }

  .MuiList-subheader.extract-list {
    margin-top: 5px;
    padding-bottom:0;
  }

  .MuiListSubheader-root {
    font-size: 16px;
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > .MuiListItemText-primary {
      color: ${p => p.active && colors.turquoise};
    }
    & > .MuiListItemText-secondary {
      color: ${p => p.active && colors.grey};
      font-style: normal;
      padding-top: 0;
    }
  }
`

const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
    font-weight: 400;
  }
`

const StyledOkButton = styled(Button)`
  && {
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`

const StyledCancel = styled(Button)`
  && {
    background-color: ${colors.turquoise};
    color: ${colors.white};
  }
`

const StyledLinkButton = styled.a`
  background-color: ${colors.calsawsBlue};
  color: ${colors.white};
  height: 50px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
`

const DialogContainer = styled.div`
  margin: 10px 0px;
`

const StyledFormLabel = styled(FormLabel)`
  && {
    color: ${colors.black};
  }
`

const StyledListItem = withStyles({
  root: {
    paddingLeft: '16px',
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
    '&.extract-item': {
      paddingTop: '0',
    },
  },
  selected: {},
})(ListItem)

export const GET_DATA_EXTRACT_YEARS = gql`
  {
    dataExtractGroups
  }
`

export const GET_DATA_EXTRACT_MONTHS = gql`
  query dataExtractGroups($year: String!) {
    dataExtractGroups(year: $year)
  }
`

export const GET_DATA_EXTRACT_TABLE = gql`
  query dataExtracts($year: String!, $month: String!) {
    dataExtracts(year: $year, month: $month)
  }
`

export const GET_DATA_EXTRACT_ARCHIVE = gql`
query downloadExtractsArchive($year: String!, $month: String!, $files: [String!]!) {
  downloadExtractsArchive(year:$year, month: $month, files: $files)
}
`

// https://devviz.anadev.aws.calsaws.net/calaces-adfs/hub
export const GET_REPORT_LINK_QLIK = gql`
  query getReportLinkQlik($reportName: String!) {
    getReportLinkQlik(reportName: $reportName)
  }
`
const goToQlikResource = async (reportName, dispatch) => {
  try {
    const result = await client.mutate({
      mutation: GET_REPORT_LINK_QLIK,
      variables: {
        reportName: reportName,
      },
    })

    if (!result || !result.data || !result.data.getReportLinkQlik) {
      throw new Error()
    }

    window.open(result.data.getReportLinkQlik, '_blank')
    dispatch(closeDialog())
  } catch (error) {
    // this function is declared in the index file for application monitoring
    // eslint-disable-next-line no-undef
    ineum('reportError', error)
    dispatch(
      openDialog({
        type: 'error',
        title: 'System Error',
        props: {
          error,
        },
      })
    )
  }
}

const FileDownloadDialog = ({ downloadLink, onCloseDialog }) => {

  return (
    <>
      <Dialog aria-labelledby='Download Data Extracts' open={downloadLink !== ''}>
        <DialogTitle id='dlg-title'>
          Data Extract is Ready to Download
        </DialogTitle>
        <DialogContent>
          <StyledLinkButton target="_blank" href={downloadLink}>Download</StyledLinkButton>
        </DialogContent>
        <DialogActions>
          <StyledOkButton onClick={onCloseDialog} data-testid={`Report-data-extracts-download-dialog-close`}>
                Close
          </StyledOkButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DataExtractDialog = props => {
  const { open, onClose, generate, generating, table } = props
  const [checked, setChecked] = useState([])

  const handleClose = () => {
    onClose(false)
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleContinue = () => {
    generate(checked)
    setChecked([])
  }

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby='Download Data Extracts' open={open}>
        <DialogTitle id='dlg-title'>
          {!generating ? 'Select the tables you want to download' : 'Fetching Data'}
        </DialogTitle>
        <DialogContent>
          {!generating && (
            <>
              <FormControl component='fieldset'>
                <StyledFormLabel component='legend'>
                  Please select from the following options.
                </StyledFormLabel>
                <DialogContainer>
                  <FormGroup>
                    {
                      table.map((value) => {
                        const labelId = `data-extracts-list-item-${value}-label`

                        return (
                          <React.Fragment key={value}>
                            <FormControlLabel
                              control={<Checkbox checked={checked.indexOf(value) !== -1} onChange={handleToggle(value)} name={labelId} data-testid={labelId} />}
                              label={value}
                            />
                          </React.Fragment>
                        )
                      })}
                  </FormGroup>
                </DialogContainer>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!generating ? (
            <>
              <StyledCancel onClick={() => handleClose()} data-testid={`Report-data-extracts-dialog-cancel`}>Cancel</StyledCancel>
              <StyledOkButton onClick={() => handleContinue()} data-testid={`Report-data-extracts-dialog-continue`}>
                Continue
              </StyledOkButton>
            </>
          ) : (
              <>
              </>
            )}
        </DialogActions>
      </Dialog>
    </>
  )
}

const DataExtractMonths = (props) => {

  const { isStatewide } = useSelector(state => state.user)

  const monthName = (monthNumber) => {
    switch (monthNumber) {
      case "1":
        return "January"
      case "2":
        return "February"
      case "3":
        return "March"
      case "4":
        return "April"
      case "5":
        return "May"
      case "6":
        return "June"
      case "7":
        return "July"
      case "8":
        return "August"
      case "9":
        return "September"
      case "10":
        return "October"
      case "11":
        return "November"
      case "12":
        return "December"
      default:
        return monthNumber
    }
  }

  const quarterName = (monthNumber) => {
    switch (monthNumber) {
      case "1":
      case "2":
      case "3":
        return "Quarter 1 -"
      case "4":
      case "5":
      case "6":
        return "Quarter 2 -"
      case "7":
      case "8":
      case "9":
        return "Quarter 3 -"
      case "10":
      case "11":
      case "12":
        return "Quarter 4 -"
      default:
        return monthNumber
    }
  }

  const buildExtractElement = (element, index, handleClick) => {
    return (
      <StyledListItem key={index}>
        <StyledListItemText
          onClick={() => handleClick(element)}
          primary={
            <React.Fragment>
              <StyledLink data-testid={`Report-quarter-or-month-${element}`}>
                { isStatewide ? quarterName(element) : monthName(element)} {props.selectedYear}
              </StyledLink>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
            </React.Fragment>
          }
        />
      </StyledListItem>
    )
  }

  const handleClick = (month) => {
    props.handleMonthClick(month)
  }

  return (
    <React.Fragment>
      {props.showMonths ? (
        <StyledContainer>
          {props.monthsLoading ? (
            <List>
              <StyledListItem>
                <ListItemText>{isStatewide ? 'Loading quarters...' : 'Loading months...'}</ListItemText>
              </StyledListItem>
            </List>
          )
            : (
              <List>
                {props.months && props.months.length > 0 ? props.months.map((v, i) => {
                  return buildExtractElement(v, i, handleClick)
                })
                  : (
                    <StyledListItem>
                      <ListItemText>No extract data found for {props.selectedYear}</ListItemText>
                    </StyledListItem>
                  )}
              </List>)}
        </StyledContainer>
      ) : (<></>)}
    </React.Fragment>
  )
}

const DataExtractYears = (props) => {
  const buildExtractElement = (element, index, handleClick) => {
    return (
      <StyledListItem 
        key={index} 
        onClick={() => handleClick(element)}
        data-testid={`Report-year-${element}`}
      >
        <StyledListItemText
          primary={
            <React.Fragment>
              <StyledLink
              >
                {element}
              </StyledLink>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
            </React.Fragment>
          }
        />
      </StyledListItem>
    )
  }

  const handleClick = (year) => {
    props.handleYearClick(year)
  }

  return (
    <React.Fragment>
      {props.showYears ? (
        <StyledContainer>
          {props.yearsLoading ? (
            <List>
              <StyledListItem>
                <ListItemText>Loading Data Extract information...</ListItemText>
              </StyledListItem>
            </List>
          )
            : (
              <List>
                {props.showYears && props.years && props.years.length > 0 ? props.years.map((v, i) => {
                  return buildExtractElement(v, i, handleClick)
                })
                  : (
                    <StyledListItem>
                      <ListItemText>No extract data found</ListItemText>
                    </StyledListItem>
                  )}
              </List>
            )}
        </StyledContainer>
      ) : (<></>)}
    </React.Fragment>
  )
}

const DataExtracts = () => {
  const dispatch = useDispatch()
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([])
  const [table, setTable] = useState([])
  const [showYears, setShowYears] = useState(true)
  const [selectedYear, setSelectedYear] = useState()
  const [selectedMonth, setSelectedMonth] = useState()
  const [showMonths, setShowMonths] = useState(false)
  const [open, setOpen] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')

  const { loading: yearsLoading, data: yearsData, error } = useQuery(GET_DATA_EXTRACT_YEARS, {
    fetchPolicy: 'network-only',
  })

  const [queryMonths, { loading: monthsLoading, data: monthData, error: monthError }] = useLazyQuery(
    GET_DATA_EXTRACT_MONTHS,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [queryTables, { data: tableData, error: tableError }] = useLazyQuery(
    GET_DATA_EXTRACT_TABLE,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [queryArchive, { data: archiveData, error: archiveError }] = useLazyQuery(
    GET_DATA_EXTRACT_ARCHIVE,
    {
      fetchPolicy: 'network-only',
    }
  )

    const downloadDialogClose = () => {
      setDownloadLink('')
    }

  // EFFECT: When Data Extract years data is retrieved
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
    yearsData && setYears(yearsData.dataExtractGroups)
  }, [yearsData, error, dispatch])

  // EFFECT: When Data Extract month data is retrieved for a given year
  useEffect(() => {
    if (monthError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            monthError,
          },
        })
      )
    }
    if (monthData) {
      function compareNumbers(a, b) {
        return a - b;
      }
      const numberArray = monthData.dataExtractGroups.map(n => parseInt(n))
      numberArray.sort(compareNumbers)
      const stringArray = numberArray.map(s => s.toString())
      monthData && setMonths(stringArray)
    }
  }, [monthData, monthError, dispatch])

  // EFFECT: When Data Extract table data is retrieved for a given year and month
  useEffect(() => {
    if (tableError) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            tableError,
          },
        })
      )
    }

    if (tableData) {
      setTable(tableData.dataExtracts)
      handleOpen(true)
    }
  }, [tableData, tableError, dispatch])

  // EFFECT: When Data Extract archive link data is retrieved
  useEffect(() => {
    if (archiveError) {
      handleOpen(false)
      setGenerating(false)

      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            archiveError,
          },
        })
      )
    }

    if (archiveData) {
      handleDownload(archiveData.downloadExtractsArchive)
    }
  }, [archiveData, archiveError, dispatch])

  // EVENT: When a Data Extract year is selected
  const handleYearClick = (year) => {
    setShowYears(false)
    setShowMonths(true)
    setSelectedYear(year)
    queryMonths({
      variables: { year: year }
    })
  }

  // EVENT: When a Data Extract month is selected
  const handleMonthClick = (month) => {
    setSelectedMonth(month)
    queryTables({
      variables: { year: selectedYear, month: month }
    })
  }

  // EVENT: When we want to open the modal to select bits for archive
  const handleOpen = value => {
    // setGenerating(false)
    setOpen(value)
  }

  // EVENT: When the user has selected bits for archive and has requested the URI
  const handleGenerate = checked => {
    setGenerating(true)
    queryArchive({
      variables: { year: selectedYear, month: selectedMonth, files: checked }
    })
  }

  // EVENT: When we've received the Data Extracts archive URI and want to download it
  const handleDownload = uri => {
    // If we don't give AWS a few we get an "Key not found" error
    //setTimeout(() => {
      
      setDownloadLink(uri)
      setGenerating(false)
      setOpen(false)
    //}, 1500)
  }

  // EVENT: When the user wants to navigate back to the list of Data Extract years
  const handleBackToYears = (event) => {
    event.preventDefault()
    setShowMonths(false)
    setShowYears(true)
  }

  return (
    <React.Fragment>
      <List
        subheader={
          <ListSubheader disableSticky={true}>
            System Database Extract
            </ListSubheader>
        }
        className='extract-list'
      >
      </List>

      {showMonths && (<Link href="#" data-testid={`Report-link-back-to-years`} onClick={handleBackToYears} className='back-to-all-years'>Back to All Years</Link>)}

      <DataExtractYears yearsLoading={yearsLoading} years={years} showYears={showYears} handleYearClick={handleYearClick} />
      <DataExtractMonths monthsLoading={monthsLoading} months={months} showMonths={showMonths} selectedYear={selectedYear} handleMonthClick={handleMonthClick} />
      <DataExtractDialog
        open={open}
        table={table}
        generating={generating}
        onClose={handleOpen}
        generate={handleGenerate}
      />
      <FileDownloadDialog downloadLink={downloadLink} onCloseDialog={downloadDialogClose} />

    </React.Fragment>
  )
}

const Report = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <StyledHeader variant='h4'>Reports</StyledHeader>
      <Typography variant='body1'>
        Click on the link below to view OCAT Reports
      </Typography>

      <Grid container spacing={2} data-testid={`Report-container`}>
        <Grid item xs={5}>
          <StyledContainer>
            <List>
              <StyledListItem tabIndex='0'>
                <StyledListItemText
                  primary={
                    <React.Fragment>
                      <StyledLink
                        data-testid={`Report-qlik-resource`}
                        onClick={() =>
                          goToQlikResource(
                            'OCAT Reports',
                            dispatch
                          )
                        }
                      >
                        OCAT Reports
                      </StyledLink>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                    </React.Fragment>
                  }
                />
              </StyledListItem>
            </List>

            {user.dataExtract === 1 && (
              <DataExtracts />
            )}
          </StyledContainer>
        </Grid>
        <Grid item xs={7}>
          <StyledContainer>
          </StyledContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Report
