import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import { clearUnsavedNote } from 'ducks/unsavedNotesSlice'
import { getLastSavedDate } from 'thunks/getLastSavedDate'
import { setLoadingTimestamp } from 'ducks/lastSavedSlice'

const HANDLE_NOTE = gql`
  mutation upsertInterviewNotes(
    $interviewId: Int
    $notes: [InterviewNoteInput]
  ) {
    upsertInterviewNotes(interviewId: $interviewId, notes: $notes) {
      subdomain
      Notes
    }
  }
`

export const saveNote = () => {
  return async (dispatch, getState) => {
    try {
      setLoadingTimestamp(true)
      const notes = getState().notes
      const currentInterviewId = getState().client?.currentInterview
      await client.mutate({
        mutation: HANDLE_NOTE,
        variables: {
          interviewId: currentInterviewId,
          notes: Object.values(notes),
        },
      })
      dispatch(clearUnsavedNote())
      // // Get the last saved date and set it.
      dispatch(getLastSavedDate())

      return true
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)      
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      return false
    }
  }
}
