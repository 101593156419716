import React from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const issuesSelector = state =>
  state.interview.relationship_partner_issues.answer

const previewIssuesSelector = state =>
  state.preview.relationship_partner_issues.answer

const selectIssues = createSelector(
  issuesSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)

const selectPreviewIssues = createSelector(
  previewIssuesSelector,
  answer => !answer || answer.toUpperCase() === 'YS'
)

const RelationshipIndicator = React.memo(({ isPreview }) => {
  const indicatorSelected = useSelector(isPreview ? selectPreviewIssues : selectIssues)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Relationships'
        />
        <form>
          <Select question_id='relationship_partner_issues' isPreview={isPreview} />
        </form>
        <SkipButton isDisabled={indicatorSelected}></SkipButton>
      </Grid>
    </Grid>
  )
})
export default RelationshipIndicator
