import { batch } from 'react-redux'
import {
  updateClient,
  setInterview,
  setInterviewStatus,
} from 'ducks/clientSlice'
import { openDialog } from 'ducks/dialogSlice'
import {
  getInterviewQuestionsAndAnswers,
  getInterviewProgress,
  getAssociatedClientInterviews,
} from 'thunks/retrieveInterviewData'
import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import {
  setInterviewLoading,
  clearNewInterviewSlice,
} from 'ducks/newInterviewSlice'
import { getLastSavedDate } from 'thunks/getLastSavedDate'

const CREATE_NEW_APPRAISAL = gql`
  mutation(
    $clientID: Int!
    $caseNumber: String
    $caseID: Int
    $isNewCaseNumber: Boolean!
    $caseCounty: Int
    $userCountyID: Int
    $prepopulateInterview: Boolean!
  ) {
    createNewAppraisal(
      clientID: $clientID
      caseNumber: $caseNumber
      caseID: $caseID
      isNewCaseNumber: $isNewCaseNumber
      caseCounty: $caseCounty
      userCountyID: $userCountyID
      prepopulateInterview: $prepopulateInterview
    ) {
      id
      effectiveDate
      effectiveEndDate
      interviewStatus
    }
  }
`

export const createNewAppraisal = ({
  history,
  caseID,
  caseNumber,
  caseCounty = null,
  isNewCaseNumber = false,
  prepopulateInterview,
}) => {
  return async (dispatch, getState) => {
    try {
      const clientID = getState().client.currentClient
      const { countyId: userCountyID } = getState().user
      const { canCreateInterview } = getState().newInterview

      if (!canCreateInterview) return
      dispatch(setInterviewLoading(true))
      const newInterview = await client.mutate({
        mutation: CREATE_NEW_APPRAISAL,
        variables: {
          clientID,
          caseNumber,
          caseID,
          isNewCaseNumber,
          caseCounty,
          userCountyID,
          prepopulateInterview:
            prepopulateInterview === 'populate' ? true : false,
        },
      })

      batch(() => {
        dispatch(clearNewInterviewSlice())
        const {
          id,
          effectiveDate,
          effectiveEndDate,
          interviewStatus,
        } = newInterview.data.createNewAppraisal
        dispatch(setInterview(id))
        dispatch(setInterviewStatus(interviewStatus.toLowerCase()))
        dispatch(
          updateClient({
            startDate: effectiveDate,
            endDate: effectiveEndDate,
            caseNumber,
          })
        )
        dispatch(getInterviewQuestionsAndAnswers(id))
        dispatch(getInterviewProgress(id))
        dispatch(getAssociatedClientInterviews(id))
        dispatch(getLastSavedDate())
      })
      history.push('/interview/demographics_general_information')
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
      dispatch(clearNewInterviewSlice())
    }
  }
}
