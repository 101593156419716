import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import Typography from '@material-ui/core/Typography'

import AdminSearchTable from 'components/admin/AdminSearchTable'

import { formatUsersForTable } from 'components/admin/AdminHelperFunctions'
import { openDialog } from 'ducks/dialogSlice'

const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const CreateUser = styled.span`
  float: right;
  font-size: 14px;
  font-weight: 400;
`

const AdminManageSearchResults = ({ loading, data, error }) => {
  const dispatch = useDispatch()

  const [inputFilters, setInputFilters] = useState({})

  const filterByTextInputs = row => {
    // If there are no input values return true
    if (isEmpty(inputFilters)) return true
    let shouldReturn = true
    // Check each filter to see if a particular row doesn't match
    // the input value for this column
    for (const filter in inputFilters) {
      const filterValueForColumn = inputFilters[filter]?.toLowerCase()
      const columnValue = row[filter]?.toLowerCase()
      // Check if row value for this column doesn't contain the filter value
      if (columnValue && !columnValue.includes(filterValueForColumn)) {
        // If it does not, filter out this row
        return false
      }
    }
    return shouldReturn
  }

  const handleInputFilters = (row, text) => {
    const newInputFilters = { ...inputFilters }
    newInputFilters[row] = text
    setInputFilters(newInputFilters)
  }

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  let noResults = null
  let resultsTable = null
  let resultCount = null

  const shouldShowResultsOrFeedback = !loading && data
  if (shouldShowResultsOrFeedback) {
    resultCount = data.length
    if (!resultCount) {
      noResults = <h4>No records were found which match all the filters.</h4>
    } else {
      const tableData = formatUsersForTable(data)
      resultCount = tableData.length
      const displayData = tableData.filter(record => filterByTextInputs(record))
      resultsTable = (
        <AdminSearchTable
          tableData={displayData}
          handleInputFilters={handleInputFilters}
        />
      )
    }
  }

  return (
    <Container>
      {loading ? (
        <Typography variant='h6'>Fetching results...</Typography>
      ) : null}

      {!loading && data ? (
        <Typography variant='h6'>
          Search Results ({resultCount || '0'})
          <CreateUser>
            <em style={{ marginRight: '13px' }}>User not found?</em>
            <Link to='/administration_create'>Create New User</Link>
          </CreateUser>
        </Typography>
      ) : null}

      {noResults}
      {resultsTable}
    </Container>
  )
}

export default AdminManageSearchResults
