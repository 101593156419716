import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { setFileDownloadAuthCookie } from 'utilTools/fileDownloadHelper'
import { colors } from 'app/theme'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import PrintIcon from '@material-ui/icons/Print'
import CircularProgress from '@material-ui/core/CircularProgress'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'

const StyledIconButton = styled(IconButton)`
  color: ${colors.calsawsBlue};
  :hover {
    color: ${colors.saffron};
  }
  padding-bottom: 15px;
  margin: 0;
`

const GET_LINK = gql`
  query($interviewID: Int!) {
    getInterviewAsrLinks(interviewId: $interviewID) {
      reportLink
      spanishReportLink
    }
  }
`

const RetrieveAsrPdfButton = ({ interviewId }) => {
  const dispatch = useDispatch()
  const [clickCount, setClickCount] = useState(0)
  const [doFetchAsrLinks, { loading, data, error }] = useLazyQuery(GET_LINK)

  const handleDownloadClick = () => {
    setClickCount(clickCount + 1)
    doFetchAsrLinks({
      variables: {
        interviewID: interviewId,
      },
    })
  }

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
    // If an ASR is generated, an english version (reportLink) is
    // always generated. If a spanish ASR was selected to be generated,
    // display that version.
    if (!loading && data?.getInterviewAsrLinks?.reportLink) {
      const { reportLink, spanishReportLink } = data.getInterviewAsrLinks
      const link = spanishReportLink ? spanishReportLink : reportLink
      setFileDownloadAuthCookie()
      window.open(link, '_blank')
    }
  }, [loading, data, error, clickCount, dispatch])

  return (
    <>
      <StyledIconButton
        color='secondary'
        variant='contained'
        aria-label='appraisal summary button'
        onClick={handleDownloadClick}
      >
        {loading ? <CircularProgress /> : <PrintIcon />}
      </StyledIconButton>
    </>
  )
}

export default RetrieveAsrPdfButton
