import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const colors = {
  calsawsBlue: '#1a3360',
  turquoise: '#00758f',
  saffron: '#ffa600',
  black: '#000',
  white: '#fff',
  lightgrey: '#d1d1d1',
  red: '#e3022f',
  grey: '#736c6c',
  darkgrey: '#4a4335',
  blue: '#0865ce',
  warning: '#f2d883'
}

let theme = createTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle2: 'div',
      },
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        color: colors.calsawsBlue,
        fontSize: '31px',
        fontWeight: '400',
        paddingTop: '10px',
      },
      h4: {
        color: colors.turquoise,
        fontSize: '20px',
        fontWeight: '400',
        paddingTop: '40px',
      },
      h5: {
        color: colors.turquoise,
        fontSize: '18px',
        fontWeight: '400',
        paddingTop: '40px',
      },
      h6: {
        color: colors.turquoise,
        fontSize: '16px',
        fontWeight: '700',
        paddingTop: '20px',
      },
      subtitle1: {
        color: colors.black,
        fontSize: '14px',
        paddingTop: '10px',
      },
      subtitle2: {
        color: colors.black,
        fontSize: '12px',
        fontWeight: '500',
        paddingTop: '20px',
      },
      body1: {
        color: colors.black,
        fontSize: '16px',
        fontWeight: '400',
        paddingTop: '5px',
      },
      body2: {
        color: colors.black,
        fontStyle: 'italic',
        fontSize: '16px',
        fontWeight: '400',
        paddingTop: '10px',
      },
      button: {
        color: colors.black,
        fontSize: '14px',
        marginTop: '40px',
      },
    },
    MuiFormControlLabel: {
      label: {
        marginTop: '5px',
        paddingTop: '0px',
        paddingBottom: '0px',
        lineHeight: '1',
      },
    },
    MuiCheckbox: {
      root: {
        opacity: 1,
        boxShadow: 'none !important',
        marginTop: '5px',
        paddingTop: '0px',
        paddingBottom: '1px',
        marginRight: '-3px',
        lineHeight: '1',
        '&$disabled': {
          color: colors.grey,
        },
      },
      colorSecondary: {
        color: colors.darkgrey,
        '&$checked': {
          color: colors.saffron,
        },
      },
    },
    MuiRadio: {
      root: {
        marginTop: '5px',
        paddingTop: '0px',
        paddingBottom: '0px',
        lineHeight: '1',
        '&$disabled': {
          color: colors.grey,
        },
      },
      colorSecondary: {
        color: colors.darkgrey,
        '&$checked': {
          color: colors.saffron,
        },
      },
    },

    MuiButton: {
      outlined: {
        border: `solid 1px ${colors.grey}`,
        backgroundColor: colors.white,
        '&$disabled': {
          color: colors.grey,
        },
        '&:hover': {
          color: colors.darkgrey,
          backgroundColor: colors.saffron,
          border: colors.saffron,
        },
      },
      contained: {
        '&&:hover': {
          color: colors.darkgrey,
          backgroundColor: colors.saffron,
          border: colors.saffron,
        },
      },
    },
    MuiListItem: {
      root: {
        cursor: 'pointer',
        color: colors.grey,
        fontSize: '16px',
        fontWeight: '700',
        paddingTop: '10px',
      },
    },
    MuiListItemText: {
      primary: {
        cursor: 'pointer',
        color: colors.grey,
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        paddingTop: '10px',
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: colors.white,
          outline: `1px solid ${colors.saffron}`,
        },
        '&:focus': {
          backgroundColor: colors.white,
          outline: `1px solid ${colors.saffron}`,
        },

        '&$selected': {
          backgroundColor: colors.white,
          outline: `1px solid ${colors.saffron}`,
          '&:hover': {
            backgroundColor: colors.white,
          },
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: colors.calsawsBlue,
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: '700',
        paddingTop: '10px',
      },
    },

    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: colors.darkgrey,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: colors.darkgrey,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: colors.darkgrey,
          },
        },
      },
    },

    MuiTablePagination: {
      caption: {
        color: colors.grey,
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: '400',
        paddingTop: '0',
      },
    },

    MuiTableCell: {
      head: {
        fontWeight: '700',
        color: colors.black,
      },
    },

    MuiTableSortLabel: {
      root: {
        color: colors.darkgrey,
        '&:hover': colors.darkgrey,
        '&:focus': colors.darkgrey,
      },
      active: {
        color: colors.darkgrey,
        '&:hover': colors.darkgrey,
      },
    },
  },

  palette: {
    primary: {
      main: colors.calsawsBlue,
    },
    secondary: {
      main: colors.turquoise,
    },
    error: {
      main: colors.red,
    },
    text: {
      disabled: colors.grey,
    },
    background: {
      default: '#fff',
    },
    warning: {
      main: colors.warning
    }
  },
})

theme = responsiveFontSizes(theme)

export default theme
