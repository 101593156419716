import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import store from 'app/store.js'
import { resetNewUser } from 'ducks/adminSlice'
import { resetEntities } from 'ducks/entitiesSlice'
import { removeAllValidationErrors } from 'ducks/validationErrorsSlice'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import Can from 'app/Can'
import NavLink from '@material-ui/core/Link'
import { colors } from 'app/theme'

// ADA: have to use ListItem and ListItemText
// to make navbar having a correct ul li structure
const StyledListItem = withStyles({
  root: {
    marginTtop: 0,
    maxWidth: '230px',
    marginLeft: '16px',
    fontWeight: 'normal',
  },
})(ListItem)

// ADA: Have to use the NavLink to override the inherent color of ListItemText
// and achieve same hover, focus effect as top nav bar
const StyledNavLink = styled(NavLink)`
  font-size: 14px;
  text-decoration: underline;
  :hover {
    color: ${colors.turquoise};
    cursor: pointer;
  }
  :focus {
    color: ${colors.turquoise};
    cursor: pointer;
  }
  :active {
    color: ${colors.turquoise};
    outline: 0;
  }
`

const StyledListHeadline = styled(ListItem)`
  margin-bottom: -3px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  cursor: auto;
`

export default React.memo(() => {
  const history = useHistory()
  const { roles } = useSelector(state => state.user)

  const handleClick = path => {
    if (history.location.pathname !== path) {
      store.dispatch(resetNewUser())
      store.dispatch(resetEntities())
      store.dispatch(removeAllValidationErrors())
      history.push(path)
    }
  }

  return (
    <Can
      roles={roles}
      perform='user:list'
      yes={() => {
        return (
          <>
            <List dense>
              <StyledListHeadline>Users</StyledListHeadline>
              <StyledListItem>
                <ListItemText
                  data-testid="AdminNavbar-create-user"
                  onClick={() => {
                    handleClick('/administration_create')
                  }}
                >
                  <StyledNavLink tabIndex='0'>Create New User</StyledNavLink>
                </ListItemText>
              </StyledListItem>

              <StyledListItem>
                <ListItemText
                  data-testid="AdminNavbar-manage-users"
                  onClick={() => {
                    handleClick('/administration_manage')
                  }}
                >
                  <StyledNavLink tabIndex='0'>Manage/Edit Users</StyledNavLink>
                </ListItemText>
              </StyledListItem>

              <StyledListHeadline>Entity</StyledListHeadline>

              <StyledListItem>
                <ListItemText
                  data-testid="AdminNavbar-manage-entities"
                  onClick={() => {
                    handleClick('/administration_manage_entities')
                  }}
                >
                  <StyledNavLink tabIndex='0'>
                    Manage/Edit Entities
                  </StyledNavLink>
                </ListItemText>
              </StyledListItem>
            </List>
          </>
        )
      }}
    />
  )
})
