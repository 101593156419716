import React from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import validation from 'validation/specialCharacterValidator'

const TextAreaAutoSize = props => {
  const handleChange = event => {
    const isInvalidInput = validation.hasSpecialCharacter(event.target.value)

    if (!isInvalidInput && props.onChange) {
      props.onChange(event.target.value)
    }
  }

  return <TextareaAutosize {...props} onChange={handleChange} minRows={4} />
}

export default TextAreaAutoSize
