import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from 'components/general/Select'
import { useSelector } from 'react-redux'
import { previewInterviewSelector } from '../../utilTools/reduxHelper';
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableRowDescription from 'components/general/TableRowDescription'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const tableHeaders = [
  { name: '', colWidth: '50%' },
  { name: 'Has this ever been a problem?', colWidth: '25%' },
  { name: 'Issue now?', colWidth: '25%' },
]

export const tableQuestions = [
  {
    problem: 'previous_problem_police_call_self',
    issue: 'issue_now_police_call_self',
  },
  {
    problem: 'previous_problem_threatened_family',
    issue: 'issue_now_threatened_family',
  },
  {
    problem: 'previous_problem_destroyed_property_self',
    issue: 'issue_now_destroyed_property_self',
  },
  {
    problem: 'previous_problem_control_money_self',
    issue: 'issue_now_control_money_self',
  },
  {
    problem: 'previous_problem_prevented_leaving_house',
    issue: 'issue_now_prevented_leaving_house',
  },
  {
    problem: 'previous_problem_followed_partner',
    issue: 'issue_now_followed_partner',
  },
  {
    problem: 'previous_problem_criticized_partner',
    issue: 'issue_now_criticized_partner',
  },
  {
    problem: 'previous_problem_harrassed_partner',
    issue: 'issued_now_harrassed_partner',
  },
  {
    problem: 'previous_problem_forced_sexual_activities',
    issue: 'issue_now_forced_sexual_activities',
  },
  {
    problem: 'previous_problem_firearm_access',
  },
  {
    problem: 'previous_problem_prevented_family_relationships',
  },
]

const DomesticAbusePerpetratorQuestions = React.memo(({ isPreview }) => {
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  const problemIsYes = tableQuestions.map(question => 
    selectorState[question.problem].answer?.toUpperCase() === 'YS'
    )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Perpetrator Questions'
          domain='Domestic Abuse'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Conflict in your life can sometimes hinder your efforts to participate
          in work or WTW activities. The next set of questions ask about
          conflict you may experience.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CustomTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableHeaderCell key={index} colwidth={header.colWidth}>
                      {header.name}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableQuestions.map((question, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={question.problem}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Select
                          question_id={question.problem}
                          disableDescription={true}
                          isPreview={isPreview}
                        />
                      </TableCell>
                      <TableCell>
                        {question.issue && (
                          <Select
                            question_id={question.issue}
                            disableDescription={true}
                            isDisabled={!problemIsYes[index]}
                            isPreview={isPreview}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <Select question_id='previous_received_counseling_services' isPreview={isPreview} />
          <Select question_id='desire_counseling_services' isPreview={isPreview} />
          <Select question_id='help_safely_accessing_services' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default DomesticAbusePerpetratorQuestions
