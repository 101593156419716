import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import Select from 'react-select'
import InterviewListQueries from './InterviewListQueries'
import { openDialog } from 'ducks/dialogSlice'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { colors } from 'app/theme'
import { decodeHtml } from '../../utilTools/decodeHtml'

const StyledSelect = styled(Select)`
  flex: 1 0 auto;
  margin: auto;
  width: 75%;
`

const IconContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  font-size: 18px;
  align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
  && {
    &:hover {
      color: ${colors.saffron};
    }
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  && {
    &:hover {
      color: ${colors.saffron};
    }
  }
`
const StyledIconButton = styled(IconButton)`
  && {
    color: ${colors.calsawsBlue};
    background-color: transparent;
    font-size: 18px;
    &:hover {
      color: ${colors.saffron};
    }
  }
`

const customStyles = {
  control: (provided, { isFocused, hasValue }) => ({
    ...provided,
    border: isFocused && !hasValue ? `1px solid ${colors.saffron}` : 'none',
    boxShadow: isFocused ? 0 : 0,
    ':hover': {
      border: `1px solid ${colors.saffron}`,
    },
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ':hover': {
      outline: `1px solid ${colors.saffron}`,
    },
    ':active': {
      outline: `1px solid ${colors.saffron}`,
    },
    backgroundColor: 'rgba(255, 255, 255, 1)',
    outline: isFocused ? `1px solid ${colors.saffron}` : null,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: `${colors.grey}`,
  }),
}

const AssigneeEditList = ({
  assignee,
  setShouldEdit,
  setNewAssignee,
  handleCheckSelect,
  countyId,
}) => {
  const dispatch = useDispatch()
  // Query all users in the current user's county
  const { loading, error, data } = useQuery(
    InterviewListQueries.ASSIGNABLE_USERS,
    {
      fetchPolicy: 'network-only',
    }
  )

  const options = []
  if (data) {
    data.assignableUsers.forEach(user => {
      const { firstName, lastName } = user
      options.push({
        value: { ...user },
        label: decodeHtml(`${firstName} ${lastName}`),
      })
    })
  }

  // Something went wrong with the query, notify the user
  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  if (loading) {
    return <div>Loading user details...</div>
  }

  return (
    <>
      <StyledSelect
        aria-label='interviewer type ahead'
        autoFocus
        styles={customStyles}
        options={options}
        onChange={value => setNewAssignee(value)}
        isLoading={loading}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder='Type ahead...'
      />
      <IconContainer>
        <StyledIconButton
          onClick={() => setShouldEdit(false)}
          aria-label='close'
        >
          <StyledCloseIcon />
        </StyledIconButton>
        <StyledIconButton onClick={handleCheckSelect} aria-label='save'>
          <StyledCheckIcon />
        </StyledIconButton>
      </IconContainer>
    </>
  )
}

export default AssigneeEditList
