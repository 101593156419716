import {
  addValidationError,
  removeValidationError,
  bulkAddValidationErrors,
} from 'ducks/validationErrorsSlice'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import AdminQueries from 'components/admin/AdminQueries'

import validator from 'validation/validationLogic'
import validation from 'validation/specialCharacterValidator'

const isValidEmail = email =>
  email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
// Validate the large initial payload to trigger any validation errors
// that should be already present
export const initialValidation = data => {
  return async (dispatch, getState) => {
    const results = []
    // For each data point in the initial load
    for (let { question_id, answer } of Object.values(data)) {
      // Find current question_id's validator function, if it exists
      if (!validator.hasValidation(question_id)) {
        continue
      }
      const result = validator.validate(question_id, answer)
      // If there is a validation error, result will not be falsy
      if (result) {
        const validationError = {
          question_id: question_id,
          error: result,
        }
        // Add result to results array
        results.push(validationError)
      }
    }
    dispatch(bulkAddValidationErrors(results))
  }
}
const emailError = (dispatch, error) => {
  dispatch(addValidationError({ admin: true, field: 'email', error }))
}

export const emailValidation = (value, isEdit) => {
  return async (dispatch, getState) => {
    try {
      const payload = { email: value }

      // If this is coming from the admin edit page, send the id in
      // the admin slice as a part of the payload.
      if (isEdit) payload.id = getState().admin.id

      // Check if there is a value for email.
      if (!value) emailError(dispatch, 'This field is required.')
      // Check if the email is a valid email format.
      else if (!isValidEmail(value))
        emailError(dispatch, 'Not a valid email address.')
      // Query and check the return value.
      else {
        const { data } = await client.query({
          query: AdminQueries.IS_DUP_EMAIL,
          variables: { ...payload },
          fetchPolicy: 'network-only',
        })

        // Error if dup email, remove validation errors if unique email.
        data.isDuplicateEmail
          ? emailError(dispatch, 'Email is not unique.')
          : dispatch(removeValidationError('email'))
      }
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}

const validate = ({
  question_id,
  answer,
  fieldName,
  shouldCheckCharacters,
}) => {
  return async (dispatch, getState) => {
    const hasCurrentValidationError = Object.keys(
      getState().validationErrors
    ).includes(question_id)
    const validationError = {
      question_id,
    }

    const hasInputError =
      (shouldCheckCharacters ?? false) && validation.hasSpecialCharacter(answer)
    const label = fieldName?.replaceAll('*', '')
    const inputErrorMessage = hasInputError
      ? label
        ? `${label} has invalid input`
        : 'Invalid input'
      : null
    const hasValidation = validator.hasValidation(question_id)
    let errorMessage = inputErrorMessage
    if (hasValidation) {
      const result = validator.validate(question_id, answer)
      errorMessage = result ?? inputErrorMessage
    }
    const hasValidatorError = errorMessage ?? false
    const hasError = hasInputError || hasValidatorError

    validationError.error = errorMessage

    if (hasError) {
      dispatch(addValidationError(validationError))
    } else if (hasCurrentValidationError) {
      dispatch(removeValidationError(question_id))
    }
  }
}

export default validate
