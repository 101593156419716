import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch } from 'react-redux'
import InterviewListQuery from './InterviewListQueries'
import InterviewList from './InterviewList'

const InterviewListDataLoader = () => {
  const dispatch = useDispatch()
  const clientId = useSelector(state => state.client.currentClient)
  // Query the client interview data by client id
  const { loading, error, data, refetch } = useQuery(
    InterviewListQuery.FEED_QUERY,
    {
      variables: { id: clientId },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  if (loading) return <div>Loading interview list...</div>
  const interviewRecords = data?.interviewsByClient || []

  return (
    <InterviewList refetchInterviewList={refetch} records={interviewRecords} />
  )
}

export default InterviewListDataLoader
