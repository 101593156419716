import employmentCurrentActivitiesEffects from 'side-effects/employmentCurrentActivitiesEffects'
import educationHighestGradeEffects from 'side-effects/educationHighestGradeEffects'
import educationLearningNeedsAssessmentEffects from 'side-effects/educationLearningNeedsAssessmentEffects'
import transportationDriverLicenseEffects from 'side-effects/transportationDriverLicenseEffects'
import transportationChallengeEffects from 'side-effects/transportationChallengeEffects'
import substanceUseInHouseholdEffects from 'side-effects/substanceUseInHouseholdEffects'
import relationshipsEffects from 'side-effects/relationshipsEffects'
import closingQuestionEffects from 'side-effects/closingQuestionEffects'
import wrapUpEffects from 'side-effects/wrapUpEffects'
import generalHealthIndicatorEffects from 'side-effects/generalHealthIndicatorEffects'
import childCareEffects from 'side-effects/childCareEffects'
import transportationMethodEffects from 'side-effects/transportationMethodEffects'
import legalBarriersEffects from 'side-effects/legalBarriersEffects'
import domesticAbuseIndicatorQuestionsEffects from 'side-effects/domesticAbuseIndicatorQuestionsEffects'
import housingSituationEffects from 'side-effects/housingSituationEffects'
import alcoholDrugsEffects from 'side-effects/alcoholDrugsEffects'
import emotionalAndMentalHealthIndicatorEffects from 'side-effects/emotionalAndMentalHealthIndicatorEffects'
import currentHousingEffects from 'side-effects/currentHousingEffects'
import SubstanceUseIndicatorQuestionsEffects from 'side-effects/SubstanceUseIndicatorQuestionsEffects'
import DomesticAbuseVictimQuestionsEffects from 'side-effects/DomesticAbuseVictimQuestionsEffects'
import DomesticAbusePerpetratorQuestionsEffects from 'side-effects/DomesticAbusePerpetratorQuestionsEffects'
import OtherEducationTrainingEffects from 'side-effects/OtherEducationTrainingEffects'
import employmentJobHistoryEffects from 'side-effects/employmentJobHistoryEffects'
import demographicsGeneralInformationEffects from 'side-effects/demographicsGeneralInformationEffects'
import ReasonsNotWorkingEffects from 'side-effects/ReasonsNotWorkingEffects'
import financesBenefitsEffects from 'side-effects/financesBenefitsEffects'
import currentStatusEffects from 'side-effects/currentStatusEffects'
import SafetyConcernsVictimEffects from 'side-effects/safetyConcernsEffects'
import demographicsHouseholdCompEffects from 'side-effects/demographicsHouseholdCompEffects'
import employmentWorkExperienceInterests from 'side-effects/employmentWorkExperienceInterests'
import parentingAndChildSupportEffects from 'side-effects/parentingAndChildSupportEffects'
import pregnancyPregnancyEffects from 'side-effects/pregnancyPregnancyEffects'
import pregnancyIndicatorQuestionsEffects from 'side-effects/pregnancyIndicatorQuestionsEffects'
import childCareParentingChildrensIssuesEffects from 'side-effects/childCareParentingChildrensIssuesEffects'
import childCarePrimaryCaregiverEffects from 'side-effects/childCarePrimaryCaregiverEffects'

const effects = [
  employmentCurrentActivitiesEffects,
  educationHighestGradeEffects,
  educationLearningNeedsAssessmentEffects,
  transportationDriverLicenseEffects,
  transportationChallengeEffects,
  emotionalAndMentalHealthIndicatorEffects,
  substanceUseInHouseholdEffects,
  relationshipsEffects,
  closingQuestionEffects,
  wrapUpEffects,
  generalHealthIndicatorEffects,
  childCareEffects,
  transportationMethodEffects,
  legalBarriersEffects,
  domesticAbuseIndicatorQuestionsEffects,
  housingSituationEffects,
  alcoholDrugsEffects,
  currentHousingEffects,
  SubstanceUseIndicatorQuestionsEffects,
  DomesticAbuseVictimQuestionsEffects,
  DomesticAbusePerpetratorQuestionsEffects,
  OtherEducationTrainingEffects,
  employmentJobHistoryEffects,
  demographicsGeneralInformationEffects,
  ReasonsNotWorkingEffects,
  financesBenefitsEffects,
  currentStatusEffects,
  SafetyConcernsVictimEffects,
  demographicsHouseholdCompEffects,
  employmentWorkExperienceInterests,
  parentingAndChildSupportEffects,
  pregnancyPregnancyEffects,
  pregnancyIndicatorQuestionsEffects,
  childCareParentingChildrensIssuesEffects,
  childCarePrimaryCaregiverEffects,
  // Others go here!
]

export default effects;