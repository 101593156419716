import React, { useCallback, useState, useEffect } from 'react'
import CustomDropdown from 'components/clientSearch/SearchDropdown'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from 'ducks/dialogSlice'
import OfficesTable from 'components/admin/AdminOfficesTable'
import RegionsTable from 'components/admin/AdminRegionsTable'
import { setOffices, setRegions, setCounty } from 'ducks/entitiesSlice'
import { Typography, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from 'app/theme'

const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
  }
`

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`

const ORG_LEVEL_TYPE_QUERY = gql`
  query entityOrgLevelTypes {
    entityOrgLevelTypes {
      code
      value
    }
  }
`

const OFFICES_QUERY = gql`
  query offices($orgLevelID: Int) {
    offices(orgLevelID: $orgLevelID) {
      regionID
      regionName
      officeID
      officeName
      hasUsers
    }
  }
`

const REGIONS_QUERY = gql`
  query regions($orgLevelID: Int) {
    regions(orgLevelID: $orgLevelID) {
      regionID
      regionName
      hasUsers
      hasOffices
    }
  }
`

const CountyDropdown = ({
  onSelect: { getOfficesByCounty, getRegionsByCounty },
}) => {
  const { orgLevelID, isStatewide } = useSelector(state => state.user)
  const STATE_WIDE = '00'
  const [countyId, setCountyId] = useState('')
  const dispatch = useDispatch()
  const { data } = useQuery(ORG_LEVEL_TYPE_QUERY, {
    fetchPolicy: 'network-only',
    onError: error => {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    },
  })

  // If for some reason, the countyId turns out to be lacking the two digit
  // format (9 instead of 09) make sure we add that zero for consistency
  const ensureTwoDigitCounty = countyId => {
    if (!countyId) return ''
    return countyId?.toString().length === 1 ? `0${countyId}` : `${countyId}`
  }

  const selectCounty = useCallback(
    countyId => {
      dispatch(setCounty(countyId))
      setCountyId(countyId)
      getOfficesByCounty({
        variables: { orgLevelID: parseInt(countyId) },
      })
      getRegionsByCounty({
        variables: { orgLevelID: parseInt(countyId) },
      })
    },
    [getRegionsByCounty, getOfficesByCounty, setCountyId, dispatch]
  )
  const orgLevels =
    data?.entityOrgLevelTypes?.map(county => ({
      optionId: county.code,
      optionLabel: county.value,
    })) || []

  // Remove statewide from selections
  const counties = orgLevels.filter(county => county.optionId !== STATE_WIDE)

  const handleChange = e => {
    selectCounty(e.target.value)
  }
  useEffect(() => {
    if (!isStatewide && data) selectCounty(orgLevelID)
  }, [data, isStatewide, orgLevelID, selectCounty])

  return (
    <CustomDropdown
      dropdownProps={{ disabled: !isStatewide }}
      label='County'
      value={ensureTwoDigitCounty(countyId)}
      handleChange={handleChange}
      options={counties}
    />
  )
}

const Component = () => {
  const dispatch = useDispatch()
  const [getOfficesByCounty] = useLazyQuery(OFFICES_QUERY, {
    fetchPolicy: 'network-only',
    onError: error => {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    },
    onCompleted: data => dispatch(setOffices({ entities: data.offices })),
  })
  const [getRegionsByCounty] = useLazyQuery(REGIONS_QUERY, {
    fetchPolicy: 'network-only',
    onError: error => {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    },
    onCompleted: data => dispatch(setRegions({ entities: data.regions })),
  })
  const countyId = useSelector(state => state.entities.county)
  const isDisabled = countyId ? false : true

  return (
    <Grid container spacing={2}>
      <PageTitleContainer>
        <Typography variant='h3'>OCAT Administration</Typography>
      </PageTitleContainer>
      <Grid item xs={12}>
        <SectionTitle variant='h4'>Create and Manage Entities</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>
          Click on the Action buttons to add or edit the entities in your
          County.
        </Typography>
        <Typography variant='body1'>
          If a region/office cannot be deleted, then there are currently active
          users or offices in that region/office.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CountyDropdown onSelect={{ getOfficesByCounty, getRegionsByCounty }} />
      </Grid>
      <Grid item xs={12}>
        <RegionsTable
          isDisabled={isDisabled}
          description='Create and Manage Regions'
        />
        <OfficesTable
          isDisabled={isDisabled}
          description='Create and Manage Offices'
        />
      </Grid>
    </Grid>
  )
}
 export default Component;