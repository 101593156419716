import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { closeDialog } from 'ducks/dialogSlice'

import {
  ErrorDialogActions,
  ErrorDialogContent,
} from 'components/general/ErrorDialog'
import {
  ValidationDialogActions,
  ValidationDialogContent,
} from 'components/general/ValidationDialog'
import {
  DiscardDialogActions,
  DiscardDialogContent,
} from 'components/general/DiscardDialog'
import {
  AddNotesDialogActions,
  AddNotesDialogContent,
} from 'components/general/AddNotesDialog'
import {
  TimeoutWarningDialogActions,
  TimeoutWarningDialogContent,
} from 'components/general/TimeoutWarningDialog'
import {
  NewAppraisalDialogActions,
  NewAppraisalDialogContent,
} from 'components/interviewList/NewAppraisalDialog'

import {
  PrintQuestionnaireDialogActions,
  PrintQuestionnaireDialogContent,
} from 'components/interviewList/PrintQuestionnaireDialog'

import {
  NewAppraisalPopulateDialogActions,
  NewAppraisalPopulateDialogContent,
} from 'components/interviewList/NewAppraisalPopulateDialog'

import {
  SharingCountiesDialogActions,
  SharingCountiesDialogContent,
} from 'components/general/SharingCountiesDialog'

import {
  AdminCancelDialogActions,
  AdminCancelDialogContent,
} from 'components/admin/AdminCancelDialog'

import {
  AdminRequiredFieldsDialogActions,
  AdminRequiredFieldsDialogContent,
} from 'components/admin/AdminRequiredFieldsDialog'

import {
  AdminConfirmDataExtractActions,
  AdminConfirmDataExtractContent,
} from 'components/admin/AdminConfirmDataExtractDialog'

import {
  DiscardResourceDialogContent,
  DiscardResourceDialogActions,
} from 'components/general/DiscardResourceDialog'

import {
  AuthErrorDialogActions,
  AuthErrorDialogContent,
} from 'components/general/AuthErrorDialog'

import {
  InformationDialogActions,
} from 'components/general/InformationDialog'

import {
  ConfirmationDialogActions,
  ConfirmationDialogContent
} from 'components/general/ConfirmationDialog'

import {
  DialogDatePickerContent,
  DialogDatePickerActions
} from 'components/general/DatePickerDialog'

import {
  InterviewImpactsDialogContent,
  InterviewImpactsDialogActions
} from 'components/general/InterviewImpactsDialog'

// Specific content to use based on dialog type
const DIALOG_CONTENT = {
  error: <ErrorDialogContent />,
  validation: <ValidationDialogContent />,
  discard: <DiscardDialogContent />,
  timeoutWarning: <TimeoutWarningDialogContent />,
  note: <AddNotesDialogContent />,
  newAppraisal: <NewAppraisalDialogContent />,
  sharingCounties: <SharingCountiesDialogContent />,
  adminCancel: <AdminCancelDialogContent />,
  newAppraisalPopulate: <NewAppraisalPopulateDialogContent />,
  discardResources: <DiscardResourceDialogContent />,
  printQuestionnaire: <PrintQuestionnaireDialogContent />,
  adminRequiredFields: <AdminRequiredFieldsDialogContent />,
  adminConfirmDataExtract: <AdminConfirmDataExtractContent />,
  authError: <AuthErrorDialogContent />,
  information: <div />,
  confirmation: <ConfirmationDialogContent />,
  dialogDatePicker: <DialogDatePickerContent />,
  interviewImpacts: <InterviewImpactsDialogContent />
}

// Specific actions to use based on dialog type
const DIALOG_ACTIONS = {
  error: <ErrorDialogActions />,
  validation: <ValidationDialogActions />,
  discard: <DiscardDialogActions />,
  timeoutWarning: <TimeoutWarningDialogActions />,
  note: <AddNotesDialogActions />,
  newAppraisal: <NewAppraisalDialogActions />,
  sharingCounties: <SharingCountiesDialogActions />,
  adminCancel: <AdminCancelDialogActions />,
  newAppraisalPopulate: <NewAppraisalPopulateDialogActions />,
  discardResources: <DiscardResourceDialogActions />,
  printQuestionnaire: <PrintQuestionnaireDialogActions />,
  adminRequiredFields: <AdminRequiredFieldsDialogActions />,
  adminConfirmDataExtract: <AdminConfirmDataExtractActions />,
  authError: <AuthErrorDialogActions />,
  information: <InformationDialogActions />,
  confirmation: <ConfirmationDialogActions/>,
  dialogDatePicker: <DialogDatePickerActions />,
  interviewImpacts: <InterviewImpactsDialogActions />
}

const DialogRoot = () => {
  const { type, title, description, props } = useSelector(state => state.dialog)
  const dispatch = useDispatch()

  // If there is no active type, return nothing
  if (!type) {
    return null
  }

  // Assign props to content and actions Components programmatically
  const dialogContent = React.cloneElement(DIALOG_CONTENT[type], props)
  const dialogActions = React.cloneElement(DIALOG_ACTIONS[type], props)

  return (
    <Dialog
      open
      onClose={(event, reason) => 
        {
          if (reason !== 'backdropClick') {
            dispatch(closeDialog())
          }
        }
      }
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      role='dialog'
      disablePortal={true}
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText id='dialog-description'>
            {description}
          </DialogContentText>
        )}
        {dialogContent}
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  )
}

export default DialogRoot
