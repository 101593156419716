import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { gql } from 'apollo-boost'
import {
  setPrintQuestionnairePayload,
  cleanPrintQuestionnairePayload,
} from 'ducks/printQuestionnaireSlice'
import { useQuery } from '@apollo/react-hooks'
import { Button } from '@material-ui/core'
import { openDialog } from 'ducks/dialogSlice'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import cloneDeep from 'lodash/cloneDeep'
import { colors } from 'app/theme'
import { withStyles } from '@material-ui/core/styles'
import Mutations from './InterviewListMutations'
import { setFileDownloadAuthCookie } from '../../utilTools/fileDownloadHelper'
import { useMutation } from '@apollo/react-hooks'
const ContentContainer = styled.div`
  min-width: 450px;
  margin: 0px 0px 20px 0px;
`
const StyledMenuItem = withStyles({
  root: {
    '&.Mui-focusVisible': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:hover': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:focus': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&:active': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
    },
    '&$selected': {
      backgroundColor: colors.white,
      outline: `1px solid ${colors.saffron}`,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  selected: {},
})(MenuItem)

const StyledCheckbox = styled(Checkbox)`
  padding-bottom: 5px;
`

const StyledPrintListContainer = styled(Paper)`
  width: 350px;
  max-height: 200px;
  margin: 10px;
  overflow-y: auto;
  padding: 10px;
`
const ValidationMessageContainer = styled(Grid)`
  height: 60px;
  text-align: right;
  margin-bottom: 5px;
`

const ValidationMessage = styled(Typography)`
  color: ${colors.red};
  margin: 0px 10px 3px auto;
  line-height: 5px;
  display: block;
  height: 5px;
`
const StyledButton = styled(Button)`
  float: right;
  width: 100px;
  margin: 0 10px 0 10px;
`
const ButtonContainer = styled(Grid)`
  max-width: 500px;
  margin-bottom: 10px;
`

const GET_INTERVIEW_NAV_DATA = gql`
  query categoriesAndDomains {
    categoriesAndDomains {
      payload
    }
  }
`

export const PrintQuestionnaireDialogContent = ({ interviewId }) => {
  const dispatch = useDispatch()

  const { loading, error, data: navData } = useQuery(GET_INTERVIEW_NAV_DATA)
  const subDomainsDisabled = loading

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])
  const getPrintQuestionnaireDefaultListOptions = navPayload => {
    const questionnaireCategory = navPayload.find(category => {
      return category.code === 'questionnaire'
    })
    const sortedDomains = questionnaireCategory.domains
      .slice()
      .sort((a, b) => a.order - b.order)
    return sortedDomains.map(domain => {
      return { name: domain.name, code: domain.code, selected: false }
    })
  }

  const getPrintQuestionnairePayload = (
    printQuestionnaireListOptions,
    notesIncluded
  ) => {
    const printSubDomains = printQuestionnaireListOptions.filter(option => {
      return option.selected === true
    })
    return {
      interviewId,
      printSubDomains,
      includeNotes: notesIncluded,
    }
  }

  const [
    printQuestionnaireListOptions,
    setPrintQuestionnaireListOptions,
  ] = React.useState([])

  const [checkAllSections, setCheckAllSections] = React.useState(false)

  const [notesIncluded, setNotesIncluded] = React.useState(null)

  // set default printQuestionnaireListOptions for first time render
  React.useEffect(() => {
    if (
      navData?.categoriesAndDomains?.payload &&
      printQuestionnaireListOptions.length === 0
    ) {
      const options = getPrintQuestionnaireDefaultListOptions(
        navData.categoriesAndDomains.payload
      )
      setPrintQuestionnaireListOptions(options)
    }
  }, [navData, printQuestionnaireListOptions])

  // tracking and updating the print payload changing
  React.useEffect(() => {
    const payload = getPrintQuestionnairePayload(
      printQuestionnaireListOptions,
      notesIncluded
    )
    dispatch(
      setPrintQuestionnairePayload({ printQuestionnairePayload: payload })
    )
  })

  const handleQuestionnaireListChange = index => {
    const tempState = cloneDeep(printQuestionnaireListOptions)
    tempState[index].selected = !tempState[index].selected
    setPrintQuestionnaireListOptions(tempState)
  }

  const handleNotesIncludedChange = e => {
    setNotesIncluded(e.target.value)
  }
  // ADA requires uncheck functionality
  const handleNotesIncludedClick = e => {
    if (e.target.checked) {
      setNotesIncluded(null)
    }
  }

  // ADA requires enter key accessibility
  const handleNotesIncludedKeyPress = e => {
    const keyCode = e.which || e.code
    const enterKeyCode = 13
    if (keyCode === enterKeyCode) {
      if (e.target.checked) {
        setNotesIncluded(null)
      } else {
        setNotesIncluded(e.target.value)
      }
    }
  }

  const handleCheckAllSections = () => {
    const optionsCopy = cloneDeep(printQuestionnaireListOptions)
    if (!checkAllSections) {
      optionsCopy.forEach(option => {
        option.selected = true
      })
    } else {
      optionsCopy.forEach(option => {
        option.selected = false
      })
    }
    setCheckAllSections(!Boolean(checkAllSections))
    setPrintQuestionnaireListOptions(optionsCopy)
  }

  const printListMenuItems = printQuestionnaireListOptions?.map(
    (option, index) => {
      return (
        <StyledMenuItem
          role='menu'
          disableRipple
          disableTouchRipple
          key={`printListMenuItem-${index}`}
          onClick={() => handleQuestionnaireListChange(index)}
          onKeyDown={e => {
            // ADA requires enter key accessibility
            const enterKeyCode = 13
            const keyCode = e.which || e.code
            if (keyCode === enterKeyCode) {
              handleQuestionnaireListChange(index)
            }
          }}
        >
          <FormControlLabel
            control={
              <StyledCheckbox
                role='menuitemcheckbox'
                disableRipple
                disableTouchRipple
                key={`printListCheckbox-${index}`}
                checked={option.selected}
                disabled={subDomainsDisabled}
              />
            }
            label={option.name}
          />
        </StyledMenuItem>
      )
    }
  )

  const checkAllSectionsMenuItem = (
    <StyledMenuItem
      role='menu'
      disableRipple
      disableTouchRipple
      key={`printListMenuItem-check-all`}
      onClick={handleCheckAllSections}
      onKeyDown={e => {
        // ADA requires enter key accessibility
        const enterKeyCode = 13
        const keyCode = e.which || e.code
        if (keyCode === enterKeyCode) {
          handleCheckAllSections()
        }
      }}
    >
      <FormControlLabel
        control={
          <StyledCheckbox
            role='menuitemcheckbox'
            disableRipple
            disableTouchRipple
            key={`printListCheckbox-check-all`}
            checked={checkAllSections}
            disabled={subDomainsDisabled}
          />
        }
        label='All'
      />
    </StyledMenuItem>
  )

  return (
    <ContentContainer>
      <Typography variant='body1'>
        Select from the options below to print Questionnaire and associated
        Notes
      </Typography>
      <StyledPrintListContainer>
        {[checkAllSectionsMenuItem, ...printListMenuItems]}
      </StyledPrintListContainer>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>
          <Typography variant='body1'>Include Notes</Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label='include notes'
          name='include notes'
          value={notesIncluded}
          onChange={handleNotesIncludedChange}
          onClick={handleNotesIncludedClick}
          onKeyPress={handleNotesIncludedKeyPress}
        >
          <FormControlLabel
            value={'true'}
            control={
              <Radio
                checked={notesIncluded === 'true'}
                inputProps={{ 'aria-label': `Include notes option Yes` }}
              />
            }
            label='Yes'
          />
          <FormControlLabel
            value={'false'}
            control={
              <Radio
                checked={notesIncluded === 'false'}
                inputProps={{ 'aria-label': `Include notes option No` }}
              />
            }
            label='No'
          />
        </RadioGroup>
      </FormControl>
    </ContentContainer>
  )
}

export const PrintQuestionnaireDialogActions = ({ interviewId }) => {
  const dispatch = useDispatch()

  const [doGenerateQuestionnaire, { loading, data, error }] = useMutation(
    Mutations.CREATE_QUESTIONNAIRE_PDF
  )

  const printQuestionnairePayload = useSelector(
    state => state.printQuestionnaire?.printQuestionnairePayload
  )
  // const interviewId = useSelector(state => state.client.currentInterview)
  const [validationMessage, setValidationMessage] = React.useState(null)
  const invalidQuestionnaireMsg =
    'Please select at least one questionnaire section'
  const invalidIncludeNotesMsg = 'Please select whether to include notes'

  const isInvalidInput =
    printQuestionnairePayload?.printSubDomains.length < 1 ||
    !printQuestionnairePayload?.includeNotes

  const handleClose = () => {
    dispatch(cleanPrintQuestionnairePayload())
    dispatch(closeDialog())
  }

  React.useEffect(() => {
    // the validationMessages are not shown and tracked when first render
    if (validationMessage === null) return
    const validationMessageGenerator = () => {
      const message = []
      if (printQuestionnairePayload?.printSubDomains.length < 1) {
        message.push(invalidQuestionnaireMsg)
      }
      if (!printQuestionnairePayload?.includeNotes) {
        message.push(invalidIncludeNotesMsg)
      }
      return message
    }
    const currentMessage = validationMessageGenerator()
    // once print button been clicked it will tracking validationMessage updating
    if (validationMessage.join() !== currentMessage.join()) {
      setValidationMessage(currentMessage)
    }
  }, [validationMessage, printQuestionnairePayload])

  const handlePrint = () => {
    if (validationMessage === null) {
      setValidationMessage([])
    }
    if (isInvalidInput) {
      return
    } else {
      const domainCodes = (
        printQuestionnairePayload?.printSubDomains ?? []
      ).map(item => item.code)
      const includeNotes = printQuestionnairePayload?.includeNotes === 'true'

      doGenerateQuestionnaire({
        variables: {
          interviewId,
          domains: domainCodes,
          includeNotes,
        },
      })
    }
  }

  React.useEffect(() => {
    if (data?.generateQuestionnairePdf) {
      setFileDownloadAuthCookie()
      window.open(data.generateQuestionnairePdf, '_blank')
      dispatch(cleanPrintQuestionnairePayload())
      dispatch(closeDialog())
      return
    }
  }, [dispatch, data, loading, error])

  if (error) {
    dispatch(
      openDialog({
        type: 'error',
        title: 'System Error',
        props: {
          error,
        },
      })
    )
  }

  return (
    <>
      <ButtonContainer container>
        <ValidationMessageContainer item xs={12}>
          {validationMessage &&
            validationMessage.length > 0 &&
            validationMessage.map((message, index) => {
              return (
                <ValidationMessage variant='subtitle2' key={index}>
                  {message}
                </ValidationMessage>
              )
            })}
        </ValidationMessageContainer>
        <Grid justifyContent='flex-end' container>
          <StyledButton
            onClick={handleClose}
            color='secondary'
            variant='contained'
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handlePrint}
            color='primary'
            variant='contained'
            disabled={loading}
          >
            Print
          </StyledButton>
        </Grid>
      </ButtonContainer>
    </>
  )
}
