import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const unsavedNotesSlice = createSlice({
  name: 'unsavedNotes',
  initialState,
  reducers: {
    setUnsavedNote: (state, action) => {
      state.domain = action.payload.domain
      state.subdomain = action.payload.subdomain
      state.note = action.payload.note
    },
    clearUnsavedNote: (state, action) => initialState
  }
})

export const {
  setUnsavedNote,
  clearUnsavedNote
} = unsavedNotesSlice.actions

export default unsavedNotesSlice.reducer
