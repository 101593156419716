import React, { useEffect } from 'react'
import styled from 'styled-components'
import { createSelector } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { signOut } from 'thunks/signOut'
import { colors } from 'app/theme'

const StyledButton = styled(Button)`
  min-width: 20px;
  padding: 0px;
  :hover {
    background-color: transparent;
  }
  :focus {
    box-shadow: 0 0 0 2px ${colors.blue};
    cursor: pointer;
    text-decoration: none;
  }
`

const StyledUserName = styled(Typography)`
  && {
    display: inline-block;
    padding: 0;
    text-transform: none;
  }
`

const StyledPopper = styled(Popper)`
  z-index: 10;
`
const selectEmail = createSelector(
  state => state.user.email,
  email => email
)

const UserMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const email = useSelector(selectEmail)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleSignOut = () => {
    setOpen(false)
    dispatch(signOut())
  }

  const handleViewProfile = () => {
    setOpen(false)
    history.push('/view_profile')
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <div data-testid="UserMenu-wrapper">
      <StyledButton
        disableRipple={true}
        color='default'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        data-testid="UserMenu-button"
      >
        <StyledUserName variant='subtitle2'>{email}</StyledUserName>
        {email ? <ExpandMore fontSize='small' /> : null}
      </StyledButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                  data-testid="UserMenu-keydown"
                >
                  <MenuItem data-testid="UserMenu-item-profile" onClick={handleViewProfile}>View Profile</MenuItem>
                  <MenuItem data-testid="UserMenu-item-signout" onClick={handleSignOut}>Log Out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </div>
  )
}

export default UserMenu
