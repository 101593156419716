const config = {
  apiName:
    process.env.NODE_ENV !== 'development'
      ? '/graphql'
      : process.env.REACT_APP_GRAPHQL_ENDPOINT,
  maxActionPlanTextLength:
    parseInt(process.env.REACT_APP_MAX_ACTION_PLAN_TEXT_LENGTH ?? 30) ?? 2000,
  oidcUrl:
    process.env.NODE_ENV !== 'development'
      ? '/auth'
      : process.env.REACT_APP_OIDC_ENDPOINT,
  // This is a temporary way to bypass auth locally until localhost is whitelisted with ForgeRock.
  // Required since we merged the ALBs on AWS and don't have another OIDC ALB to bounc through for local authentication
  // This token can be overridden by loading the app with a token URL: http://localhost:3000?token=X
  // If this token expires, just grab one from dev.ocat.calsaws.net and use it in the URL.
  token:
    // caseworker
    //'eyJ0eXAiOiJKV1QiLCJraWQiOiI3MTI1Y2I1Yy05NDQyLTQzNGEtODk2My1lODMwNDFjMDM0NWIiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vaWQtZGV2LmNhbHNhd3MubmV0L2F1dGgvb2F1dGgyIiwiY2xpZW50Ijoib2NhdCIsInNpZ25lciI6ImFybjphd3M6ZWxhc3RpY2xvYWRiYWxhbmNpbmc6dXMtd2VzdC0yOjcxNTQ3MTEzMTc0NDpsb2FkYmFsYW5jZXIvYXBwL29jYXQtd2ViQUwtMTlEVVQyTldNQjBVSS9kODYxMDY3ODM4OGQ1YjU2IiwiZXhwIjoxNjE3NzQwNzczfQ==.eyJfaWQiOiJhNzllYmM3Ny03ZTZhLTQ1M2QtODk3NC0zOTQ0MmY5NzA3MWYiLCJyb2xlcyI6WyJjNzYyMmQxMC0yN2RhLTRiZDAtODM4Ny1hY2RjN2RlMGZlMjMiLCJlZjE1NmNlOC1kMWYwLTQzZTEtYWRhNi1mMDg0MTdhNDEyNjQiXSwic3ViIjoicmVlc2VzQGluZmluaXR5LXNvZnR3YXJlLmNvbSIsInN1Ym5hbWUiOiJpZD1yZWVzZXNAaW5maW5pdHktc29mdHdhcmUuY29tLG91PXVzZXIsbz1jYWxzYXdzLG91PXNlcnZpY2VzLG91PWFtLWNvbmZpZyIsImV4cCI6MTYxNzc0MDc3MywiaXNzIjoiaHR0cHM6Ly9pZC1kZXYuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIifQ==.pvTYW9UCA0bw3JpmAT9soI_e2azBhUpI6y9i33v0FkbKRmhX2VMSuW8dVMm6XjlSC6z4F5BI144Cfy3GEjQatA=='
    // 'eyJ0eXAiOiJKV1QiLCJraWQiOiIyNTQ4Mjc4ZS03MGVkLTRlYjQtYjY2OS1mMGNjMTQxNzBmYTYiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vaWQtZGV2LmNhbHNhd3MubmV0L2F1dGgvb2F1dGgyIiwiY2xpZW50Ijoib2NhdCIsInNpZ25lciI6ImFybjphd3M6ZWxhc3RpY2xvYWRiYWxhbmNpbmc6dXMtd2VzdC0yOjcxNTQ3MTEzMTc0NDpsb2FkYmFsYW5jZXIvYXBwL29jYXQtd2ViQUwtMTlEVVQyTldNQjBVSS9kODYxMDY3ODM4OGQ1YjU2IiwiZXhwIjoxNjE3MjkxOTM1fQ==.eyJfaWQiOiI5NjE5Y2FkZi04NDc0LTQ2MjMtYTIwNi05NGE5OTA5ZDYwODgiLCJyb2xlcyI6WyI1NjNmOGZiOS1lZmQ2LTQ1ZmItOWVjYi1kMzBkYmU1NmI0MzYiLCJmYzU0ZTZiZi0yYTA5LTRlMWYtODI4My1iMDA3YzgxMDBjMTAiLCJlOGNkOGMzOC1iNTQ3LTQ3ZjYtYTdhOS1lZDgzMTFlNmMzMGUiLCI2MzVmZDI2Mi1kY2MwLTQ5OGYtYjQ1Yy0zZmUwOTBmNTM5ZmIiLCJkMzNlNzBkMS01NWRjLTQ4N2UtYWNhYS0xNThhZGZmM2E1YjIiLCI1MWQzYjQ5NC1iODZkLTRhY2ItYTM3ZC0xYTU1MGNmMDIxYjMiLCJiMDBhZTg2MC02MWU1LTQ4OTYtODAxZC1lZTdhMThhMzQ2MDYiLCJjNzYyMmQxMC0yN2RhLTRiZDAtODM4Ny1hY2RjN2RlMGZlMjMiLCI2MmNkYjk0YS1hNTYxLTRmMmEtYjg1Yy0zMWZkNWYxNGZkMTQiLCJlZjE1NmNlOC1kMWYwLTQzZTEtYWRhNi1mMDg0MTdhNDEyNjQiXSwic3ViIjoib3NoYWlraEBjYW1icmlhc29sdXRpb25zLmNvbSIsInN1Ym5hbWUiOiJpZD1vc2hhaWtoQGNhbWJyaWFzb2x1dGlvbnMuY29tLG91PXVzZXIsbz1jYWxzYXdzLG91PXNlcnZpY2VzLG91PWFtLWNvbmZpZyIsImV4cCI6MTYxNzI5MTkzNSwiaXNzIjoiaHR0cHM6Ly9pZC1kZXYuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIifQ==.iwIYSz1B_FBdaBm1XkpK84a3IHRv5gT3XKlaxah3LVB71Py81oYHpEL508lOUubBi852SfViDnrgfG1kKBLazg=='
    // BRE
    'eyJ0eXAiOiJKV1QiLCJraWQiOiIwODAzOTdlZi0yYjVhLTQxZjQtYWY5My1lZjVmOWQ1NTk4N2IiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vaWQtYXQuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIiLCJjbGllbnQiOiJvY2F0Iiwic2lnbmVyIjoiYXJuOmF3czplbGFzdGljbG9hZGJhbGFuY2luZzp1cy13ZXN0LTI6NzE1NDcxMTMxNzQ0OmxvYWRiYWxhbmNlci9hcHAvb2NhdC13ZWJBTC0xTkwxOFhNNlc2SllVLzE1NGYzNjBiN2I2NDQ5NjEiLCJleHAiOjE3MjkyMTEzNDN9.eyJfaWQiOiJlNzI3OGYwOS1mZDkxLTRkMGItYjJhZS1hMTZjNmVhZDA3ZmMiLCJzdWIiOiJXb29OIiwic3VibmFtZSI6Ildvb04iLCJleHAiOjE3MjkyMTEzNDMsImlzcyI6Imh0dHBzOi8vaWQtYXQuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIifQ==.g8CWp0al3k48YJ4hTzAT0167LKt2eoVNHaLtbbgDrG3uV15TtzOjmcQLzXIcVlMXMyd1SSjXPXxtapcvQqpjHg==',
  // statewide
  //'eyJ0eXAiOiJKV1QiLCJraWQiOiJmMTM5YzNjOS01ZTM0LTQ3MWEtODFjMi0xMzRmZWY2OWFmOTIiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vaWQtYXQuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIiLCJjbGllbnQiOiJvY2F0Iiwic2lnbmVyIjoiYXJuOmF3czplbGFzdGljbG9hZGJhbGFuY2luZzp1cy13ZXN0LTI6NzE1NDcxMTMxNzQ0OmxvYWRiYWxhbmNlci9hcHAvb2NhdC13ZWJBTC0xTkwxOFhNNlc2SllVLzE1NGYzNjBiN2I2NDQ5NjEiLCJleHAiOjE2MTg1MjE0NTN9.eyJfaWQiOiI3ZjFhY2MwNi1jMzU1LTRiZmQtYjA3ZS1hYzlkZmFiNjZjNTUiLCJyb2xlcyI6WyI1NjNmOGZiOS1lZmQ2LTQ1ZmItOWVjYi1kMzBkYmU1NmI0MzYiLCJmYzU0ZTZiZi0yYTA5LTRlMWYtODI4My1iMDA3YzgxMDBjMTAiLCI2MzVmZDI2Mi1kY2MwLTQ5OGYtYjQ1Yy0zZmUwOTBmNTM5ZmIiLCJkMzNlNzBkMS01NWRjLTQ4N2UtYWNhYS0xNThhZGZmM2E1YjIiLCI1MWQzYjQ5NC1iODZkLTRhY2ItYTM3ZC0xYTU1MGNmMDIxYjMiLCJiMDBhZTg2MC02MWU1LTQ4OTYtODAxZC1lZTdhMThhMzQ2MDYiLCI1OTQwYWExNC05NWIwLTRmZWItYTczYS1iZjA1N2JlMTA0MWQiLCJlZjE1NmNlOC1kMWYwLTQzZTEtYWRhNi1mMDg0MTdhNDEyNjQiXSwic3ViIjoic3JlZXNlQGNhbWJyaWFzb2x1dGlvbnMuY29tIiwic3VibmFtZSI6ImlkPXNyZWVzZUBjYW1icmlhc29sdXRpb25zLmNvbSxvdT11c2VyLG89Y2Fsc2F3cyxvdT1zZXJ2aWNlcyxvdT1hbS1jb25maWciLCJleHAiOjE2MTg1MjE0NTMsImlzcyI6Imh0dHBzOi8vaWQtYXQuY2Fsc2F3cy5uZXQvYXV0aC9vYXV0aDIifQ==.6jOYTmv7-wmZXmKFoeFLfvq785HTHzCoU6FK0DzVmPWaxOGolY9C_kSJ4b2tBU9rSt9fBgARJsYDkMrsBYHdZw=='
}

export default config
