import Validator from 'validation/validator'
import { isValid, parse, isAfter, isBefore, isFuture, isEqual } from 'date-fns'
import store from 'app/store'

const validator = new Validator()

// Example only
// validator.add('transportation_license_reinstated', answer => {
//   if (answer === null) {
//     return 'Field is required'
//   } else if (answer === '123') {
//     return '123 is bad'
//   }
// })

// Checks to see if all "sub_question_ids" passed within "answers" are valid dates.
// Displays "errorMessage" if they are not valid dates. Format is optional for
// truncated and full dates.
const generalDateValidator = (answer, checkPast = false) => {
  // Get the date the interview was created and format it into MM/dd/yyyy date.
  const dateInterviewInitiated = parse(
    store.getState().client.currentInterviewStartDate,
    'MM/dd/yyyy',
    new Date()
  )

  // Set the day to the first of the month. We only check the past on dates
  // formatted MM/yyyy.
  dateInterviewInitiated.setDate(1)
  const parsedAnswer = parse(answer, 'MM/dd/yyyy', new Date())

  // Throw an error if the there is an invalid date.
  if (answer && !isValid(parsedAnswer)) {
    return 'Date is not a valid date.'
  }
  if (answer && isValid(parsedAnswer)) {
    // Throw an errror if there is a valid date but past dates are disabled and
    // the provided date is in the past.
    if (
      checkPast &&
      (isEqual(parsedAnswer, dateInterviewInitiated) ||
        isBefore(parsedAnswer, dateInterviewInitiated))
    ) {
      return 'Date format must be MM/YYYY and a future date.'

      // The date is before 1/1/1000 or in the future, throw an error.
    } else if (
      !checkPast &&
      (isBefore(parsedAnswer, new Date('01/01/1000')) || isFuture(parsedAnswer))
    ) {
      return 'Date is not a valid date.'
    }
  }
}

const tableDateValidator = (
  answers,
  sub_question_ids,
  errorMessage,
  format = 'MM/dd/yyyy',
  checkPast = false
) => {
  // Get the date the interview was created and format it into MM/dd/yyyy date.
  const dateInterviewInitiated = parse(
    store.getState().client.currentInterviewStartDate,
    'MM/dd/yyyy',
    new Date()
  )
  // Set the day to the first of the month. We only check the past on dates
  // formatted MM/yyyy.
  dateInterviewInitiated.setDate(1)
  // Check to see if there are answers within the table by checking if it is an array
  // and rows have been entered into the array.
  if (Array.isArray(answers) && answers.length > 0) {
    // Loop over each row entry in the table and check if every sub_question_id passes
    // validation. If all pass, Array.every returns true and if even one fails,
    // Array.every returns false.
    const areAllDatesValid = answers.every(answer => {
      return sub_question_ids.every(sub_question_id => {
        // If the sub_question hasn't been answered, no validation needed.
        if (!answer[sub_question_id]) return true
        // Else, correctly parse the date and check if it is a valid date.

        const parsedAnswer = parse(answer[sub_question_id], format, new Date())
        if (checkPast) {
          return (
            isValid(parsedAnswer) &&
            (!isBefore(parsedAnswer, dateInterviewInitiated) ||
              isEqual(parsedAnswer, dateInterviewInitiated))
          )
        }
        return (
          isValid(parsedAnswer) && isAfter(parsedAnswer, new Date('01/01/1000'))
        )
      })
    })
    if (!areAllDatesValid) {
      return errorMessage
    }
  }
}

validator.add('first_name', answer => {
  if (answer === null || answer?.trim() === '') {
    return 'First Name is required'
  }
})

validator.add('last_name', answer => {
  if (answer === null || answer?.trim() === '') {
    return 'Last Name is required'
  }
})

validator.add('dob', answer => {
  if (answer === null) {
    return 'Date of Birth is required'
  }
  return generalDateValidator(answer)
})

validator.add('gender', answer => {
  if (answer === null) {
    return 'Gender is required'
  }
})

validator.add('ssn', answer => {
  if (typeof answer === 'string' && answer.length < 9) {
    return 'Social Security Number is invalid'
  }
})

validator.add('highest_grade_start_date', answer => {
  if (!isValid(new Date(answer))) {
    return 'Date is not a valid date.'
  }
  return generalDateValidator(answer)
})

validator.add('highest_grade_end_date', answer => {
  if (!isValid(new Date(answer))) {
    return 'Date is not a valid date.'
  }
  return generalDateValidator(answer)
})

validator.add('due_date', answer => {
  if (!isValid(new Date(answer))) {
    return 'Date is not a valid date.'
  }
})

// Validators for Infinite Tables
validator.add('pregnancy_table', answers => {
  return tableDateValidator(
    answers,
    ['household_member_due_date'],
    'Date format must be MM/YYYY.',
    'MM/yyyy'
  )
})

validator.add('professional_license_table', answers => {
  return tableDateValidator(
    answers,
    ['professional_license_expiration_date'],
    'Date format must be MM/DD/YYYY and after 01/01/1000.'
  )
})

validator.add('vocational_technical_table', answers => {
  return tableDateValidator(
    answers,
    ['vocational_license_expiration_date'],
    'Date format must be MM/DD/YYYY and after 01/01/1000.'
  )
})

validator.add('court_dates_table', answers => {
  return tableDateValidator(
    answers,
    ['date_court'],
    'Date format must be MM/DD/YYYY and between 01/01/1000 and today.'
  )
})

validator.add('household_members_table', answers => {
  return tableDateValidator(
    answers,
    ['household_dob'],
    'Date format must be MM/DD/YYYY and between 01/01/1000 and today.'
  )
})

validator.add('job_history_table', answers => {
  return tableDateValidator(
    answers,
    ['begin_date_job', 'end_date_job'],
    'Date format must be MM/YYYY and between 01/01/1000 and today.',
    'MM/yyyy'
  )
})

export default validator
