import { setLastSaved, setLoadingTimestamp } from 'ducks/lastSavedSlice'
import { gql } from 'apollo-boost'
import { batch } from 'react-redux'
import { openDialog } from 'ducks/dialogSlice'
import client from 'apollo/Apollo'

const GET_INTERVIEW_UPDATED_DATE = gql`
  query latestSavedAnswerUpdatedAt($interviewId: Int) {
    latestSavedAnswerUpdatedAt(interviewId: $interviewId)
  }
`
// Query the db for the date the current interview was updated and set the
// lastSaved slice.
export const getLastSavedDate = () => {
  return async (dispatch, getState) => {
    try {
      // Get the current interview ID.
      const { currentInterview: interviewId } = getState().client

      // Get the date that the interview was last updated.
      const { data } = await client.query({
        query: GET_INTERVIEW_UPDATED_DATE,
        variables: { interviewId },
        fetchPolicy: 'network-only',
      })
      // Set the lastSaved slice.
      batch(() => {
        dispatch(setLastSaved(data?.latestSavedAnswerUpdatedAt))
        dispatch(setLoadingTimestamp(false))
      })
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      dispatch(setLastSaved('Error Saving'))
      // On error, open error modal.
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
