import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { colors } from 'app/theme'
import AdminDropdown from './AdminDropdown'
import AdminText from './AdminText'
import AdminTable from './AdminTable'
import AdminSelect from './AdminSelect'
import AdminDatePicker from './AdminDatePicker'
import AdminSaveButton from './AdminSaveButton'
import AdminCancelButton from './AdminCancelButton'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import AdminQueries from './AdminQueries'
import { setEditUser, setNewUser } from 'ducks/adminSlice'
import { filterData } from 'components/admin/AdminHelperFunctions'
import { openDialog } from 'ducks/dialogSlice'
import Can from 'app/Can'

const SectionTitle = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding: 0;
  }
`

const GridContainer = styled(Grid)`
  margin-bottom: 8px;
`

const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${p => (p.end ? `flex-end` : `space-between`)};
  align-items: center;
  align-content: center;
`

const AdminEdit = React.memo(() => {
  const dispatch = useDispatch()
  
  let { id, organization, region, role, secondaryRole, status } = useSelector(
    state => state.admin
  )
  const { orgLevelCode, roles } = useSelector(state => state.user)
  const isAdmin = roles.some((role) => {return role.code === 'AD'})
  const userId = useSelector(state => state.user.id)

  // Disable the secondary role if primary is admin or BRE, or an admin is editing himself and the secondaryRole has been set to admin
  const shouldDisableSecondaryRole = ((role === 'AD' || role === 'BRE') || (isAdmin && secondaryRole === 'AD' && id === userId))

  const breRolePicked = role === 'BRE'
  let user = useSelector(
    state => state.admin
  )
  useEffect(() => {
    if(breRolePicked) {
      dispatch(setEditUser({
        ...user,
        organization: '00',
        dataExtract: 0
      }))
    }
  }, [breRolePicked, dispatch, user])


  // Set up lazy query. Fires off when an organization is selected.
  // querySupervisors callback is passed into the dropdown component.
  // Once there is a value for organizationLevel,
  const [querySupervisors, { data, error }] = useLazyQuery(
    AdminQueries.SUPERVISORS_IN_COUNTY
  )
  // Query for regions. Fires off after an organziation is selected.
  const [queryRegions, { data: regionData, error: regionError }] = useLazyQuery(
    AdminQueries.GET_REGIONS
  )
  // Query for offices. Fires off after a region is selected.
  const [queryOffices, { data: officeData, error: officeError }] = useLazyQuery(
    AdminQueries.GET_OFFICES
  )
  // Query for uses by county. Fires off after supervisor role is selected.
  const [
    queryUsersByCounty,
    { data: usersData, error: usersError },
  ] = useLazyQuery(AdminQueries.USERS_BY_COUNTY)

  // Handler for making the supervisor and region queries.
  const querySupervisorsRegionAndUsers = useCallback(
    organization => {
      if (organization) {
        querySupervisors({
          variables: { id: organization },
          fetchPolicy: 'network-only',
        })
        queryRegions({
          variables: { id: organization },
          fetchPolicy: 'network-only',
        })
        queryUsersByCounty({
          variables: { orgLevelCode: organization },
          fetchPolicy: 'network-only',
        })
      }
    },
    [queryRegions, querySupervisors, queryUsersByCounty]
  )

  const queryOfficesCallback = useCallback(
    region => {
      if (region) {
        queryOffices({
          variables: { id: region },
          fetchPolicy: 'network-only',
        })
      }
    },
    [queryOffices]
  )

  // Query for the counties associated with that organization level.
  // Fires off immediately.
  const { data: orgLevelData, error: orgLevelError } = useQuery(
    AdminQueries.GET_COUNTIES,
    {
      variables: { id: orgLevelCode },
      fetchPolicy: 'network-only',
    }
  )

  useQuery(AdminQueries.GET_SECTION_MEMBERS, {
    skip: role !== 'SP',
    variables: { id },
    fetchPolicy: 'network-only',
    onError: error => {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    },
    onCompleted: data => {
      const sectionMemberIds = data?.getSectionMembers.map(sectionMember => ({
        label: `${sectionMember.firstName} ${sectionMember.lastName}`,
        value: sectionMember.id,
      }))
      dispatch(setNewUser({ field: 'sectionMembers', value: sectionMemberIds }))
    },
  })

  useEffect(() => {
    querySupervisorsRegionAndUsers(organization)
    queryOfficesCallback(region)
  }, [
    querySupervisorsRegionAndUsers,
    queryOfficesCallback,
    dispatch,
    region,
    organization,
  ])

  // Filter the options for the dropdowns/typeahead.
  const [orgLevelOptions, setOrgLevelOptions] = useState([])
  const [usersOptions, setUsersOptions] = useState([])
  const [supervisorOptions, setSupervisorOptions] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [officeOptions, setOfficeOptions] = useState([])
  useEffect(() => {
    setOrgLevelOptions(
      filterData(
        orgLevelData,
        orgLevelError,
        'getCountyByOrgLevelCode',
        'dropdown',
        dispatch
      )
    )
  }, [orgLevelData, orgLevelError, dispatch])

  useEffect(() => {
    setUsersOptions(
      filterData(usersData, usersError, 'usersByCounty', 'typeahead', dispatch).filter(user => {
        // Do not offer the option to set the currently edited user as their own section member
        return user.value !== id
      })
    )
  }, [id, usersData, usersError, dispatch])

  useEffect(() => {
    setSupervisorOptions(
      filterData(data, error, 'supervisorByCounty', 'typeahead', dispatch).filter(supervisor => {
        // Do not offer the option to set the currently edited user as their own supervisor
        return supervisor.value !== id
      })
    )
  }, [id, data, error, dispatch])

  useEffect(() => {
    setRegionOptions(
      filterData(
        regionData,
        regionError,
        'getRegionsByOrganizationLevelID',
        'dropdown',
        dispatch
      )
    )
  }, [regionData, regionError, dispatch])

  const handleChange = (value) => {
    if (value === 'BRE') {
      dispatch(
      openDialog({
        type: 'information',
        title: `Attention`,
        description: 'This role is restricted to Consortium staff. Once this profile is saved, submit a request to the CalSAWS Help Desk Team to complete registration and enable the user’s access.',
      })
      )
    }
  }

  useEffect(() => {
    setOfficeOptions(
      filterData(
        officeData,
        officeError,
        'getOfficesByRegionID',
        'dropdown',
        dispatch
      )
    )
  }, [officeData, officeError, dispatch])

  const { isStatewide } = useSelector(state => state.user)

  const roleOptions = [
    { name: 'Case Worker', id: 'CW' },
    { name: 'Supervisor', id: 'SP' },
    { name: 'Administrator', id: 'AD' },
    { name: 'Executive', id: 'EX' },
    { name: 'QA', id: 'QA' },
  ]
  if (isStatewide) {
    roleOptions.push({name: 'Business Rules Editor', id: 'BRE'})
  }
  return (
    <Can
      roles={roles}
      perform='user:edit'
      yes={() => {
        return (
          <Grid container spacing={2}>
            <PageTitleContainer>
              <Typography variant='h3'>OCAT Administration</Typography>
              <div>
                <AdminSaveButton isNewUser={false} />
                <AdminCancelButton isNewUser={false} />
              </div>
            </PageTitleContainer>
            <Grid item xs={12}>
              <SectionTitle variant='h4'>Edit User</SectionTitle>
            </Grid>
            <Grid item xs={12}>
              <Typography display='block' component='span'>
                Enter user's information in the following fields. *Required
                Fields
              </Typography>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminText
                  label='First Name*'
                  field='firstName'
                  isRequired={true}
                ></AdminText>
                <AdminText
                  label='Last Name*'
                  field='lastName'
                  isRequired={true}
                ></AdminText>
                <AdminText
                  label='Email*'
                  field='email'
                  isEdit={true}
                  validateEmail
                  isRequired={true}
                ></AdminText>
              </GridContainer>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminDropdown
                  label={'Role*'}
                  field='role'
                  values={roleOptions}
                  onChange={value => handleChange(value)}
                  isRequired={true}
                ></AdminDropdown>
                <AdminDropdown
                  label={'Secondary Role'}
                  field='secondaryRole'
                  values={[{ name: 'Administrator', id: 'AD' }]}
                  disabled={shouldDisableSecondaryRole}
                ></AdminDropdown>
                <AdminDropdown
                  query={querySupervisorsRegionAndUsers}
                  label={'Organization*'}
                  field='organization'
                  values={orgLevelOptions}
                  isRequired={true}
                  disabled={orgLevelCode !== '00' || breRolePicked}
                />
              </GridContainer>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminDropdown
                  query={queryOfficesCallback}
                  disabled={!organization}
                  label={'Region'}
                  field='region'
                  values={regionOptions || []}
                ></AdminDropdown>
                <AdminDropdown
                  disabled={!region}
                  label={'Office'}
                  field='office'
                  values={officeOptions || []}
                ></AdminDropdown>
                <AdminDropdown
                  label={'Database Extract'}
                  field='dataExtract'
                  showNoneSelection={false}
                  disabled={breRolePicked}
                  values={breRolePicked 
                    ? [{ name: 'No', id: 0 }] 
                    : [
                      { name: 'No', id: 0 },
                      { name: 'Yes', id: 1 },
                    ]
                  }
                ></AdminDropdown>
              </GridContainer>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminDropdown
                  label={'Status*'}
                  isRequired={true}
                  field='status'
                  values={[
                    { name: 'Active', id: 'Active' },
                    { name: 'Inactive', id: 'Inactive' },
                  ]}
                ></AdminDropdown>
                <AdminDatePicker
                  label={'Inactive Date'}
                  field='disabledDate'
                  isDisabled={
                    !status ||
                    status?.toLowerCase() === 'active' ||
                    status?.toLowerCase() !== 'inactive'
                  }
                ></AdminDatePicker>
              </GridContainer>
              <SectionTitle variant='h6'>Supervisor</SectionTitle>
              <GridContainer container justifyContent='flex-start' spacing={2}>
                <AdminSelect
                  field={'supervisor'}
                  options={supervisorOptions || []}
                  selectOpts={{ isClearable: true, isDisabled: !organization }}
                />
              </GridContainer>
              <SectionTitle variant='h6'>Section Members</SectionTitle>
              <AdminTable
                headers={['Name']}
                isDisabled={role !== 'SP'}
                inputs={[
                  {
                    type: 'select',
                    field: 'sectionMembers',
                    options: usersOptions || [],
                    selectOpts: { isClearable: true, isDisabled: false },
                  },
                ]}
              ></AdminTable>
            </Grid>
          </Grid>
        )
      }}
    />
  )
})

export default AdminEdit
