import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { setNewUser } from 'ducks/adminSlice'

const InputContainer = styled.div`
  margin-left: 8px;
  display: inline-block;
`

const CustomTextField = styled(TextField)`
  && {
    width: ${p => p.width};
    margin-top: ${p => (p.label ? '20px' : '3px')};
    > input: {
      color: white;
    }
    margin-right: 20px;
  }
`

const AdminTableText = React.memo(
  ({ label, field, width = '250px', index }) => {
    const dispatch = useDispatch()
    const values = useSelector(state => state.admin[field])
    const handleChange = event => {
      dispatch(
        setNewUser({
          field: field,
          index,
          value: event.target.value,
          table: true,
        })
      )
    }
    return (
      <InputContainer>
        <CustomTextField
          inputProps={{ 'aria-label': { label } }}
          label={label}
          variant='outlined'
          value={values[index]}
          type='text'
          onChange={handleChange}
          width={width}
          autoComplete='off'
        />
      </InputContainer>
    )
  }
)

export default AdminTableText
