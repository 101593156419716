import React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import { colors } from 'app/theme'

const CustomDropdown = styled(Select)`
  && {
    width: ${p => p.width};
    margin-right: 40px;
  }
`

const NoSelection = styled(MenuItem)`
  && {
    color: ${colors.grey};
  }
`

const SearchDropdown = React.memo(
  ({
    label,
    value,
    width = '250px',
    handleChange,
    options,
    dropdownProps = {},
    errorText = null,
  }) => {
    const [labelWidth, setLabelWidth] = React.useState(0)
    const inputLabel = React.useRef(null)
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth)
    }, [])

    // Create dropdown options
    const controlOptions = options.map(({ optionId, optionLabel }) => (
      <MenuItem
        disableRipple
        disableTouchRipple
        value={optionId}
        key={optionId}
      >
        {optionLabel}
      </MenuItem>
    ))

    const parsedLabel = label?.replace(' *', '')

    return (
      <div>
        <FormControl variant='outlined'>
          <InputLabel ref={inputLabel} id={label} error={dropdownProps?.error}>
            {label}
          </InputLabel>
          <CustomDropdown
            {...dropdownProps}
            width={width}
            labelId={`Dropdown-${parsedLabel}`}
            id={`Select-${parsedLabel}`}
            value={value}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            <NoSelection
              component='em'
              disableRipple
              disableTouchRipple
              value={null}
            >
              None
            </NoSelection>
            {controlOptions}
          </CustomDropdown>
          {errorText && <FormHelperText error>{errorText}</FormHelperText>}
        </FormControl>
      </div>
    )
  }
)

export default SearchDropdown
