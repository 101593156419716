import React from 'react'
import RecommendationsPage from 'components/recommendation/RecommendationsPage'

const RecommendationsBarrier = React.memo(({ isPreview }) => {
  return (
    <RecommendationsPage
      domainCode='BR'
      domain='Recommendations'
      subdomain='Recommendations (Work, Education, and/or Barrier Removal)'
      isPreview={isPreview}
    />
  )
})

export default RecommendationsBarrier
