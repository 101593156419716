import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TableCell from '@material-ui/core/TableCell'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

import ControlDescription from 'components/general/ControlDescription'

import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import { decodeHtml } from 'utilTools/decodeHtml'

// Data Selector function to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
//
// To have createSelector to be "shared" across multiple instances
// of a component, we must create a function to return a selector
// for each instance of a component.
// https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances

const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (index, sub_question_id, isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty string)
      tableAnswers: data.answer || [],
      label: data.sub_question_ids[sub_question_id]?.label || '',
      description: data.sub_question_ids[sub_question_id]?.description || '',
      subQuestionAnswer:
        data.answer && index >= 0 && data.answer[index][sub_question_id]
          ? data.answer[index][sub_question_id]
          : '',
      // This is a sanity check just in case there are duplicates
      options: uniqBy(
        data.sub_question_ids[sub_question_id].options,
        'optionId'
      ),
    }
  })
}

const InfiniteSelect = React.memo(
  ({
    radioGroupOptions = {},
    index,
    isDisabled,
    hideDescription = true,
    hideLabel = true,
    question_id,
    sub_question_id,
    noTable = false,
    className,
    isPreview
  }) => {
    // Validation/data selectors and dispatch
    const dispatch = useDispatch()
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )
    const dataSelector = createDataSelector(index, sub_question_id, isPreview)
    const {
      tableAnswers,
      subQuestionAnswer,
      options,
      label,
      description,
    } = useSelector(state => dataSelector(state, question_id))

    // Input Handlers
    const handleChange = e => {
      if (cancelledOrCompletedInterview) return
      let updatedTableAnswers = tableAnswers.map(answer => {
        const rowAnswers = {}
        Object.keys(answer).forEach(
          sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
        )
        return rowAnswers
      })
      updatedTableAnswers[index][sub_question_id] = e.target.value
      dispatch(isPreview
        ? capturePreviewFormData({ question_id, answer: updatedTableAnswers })
        : captureFormData({ question_id, answer: updatedTableAnswers }))
    }
    const handleClick = e => {
      if (cancelledOrCompletedInterview) return
      if (subQuestionAnswer === e.target.value) {
        let updatedTableAnswers = tableAnswers.map(answer => {
          const rowAnswers = {}
          Object.keys(answer).forEach(
            sub_que_id => (rowAnswers[sub_que_id] = answer[sub_que_id])
          )
          return rowAnswers
        })
        updatedTableAnswers[index][sub_question_id] = null
        dispatch(isPreview
          ? capturePreviewFormData({ question_id, answer: updatedTableAnswers })
          : captureFormData({ question_id, answer: updatedTableAnswers }))
      }
    }

    // ADA requires enter key accessibility
    const handleKeyPress = e => {
      const keyCode = e.which || e.keyCode
      const enterKeyCode = 13
      if (keyCode === enterKeyCode) {
        if (subQuestionAnswer === e.target.value) {
          handleClick(e)
        } else {
          handleChange(e)
        }
      }
    }

    // Label for ADA
    const labelid = `${question_id}-${sub_question_id}-label-${index}`

    // Create radio button options
    const controlOptions = options
      .sort((a, b) => a.order - b.order)
      .map(({ optionId, optionLabel }) => (
        <Can
          key={optionId}
          roles={roles}
          perform={isPreview ? 'BRE:view' : 'interview:edit'}
          no={() => {
            return (
              <FormControlLabel
                disabled={true}
                value={optionId}
                control={<Radio />}
                label={decodeHtml(optionLabel)}
                key={optionId}
              />
            )
          }}
          yes={() => {
            return (
              <FormControlLabel
                disabled={cancelledOrCompletedInterview || isDisabled}
                value={optionId}
                control={<Radio />}
                label={decodeHtml(optionLabel)}
                key={optionId}
              />
            )
          }}
        />
      ))

    const radioGroup = (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                {!hideLabel && (
                  <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-label={`Radio group ${labelid}`}
                  name={labelid}
                  value={subQuestionAnswer}
                  {...radioGroupOptions}
                >
                  {controlOptions}
                </RadioGroup>
              </FormControl>
            </div>
          )
        }}
        yes={() => {
          return (
            <div className={className}>
              {!hideDescription && description && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <FormControl component='fieldset'>
                {!hideLabel && (
                  <FormLabel component='legend'>{decodeHtml(label)}</FormLabel>
                )}
                <RadioGroup
                  row
                  aria-label={`Radio group ${labelid}`}
                  name={labelid}
                  value={subQuestionAnswer}
                  onChange={handleChange}
                  onClick={handleClick}
                  onKeyPress={handleKeyPress}
                  {...radioGroupOptions}
                >
                  {controlOptions}
                </RadioGroup>
              </FormControl>
            </div>
          )
        }}
      />
    )

    if (noTable) {
      return radioGroup
    }
    return <TableCell align='center'>{radioGroup}</TableCell>
  }
)

export default InfiniteSelect
