import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/Info'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import { closeDialog } from 'ducks/dialogSlice'
import {
  setPrepopulateInterview,
  clearNewInterviewSlice,
} from 'ducks/newInterviewSlice'
import { colors } from 'app/theme'

import { useHistory } from 'react-router-dom'
import { createNewAppraisal } from 'thunks/createNewAppraisal'
import { handleExistingInterview } from 'thunks/handleExistingInterview'

//                            Styled Components
const Container = styled.div`
  margin: 28px 0 20px 0;
  display: flex;
  align-items: center;
`

const StyledIcon = styled(InfoIcon)`
  && {
    color: ${colors.saffron};
    margin-right: 6px;
  }
`

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`

//                           State Selector
const newInterviewState = state => state.newInterview

//                            Dialog Content
export const NewAppraisalPopulateDialogContent = ({ rowData = {} }) => {
  const dispatch = useDispatch()
  const isInterfaceInterview = rowData?.isInterfaceInterview ?? false
  const { prepopulateInterview } = useSelector(newInterviewState)

  const options = [
    { value: 'blank', label: 'Start blank appraisal' },
    { value: 'populate', label: 'Populate from prior appraisal' },
  ].map(({ value, label }) => (
    <FormControlLabel
      key={value}
      value={value}
      control={<Radio />}
      label={label}
    />
  ))

  const handleClick = e => {
    const { value } = e.target
    prepopulateInterview === value && dispatch(setPrepopulateInterview(null))
  }
  const handleChange = e => {
    dispatch(setPrepopulateInterview(e.target.value))
  }
  return (
    <>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='prepopulate-interview-options'
          name='prepopulate-options'
          value={prepopulateInterview}
          onChange={handleChange}
          onClick={handleClick}
        >
          {options}
        </RadioGroup>
      </FormControl>
      {!isInterfaceInterview && (
        <Container>
          <StyledIcon />
          <Typography>
            <strong>This is not a linked record.</strong>
          </Typography>
        </Container>
      )}
    </>
  )
}

//                            Dialog Actions
export const NewAppraisalPopulateDialogActions = ({
  caseNumber,
  caseID,
  caseCounty,
  isNewCaseNumber,
  isExistingInterview = false,
  rowData = {},
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { prepopulateInterview } = useSelector(newInterviewState)
  const continueButtonAction = isExistingInterview
    ? () => dispatch(handleExistingInterview(rowData))
    : () =>
        dispatch(
          createNewAppraisal({
            history,
            caseID,
            caseNumber,
            caseCounty,
            isNewCaseNumber,
            prepopulateInterview,
          })
        )

  const ContinueButton = () => (
    <Button
      disabled={!prepopulateInterview}
      variant='contained'
      color='primary'
    >
      Continue
    </Button>
  )

  // Event handlers
  const handleClose = () => {
    dispatch(closeDialog())
    dispatch(clearNewInterviewSlice())
  }
  const handleClick = async () => {
    await continueButtonAction()
    handleClose()
  }

  return (
    <>
      <Button variant='contained' color='secondary' onClick={handleClose}>
        Cancel
      </Button>
      {prepopulateInterview ? (
        <StyledLink
          aria-label={`Link to interview page`}
          to={`/interview/demographics_general_information`}
          onClick={handleClick}
        >
          <ContinueButton />
        </StyledLink>
      ) : (
        <ContinueButton />
      )}
    </>
  )
}
