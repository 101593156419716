import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { KeyboardDatePicker as Picker } from '@material-ui/pickers'
import { createSelector } from '@reduxjs/toolkit'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import ControlDescription from 'components/general/ControlDescription'
import Indicator from 'components/general/Indicator'
import { isValid, format, parse } from 'date-fns'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { decodeHtml } from 'utilTools/decodeHtml'

const CustomDatePicker = styled(Picker)`
  && {
    margin-top: 20px;
    margin-right: 20px;
    width: 250px;
  }
`

const Container = styled.div`
  padding-left: ${p => p.subquestion && '30px'};
`
// Data selector to select minimum amount of data required.
// createSelector caches and memoizes this data for us.
// These need to be defined outside of the React component
// so that they are not re-created on each re-render.
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const validationSelector = (state, question_id) =>
  state.validationErrors[question_id]
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      description: data.description,
      label: data.label,
      // Don't rely on the answer, convert to appropriate
      // 'falsy' value for this input (empty string)
      indicator: data.indicator,
      answer: data.answer ? data.answer : '',
    }
  })
}

const createValidationSelector = () => {
  return createSelector(validationSelector, validation => {
    return {
      hasValidationError: validation ? true : false,
      validationError: validation ? validation : null,
    }
  })
}

// The "datePickerOptions" prop is used to pass props to the date picker
// component from material-picker programatically. Look to material-picker
// docs for additional props if needed:

// https://material-ui-pickers.dev/api/KeyboardDatePicker
const DatePicker = React.memo(
  ({
    question_id,
    isDisabled,
    datePickerOptions = { disableFuture: true, format: 'MM/dd/yyyy' },
    subquestion,
    sawsIndicator,
    indicatorStyle,
    isPreview
  }) => {
    const dispatch = useDispatch()

    // Fetch data from redux store using selector
    const dataSelector = createDataSelector(isPreview)
    const validationSelector = createValidationSelector()
    const { answer, description, label, indicator } = useSelector(state =>
      dataSelector(state, question_id)
    )
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )

    const hasIndicator = sawsIndicator ? indicator : 'noIndicator'

    const { hasValidationError, validationError } = useSelector(state =>
      validationSelector(state, question_id)
    )

    // second parameter to captureFormData is true because this needs to update
    // client data from the general information page
    const sendData = data => {
      dispatch(
        isPreview
        ? capturePreviewFormData({
          question_id: question_id,
          answer: data,
        })
        : captureFormData({
          question_id: question_id,
          answer: data,
        })
      )
    }

    // Label for ADA
    const inputId = `${question_id}-input`

    const handleChange = (date, value) => {
      if (cancelledOrCompletedInterview) return
      // If it's a valid date, we send the date formatted "MM/dd/yyyy"
      if (isValid(date)) {
        sendData(format(date, 'MM/dd/yyyy'))
      } else if (value === null) {
        sendData('')
        // Else just send the value
      } else {
        sendData(value)
      }
    }

    const parsedAnswer = parse(answer, 'MM/dd/yyyy', new Date())

    return (
      <Can
        roles={roles}
        perform={isPreview ? 'BRE:view' : 'interview:edit'}
        no={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && (
                <ControlDescription disabled={true}>
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomDatePicker
                KeyboardButtonProps={{
                  'aria-label': `Button of the date picker ${label} ${inputId}`,
                }}
                disabled={true}
                id={inputId}
                value={null}
                label={decodeHtml(label)}
                variant='inline'
                inputVariant='outlined'
                inputValue={
                  isValid(parsedAnswer)
                    ? format(parsedAnswer, datePickerOptions.format)
                    : answer
                }
                helperText={validationError}
                error={hasValidationError}
                maxDateMessage={null}
                minDateMessage={null}
                {...datePickerOptions}
              />
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={indicatorStyle} />
              )}
            </Container>
          )
        }}
        yes={() => {
          return (
            <Container subquestion={subquestion ? 1 : 0}>
              {description && (
                <ControlDescription
                  disabled={cancelledOrCompletedInterview || isDisabled}
                >
                  {decodeHtml(description)}
                </ControlDescription>
              )}
              <CustomDatePicker
                KeyboardButtonProps={{
                  'aria-label': `Button of the date picker ${label} ${inputId}`,
                }}
                disabled={cancelledOrCompletedInterview || isDisabled}
                id={inputId}
                value={null}
                label={decodeHtml(label)}
                variant='inline'
                inputVariant='outlined'
                inputValue={
                  isValid(parsedAnswer)
                    ? format(parsedAnswer, datePickerOptions.format)
                    : answer
                }
                helperText={validationError}
                onChange={(date, value) => handleChange(date, value)}
                error={hasValidationError}
                maxDateMessage={null}
                minDateMessage={null}
                {...datePickerOptions}
              />
              {sawsIndicator && (
                <Indicator indicator={hasIndicator} style={indicatorStyle} />
              )}
            </Container>
          )
        }}
      />
    )
  }
)

export default DatePicker
