import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { setClient } from 'ducks/clientSlice'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'app/theme'
import { decodeHtml } from '../../utilTools/decodeHtml'
import validation from 'validation/specialCharacterValidator'

// ADA required styling change, use Material-UI default styling method for active and focus behaviors
const StyledHeader = withStyles({
  root: {
    color: colors.turquoise,
    fontSize: '16px',
    '&:hover': {
      '& svg': {
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    '&:focus': {
      outline: `1px solid ${colors.saffron}`,
    },
  },
  active: {
    color: `${colors.turquoise}!important`,
  },
})(TableSortLabel)

// ADA required styling change, use Material-UI default styling method for selected behaviors
const StyledRow = withStyles({
  root: {
    '&:hover': {
      outline: `1px solid ${colors.saffron}`,
      outlineOffset: '-1px',
    },
  },
  selected: {
    outline: `1px solid ${colors.saffron}`,
    backgroundColor: `${colors.white} !important`,
  },
})(TableRow)

const StyledLink = styled(Link)`
  && {
    color: ${colors.black};
  }
  &:visited {
    color: ${colors.black};
  }
  &:focus {
    outline: 1px solid ${colors.saffron};
  }
  &:active {
    outline: 0;
  }
`

const clientListHeadCellsBase = [
  {
    label: 'Name',
    id: 'name',
    type: 'text',
  },
  {
    label: 'DOB',
    id: 'dob',
    type: 'date',
  },
  {
    label: 'SSN',
    id: 'ssn',
    type: 'text',
  },
  {
    label: 'CIN',
    id: 'cin',
    type: 'text',
  },
  {
    label: 'SAWS Case#',
    id: 'sawsCase',
    type: 'text',
  },
  {
    label: 'County',
    id: 'county',
    type: 'text',
  },
  {
    label: 'Date Initiated',
    id: 'update',
    type: 'date',
  },
  {
    label: 'Appraisal Status',
    id: 'asrStatus',
    type: 'text',
  },
  {
    label: 'Interviewer',
    id: 'interviewer',
    type: 'text',
  },
  {
    label: 'Source',
    id: 'source',
    type: 'text',
  },
]

const tableStructureGenerator = (
  includeSource,
  includeGender,
  headCellsBase
) => {
  let resultsCells = [...headCellsBase]

  if (!includeSource) {
    // remove the source column
    const sourceColIndex = resultsCells.findIndex(col => {
      return col.id === 'source'
    })
    resultsCells.splice(sourceColIndex, 1)
  }

  if (includeGender) {
    const ssnColIndex = resultsCells.findIndex(col => {
      return col.id === 'ssn'
    })
    resultsCells.splice(ssnColIndex, 0, {
      // add gender column before ssn column
      label: 'Gender',
      id: 'gender',
    })
  }
  return resultsCells
}

// Create the table header
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, clientListHeadCells } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {clientListHeadCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledHeader
              data-testid={`ClientListTable-sortby-${headCell.id}`}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </StyledHeader>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

// A custom sort for the first row of the table accepting text input
function TableFilterRow({
  inputFilters,
  handleInputFilters,
  clientListHeadCells,
}) {
  // if we recieve a set of filter values then we need to treat the input as a controlled input
  // otherwise let the DOM maintain the input value state. This behavior is handled by React
  // based on whether the value prop is set. Controlled behavior is desired for the Client List
  // but the Client Search also uses the table and needs it to behave uncontrolled. This should
  // be changes on the Client Search to make it consistant
  const isInputControlled = !!inputFilters

  const handleChange = (e, cell) => {
    const rowId = cell.id
    const callback = validation.getValidationCallBack(cell.type)
    const isInvalidInput = callback(e.target.value)
    if (!isInvalidInput) {
      handleInputFilters(rowId, e.target.value.toLowerCase())
    }
  }
  return (
    <TableRow>
      {clientListHeadCells.map((headCell, i) => {
        // if the value is meant to be controlled set it to the value passed in or default to empty string
        // otherwise set it to undefined
        const inputValue = isInputControlled
          ? inputFilters[headCell.id] || ''
          : undefined

        return (
          <TableCell key={`${headCell}-${i}`}>
            <TextField
              inputProps={{
                'aria-label': `Table filter ${headCell}-${i}`,
                'data-testid': `ClientListTable-filter-input-${headCell.id}`,
              }}
              onChange={e => handleChange(e, headCell)}
              placeholder='Filter'
              autoComplete='off'
              value={inputValue}
            />
          </TableCell>
        )
      })}
    </TableRow>
  )
}

// ClientListTable does pagination locally (no server pagination)
export default function ClientListTable({
  tableData,
  inputFilters,
  handleInputFilters,
  handlePage,
  handleRowsPerPage,
  page,
  rowsPerPage,
  totalRecordCount,
  selectedRowIndex,
  handleSelectedRowIndexChange,
  sort,
  handleSortChange,
  displaySource,
  displayGender,
}) {
  const dispatch = useDispatch()

  // Given that we now keep search results after page navigation, make sure
  // that the selected row is reset on first render
  useEffect(() => {
    handleSelectedRowIndexChange(null)
  }, [handleSelectedRowIndexChange])

  const { order, orderBy } = sort

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    handleSortChange({ order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const handleChangePage = (event, newPage) => {
    handlePage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    handleRowsPerPage(parseInt(event.target.value, 10))
    handlePage(0)
  }

  // Set client information that doesn't depend on individual
  // interview info in redux
  const handleClick = row => {
    dispatch(
      setClient({
        id: row.clientId,
        firstName: row.firstName,
        lastName: row.lastName,
        cin: row.cin,
        dob: row.dob,
        caseNumber: row.sawsCase,
      })
    )
  }

  // ADA requires selecting the tableRow when any element in this row is selected
  const handleTableRowFocus = index => {
    selectedRowIndex !== index && handleSelectedRowIndexChange(index)
  }
  const handleTableRowBlur = () => {
    selectedRowIndex !== null && handleSelectedRowIndexChange(null)
  }

  const clientListHeadCells = tableStructureGenerator(
    displaySource,
    displayGender,
    clientListHeadCellsBase
  )

  const sortedData = tableData
  const sortedDataPage = sortedData

  return (
    <Paper>
      <TableContainer>
        <Table data-testid='ClientListTable' aria-label='client list table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
            clientListHeadCells={clientListHeadCells}
          />
          <TableBody>
            <TableFilterRow
              inputFilters={inputFilters}
              handleInputFilters={handleInputFilters}
              clientListHeadCells={clientListHeadCells}
            />
            {sortedDataPage.map((row, index) => {
              return (
                <StyledRow
                  className='disabled'
                  key={`${row.firstName} ${row.lastName}-${index}`}
                  selected={selectedRowIndex === index}
                  tabIndex={`row-${index}`}
                  onFocus={() => handleTableRowFocus(index)}
                  onBlur={handleTableRowBlur}
                >
                  <TableCell
                    data-testid={`ClientListTable-cell-${row.firstName} ${row.lastName}-${index}`}
                  >
                    <StyledLink
                      to={`/interview_list`}
                      onClick={() => handleClick(row)}
                    >
                      {decodeHtml(row.firstName + ' ' + row.lastName)}
                    </StyledLink>
                  </TableCell>
                  <TableCell>{row.dob}</TableCell>
                  {displayGender && <TableCell>{row.gender}</TableCell>}
                  <TableCell>{row.ssn}</TableCell>
                  <TableCell>{row.cin}</TableCell>
                  <TableCell>{row.sawsCase}</TableCell>
                  <TableCell>{row.county}</TableCell>
                  <TableCell>{row.update}</TableCell>
                  <TableCell>{row.asrStatus}</TableCell>
                  <TableCell>{decodeHtml(row.interviewer)}</TableCell>
                  {displaySource && <TableCell>{row.source}</TableCell>}
                </StyledRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100, 250, 500, 1000]}
        component='div'
        count={totalRecordCount}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage=''
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
