import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (question_id === 'no_answer_emo_mental_health' && answer) {
    effectCollector.add('emptional_mental_health_issues', null)
    effectCollector.add('diagnosed_emotional_mental_health', null)
  }

  return effectCollector.effects
}

export default effects;