import React from 'react'
import Grid from '@material-ui/core/Grid'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const generalHealthChallengeSelector = state =>
  state.interview.general_health_challenge.answer

const previewGeneralHealthChallengeSelector = state =>
  state.preview.general_health_challenge.answer

const healthChallengeWTWSelector = state =>
  state.interview.health_challenge_wtw_activities.answer

const previewHealthChallengeWTWSelector = state =>
  state.preview.health_challenge_wtw_activities.answer

const selectGeneralHealthChallenge = createSelector(
  generalHealthChallengeSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectPreviewGeneralHealthChallenge = createSelector(
  previewGeneralHealthChallengeSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectHealthChallengeWTWActivities = createSelector(
  healthChallengeWTWSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const selectPreviewHealthChallengeWTWActivities = createSelector(
  previewHealthChallengeWTWSelector,
  answer => !answer || answer.toUpperCase() === 'NO'
)

const GeneralHealthIndicator = React.memo(({ isPreview }) => {
  const isGeneralHealthChallenge = useSelector(isPreview ? selectPreviewGeneralHealthChallenge : selectGeneralHealthChallenge)
  const hasHealthChallengeWTWActivities = useSelector(
    isPreview ? selectPreviewHealthChallengeWTWActivities : selectHealthChallengeWTWActivities
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='General Health'
        />
        <form>
          <Select question_id='general_health_challenge' isPreview={isPreview} />
          <Grid item xs={12}>
            <Select
              question_id='health_challenge_wtw_activities'
              isDisabled={isGeneralHealthChallenge}
              subquestion
              isPreview={isPreview}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              aria-label='General health explanation'
              question_id='general_health_explain'
              multiline
              rows='4'
              isDisabled={hasHealthChallengeWTWActivities}
              shouldDecode={true}
              subquestion
              isPreview={isPreview}
            />
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
})
export default GeneralHealthIndicator
