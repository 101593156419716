import { gql } from 'apollo-boost'
import client from 'apollo/Apollo'
import { openDialog } from 'ducks/dialogSlice'
import {
  setUpcomingChangeDate
} from 'ducks/pendingChangesSlice'

const UPCOMING_CHANGE_DATE = gql`
query upcomingChangeDate {
  upcomingChangeDate
}
`

export const refreshUpcomingChangeDate = () => {
  return async (dispatch, getState) => {
    try {
      const upcomingChangeDateData = await client.query({
        query: UPCOMING_CHANGE_DATE,
        fetchPolicy: 'network-only',
      })

      dispatch(setUpcomingChangeDate(upcomingChangeDateData.data))
    } catch (error) {
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportError', error)
      // If server error, show error dialog
      dispatch(
        openDialog({
          type: 'error',
          title: `System Error`,
          props: {
            error,
          },
        })
      )
    }
  }
}
