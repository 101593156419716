import EffectCollector from 'side-effects/effectCollector'
import store from 'app/store'
import isEqual from 'lodash/isEqual'

const useSameValuesHelper = (card, answer, index) => {
  const allKeysFromPrevRow = Object.keys(answer[index - 1])
  const allKeysInCurrentRow = Object.keys(card)
  // Copy everything over
  // Get all keys from the previous row and all keys in the current row

  // Delete all answers in the current row barring name, id, relationship, and
  // the use_same_values checkbox.
  allKeysInCurrentRow.forEach(key => {
    if (
      key === 'pcs_name' ||
      key === 'pcs_relationship' ||
      key === 'use_same_values' ||
      key === 'id'
    ) {
      return
    } else {
      delete card[key]
    }
  })
  // Copy the previous row's answers to this row.
  allKeysFromPrevRow.forEach(key => {
    if (
      key === 'pcs_name' ||
      key === 'pcs_relationship' ||
      key === 'use_same_values' ||
      key === 'id'
    ) {
      return
    } else {
      card[key] = answer[index - 1][key]
    }
  })
}

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'paying_child_support' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('how_much_child_support', null)
    effectCollector.add('chld_spp_formal_infrml', null)
    effectCollector.add('chld_spp_wrttn_verbal', null)
    effectCollector.add('help_with_modifying_child_support', null)
  }

  if (
    question_id === 'paying_child_support' &&
    (answer?.toUpperCase() === 'YS' || !answer)
  ) {
    effectCollector.add('responsible_child_support', null)
    effectCollector.add('not_paying_child_spp_explain', null)
    effectCollector.add('not_paying_child_support_explain', null)
  }

  if (
    question_id === 'not_paying_child_spp_explain' &&
    !answer?.includes('OT')
  ) {
    effectCollector.add('not_paying_child_support_explain', null)
  }
  const stateProp = isPreview ? 'preview' : 'interview'
  if (question_id === 'parenting_and_child_support_table' && answer) {
    const currentTableState = store.getState()[stateProp]
      .parenting_and_child_support_table.answer
    answer.forEach((card, index) => {
      // Conditional check involving the "Use Same Values" Checkbox.
      // *************************************************************************
      // Check if use_same_values exists
      const useSameValueExists =
        card.use_same_values && currentTableState[index].use_same_values
      // And if it does exist check if their lengths are the same.
      const areValuesEqual =
        useSameValueExists &&
        isEqual(card.use_same_values, currentTableState[index].use_same_values)

      // Check if the card is not the first card and check if anything in the card
      // has changed.
      if (index && isEqual(card, currentTableState[index])) {
        // If nothing has changed and the "Use same values" checkbox was selected,
        // copy over the answer.
        if (
          card.use_same_values &&
          card.use_same_values.includes('SV')
          // !isEqual(currentCard, prevCard)
        ) {
          useSameValuesHelper(card, answer, index)
        }
        // Check if the card is not the first card and check if the change in the row was
        // the "use same values" checkbox.
      } else if (index && useSameValueExists && !areValuesEqual) {
        // If the use same values checkbox was set, copy over answer.
        if (card.use_same_values.includes('SV')) {
          useSameValuesHelper(card, answer, index)
        }
        // Check if card is not the first card,
      } else if (index && useSameValueExists && areValuesEqual) {
        if (card.use_same_values.includes('SV')) {
          card.use_same_values = []
        }
      }
      // *************************************************************************
      // Other Conditionals for the cards.
      if (
        card.parental_involvement_custody &&
        !card.parental_involvement_custody.includes('AN')
      ) {
        delete card.custody_other_name
      }
      if (card.share_responsibility === null) {
        delete card.equal_responsibility
        delete card.other_parent_role
      }
      if (
        card.share_responsibility &&
        card.share_responsibility.toUpperCase() !== 'YS'
      ) {
        delete card.equal_responsibility
      }
      if (
        card.share_responsibility &&
        card.share_responsibility.toUpperCase() !== 'NO'
      ) {
        delete card.other_parent_role
      }
      if (
        card.rate_relationship_other_parent &&
        !card.rate_relationship_other_parent.includes('OT')
      ) {
        delete card.rate_relationship_other_parent_explain
      }
      return
    })
  }

  return effectCollector.effects
}

export default effects;