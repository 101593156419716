import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import idleTimer from './idleTimer'
import mockState from '../sample-json/state.json'
// Disable serializable and immutable checks due to
// poor performance in development mode. This is
// due to the app's large state. They don't affect
// the production build at all, and aren't critical.
const customMiddleware = [
  ...getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
    immutableCheck: false,
  }),
  idleTimer,
]

export const mockStore = configureStore({
  reducer: rootReducer,
  middleware: customMiddleware,
  preloadedState: JSON.parse(mockState.preloadedState),
  devTools: process.env.NODE_ENV !== 'production',
})

const store = configureStore({
  reducer: rootReducer,
  middleware: customMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
})

// Hot module replacement for the Redux store. Enables changes
// to actions and reducers without triggering a refresh in
// the browser.
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export default store
