import store from 'app/store.js'

const AUTH_COOKIE_LIMETIME_IN_SECONDS = 60

export const setFileDownloadAuthCookie = () => {
  const token = store.getState().user.token
  const date = new Date()
  date.setTime(date.getTime() + AUTH_COOKIE_LIMETIME_IN_SECONDS * 1000)

  document.cookie = `file_auth=${token}; expires=${date.toUTCString()}; path=/; samesite=strict`
}
