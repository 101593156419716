import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  // Currently working not answered
  if (question_id === 'currently_working' && answer === null) {
    effectCollector.add('previously_held_job', null)
    effectCollector.add('job_improvement_desired', null)
    effectCollector.add('additional_education_desired', null)
    effectCollector.add('if_yes_specify_activities', null)
  }
  // Not Currently working
  if (question_id === 'currently_working' && answer?.toUpperCase() === 'NO') {
    effectCollector.add('job_improvement_desired', null)
    effectCollector.add('additional_education_desired', null)
    effectCollector.add('if_yes_specify_activities', null)
  }
  // Currently working
  if (question_id === 'currently_working' && answer?.toUpperCase() !== 'NO') {
    effectCollector.add('previously_held_job', null)
  }

  if (question_id === 'additional_education_desired' && answer?.toUpperCase() !== 'YS') {
    effectCollector.add('if_yes_specify_activities', null)
  }

  return effectCollector.effects
}

export default effects;