import React from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'ducks/dialogSlice'

export const ConfirmationDialogContent = () => {
  return null
}

export const ConfirmationDialogActions = (props) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeDialog())
  }
  // takes in route
  const handleConfirm = () => {
    props.action()
    dispatch(closeDialog())
  }

  return (
    <>
      <Button data-testid="ConfirmationDialogActions-close" onClick={handleClose} variant='outlined'>Exit</Button>
      <Button data-testid="ConfirmDialogActions-confirm" onClick={handleConfirm} variant='contained'  color='primary'>OK</Button>
    </>
  )
}
