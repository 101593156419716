import React from 'react'
import styled from 'styled-components'
import { KeyboardDatePicker as Picker } from '@material-ui/pickers'
import { isValid, format, parse } from 'date-fns'

const CustomDatePicker = styled(Picker)`
  && {
    margin-top: 20px;
    margin-right: 20px;
    width: 250px;
  }
`

const SearchDatePicker = React.memo(
  ({
    datePickerOptions = { disableFuture: true, format: 'MM/dd/yyyy' },
    label,
    inputValue,
    handleChange,
  }) => {
    const parsedValue = parse(inputValue, 'MM/dd/yyyy', new Date())

    return (
      <CustomDatePicker
        KeyboardButtonProps={{
          'aria-label': `Button of the date picker ${label}`,
        }}
        id={label}
        value={inputValue}
        label={label}
        variant='inline'
        inputVariant='outlined'
        inputValue={
          isValid(parsedValue)
            ? format(parsedValue, datePickerOptions.format)
            : inputValue
        }
        onChange={(date, value) => handleChange(date, value)}
        maxDateMessage={null}
        minDateMessage={null}
        {...datePickerOptions}
      />
    )
  }
)

export default SearchDatePicker
