import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckboxGrid from 'components/general/CheckboxGrid'
import Select from 'components/general/Select'
import SkipButton from 'components/general/SkipButton'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'

const emotionalMentalHealthIndicatorSkipSelector = state =>
  state.interview.no_answer_emo_mental_health.answer &&
  state.interview.no_answer_emo_mental_health.answer.includes('NA')

const previewEmotionalMentalHealthIndicatorSkipSelector = state =>
  state.preview.no_answer_emo_mental_health.answer &&
  state.preview.no_answer_emo_mental_health.answer.includes('NA')

const EmotionalMentalHealthIndicator = React.memo(({ isPreview }) => {
  const emotionalMentalHealthIndicatorSkip = useSelector(
    isPreview ? previewEmotionalMentalHealthIndicatorSkipSelector : emotionalMentalHealthIndicatorSkipSelector
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Indicator Questions'
          domain='Emotional and Mental Health'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions to worker: Following is guidance, not intended to be read
          to the client. Refer individual to his or her copy of the OCAT Rights
          and Privacy Overview form. Remind individual that the interview is
          confidential except for mandated reporting of suspected elder and
          child abuse.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <form>
          <CheckboxGrid question_id='no_answer_emo_mental_health' isPreview={isPreview} />
          <Select
            question_id='emptional_mental_health_issues'
            isDisabled={emotionalMentalHealthIndicatorSkip}
            isPreview={isPreview}
          />
          <Select
            question_id='diagnosed_emotional_mental_health'
            isDisabled={emotionalMentalHealthIndicatorSkip}
            isPreview={isPreview}
          />
        </form>
        <SkipButton
          isDisabled={!emotionalMentalHealthIndicatorSkip}
        ></SkipButton>
      </Grid>
    </Grid>
  )
})

export default EmotionalMentalHealthIndicator
