import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getInterviewAppraisalPreview,
  getPreviewInterviewAppraisalPreview,
  isInterviewReadyToGenerateStatus,
} from './../../thunks/retrieveInterviewData'
import { createAsrPdf } from './../../thunks/createNewAsr'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { colors } from 'app/theme'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import DialogContentText from '@material-ui/core/DialogContentText'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Can from 'app/Can'
import { setFileDownloadAuthCookie } from '../../utilTools/fileDownloadHelper'
import CircularProgress from '@material-ui/core/CircularProgress'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  && {
    margin: 16px;
  }
`

const StyledOkButton = styled(Button)`
  && {
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`

const StyledCancel = styled(Button)`
  && {
    background-color: ${colors.turquoise};
    color: ${colors.white};
  }
`

const StyledDomain = styled.div`
  && {
    color: ${colors.grey};
  }
`

const StyledSubdomain = styled(Typography)`
  && {
    color: ${colors.turquoise};
    padding-top: 0px;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-bottom: 8px;
`

const PreviewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 8px;
`

const HtmlContainer = styled.div`
  width: 50%;
`

const DialogContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`
const StyledFormLabel = styled(FormLabel)`
  && {
    color: ${colors.black};
  }
`

const StyledSpinner = styled(CircularProgress)`
  && {
    margin: 1em;
  }
`

const AppraisalPreviewHeader = React.memo(({ domain, subdomain }) => {
  return (
    <HeaderContainer>
      <div>
        <StyledDomain>{domain}</StyledDomain>
        <StyledSubdomain variant='h4'>{subdomain}</StyledSubdomain>
      </div>
    </HeaderContainer>
  )
})

const GenerateAsrButton = props => {
  const { label, handleClick, isDisabled } = props

  return (
    <ButtonContainer>
      {isDisabled ? (
        <StyledButton disabled variant='contained' color='primary'>
          {label}
        </StyledButton>
      ) : (
        <StyledButton variant='contained' color='primary' onClick={handleClick}>
          {label}
        </StyledButton>
      )}
    </ButtonContainer>
  )
}

const SuccessDialog = props => {
  const { title, onClose, open, reportLink } = props
  const handleClose = () => {
    onClose()
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <MiddleContainer>
            <StyledButton
              color='secondary'
              variant='contained'
              href={reportLink}
              download
            >
              Download ASR PDF
            </StyledButton>
          </MiddleContainer>
        </DialogContent>
        <DialogActions>
          <StyledOkButton onClick={() => handleClose()}>OK</StyledOkButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const AsrDialog = props => {
  const { open, isReady, onClose, generate, isGenerating } = props
  const [language, setLanguage] = useState('en')
  const handleClose = () => {
    onClose(false)
  }

  const handleChange = event => {
    setLanguage(event.target.value)
  }

  const handleContinue = () => {
    generate(language)
  }

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby='Generate ASR' open={open}>
        <DialogTitle id='dlg-title'>
          {isReady
            ? 'You are about to generate an ASR.'
            : 'You have recommendations that have not been reviewed.'}
        </DialogTitle>
        <DialogContent>
          {isGenerating ? 
            <>
              <DialogContentText>
                <DialogContainer>
                  <StyledSpinner />
                  <StyledFormLabel>
                    ASR Generation in progress. Please wait a few moments for it to complete.
                  </StyledFormLabel>
                </DialogContainer>
              </DialogContentText>
            </>
          : isReady ? (
            <>
              <FormControl component='fieldset'>
                <StyledFormLabel component='legend'>
                  Please select from the following options.
                </StyledFormLabel>
                <DialogContainer>
                  <RadioGroup
                    aria-label='generate options'
                    name='generate-asr'
                    value={language}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value='en'
                      control={<Radio />}
                      label='Lock questionnaire and generate ASR (English)'
                    />
                    <FormControlLabel
                      value='es'
                      control={<Radio />}
                      label='Lock questionnaire and generate ASR (Spanish)'
                    />
                  </RadioGroup>
                </DialogContainer>
              </FormControl>
            </>
          ) : (
            <>
              <DialogContentText>
                <DialogContainer>
                  <StyledFormLabel>
                    Please go back and review each recommendation
                  </StyledFormLabel>
                </DialogContainer>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          { isGenerating ? <></> : isReady ? (
            <>
              <StyledCancel onClick={() => handleClose()}>Cancel</StyledCancel>
              <StyledOkButton onClick={() => handleContinue()}>
                Continue
              </StyledOkButton>
            </>
          ) : (
            <>
              <StyledOkButton onClick={() => handleClose()}>OK</StyledOkButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

const AppraisalPreview = ({ isPreview }) => {
  const [html, setHtml] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  let [isReady, setIsReady] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [open, setOpen] = useState(false)
  const [reportLink, setReportLink] = useState('')
  const dispatch = useDispatch()

  let interviewId = useSelector(state => state.client.currentInterview)
  const interviewStatus = useSelector(
    state => state.client.currentInterviewStatus
  )
  const roles = useSelector(state => state.user.roles)

  if(!isPreview) {
    dispatch(getInterviewAppraisalPreview(interviewId)).then(value => {
     setHtml(value.getAppraisalPreview)
   })
    
    dispatch(isInterviewReadyToGenerateStatus(interviewId)).then(value => {
      setIsReady(value.interviewReadyToGenerate)
   })
  } else {
    dispatch(getPreviewInterviewAppraisalPreview()).then(value => {
      setHtml(value.getPreviewAppraisalPreview)
    isReady = true
  })
  }

  const handleOpen = value => {
    setOpen(value)
  }

  const handleSuccess = value => {
    setSuccess(value)
    setIsComplete(true)
  }

  const handleGenerate = language => {
    
    setIsGenerating(true)
    const started = new Date();
    dispatch(createAsrPdf(interviewId, language)).then(value => {
      handleOpen(false)
      if (value) {
        const enReportLink = value.generateAsrPdf.reportLink
        const esReportLink = value.generateAsrPdf.spanishReportLink

        setFileDownloadAuthCookie()

        setReportLink(esReportLink || enReportLink)

        setSuccess(true)
      }
    }).finally(() => { 
      const timeElapsedInMs = new Date() - started
      // this function is declared in the index file for application monitoring
      // eslint-disable-next-line no-undef
      ineum('reportEvent', 'asrGeneration', {
        duration: timeElapsedInMs
      });
      setIsGenerating(false)
    })
    
  }
  return (
    <>
      {isComplete ? (
        <Redirect to='/interview_list' />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AppraisalPreviewHeader
                subdomain='Appraisal Preview'
                domain='Appraisal'
              />
            </Grid>
            <Grid item xs={6}>
              <Can
                roles={roles}
                perform={'asr:create'}
                yes={() => {
                  return (
                    <GenerateAsrButton
                      label='Generate ASR'
                      isDisabled={
                        interviewStatus === 'complete' ||
                        interviewStatus === 'cancelled'
                      }
                      handleClick={() => handleOpen(true)}
                      interviewStatus={interviewStatus}
                    />
                  )
                }}
                no={() => {
                  return (
                    <GenerateAsrButton
                      label='Generate ASR'
                      isDisabled={true}
                      handleClick={() => handleOpen(true)}
                      interviewStatus={interviewStatus}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PreviewContainer>
                <HtmlContainer dangerouslySetInnerHTML={{ __html: html }} />
              </PreviewContainer>
            </Grid>
          </Grid>
          <AsrDialog
            open={open}
            isReady={isReady}
            onClose={handleOpen}
            generate={handleGenerate}
            isGenerating={isGenerating}
          />
          <SuccessDialog
            open={showSuccess}
            onClose={handleSuccess}
            reportLink={reportLink}
            title='ASR Created Successfully'
          />
        </>
      )}
    </>
  )
}

export default AppraisalPreview
