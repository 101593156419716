import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  creatingClient: false,
}

const createClientSlice = createSlice({
  name: 'createClient',
  initialState: initialState,
  reducers: {
    setCreatingClient: (state, action) => {
      state.creatingClient = action.payload
    },
  },
})

export const { setCreatingClient } = createClientSlice.actions

export default createClientSlice.reducer
