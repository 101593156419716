import { batch } from 'react-redux'
import {
  updateClient,
  setInterview,
  setInterviewStatus,
} from 'ducks/clientSlice'
import {
  getInterviewQuestionsAndAnswers,
  getInterviewProgress,
  getInterviewNotes,
  getRecommendationsAndActionPlans,
  getAssociatedClientInterviews,
} from 'thunks/retrieveInterviewData'
import {
  setInterviewLoading,
  clearNewInterviewSlice,
} from 'ducks/newInterviewSlice'
import client from 'apollo/Apollo'
import { gql } from 'apollo-boost'
import { openDialog } from 'ducks/dialogSlice'
import { getLastSavedDate } from './getLastSavedDate'

const PREPOPULATE_INTERVIEW = gql`
  mutation($interviewID: Int!) {
    prepopulateNewInterview(interviewID: $interviewID) {
      interviewID
    }
  }
`

export const handleExistingInterview = rowData => {
  return async (dispatch, getState) => {
    const prepopulate = getState().newInterview.prepopulateInterview
    const {
      interviewId: interviewID,
      effectiveStartAndEndDates: { start, end },
      caseNumber,
    } = rowData

    dispatch(setInterviewLoading(true))

    if (prepopulate === 'populate') {
      try {
        await client.mutate({
          mutation: PREPOPULATE_INTERVIEW,
          variables: {
            interviewID,
          },
        })
      } catch (error) {
        // this function is declared in the index file for application monitoring
        // eslint-disable-next-line no-undef
        ineum('reportError', error)        
        // If server error, show error dialog
        dispatch(
          openDialog({
            type: 'error',
            title: `System Error`,
            props: {
              error,
            },
          })
        )
        dispatch(clearNewInterviewSlice())
      }
    }
    batch(() => {
      dispatch(clearNewInterviewSlice())
      dispatch(
        updateClient({
          startDate: start,
          endDate: end,
          assignedInterviewer: rowData.interviewerId
            ? rowData.interviewer
            : null,
          caseNumber,
        })
      )
      dispatch(setInterview(rowData.interviewId))
      dispatch(setInterviewStatus(rowData.asrStatus?.toLowerCase()))
      dispatch(getInterviewQuestionsAndAnswers(rowData.interviewId))
      dispatch(getInterviewProgress(rowData.interviewId))
      dispatch(getInterviewNotes(rowData.interviewId))
      dispatch(getRecommendationsAndActionPlans(rowData.interviewId))
      dispatch(getLastSavedDate())
      dispatch(getAssociatedClientInterviews(rowData.interviewId))
    })
  }
}
