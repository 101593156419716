import EffectCollector from 'side-effects/effectCollector'

// Side effects for changes on the Transportation - Driver's License page (D2)
// This set of side effects is triggered whenever a field changes on the page
// Receives a single question_id (the one that just changed) and its answer
const effects = ({ question_id, answer }) => {
  // The EffectCollector is simply a class that contains an array
  // and an add() method that builds the array of side effects.
  // It's simply for convenience and easier to read code.
  const effectCollector = new EffectCollector()

  // When license is invalid
  if (
    question_id === 'valid_driver_license' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('all_license_types', [])
    effectCollector.add('driving_license_specify', null)
    effectCollector.add('issuing_state', null)
    effectCollector.add('restricted_license', null)
    effectCollector.add('clean_driving_record', null)
    effectCollector.add('driving_license_explain', null)
  }

  // When license is valid
  if (
    question_id === 'valid_driver_license' &&
    (answer?.toUpperCase() === 'YS' || !answer)
  ) {
    effectCollector.add('other_forms_government_id', null)
    effectCollector.add('suspended_license', null)
    effectCollector.add('driving_license_why_revoked', null)
    effectCollector.add('driving_license_revoked', null)
    effectCollector.add('driving_license_reinstated', null)
  }

  if (
    question_id === 'all_license_types' &&
    answer &&
    !answer.map(ans => ans.toUpperCase()).includes('OT')
  ) {
    effectCollector.add('driving_license_specify', null)
  }

  if (
    question_id === 'clean_driving_record' &&
    answer?.toUpperCase() !== 'NO'
  ) {
    effectCollector.add('driving_license_explain', null)
  }

  if (question_id === 'suspended_license' && answer?.toUpperCase() === 'NO') {
    effectCollector.add('driving_license_why_revoked', null)
    effectCollector.add('driving_license_revoked', null)
    effectCollector.add('driving_license_reinstated', null)
  }

  return effectCollector.effects
}

export default effects;