import EffectCollector from 'side-effects/effectCollector'
import store from 'app/store'

const effects = ({ question_id, answer }, isPreview) => {
  const effectCollector = new EffectCollector()
  const stateProp = isPreview ? 'preview' : 'interview'
  if (question_id === 'child_issues_card' && answer && Array.isArray(answer)) {
    // Get a falsy value for the last answer in the array.
    const answerLength = answer ? answer.length : 0
    const lastAnswer = answerLength ? answer[answerLength - 1] : 0

    // Get answers for the household composition table and  parent and child support table.
    const householdMembersAnswers =
      store.getState()[stateProp].household_members_table.answer || []
    const parentingAndChildSupportAnswer =
      store.getState()[stateProp].parenting_and_child_support_table.answer || []

    // Create an object with all relationship types.
    const relationshipTypes = store
      .getState()[stateProp].household_members_table.sub_question_ids.household_relationship.options.reduce(
        (relationshipTypes, option) => {
          relationshipTypes[option.optionId] = option.optionLabel
          return relationshipTypes
        },
        {}
      )
    // If a child was removed from the child issues page, remove the child from the parenting and
    // child support page as well. If the id was not found in the list, the last child in the
    // parenting and child support page must be the child to remove.
    if (answer.length < parentingAndChildSupportAnswer.length) {
      let removeLastChild = true
      for (const index in answer) {
        const memberId = answer[index].id
        if (memberId !== parentingAndChildSupportAnswer[index].id) {
          const newParentingAndChildSupportAnswer = parentingAndChildSupportAnswer.filter(
            (_, idx) => idx.toString() !== index
          )
          removeLastChild = false
          effectCollector.add(
            'parenting_and_child_support_table',
            newParentingAndChildSupportAnswer
          )
          break
        }
      }
      if (removeLastChild) {
        const newAnswer = parentingAndChildSupportAnswer.filter(
          (_, index) => index !== parentingAndChildSupportAnswer.length - 1
        )
        effectCollector.add('parenting_and_child_support_table', newAnswer)
      }
    }

    // If the answer is longer than the answer in the parenting and child support
    // page, add the last answer to the parenting and child support page.
    else if (answer.length > parentingAndChildSupportAnswer.length) {
      for (const index in householdMembersAnswers) {
        const member = householdMembersAnswers[index]
        if (member.id === lastAnswer.id) {
          lastAnswer.chld_name = member.household_name
          lastAnswer.relationship =
            relationshipTypes[member.household_relationship]
          const newParentingAndChildSupportAnswer = {
            pcs_name: lastAnswer.chld_name,
            pcs_relationship: lastAnswer.relationship,
            id: lastAnswer.id,
            use_same_values: [],
          }

          effectCollector.add('parenting_and_child_support_table', [
            ...parentingAndChildSupportAnswer,
            newParentingAndChildSupportAnswer,
          ])
          break
        }
      }
    }

    let isChangeConditional = false
    const filteredIssuesAnswer = answer.map(row => {
      const rowCopy = { ...row }
      const {
        a_have_disabilities,
        b_have_health_prob,
        c_have_mental_health,
        d_have_behavioral_prob,
        e_have_special_needs,
        f_discipline_prob,
        g_miss_school,
        h_suspension_expulsion,
        i_face_charges,
        j_safety_issues,
        k_history_assault,
      } = rowCopy

      if (a_have_disabilities?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_disabilities
        isChangeConditional = true
      }
      if (b_have_health_prob?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_health_problems
        isChangeConditional = true
      }
      if (c_have_mental_health?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_mental_health
        isChangeConditional = true
      }
      if (d_have_behavioral_prob?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_behavioral_prob
        isChangeConditional = true
      }
      if (e_have_special_needs?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_special_needs
        isChangeConditional = true
      }
      if (f_discipline_prob?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_discipline_prob
        isChangeConditional = true
      }
      if (g_miss_school?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_miss_school
        isChangeConditional = true
      }
      if (h_suspension_expulsion?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_suspension_expulsion
        isChangeConditional = true
      }
      if (i_face_charges?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_face_charges
        isChangeConditional = true
      }
      if (j_safety_issues?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_safety_issues
        isChangeConditional = true
      }
      if (k_history_assault?.toUpperCase() === 'NO') {
        delete rowCopy.if_yes_specify_history_assault
        isChangeConditional = true
      }
      return rowCopy
    })
    if (isChangeConditional) {
      effectCollector.add('child_issues_card', filteredIssuesAnswer)
    }
  }
  return effectCollector.effects
}

export default effects;