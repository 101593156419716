import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { colors } from 'app/theme'
import { setCreatingClient } from 'ducks/createClientSlice'

const StyledPrimaryButton = styled(Button)`
  && {
    background-color: ${colors.calsawsBlue};
    color: ${colors.white};
  }
`

const StyledSecondaryButton = styled(Button)`
  && {
    background-color: ${colors.turquoise};
    color: ${colors.white};
  }
`

const CreateClientDialog = ({
  wantsToCreate,
  setWantsToCreate,
  handleCreateClient,
  wantsToClear,
  setWantsToClear,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  // We have two purposes for this dialog:
  // 1. To confirm or cancel the creation of a client (Triggered by pressing SAVE)
  // 2. To confirm the cancellation of creating a client (Triggered by CANCEL)

  // The secondary button functions the same for both use cases, it simply cancels
  // the confirmation dialog
  const handleSecondaryClick = () => {
    if (wantsToCreate) {
      setWantsToCreate(false)
    } else {
      setWantsToClear(false)
    }
  }

  // The primary button either starts the creation of client mutation, or
  // clears out the form and navigates back to the client search page
  const handlePrimary = () => {
    if (wantsToCreate) {
      setWantsToCreate(false)
      handleCreateClient()
    } else {
      setWantsToClear(false)
      dispatch(setCreatingClient(false))
      history.push('/search')
    }
  }

  const handleClose = () => {
    if (wantsToCreate) {
      setWantsToCreate(false)
    } else {
      setWantsToClear(false)
    }
  }

  const warningText = wantsToCreate
    ? 'You are creating a Non-SAWS record. No ASR information will be sent to SAWS.'
    : ' You are about to exit without saving! Click Cancel to go back or Exit to continue.'

  return (
    <Dialog
      data-testid={`CreateClientDialog`}
      open={wantsToCreate || wantsToClear}
      onClose={handleClose}
      aria-labelledby='create-client-dialog'
      aria-describedby='create-client-dialog'
    >
      <DialogTitle>
        Confirm {wantsToCreate ? 'New Client' : 'Cancel'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
        <DialogActions>
          <StyledSecondaryButton
            data-testid={`CreateClientDialog-button-cancel`}
            onClick={handleSecondaryClick}>
            CANCEL
          </StyledSecondaryButton>
          <StyledPrimaryButton 
            data-testid={`CreateClientDialog-button-confirm`}
            onClick={handlePrimary}>
            {wantsToCreate ? 'OK' : 'EXIT'}
          </StyledPrimaryButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default CreateClientDialog
