import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CheckboxGrid from 'components/general/CheckboxGrid'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Text from 'components/general/Text'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import ControlDescription from 'components/general/ControlDescription'
import InfiniteTable from 'components/table/InfiniteTable'
import InfiniteText from 'components/table/InfiniteText'
import InfiniteDatePicker from 'components/table/InfiniteDatePicker'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'

const StyledInfiniteTable = styled(InfiniteTable)`
  && {
    margin-bottom: 32px;
  }
`

const VocationalTechnicalRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText
        sub_question_id={'vocational_certificate_specify'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteText
        sub_question_id={'vocational_certificate_name'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteText
        sub_question_id={'vocational_certificate_location'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteDatePicker
        hideLabel
        sub_question_id={'vocational_license_expiration_date'}
        datePickerOptions={{ disableFuture: false, format: 'MM/dd/yyyy' }}
        {...props}
        isPreview={isPreview}
      />
    </>
  )
})

const ProfessionalLicenseRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText
        sub_question_id={'professional_license_specify'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteText sub_question_id={'professional_license_name'} {...props} />
      <InfiniteText
        sub_question_id={'professional_license_location'}
        {...props}
        shouldDecode={true}
        isPreview={isPreview}
      />
      <InfiniteDatePicker
        hideLabel
        sub_question_id={'professional_license_expiration_date'}
        datePickerOptions={{ disableFuture: false, format: 'MM/dd/yyyy' }}
        {...props}
        isPreview={isPreview}
      />
    </>
  )
})

const CollegeDegreeRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText sub_question_id={'college_degree_specify'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'college_degree_name'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'college_degree_location'} {...props} shouldDecode={true} isPreview={isPreview} />
    </>
  )
})

const PostgradDegreeRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText sub_question_id={'postgrad_degree_specify'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'postgrad_degree_name'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'postgrad_degree_location'} {...props} shouldDecode={true} isPreview={isPreview} />
    </>
  )
})

const OtherDegreeRow = React.memo(props => {
  const { isPreview } = props
  return (
    <>
      <InfiniteText sub_question_id={'other_degree_specify'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'other_degree_name'} {...props} shouldDecode={true} isPreview={isPreview} />
      <InfiniteText sub_question_id={'other_degree_location'} {...props} shouldDecode={true} isPreview={isPreview} />
    </>
  )
})

const noDiplomaSelector = state => state.interview.no_diploma.answer
const previewNoDiplomaSelector = state => state.preview.no_diploma.answer
const selectNoDiploma = createSelector(noDiplomaSelector, answer => !answer)
const selectPreviewNoDiploma = createSelector(previewNoDiplomaSelector, answer => !answer)
const validQuestionsCheckList = [
  'highschool_diploma_name',
  'highschool_diploma_location',
  'ged_from',
  'ged_location',
  'vocational_technical_table',
  'professional_license_table',
  'college_degree_table',
  'postgrad_degree_table',
  'other_degree_table',
]

const validQuestionsSelectors = [
  ...validQuestionsCheckList,
].map(question => state => state.interview[question])

const previewValidQuestionsSelectors = [
  ...validQuestionsCheckList,
].map(question => state => state.preview[question])

const canDisableNoneCheckboxSelector = createSelector(
  validQuestionsSelectors,
  (...questions) => {
    for (let question of questions) {
      if (question.answer) {
        return true
      }
    }
    return false
  }
)

const previewCanDisableNoneCheckboxSelector = createSelector(
  previewValidQuestionsSelectors,
  (...questions) => {
    for (let question of questions) {
      if (question.answer) {
        return true
      }
    }
    return false
  }
)

const vocationTechnicalDegreeTableQuestionSelector = state =>
  state.interview.vocational_technical_table

const previewVocationTechnicalDegreeTableQuestionSelector = state =>
  state.preview.vocational_technical_table

const professionalLicenseTableQuestionSelector = state =>
  state.interview.professional_license_table

const previewProfessionalLicenseDegreeTableQuestionSelector = state =>
  state.preview.professional_license_table

const collegeDegreeTableQuestionSelector = state =>
  state.interview.college_degree_table

const previewCollegeDegreeTableQuestionSelector = state =>
  state.preview.college_degree_table

const postGradDegreeTableQuestionSelector = state =>
  state.interview.postgrad_degree_table

const previewPostGradDegreeTableQuestionSelector = state =>
  state.preview.postgrad_degree_table

const otherDegreeTableQuestionSelector = state =>
  state.interview.other_degree_table

const previewOtherTechnicalDegreeTableQuestionSelector = state =>
  state.preview.other_degree_table

const diplomaNameSelector = state =>
  state.interview.highschool_diploma_name

const previewDiplomaNameSelector = state =>
  state.preview.highschool_diploma_name

const diplomaLocationSelector = state =>
  state.interview.highschool_diploma_location

const previewDiplomaLocationSelector = state =>
  state.preview.highschool_diploma_location

const gedNameSelector = state =>
  state.interview.ged_from

const previewGedNameSelector = state =>
  state.preview.ged_from
  
const gedLocationSelector = state =>
  state.interview.ged_location
  
const previewGedLocationSelector = state =>
  state.preview.ged_location

const EducationDiplomaDegreeCertification = ({ isPreview }) => {
  const noDiploma = useSelector(isPreview ? selectPreviewNoDiploma : selectNoDiploma)
  const cancelledOrCompletedInterview = useSelector(
    isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
  )
  const { roles } = useSelector(state => state.user)
  const isDisabled = !noDiploma
  const canDisableNoneCheckbox = useSelector(isPreview ? previewCanDisableNoneCheckboxSelector : canDisableNoneCheckboxSelector)

  const vocationTechnicalDegreeTableQuestion = useSelector(isPreview ? previewVocationTechnicalDegreeTableQuestionSelector : vocationTechnicalDegreeTableQuestionSelector)
  const professionalLicenseDegreeTableQuestion = useSelector(isPreview ? previewProfessionalLicenseDegreeTableQuestionSelector : professionalLicenseTableQuestionSelector)
  const collegeDegreeTableQuestion = useSelector(isPreview ? previewCollegeDegreeTableQuestionSelector : collegeDegreeTableQuestionSelector)
  const postGradDegreeTableQuestion = useSelector(isPreview ? previewPostGradDegreeTableQuestionSelector : postGradDegreeTableQuestionSelector)
  const otherDegreeTableQuestion = useSelector(isPreview ? previewOtherTechnicalDegreeTableQuestionSelector : otherDegreeTableQuestionSelector)

  const gedNameQuestion = useSelector(isPreview ? previewGedNameSelector : gedNameSelector)
  const gedLocationQuestion = useSelector(isPreview ? previewGedLocationSelector : gedLocationSelector)
  const diplomaNameQuestion = useSelector(isPreview ? previewDiplomaNameSelector : diplomaNameSelector)
  const diplomaLocationQuestion = useSelector(isPreview ? previewDiplomaLocationSelector : diplomaLocationSelector)

  const voc_tech_headers = [
    vocationTechnicalDegreeTableQuestion.sub_question_ids.vocational_certificate_specify.label,
    vocationTechnicalDegreeTableQuestion.sub_question_ids.vocational_certificate_name.label,
    vocationTechnicalDegreeTableQuestion.sub_question_ids.vocational_certificate_location.label,
    vocationTechnicalDegreeTableQuestion.sub_question_ids.vocational_license_expiration_date.label,
  ]
  const prof_lic_headers = [
    professionalLicenseDegreeTableQuestion.sub_question_ids.professional_license_specify.label,
    professionalLicenseDegreeTableQuestion.sub_question_ids.professional_license_name.label,
    professionalLicenseDegreeTableQuestion.sub_question_ids.professional_license_location.label,
    professionalLicenseDegreeTableQuestion.sub_question_ids.professional_license_expiration_date.label,
  ]
  const coll_deg_headers = [
    collegeDegreeTableQuestion.sub_question_ids.college_degree_specify.label,
    collegeDegreeTableQuestion.sub_question_ids.college_degree_name.label,
    collegeDegreeTableQuestion.sub_question_ids.college_degree_location.label,
  ]
  const post_deg_headers = [
    postGradDegreeTableQuestion.sub_question_ids.postgrad_degree_specify.label,
    postGradDegreeTableQuestion.sub_question_ids.postgrad_degree_name.label,
    postGradDegreeTableQuestion.sub_question_ids.postgrad_degree_location.label,
  ]
  const othr_deg_headers = [
    otherDegreeTableQuestion.sub_question_ids.other_degree_specify.label,
    otherDegreeTableQuestion.sub_question_ids.other_degree_name.label,
    otherDegreeTableQuestion.sub_question_ids.other_degree_location.label,
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Diploma, Degree, Certification'
          domain='Education'
        />
        <Typography variant='body2'>
          Instructions: Ask about those that apply based on responses in Highest
          Grade.
        </Typography>
        <Typography variant='h5'>
          Do you have a diploma, degree, or certification?
        </Typography>
      </Grid>

      <CheckboxGrid
        question_id='no_diploma'
        isDisabled={canDisableNoneCheckbox}
        isPreview={isPreview}
      />
      <Grid container alignContent={'flex-start'}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Can
              roles={roles}
              perform={isPreview ? 'BRE:view' : 'interview:edit'}
              no={() => {
                return (
                  <ControlDescription disabled>
                    {diplomaNameQuestion.label}
                  </ControlDescription>
                )
              }}
              yes={() => {
                return (
                  <ControlDescription
                    disabled={cancelledOrCompletedInterview || isDisabled}
                  >
                    {diplomaNameQuestion.label}
                  </ControlDescription>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              question_id='highschool_diploma_name'
              disableDescriptionLabel={true}
              width='300px'
              isDisabled={isDisabled}
              shouldDecode={true}
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Can
              roles={roles}
              perform={isPreview ? 'BRE:view' : 'interview:edit'}
              no={() => {
                return (
                  <ControlDescription disabled>
                    {diplomaLocationQuestion.label}
                  </ControlDescription>
                )
              }}
              yes={() => {
                return (
                  <ControlDescription
                    disabled={cancelledOrCompletedInterview || isDisabled}
                  >
                    {diplomaLocationQuestion.label}
                  </ControlDescription>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              question_id='highschool_diploma_location'
              disableDescriptionLabel={true}
              width='300px'
              isDisabled={isDisabled}
              shouldDecode={true}
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Can
              roles={roles}
              perform={isPreview ? 'BRE:view' : 'interview:edit'}
              no={() => {
                return (
                  <ControlDescription disabled>
                    {gedNameQuestion.label}
                  </ControlDescription>
                )
              }}
              yes={() => {
                return (
                  <ControlDescription
                    disabled={cancelledOrCompletedInterview || isDisabled}
                  >
                    {gedNameQuestion.label}
                  </ControlDescription>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              question_id='ged_from'
              disableDescriptionLabel={true}
              width='300px'
              isDisabled={isDisabled}
              shouldDecode={true}
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Can
              roles={roles}
              perform={isPreview ? 'BRE:view' : 'interview:edit'}
              no={() => {
                return (
                  <ControlDescription disabled>
                    {gedLocationQuestion.label}
                  </ControlDescription>
                )
              }}
              yes={() => {
                return (
                  <ControlDescription
                    disabled={cancelledOrCompletedInterview || isDisabled}
                  >
                    {gedLocationQuestion.label}
                  </ControlDescription>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Text
              question_id='ged_location'
              disableDescriptionLabel={true}
              width='300px'
              isDisabled={isDisabled}
              shouldDecode={true}
              isPreview={isPreview}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledInfiniteTable
            question_id='vocational_technical_table'
            headers={voc_tech_headers}
            rowComponent={<VocationalTechnicalRow isPreview={isPreview} />}
            isDisabled={isDisabled}
            isPreview={isPreview}
          ></StyledInfiniteTable>
          <StyledInfiniteTable
            question_id='professional_license_table'
            headers={prof_lic_headers}
            rowComponent={<ProfessionalLicenseRow isPreview={isPreview} />}
            isDisabled={isDisabled}
            isPreview={isPreview}
          ></StyledInfiniteTable>
          <StyledInfiniteTable
            question_id='college_degree_table'
            headers={coll_deg_headers}
            rowComponent={<CollegeDegreeRow isPreview={isPreview} />}
            isDisabled={isDisabled}
            isPreview={isPreview}
          ></StyledInfiniteTable>
          <StyledInfiniteTable
            question_id='postgrad_degree_table'
            headers={post_deg_headers}
            rowComponent={<PostgradDegreeRow isPreview={isPreview} />}
            isDisabled={isDisabled}
            isPreview={isPreview}
          ></StyledInfiniteTable>
          <StyledInfiniteTable
            question_id='other_degree_table'
            headers={othr_deg_headers}
            rowComponent={<OtherDegreeRow isPreview={isPreview} />}
            isDisabled={isDisabled}
            isPreview={isPreview}
          ></StyledInfiniteTable>
          <CheckboxGrid question_id='esl' isPreview={isPreview} />
          <CheckboxGrid question_id='vesl' isPreview={isPreview} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EducationDiplomaDegreeCertification
