import gql from 'graphql-tag'
const queries = {
  FEED_QUERY_PAGING: gql`
    query Interview(
      $countyId: Int
      $pageIndex: Int!
      $pageSize: Int!
      $statusFilterValues: [String!]!
      $startDate: String!
      $endDate: String!
      $interviewerFilterValues: [Int!]!
      $excludeUnassigned: Boolean
      $sortBy: interviewsByCountySortOptions!
      $sortAscending: Boolean!
    ) {
      interviewsByCountyPage(
        countyId: $countyId
        pageIndex: $pageIndex
        pageSize: $pageSize
        statusFilterValues: $statusFilterValues
        startDate: $startDate
        endDate: $endDate
        interviewerFilterValues: $interviewerFilterValues
        excludeUnassigned: $excludeUnassigned
        sortBy: $sortBy
        sortAscending: $sortAscending
      ) {
        pageIndex
        pageSize
        totalRecordCount
        records {
          Client {
            id
            cin
            birthDate
            firstName
            lastName
            ssn
          }
          Case {
            id
            caseNumber
            county {
              id
              description
              code
            }
          }
          Assignee {
            User {
              id
              firstName
              lastName
              supervisorID
              details {
                officeID
                regionID
              }
            }
          }
          currentStatus {
            CVLType {
              description
            }
          }
          InterfaceInbound {
            source
          }
          initiatedDate
          updatedAt
        }
      }
    }
  `,
  CLIENT_INFO_QUERY: gql`
    query Client($id: Int) {
      clientById(clientId: $id) {
        firstName
        lastName
      }
    }
  `,
  GENDERS_LIST_QUERY: gql`
    query {
      genders {
        id
        interfaceCode
        description
      }
    }
  `,
  COUNTIES_LIST_QUERY: gql`
    query {
      counties {
        id
        interfaceCode
        description
      }
    }
  `,
  CLIENT_SEARCH_INTERVIEW_PAGED_QUERY: gql`
    query Interview(
      $firstName: String
      $lastName: String
      $ssn: String
      $cin: String
      $birthDate: DateTime
      $countyId: Int
      $caseNumber: String
      $interviewerId: Int
      $pageIndex: Int!
      $pageSize: Int!
      $sortBy: interviewsByCountySortOptions!
      $sortAscending: Boolean!
    ) {
      interviewsByClientSearchPage(
        firstName: $firstName
        lastName: $lastName
        ssn: $ssn
        cin: $cin
        birthDate: $birthDate
        countyId: $countyId
        caseNumber: $caseNumber
        interviewerId: $interviewerId
        pageSize: $pageSize
        pageIndex: $pageIndex
        sortBy: $sortBy
        sortAscending: $sortAscending
      ) {
        pageIndex
        pageSize
        totalRecordCount
        records {
          clientId
          name
          firstName
          lastName
          birthDate
          gender
          ssn
          cin
          caseNumber
          county
          dateInitiated
          appraisalStatus
          interviewer
          interviewerId
        }
      }
    }
  `,
  INTERVIEWER_QUERY: gql`
    query Interview($countyId: Int, $isStatewide: Boolean!) {
      getInterviewers(countyId: $countyId, isStatewide: $isStatewide) {
        id
        firstName
        lastName
      }
    }
  `,
  INTERVIEWER_OPTIONS: gql`
    query Interview {
      clientSearchInterviewerOptions {
        value
        label
      }
    }
  `,
}

export default queries;