import React from 'react'
import RecommendationsPage from 'components/recommendation/RecommendationsPage'

const RecommendationsFamily = React.memo(({ isPreview }) => {
  return (
    <RecommendationsPage
      domainCode='FS'
      domain='Recommendations'
      subdomain='Family Stabilization Evaluation'
      isPreview={isPreview}
    />
  )
})

export default RecommendationsFamily
