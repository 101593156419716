import EffectCollector from 'side-effects/effectCollector'

const effects = ({ question_id, answer }) => {
  const effectCollector = new EffectCollector()
  if (
    question_id === 'moved_last_12_months' &&
    (answer?.toUpperCase() === 'NT' || !answer)
  ) {
    effectCollector.add('number_housing_moves', null)
    effectCollector.add('explain_housing_moves', null)
  }

  if (question_id === 'number_housing_moves' && (!answer || answer < 3)) {
    effectCollector.add('explain_housing_moves', null)
  }

  if (
    question_id === 'current_housing_situation_unstable' &&
    (answer?.toUpperCase() === 'NO' || !answer)
  ) {
    effectCollector.add('housing_sitation_explain', null)
  }

  return effectCollector.effects
}

export default effects;