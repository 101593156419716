import React from 'react'
import { IconButton } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { colors } from 'app/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import * as oBotScript from './chatbotIntegration/obotscript'
import './chatbotIntegration/obotstyle.css'

const useStyles = makeStyles(theme =>
  createStyles({
    chatTrigger: {
      position: 'absolute',
      bottom: 40,
      right: 40,
      color: colors.turquoise,
      fontSize: '5em',
    },
    chatContainer: {
      position: 'absolute',
      bottom: 40,
      right: 40,
      height: '50%',
      display: 'flex',
      boxShadow: `0 1px 1px rgba(0,0,0,0.12),
              0 2px 2px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12)`,
      overflow: 'hidden',
      width: '520px',
      maxWidth: '50%',
      cursor: 'default',
    },
    chatHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#2F5496',
      color: 'white',
      height: '2em',
      padding: '8px 16px',
    },
  })
)

const Component = () => {
  const classes = useStyles()

  const { firstName, lastName } = useSelector(state => state.user)

  const initials = `${firstName?.substr(0, 1)}${lastName?.substr(0, 1)}`

  return (
    <>
      <IconButton
        data-testid='chatbot-open-button'
        className={classes.chatTrigger}
        onClick={() => oBotScript.openObot(initials)}
      >
        <FontAwesomeIcon icon={faCommentDots} />
      </IconButton>
      <div id={'chatbot-trigger-frame'}></div>
    </>
  )
}

export default Component
